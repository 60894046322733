import * as React from 'react';
import { EmbroiderySpecification, Logo, PlacementOption } from '../../common/EmbroideryTypes';
import { Button, Col, Container, Row } from 'reactstrap';
import ImageLoader from '../image-loader/ImageLoader';

import './ConfirmEmbroidery.scss';

// ----------------
// PROPS

interface ConfirmEmbroideryProps {
    embroiderySpecifications: EmbroiderySpecification[];
    onConfirm: (confirmed: boolean) => void;
}

// ----------------
// LOCAL STATE

interface ConfirmEmbroideryState {
    duplicateTapes: EmbroiderySpecification[];
    duplicatePlacements: EmbroiderySpecification[];
}

class ConfirmEmbroidery extends React.PureComponent<ConfirmEmbroideryProps, ConfirmEmbroideryState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: ConfirmEmbroideryProps, state: ConfirmEmbroideryState) {
        super(props);
        this.state = {
            duplicateTapes: [],
            duplicatePlacements: []
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.evaluateSpecifications();
    }

    public componentDidUpdate = (prevProps: ConfirmEmbroideryProps) => {
        if (!this.areEqual(this.props.embroiderySpecifications, prevProps.embroiderySpecifications)) {
            this.evaluateSpecifications();
        }
    }

    public componentWillUnmount = () => {}

    public render = () => {
        return (
            <Container id="confirm-embroidery">
                <Row>
                    <Col className="pl-4 pr-4 pb-2 pt-4 prompt">
                        <label>Please Confirm Selections</label>
                    </Col>
                </Row>
                {this.state.duplicateTapes.length > 0 && (
                    <Row>
                        <Col className="pl-4 pr-4 pb-3 pt-2 issue">
                            <Container>
                                <Row>
                                    <Col className="pl-0 pr-0 heading">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-alert-octagon">
                                            <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                            <line x1="12" y1="8" x2="12" y2="12"></line>
                                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                        </svg>
                                        <label>Duplicate tapes requested</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-0 pt-3 thumbnails">
                                        <React.Fragment>
                                            {this.state.duplicateTapes.map((embroiderySpecification: EmbroiderySpecification, index: number) => (
                                                <div className="image" key={"duplicateTape1-" + index}>
                                                    <div className="logo-image">
                                                        <div className="image-stretcher">
                                                            <div className="image-canvas">
                                                                <ImageLoader url={embroiderySpecification.logo ? embroiderySpecification.logo.logoUrl : null}
                                                                    isLoading={false} autoSize={true} noFadeIn={false} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="placements">
                                                <ul>
                                                    {this.state.duplicateTapes.map((embroiderySpecification: EmbroiderySpecification, index: number) => (
                                                        <li key={"duplicatePlacement1-" + index}>
                                                            &#9642;&nbsp; {embroiderySpecification.placement ? embroiderySpecification.placement.description : ''}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </React.Fragment>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                )}
                {this.state.duplicatePlacements.length > 0 && (
                    <Row>
                        <Col className="pl-4 pr-4 pb-3 pt-2 issue">
                            <Container>
                                <Row>
                                    <Col className="pl-0 pr-0 heading">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-alert-octagon">
                                            <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                            <line x1="12" y1="8" x2="12" y2="12"></line>
                                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                        </svg>
                                        <label>Duplicate placements requested</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-0 pt-3 thumbnails">
                                        <React.Fragment>
                                            {this.state.duplicatePlacements.map((embroiderySpecification: EmbroiderySpecification, index: number) => (
                                                <div className="image" key={"duplicateTape2-" + index}>
                                                    <div className="logo-image">
                                                        <div className="image-stretcher">
                                                            <div className="image-canvas">
                                                                <ImageLoader url={embroiderySpecification.logo ? embroiderySpecification.logo.logoUrl : null}
                                                                    isLoading={false} autoSize={true} noFadeIn={false} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="placements">
                                                <ul>
                                                    {this.state.duplicatePlacements.map((embroiderySpecification: EmbroiderySpecification, index: number) => (
                                                        <li key={"duplicatePlacement2-" + index}>
                                                            &#9642;&nbsp; {embroiderySpecification.placement ? embroiderySpecification.placement.description : ''}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </React.Fragment>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className="pl-4 pr-4 pt-3 pb-1 embroidery-confirmation">
                        Continue? &nbsp;Press <span>Yes</span> to confirm, <span>No</span> to make changes.
                    </Col>
                </Row>
                <Row>
                    <Col className="pl-4 pr-4 pt-2 pb-2 embroidery-confirmation-button-bar">
                        <Button color="secondary" onClick={() => this.props.onConfirm(true)}>Yes</Button>
                        <Button color="secondary" onClick={() => this.props.onConfirm(false)}>No</Button>
                    </Col>
                </Row>
            </Container>
        );
    }

    // ----------------
    // HELPERS

    private areEqual = (specifications: EmbroiderySpecification[], prevSpecifications: EmbroiderySpecification[]): boolean => {
        let result: boolean = specifications.length === prevSpecifications.length ? true : false;
        if (result) {
            for (let n: number = 0; n < specifications.length; n++) {

                let currentLogo: Logo | null = specifications[n].logo;
                let prevLogo: Logo | null = prevSpecifications[n].logo;
                if ((currentLogo && !prevLogo) || (prevLogo && !currentLogo)) {
                    result = false;
                }
                else if (currentLogo && prevLogo) {
                    result = (currentLogo.tapeNumber === prevLogo.tapeNumber ? true : false);
                }
                if (!result) {
                    break;
                }


                let currentPlacement: PlacementOption | null = specifications[n].placement;
                let prevPlacement: PlacementOption | null = prevSpecifications[n].placement;
                if ((currentPlacement && !prevPlacement) || (prevPlacement && !currentPlacement)) {
                    result = false;
                }
                else if (currentPlacement && prevPlacement) {
                    result = (currentPlacement.code === prevPlacement.code ? true : false);
                }
                if (!result) {
                    break;
                }
            }
        }
        return result;
    }

    private evaluateSpecifications = (): void => {
        let specifications: EmbroiderySpecification[] = this.props.embroiderySpecifications;
        let duplicateTapes: EmbroiderySpecification[] = [];
        let duplicatePlacements: EmbroiderySpecification[] = [];

        for (let n: number = 0; n < specifications.length; n++) {
            let currentLogo: Logo | null = specifications[n].logo;

            duplicateTapes = specifications.filter((s: EmbroiderySpecification) => {
                return (currentLogo && s.logo && s.logo.tapeNumber === currentLogo.tapeNumber);
            });
            if (duplicateTapes.length > 1) {
                break;
            }
            else {
                duplicateTapes = [];
            }
        }

        for (let n: number = 0; n < specifications.length; n++) {
            let currentPlacement: PlacementOption | null = specifications[n].placement;

            duplicatePlacements = specifications.filter((s: EmbroiderySpecification) => {
                return (currentPlacement && s.placement && s.placement.code === currentPlacement.code);
            });
            if (duplicatePlacements.length > 1) {
                break;
            }
            else {
                duplicatePlacements = [];
            }
        }

        if (duplicatePlacements.length > 0 && duplicatePlacements.length === duplicateTapes.length) {
            let sameTapes: boolean = true;
            for (let n: number = 0; n < duplicatePlacements.length; n++) {
                let currentLogo: Logo | null = duplicatePlacements[n].logo;
                let currentPlacement: PlacementOption | null = duplicatePlacements[n].placement;
                let matches: EmbroiderySpecification[] = duplicateTapes.filter((s: EmbroiderySpecification) => {
                    return ((currentLogo && s.logo && currentLogo.tapeNumber === s.logo.tapeNumber) &&
                        (currentPlacement && s.placement && currentPlacement.code === s.placement.code)) ? true : false;
                });
                if (matches.length === 0) {
                    sameTapes = false;
                    break;
                }
            }
            if (sameTapes) {
                duplicatePlacements = [];
            }
        }

        this.setState({
            duplicateTapes: duplicateTapes,
            duplicatePlacements: duplicatePlacements
        });
    }
}

// ----------------
// EXPORT

export default ConfirmEmbroidery;
