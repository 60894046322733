import * as React from 'react';
import { motion } from 'framer-motion';
import { OrderItem } from '../../store/OrderItems';
import { CustomerAccount } from '../../store/Customers';
import { Client } from '../../store/Client';
import { Button, Container, Row, Col } from 'reactstrap';
import { AvailableUnits, Dimension, InventoryDate, Size } from '../../common/ProductTypes';
import { EmbroiderySpecification } from '../../common/EmbroideryTypes';
import AddOrderItemNotes from '../add-order-item-notes/AddOrderItemNotes';
import OrderItemDescription from '../order-item-description/OrderItemDescription';
import OrderItemEmbroidery from '../order-item-embroidery/OrderItemEmbroidery';
import OrderItemNotes from '../order-item-notes/OrderItemNotes';
import OrderItemTotal from '../order-item-total/OrderItemTotal';
import ImageLoader from '../image-loader/ImageLoader';
import SizeRun from '../size-run/SizeRun';
import AddEmbroidery from '../add-embroidery/AddEmbroidery';
import CopyOrderItem from '../copy-order-item/CopyOrderItem';
import DeleteOrderItem from '../delete-order-item/DeleteOrderItem';
import cloneDeep from 'lodash/cloneDeep';

import './OrderItemForm.scss';

// ----------------
// PROPS

interface OrderItemFormProps {
    client: Client;
    customerAccount: CustomerAccount | null | undefined;
    isLoading: boolean;
    isSelected: boolean;
    isOpenOrder: boolean;
    orderItem: OrderItem;
    onViewProduct: (orderItem: OrderItem) => void;
    onCopyOrderItem: (orderItem: OrderItem) => void;
    onRemoveOrderItem: () => void;
    onChangeOrderItemQuantity: (orderItem: OrderItem) => void;
    onAddOrderItemEmbroidery: (orderItem: OrderItem) => void;
    onRemoveOrderItemEmbroidery: (orderItem: OrderItem) => void;
    onImageLoad: () => void;
    shipDate: Date;
}

// ----------------
// LOCAL STATE

interface OrderItemFormState {
    embroideryIndex: number;
    embroiderySpecifications: EmbroiderySpecification[];
    isAddingEmbroidery: boolean;
    isAddingNote: boolean;
    orderItemToCopy: OrderItem | null;
    orderItemToDelete: OrderItem | null;
    orderItemNotes: string;
}

class OrderItemForm extends React.PureComponent<OrderItemFormProps, OrderItemFormState> {

    // ----------------
    // VARIABLES

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', 'overflow': 'visible' }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderItemFormProps, state: OrderItemFormState) {
        super(props);
        this.state = {
            embroideryIndex: 0,
            embroiderySpecifications: cloneDeep(this.props.orderItem.embroiderySpecifications),
            isAddingEmbroidery: false,
            isAddingNote: false,
            orderItemToCopy: null,
            orderItemToDelete: null,
            orderItemNotes: this.props.orderItem.notes
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: OrderItemFormProps) => {
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <Container className={"order-item-form" + (this.state.orderItemToCopy || this.state.orderItemToDelete || this.props.isSelected ? " selected" : "")}>
                <Row>
                    <Col className="pl-0 pr-0 pr-md-2 order-item-product" xl={5} lg={5} md={12} xs={12}>
                        <Container>
                            <Row>
                                <Col className="pl-0 pr-0">
                                    <OrderItemDescription orderItem={this.props.orderItem} client={this.props.client}
                                        embroiderySpecifications={this.state.embroiderySpecifications}
                                        isLoading={this.props.isLoading} isOpenOrder={this.props.isOpenOrder}
                                        onViewProduct={this.props.onViewProduct} onImageLoad={this.onImageLoad}
                                        onAddEmbroidery={this.onAddEmbroidery} onAddNotes={this.onAddNotes}
                                        onCopyOrderItem={this.onCopyOrderItem} onRemoveOrderItem={this.onRemoveOrderItem} />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col className={"pl-0 pl-md-3 pr-0 mt-2 mt-md-0 order-item-size" + (this.isEmbroidered() ? " mt-0" : " mt-2")} xl={7} lg={7} md={12} xs={12}>
                        <Container>
                            <Row>
                                <Col className="pl-0 pr-0">
                                    <div className={"size-subtotal-wrapper" + (this.props.orderItem.dimensionDescription || this.isEmbroidered() ? "" : " single-dimension")}>
                                        <div className="size">
                                            {this.props.orderItem.dimensionDescription && (
                                                <div className="dimension">
                                                    <label className="name">{this.props.orderItem.dimensionDescription}</label>
                                                    <label className="value">{this.props.orderItem.dimension.code.toLowerCase()}</label>
                                                </div>
                                            )}
                                            <SizeRun dimension={this.props.orderItem.dimension} description={this.props.orderItem.sizeDescription}
                                                displayOnly={false} readOnly={!this.props.orderItem.isEditable ? true : false}
                                                shipDate={this.props.shipDate} chaseDate={this.props.orderItem.chaseDate}
                                                useEmbroideredDate={this.isEmbroidered()} onChangeQuantity={this.onChangeQuantity} />
                                        </div>
                                        <div className="d-none d-lg-block text-right subtotal">
                                            <OrderItemTotal orderItem={this.props.orderItem} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="collapsible-row pb-0 pl-0 pr-0">
                                    <motion.div animate={this.isEmbroidered() ? "visible" : "hidden"}
                                        initial={this.props.orderItem.embroiderySpecifications.length > 0 ? "visible" : "hidden"}
                                        variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                                        <OrderItemEmbroidery orderItem={this.props.orderItem} embroiderySpecifications={this.state.embroiderySpecifications}
                                            onRemoveEmbroidery={this.onRemoveEmbroidery} onEditEmbroidery={this.onEditEmbroidery} />
                                    </motion.div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="collapsible-row pb-0 pl-0 pr-0">
                                    <motion.div animate={this.hasNotes() ? "visible" : "hidden"}
                                        initial={this.hasNotes() ? "visible" : "hidden"}
                                        variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                                        <OrderItemNotes client={this.props.client} orderItem={this.props.orderItem} orderItemNotes={this.state.orderItemNotes}
                                            onEditNotes={this.onAddNotes} onRemoveNotes={this.hideOrderItemNotes} />
                                    </motion.div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <CopyOrderItem orderItemToCopy={this.state.orderItemToCopy} client={this.props.client}
                    onDismiss={this.hideCopyOrderItem} />
                <DeleteOrderItem orderItemToDelete={this.state.orderItemToDelete} clientCode={this.props.client.code}
                    onDelete={this.onDeleteOrderItemComplete} onDismiss={this.hideDeleteOrderItem}
                    onConcurrencyError={this.onDeleteOrderItemNotAllowed} />
                <AddOrderItemNotes isOpen={this.state.isAddingNote} client={this.props.client} orderItem={this.props.orderItem}
                    isOpenOrder={this.props.isOpenOrder} onDismiss={this.hideOrderItemNotes} />
                {this.props.customerAccount && (
                    <AddEmbroidery isOpen={this.state.isAddingEmbroidery} embroideryIndex={this.state.embroideryIndex}
                        client={this.props.client} customerAccount={this.props.customerAccount} orderItem={this.props.orderItem}
                        onDismiss={this.hideAddEmbroidery} onChangeEmbroideryIndex={this.onChangeEmbroideryIndex} />
                )}
            </Container>
        );
    }

    // ----------------
    // HELPERS

    private hasNotes = (): boolean => {
        return this.props.orderItem.notes ? true : false;
    }

    private hideAddEmbroidery = (orderItem: OrderItem | null | undefined): void => {
        if (orderItem) {
            this.props.orderItem.embroiderySpecifications = orderItem.embroiderySpecifications;
            this.props.onAddOrderItemEmbroidery(orderItem);
        }
        this.setState({
            isAddingEmbroidery: false,
            embroiderySpecifications: orderItem ?
                orderItem.embroiderySpecifications :
                this.state.embroiderySpecifications,
            embroideryIndex: 0
        });
    }

    private hideCopyOrderItem = (hasUpdates: boolean): void => {
        if (hasUpdates && this.state.orderItemToCopy) {
            let orderItem: OrderItem = cloneDeep(this.state.orderItemToCopy);
            setTimeout(() => {
                this.props.onCopyOrderItem(orderItem);
            }, 400);
        }
        this.setState({
            orderItemToCopy: null
        });
    }

    private hideDeleteOrderItem = (): void => {
        this.setState({
            orderItemToDelete: null
        });
    }

    private hideOrderItemNotes = (orderItem: OrderItem | null | undefined): void => {
        if (orderItem) {
            this.props.orderItem.notes = orderItem.notes;
        }
        this.setState({
            isAddingNote: false,
            orderItemNotes: orderItem ? orderItem.notes : this.state.orderItemNotes
        });
    }

    private isEmbroidered = (): boolean => {
        return this.state.embroiderySpecifications.length > 0;
    }

    private onAddEmbroidery = (event: any, orderItem: OrderItem, embroideryIndex: number): void => {
        event.preventDefault();
        this.setState({
            isAddingEmbroidery: true,
            embroideryIndex: embroideryIndex
        });
    }

    private onAddNotes = (event: any, orderItem: OrderItem): void => {
        event.preventDefault();
        this.setState({
            isAddingNote: true
        });
    }

    private onChangeEmbroideryIndex = (index: number): void => {
        this.setState({
            embroideryIndex: index
        });
    }

    private onChangeQuantity = (size: Size): void => {
        let matches: Size[] = this.props.orderItem.dimension.sizes.filter((s: Size) => {
            return s.code === size.code;
        });
        if (matches.length > 0) {
            matches[0].quantity = size.quantity;
            this.props.onChangeOrderItemQuantity(this.props.orderItem);
        }
    }

    private onCopyOrderItem = (event: any, orderItem: OrderItem): void => {
        event.preventDefault();
        this.setState({
            orderItemToCopy: orderItem
        });
    }

    private onDeleteOrderItemComplete = (): void => {
        this.hideDeleteOrderItem();
        setTimeout(() => {
            this.props.onRemoveOrderItem();
        });
    }

    private onDeleteOrderItemNotAllowed = (): void => {
        //    this.reloadTable();
    }

    private onRemoveEmbroidery = (embroideryIndex: number): void => {
        let embroiderySpecifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        embroiderySpecifications.splice(embroideryIndex, 1);
        this.setState({
            embroiderySpecifications: embroiderySpecifications
        })
        this.props.orderItem.embroiderySpecifications.splice(embroideryIndex, 1);
        this.props.onRemoveOrderItemEmbroidery(cloneDeep(this.props.orderItem));
    }

    private onRemoveOrderItem = (event: any, orderItem: OrderItem): void => {
        event.preventDefault();
        this.setState({
            orderItemToDelete: orderItem
        });
    }

    private onEditEmbroidery = (embroideryIndex: number): void => {
        this.setState({
            isAddingEmbroidery: true,
            embroideryIndex: embroideryIndex
        });
    }

    private onImageLoad = (): void => {
        this.props.onImageLoad();
    }
}

// ----------------
// EXPORT

export default OrderItemForm;
