import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as CustomersStore from '../../store/Customers';
import * as OrdersStore from '../../store/Orders';
import * as ProductlistsStore from '../../store/Productlists';
import { Client } from '../../store/Client';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import AddProductlistForm from '../add-productlist-form/AddProductlistForm';
import ProductlistFinder, { ProductlistName } from '../productlist-finder/ProductlistFinder';
import $ from 'jquery';

import './ProductlistContextSelector.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    customersState: {} as CustomersStore.CustomersState,
    ordersState: {} as OrdersStore.OrdersState,
    productlistsState: {} as ProductlistsStore.ProductlistsState
}

const actionCreators = {
    actions: Object.assign({}, ProductlistsStore.actionCreators, MessageStore.actionCreators)
}

interface ProductlistContextSelectorAsyncActions {
    asyncActions: {
        requestProductlistDetailAsync: (client: Client, id: number) => Promise<ProductlistsStore.ProductlistDetail | null | undefined>
    }
}

interface ProductlistContextSelectorOwnProps {
    isOpen: boolean;
    client: Client;
    onDismiss: () => void
}

type ProductlistContextSelectorProps =
    ProductlistContextSelectorOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & ProductlistContextSelectorAsyncActions;

// ----------------
// LOCAL STATE

interface ProductlistContextSelectorState {
    submitting: boolean;
    retrieving: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    submitNewProductlistRequested: boolean;
    formResult: string | null;
    actions: FieldWrapper.OptionValue[];
    selectedAction: number;
    newProductlist: ProductlistsStore.BaseProductlist | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateSelectProductlistContextForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (formValues.action === 0) {
        errors.action = 'Select action';
    }
    else if (formValues.action && parseInt(formValues.action) === 1) {
        if (!formValues.selectedProductlistId) {
            errors.productlist = 'Select list';
        }
    }
    return errors;
}

class ProductlistContextSelector extends React.PureComponent<ProductlistContextSelectorProps, ProductlistContextSelectorState> {
 
    // ----------------
    // VARIABLES

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', 'overflow': 'visible' }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductlistContextSelectorProps, state: ProductlistContextSelectorState) {
        super(props);
        this.state = {
            submitting: false,
            retrieving: false,
            changesAccepted: false,
            changesRejected: false,
            submitNewProductlistRequested: false,
            formResult: null,
            actions: this.getActions(),
            selectedAction: 0,
            newProductlist: undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: ProductlistContextSelectorProps) => {
        if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
            this.props.onDismiss();
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        switch (parseInt(values.action)) {
            case 1:
                this.getProductlist(values.selectedProductlistId as number);
                break;
            case 2:
                if (values.selectedProductlistId) {
                    this.getProductlist(values.selectedProductlistId as number);
                }
                else {
                    this.setState({
                        submitNewProductlistRequested: true
                    });
                }
                break;
        }        
    }

    public render = () => {
        return (
            <Modal id="productlist-context-selector" isOpen={this.props.isOpen}
                onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss()} className={(this.state.retrieving || this.state.submitting) ? "disabled" : ""}>
                    Select List
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                            <Row>
                                <Col className="pl-3 pt-2 pb-2 pr-4 text-center action-bar">
                                    <Field name="action" type="text" label=""
                                        component={FieldWrapper.renderRadioButtonField}
                                        options={this.state.actions} onChange={this.onSelectAction}
                                        disabled={this.state.retrieving || this.state.submitting || this.state.changesAccepted} />
                                    <button type="submit" ref={this.submitButton}>Submit</button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="collapsible-row pl-0 pr-0">
                                    <motion.div animate={this.state.selectedAction === 1 ? "visible" : "hidden"} initial={this.state.selectedAction === 1 ? "visible" : "hidden"}
                                        variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction !== 1 ? 0 : 0.25), delay: (this.state.selectedAction === 1 ? 0.1 : 0)}}>
                                        <Container className="component-wrapper flush-bottom">
                                            <Row>
                                                <Col>
                                                    <label className="title">Available Lists</label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="productlist-finder-wrapper">
                                                    <Field name="productlist" type="text" label="" editableListsOnly={true}
                                                        component={FieldWrapper.renderProductlistFinderField}                                                        
                                                        onSelectProductlist={this.onSelectProductlist}
                                                        resetFinder={this.state.selectedAction === 1 ? true : false}
                                                        disabled={this.state.retrieving || this.state.submitting || this.state.changesAccepted} />
                                                    <Field name="selectedProductlistId" component="input" type="hidden" />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </motion.div>
                                </Col>
                            </Row>
                        </form>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0 add-productlist">
                                <motion.div animate={this.state.selectedAction === 2 ? "visible" : "hidden"} initial={this.state.selectedAction === 2 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction !== 2 ? 0 : 0.25), delay: (this.state.selectedAction === 2 ? 0.1 : 0) }}
                                    onAnimationComplete={this.onAddProductListExpand}>
                                    <Container className="component-wrapper">
                                        <AddProductlistForm submitRequested={this.state.submitNewProductlistRequested}
                                            customerAccount={this.getCurrentCustomer()}
                                            onSubmit={this.onAddProductlistFormSubmit}
                                            onSubmitFail={this.onAddProductlistFormSubmitFail}
                                            onSubmitSucceed={this.onAddProductlistFormSubmitSucceed}
                                            disabled={this.state.submitting || this.state.changesAccepted}
                                            resetForm={this.state.selectedAction === 2} />
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={this.submitSelectProductlistContextForm} disabled={this.state.retrieving || this.state.submitting || this.state.changesAccepted}>
                                    {this.state.submitting ? "Working ..." : "Apply"}
                                </Button>
                                <Button color="link" onClick={() => this.props.onDismiss()} disabled={this.state.retrieving || this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private getActions = (): FieldWrapper.OptionValue[] => {
        let actions: FieldWrapper.OptionValue[] = [];
        actions.push({ label: 'Use Existing List', value: 1 });
        actions.push({ label: 'Start New List', value: 2 });
        return actions;
    }

    private getCurrentCustomer = (): CustomersStore.CustomerAccount | null | undefined => {
        let currentCustomer: CustomersStore.CustomerAccount | null | undefined = this.props.customersState.customerDetail;
        if (!currentCustomer && this.props.ordersState.orderDetail) {
            currentCustomer = this.props.ordersState.orderDetail.customerAccount;
        }
        return currentCustomer;
    }

    private getProductlist = (productlistId: number): void => {
        if (this.props.productlistsState.productlistDetail && this.props.productlistsState.productlistDetail.id !== productlistId) {
            this.setState({
                changesAccepted: false,
                changesRejected: false,
                formResult: null,
                submitting: true
            });
            this.props.asyncActions.requestProductlistDetailAsync(this.props.client, productlistId)
                .then(result => {
                    this.setState({
                        submitting: false,
                        changesAccepted: true,
                        formResult: 'Current list changed'
                    });
                    setTimeout(() => {
                        this.props.onDismiss();
                    }, 1000);
                },
                err => {
                });
        }
        else {
            this.props.onDismiss();
        }
    }

    private initializeForm = (): void => {
        this.setState({
            actions: this.getActions(),
            selectedAction: 0
        });
        this.props.change("action", 0);
    }

    onAddProductlistFormChange = (): void => {
        this.setState({
            formResult: null
        });
    }

    onAddProductlistFormSubmit = (submitting: boolean): void => {
        this.setState({
            formResult: null,
            submitNewProductlistRequested: false,
            submitting: submitting
        });
    }

    onAddProductlistFormSubmitFail = (): void => {
        this.setState({
            formResult: null,
            submitNewProductlistRequested: false
        });
    }

    onAddProductlistFormSubmitSucceed = (newList: ProductlistsStore.BaseProductlist): void => {
        this.setState({
            newProductlist: newList,            
        });
        this.props.change('selectedProductlistId', newList.id as number);
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        });
    }

    private onAddProductListExpand = (): void => {
        setTimeout(() => {
            $('.collapsible-row.add-productlist').css({ 'overflow': this.state.selectedAction === 2 ? 'visible' : 'hidden' });
        }, 400);
    }

    private onSelectAction = (event: React.ChangeEvent): void => {
        let selectedValue = event.target.getAttribute("value");
        if (selectedValue && parseInt(selectedValue) != this.state.selectedAction) {
            this.setState({
                selectedAction: parseInt(selectedValue)
            });
            this.props.reset();
            switch (parseInt(selectedValue)) {
                case 1:
                    this.setFocusOnProductlistFinder();
                    break;
                case 2:
                    this.setFocusOnAddProductlistForm();
                    break;
            }
        }
    }

    private onSelectProductlist = (productlist: ProductlistName | null): void => {
        this.props.change('selectedProductlistId', productlist ? parseInt(productlist.number) : null);
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private resetForm = (): void => {
        this.setState({
            submitting: false,
            retrieving: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            selectedAction: 0
        });
        this.props.reset();
    }

    private setFocusOnAddProductlistForm = (): void => {
        let productlistNameInput: JQuery<Element> = $('#add-productlist-form :text[name="name"]');
        if (productlistNameInput.length > 0) {
            setTimeout(() => {
                productlistNameInput.focus();
            });
        }
        else if (this.state.selectedAction === 2) {
            setTimeout(() => {
                this.setFocusOnAddProductlistForm();
            }, 250);
        }
    }

    private setFocusOnProductlistFinder = (): void => {
        let productlistFinder: JQuery<Element> = $('#productlist-finder .rbt-input');
        if (productlistFinder.length > 0) {
            setTimeout(() => {
                productlistFinder.focus();
            }, 250);
        }
        else if (this.state.selectedAction === 1) {
            setTimeout(() => {
                this.setFocusOnProductlistFinder();
            }, 250);
        }
    }

    private submitSelectProductlistContextForm = (): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        customersState: state.customers,
        ordersState: state.orders,
        productlistsState: state.productlists
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            ProductlistsStore.actionCreators,
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestProductlistDetailAsync: (client: Client, id: number) =>
                dispatch(ProductlistsStore.actionCreators.requestProductlistDetail(client, id))
        }
    };
}

export default connect<{}, {}, ProductlistContextSelectorOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'selectProductlistContextForm',
    validate: validateSelectProductlistContextForm,
    enableReinitialize: true
})(ProductlistContextSelector as any));
