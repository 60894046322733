import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as CustomersStore from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import * as UserStore from '../../store/User';
import { ClientCode } from '../../enums/ClientCode';
import { Role } from '../../enums/Role';
import { User } from '../../store/User';
import { Order } from '../../store/Orders';
import { CustomerName } from '../../common/AccountTypes';
// import { ProductDetail } from '../../store/Products';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import { Col, Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem, Row, UncontrolledDropdown } from 'reactstrap';
import * as ErrorMessage from '../../common/ErrorMessage';
import Loader from '../loader/Loader';

import './OrderPicker.scss';

// ----------------
// PROPS

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    customersState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState,
    userState: {} as UserStore.UserState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
};

interface OrderPickerOwnProps {
    activated: boolean;
    customerAccount?: CustomersStore.CustomerAccount | null | undefined;
    onRetrievingOrder?: (retrieving: boolean) => void;
    onSelectOrder: (order: Order | null | undefined) => void;
    onSubmit: (submitting: boolean) => void;
    onSubmitFail: () => void;
    submitRequested: boolean;
    disabled?: boolean;
}

type OrderPickerProps =
    OrderPickerOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;     // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface OrderPickerState {
    initialized: boolean;
    retrievingAccounts: boolean;
    retrievingOrders: boolean;
    activeAccounts: CustomerName[];
    selectedAccount: CustomerName | null | undefined;    
    openOrders: Order[] | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateOrderPickerForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (formValues.accountRequired) {
        if (!formValues.account) {
            errors.account = 'Required';
        }
        else if (!formValues.accountOrder) {
            errors.accountOrder = 'Required';
        }
    }
    
    return errors;
}

const onFormValidationFailed = (errors: any, dispatch: any, submiError: Error, props: OrderPickerOwnProps) => {
    if (props.onSubmitFail) {
        props.onSubmitFail();
    }
}

class OrderPicker extends React.PureComponent<OrderPickerProps, OrderPickerState> {

    // ----------------
    // VARIABLES

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', 'overflow': 'visible' }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderPickerProps, state: OrderPickerState) {
        super(props);
        this.state = {
            initialized: false,
            retrievingAccounts: false,
            retrievingOrders: false,
            activeAccounts: [],
            selectedAccount: undefined,
            openOrders: undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: OrderPickerProps) => {
        if ((this.props.activated && !prevProps.activated) ||
            (!this.props.activated && prevProps.activated)) {
            this.setState({
                initialized: false,
                retrievingAccounts: false,
                retrievingOrders: false,
                activeAccounts: [],
                openOrders: undefined
            });
            this.props.reset();
            this.props.onSelectOrder(undefined);
            if (this.props.activated && !prevProps.activated) {
                let x: any = this.props.customerAccount;
                this.initializeForm();
            }
        }
        if (this.props.submitRequested && !prevProps.submitRequested) {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        }
    }

    public componentWillUnmount = () => {       
    }

    public handleFormSubmit = (values: any): void => {
        this.props.onSubmit(true);
    }

    public render = () => {
        return (
            <React.Fragment>
                {/*<Loader isLoading={this.props.activated && !this.state.initialized} isChild={true} />*/}
                <Container id="order-picker">
                    <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                        <Field name="accountRequired" component="input" type="hidden" />

                        <motion.div animate={this.requiresAccountFilter() ? "visible" : "hidden"} initial={"hidden"}
                            variants={this.collapsibleRowVariants} transition={{ duration: 0.25, delay: 0.25 }}>
                            <Row>
                                <Col className="pl-0 pr-0">
                                    <label className="title">Open Orders</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pr-0 mb-0">
                                    <Field name="account" label="" prompt="Select Account ..." component={FieldWrapper.renderCustomerListField}
                                        options={this.state.activeAccounts} disabled={this.state.retrievingOrders || this.props.disabled}
                                        required={true} onSelectCustomer={this.onSelectAccount} />
                                </Col>
                            </Row>
                            <motion.div animate={this.accountHasOpenOrders() ? "visible" : "hidden"} initial={"hidden"}
                                variants={this.collapsibleRowVariants} transition={{ duration: 0.25, delay: 0.25 }}>
                                <Row>
                                    <Col className="pl-0 pr-0 mb-2">
                                        {this.state.openOrders && this.requiresAccountFilter() && (
                                            <Field name="accountOrder" label="" component={FieldWrapper.renderOrderListField}
                                                options={this.state.openOrders} autoSelect={true} required={true}
                                                disabled={this.state.retrievingOrders || this.props.disabled}
                                                onSelectOrder={this.onSelectAccountOrder} />
                                        )}
                                    </Col>
                                </Row>
                            </motion.div>
                        </motion.div>

                        <motion.div animate={(this.accountHasOpenOrders() && !this.requiresAccountFilter()) ? "visible" : "hidden"}
                            variants={this.collapsibleRowVariants} transition={{ duration: 0.25, delay: 0.25 }}>
                            <Row>
                                <Col className="pl-0 pr-0">
                                    {this.state.selectedAccount && (
                                        <label className="title">{this.state.selectedAccount.nameOnly}</label>
                                    )}
                                    {!this.state.selectedAccount && (
                                        <label className="title">Open Orders</label>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pr-0">
                                    {this.state.openOrders && !this.requiresAccountFilter() && (
                                        <Field name="userOrder" label="" component={FieldWrapper.renderOrderListField}
                                            options={this.state.openOrders} autoSelect={true} required={true}
                                            disabled={this.state.retrievingOrders || this.props.disabled}
                                            onSelectOrder={this.onSelectUserOrder} />
                                    )}
                                </Col>
                            </Row>
                        </motion.div>

                        <motion.div animate={this.accountHasNoOpenOrders() ? "visible" : "hidden"}
                            variants={this.collapsibleRowVariants} transition={{ duration: 0.25, delay: 0.25 }}>
                            <Row>
                                <Col className="pl-0 pr-0 mb-1">
                                    <div className="no-orders">
                                        {this.props.customerAccount && (
                                            <label>No open orders for <span>{this.props.customerAccount.nameOnly}</span></label>
                                        )}
                                        {!this.props.customerAccount && this.userIsSingleAccountBuyer() && (
                                            <label>No open orders for <span>{this.state.activeAccounts[0].nameOnly}</span></label>
                                        )}
                                        {!this.props.customerAccount && !this.userIsSingleAccountBuyer() && this.state.selectedAccount && (
                                            <label>No open orders for <span>{this.state.selectedAccount.nameOnly}</span></label>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </motion.div>

                        <motion.div animate={this.hasNoActiveAccounts() ? "visible" : "hidden"}
                            variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                            <Row>
                                <Col className="pl-0 pr-0 mb-1">
                                    <div className="no-orders">
                                        {/*{this.props.customerAccount && (*/}
                                        {/*    <label>No open orders for <span>{this.props.customerAccount.nameOnly }</span></label>*/}
                                        {/*)}*/}
                                        {/*{!this.props.customerAccount && (*/}
                                            <label>No open orders</label>
                                        {/*)}*/}
                                    </div>
                                </Col>
                            </Row>
                        </motion.div>

                        <button type="submit" ref={this.submitButton}>Submit</button>
                    </form>
                    {this.state.retrievingOrders && (
                        <div className="retrieval-message">Retrieving orders...</div>
                    )}
                </Container>
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private accountHasNoOpenOrders = (): boolean => {
        return (this.state.openOrders && this.state.openOrders.length === 0) ? true : false;
    }

    private accountHasOpenOrders = (): boolean => {
        return (this.state.openOrders && this.state.openOrders.length > 0) ? true : false;
    }

    private convertCustomerAccountToName = (customerAccount: CustomersStore.CustomerAccount): CustomerName => {
        return {
            address: customerAccount.shipToLocations[0].address,
            billto: customerAccount.billto,
            billto_shipto: this.getAccountNumber(customerAccount),
            name: customerAccount.name,
            nameOnly: customerAccount.nameOnly,
            number: customerAccount.number,
            shipto: customerAccount.shipToLocations[0].shipto || '',
            locationDescription: customerAccount.shipToLocations[0].locationDescription
        };
    }

    private customerHasOpenOrder = (customer: CustomersStore.CustomerAccount | null | undefined, customersWithOrders: CustomerName[]): CustomerName | null => {
        let match: CustomerName | null = null;
        if (customer) {
            let matches: CustomerName[] = customersWithOrders.filter((customerName: CustomerName) => {
                return customerName.number === customer.billto;
            });
            match = matches.length > 0 ? matches[0] : null;
        }
        else if (customersWithOrders.length === 1) {
            match = customersWithOrders[0];
        }
        return match;
    }

    private getAccountNumber = (account: CustomersStore.CustomerAccount): string => {
        let accountNumber: string = account.billto;
        if (account.shipToLocations[0].shipto) {
            accountNumber = accountNumber + "-" + account.shipToLocations[0].shipto;
        }
        return accountNumber;
    }

    private getOrderAccounts = (clientCode: ClientCode): void => {
        if (!this.state.retrievingAccounts) {
            this.props.actions.clearMessage();
            this.setState({
                retrievingAccounts: true
            });
            let fetchError: boolean = false;
            let action: string = 'customers/getactivecustomers';
            let url: string = `${action}/${clientCode}`;

            fetch(url,
                {
                    method: 'GET',
                    headers: { 'Accept': 'application/json' }
                })
                .then(response => {
                    if (response.status != 200) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                this.props.actions.broadcastMessage(errorMessage);
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    let result: CustomerName[] = data as CustomerName[];                    
                    this.setState({
                        initialized: true,
                        activeAccounts: result.length > 0 ? result : []
                    });
                    let preSelectedName: CustomerName | null = this.customerHasOpenOrder(this.props.customerAccount, result);
                    if (preSelectedName) {
                        this.props.change("account", preSelectedName);
                        setTimeout(() => {
                            if (preSelectedName) {
                                this.onSelectAccount(preSelectedName);
                            }
                        });
                    }
                },
                err => {
                    this.setState({
                        initialized: true,
                        retrievingAccounts: false
                    });
                    if (!fetchError) {
                        this.props.actions.broadcastMessage(ErrorMessage.getFromError(err, action));
                    }
                }
            )
        }
    }

    private getOrders = (client: ClientStore.Client, customerName: CustomerName): void => {
        if (!this.state.retrievingOrders) {
            this.props.actions.clearMessage();
            this.setState({
                retrievingOrders: true
            });
            if (this.props.onRetrievingOrder) {
                this.props.onRetrievingOrder(true);
            }
            let fetchError: boolean = false;
            let action: string = 'orders/getopen';
            let url: string = `${action}/${encodeURIComponent(customerName.number)}`;

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(client)
                })
                .then(response => {
                    if (response.status != 200) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                this.props.actions.broadcastMessage(errorMessage);
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    let result: Order[] = data as Order[];
                    this.setState({
                        initialized: true,
                        retrievingOrders: false,
                        selectedAccount: customerName,
                        openOrders: result
                    });
                    if (this.props.onRetrievingOrder) {
                        this.props.onRetrievingOrder(false);
                    }
                },
                err => {
                    this.setState({
                        initialized: true,
                        retrievingOrders: false
                    });
                    if (!fetchError) {
                        this.props.actions.broadcastMessage(ErrorMessage.getFromError(err, action));
                    }
                    if (this.props.onRetrievingOrder) {
                        this.props.onRetrievingOrder(false);
                    }
                }
            )
        }
    }

    private hasNoActiveAccounts = (): boolean => {
        //if (this.props.userState.user && this.props.userState.user.role === Role.Buyer) {
        //    return false;
        //}
        //else {
            return (this.state.activeAccounts.length == 0 && this.state.initialized && !this.state.retrievingAccounts) ? true : false;
        //}
    }

    private initializeForm = (): void => {
        if (this.props.clientState.client && this.props.userState.user) {
            if (this.userIsSingleAccountBuyer()) {
                let customerName: CustomerName = this.props.userState.user.buyerAccounts[0];
                this.props.change("accountRequired", false);
                this.setState({
                    activeAccounts: [customerName]
                });
                this.getOrders(this.props.clientState.client, customerName);
            }
            else if (this.props.customerAccount) {
                this.props.change("accountRequired", false);
                let accountCustomerName: CustomerName = this.convertCustomerAccountToName(this.props.customerAccount);
                this.setState({
                    activeAccounts: [accountCustomerName]
                });
                this.getOrders(this.props.clientState.client, accountCustomerName);
            }
            else {
                this.props.change("accountRequired", true);
                this.getOrderAccounts(this.props.clientState.client.code);
            }
        }
    }    

    private onSelectAccount = (customerName: CustomerName): void => {
        this.props.change("account", customerName);        
        if (this.state.activeAccounts) {
            let matches: CustomerName[] = this.state.activeAccounts.filter((activeAccount: CustomerName) => {
                return activeAccount.number === customerName.number;
            });
            setTimeout(() => {
                if (this.props.clientState.client) {
                    this.getOrders(this.props.clientState.client, matches[0])
                }
            })
        }
    }

    private onSelectAccountOrder = (order: Order | null | undefined): void => {        
        this.props.onSelectOrder(order);
    }

    private onSelectUserOrder = (order: Order | null | undefined): void => {
        this.props.onSelectOrder(order);
    }

    private requiresAccountFilter = (): boolean => {
        return (this.state.activeAccounts && this.state.activeAccounts.length > 1) ? true : false;
    }

    private userIsBuyer = (): boolean => {
        let isBuyer: boolean = false;
        if (this.props.userState.user) {
            isBuyer = this.props.userState.user.role === Role.Buyer;
        }
        return isBuyer;
    }

    private userIsMultipleAccountBuyer = (): boolean => {
        let isMultipleAccountBuyer: boolean = this.userIsBuyer();
        if (isMultipleAccountBuyer && this.props.userState.user) {
            isMultipleAccountBuyer = this.props.userState.user.buyerAccounts.length > 1;
        }
        return isMultipleAccountBuyer;
    }

    private userIsSingleAccountBuyer = (): boolean => {
        let isSingleAccountBuyer: boolean = this.userIsBuyer();
        if (isSingleAccountBuyer && this.props.userState.user) {
            isSingleAccountBuyer = this.props.userState.user.buyerAccounts.length === 1;
        }
        return isSingleAccountBuyer;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        customersState: state.customers,
        messageState: state.message,
        ordersState: state.orders,
        userState: state.user,
        initialValues: {
            customerNumber: (state.user.user && state.user.user.role === Role.Buyer) ? state.user.user.customerNumber : null
        }
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators
        ), dispatch)
    };
}

export default connect<{}, {}, OrderPickerOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'orderPickerForm',
    validate: validateOrderPickerForm,
    onSubmitFail: onFormValidationFailed,
    enableReinitialize: true
})(OrderPicker as any));
