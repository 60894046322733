﻿import { Message, MessageType } from '../store/Message';

// ----------------- 
// METHODS

export const getFromError = (error: Error, action: string, useErrorMessage: boolean = false): Message => {
    let text: string = useErrorMessage ? error.message : 'Error processing response';
    let messageType: MessageType = MessageType.ERROR;
    let reference: string | null = null;

    // Note: should not receive this if request header accepts only application/json
    if (error.message.indexOf('Unexpected token <') >= 0) {
        text = 'Invalid format returned';
    }

    return { messageType: messageType, text: text, action: action, reference: reference};
}

export const getFromResponse = (response: Response, action: string): Promise<Message> => {
    return new Promise<Message>((resolve, reject) => {

        let text: string = 'Error performing action';
        let messageType: MessageType = MessageType.ERROR;
        let reference: string | null = null;

        let contentType: string | null = response.headers.get('content-type');
        let isJson: boolean = ((contentType || '').includes('application/json'));

        if (response.status == 401) {
            messageType = MessageType.UNAUTHORIZED;
            text = "Not authorized to perform this action";
            resolve({ messageType: messageType, text: text, action: action, reference: reference });
        }
        else if (response.status == 400 && isJson) {
            messageType = MessageType.VALIDATIONERROR;
            response.json().then(
                (errorObject => {
                    text = errorObject.message;
                    resolve({ messageType: messageType, text: text, action: action, reference: reference });
                })
            );
        }
        else if ((response.status == 500 || response.status == 404) && isJson) {
            messageType = response.status == 404 ? MessageType.WARNING : MessageType.ERROR;
            response.json().then(
                (errorObject => {
                    text = errorObject.message;
                    resolve({ messageType: messageType, text: text, action: action, reference: reference });
                })
            );
        }       
        else {
           switch (response.status) {
                case 403:
                    text = 'Permission required';
                    break;
                case 404:
                    text = 'Resource not found';
                    break;
                case 405:
                    text = `$(method || '') method not supported`;
                    break;
                case 407:
                    text = 'Proxy authentication required';
                    break;
                case 408:
                    text = 'Request timed out';
                    break;
                case 500:
                    text = "Server error";
                    break;
                default:
                    text = `HTTP error ${response.status}`;
                    break;
            }
            resolve({ messageType: messageType, text: text, action: action, reference: reference })
        }
    })
}


