import * as React from 'react';
import { Client } from '../../store/Client';
import { Order } from '../../store/Orders';
import { ClientCode } from '../../enums/ClientCode';
import { convertSQLDateToString } from '../../common/DateConverter';

import './OrderCard.scss';

// ----------------
// PROPS

interface OrderCardProps {
    client: Client | null;
    data: Order;
    hideCustomerName?: boolean | undefined;
}

class OrderCard extends React.PureComponent<OrderCardProps> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderCardProps) {
        super(props);
    }

    // ----------------
    // METHODS

    public render = () => {
        return (
            <div className="order-card">
                <div className="id-column">
                    <label>{this.getOrderNumber()}</label>
                    <label>{this.getOrderStatus()}</label>
                </div>
                {!this.props.hideCustomerName && (
                    <div>{this.props.data.customerNameOnly}</div>
                )}
                {this.props.data.poNumber && (
                    <div>PO: {this.props.data.poNumber}</div>
                )}
                <div className="date-column first">
                    <label>Started</label>
                    <label>{this.getDateString(this.props.data.dateCreated)}</label>
                </div>
                <div className="date-column">
                    <label>Ship Date</label>
                    <label>{this.getDateString(this.props.data.shipDate)}</label>
                </div>
                {this.props.data.dateSubmitted && (
                    <div className="date-column">
                        <label>Submitted</label>
                        <label>{this.getDateString(this.props.data.dateSubmitted)}</label>
                    </div>
                )}
            </div>
        )
    }

    // ----------------
    // HELPERS

    private getDateString = (date: Date | null | undefined) => {
        let dateString: string = date ? convertSQLDateToString(date, "M/d/yyyy") : 'not available';
        return dateString;
    }

    private getOrderNumber = (): string => {
        let orderNumber: string = this.props.data.orderNumber ? this.props.data.orderNumber : '';
        //if (this.props.data.orderNumberERP) {
        //    if (this.props.data.orderNumber) {
        //        orderNumber += " | ";
        //    }
        //    orderNumber += this.props.data.orderNumberERP;
        //}
        return orderNumber;
    }

    private getOrderStatus = (): string => {
        let orderStatus: string = this.props.data.orderStatus ? this.props.data.orderStatus : '';
        return orderStatus;
    }
}

export default OrderCard;
