import * as React from "react";
import { Route, Redirect } from 'react-router';

// ----------------
// PROPS
interface PrivateRouteProps {
    isAuthenticated: boolean,
    path: string;
    component: React.ComponentType<any>;
}

class PrivateRoute extends React.PureComponent<PrivateRouteProps> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: PrivateRouteProps) {
        super(props);
    }

    // ----------------
    // METHODS

    public render = () => {
        return (
            <React.Fragment>
                {this.props.isAuthenticated && (
                    <Route exact path={this.props.path} component={this.props.component} />
                )}
                {!this.props.isAuthenticated && (
                    <Route path={this.props.path}>
                        <Redirect to="/login" />
                    </Route>
                )}
            </React.Fragment>
        );
    }
}

// ----------------
// EXPORT

export default PrivateRoute;
