import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import { BaseProductlist, Productlist, ProductlistHistoryEntry } from '../../store/Productlists';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { Message } from '../../store/Message';
import * as ErrorMessage from '../../common/ErrorMessage';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row, Table } from 'reactstrap';
import Loader from '../loader/Loader';

import './ProductlistHistory.scss'

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
};

interface ProductlistHistoryOwnProps {
    client: Client | null;
    isOpen: boolean;
    productlist: BaseProductlist | null | undefined;
    onDismiss: () => void;
}

type ProductlistHistoryProps =
    ProductlistHistoryOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;     // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface ProductlistHistoryState {
    isLoading: boolean;
    entries: ProductlistHistoryEntry[] | undefined;
    sortDescending: boolean;
}

class ProductlistHistory extends React.PureComponent<ProductlistHistoryProps, ProductlistHistoryState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductlistHistoryProps, state: ProductlistHistoryState) {
        super(props);
        this.state = {
            isLoading: false,
            entries: undefined,
            sortDescending: true
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: ProductlistHistoryProps) => {
        if (this.state.isLoading && this.receivedErrorMessage()) {
            this.setState({
                isLoading: false,
                entries: undefined
            })
            this.props.onDismiss();
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <Modal id="productlist-history" isOpen={this.props.isOpen} onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss()}>
                    { this.props.productlist ? 'Product List ' + this.props.productlist.id : '' }
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Loader isLoading={this.state.isLoading} isChild={true} />
                        {this.props.productlist && (
                            <Row>
                                <Col className="title-bar pl-0 pr-0">
                                    <label>{this.props.productlist.name}</label>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col className="pl-0 pr-0">
                                <AnimatePresence>
                                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.5 }}>
                                        <Table size="sm" borderless>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <a href="#" className={"sortable-header" + (this.state.isLoading || !this.hasEntries() ? " disabled" : "")}
                                                            onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.toggleSortOrder(e)} >
                                                            <div>Date</div>
                                                            {this.hasEntries() && (
                                                                <svg className={this.state.sortDescending ? "desc" : "asc"} focusable="false" viewBox="0 0 24 24" fill="currentColor">
                                                                    <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
                                                                </svg>
                                                            )}
                                                        </a>
                                                    </th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.entries && this.state.entries.length === 0 && (
                                                    <tr className="no-entries">
                                                        <td colSpan={2}>No activity recorded</td>
                                                    </tr>
                                                )}
                                                {this.state.entries && this.state.entries.length > 0 && this.state.entries.map((entry: ProductlistHistoryEntry) => (
                                                    <tr>    
                                                        <td>{entry.date}</td>
                                                        <td>
                                                            <label>{entry.userName}</label>
                                                            <div className="action-text">{entry.action}</div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </motion.div>
                                </AnimatePresence>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={this.props.onDismiss}>
                                    OK
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private getProductlistHistory = (sortDescending: boolean): void => {
        if (this.props.productlist && this.props.client) {
            this.props.actions.clearMessage();
            this.setState({
                isLoading: true,
                sortDescending: sortDescending
            });
            let fetchError: boolean = false;
            let action: string = 'productlists/gethistory';
            let url: string = `${action}/${this.props.productlist.id}/${sortDescending ? 'true' : 'false'}`;

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(this.props.client)
                })
                .then(response => {
                    if (response.status != 200) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                this.props.actions.broadcastMessage(errorMessage);
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    let result: ProductlistHistoryEntry[] = data as ProductlistHistoryEntry[];
                    this.setState({
                        isLoading: false,
                        entries: result
                    });
                },
                err => {
                    if (!fetchError) {
                        this.props.actions.broadcastMessage(ErrorMessage.getFromError(err, action));
                    }
                }
            )
        }
    }

    private hasEntries = (): boolean => {
        return this.state.entries && this.state.entries.length > 0 ? true : false;
    }

    private initializeForm = (): void => {
        if (this.props.productlist) {
            this.getProductlistHistory(true);
        }
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private resetForm = (): void => {
        this.setState({
            isLoading: false,
            entries: undefined,
            sortDescending: true
        })
    }

    private toggleSortOrder = (event: React.MouseEvent | React.KeyboardEvent): void => {
        event.preventDefault();
        if (this.hasEntries()) {
            let sortDescending: boolean = this.state.sortDescending ? false : true;
            this.getProductlistHistory(sortDescending);
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators
        ), dispatch)
    };
}

export default connect<{}, {}, ProductlistHistoryOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ProductlistHistory as any);