import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import * as CustomersStore from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import * as TapeSelectionStore from '../../store/TapeSelection';
import { Logo } from '../../common/EmbroideryTypes';
import { Button, Container, Col, Row } from 'reactstrap';
import MaterialTable, { Column, Filter, Options, Query, QueryResult, MTableBody, MTableToolbar } from 'material-table';
import { LabelDisplayedRowsArgs } from '@material-ui/core';
import { BaseTableFilter } from '../../common/BaseTypes';
import { FilterEx } from '../../common/ExtendedTypes';
import TableFilter from '../table-filter/TableFilter';
import * as TableIcons from '../table-icons/TableIcons';
import Loader from '../loader/Loader';
import TapeDescription from '../tape-description/TapeDescription';

import './TapeSelector.scss';
import { doesNotReject } from 'assert';

// ----------------
// PROPS

const applicationState = {
    customersState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, TapeSelectionStore.actionCreators)
}

interface TapeSelectorAsyncActions {
    asyncActions: {
        requestCustomerLogosAsync: (clientCode: ClientCode, billto: string, shipto: string, query: Query<any>, broadcastError: boolean) => Promise<QueryResult<any>>;
    }
}

interface TapeSelectorOwnProps {
    isOpen: boolean;
    client: Client;
    customerAccount: CustomersStore.CustomerAccount;
    onSelect: (logo: Logo |undefined) => void;
}

type TapeSelectorProps =
    TapeSelectorOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & TapeSelectorAsyncActions;

// ----------------
// LOCAL STATE

interface TapeSelectorState {
    isVisible: boolean;
    isTableInitialized: boolean;
    isTableLoading: boolean;
    isTableError: boolean;
    isInitialImageLoaded: boolean;
    columns: any[];
    operators: FilterEx[];
    options: Options<any>;
    rowCount: number | undefined;
    total: number | undefined;
    useStoredQuery: boolean;
    selectedLogo: string | undefined;
    noResultsMessage: string;
}

class TapeSelector extends React.PureComponent<TapeSelectorProps, TapeSelectorState> {

    // ----------------
    // VARIABLES

    public tableElement: any;

    public tapeSelectorVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: TapeSelectorProps, state: TapeSelectorState) {
        super(props);
        this.state = {
            isVisible: false,
            isTableInitialized: false,
            isTableLoading: false,
            isTableError: false,
            isInitialImageLoaded: false,
            columns: this.getColumnDefinitions(),
            operators: [],
            options: {
                debounceInterval: 500,
                filtering: true,
                initialPage: 0,
                pageSize: 2,
                pageSizeOptions: [],
                search: false,
                showTitle: false,
                toolbar: false,
                thirdSortClick: false
            },
            rowCount: undefined,
            total: undefined,
            useStoredQuery: false,
            selectedLogo: undefined,
            noResultsMessage: 'No logos found'
        };
        this.tableElement = React.createRef();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: TapeSelectorProps) => {
        if (this.props.isOpen && !prevProps.isOpen) {
            this.initializePopup();
        }
        else if (prevProps.isOpen && !this.props.isOpen) {
            setTimeout(() => {
                this.resetPopup();
            }, 400);
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <div id="tape-selector">
                <Loader isLoading={this.state.isTableLoading || !this.state.isTableInitialized || !this.state.isInitialImageLoaded || !this.state.isVisible} isChild={true} />
                <motion.div animate={(this.state.isVisible && this.state.isInitialImageLoaded) ? "visible" : "hidden"} initial={"hidden"} variants={this.tapeSelectorVariants} transition={{ duration: 0.75, delay: 0.25 }}>
                    <Container>
                        <Row className={"table-container" + (this.state.isTableError ? " load-error" : "") + (this.state.isTableLoading || !this.state.isTableInitialized ? " loading" : "") + (this.state.isTableInitialized ? " initialized" : "")}>
                            <Col className="pl-0 pr-0">
                                <MaterialTable
                                    tableRef={this.tableElement}
                                    columns={this.state.columns}
                                    data={this.getLogos}
                                    icons={TableIcons.icons}
                                    options={this.state.options}
                                    components={{
                                        OverlayError: props => (
                                            <span>x</span>
                                        ),
                                        OverlayLoading: props => (
                                            <span></span>
                                        ),
                                        Cell: props => (
                                            <React.Fragment>
                                                {props.columnDef.tableData.id === 0 && (
                                                    <td className="MuiTableCell-root MuiTableCell-body multi-span" colSpan={this.state.columns.length}>
                                                        <TapeDescription logo={props.rowData} onSelect={this.onSelect} onImageLoad={this.onImageLoad}
                                                            selected={props.rowData.tapeNumber === this.state.selectedLogo ? true : false}
                                                            isLoading={this.state.isTableLoading} />
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        )
                                    }}
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: this.state.rowCount === 0 ? this.state.noResultsMessage : ''
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private getColumnDefinitions = (): any[] => {
        let columns: any[] = [];
        switch (this.props.client.code) {
            case ClientCode.GTN:
            default:
                columns = this.getDefaultColumnDefinitions();
                break;
        }
        return columns;
    }

    private getDefaultColumnDefinitions = (): any => {
        return [
            {
                title: "Tape Number",
                field: "tapeNumber",
                type: "string",
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType="string"
                        initialValue={null} onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Description",
                field: "description",
                type: "string",
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType="string"
                        initialValue={null} onFilterChanged={props.onFilterChanged} />
            }
        ];
    }

    private getFilterOperator = (field: string | number | symbol | undefined): string | undefined => {
        let filterOperator: string | undefined = undefined;
        let index: number = this.state.operators.findIndex(f => f.column.field === field);
        if (index >= 0) {
            filterOperator = this.state.operators[index].operatorOverride;
        }
        return filterOperator;
    }

    private getLogos = (query: Query<any>): Promise<QueryResult<any>> => {

        return new Promise((resolve, reject) => {
            if (!this.state.isVisible) {
                resolve({
                    data: [],
                    page: 0,
                    totalCount: 0
                });
            }
            else {
                this.showTableLoader(true, this.state.isTableInitialized, undefined, this.state.total);
                let mergedQuery: Query<any> = this.mergeQuery(query);
                this.props.asyncActions.requestCustomerLogosAsync(this.props.client.code, this.props.customerAccount.billto, this.props.customerAccount.shipToLocations[0].shipto || '', mergedQuery, false)
                    .then(result => {
                        resolve(result);
                        this.showTableLoader(false, true, result.data.length, result.totalCount);
                    },
                    err => {
                        this.showTableReloader(err);
                        resolve({
                            data: [],
                            page: 0,
                            totalCount: 0
                        });
                    })
            }
        });
    }

    private initializePopup = (): void => {
        this.props.actions.tapeSelected(false);
        this.setState({
            isVisible: false,
            isTableInitialized: false,
            rowCount: undefined,
            total: undefined,
            selectedLogo: undefined
        });
        setTimeout(() => {
            this.setState({
                isVisible: true
            });
            this.reloadTable();
        }, 800);
    }

    private mergeQuery = (query: Query<any>): Query<any> => {
        if (!this.state.isTableInitialized) {
            query.page = 0;
            query.totalCount = 0;
        }
        return query;
    }

    private onImageLoad = (): void => {
        if (!this.state.isInitialImageLoaded) {
            this.setState({
                isInitialImageLoaded: true
            });
        }
    }

    private onSelect = (logo: Logo): void => {
        this.props.actions.tapeSelected(true);
        setTimeout(() => {
            this.props.onSelect(logo);
        }, 400);
    }

    private reloadTable = () => {
        this.props.actions.clearMessage();
        this.tableElement.current.onQueryChange();
    }

    private resetPopup = () => {
        this.setState({
            isVisible: false
        })
    }

    private showTableLoader = (active: boolean, initialized: boolean, rowCount: number | undefined, total: number | undefined): void => {
        if (this.state.isTableLoading != active) {
            let noResultsMessage: string = (!this.state.isTableInitialized && initialized && rowCount === 0) ?
                ('No logos found for ' + this.props.customerAccount.nameOnly) : this.state.noResultsMessage;            
            setTimeout(() => {
                this.setState({
                    isTableInitialized: initialized ? true : false,
                    isTableLoading: active ? true : false,
                    isTableError: false,
                    isInitialImageLoaded: (rowCount === 0 ? true : this.state.isInitialImageLoaded),
                    rowCount: rowCount,
                    total: total,
                    noResultsMessage: noResultsMessage
                });
            });
        }
    }

    private showTableReloader = (errorMessage: string): void => {
        setTimeout(() => {
            this.setState({
                isTableInitialized: true,
                isTableLoading: false,
                isTableError: true
            });
        }, 500);
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        customersState: state.customers,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            CustomersStore.actionCreators,
            MessageStore.actionCreators,
            TapeSelectionStore.actionCreators), dispatch),
        asyncActions: {
            requestCustomerLogosAsync: (clientCode: ClientCode, billto: string, shipto: string, query: Query<any>, broadcastError: boolean) => dispatch(CustomersStore.actionCreators.requestCustomerLogos(clientCode, billto, shipto, query, broadcastError))
        }
    };
}

export default connect<{}, {}, TapeSelectorOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(TapeSelector as any);
