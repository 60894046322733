import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as OrderDatesStore from '../../store/OrderDates';
import * as OrdersStore from '../../store/Orders';
import * as UserStore from '../../store/User';
import { Role } from '../../enums/Role';
import { Button, Col, Container, ListGroup, ListGroupItem, Row } from 'reactstrap';
import AddOrder from '../add-order/AddOrder';
import FindOrder from '../find-order/FindOrder';
import FindCustomer from '../find-customer/FindCustomer';
import FindProduct from '../find-product/FindProduct';
import Loader from '../loader/Loader';

import './Dashboard.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    orderDatesState: {} as OrderDatesStore.OrderDatesState,
    ordersState: {} as OrdersStore.OrdersState,
    userState: {} as UserStore.UserState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrderDatesStore.actionCreators, OrdersStore.actionCreators, UserStore.actionCreators )
};

type DashboardProps =
    RouteComponentProps
    & typeof applicationState     // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface DashboardState {
    isVisible: boolean;
    isBuyer: boolean;
    addingOrder: boolean;
    findingOrder: boolean;
    findingCustomer: boolean;
    findingProduct: boolean;
}

class Dashboard extends React.PureComponent<DashboardProps, DashboardState> {

    // ----------------
    // VARIABLES

    public dashboardVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: DashboardProps, state: DashboardState) {
        super(props);
        this.state = {
            isVisible: (this.props.clientState.client && this.props.userState.user && this.props.orderDatesState.orderDates) ? true : false,
            isBuyer: this.userInRole(Role.Buyer),
            addingOrder: false,
            findingOrder: false,
            findingCustomer: false,
            findingProduct: false
        };
        this.ensureDataFetched();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
        if (this.props.clientState.client && this.props.userState && this.props.orderDatesState.orderDates) {
            if (!this.state.isVisible) {
                this.setState({
                    isVisible: true,
                    isBuyer: this.userInRole(Role.Buyer)
                });
            }
        }
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={!this.state.isVisible} />
                <motion.nav id="dashboardPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"} variants={this.dashboardVariants} transition={{ duration: 0.75 }} >
                    <Container>
                        <Row className="header">
                            <Col className="pl-0 mb-4 greeting" sm={8} xs={12}>
                                <h2>Welcome {this.getUserFullName()}</h2>
                                <div className="alerts-inline">                                    
                                </div>
                            </Col>
                            <Col className="pr-0 mb-4 alerts" sm={4} xs={0}>                                
                            </Col>
                        </Row>
                        <Row className="body">
                            <Col className="pl-0 pr-0">
                                <Container>
                                    <Row>
                                        <Col className="pl-0 pr-0 pr-lg-4 column-left" sm={12} md={8}>
                                            <Container className="summary">
                                                <Row>
                                                    <Col className="pl-0 pr-0 mb-4 shortcuts" xs={12} sm={6}>
                                                        <div className="info-panel flush-all">
                                                            <div className="title-bar">
                                                                <label>Shortcuts</label>
                                                            </div>
                                                            <div className="content">
                                                                <ListGroup>                                                                    
                                                                    <ListGroupItem tag="button" action className="start-order" onClick={this.startOrder} title={"Start Order"}>
                                                                        <div className="icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H9M15 5H17C18.1046 5 19 5.89543 19 7V9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                <path d="M14.902 20.3343L12.7153 20.7716L13.1526 18.585C13.1914 18.3914 13.2865 18.2136 13.4261 18.074L17.5 14L19.5 12L21.4869 13.9869L19.4869 15.9869L15.413 20.0608C15.2734 20.2004 15.0956 20.2956 14.902 20.3343Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                        <label>START ORDER</label>
                                                                    </ListGroupItem>
                                                                    <ListGroupItem tag="button" action className="find-order" onClick={this.findOrder}>
                                                                        <div className="icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24">
                                                                                <g transform="matrix(-1,0,0,1,22,0)" fill="currentColor">
                                                                                    <path d="m 13.75,2 c 1.102762,0 2.020308,0.7933968 2.212827,1.8405339 L 15.9862,3.99944 17.75,4 c 1.190825,0 2.165642,0.9251622 2.244809,2.0959513 L 20,6.25 v 13.5 c 0,1.190825 -0.925199,2.165642 -2.095956,2.244809 L 17.75,22 h -3.9184 c 0.199556,-0.4208 0.221728,-0.904652 0.06652,-1.339463 L 13.8316,20.5 H 17.75 c 0.379683,0 0.693489,-0.282165 0.743153,-0.648232 L 18.5,19.75 V 6.25 C 18.5,5.8703075 18.217835,5.5565096 17.851768,5.5068467 L 17.75,5.5 15.621,5.50016 C 15.248446,6.0566923 14.634075,6.437715 13.928269,6.4930408 L 13.75,6.5 h -3.5 C 9.5299723,6.5 8.8889572,6.161813 8.4771183,5.6355871 L 8.37902,5.5002 6.25,5.5 C 5.8703075,5.5 5.5565096,5.7821569 5.5068467,6.1482302 L 5.5,6.25 V 9.59069 C 5.07556,9.66865 4.6681632,9.7953396 4.2838307,9.9647377 L 4,10.0997 V 6.25 C 4,5.0591367 4.9251622,4.0843551 6.0959513,4.0051908 L 6.25,4 8.01379,3.99944 C 8.1324757,2.9282781 9.003107,2.0838736 10.086036,2.005883 L 10.25,2 Z M 6.5,10.5 c 2.48528,0 4.5,2.0147 4.5,4.5 0,1.015292 -0.336227,1.952024 -0.903436,2.705035 L 9.95001,17.8894 12.7803,20.7197 c 0.2929,0.2929 0.2929,0.7677 0,1.0606 -0.266273,0.266273 -0.682876,0.290479 -0.976482,0.07262 L 11.7197,21.7803 8.80499,18.8656 C 8.13082,19.2685 7.34245,19.5 6.5,19.5 4.01472,19.5 2,17.4853 2,15 2,12.5147 4.01472,10.5 6.5,10.5 Z m 0,1.5 c -1.65685,0 -3,1.3431 -3,3 0,1.6569 1.34315,3 3,3 1.65685,0 3,-1.3431 3,-3 0,-1.6569 -1.34315,-3 -3,-3 z m 7.25,-8.5 h -3.5 C 9.83579,3.5 9.5,3.83579 9.5,4.25 c 0,0.3796925 0.2821569,0.6934904 0.64823,0.7431533 L 10.25,5 h 3.5 C 14.1642,5 14.5,4.66421 14.5,4.25 14.5,3.8703075 14.217835,3.5565096 13.851768,3.5068467 Z" />
                                                                                </g>
                                                                            </svg>
                                                                        </div>
                                                                        <label>Find Order</label>
                                                                    </ListGroupItem>
                                                                    {!this.state.isBuyer && (
                                                                        <ListGroupItem tag="button" action className="find-customer" onClick={this.findCustomer}>
                                                                            <div className="icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" fill="currentColor">
                                                                                    <path d="M22,3H2C0.91,3.04 0.04,3.91 0,5V19C0.04,20.09 0.91,20.96 2,21H22C23.09,20.96 23.96,20.09 24,19V5C23.96,3.91 23.09,3.04 22,3M22,19H2V5H22V19M14,17V15.75C14,14.09 10.66,13.25 9,13.25C7.34,13.25 4,14.09 4,15.75V17H14M9,7A2.5,2.5 0 0,0 6.5,9.5A2.5,2.5 0 0,0 9,12A2.5,2.5 0 0,0 11.5,9.5A2.5,2.5 0 0,0 9,7M14,7V8H20V7H14M14,9V10H20V9H14M14,11V12H18V11H14" />
                                                                                </svg>
                                                                            </div>
                                                                            <label>Find Customer</label>
                                                                        </ListGroupItem>
                                                                    )}
                                                                    <ListGroupItem tag="button" action className="find-product" onClick={this.findProduct}>
                                                                        <div className="icon">
                                                                            <svg width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M314.56,48S291.78,56,256,56s-58.56-8-58.56-8a31.94,31.94,0,0,0-10.57,1.8L32,104l16.63,88,48.88,5.52A24,24,0,0,1,118.8,222.1L112,464H400l-6.8-241.9a24,24,0,0,1,21.29-24.58L463.37,192,480,104,325.13,49.8A31.94,31.94,0,0,0,314.56,48Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                                                                <path d="M333.31,52.66a80,80,0,0,1-154.62,0" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                                                            </svg>
                                                                        </div>
                                                                        <label>Find Product</label>
                                                                    </ListGroupItem>
                                                                    <ListGroupItem tag="button" action className="edit-profile" onClick={this.editProfile}>
                                                                        <div className="icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                                                                                <rect width="256" height="256" fill="none" />
                                                                                <circle cx="128" cy="128" r="96" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="20" />
                                                                                <circle cx="128" cy="120" r="40" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="20" />
                                                                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="20" d="M63.79905,199.37405a72.02812,72.02812,0,0,1,128.40177-.00026" />
                                                                            </svg>
                                                                        </div>
                                                                        <label>Edit Profile</label>                                                                        
                                                                    </ListGroupItem>
                                                                </ListGroup>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="pl-0 pr-0 pl-sm-4 mb-4 recent-activity" xs={12} sm={6}>
                                                    {/*    recent-activity*/}
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                        <Col className="pl-0 pr-0 column-right" sm={12} md={4}>
                                            <Container className="promotions">
                                               {/* promotions*/}
                                            </Container>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        {this.props.clientState.client && (
                            <React.Fragment>
                                <AddOrder isOpen={this.state.addingOrder} customerNumber={this.getCustomerNumber()} onDismiss={this.hideAddOrder} />
                                <FindOrder isOpen={this.state.findingOrder} onDismiss={this.hideFindOrder} client={this.props.clientState.client} />
                                <FindCustomer isOpen={this.state.findingCustomer} onDismiss={this.hideFindCustomer} client={this.props.clientState.client} />
                                <FindProduct isOpen={this.state.findingProduct} onDismiss={this.hideFindProduct} client={this.props.clientState.client} />
                            </React.Fragment>
                        )}
                    </Container>
                </motion.nav>
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private editProfile = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        let path: string = '/profile';
        this.props.history.push(path);
    }

    private ensureDataFetched = () => {
        if (this.props.clientState.client) {
            this.props.actions.requestOrderDates(this.props.clientState.client);
        }
        else {
            setTimeout(() => {
                this.ensureDataFetched();
            }, 250);
        }
    }

    private findCustomer = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        this.setState({
            findingCustomer: true
        });
    }

    private findOrder = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        this.setState({
            findingOrder: true
        });
    }

    private findProduct = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        this.setState({
            findingProduct: true
        });
    }

    private getCustomerNumber = (): string | null => {
        let customerNumber: string | null = null;
        if (this.props.userState.user && this.props.userState.user.role === Role.Buyer) {
            customerNumber = this.props.userState.user.customerNumber;
        }
        return customerNumber;
    }

    private getUserFullName = (): string => {
        let userFullName: string = '';
        if (this.props.userState.user) {
            userFullName = this.props.userState.user.fullName;
        }
        return userFullName;
    }

    private hideAddOrder = (id: number | null | undefined): void => {
        this.setState({
            addingOrder: false
        });
        if (id) {
            setTimeout(() => {
                let path: string = '/orders/edit/' + id + '/header';
                this.props.history.push(path);
            }, 400);
        }
    }

    private hideFindCustomer = (billto: string | null | undefined, shipto: string | null | undefined): void => {
        this.setState({
            findingCustomer: false
        });
        if (billto) {
            setTimeout(() => {
                let path: string = '/customers/edit/' + billto;
                if (shipto) {
                    path = path + '-' + shipto;
                }
                path += '/account';
                this.props.history.push(path);
            }, 400);
        }
    }

    private hideFindOrder = (id: number | null | undefined): void => {
        this.setState({
            findingOrder: false
        });
        if (id) {
            setTimeout(() => {
                let path: string = '/orders/edit/' + id + '/header';
                this.props.history.push(path);
            }, 400);
        }
    }

    private hideFindProduct = (productId: string | null | undefined): void => {
        this.setState({
            findingProduct: false
        });
        if (productId) {
            setTimeout(() => {
                let path: string = '/products/detail/' + encodeURIComponent(productId);
                this.props.history.push(path);
            }, 400);
        }
    }

    private startOrder = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        this.setState({
            addingOrder: true
        });
    }

    private userInRole = (role: Role): boolean => {
        let inRole: boolean = false;
        if (this.props.userState.user) {
            inRole = this.props.userState.user.role === role ? true : false;
        }
        return inRole;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        orderDatesState: state.orderDates,
        ordersState: state.orders,
        userState: state.user
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrderDatesStore.actionCreators,
            OrdersStore.actionCreators,
            UserStore.actionCreators),
            dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard as any);

