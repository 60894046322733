import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as CustomersStore from '../../store/Customers';
import { Address, Country, State } from '../../common/AddressTypes';
import { Button, Container, Col, Row, Table } from 'reactstrap';
import sanitizeHTML from 'sanitize-html';

import './AddressForm.scss';

// ----------------
// PROPS

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    customersState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState
}

const actionCreators = {
    actions: Object.assign({}, CustomersStore.actionCreators, MessageStore.actionCreators)
};

interface AddressFormOwnProps {
    customerDetail: CustomersStore.CustomerDetail;
    readonly: boolean;
    showLabels: boolean;    
    showCustomerNumber?: boolean;
    showPlaceholder: boolean;
    noMargin?: boolean;
    hideExtendedFields?: boolean;
}

type AddressFormProps =
    AddressFormOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface AddressFormState {
}

class AddressForm extends React.PureComponent<AddressFormProps, AddressFormState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: AddressFormProps, state: AddressFormState) {
        super(props);
        this.state = {};
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <React.Fragment>
                {this.props.readonly && (
                    <Container className={"address-form" + (this.props.noMargin ? " no-margin" : "")}>
                        <Row>
                            <Col xs={12} xl={this.props.noMargin || this.props.showLabels ? 12 : 6}>
                                <Table size="sm" borderless>
                                    <tbody>
                                        {!this.props.noMargin && this.props.showCustomerNumber && (
                                            <tr>
                                                {this.props.showLabels && (
                                                    <td className="pl-0">Number</td>
                                                )}
                                                <td>{this.props.customerDetail.number}</td>
                                            </tr>
                                        )}
                                        {this.props.customerDetail.attention && (
                                            <tr>
                                                {this.props.showLabels && (
                                                    <td className="pl-0">Attn</td>
                                                )}
                                                <td>{this.props.customerDetail.attention}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            {this.props.showLabels && (
                                                <td className="pl-0">Name</td>
                                            )}
                                            <td className="customer-name">{this.formatName(this.props.customerDetail)}</td>
                                        </tr>
                                        <tr>
                                            {this.props.showLabels && (
                                                <td className="pl-0">Address</td>
                                            )}
                                            <td className="customer-address" dangerouslySetInnerHTML={this.formatAddress(this.props.customerDetail.address)}></td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </Col>
                            {!this.props.hideExtendedFields && (
                                <Col className={"mt-xl-0 pl-3" + (this.props.noMargin || this.props.showLabels ? " mt-0" : " mt-1")} xs={12} xl={this.props.noMargin || this.props.showLabels ? 12 : 6}>
                                    <Table size="sm" borderless>
                                        <tbody>
                                            <tr>
                                                {this.props.showLabels && (
                                                    <td className={"pl-0" + (this.props.showLabels ? "" : " pl-xl-3")}>Country</td>
                                                )}
                                                <td className={"country-code" + (this.props.customerDetail.address.country.code ? " with-icon" : "")}>
                                                    {this.props.customerDetail.address.country.code && (
                                                        <span className={"fi fi-" + this.props.customerDetail.address.country.code.toLowerCase()}></span>
                                                    )}
                                                    {this.addPlaceholder((this.props.customerDetail.address.country.name || ""))}
                                                </td>
                                            </tr>
                                            <tr>
                                                {this.props.showLabels && (
                                                    <td className={"pl-0" + (this.props.showLabels ? "" : " pl-xl-3")}>Phone</td>
                                                )}
                                                <td className="phone-number">{this.addPlaceholder(this.props.customerDetail.phone)}</td>
                                            </tr>                                            
                                            {this.props.customerDetail.contact && (
                                                <tr>
                                                    {this.props.showLabels && (
                                                        <td className="pl-0 pl-xl-3">Contact</td>
                                                    )}
                                                    <td className={!this.props.customerDetail.contact ? 'placeholder' : ''}>{this.addPlaceholder(this.props.customerDetail.contact)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                            )}
                        </Row>
                    </Container>
                )}
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private addPlaceholder = (value: string | null | undefined): string => {
        return (value ? value : this.props.showPlaceholder ? 'not available' : '');
    }

    private formatAddress = (address: Address): { __html: string } => {
        let output: string = "";
        if (address.street1){
            output = output + "<label class=\"address-label\">" + address.street1 + "</label>";
        }
        if (address.street2) {
            output = output + "<label class=\"address-label\">" + address.street2 + "</label>";
        }
        if (address.street3) {
            output = output + "<label class=\"address-label\">" + address.street3 + "</label>"
        }
        if (address.city && address.state && address.postalCode) {
            output = output + "<label class=\"address-label\">" + address.city + ", " + address.state.code + " &nbsp;" + address.postalCode + "</label>";
        }
        else if (address.city && address.state) {
            output = output + "<label class=\"address-label\">" + address.city + ", " + address.state.code + "</label>";
        }
        else if (address.city && address.postalCode) {
            output = output + "<label class=\"address-label\">" + address.city + " &nbsp;" + address.postalCode + "</label>";
        }
        else if (address.state && address.postalCode) {
            output = output + "<label class=\"address-label\">" + address.state.name + " &nbsp;" + address.postalCode + "</label>";
        }
        else if (address.state) {
            output = output + "<label class=\"address-label\">" + address.state.name + "</label>";
        }
        else if (address.city) {
            output = output + "<label class=\"address-label\">" + address.city + "</label>";
        }
        return {
            __html: output
        };
    }

    private formatName = (customerDetail: CustomersStore.CustomerDetail): string => {
        let output: string = '';
        if (this.props.noMargin && this.props.showCustomerNumber) {
            output = customerDetail.number + ' - ';
        }
        output += customerDetail.nameOnly;
        return output;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        customersState: state.customers,
        messageState: state.message
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, CustomersStore.actionCreators, MessageStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, AddressFormOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(AddressForm as any);
