import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import * as OrderInvoiceStore from '../../store/OrderInvoice';
import * as OrdersStore from '../../store/Orders';
import { Button, Col, Container, Row } from 'reactstrap';
import EditOrderDates from '../edit-order-dates/EditOrderDates';
import OrderContextSelector from '../order-context-selector/OrderContextSelector';
import OrderSidebar from '../order-sidebar/OrderSidebar';
import { convertJSDateToString } from '../../common/DateConverter';
import $ from 'jquery';

import './OrderContextBar.scss';
import { Directions } from '@material-ui/icons';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    orderInvoiceState: {} as OrderInvoiceStore.OrderInvoiceState,
    ordersState: {} as OrdersStore.OrdersState
}

const actionCreators = {
    actions: Object.assign({}, OrderInvoiceStore.actionCreators, OrdersStore.actionCreators)
}

type OrderContextBarProps =
    RouteComponentProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;     // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface OrderContextBarState {
    editingOrderDates: boolean;
    isActive: boolean;
    isOrderDropdownInfoOpen: boolean;
    isOrderSelectorOpen: boolean;
}

class OrderContextBar extends React.PureComponent<OrderContextBarProps, OrderContextBarState> {

    // ----------------
    // VARIABLES

    public orderSidebarComponent: any;

    public orderContextBarVariants = {
        hidden: { overflow: 'hidden', height: 0 },
        visible: { overflow: 'hidden', height: 'auto' }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderContextBarProps, state: OrderContextBarState) {
        super(props);
        this.state = {
            editingOrderDates: false,
            isActive: false,
            isOrderDropdownInfoOpen: false,
            isOrderSelectorOpen: false
        };
        this.orderSidebarComponent = React.createRef();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {}

    public componentDidUpdate = (prevProps: OrderContextBarProps) => {
        if (this.props.ordersState.orderDetail && !prevProps.ordersState.orderDetail) {
            this.setState({
                isActive: this.props.ordersState.orderDetail.orderStatus === 'Open'
            });
        }
        else if (prevProps.ordersState.orderDetail && !this.props.ordersState.orderDetail) {
            this.setState({
                isActive: false
            })
        }
        else if (this.props.ordersState.orderDetail && prevProps.ordersState.orderDetail) {
            if (this.props.ordersState.orderDetail.orderStatus != 'Open' && prevProps.ordersState.orderDetail.orderStatus === 'Open') {
                this.setState({
                    isActive: false
                })
            }
            else if (this.props.ordersState.orderDetail.orderStatus === 'Open' && prevProps.ordersState.orderDetail.orderStatus != 'Open') {
                this.setState({
                    isActive: true
                })                
            }
        }

        this.setBodyClass(this.props.ordersState.orderDetail && this.props.ordersState.orderDetail.orderStatus === 'Open' ? true : false);
    }

    public componentWillUnmount = () => {}

    public render = () => {
        return (
            <AnimatePresence>
                {this.state.isActive && (
                    <motion.div id="order-context-bar" animate={this.state.isActive ? "visible" : "hidden"} initial={"hidden"} 
                        variants={this.orderContextBarVariants} transition={{ duration: 0.5 }}>
                        <Container>
                            <Row>
                                <Col className="pr-0 order-context-link" xs={"auto"}>
                                    <Button color="link" onClick={this.goToOrder} disabled={this.isEditingOrder()}>Current</Button>
                                </Col>
                                <Col className="pl-0 pr-0 order-context-info">
                                    <div className="order-number">
                                        <label>{this.getOrderNumber()}</label>
                                    </div>
                                    <div className="order-customer">
                                        <label>{this.getOrderCustomer()}</label>
                                    </div>
                                    {!this.isEditingOrder() && (
                                        <Button color="secondary" title="Detail" onClick={this.showOrderSidebar}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather-shopping-cart">
                                                <circle cx="9" cy="21" r="1"></circle>
                                                <circle cx="20" cy="21" r="1"></circle>
                                                <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                            </svg>
                                        </Button>
                                    )}
                                    <Button color="secondary" title="Change" onClick={this.showOrderSelector}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 626 626" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-change">
                                            <g transform="matrix(0.1,0,0,-0.1,7.8105308e-7,626)">
                                                <path d="m 4969,5905 c -46,-25 -49,-54 -49,-421 v -344 h -512 c -454,0 -528,-2 -644,-19 -225,-34 -488,-132 -663,-250 -146,-97 -376,-332 -506,-516 -139,-197 -307,-507 -450,-830 -235,-534 -294,-663 -357,-790 -188,-375 -370,-581 -589,-668 -127,-51 -202,-57 -676,-57 -365,0 -439,-2 -463,-15 -61,-32 -60,-25 -60,-422 0,-211 4,-372 10,-387 5,-14 24,-35 42,-46 32,-20 44,-21 548,-17 582,4 596,5 827,82 271,90 421,182 619,379 340,341 517,647 941,1636 67,156 202,423 266,525 181,292 351,433 580,482 72,16 142,18 582,18 h 500 l 5,-358 5,-359 33,-29 c 26,-23 42,-29 79,-29 h 47 l 574,573 c 315,314 580,584 588,599 18,34 18,72 0,106 -16,29 -1115,1129 -1156,1156 -30,20 -86,20 -121,1 z" />
                                                <path d="M 52,5120 C 34,5109 15,5088 10,5074 4,5059 0,4897 0,4685 v -365 l 29,-32 29,-33 479,-6 c 548,-6 555,-7 720,-90 194,-96 347,-277 513,-603 37,-71 71,-131 77,-133 5,-2 40,67 77,153 37,86 90,201 117,255 27,54 49,100 49,102 0,8 125,238 178,326 64,108 68,86 -43,225 -85,108 -248,266 -348,340 -200,147 -435,249 -677,292 -80,15 -177,18 -605,21 -499,4 -511,3 -543,-17 z" />
                                                <path d="m 3042,2789 c -16,-35 -44,-98 -62,-139 -64,-146 -260,-536 -319,-637 -34,-56 -61,-105 -61,-108 0,-19 131,-177 240,-289 164,-168 245,-228 443,-325 175,-87 263,-113 485,-145 157,-23 491,-34 849,-28 l 302,5 3,-362 3,-363 33,-29 c 26,-23 42,-29 79,-29 h 47 l 574,572 c 315,315 580,585 588,600 18,34 18,72 0,106 -16,29 -1115,1129 -1156,1156 -46,30 -135,12 -156,-31 -5,-10 -11,-178 -14,-373 l -5,-355 -470,-3 c -527,-3 -584,2 -728,62 -96,40 -163,82 -238,151 -103,94 -236,289 -344,505 -32,63 -59,117 -61,119 -2,2 -17,-25 -32,-60 z" />
                                            </g>
                                        </svg>
                                    </Button>
                                    <Button color="secondary" title="Close" onClick={this.closeOrder}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </Button>          
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pl-sm-3 pr-0 order-context-dates">
                                    <label className="tag">Ship Date</label>
                                    <Button color="link" onClick={this.changeShipDate} disabled={this.isEditingOrder()}>
                                        <label>{this.getOrderShipDate()}</label>
                                        {!this.isEditingOrder() && (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-edit-3">
                                                <path d="M12 20h9"></path>
                                                <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                            </svg>
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        {this.props.ordersState.orderDetail && (
                            <React.Fragment>
                                <EditOrderDates isOpen={this.state.editingOrderDates} orderDetail={this.props.ordersState.orderDetail}
                                    onDismiss={this.hideEditOrderDates} />
                                <OrderContextSelector isOpen={this.state.isOrderSelectorOpen} client={this.props.ordersState.orderDetail.client}
                                        onDismiss={this.hideOrderSelector} />
                            </React.Fragment>
                        )}
                        {this.props.ordersState.orderDetail && this.props.orderInvoiceState.orderInvoice && (
                            <OrderSidebar ref={this.orderSidebarComponent}
                                onDismiss={() => this.hideOrderSidebar(false)}
                                onEdit={() => this.hideOrderSidebar(true)}
                                onViewProduct={this.goToProduct}
                                orderDetail={this.props.ordersState.orderDetail}
                                orderInvoice={this.props.orderInvoiceState.orderInvoice} />
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        );
    }

    // ----------------
    // HELPERS

    private changeShipDate = (): void => {
        if (!this.isEditingOrder()) {
            this.setState({
                editingOrderDates: true
            });
        }
    }

    private closeOrder = (): void => {
        this.props.actions.closeOrder();
        if (this.props.location.pathname.startsWith("/orders/edit")) {
            this.props.history.push('/orders');
        }
    }

    private getOrderCustomer = (): string => {
        let orderCustomer: string = "";
        if (this.props.ordersState.orderDetail && this.props.ordersState.orderDetail.customerAccount) {
            orderCustomer = this.props.ordersState.orderDetail.customerAccount.nameOnly;
        }
        return orderCustomer;
    }

    private getOrderNumber = (): string => {
        let orderNumber: string = "";
        if (this.props.ordersState.orderDetail && this.props.ordersState.orderDetail.orderNumber) {
            orderNumber = this.props.ordersState.orderDetail.orderNumber;
        }
        return orderNumber;
    }

    private getOrderShipDate = (): string => {
        let shipDate: string = "";
        if (this.props.ordersState.orderDetail) {
            shipDate = convertJSDateToString(this.props.ordersState.orderDetail.shipDate, 'M/d/yyyy');
        }
        return shipDate;
    }

    private goToOrder = (): void => {
        if (this.props.ordersState.orderDetail) {
            let path: string = '/orders/edit/' + this.props.ordersState.orderDetail.id + '/detail';
            this.props.history.push(path);
        }
    }

    private goToProduct = (orderInvoiceItem: OrderInvoiceStore.OrderInvoiceItem): void => {
        this.hideOrderSidebar(false);
        setTimeout(() => {
            let currentPath = this.props.location.pathname;
            this.props.history.push('/products/detail/' + encodeURIComponent(orderInvoiceItem.sanitizedProductId),
                { from: currentPath });
        }, 400);        
    }

    private hideEditOrderDates = (): void => {
        this.setState({
            editingOrderDates: false
        });
    }

    private hideOrderSelector = (orderId: number | null | undefined): void => {
        this.setState({
            isOrderSelectorOpen: false
        });
        if (orderId) {
            if (this.props.location.pathname.startsWith('/orders/edit/')) {
                this.goToOrder();
            }
        }
    }

    private hideOrderSidebar = (goToOrder: boolean): void => {
        if (this.orderSidebarComponent && this.orderSidebarComponent.current) {
            this.orderSidebarComponent.current.setState({ isOpen: false });
            if (goToOrder) {
                setTimeout(() => {
                    this.goToOrder();
                }, 400);
            }
        }
    }

    private isEditingOrder = (): boolean => {
        return this.props.location.pathname.startsWith('/orders/edit');
    }

    private setBodyClass = (active: boolean): void => {
        if (active) {
            $(document.body).addClass('order-context');
        }
        else {
            $(document.body).removeClass('order-context');
        }
    }

    private showOrderSelector = (): void => {
        this.setState({
            isOrderSelectorOpen: true
        });
    }

    private showOrderSidebar = (): void => {
        if (this.orderSidebarComponent && this.orderSidebarComponent.current) {
            this.orderSidebarComponent.current.setState({ isOpen: true });
        }
    }

    private toggleDropdownInfo = () => {
        this.setState({
            isOrderDropdownInfoOpen: !this.state.isOrderDropdownInfoOpen
        });
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        orderInvoiceState: state.orderInvoice,
        ordersState: state.orders
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},            
            OrderInvoiceStore.actionCreators,
            OrdersStore.actionCreators), dispatch)
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderContextBar as any));
