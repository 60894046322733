﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ClientStore from './Client';
import * as CustomersStore from './Customers';
import * as MessageStore from './Message';
import * as OrderInvoiceStore from './OrderInvoice';
import { CustomerAccount } from './Customers';
import { Product } from './Products';
import { OrderItem } from './OrderItems';
import { ShippingOption } from './ShippingOptions';
import * as ErrorMessage from '../common/ErrorMessage';
import { AvailableUnits, Color, Dimension, GarmentType, Gender, Season, Size, Style } from '../common/ProductTypes';
import { CustomerName, CustomShipping, FreightTerms, ShipVia, Terms } from '../common/AccountTypes';
import { DropShipAddress } from '../common/AddressTypes';
import { EmbroiderySpecification, PlacementOption } from '../common/EmbroideryTypes';
import { OrderDateType } from '../enums/OrderDateType';
import { ClientCode } from '../enums/ClientCode';
import { OrderSubmissionErrorType } from '../enums/OrderSubmissionErrorType';
import { Query, QueryResult } from 'material-table';
import { convertSQLDateToJSDate } from '../common/DateConverter';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface OrdersState {
    orders: Orders | null;
    orderDetail: OrderDetail | null | undefined;
    newOrder: BaseOrder | null;
    bouncedOrders: BouncedOrders | null;
    clientCode: ClientCode;
    isLoading: boolean;
    isLoadingDetail: boolean;
    isLoadingBouncedOrders: boolean;
    isAdding: boolean;
    isDeleting: boolean;
    isUpdating: boolean;
}

export interface BaseOrder {
    id: number | null | undefined;
    billto: string;
    cancelDate: Date | null | undefined;
    client: ClientStore.Client;
    createdBy: string;
    customerAccount: CustomerAccount | null | undefined;
    customerNumber: string;
    dateCreated: Date;
    dateLastModified: Date;
    dateSubmitted: Date | null | undefined;
    description: string;
    orderDate: Date;
    orderNumber: string | null | undefined;
    orderStatus: string | null | undefined;
    poNumber: string | null | undefined;
    poRequired: boolean | null | undefined;
    seasonCode: string | null | undefined;
    shipDate: Date;
    shipto: string | null | undefined;
}

export interface Order extends BaseOrder {
    clientCode: ClientCode;
    customerName: string;
    customerNameOnly: string;
    orderNumberERP: string | null | undefined;
    repName: string | null | undefined;
    season: Season;
    total: number;
}

export interface OrderDetail extends BaseOrder {
    billToCustomer: CustomersStore.CustomerDetail;
    customShipping: CustomShipping;
    discount: number;
    dropShipAddress: DropShipAddress | null | undefined;
    freightTerms: FreightTerms;
    instructions: string;
    shipToCustomer: CustomersStore.CustomerDetail;
    shipVia: ShipVia;
    terms: Terms;
}

export interface OrderManifest {
    orderDetail: OrderDetail;
    orderInvoice: OrderInvoiceStore.OrderInvoice;
}

export interface OrderDateRange {
    dateType: OrderDateType;
    dateFrom: Date;
    dateTo: Date;
}

export interface OrderHistoryEntry {
    date: string,
    action: string,
    userName: string
}

export interface OrderSubmissionError {
    errorType: OrderSubmissionErrorType;
    index: number | null | undefined;
    orderItem: OrderItem | null | undefined;
}

export interface OrderSubmissionSummary {
    errorMessage: string;
    errors: OrderSubmissionError[];
    hasNoItems: boolean;
    instructions: string;
    isAlreadySubmitted: boolean;
    isInactiveUser: boolean;
    orderDetail: OrderDetail;
    submitted: boolean;
}

export interface Orders extends QueryResult<Order> {
    query: Query<any>;
}

export interface OrderCopy {
    copyQuantities: boolean;
    copyEmbroideries: boolean;
    customer: CustomerName;
    order: BaseOrder;
    id?: number | null | undefined;
    itemsUnavailable: boolean;
}

export interface BouncedOrder {
    billto: string;
    clientCode: ClientCode;
    customerName: string;
    customerNameOnly: string;
    customerNumber: string;
    errorMessage: string;
    id: number;
    orderId: number;
    orderNumber: string | null | undefined;
    dateProcessed: Date;
    repName: string | null | undefined;
}

export interface BouncedOrders extends QueryResult<BouncedOrder> {
    query: Query<any>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface AddOrderAction {
    type: 'ADD_ORDER';
    clientCode: ClientCode;
    newOrder: BaseOrder;
}

interface AddOrderCompleteAction {
    type: 'ADD_ORDER_COMPLETE';
    clientCode: ClientCode;
    newOrder: BaseOrder | null;
}

interface ClearOrdersQueryAction {
    type: 'CLEAR_ORDERS_QUERY';
}

interface CopyOrderAction {
    type: 'COPY_ORDER';
    clientCode: ClientCode;
    orderToCopy: OrderCopy;
}

interface CopyOrderCompleteAction {
    type: 'COPY_ORDER_COMPLETE';
    clientCode: ClientCode;
    orderToCopy: OrderCopy;
}

interface DeleteOrderAction {
    type: 'DELETE_ORDER';
    clientCode: ClientCode;
    orderToDelete: Order;
}

interface DeleteOrderCompleteAction {
    type: 'DELETE_ORDER_COMPLETE';
    clientCode: ClientCode;
    orderToDelete: Order | null;
}

interface RequestOrdersAction {
    type: 'REQUEST_ORDERS';
    clientCode: ClientCode;
    query: Query<any>;
}

interface ReceiveOrdersAction {
    type: 'RECEIVE_ORDERS';
    clientCode: ClientCode;
    orders: Orders | null;
}

interface RequestOrderDetailAction {
    type: 'REQUEST_ORDER_DETAIL';
    clientCode: ClientCode;
    id: number;
}

interface ReceiveOrderDetailAction {
    type: 'RECEIVE_ORDER_DETAIL';
    clientCode: ClientCode;
    orderDetail: OrderDetail | null | undefined;
}

interface CloseOrderAction {
    type: 'CLOSE_ORDER';
}

interface ChangeOrderCustomerAction {
    type: 'CHANGE_ORDER_CUSTOMER',
    clientCode: ClientCode;
    id: number;
}

interface ChangeOrderCustomerCompleteAction {
    type: 'CHANGE_ORDER_CUSTOMER_COMPLETE',
    clientCode: ClientCode;
    orderDetail: OrderDetail | null | undefined;
}

interface ChangeOrderShippingAction {
    type: 'CHANGE_ORDER_SHIPPING';
    clientCode: ClientCode;
    id: number;
}

interface ChangeOrderShippingCompleteAction {
    type: 'CHANGE_ORDER_SHIPPING_COMPLETE';
    clientCode: ClientCode;
    orderDetail: OrderDetail | null | undefined;
}

interface SaveOrderDetailAction {
    type: 'SAVE_ORDER_DETAIL';
    clientCode: ClientCode;
    orderDetail: OrderDetail;
}

interface SaveOrderDetailCompleteAction {
    type: 'SAVE_ORDER_DETAIL_COMPLETE',
    clientCode: ClientCode;
    orderDetail: OrderDetail | null | undefined;
}

interface SetOrderDetailAction {
    type: 'SET_ORDER_DETAIL',
    clientCode: ClientCode,
    orderDetail: OrderDetail | null | undefined;
}

interface SubmitOrderAction {
    type: 'SUBMIT_ORDER';
    clientCode: ClientCode;
    orderDetail: OrderDetail;
}

interface SubmitOrderCompleteAction {
    type: 'SUBMIT_ORDER_COMPLETE',
    clientCode: ClientCode;
    orderDetail: OrderDetail;
}

interface RequestBouncedOrdersAction {
    type: 'REQUEST_BOUNCED_ORDERS';
    clientCode: ClientCode;
    query: Query<any>
}

interface ReceiveBouncedOrdersAction {
    type: 'RECEIVE_BOUNCED_ORDERS';
    clientCode: ClientCode;
    bouncedOrders: BouncedOrders | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = AddOrderAction | AddOrderCompleteAction |
    CopyOrderAction | CopyOrderCompleteAction |
    DeleteOrderAction | DeleteOrderCompleteAction | ClearOrdersQueryAction | CloseOrderAction |
    RequestOrdersAction | ReceiveOrdersAction |
    RequestOrderDetailAction | ReceiveOrderDetailAction |
    ChangeOrderCustomerAction | ChangeOrderCustomerCompleteAction |
    ChangeOrderShippingAction | ChangeOrderShippingCompleteAction |
    SaveOrderDetailAction | SaveOrderDetailCompleteAction | SetOrderDetailAction |
    OrderInvoiceStore.ClearOrderInvoiceAction | OrderInvoiceStore.SetOrderInvoiceAction |
    SubmitOrderAction | SubmitOrderCompleteAction |
    RequestBouncedOrdersAction | ReceiveBouncedOrdersAction |
    CustomersStore.ReceiveCustomerDetailAction |
    MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const formatOrderDates = (orderDetail: OrderDetail): OrderDetail => {
    if (orderDetail.dateSubmitted) {
        orderDetail.dateSubmitted = convertSQLDateToJSDate(orderDetail.dateSubmitted);
    }
    if (orderDetail.shipDate) {
        orderDetail.shipDate = convertSQLDateToJSDate(orderDetail.shipDate);
    }
    if (orderDetail.cancelDate) {
        orderDetail.cancelDate = convertSQLDateToJSDate(orderDetail.cancelDate);
    }
    return orderDetail;
}

const isDeletedOrder = (state: OrdersState | undefined, action: DeleteOrderCompleteAction): boolean => {
    let isDeleted: boolean = false;
    if (state && state.orderDetail) {
        if (action && action.orderToDelete) {
            isDeleted = action.orderToDelete.id === state.orderDetail.id;
        }
    }
    return isDeleted;
}

export const actionCreators = {
    requestOrders: (client: ClientStore.Client, query: Query<Order>): AppThunkAction<KnownAction> => (dispatch, getState): Promise<QueryResult<Order>> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orders && !appState.orders.isLoading) {

                let clientAwareQuery: object = Object.assign({}, query, { client: client, clientCode: client.code });
                let fetchError: boolean = false;
                let action: string = 'orders/get';
                let url: string = `${action}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(clientAwareQuery)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_ORDERS', clientCode: client.code, orders: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                        let orders: Orders = data as Orders;
                        dispatch({ type: 'RECEIVE_ORDERS', clientCode: client.code, orders: orders });
                    },
                        err => {
                            reject(err.message);
                            if (!fetchError) {
                                dispatch({ type: 'RECEIVE_ORDERS', clientCode: client.code, orders: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                        });

                dispatch({ type: 'REQUEST_ORDERS', clientCode: client.code, query: query });
            }
            else {
                resolve(appState.orders ?
                    appState.orders.orders as QueryResult<Order> :
                    { data: [], query: query, page: query.page, totalCount: 0 } as QueryResult<Order>);
            }
        });
    },
    requestOrderDetail: (client: ClientStore.Client, id: number): AppThunkAction<KnownAction> => (dispatch, getState): Promise<OrderDetail | null | undefined> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orders && !appState.orders.isLoadingDetail) {

                let fetchError: boolean = false;
                let action: string = 'orders/getdetail';
                let url: string = `${action}/${id.toString()}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(client)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_ORDER_DETAIL', clientCode: client.code, orderDetail: null });
                                    dispatch({ type: 'CLEAR_ORDER_INVOICE' });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let orderManifest: OrderManifest = data as OrderManifest;
                        let orderDetail: OrderDetail = formatOrderDates(orderManifest.orderDetail);
                        resolve(orderDetail);
                        dispatch({ type: 'RECEIVE_ORDER_DETAIL', clientCode: client.code, orderDetail });
                        dispatch({ type: 'RECEIVE_CUSTOMER_DETAIL', clientCode: client.code, customerDetail: null });
                        dispatch({ type: 'SET_ORDER_INVOICE', clientCode: client.code, orderId: id, orderInvoice: orderManifest.orderInvoice });
                    },
                        err => {
                            reject(err.message);
                            if (!fetchError) {
                                dispatch({ type: 'RECEIVE_ORDER_DETAIL', clientCode: client.code, orderDetail: null });
                                dispatch({ type: 'CLEAR_ORDER_INVOICE' });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                        })

                dispatch({ type: 'REQUEST_ORDER_DETAIL', clientCode: client.code, id: id });
            }
            else {
                resolve(appState.orders ? appState.orders.orderDetail : undefined);
            }
        });
    },
    addOrder: (client: ClientStore.Client, customer: CustomerAccount, newOrder: BaseOrder): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.orders && !appState.orders.isAdding) {

            newOrder.client = client;
            newOrder.customerAccount = customer;
            let fetchError: boolean = false;
            let action: string = 'orders/add';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(newOrder)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'ADD_ORDER_COMPLETE', clientCode: client.code, newOrder: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    newOrder = data as BaseOrder;
                    dispatch({ type: 'ADD_ORDER_COMPLETE', clientCode: client.code, newOrder: newOrder });
                    let actionComplete: MessageStore.Message = {
                        messageType: MessageStore.MessageType.ACTIONCOMPLETE,
                        action: action,
                        reference: null,
                        text: 'Add complete'
                    };
                    dispatch({ type: 'BROADCAST_MESSAGE', message: actionComplete });
                },
                err => {
                    if (!fetchError) {
                        dispatch({ type: 'ADD_ORDER_COMPLETE', clientCode: client.code, newOrder: null })
                        dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                    }
                });

            dispatch({ type: 'ADD_ORDER', clientCode: client.code, newOrder: newOrder });
        }
    },
    copyOrder: (client: ClientStore.Client, orderToCopy: OrderCopy): AppThunkAction<KnownAction> => (dispatch, getState): Promise<OrderCopy | null | undefined> => {
        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orders && !appState.orders.isAdding) {

                let fetchError: boolean = false;
                let action: string = 'orders/copy';
                let url: string = `${action}/${(orderToCopy.order.id as number).toString()}`;

                // make argument client-aware
                orderToCopy.order.client = client;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(orderToCopy)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'COPY_ORDER_COMPLETE', clientCode: client.code, orderToCopy: orderToCopy });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let updatedOrderToCopy: OrderCopy = data as OrderCopy;
                        resolve(updatedOrderToCopy);
                        dispatch({ type: 'COPY_ORDER_COMPLETE', clientCode: client.code, orderToCopy: updatedOrderToCopy });
                    },
                        err => {
                            reject(err.message);
                            if (!fetchError) {
                                dispatch({ type: 'COPY_ORDER_COMPLETE', clientCode: client.code, orderToCopy: orderToCopy });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                        });

                dispatch({ type: 'COPY_ORDER', clientCode: client.code, orderToCopy: orderToCopy });
            }
            else {
                resolve(null);
            }
        });
    },
    deleteOrder: (clientCode: ClientCode, orderToDelete: Order): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.orders && !appState.orders.isDeleting) {

            orderToDelete.clientCode = clientCode;
            let fetchError: boolean = false;
            let action: string = 'orders/remove';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'DELETE',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(orderToDelete)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'DELETE_ORDER_COMPLETE', clientCode: clientCode, orderToDelete: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    orderToDelete = data as Order;
                    dispatch({ type: 'DELETE_ORDER_COMPLETE', clientCode: clientCode, orderToDelete: orderToDelete });
                    let actionComplete: MessageStore.Message = {
                        messageType: MessageStore.MessageType.ACTIONCOMPLETE,
                        action: action,
                        reference: null,
                        text: 'Delete complete'
                    };
                    dispatch({ type: 'BROADCAST_MESSAGE', message: actionComplete });
                },
                    err => {
                        if (!fetchError) {
                            dispatch({ type: 'DELETE_ORDER_COMPLETE', clientCode: clientCode, orderToDelete: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

            dispatch({ type: 'DELETE_ORDER', clientCode: clientCode, orderToDelete: orderToDelete });
        }
    },
    closeOrder: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.orders && appState.orders.orderDetail) {
            dispatch({ type: 'CLOSE_ORDER' });
            dispatch({ type: 'CLEAR_ORDER_INVOICE' });
            dispatch({ type: 'RECEIVE_CUSTOMER_DETAIL', clientCode: appState.orders.orderDetail.client.code, customerDetail: undefined });
        }
    },
    changeOrderCustomer: (client: ClientStore.Client, orderDetail: OrderDetail, customer: CustomerAccount): AppThunkAction<KnownAction> => (dispatch, getState): Promise<OrderDetail | null | undefined> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orders && !appState.orders.isUpdating) {

                let fetchError: boolean = false;
                let action: string = 'orders/changecustomer';
                let url: string = `${action}/${(orderDetail.id as number).toString()}`;

                // make argument client-aware
                customer.client = client;

                fetch(url,
                    {
                        method: 'PUT',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(customer)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'CHANGE_ORDER_CUSTOMER_COMPLETE', clientCode: orderDetail.client.code, orderDetail: orderDetail });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let orderManifest: OrderManifest = data as OrderManifest;
                        let updatedOrderDetail: OrderDetail = formatOrderDates(orderManifest.orderDetail);
                        resolve(updatedOrderDetail);
                        dispatch({ type: 'CHANGE_ORDER_CUSTOMER_COMPLETE', clientCode: orderDetail.client.code, orderDetail: updatedOrderDetail });
                        dispatch({ type: 'SET_ORDER_INVOICE', clientCode: orderDetail.client.code, orderId: orderDetail.id as number, orderInvoice: orderManifest.orderInvoice });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'CHANGE_ORDER_CUSTOMER_COMPLETE', clientCode: orderDetail.client.code, orderDetail: orderDetail });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    })

                dispatch({ type: 'CHANGE_ORDER_CUSTOMER', clientCode: orderDetail.client.code, id: orderDetail.id as number });
            }
            else {
                resolve(appState.orders ? appState.orders.orderDetail : orderDetail);
            }
        });
    },
    changeOrderShipping: (client: ClientStore.Client, orderDetail: OrderDetail): AppThunkAction<KnownAction> => (dispatch, getState): Promise<OrderDetail | null | undefined> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orders && !appState.orders.isUpdating) {

                let fetchError: boolean = false;
                let action: string = 'orders/changeshipping';
                let url: string = `${action}/${(orderDetail.id as number).toString()}`;

                fetch(url,
                    {
                        method: 'PUT',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(orderDetail)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'CHANGE_ORDER_SHIPPING_COMPLETE', clientCode: orderDetail.client.code, orderDetail: orderDetail });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let updatedOrderDetail: OrderDetail = formatOrderDates(data as OrderDetail);
                        resolve(updatedOrderDetail);
                        dispatch({ type: 'CHANGE_ORDER_SHIPPING_COMPLETE', clientCode: orderDetail.client.code, orderDetail: updatedOrderDetail });
                    },
                        err => {
                            reject(err.message);
                            if (!fetchError) {
                                dispatch({ type: 'CHANGE_ORDER_SHIPPING_COMPLETE', clientCode: orderDetail.client.code, orderDetail: orderDetail });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                        })

                dispatch({ type: 'CHANGE_ORDER_SHIPPING', clientCode: orderDetail.client.code, id: orderDetail.id as number });
            }
            else {
                resolve(appState.orders ? appState.orders.orderDetail : orderDetail);
            }
        });
    },
    clearOrdersQuery: () => ({ type: 'CLEAR_ORDERS_QUERY' } as ClearOrdersQueryAction),
    saveOrderDetail: (client: ClientStore.Client, orderDetail: OrderDetail): AppThunkAction<KnownAction> => (dispatch, getState): Promise<OrderDetail | null | undefined> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orders && !appState.orders.isUpdating) {

                let fetchError: boolean = false;
                let action: string = 'orders/savedetail';
                let url: string = `${action}/${(orderDetail.id as number).toString()}`;

                fetch(url,
                    {
                        method: 'PUT',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(orderDetail)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'SAVE_ORDER_DETAIL_COMPLETE', clientCode: orderDetail.client.code, orderDetail: orderDetail });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let orderManifest: OrderManifest = data as OrderManifest;
                        let updatedOrderDetail: OrderDetail = formatOrderDates(orderManifest.orderDetail);
                        resolve(updatedOrderDetail);
                        dispatch({ type: 'SAVE_ORDER_DETAIL_COMPLETE', clientCode: orderDetail.client.code, orderDetail: updatedOrderDetail });
                    },
                        err => {
                            reject(err.message);
                            if (!fetchError) {
                                dispatch({ type: 'SAVE_ORDER_DETAIL_COMPLETE', clientCode: orderDetail.client.code, orderDetail: orderDetail });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                        })

                dispatch({ type: 'SAVE_ORDER_DETAIL', clientCode: orderDetail.client.code, orderDetail: orderDetail })
            }
            else {
                resolve(appState.orders ? appState.orders.orderDetail : orderDetail);
            }
        });
    },
    setOrderDetail: (client: ClientStore.Client, orderDetail: OrderDetail): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_ORDER_DETAIL', clientCode: client.code, orderDetail: orderDetail });
    },
    submitOrder: (client: ClientStore.Client, orderDetail: OrderDetail): AppThunkAction<KnownAction> => (dispatch, getState): Promise<OrderSubmissionSummary | null | undefined> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orders && !appState.orders.isUpdating) {

                let fetchError: boolean = false;
                let action: string = 'orders/submit';
                let url: string = `${action}/${(orderDetail.id as number).toString()}`

                fetch(url,
                    {
                        method: 'PUT',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(orderDetail)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'SUBMIT_ORDER_COMPLETE', clientCode: client.code, orderDetail: orderDetail });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let submissionSummary: OrderSubmissionSummary = data as OrderSubmissionSummary;
                        let updatedOrderDetail: OrderDetail = formatOrderDates(submissionSummary.orderDetail);
                        resolve(submissionSummary);
                        dispatch({ type: 'SUBMIT_ORDER_COMPLETE', clientCode: client.code, orderDetail: updatedOrderDetail });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'SUBMIT_ORDER_COMPLETE', clientCode: client.code, orderDetail: orderDetail });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

                dispatch({ type: 'SUBMIT_ORDER', clientCode: client.code, orderDetail: orderDetail });
            }
            else {
                resolve(null);
            }
        })
    },
    requestBouncedOrders: (client: ClientStore.Client, query: Query<BouncedOrder>): AppThunkAction<KnownAction> => (dispatch, getState): Promise<QueryResult<BouncedOrder>> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orders && !appState.orders.isLoadingBouncedOrders) {

                let clientAwareQuery: object = Object.assign({}, query, { client: client, clientCode: client.code });
                let fetchError: boolean = false;
                let action: string = 'orders/getbounced';
                let url: string = `${action}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(clientAwareQuery)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_BOUNCED_ORDERS', clientCode: client.code, bouncedOrders: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                        let bouncedOrders: BouncedOrders = data as BouncedOrders;
                        dispatch({ type: 'RECEIVE_BOUNCED_ORDERS', clientCode: client.code, bouncedOrders: bouncedOrders });
                    }, err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'RECEIVE_BOUNCED_ORDERS', clientCode: client.code, bouncedOrders: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

                dispatch({ type: 'REQUEST_BOUNCED_ORDERS', clientCode: client.code, query: query });
            }
            else {
                resolve(appState.orders ?
                    appState.orders.bouncedOrders as QueryResult<BouncedOrder> :
                    { data: [], query: query, page: query.page, totalCount: 0 } as QueryResult<BouncedOrder>);            }
        })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: OrdersState = {
    orders: null,
    orderDetail: null,
    newOrder: null,
    bouncedOrders: null,
    clientCode: ClientCode.Undefined,
    isLoading: false,
    isLoadingDetail: false,
    isLoadingBouncedOrders: false,
    isAdding: false,
    isDeleting: false,
    isUpdating: false
};

export const reducer: Reducer<OrdersState> = (state: OrdersState | undefined, incomingAction: Action): OrdersState => {
    if (state == undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_ORDER':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: state.orderDetail,
                newOrder: action.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: true,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating
            }
        case 'ADD_ORDER_COMPLETE':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: state.orderDetail,
                newOrder: action.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: false,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating
            }
        case 'DELETE_ORDER':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: true,
                isUpdating: state.isUpdating
            }
        case 'COPY_ORDER':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: true,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating
            }
        case 'COPY_ORDER_COMPLETE':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: false,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating
            }
        case 'DELETE_ORDER_COMPLETE':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: isDeletedOrder(state, action) ? null : state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: false,
                isUpdating: state.isUpdating
            }
        case 'CLOSE_ORDER':
            return {
                clientCode: state.clientCode,
                orders: state.orders,
                orderDetail: null,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating
            }
        case 'CLEAR_ORDERS_QUERY': 
            return {
                clientCode: state.clientCode,
                orders: null,
                orderDetail: state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating
            };
        case 'REQUEST_ORDERS':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: true,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating
            };
        case 'RECEIVE_ORDERS':
            return {
                clientCode: action.clientCode,
                orders: action.orders,
                orderDetail: state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: false,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating
            };  
        case 'REQUEST_ORDER_DETAIL':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: true,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating
            };
        case 'RECEIVE_ORDER_DETAIL':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: action.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: false,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating
            };
        case 'CHANGE_ORDER_CUSTOMER':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: true
            };
        case 'CHANGE_ORDER_CUSTOMER_COMPLETE':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: action.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: false
            }
        case 'CHANGE_ORDER_SHIPPING':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: true
            };
        case 'CHANGE_ORDER_SHIPPING_COMPLETE':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: action.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: false
            };
        case 'SAVE_ORDER_DETAIL':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: true
            };
        case 'SAVE_ORDER_DETAIL_COMPLETE':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: action.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: false
            };
        case 'SET_ORDER_DETAIL':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: action.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating
            };
        case 'SUBMIT_ORDER':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: action.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: true
            }
        case 'SUBMIT_ORDER_COMPLETE':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: action.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: state.isLoadingBouncedOrders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: false
            }
        case 'REQUEST_BOUNCED_ORDERS':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: state.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: true,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: false
            }
        case 'RECEIVE_BOUNCED_ORDERS':
            return {
                clientCode: action.clientCode,
                orders: state.orders,
                orderDetail: state.orderDetail,
                newOrder: state.newOrder,
                bouncedOrders: action.bouncedOrders,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingBouncedOrders: false,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: false
            }
        default:
            return state;
    }
}