import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as UserStore from '../../store/User';
import { Role } from '../../enums/Role';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import Loader from '../loader/Loader';
import DetailTitleBlock from '../detail-title-block/DetailTitleBlock';
import { TitleBlock } from '../../common/ComponentTypes';
import PasswordChange from '../password-change/PasswordChange';
import PreferencesEdit from '../preferences-edit/PreferencesEdit';
import ProfileEdit from '../profile-edit/ProfileEdit';
import cloneDeep from 'lodash/cloneDeep';

import './Profile.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    userState: {} as UserStore.UserState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, UserStore.actionCreators)
};

type ProfileProps =
    RouteComponentProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators;        // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface ProfileState {
    isVisible: boolean,    
    editingProfile: boolean,
    changingPassword: boolean,
    editingPreferences: boolean,
    titleBlock: TitleBlock;
    userPreferences: UserStore.UserPreferences;
}

class Profile extends React.PureComponent<ProfileProps, ProfileState> {

    // ----------------
    // VARIABLES

    public profileVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProfileProps, state: ProfileState) {
        super(props);
        this.state = {
            isVisible: false,
            editingProfile: false,
            changingPassword: false,
            editingPreferences: false,
            titleBlock: this.getTitleBlock(undefined),
            userPreferences: this.getUserPreferences()
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        if (this.props.userState.user && this.props.clientState.client) {
            this.setState({
                isVisible: true,
                titleBlock: this.getTitleBlock(this.props.userState.user),
                userPreferences: this.getUserPreferences()
            });
        }
    }

    public componentDidUpdate = (prevProps: ProfileProps) => {
        if (this.props.userState.user && this.props.clientState.client && !this.state.isVisible) {
            this.setState({
                isVisible: true,
                titleBlock: this.getTitleBlock(this.props.userState.user),
                userPreferences: this.getUserPreferences()
            });
        }
        else if (this.userPreferencesChanged(prevProps)) {
            this.setState({
                userPreferences: this.getUserPreferences()
            });
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={!this.state.isVisible} />
                <motion.div id="profilePage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"} variants={this.profileVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className="justify-content-start title-block">
                            <DetailTitleBlock titleBlock={this.state.titleBlock} disabled={this.state.editingProfile} />
                        </Row>
                        <Row>
                            <Col className="pl-0 pr-0 pr-lg-4" sm={12} md={12} lg={9}>
                                <Container>
                                    <Row>
                                        <Col className="pl-0 pr-0 mb-4" xs={12} lg={6}>
                                            <div className="info-panel editable">
                                                <div className="title-bar">
                                                    <div className="heading">
                                                        <label>Profile</label>
                                                    </div>
                                                    <div className="button-bar">
                                                        <Button color="link" onClick={this.showProfileEdit}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-edit-3">
                                                                <path d="M12 20h9"></path>
                                                                <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                                            </svg>
                                                            <label>Edit</label>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <Container className="content identity-container">
                                                    <Row>
                                                        <Col className="text-left pl-0" xs={12}>
                                                            <Table size="sm" borderless>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="pl-0">Name</td>
                                                                        <td>{this.props.userState.user ? this.props.userState.user.fullName : ''}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="text-left pl-0" xs={12}>
                                                            <Table size="sm" borderless>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="pl-0">Company</td>
                                                                        <td>{this.props.userState.user ? this.props.userState.user.affiliation : ''}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="text-left pl-0" xs={12}>
                                                            <Table size="sm" borderless>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="pl-0">Email</td>
                                                                        <td>{this.props.userState.user ? this.props.userState.user.email : ''}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </div>
                                        </Col>
                                        <Col className="pl-0 pr-0 pl-lg-4 mb-4" xs={12} lg={6}>
                                            <div className="info-panel editable">
                                                <div className="title-bar">
                                                    <div className="heading">
                                                        <label>Credentials</label>
                                                    </div>
                                                    <div className="button-bar">
                                                        <Button color="link" onClick={this.showPasswordChange}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-key">
                                                                <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                                            </svg>
                                                            <label>Change Password</label>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <Container className="content credentials-container">
                                                    <Row>
                                                        <Col className="text-left pl-0" xs={12}>
                                                            <Table size="sm" borderless>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="pl-0">Username</td>
                                                                        <td>{this.props.userState.user ? this.props.userState.user.username : ''}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="text-left pl-0" xs={12}>
                                                            <Table size="sm" borderless>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="pl-0">Password</td>
                                                                        <td>********</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="text-left pl-0" xs={12}>
                                                            <Table size="sm" borderless>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="pl-0">Role</td>
                                                                        <td>{this.props.userState.user ? Role[this.props.userState.user.role] : '' }</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col className="pl-0 pr-0" sm={12} md={12} lg={3}>
                                <Container>
                                    <Row>
                                        <Col className="pl-0 pr-0 mb-4">
                                            <div className="info-panel editable">
                                                <div className="title-bar">
                                                    <div className="heading">
                                                        <label>Preferences</label>
                                                    </div>
                                                    <div className="button-bar">
                                                        <Button color="link" onClick={this.showPreferencesEdit}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-edit-3">
                                                                <path d="M12 20h9"></path>
                                                                <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                                            </svg>
                                                            <label>Edit</label>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <Container className="content preferences-container">
                                                    <Row>
                                                        <Col className="text-left pl-0 pr-0" xs={12}>
                                                            <Table size="sm" borderless>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="pl-0">Catalog Page Size</td>
                                                                        <td>{this.formatProductsPerPage(this.state.userPreferences.productsPerPage)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                    <ProfileEdit isOpen={this.state.editingProfile} onDismiss={this.hideProfileEdit} />
                    <PasswordChange isOpen={this.state.changingPassword} onDismiss={this.hidePasswordChange} />
                    {this.props.userState.user && this.props.clientState.client && (
                        <PreferencesEdit isOpen={this.state.editingPreferences} onDismiss={this.hidePreferencesEdit}
                            client={this.props.clientState.client} userPreferences={this.state.userPreferences} />
                    )}
                </motion.div>
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private formatProductsPerPage = (productsPerPage: string | null | undefined): string | null | undefined => {
        if (productsPerPage) {
            if (productsPerPage === 'All') {
                return "All (no paging)";
            }
            else {
                return productsPerPage + " per page";
            }
        }
        else {
            return productsPerPage;
        }
    }

    private getTitleBlock = (user: UserStore.User | null | undefined): TitleBlock => {
        let titleBlock: TitleBlock = {
            header: undefined,
            title: undefined,
            subtitle: undefined,
            link: '/dashboard'
        };
        if (user) {
            titleBlock.header = Role[user.role];
            titleBlock.title = user.fullName;
            titleBlock.subtitle = user.affiliation;
        }
        return titleBlock;
    }

    private getUserPreferences = (): UserStore.UserPreferences => {
        let userPreferences: UserStore.UserPreferences = {
            productsPerPage: undefined
        };
        if (this.props.userState.user && this.props.userState.user.preferences) {
            userPreferences = cloneDeep(this.props.userState.user.preferences);
        }
        else if (this.props.clientState.client) {
            let defaultPerPage: number | null = this.props.clientState.client.settings.productSettings.defaultPerPage;
            let maxPerPage: number | null = this.props.clientState.client.settings.productSettings.maxPerPage;
            let maxPerPageEqualsAll: boolean = this.props.clientState.client.settings.productSettings.maxPerPageEqualsAll;
            if (defaultPerPage) {
                userPreferences.productsPerPage = ((defaultPerPage === maxPerPage) && maxPerPageEqualsAll) ? "All" : defaultPerPage.toString();
            }
        }
        return userPreferences;
    }

    private hidePasswordChange = (): void => {
        this.setState({
            changingPassword: false
        });
    }

    private hidePreferencesEdit = (): void => {
        this.setState({
            editingPreferences: false
        });
    }

    private hideProfileEdit = (): void => {
        this.setState({
            editingProfile: false
        });
    }

    private showPasswordChange = (): void => {
        this.props.actions.clearMessage();
        this.setState({
            changingPassword: true
        });
    }

    private showPreferencesEdit = (): void => {
        this.props.actions.clearMessage();
        this.setState({
            editingPreferences: true
        });
    }

    private showProfileEdit = (): void => {
        this.props.actions.clearMessage();
        this.setState({
            editingProfile: true
        });
    }

    private userPreferencesChanged = (prevProps: ProfileProps): boolean => {
        let preferencesChanged: boolean = false;
        let currentPreferences: UserStore.UserPreferences | null | undefined = this.props.userState.user ? this.props.userState.user.preferences : null;
        let previousPreferences: UserStore.UserPreferences | null | undefined = prevProps.userState.user ? prevProps.userState.user.preferences : null;
        if (currentPreferences && !previousPreferences) {
            preferencesChanged = true;
        }
        else if (currentPreferences && previousPreferences) {
            preferencesChanged = currentPreferences.productsPerPage != previousPreferences.productsPerPage;
        }
        return preferencesChanged;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        message: state.message,
        userState: state.user
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, MessageStore.actionCreators, UserStore.actionCreators), dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile as any);