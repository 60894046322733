import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { Role } from '../../enums/Role';
import { User } from '../../store/User';
import { CustomerName } from '../../common/AccountTypes';
import { CustomerAccount, CustomerDetail } from '../../store/Customers';
import { AffiliationType } from '../../enums/AffiliationType';
import { Address, Country } from '../../common/AddressTypes';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import { Button, Container, Col, Row } from 'reactstrap';
import OrderCustomer from '../order-customer/OrderCustomer';
import UsernameRules from '../username-rules/UsernameRules';
import FormResult from '../form-result/FormResult';
import * as DateCoverter from '../../common/DateConverter';
import * as Validators from '../../common/Validators';
import cloneDeep from 'lodash/cloneDeep';
import $ from 'jquery';

import './UserForm.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
};

interface UserFormOwnProps {
    client: Client;
    userDetail: User | null | undefined;
    onCancel: () => void;
    onSave: (user: User) => void;
    onResetPassword?: () => void;
}

type UserFormProps =
    UserFormOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface UserFormState {
    submitting: boolean;
    canceling: boolean;
    changesAccepted: boolean;
    changesRejected: boolean,
    formResult: string | null;
    username: string | null,
    roles: FieldWrapper.OptionValue[];
    previousRole: Role;
    selectedRole: Role;
    buyerAccounts: CustomerName[];
    isSelectingBuyerAccount: boolean;
    displayShipTo: boolean;
}

// ----------------
// FORM VALIDATOR

const validateUserForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.firstName) {
        errors.firstName = 'Required';
    }
    if (!formValues.lastName) {
        errors.lastName = 'Required';
    }
    if (!formValues.username) {
        errors.username = 'Required';
    }
    else if (!formValues.usernameValidated) {
        errors.username = "Invalid username";
    }
    if (!formValues.email) {
        errors.email = 'Required';
    }
    else if (!Validators.validateEmail(formValues.email)) {
        errors.email = 'Invalid email';
    }
    if (!formValues.role) {
        errors.role = 'Required'
    }
    else {
        if (parseInt(formValues.role) == Role.Admin || parseInt(formValues.role) == Role.CSR || parseInt(formValues.role) == Role.Rep) {
            if (!formValues.affiliation) {
                errors.affiliation = 'Required';
            }
        }
        if (parseInt(formValues.role) == Role.Rep) {
            if (!formValues.repId) {
                errors.repId = 'Required';
            }
        }
        if (parseInt(formValues.role) == Role.Buyer) {
            if (parseInt(formValues.buyerAccountTotal) == 0) {
                errors.noAccounts = 'Required';
            }
        }
    }
    return errors;
};


class UserForm extends React.PureComponent<UserFormProps, UserFormState> {

    // ----------------
    // VARIABLES

    public usernameElement: React.RefObject<HTMLInputElement>;

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', 'overflow': 'hidden' }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: UserFormProps, state: UserFormState) {
        super(props);
        this.state = {
            submitting: false,
            canceling: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            username: this.props.userDetail ? this.props.userDetail.username : null,
            roles: [
                { label: Role[Role.Admin], value: Role.Admin } as FieldWrapper.OptionValue,
                { label: Role[Role.CSR], value: Role.CSR } as FieldWrapper.OptionValue,
                { label: Role[Role.Rep], value: Role.Rep } as FieldWrapper.OptionValue,
                { label: Role[Role.Buyer], value: Role.Buyer } as FieldWrapper.OptionValue
            ],
            previousRole: this.props.userDetail ? this.props.userDetail.role : Role.Undefined,
            selectedRole: this.props.userDetail ? this.props.userDetail.role : Role.Undefined,
            buyerAccounts: [],
            isSelectingBuyerAccount: false,
            displayShipTo: this.props.client.rules.displayShipToNumber
        };
        this.usernameElement = React.createRef<HTMLInputElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        setTimeout(() => {
            this.setInitialValues();
        });
    }

    public componentDidUpdate = (prevProps: UserFormOwnProps) => {
        if (this.isDifferentUser(prevProps)) {
            this.setInitialValues();
        }
        if (this.state.submitting) {
            if (this.receivedErrorMessage()) {
                this.setState({
                    submitting: false
                });
            }
            else if (this.receivedValidationError()) {
                let formResult: string | null = this.getFormResult();
                this.props.actions.clearMessage();
                setTimeout(() => {
                    this.setState({
                        submitting: false,
                        changesRejected: true,
                        formResult: formResult
                    });
                    this.scrollToBottom();
                })
            }
            else if (this.receivedActionCompleteMessage()) {
                let formResult: string | null = this.getFormResult();
                setTimeout(() => {
                    this.setState({
                        submitting: false,
                        changesAccepted: true,
                        formResult: formResult
                    })
                    this.scrollToBottom();
                });
            }
        }
        else if (this.state.canceling) {
            this.setState({
                canceling: false
            });
            setTimeout(() => {
                this.props.reset();
                this.setInitialValues();
            }, 250);
        }
    }

    public componentWillUnmount = () => {
    }

    public handleFormSubmit = (values: any): void => {
        if (this.props.userDetail) {
            let user: User = this.convertToUser(values, this.props.userDetail);
            this.setState({
                submitting: true
            });
            this.props.onSave(user);
        }
    }

    public render = () => {
        return (
            <Container id="user-form">
                {this.props.userDetail && (
                    <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                        <Row>
                            <Col className="pl-0 pr-0 pr-lg-4" sm={12} md={12} lg={8}>
                                <Container>
                                    <Row>                                        
                                        <Col className="pl-0 pr-0 mb-4" xs={12} lg={6}>
                                            <div className="info-panel editable">
                                                <div className="title-bar">
                                                    <div className="heading">
                                                        <label>Identity</label>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <Container>
                                                        <Row>
                                                            <Col className="pl-0 pr-0 user-identity">
                                                                <div className="name">First Name</div>
                                                                <div className="value">
                                                                    <Field name="firstName" type="text" label="" component={FieldWrapper.renderTextField}
                                                                        maxLength={25} autoComplete={false} autoFocus={true} disabled={this.state.submitting} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="pl-0 pr-0 user-identity">
                                                                <div className="name">Last Name</div>
                                                                <div className="value">
                                                                    <Field name="lastName" type="text" label="" component={FieldWrapper.renderTextField}
                                                                        maxLength={25} autoComplete={false} disabled={this.state.submitting} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="pl-0 pr-0 user-identity">
                                                                <div className="name">Email</div>
                                                                <div className="value">
                                                                    <Field name="email" type="text" label="" component={FieldWrapper.renderTextField}
                                                                        maxLength={255} autoComplete={false} noSpaces={true} disabled={this.state.submitting} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="pl-0 pr-0 user-identity collapsible-row">
                                                                <motion.div animate={this.isNewUser() ? "hidden" : "visible"} initial={this.isNewUser() ? "hidden" : "visible"}
                                                                    variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                                                                    <div className="user-id">
                                                                        <div className="name">User Id</div>
                                                                        <div className="value">
                                                                            <label>{this.props.userDetail.id}</label>
                                                                        </div>
                                                                    </div>
                                                                </motion.div>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="pl-0 pr-0 pl-lg-4 mb-4" xs={12} lg={6}>
                                            <div className="info-panel editable">
                                                <div className="title-bar">
                                                    <div className="heading">
                                                        <label>Credentials</label>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <Container>
                                                        <Row>
                                                            <Col className="pl-0 pr-0 user-credentials">
                                                                <div className="name">Username</div>
                                                                <div className="value">
                                                                    <Field name="username" type="text" label=""
                                                                        component={FieldWrapper.renderTextField}
                                                                        maxLength={75} autoComplete={false}
                                                                        reference={this.usernameElement}
                                                                        disabled={this.state.submitting}
                                                                        onChange={this.onUsernameChange} />
                                                                    <Field name="usernameValidated" component="input" type="hidden" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="text-center pl-0 pr-0">
                                                                <UsernameRules input={this.state.username} user={this.props.userDetail}
                                                                    isValidUsername={this.onValidateUsername} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="text-center user-credentials-button-bar pl-0 pr-0 mt-4">
                                                                {this.isNewUser() && (
                                                                    <div className="password-instructions">
                                                                        Password will be emailed when form is saved.
                                                                    </div>
                                                                )}
                                                                {!this.isNewUser() && (
                                                                    <Button color="secondary" onClick={this.resetPassword} disabled={this.state.submitting}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-key">
                                                                            <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                                                        </svg>
                                                                        <label>Reset Password</label>
                                                                    </Button>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {!this.isNewUser() && (
                                        <Row>
                                            <Col className="collapsible-row pl-0 pr-0 mb-4" xs={12} lg={6}>
                                                <motion.div animate={this.isNewUser() ? "hidden" : "visible"} initial={this.isNewUser() ? "hidden" : "visible"}
                                                    variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                                                    <div className="info-panel">
                                                        <div className="title-bar">
                                                            <div className="heading">
                                                                <label>Status</label>
                                                            </div>
                                                        </div>
                                                        <div className="content user-status">
                                                            <Container>
                                                                <Row>
                                                                    <Col className="pl-0 pr-0 user-status">
                                                                        <div className="name">Activated</div>
                                                                        <div className="value">
                                                                            {this.props.userDetail.created && (
                                                                                <label>{DateCoverter.convertSQLDateToString(this.props.userDetail.created, "MMM d, yyyy")}</label>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="pl-0 pr-0 user-status">
                                                                        <div className="name">Last Login</div>
                                                                        <div className="value">
                                                                            {this.props.userDetail.lastLogin && (
                                                                                <label>{DateCoverter.convertSQLDateToString(this.props.userDetail.lastLogin, "MMM d, yyyy")}</label>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </div>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                        </Row>
                                    )}
                                </Container>
                            </Col>
                            <Col className="pl-0 pr-0" sm={12} md={12} lg={4}>
                                <Container>
                                    <Row>
                                        <Col className="pl-0 pr-0 mb-4">
                                            <div className="info-panel editable">
                                                <div className="title-bar">
                                                    <div className="heading">
                                                        <label>Role</label>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <Container>
                                                        <Row>
                                                            <Col className="pl-2 pr-0 pt-0 user-role">
                                                                <Field name="role" type="text" label="" component={FieldWrapper.renderRadioButtonField}
                                                                    options={this.state.roles} onChange={this.onSelectRole}
                                                                    disabled={this.state.submitting} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="collapsible-row pl-0 pr-0" xs={12}>
                                                                <motion.div animate={this.requiresCompany() ? "visible" : "hidden"} initial={this.requiresCompany() ? "visible" : "hidden"}
                                                                    variants={this.collapsibleRowVariants} transition={{ duration: 0.25, delay: (this.requiresRepId() && (this.state.previousRole == Role.Undefined || this.state.previousRole == Role.Buyer) ? 0.2 : 0) }}>
                                                                    <div className="user-company">
                                                                        <div className="company-name">
                                                                            <div className="name">Company</div>
                                                                            <div className="value">
                                                                                <Field name="affiliation" type="text" label="" component={FieldWrapper.renderTextField}
                                                                                    maxLength={50} autoComplete={false} disabled={this.state.submitting} />
                                                                            </div>
                                                                        </div>

                                                                        <motion.div animate={this.requiresRepId() ? "visible" : "hidden"} initial={this.requiresRepId() ? "visible" : "hidden"}
                                                                            variants={this.collapsibleRowVariants}
                                                                            transition={{ duration: ((this.state.previousRole == Role.Undefined || this.state.previousRole == Role.Buyer) ? 0 : 0.25), delay: ((this.state.previousRole == Role.Undefined || this.state.previousRole == Role.Buyer) ? 0.0 : 0) }}>
                                                                            <div className="repid">
                                                                                <div className="name">Rep Id</div>
                                                                                <div className="value">
                                                                                    <Field name="repId" type="text" label="" component={FieldWrapper.renderTextField}
                                                                                            maxLength={10} noSpaces={true} autoComplete={false} disabled={this.state.submitting} />
                                                                                </div>
                                                                            </div>
                                                                        </motion.div>

                                                                    </div>
                                                                </motion.div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="collapsible-row pl-0 pr-0" xs={12}>
                                                                <motion.div animate={this.requiresAccounts() ? "visible" : "hidden"} initial={this.requiresAccounts() ? "visible" : "hidden"}
                                                                    variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                                                                    <div className="user-accounts">
                                                                        <div className="account-list-header">
                                                                            <div className="header-title">
                                                                                {/*<label>Buyer Accounts</label>*/}
                                                                                <Field name="noAccounts" label="Buyer Accounts" component={FieldWrapper.renderFieldPlaceholder} />
                                                                            </div>
                                                                            <div className="header-button-bar">
                                                                                <Button color="secondary" onClick={this.addBuyerAccount} disabled={this.state.submitting}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-plus">
                                                                                        <line x1="12" y1="5" x2="12" y2="19"></line>
                                                                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                                                                    </svg>
                                                                                    <label>Add</label>
                                                                                </Button>
                                                                            </div>                          
                                                                        </div>
                                                                        <div className="account-list">
                                                                            {this.state.buyerAccounts.length == 0 && (
                                                                               <label className="no-accounts">None Assigned</label>
                                                                            )}
                                                                            {this.state.buyerAccounts.map((account: CustomerName, accountIndex: number) => (
                                                                                <div className="account" key={"account-" + accountIndex}>
                                                                                    <div className="account-actions">
                                                                                       <Button color="link" onClick={() => this.removeBuyerAccount(account, accountIndex)} title={"Remove"}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-trash">
                                                                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                                                            </svg>
                                                                                        </Button>
                                                                                    </div>
                                                                                    <div className="account-description">
                                                                                        <div className="two-column">
                                                                                            <div className="billto">
                                                                                                {this.state.displayShipTo && (
                                                                                                    <span>{account.billto_shipto}</span>
                                                                                                )}
                                                                                                {!this.state.displayShipTo && (
                                                                                                    <span>{account.billto}</span>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="description">
                                                                                                {account.locationDescription && !this.state.displayShipTo && (
                                                                                                    <span>{account.locationDescription}</span>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="customer-name">{account.nameOnly}</div>
                                                                                        {account.address && (
                                                                                            <div className="customer-location">
                                                                                                {account.address.street1 && (
                                                                                                    <label className="street">{account.address.street1}</label>
                                                                                                )}
                                                                                                {account.address.street2 && (
                                                                                                    <label className="street">{account.address.street2}</label>
                                                                                                )}
                                                                                                {account.address.street3 && (
                                                                                                    <label className="street">{account.address.street3}</label>
                                                                                                )}
                                                                                                {(account.address.city || account.address.state.code) && (
                                                                                                    <label className="city-state" dangerouslySetInnerHTML={this.formatCityStateZip(account)}></label>
                                                                                                )}
                                                                                                {account.address.country.code && this.isInternational(account.address.country) && (
                                                                                                    <label className="country">{account.address.country.name}</label>
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>                                                              
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </motion.div>
                                                                <Field name="buyerAccountTotal" component="input" type="hidden" />
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 mt-4 button-bar">
                                <Button type="submit" color="primary" disabled={this.state.submitting}>
                                    {this.state.submitting ? "Working" : "Save"}
                                </Button>
                                <Button color="link" onClick={this.onCancel} disabled={this.state.submitting}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="user-form-result pl-0 pr-0 mt-4">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </form>
                )}
                <React.Fragment>
                    <OrderCustomer isOpen={this.state.isSelectingBuyerAccount} clientCode={this.props.client.code}
                        isSaving={false} isSaved={false} onDismiss={this.hideOrderCustomer} />
                </React.Fragment>
            </Container>
        );
    }

    // ----------------
    // HELPERS

    private addBuyerAccount = (): void => {
        this.setState({
            isSelectingBuyerAccount: true
        });
    }

    private convertToBuyerAccount = (customerAccount: CustomerAccount): CustomerName => {
        let customerDetail: CustomerDetail = customerAccount.shipToLocations[0];
        return {
            billto: customerDetail.billto,
            shipto: customerDetail.shipto,
            billto_shipto: customerDetail.billto + '-' + customerDetail.shipto,
            name: customerDetail.name,
            nameOnly: customerDetail.nameOnly,
            number: customerDetail.number,
            address: customerDetail.address,
            locationDescription: customerDetail.locationDescription
        } as CustomerName;
    }

    private convertToUser = (values: any, userDetail: User): User => {
        let role: Role = parseInt(values.role);
        let affiliation: string = "";
        let affiliationType: AffiliationType = AffiliationType.Undefined;
        let buyerAccounts: CustomerName[] = [];
        let repId: string = "";
        switch (role) {
            case Role.Admin:
            case Role.CSR:
                affiliation = values.affiliation || "";
                affiliationType = affiliation.toLowerCase().indexOf('vrlink') >= 0 ? AffiliationType.VRLink : AffiliationType.Client;
                break;
            case Role.Rep:
                affiliation = values.affiliation || "";
                affiliationType = affiliation.toLowerCase().indexOf('vrlink') >= 0 ? AffiliationType.VRLink : AffiliationType.Client;
                repId = values.repId;
                break;
            case Role.Buyer:
                let accountTotal: number = parseInt(values.buyerAccountTotal);
                affiliation = accountTotal == 1 ? this.state.buyerAccounts[0].nameOnly : "Multiple Accounts";
                affiliationType = AffiliationType.Customer;
                buyerAccounts = this.state.buyerAccounts;
                break;
        }
        let user: User = {
            active: userDetail.active,
            affiliation: affiliation,
            affiliationType: affiliationType,
            buyerAccounts: buyerAccounts,
            clientCode: this.props.client.code,
            customer: null,
            customerNumber: "",
            description: "",
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            fullName: userDetail.fullName,
            id: userDetail.id,
            isAuthenticated: userDetail.isAuthenticated,
            preferences: userDetail.preferences,
            role: role,
            salesRepId: repId,
            token: userDetail.token,
            username: values.username,
            created: userDetail.created,
            lastLogin: userDetail.lastLogin
        };
        return user;
    }

    private formatCityStateZip = (account: CustomerName): { __html: string } | undefined => {
        let output: string | undefined = undefined;
        if (account.address) {
            if (account.address.city) {
                output = account.address.city;
                if (account.address.state) {
                    output = output + ', ' + account.address.state.code;
                    if (account.address.postalCode) {
                        output = output + " &nbsp;" + account.address.postalCode;
                    }
                }
            }
            else if (account.address.state) {
                output = account.address.state.code;
                if (account.address.postalCode) {
                    output = output + " &nbsp;" + account.address.postalCode;
                }
            }
        }
        return output ? { __html: output } : undefined;
    }

    private getFormResult = (): string | null => {
        let formResult: string | null = null;
        if (this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) {
                formResult = this.props.messageState.message.text || 'Form contains invalid entries';
            }
            else if (this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) {
                formResult = 'Changes saved';
            }
        }
        return formResult;
    }

    private hideOrderCustomer = (selectedAccount: CustomerAccount | null | undefined): void => {
        if (selectedAccount) {
            let buyerAccount: CustomerName = this.convertToBuyerAccount(selectedAccount);
            let assignedAccounts: CustomerName[] = cloneDeep(this.state.buyerAccounts);
            let matches: CustomerName[] = assignedAccounts.filter((a: CustomerName) => {
                return a.number === buyerAccount.number;
            });
            if (matches.length == 0) {
                assignedAccounts.push(buyerAccount);
                assignedAccounts = assignedAccounts.sort(this.sortAccounts);
                this.props.change("buyerAccountTotal", assignedAccounts.length);
                this.setState({
                    buyerAccounts: assignedAccounts,
                    isSelectingBuyerAccount: false
                });
            }
            else {
                this.setState({
                    isSelectingBuyerAccount: false
                });
            }
        }
        else {
            this.setState({
                isSelectingBuyerAccount: false
            });
        }
    }

    private isDifferentUser = (prevProps: UserFormOwnProps): boolean => {
        let differentUser: boolean = false;
        if (this.props.userDetail && !prevProps.userDetail) {
            differentUser = true;
        }
        else if (prevProps.userDetail && !this.props.userDetail) {
            differentUser = true;
        }
        else if (prevProps.userDetail && this.props.userDetail) {
            differentUser = prevProps.userDetail.id === this.props.userDetail.id ? false : true;
        }
        return differentUser;
    }

    private isInternational = (country: Country): boolean => {
        return country.code !== 'US' ? true : false;
    }

    private isNewUser = (): boolean => {
        let isNew: boolean = true;
        if (this.props.userDetail && this.props.userDetail.id && this.props.userDetail.id > 0) {
            isNew = false;
        }
        return isNew;
    }

    private onCancel = (): void => {
        this.setState({
            canceling: true
        });
        this.props.onCancel();
    }

    private onSelectRole = (event: React.ChangeEvent): void => {
        let previousRole = this.state.selectedRole;
        let selectedValue = event.target.getAttribute('value');

        if (selectedValue) {
            let selectedRole: Role = parseInt(selectedValue);

            let staggerChanges: boolean = false;
            if (this.requiresCompany() && selectedRole === Role.Buyer) {
                staggerChanges = true;
            }
            else if (this.requiresAccounts() && selectedRole !== Role.Buyer) {
                staggerChanges = true;
            }

            if (staggerChanges) {
                this.setState({
                    previousRole: previousRole,
                    selectedRole: Role.Undefined
                });
                setTimeout(() => {
                    this.setState({
                        selectedRole: selectedRole
                    });
                }, 250);
            }
            else {
                this.setState({
                    previousRole: previousRole,
                    selectedRole: selectedRole
                });
            }
        }
    }

    private onValidateUsername = (isValid: boolean): void => {
        this.props.change("usernameValidated", isValid);
    }

    private onUsernameChange = (): void => {
        let username: string | null = this.usernameElement.current ?
            this.usernameElement.current.value : null;
        this.setState({
            username: username
        });
    }

    private receivedActionCompleteMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) ?
            true : false;
    }

    private receivedErrorMessage = (): boolean => {
        return (this.props.messageState && this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) ?
            true : false;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private removeBuyerAccount = (account: CustomerName, accountIndex: number): void => {
        let assignedAccounts: CustomerName[] = cloneDeep(this.state.buyerAccounts);
        if (accountIndex < assignedAccounts.length) {
            assignedAccounts.splice(accountIndex, 1);
            this.props.change("buyerAccountTotal", assignedAccounts.length);
            this.setState({
                buyerAccounts: assignedAccounts
            });
        }
    }

    private requiresAccounts = (): boolean => {
        var requiresAccts: boolean = this.state.selectedRole === Role.Buyer ? true : false;
        return requiresAccts;
    }

    private requiresCompany = (): boolean => {
        var requiresCo: boolean = this.state.selectedRole !== Role.Undefined && !this.requiresAccounts();
        return requiresCo;
    }

    private requiresRepId = (): boolean => {
        var requiresId: boolean = this.state.selectedRole === Role.Rep ? true : false;
        return requiresId;
    }

    private resetPassword = (): void => {
        if (this.props.onResetPassword) {
            this.props.onResetPassword();
        }
    }

    private scrollToBottom = (): void => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }

    private setInitialValues = (): void => {
        if (this.props.userDetail) {
            this.props.change("firstName", this.props.userDetail.firstName);
            this.props.change("lastName", this.props.userDetail.lastName);
            this.props.change("email", this.props.userDetail.email);
            this.props.change("username", this.props.userDetail.username);
            this.props.change("role", this.props.userDetail.role);
            this.props.change("affiliation", this.props.userDetail.affiliation);
            this.props.change("repId", this.props.userDetail.salesRepId);
            this.props.change("buyerAccountTotal", this.props.userDetail.buyerAccounts ? this.props.userDetail.buyerAccounts.length : 0);

            this.setState({
                username: this.props.userDetail.username,
                buyerAccounts: this.props.userDetail.buyerAccounts ? this.props.userDetail.buyerAccounts : []
            });
        }
        else {
            this.props.change("affiliation", this.props.client.name);
            this.props.change("buyerAccountTotal", 0);
        }
    }

    private sortAccounts(cn1: CustomerName, cn2: CustomerName) {
        return cn1.nameOnly < cn2.nameOnly ? -1 : (cn1.nameOnly > cn2.nameOnly ? 1 : 0);
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, MessageStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, UserFormOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'userForm',
    validate: validateUserForm,
    enableReinitialize: true
})(UserForm as any));
