import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

class ScrollToTop extends React.PureComponent<RouteComponentProps> {

    // ----------------
    // CONSTRUCTOR
    constructor(props: RouteComponentProps) {
        super(props);
    }

    // ----------------
    // METHODS

    public componentDidUpdate = (prevProps: RouteComponentProps) => {
        if (this.props.location.pathname != prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    public render = () => {
        return null;
    }
}

// ----------------
// EXPORT

export default withRouter(ScrollToTop);