import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as ProductsStore from '../../store/Products';
import * as UserStore from '../../store/User';
import { Client, ClientSettings, ProductSettings } from '../../store/Client';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import cloneDeep from 'lodash/cloneDeep';

import './PreferencesEdit.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    productsState: {} as ProductsStore.ProductsState,
    userState: {} as UserStore.UserState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, ProductsStore.actionCreators, UserStore.actionCreators)
}

interface PreferencesEditOwnProps {
    client: Client;
    isOpen: boolean,
    onDismiss: (() => void);
    userPreferences: UserStore.UserPreferences;
}

type PreferencesEditProps =
    PreferencesEditOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface PreferencesEditState {
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
    productsPerPageOptions: FieldWrapper.OptionValue[],
    productsPerPageFlag: boolean;
    selectedProductsPerPageOption: FieldWrapper.OptionValue | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validatePreferencesEditForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.productsPerPage) {
        errors.productsPerPage = 'Required';
    }
    return errors;
}

class PreferencesEdit extends React.PureComponent<PreferencesEditProps, PreferencesEditState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: PreferencesEditProps, state: PreferencesEditState) {
        super(props);
        this.state = {
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            productsPerPageFlag: false,
            productsPerPageOptions: this.getProductsPerPageOptions(),
            selectedProductsPerPageOption: this.getDefaultProductsPerPageOption()
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: PreferencesEditProps) => {
        if (this.state.submitting) {
            if (this.receivedErrorMessage()) {
                setTimeout(() => {
                    this.setState({
                        submitting: false
                    });
                    this.props.onDismiss();
                })
            }
            else if (this.receivedValidationError()) {
                let formResult: string | null = this.getFormResult();
                this.props.actions.clearMessage();
                setTimeout(() => {
                    this.setState({
                        submitting: false,
                        changesRejected: true,
                        formResult: formResult
                    });
                })
            }
            else if (this.receivedActionCompleteMessage()) {
                let formResult: string | null = this.getFormResult();
                this.props.actions.clearMessage();
                this.props.actions.clearProductsQuery();
                setTimeout(() => {
                    this.setState({
                        submitting: false,
                        changesAccepted: true,
                        formResult: formResult
                    });
                    setTimeout(() => {
                        this.props.onDismiss();
                    }, 1500);
                })
            }
        }
    }

    public componentWillUnmount = () => {
    }

    public handleFormSubmit = (values: any): void => {
        if (this.props.userState.user && this.state.selectedProductsPerPageOption) {
            this.setState({
                submitting: true,
                changesAccepted: false,
                changesRejected: false,
                formResult: null
            });
            let userPreferences: UserStore.UserPreferences = cloneDeep(this.props.userPreferences);
            userPreferences.productsPerPage = this.state.selectedProductsPerPageOption.value;
            this.props.actions.updateUserPreferences(this.props.userState.user, userPreferences);            
        }
    }

    public render = () => {
        return (
            <Modal id="preferences-edit" isOpen={this.props.isOpen}
                onOpened={this.initializeForm.bind(this)} onClosed={this.resetForm}>
                <ModalHeader toggle={this.props.onDismiss} className={this.state.submitting ? "disabled" : ""}>
                    Edit Preferences
                </ModalHeader>
                <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col className="pl-0 pr-3" xs={6}>
                                    <Field name="productsPerPage" label="Catalog Page Size" component={FieldWrapper.renderDropdownField}
                                        open={this.state.productsPerPageFlag} options={this.state.productsPerPageOptions}
                                        onToggle={this.onToggleProductsPerPageOptions} onSelectOption={this.onSelectProductsPerPageOption}
                                        disabled={this.state.submitting || this.state.changesAccepted} />
                                </Col>
                                <Col classsName="pl-3 pr-0" xs={6}>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Container>
                            <Row>
                                <Col className="button-bar pl-0 pr-0 pr-sm-3">
                                    <Button type="submit" color="primary" disabled={this.state.submitting || this.state.changesAccepted}>
                                        {this.state.submitting ? "Working" : "Save"}
                                    </Button>
                                    <Button color="link" onClick={this.props.onDismiss} disabled={this.state.submitting || this.state.changesAccepted}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pt-4 pr-0 pr-sm-3">
                                    <FormResult
                                        failureResult={this.state.changesRejected}
                                        successResult={this.state.changesAccepted}
                                        description={this.state.formResult} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalFooter>
                </form>
            </Modal>
        );
    }


    // ----------------
    // HELPERS

    private getDefaultProductsPerPageOption = (): FieldWrapper.OptionValue | null | undefined => {
        let selectedOption: FieldWrapper.OptionValue | null | undefined = undefined;
        let productsPerPagePreference: string | null | undefined = this.props.userPreferences.productsPerPage;
        if (productsPerPagePreference) {
            let options = this.getProductsPerPageOptions();
            let matches: FieldWrapper.OptionValue[] = options.filter((option: FieldWrapper.OptionValue) => {
                return option.value === productsPerPagePreference;
            });
            if (matches.length > 0) {
                selectedOption = matches[0];
            }
        }
        return selectedOption;
    }

    private getFormResult = (): string | null => {
        let formResult: string | null = null;
        if (this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) {
                formResult = this.props.messageState.message.text || 'Form contains invalid entries';
            }
            else if (this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) {
                formResult = 'Changes saved';
            }
        }
        return formResult;
    }

    private getProductsPerPageOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        options.push({ label: '12 per page', value: '12' });
        options.push({ label: '24 per page', value: '24' });
        options.push({ label: '48 per page', value: '48' });
        options.push({ label: '96 per page', value: '96' });

        let maxPerPage: number | null = this.props.client.settings.productSettings.maxPerPage;
        let maxPerPageEqualsAll: boolean = this.props.client.settings.productSettings.maxPerPageEqualsAll;
        if (maxPerPage) {
            if (maxPerPageEqualsAll) {
                options.push({ label: 'All (no paging)', value: 'All' })
            }
            else {
                options.push({ label: maxPerPage.toString(), value: maxPerPage.toString() });
            }
        }

        return options;
    }

    private initializeForm = (): void => {
        this.resetForm();
        let selectedProductsPerPageOption: FieldWrapper.OptionValue | null | undefined = this.getDefaultProductsPerPageOption();
        this.setState({
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            productsPerPageFlag: false,
            productsPerPageOptions: this.getProductsPerPageOptions(),
            selectedProductsPerPageOption: selectedProductsPerPageOption
        });
        if (selectedProductsPerPageOption) {
            this.props.change('productsPerPage', selectedProductsPerPageOption.label);
        }
    }

    private onSelectProductsPerPageOption = (selectedOption: FieldWrapper.OptionValue): void => {
        if (selectedOption) {
            this.props.change('productsPerPage', selectedOption.label);
            this.setState({
                selectedProductsPerPageOption: selectedOption
            });
        }
    }

    private onToggleProductsPerPageOptions = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {
        let productsPerPageFlag: boolean = this.state.productsPerPageFlag ? false : true;
        if (productsPerPageFlag) {
            this.props.change('productsPerPage', 'Select...');
        }
        this.setState({
            productsPerPageFlag: productsPerPageFlag
        });
        if (!productsPerPageFlag) {
            setTimeout(() => {
                if (this.state.selectedProductsPerPageOption) {
                    this.props.change('productsPerPage', this.state.selectedProductsPerPageOption.label);
                }
            })
        }
    }

    private receivedActionCompleteMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) ?
            true : false;
    }

    private receivedErrorMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) ?
            true : false;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private resetForm = (): void => {
        this.props.reset();
        if (this.receivedValidationError()) {
            this.props.actions.clearMessage();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        productsState: state.products,
        userState: state.user,
        initialValues: {}
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            ProductsStore.actionCreators,
            UserStore.actionCreators), dispatch)
    }
}

export default connect<{}, {}, PreferencesEditOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'preferencesEditForm',
    validate: validatePreferencesEditForm,
    enableReinitialize: true
})(PreferencesEdit as any));
