import * as React from 'react';
import * as ClientStore from '../../store/Client';
import { Table } from 'reactstrap';

import './TechSupport.scss'

// ----------------
// PROPS

interface TechSupportProps {
    isBordered: boolean,
    isErrorMessage: boolean,
    client: ClientStore.Client | null | undefined
}


class TechSupport extends React.PureComponent<TechSupportProps> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: TechSupportProps) {
        super(props);
    }

    // ----------------
    // METHODS

    public render = () => {
        return (
            <div id="techSupport" className={this.props.isBordered ? "bordered" : ""}>
                {this.props.isErrorMessage && (
                    <p>If error persists, please contact:</p>
                )}
                {!this.props.isErrorMessage && (
                    <p>For further assistance contact:</p>
                )}
                <Table borderless size="sm" className="contact-info">
                    <tbody>
                        <tr>
                            <td colSpan={2}>{this.getTechSupportName()}</td>
                        </tr>
                        <tr>
                            <td className="contact-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-mail">
                                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                    <polyline points="22,6 12,13 2,6"></polyline>
                                </svg>
                            </td>
                            <td>{this.getTechSupportEmail()}</td>
                        </tr>
                        <tr>
                            <td className="contact-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-phone">
                                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                                </svg>
                            </td>
                            <td>{this.getTechSupportPhone()}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private getTechSupportEmail = () => {
        let techSupportEmail = 'info@vrlinkcorp.com';
        if (this.props.client) {
            // TODO override with client-specific support if configured
        }
        return techSupportEmail;
    }

    private getTechSupportName = () => {
        let techSupportName: string = 'VRLink Technical Support';
        if (this.props.client) {
            // TODO override with client-specific support if configured
        }
        return techSupportName;        
    }

    private getTechSupportPhone = () => {
        let techSupportPhone = "904-285-3434";
        if (this.props.client) {
            // TODO override with client-specific support if configured
        }
        return techSupportPhone;
    }
}

// ----------------
// EXPORT

export default TechSupport;
