import * as React from 'react';
import { ProductDetail } from '../../store/Products';

import './ProductDescription.scss';

// ----------------
// PROPS

interface ProductDescriptionProps {
    productDetail: ProductDetail | null | undefined;
}

// ----------------
// LOCAL STATE

interface ProductDescriptionState { }

class ProductDescription extends React.PureComponent<ProductDescriptionProps, ProductDescriptionState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductDescriptionProps, state: ProductDescriptionState) {
        super(props);
        this.state = {};
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <div className="product-description">
                {this.props.productDetail && (
                    <React.Fragment>
                        <div className="two-column-wrapper">
                            <label className="category">{this.props.productDetail.shortDescription}</label>
                            <label className="style-code">
                                {this.props.productDetail.style.sku ? "" : "Style "}
                                {this.props.productDetail.style.sku ? this.props.productDetail.style.sku : this.props.productDetail.style.code}
                            </label>
                        </div>                        
                        <h2 className="style-name">{this.props.productDetail.style.name}</h2>
                        <label className="price">${this.props.productDetail.wholesale.toFixed(2)}</label>
                        <div className="availability">{this.props.productDetail.availability}</div>                    
                    </React.Fragment>
                )}                
            </div>
        );
    }
}

// ----------------
// EXPORT

export default ProductDescription;
