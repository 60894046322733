﻿import { BaseOrder, Order, OrderDetail } from '../store/Orders';
import { OrderDates } from '../store/OrderDates';

// ----------------- 
// METHODS

export const areEqual = (order1: OrderDetail | null | undefined, order2: OrderDetail | null | undefined): boolean => {
    let areDifferent: boolean = false;
    if ((order1 && !order2) || (order2 && !order1)) {
        areDifferent = true;
    }
    else if (order1 && order2) {
        areDifferent = order1.billto != order2.billto;
        areDifferent = areDifferent || (order1.shipto != order2.shipto);
        areDifferent = areDifferent || (order1.shipDate != order2.shipDate);
        areDifferent = areDifferent || (order1.cancelDate != order2.cancelDate);
        areDifferent = areDifferent || (order1.orderStatus != order2.orderStatus);
    }
    return areDifferent ? false : true;
}

export const convertBaseOrderToOrder = (baseOrder: BaseOrder): Order => {
    return {
        billto: baseOrder.billto,
        cancelDate: baseOrder.cancelDate,
        client: baseOrder.client,
        clientCode: baseOrder.client.code,
        createdBy: baseOrder.createdBy,
        customerAccount: baseOrder.customerAccount,
        customerName: baseOrder.customerAccount ? baseOrder.customerAccount.name : "",
        customerNameOnly: baseOrder.customerAccount ? baseOrder.customerAccount.nameOnly : "",
        customerNumber: baseOrder.customerNumber,
        dateCreated: baseOrder.dateCreated,
        dateLastModified: baseOrder.dateLastModified,
        dateSubmitted: baseOrder.dateSubmitted,
        description: baseOrder.description,
        id: baseOrder.id,
        orderDate: baseOrder.orderDate,
        orderNumber: baseOrder.orderNumber,
        orderNumberERP: "",
        orderStatus: baseOrder.orderStatus,
        poNumber: baseOrder.poNumber,
        poRequired: baseOrder.poRequired,
        repName: "",
        season: {
            code: baseOrder.seasonCode,
            name: ""
        },
        seasonCode: baseOrder.seasonCode,
        shipDate: baseOrder.shipDate,
        shipto: baseOrder.shipto,
        total: 0
    }
}

export const convertOrderDetailToOrder = (orderDetail: OrderDetail): Order => {
    return {
        billto: orderDetail.billto,
        cancelDate: orderDetail.cancelDate,
        client: orderDetail.client,
        clientCode: orderDetail.client.code,
        createdBy: orderDetail.createdBy,
        customerAccount: orderDetail.customerAccount,
        customerName: orderDetail.customerAccount ? orderDetail.customerAccount.name : "",
        customerNameOnly: orderDetail.customerAccount ? orderDetail.customerAccount.nameOnly : "",
        customerNumber: orderDetail.customerNumber,
        dateCreated: orderDetail.dateCreated,
        dateLastModified: orderDetail.dateLastModified,
        dateSubmitted: orderDetail.dateSubmitted,
        description: orderDetail.description,
        id: orderDetail.id,
        orderDate: orderDetail.orderDate,
        orderNumber: orderDetail.orderNumber,
        orderNumberERP: "",
        orderStatus: orderDetail.orderStatus,
        poNumber: orderDetail.poNumber,
        poRequired: orderDetail.poRequired,
        repName: "",
        season: {
            code: orderDetail.seasonCode,
            name: ""
        },
        seasonCode: orderDetail.seasonCode,
        shipDate: orderDetail.shipDate,
        shipto: orderDetail.shipto,
        total: 0
    };
}

export const getEarliestCancelDate = (orderDetail: OrderDetail, orderDates: OrderDates, selectedShipDate: Date | null | undefined): Date | null | undefined => {
    let earliestCancelDate: Date | null | undefined = undefined;
    let shipDate: Date | null | undefined = selectedShipDate || getEarliestShipDate(orderDetail, orderDates);
    if (shipDate) {
        let d: Date = new Date((shipDate as Date).getTime());
        let min: number = orderDetail.client.rules.minDaysBeforeCancelDate || 1;
        d.setDate(d.getDate() + min);
        earliestCancelDate = d;
    }
    return earliestCancelDate;
}

export const getEarliestShipDate = (orderDetail: OrderDetail, orderDates: OrderDates): Date | null | undefined => {
    let earliest: Date | null | undefined = new Date();
    if (orderDates) {
        let storedDate: Date = new Date();
        if (orderDates.earliestShipDateNonEmbroidered) {
            storedDate = orderDates.earliestShipDateNonEmbroidered;
        }
        else if (orderDates.earliestShipDateEmbroidered) {
            storedDate = orderDates.earliestShipDateEmbroidered;
        }
        earliest = new Date(storedDate.getTime());
    }
    return earliest;
}