import * as React from 'react';
import { Order } from '../../store/Orders';
import { ListGroup, ListGroupItem } from 'reactstrap';
import OrderDescription from '../order-description/OrderDescription';
import $ from 'jquery';

import './OrderList.scss';

// ----------------
// PROPS

interface OrderListProps {
    orders: Order[];
    onSelectOrder: (order: Order | null | undefined) => void;
    disabled?: boolean;
    autoSelect?: boolean;
}

// ----------------
// LOCAL STATE

interface OrderListState {
    selectedOrder: Order | null | undefined;
}

class OrderList extends React.PureComponent<OrderListProps, OrderListState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderListProps, state: OrderListState) {
        super(props);
        this.state = {
            selectedOrder: undefined
        };
        setTimeout(() => {
            this.preSelectOrder();
        });
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: OrderListProps) => {
        if (this.state.selectedOrder && this.isNewList(prevProps, this.props)) {
            this.setState({
                selectedOrder: undefined
            });
            this.props.onSelectOrder(undefined);
            $('.order-list').scrollTop(0);
            setTimeout(() => {
                this.preSelectOrder();
            })
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <div className="order-list">
            <ListGroup flush>
                {this.props.orders.map((order: Order, orderIndex: number) => (
                    <ListGroupItem key={"order" + orderIndex} className={orderIndex % 2 === 1 ? "odd" : ""}>
                        <label htmlFor={"order-" + order.id}>
                            <span className="selector">
                                <input type="radio" name="order-list-option" id={"order-" + order.id} value={order.id as number}
                                    checked={(this.state.selectedOrder && this.state.selectedOrder.id === order.id) ? true : false}
                                    onChange={() => this.selectOrder(order)} disabled={this.props.disabled ? true : false} />
                            </span>
                            <span className="description">
                                <OrderDescription order={order} hideAccount={true} disabled={this.props.disabled ? true : false} />
                            </span>
                        </label>
                    </ListGroupItem>
                ))}
                </ListGroup>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private getDefaultSelection = (): Order | null | undefined => {
        let defaultSelection: Order | null | undefined = undefined;
        if (this.props.autoSelect) {
            if (this.props.orders.length === 1) {
                defaultSelection = this.props.orders[0];
            }
        }
        return defaultSelection;
    }

    private isNewList = (prevProps: OrderListProps, props: OrderListProps): boolean => {
        let isNew: boolean = (props.orders && !prevProps.orders) || (prevProps.orders && !props.orders);
        if (!isNew) {
            isNew = isNew || prevProps.orders.length != props.orders.length;
            isNew = isNew || prevProps.orders[0].id != props.orders[0].id;
        }
        return isNew;
    }

    private preSelectOrder = (): void => {
        if (!this.state.selectedOrder) {
            let defaultSelection: Order | null | undefined = this.getDefaultSelection();
            if (defaultSelection) {
                this.selectOrder(defaultSelection);

            }
        }
    }

    private selectOrder = (order: Order): void => {
        this.setState({
            selectedOrder: order
        });
        this.props.onSelectOrder(order);
    }
}

// ----------------
// EXPORT

export default OrderList;
