import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store';
import * as OrderDatesStore from '../../store/OrderDates';
import { OrderDateRange } from '../../store/Orders';
import { OrderDateType } from '../../enums/OrderDateType';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import { convertJSDateToString } from '../../common/DateConverter';

import './OrderDateRangeSelector.scss';

// ----------------
// PROPS

const applicationState = {
    orderDatesState: {} as OrderDatesStore.OrderDatesState
}

const actionCreators = {
    actions: Object.assign({}, OrderDatesStore.actionCreators)
};

interface OrderDateRangeSelectorOwnProps {
    isLoading: boolean;
    orderDateRange: OrderDateRange | null;
    onSelectDateRange: (dateRange: OrderDateRange | null) => void;
}

type OrderDateRangeSelectorProps =
    OrderDateRangeSelectorOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;     // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface OrderDateRangeSelectorState {
    changesAccepted: boolean;
    changesRejected: boolean;
    dateFrom: Date | null | undefined;
    dateTo: Date | null | undefined;
    formResult: string | null;
    selectingRange: boolean;
    dateTypes: FieldWrapper.OptionValue[];
}

// ----------------
// FORM VALIDATOR

const validateOrderDateRangeSelectorForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.dateType) {
        errors.dateType = 'Select date filter';
    }
    if (!formValues.dateFrom) {
        errors.dateFrom = 'Required';
    }
    if (!formValues.dateTo) {
        errors.dateTo = 'Required';
    }
    else if (formValues.dateFrom) {
        if (formValues.dateTo < formValues.dateFrom) {
            errors.dateTo = 'Must be after ' + convertJSDateToString(formValues.dateFrom, 'M/d/yy');
        }
    }
    return errors;
}

class OrderDateRangeSelector extends React.PureComponent<OrderDateRangeSelectorProps, OrderDateRangeSelectorState> {

    // ----------------
    // VARIABLES

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderDateRangeSelectorProps, state: OrderDateRangeSelectorState) {
        super(props);
        this.state = {
            changesAccepted: false,
            changesRejected: false,
            dateFrom: undefined,
            dateTo: undefined,
            formResult: null,
            selectingRange: false,
            dateTypes: this.getDateTypes()
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: OrderDateRangeSelectorProps) => { }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        let dateRange: OrderDateRange = {
            dateType: this.getDateType(values.dateType),
            dateFrom: values.dateFrom,
            dateTo: values.dateTo
        };
        this.props.onSelectDateRange(dateRange);
        this.hideRangeSelector();
    }

    public render = () => {
        return (
            <Container className="order-date-range-selector">
                <Row className="selected-range">
                    {this.props.orderDateRange && (
                        <Col className="pl-0 pr-0">
                            {this.props.orderDateRange && (
                                <React.Fragment>
                                    <label className="date-range-description">{OrderDateType[this.props.orderDateRange.dateType]}</label>
                                    <label>{convertJSDateToString(this.props.orderDateRange.dateFrom, 'M/d/yy')}</label>
                                    <label>&nbsp;to&nbsp;</label>
                                    <label>{convertJSDateToString(this.props.orderDateRange.dateTo, 'M/d/yy')}</label>
                                    <span className="divider">|</span>
                                    <Button color="link" onClick={this.clearDateRange} disabled={this.props.isLoading}>Clear</Button>
                                </React.Fragment>
                            )}
                        </Col>
                    )}
                    {!this.props.orderDateRange && (
                        <Col className="pl-0 pr-0">
                            <label>All Dates</label>
                            <span className="divider">|</span>
                            <Button color="link" onClick={this.showRangeSelector} disabled={this.props.isLoading}>Select Range</Button>
                        </Col>
                    )}
                </Row>
                <Modal id="order-date-range-selector-modal" isOpen={this.state.selectingRange}
                    onOpened={this.initializeForm} onClosed={this.resetForm}>
                    <ModalHeader toggle={this.hideRangeSelector}>
                        Select Date Range
                    </ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col className="pl-0 pr-0">
                                    <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                        <div className="date-filter">
                                            <div className="name">Date</div>
                                            <div className="value">
                                                <Field name="dateType" type="string" label="" component={FieldWrapper.renderRadioButtonField}
                                                    options={this.state.dateTypes} />
                                            </div>
                                        </div>
                                        <div className="date-range">
                                            <div className="name">From</div>
                                            <div className="value first">
                                                <Field name="dateFrom" label="" component={FieldWrapper.renderDatePickerField}
                                                    selectedDate={this.state.dateFrom} required={true} onSelectDate={this.changeDateFrom}
                                                    wrapperClass="datefrom-picker" />
                                            </div>
                                            <div className="name second">To</div>
                                            <div className="value second">
                                                <Field name="dateTo" label="" component={FieldWrapper.renderDatePickerField}
                                                    selectedDate={this.state.dateTo} required={true} onSelectDate={this.changeDateTo}
                                                    wrapperClass="dateto-picker" />
                                            </div>
                                        </div>
                                        <button type="submit" ref={this.submitButton}>Submit</button>
                                    </form>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Container>
                            <Row>
                                <Col className="button-bar pl-0 pr-0">
                                    <Button type="button" color="primary" onClick={this.submitOrderDateRangeSelectorForm}>
                                        Save
                                    </Button>
                                    <Button color="link" onClick={() => this.hideRangeSelector()}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pt-4 pr-0">
                                    <FormResult
                                        failureResult={this.state.changesRejected}
                                        successResult={this.state.changesAccepted}
                                        description={this.state.formResult} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }

    // ----------------
    // HELPERS

    private changeDateFrom = (date: Date): void => {
        this.setState({
            dateFrom: date
        });
        this.props.change("dateFrom", date);
    }

    private changeDateTo = (date: Date): void => {
        this.setState({
            dateTo: date
        });
        this.props.change("dateTo", date);
    }

    private clearDateRange = (): void => {
        this.props.onSelectDateRange(null);
    }

    private getDateType = (value: string): OrderDateType => {
        let dateType: OrderDateType = OrderDateType.Undefined;
        switch (value) {
            case '1':
                dateType = OrderDateType.Started;
                break;
            case '2':
                dateType = OrderDateType.Submitted;
                break;
            case '3':
                dateType = OrderDateType.ShipDate;
                break;
        }
        return dateType;
    }    

    private getDateTypes = (): FieldWrapper.OptionValue[] => {
        let dateTypes: FieldWrapper.OptionValue[] = [];
        dateTypes.push({ label: 'Started', value: OrderDateType.Started });
        dateTypes.push({ label: 'Submitted', value: OrderDateType.Submitted });
        dateTypes.push({ label: 'Ship Date', value: OrderDateType.ShipDate });
        return dateTypes;
    }

    private hideRangeSelector = (): void => {
        this.setState({
            selectingRange: false
        });
    }

    private initializeForm = (): void => {
        this.props.change("dateType", null);
        this.props.change("dateFrom", null);
        this.props.change("dateTo", null);
    }

    private resetForm = (): void => {
        this.props.reset();
    }

    private showRangeSelector = (): void => {
        this.setState({
            selectingRange: true
        });
    }

    private submitOrderDateRangeSelectorForm = (): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }
};

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        orderDateState: state.orderDates
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            OrderDatesStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, OrderDateRangeSelectorOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'orderDateRangeSelectorForm',
    validate: validateOrderDateRangeSelectorForm,
    enableReinitialize: true
})(OrderDateRangeSelector as any));
