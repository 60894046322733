import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import * as UserStore from '../../store/User';
import * as DropShipAddressesStore from '../../store/DropShipAddresses';
import { Client } from '../../store/Client';
import { CustomerAccount, CustomerDetail } from '../../store/Customers';
import { OrderDates } from '../../store/OrderDates';
import { OrderDetail, BaseOrder } from '../../store/Orders';
import { ShippingOption, ShippingOptions } from '../../store/ShippingOptions';
import { User } from '../../store/User';
import { Role } from '../../enums/Role';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps, BaseFieldProps } from 'redux-form';
import { Button, Col, Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Row } from 'reactstrap';
import { AnimatePresence, motion } from 'framer-motion';
import AddressForm from '../address-form/AddressForm';
import AddDropShipAddress from '../add-dropship-address/AddDropShipAddress';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../date-picker-input/DatePickerInput';
import { Tooltip } from '@material-ui/core';
import ShipDateRules from '../ship-date-rules/ShipDateRules';
import { CustomerType } from '../../enums/CustomerType';
import { ShipToType } from '../../enums/ShipToType';
import OrderShipTo from '../order-shipto/OrderShipTo';
import { CustomShipping, FreightTerms, ShipVia } from '../../common/AccountTypes';
import { Address, DropShipAddress } from '../../common/AddressTypes';
import { areEqual, getEarliestShipDate, getEarliestCancelDate } from '../../common/OrderFunctions';
import { convertJSDateToString, convertSQLDateToString } from '../../common/DateConverter';
import CopyOrder from '../copy-order/CopyOrder';
import DownloadOrder from '../download-order/DownloadOrder';
import cloneDeep from 'lodash/cloneDeep';
import $ from 'jquery';

import './OrderHeader.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState,
    userState: {} as UserStore.UserState,
    dropShipAddressesState: {} as DropShipAddressesStore.DropShipAddressesState
}

const actionCreators = {
    actions: Object.assign({}, DropShipAddressesStore.actionCreators, MessageStore.actionCreators)
};

interface OrderHeaderAsyncActions {
    asyncActions: {}
}

interface OrderHeaderOwnProps {
    client: Client;
    orderDates: OrderDates;
    orderDetail: OrderDetail;
    onChangeAccount: () => void;
    onChangeShipDate: (shipDate: Date, cancelDate: Date | null | undefined) => void;
    onChangeCancelDate: (date: Date) => void;
    onChangeDropShipAddress: (dropShipAddress: DropShipAddress | null | undefined, shippingOption: ShippingOption | null | undefined) => void;
    onChangeShippingOption: (shippingOption: ShippingOption | null | undefined) => void;
    onChangeShippingLocation: () => void;
    onGoToCopy: (id: number) => void;
    onValidationFailed: () => void;
    onValidationSucceeded: (updatedOrderDetail: OrderDetail) => void;
    shipDateReference: React.RefObject<any>;
    shippingOptions: ShippingOptions;
    validationRequested: boolean;
    isOpenOrder: boolean;
}

type OrderHeaderProps =
    OrderHeaderOwnProps
    & InjectedFormProps 
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & OrderHeaderAsyncActions;

// ----------------
// LOCAL STATE

interface OrderHeaderState {
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    changingDropShipAddress: boolean;
    shipDate: Date;
    cancelDate: Date | null | undefined;
    earliestCancelDate: Date | null | undefined;
    shipViaOptions: FieldWrapper.OptionValue[];
    shipViaFlag: boolean;
    customShippingOptions: FieldWrapper.OptionValue[];
    customShippingFlag: boolean;
    selectedCustomShipping: ShippingOption | null | undefined;
    selectedDropShipping: number;
    useDropShippingOptions: FieldWrapper.OptionValue[];
    unavailableDropShippingOptions: number[];
    showAddDropShipAddress: boolean;
    dropShipAddress: DropShipAddress | null | undefined;
    dropShipCustomerDetail: CustomerDetail | null | undefined;
    orderToCopy: BaseOrder | null;
    orderToDownload: OrderDetail | null;
}

// ----------------
// FORM VALIDATOR

const validateOrderHeaderForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.shipDate) {
        errors.shipDate = 'Required';
    }
    if (!formValues.cancelDate) {
        errors.cancelDate = 'Required';
    }
    else if (formValues.shipDate) {
        if (formValues.cancelDate <= formValues.shipDate) {
            errors.cancelDate = 'Must be after ship date';
        }
    }
    if (formValues.poRequired && !formValues.poNumber) {
        errors.poNumber = 'Required';
    }
    if (formValues.customShippingRequired && !formValues.customShipping) {
        errors.customShipping = 'Required';
    }
    if (formValues.useDropShipping && formValues.useDropShipping.toString() === '1' && !formValues.dropShipAddressId) {
        errors.dropShipAddress = 'Required';
    }
    return errors;
}

const onFormValidationFailed = (errors: any, dispatch: any, submitError: Error, props: OrderHeaderOwnProps) => {
    props.onValidationFailed();
}

class OrderHeader extends React.PureComponent<OrderHeaderProps, OrderHeaderState> {

    // ----------------
    // VARIABLES

    public submitButton: React.RefObject<HTMLButtonElement>;

    public datePickerInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
        return (
            <DatePickerInput datePickerProps={props} readOnly={true} tooltipText="Change Date" />
        )
    }

    public collapsibleFieldVariants = {
        hidden: { height: 0, overflow: 'hidden'},
        visible: { height: 'auto', overflow: 'hidden' }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderHeaderProps, state: OrderHeaderState) {
        super(props);
        this.state = {
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            changingDropShipAddress: false,
            shipDate: this.props.orderDetail.shipDate,
            cancelDate: this.props.orderDetail.cancelDate,
            earliestCancelDate: undefined,
            shipViaOptions: this.getShipViaOptions(),
            shipViaFlag: false,
            customShippingOptions: this.getCustomShippingOptions(),
            customShippingFlag: false,
            selectedCustomShipping: this.getSelectedCustomShipping(this.props.orderDetail.customShipping),
            selectedDropShipping: (this.hasOrRequiresDropShipping() && this.props.isOpenOrder) ? 1 : 0,
            useDropShippingOptions: this.getUseDropShippingOptions(),
            unavailableDropShippingOptions: [],
            showAddDropShipAddress: false,
            dropShipAddress: this.props.orderDetail.dropShipAddress,
            dropShipCustomerDetail: this.convertToCustomerDetail(this.props.orderDetail.dropShipAddress),
            orderToCopy: null,
            orderToDownload: null
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.setInitialValues();
    }

    public componentDidUpdate = (prevProps: OrderHeaderProps) => {
        let orderUpdated: boolean = !areEqual(prevProps.orderDetail, this.props.orderDetail);
        if (orderUpdated) {
            if (!this.state.changingDropShipAddress) {
                this.setInitialValues();
            }
            else {
                setTimeout(() => {
                    this.setState({
                        changingDropShipAddress: false
                    });
                }, 800);
            }           
        }
        if (this.props.validationRequested && !prevProps.validationRequested) {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        }
        if (prevProps.validationRequested && !this.props.validationRequested) {
            this.setState({
                submitting: false
            });
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        let updatedOrderDetail: OrderDetail = cloneDeep(this.props.orderDetail);
        updatedOrderDetail.shipDate = values.shipDate;
        updatedOrderDetail.cancelDate = values.cancelDate;
        updatedOrderDetail.poNumber = values.poNumber;
        updatedOrderDetail.shipVia = values.shipVia;
        updatedOrderDetail.freightTerms = values.freightTerms;
        updatedOrderDetail.instructions = values.instructions;
        updatedOrderDetail.description = values.description;
        updatedOrderDetail.customShipping = {
            code: this.state.selectedCustomShipping ? this.state.selectedCustomShipping.code : null,
            name: this.state.selectedCustomShipping ? this.state.selectedCustomShipping.description : null
        };
        updatedOrderDetail.dropShipAddress = values.dropShipAddressId ? this.state.dropShipAddress : null;
        // TODO repeat for shipvia and freight terms
        this.setState({
            submitting: true
        });
        this.props.onValidationSucceeded(updatedOrderDetail);
    }

    public render = () => {
        return (
            <Container className="order-header">
                <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                    <Row>
                        <Col className="pl-0 pr-0">
                            <Container>
                                <Row>
                                    <Col className="pl-0 pr-0 mb-4" xs={12} sm={6}>
                                        <div className="info-panel editable">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>{this.props.userState.user && this.props.userState.user.role === Role.Buyer ? "Address" : "Customer"}</label>
                                                </div>
                                                {this.props.isOpenOrder && (
                                                    <div className="button-bar">
                                                        {this.props.userState.user && this.props.userState.user.role != Role.Buyer && (
                                                            <Button color="link" onClick={this.props.onChangeAccount} disabled={this.state.submitting}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-edit-3">
                                                                    <path d="M12 20h9"></path>
                                                                    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                                                </svg>
                                                                <label>Change</label>
                                                            </Button>
                                                        )}
                                                        {this.props.userState.user && this.props.userState.user.role === Role.Buyer && this.customerHasMultipleLocations() && (
                                                            <Button color="link" onClick={this.props.onChangeShippingLocation} disabled={this.state.submitting}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-edit-3">
                                                                    <path d="M12 20h9"></path>
                                                                    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                                                </svg>
                                                                <label>Change</label>
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="content">
                                                <Container>
                                                    <Row>
                                                        <Col className="pl-0 pr-0 selected-shipto-preview">
                                                            <AddressForm customerDetail={this.props.orderDetail.shipToCustomer} readonly={true} showLabels={false} showPlaceholder={false} noMargin={true} />
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="pl-0 pr-0 pl-sm-4 mb-4" xs={12} sm={6}>
                                        <div className="info-panel explanatory">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>Dates and Terms</label>
                                                </div>
                                                {this.props.isOpenOrder && (
                                                    <div className="button-bar">
                                                        <Tooltip placement="bottom" className="html-tooltip"
                                                            title={
                                                                <React.Fragment>
                                                                    <ShipDateRules orderDates={this.props.orderDates} />
                                                                </React.Fragment>
                                                            }>
                                                            <span>
                                                                <Button color="link" onClick={this.explainDatesAndTerms} disabled={this.state.submitting}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-help-circle">
                                                                        <circle cx="12" cy="12" r="10"></circle>
                                                                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                                                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                                                    </svg>
                                                                    <label>Help</label>
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="content">
                                                {this.isOpenOrder() && (
                                                    <div className="order-date no-input pad-bottom">
                                                        <div className="name">Started</div>
                                                        <div className="value">{convertSQLDateToString(this.props.orderDetail.dateCreated, "M/d/yyyy")}</div>
                                                    </div>
                                                )}                                                
                                                {!this.isOpenOrder() && this.props.orderDetail.dateSubmitted && (
                                                    <div className="order-date no-input">
                                                        <div className="name">Submitted</div>
                                                        <div className="value">{convertJSDateToString(this.props.orderDetail.dateSubmitted, "M/d/yyyy")}</div>
                                                    </div>
                                                )}
                                                <div className={"order-date" + (this.props.isOpenOrder ? "" : " no-input")}>
                                                    <div className="name">Ship Date</div>                                                                                                        
                                                    {this.props.isOpenOrder && (
                                                        <div className="value">
                                                            <Field name="shipDate" label="" component={FieldWrapper.renderDatePickerField}
                                                                disabled={this.state.submitting} required={true} onSelectDate={this.changeShipDate}
                                                                selectedDate={this.state.shipDate} minDate={getEarliestShipDate(this.props.orderDetail, this.props.orderDates)}
                                                                reference={this.props.shipDateReference} wrapperClass="shipdate-picker" />
                                                        </div>
                                                    )}
                                                    {!this.props.isOpenOrder && (
                                                        <div className="value">{convertJSDateToString(this.props.orderDetail.shipDate, "M/d/yyyy")}</div>
                                                    )}                                                
                                                </div>
                                                <div className={"order-date" + (this.props.isOpenOrder ? "" : " no-input")}>
                                                    <div className="name">Cancel By</div>
                                                    {this.props.isOpenOrder && (
                                                        <div className="value">
                                                            <Field name="cancelDate" label="" component={FieldWrapper.renderDatePickerField}
                                                                disabled={this.state.submitting} required={true} onSelectDate={this.changeCancelDate}
                                                                selectedDate={this.state.cancelDate} minDate={this.state.earliestCancelDate}
                                                                wrapperClass="canceldate-picker" />
                                                        </div>
                                                    )}
                                                    {!this.props.isOpenOrder && this.props.orderDetail.cancelDate && (
                                                        <div className="value">{convertJSDateToString(this.props.orderDetail.cancelDate as Date, "M/d/yyyy")}</div>
                                                    )}
                                                </div>
                                                <div className="order-date no-input">
                                                    <div className="name">Terms</div>
                                                    <div className="value">
                                                        {this.props.orderDetail.terms.name}
                                                    </div>
                                                </div>
                                                {this.props.orderDetail.discount > 0 && (
                                                    <div className="order-date no-input">
                                                        <div className="name">Discount</div>
                                                        <div className="value">{this.formatDiscount(this.props.orderDetail.discount)}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="info-panel-container pl-0 pr-0 mb-4" xs={12} sm={6}>
                                        <div className="info-panel">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>Shipping</label>
                                                </div>
                                            </div>
                                            <div className="content">
                                                {this.props.client.rules.shipViaSelectable && (
                                                    <div className="shipping-option">
                                                        <div className="name">Ship Via</div>
                                                        <div className="value">
                                                            <Field name="shipvia" label="" component={FieldWrapper.renderDropdownField} defaultValue={"Select..."}
                                                                open={this.state.shipViaFlag} disabled={this.state.submitting} required={false}
                                                                options={this.state.shipViaOptions} onSelectOption={this.onSelectShipVia}
                                                                onToggle={this.onToggleShipVia} />
                                                        </div>
                                                    </div>
                                                )}
                                                {this.props.client.rules.freightTermsSelectable && (
                                                    <div className="shipping-option">
                                                        <div className="name">Freight</div>
                                                        <div className="value">
                                                            <Field name="freightTerms" label="" component={FieldWrapper.renderDropdownField} defaultValue={this.getDefaultShipVia()}
                                                                disabled={this.state.submitting} required={false}
                                                                options={[{ label: 'some freight term', value: 'some freight term' }, { label: 'another freight term', value: 'another freight term' }, { label: 'a third freight term', value: 'a third freight term' }, { label: 'a fourth freight term', value: 'a fourth freight term' }, { label: 'a fifth freight term', value: 'a fifth freight term' }]}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {this.props.client.rules.customShippingSelectable && (
                                                    <div className={"shipping-option" + (this.props.isOpenOrder ? "" : " no-input")}>
                                                        <div className="name">Method</div>
                                                        <div className={"value" + (this.state.customShippingFlag ? " allow-overflow" : "")}>
                                                            {this.props.isOpenOrder && (
                                                                <Field name="customShipping" label="" component={FieldWrapper.renderDropdownField}
                                                                    defaultValue={this.getDefaultCustomShippingOption()}
                                                                    disabled={this.state.submitting}
                                                                    open={this.state.customShippingFlag}
                                                                    options={this.state.customShippingOptions}
                                                                    onSelectOption={this.onSelectCustomShipping}
                                                                    onToggle={this.onToggleCustomShipping} />
                                                            )}
                                                            {!this.props.isOpenOrder && (
                                                                <span>{this.props.orderDetail.customShipping.name}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="shipping-option drop-ship">
                                                    <div className="name">Drop Ship</div>
                                                    <div className="value">
                                                        {this.props.isOpenOrder && (
                                                            <Field name="useDropShipping" type="text" label="" component={FieldWrapper.renderRadioButtonField}
                                                                options={this.state.useDropShippingOptions} onChange={this.onSelectDropShipping}
                                                                disabled={this.state.submitting || this.state.changesAccepted}
                                                                disabledOptions={this.state.unavailableDropShippingOptions} />
                                                        )}
                                                        {!this.props.isOpenOrder && (
                                                            <label className="readonly-value">{(this.state.dropShipAddress && this.state.dropShipAddress.id) ? "Yes" : "No"}</label>
                                                        )}                                   
                                                    </div>
                                                </div>
                                                <motion.div animate={this.state.selectedDropShipping === 1 ? "visible" : "hidden"} initial={this.state.selectedDropShipping === 1 ? "visible" : "hidden"}
                                                    variants={this.collapsibleFieldVariants} className="shipping-option drop-ship-address" transition={{ duration: 0.25 }}>
                                                    <React.Fragment>
                                                        <div className="name">Address</div>
                                                        <div className="value">
                                                            {this.state.dropShipAddress && (
                                                                <Button color="link" className="edit-dropship-address" onClick={this.addDropShipAddress} disabled={this.state.submitting}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-edit-3">
                                                                        <path d="M12 20h9"></path>
                                                                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                                                    </svg>
                                                                    <label>Change</label>
                                                                </Button>
                                                            )}
                                                            {!this.state.dropShipAddress && (
                                                                <Field name="dropShipAddress" type="text" label="" component={FieldWrapper.renderLinkButtonField}
                                                                    buttonLabel="Add Drop Ship Address" disabled={this.state.submitting}
                                                                    onClick={this.addDropShipAddress} />
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                </motion.div>
                                                <motion.div animate={this.state.dropShipAddress ? "visible" : "hidden"} initial={this.state.dropShipAddress ? "visible" : "hidden"}
                                                    variants={this.collapsibleFieldVariants} className="selected-dropship-address-preview" transition={{ duration: 0.25 }}>
                                                    <div className="selected-dropship-address-preview-panel">
                                                        {this.state.dropShipCustomerDetail && (
                                                            <AddressForm customerDetail={this.state.dropShipCustomerDetail} readonly={true} showLabels={false} showPlaceholder={false} noMargin={true} />
                                                        )}
                                                    </div>
                                                </motion.div>
                                                <Field name="customShippingRequired" component="input" type="hidden" />
                                                <Field name="dropShippingRequired" component="input" type="hidden" />
                                                <Field name="dropShipAddressId" component="input" type="hidden" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="pl-0 pr-0 pl-sm-4 mb-4" xs={12} sm={6}>
                                        <div className="info-panel editable">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>Billing</label>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <Container>
                                                    <Row>
                                                        <Col className={"pl-0 pr-0 order-po" + (this.props.isOpenOrder ? "" : " no-input")}>
                                                            <div className="name">PO Number</div>
                                                            {this.props.isOpenOrder && (
                                                                <div className="value">
                                                                    <Field name="poNumber" type="text" label="" component={FieldWrapper.renderTextField}
                                                                        maxLength={80} autoComplete={false} autoFocus={true} required={this.requiresPO()}
                                                                        disabled={this.state.submitting || this.state.changesAccepted}
                                                                        onChange={this.changePONumber} />
                                                                    <Field name="poRequired" component="input" type="hidden" />
                                                                </div>
                                                            )}
                                                            {!this.props.isOpenOrder && (
                                                                <div className="value po">
                                                                    <input type="text" readOnly={true} value={this.props.orderDetail.poNumber ? this.props.orderDetail.poNumber : ''} />
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={"pl-0 pr-0 order-billto" + (this.hasBillingAddress() ? " block-address": "")}>
                                                            <div className="name">Address</div>
                                                            <div className="value">
                                                                {this.hasBillingAddress() && (
                                                                    <AddressForm customerDetail={this.props.orderDetail.billToCustomer} readonly={true} showLabels={false} showPlaceholder={false} noMargin={true} />
                                                                )}
                                                                {!this.hasBillingAddress() && (
                                                                    <span>Use customer address</span>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>                                  
                                                </Container>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pl-0 pr-0 mb-4" xs={12}>
                                        <div className="info-panel instructions">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>Instructions</label>
                                                </div>
                                            </div>
                                            <div className="content">
                                                {this.props.isOpenOrder && (
                                                    <Field name="instructions" label="" component={FieldWrapper.renderTextareaField}
                                                        disabled={this.state.submitting} required={false} maxLength={254}
                                                        onChange={this.changeInstructions} />
                                                )}
                                                {!this.props.isOpenOrder && (
                                                    <div className="readonly-value">
                                                        {this.props.orderDetail.instructions}
                                                    </div>
                                                )}
                                                <div className={"description" + (this.props.isOpenOrder ? "" : " no-input")}>
                                                    <div className="name">Description</div>
                                                    {this.props.isOpenOrder && (
                                                        <Field name="description" type="text" label="" component={FieldWrapper.renderTextField}
                                                            maxLength={50} autoComplete={false} autoFocus={false} required={false}
                                                            disabled={this.state.submitting || this.state.changesAccepted}
                                                            onChange={this.changeDescription} />
                                                    )}
                                                    {!this.props.isOpenOrder && (
                                                        <div className="value">{this.props.orderDetail.description}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pl-0 pr-0 mb-4" xs={12}>
                                        <div className="info-panel toolbar">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>Tools</label>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <Button color="secondary" onClick={this.downloadOrder} disabled={this.state.submitting}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-download">
                                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                        <polyline points="7 10 12 15 17 10"></polyline>
                                                        <line x1="12" y1="15" x2="12" y2="3"></line>
                                                    </svg>
                                                    Download
                                                </Button>
                                                <Button color="secondary" onClick={this.copyOrder} disabled={this.state.submitting}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-copy">
                                                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                                    </svg>
                                                    Copy
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <button type="submit" ref={this.submitButton}>Submit</button>
                </form>
                {this.props.isOpenOrder && (
                    <AddDropShipAddress client={this.props.client} isOpen={this.state.showAddDropShipAddress}
                        orderDetail={this.props.orderDetail} shippingOption={this.state.selectedCustomShipping}
                        onDismiss={this.hideAddDropShipAddress} />
                )}
                <CopyOrder client={this.props.client} orderToCopy={this.state.orderToCopy} onDismiss={this.hideCopyOrder} />
                <DownloadOrder client={this.props.client} orderToDownload={this.state.orderToDownload} onDismiss={this.hideDownloadOrder} />
            </Container>
        );
    }

    // ----------------
    // HELPERS

    private addDropShipAddress = (event: React.KeyboardEvent | React.MouseEvent): void => {
        this.setState({
            showAddDropShipAddress: true
        });
    }

    private changeCancelDate = (date: Date): void => {
        this.props.onChangeCancelDate(date);
        this.setState({
            cancelDate: date
        });
        this.props.change("cancelDate", date);
    }

    private changeDescription = (): void => {        
    }

    private changeFreightTerms = (selectedOption: FieldWrapper.OptionValue): void => {
    }

    private changeInstructions = (): void => {       
    }

    private changePONumber = (): void => {
    }

    private changeShipDate = (date: Date): void => {
        let earliestCancelDate: Date | null | undefined = getEarliestCancelDate(this.props.orderDetail, this.props.orderDates, date);
        let cancelDate: Date | null | undefined = this.state.cancelDate;
        if (earliestCancelDate && cancelDate) {
            if (cancelDate != earliestCancelDate) {
                cancelDate = earliestCancelDate;
            }
        }
        this.setState({
            shipDate: date,
            cancelDate: cancelDate,
            earliestCancelDate: earliestCancelDate
        });
        this.props.change("shipDate", date);
        this.props.change("cancelDate", cancelDate);
        this.props.onChangeShipDate(date, cancelDate);
    }

    private convertToCustomerDetail = (dropShipAddress: DropShipAddress | null | undefined): CustomerDetail | null | undefined => {
        let customerDetail: CustomerDetail | null | undefined = undefined;
        if (dropShipAddress) {
            customerDetail = {
                address: dropShipAddress as Address,
                attention: '',
                billto: this.props.orderDetail.billto,
                contact: '',
                displayNumber: '',
                name: dropShipAddress.name,
                nameOnly: dropShipAddress.name,
                number: '',
                phone: dropShipAddress.phone,
                selected: true,
                shipto: null,
                type: CustomerType.Shipping,
                locationDescription: ''
            }
        }
        return customerDetail;
    }

    private copyOrder = (): void => {
        this.setState({
            orderToCopy: this.props.orderDetail
        });
    }

    private customerHasMultipleLocations = (): boolean => {
        let hasMultiple: boolean = false;
        if (this.props.orderDetail.customerAccount) {
            hasMultiple = this.props.orderDetail.customerAccount.shipToLocations.length > 1 ? true : false;
        }
        return hasMultiple;
    }

    private downloadOrder = (): void => {
        this.setState({
            orderToDownload: this.props.orderDetail
        });
    }

    private explainDatesAndTerms = (): void => {
        //    alert('explainDatesAndTerms');
    }

    private formatDiscount = (discount: number): string => {
        let discountAsNumber: number = discount * 100;
        let formatted: string = discount % 1 == 0 ? discount.toFixed(0) : discount.toFixed(2);
        return formatted + '%';
    }

    private getCustomShippingOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        if (this.props.client.rules.customShippingSelectable) {
            for (let n: number = 0; n < this.props.shippingOptions.customOptions.length; n++) {
                let customOption: ShippingOption = this.props.shippingOptions.customOptions[n];
                options.push({ label: customOption.description, value: customOption.code });
            }
        }
        return options;
    }

    private getDefaultCustomShippingOption = (): string => {
        let defaultValue: string = "Select Option...";
        if (this.props.orderDetail.customShipping.name) {
            defaultValue = this.props.orderDetail.customShipping.name
        }
        return defaultValue;
    }

    private getDefaultShipVia = (): string => {
        let defaultValue = 'Select...';
        // TODO check order for value
        return defaultValue;
    }

    private getSelectedCustomShipping = (customShipping: CustomShipping | null | undefined): ShippingOption | null | undefined => {
        let shippingOption: ShippingOption | null | undefined = undefined;
        if (this.props.client.rules.customShippingSelectable && customShipping) {
            let matches: ShippingOption[] = this.props.shippingOptions.customOptions.filter((so: ShippingOption) => {
                return so.code === customShipping.code;
            });
            if (matches.length > 0) {
                shippingOption = matches[0];
            }
        }
        return shippingOption;
    }

    private getSelectedShipToCustomer = (selectedAccount: CustomerAccount): CustomerDetail => {
        let shipto: CustomerDetail[] = selectedAccount.shipToLocations.filter((detail: CustomerDetail) => {
            return detail.selected === true;
        });
        return shipto.length > 0 ? shipto[0] : selectedAccount as CustomerDetail;
    }

    private getShipViaOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        // TODO get real ship via options
        options.push({ label: 'Some ship via option', value: '1' });
        options.push({ label: 'Another ship via option', value: '2' });
        options.push({ label: 'A third ship via option', value: '3' });
        return options;
    }

    private getUseDropShippingOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        options.push({ label: 'No', value: 0 });
        options.push({ label: 'Yes', value: 1 });
        return options;
    }

    private hasBillingAddress = (): boolean => {
        let billing: boolean = this.props.orderDetail.billToCustomer.number != this.props.orderDetail.shipToCustomer.number ? true : false;
        if (billing) {
            billing = this.props.orderDetail.billToCustomer.name ? true : false;
        }
        return billing;
    }

    private hasOrRequiresDropShipping = (): boolean => {
        let hasOrRequired: boolean = this.props.orderDetail.dropShipAddress ? true : false;
        if (!hasOrRequired) {
            let selectedShippingOption: ShippingOption | null | undefined = this.getSelectedCustomShipping(this.props.orderDetail.customShipping);
            hasOrRequired = (selectedShippingOption && selectedShippingOption.dropshipRequired) ? true : false;
        }
        return hasOrRequired;
    }

    private hideAddDropShipAddress = (dropShipAddress: DropShipAddress | null): void => {
        this.setState({
            changingDropShipAddress: true,
            showAddDropShipAddress: false,
            dropShipAddress: dropShipAddress || this.state.dropShipAddress,
            dropShipCustomerDetail: this.convertToCustomerDetail(dropShipAddress || this.state.dropShipAddress)
        });
        if (dropShipAddress) {
            this.props.change("dropShipAddressId", dropShipAddress.id);
            setTimeout(() => {
                this.props.onChangeDropShipAddress(dropShipAddress, this.state.selectedCustomShipping);
            }, 400);
        }
    }

    private hideCopyOrder = (copyOrderId: number | null | undefined): void => {
        this.setState({
            orderToCopy: null
        });
        if (copyOrderId) {
            setTimeout(() => {
                this.props.onGoToCopy(copyOrderId as number);
            }, 400);
        }
    }

    private hideDownloadOrder = (): void => {
        this.setState({
            orderToDownload: null
        });
    }

    private isOpenOrder = (): boolean => {
        return this.props.orderDetail.orderStatus === 'Open';
    }

    private onSelectDropShipping = (event: React.ChangeEvent): void => {
        let selectedValue: string | null = event.target.getAttribute("value");        
        if (selectedValue) {
            let optionValue: number = parseInt(selectedValue || '0');
            if (optionValue === 0 && this.state.dropShipAddress) {
                let assignment: DropShipAddressesStore.DropShipAddressAssignment =
                {
                    orderId: this.props.orderDetail.id as number,
                    dropShipAddress: cloneDeep(this.state.dropShipAddress),
                    assigned: false,
                    shippingOption: this.state.selectedCustomShipping
                };
                setTimeout(() => {
                    this.props.actions.assignDropShipAddress(this.props.client, assignment);
                    if (!assignment.assigned) {
                        this.props.onChangeDropShipAddress(null, this.state.selectedCustomShipping);
                    }
                }, 250);
            }
            this.setState({
                selectedDropShipping: optionValue,
                dropShipAddress: optionValue === 0 ? null : this.state.dropShipAddress,
                dropShipCustomerDetail: optionValue === 0 ? null : this.state.dropShipCustomerDetail
            });
        }
    }

    private onSelectCustomShipping = (selectedOption: FieldWrapper.OptionValue): void => {
        let customShippingOption: ShippingOption[] = this.props.shippingOptions.customOptions.filter((so: ShippingOption) => {
            return so.code === selectedOption.value;
        });
        this.props.change('customShipping', selectedOption.label);
        if (customShippingOption[0].dropshipRequired) {
            this.props.change('useDropShipping', '1');            
        }
        this.props.change('dropShippingRequired', customShippingOption[0].dropshipRequired || this.state.selectedDropShipping === 1 ? true : false);
        this.setState({
            selectedCustomShipping: customShippingOption.length > 0 ? customShippingOption[0] : undefined,
            selectedDropShipping: customShippingOption[0].dropshipRequired ? 1 : this.state.selectedDropShipping,
            unavailableDropShippingOptions: customShippingOption[0].dropshipRequired ? [1] : []
        });
        this.props.onChangeShippingOption(customShippingOption[0]);
    }

    private onSelectShipVia = (selectedOption: FieldWrapper.OptionValue): void => {
        if (selectedOption) {
            this.props.change('shipvia', selectedOption.label);
        }
    }

    private onToggleCustomShipping = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {
        event.preventDefault();
        let customShippingFlag: boolean = !this.state.customShippingFlag;
        if (customShippingFlag) {
            this.props.change('customShipping', 'Select Option...');            
        }
        this.setState({
            customShippingFlag: customShippingFlag
        });
    }

    private onToggleShipVia = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {
        let flag: boolean = this.state.shipViaFlag ? false : true;
        if (flag) {
            this.props.change('shipvia', 'Select...');
        }
        this.setState({
            shipViaFlag: flag
        });        
    }

    private requiresPO = (customerAccount?: CustomerAccount): boolean => {
        let required: boolean = (this.props.clientState.client && this.props.clientState.client.rules.poRequired) ? true : false;
        if (!required) {
            let account: CustomerAccount | null | undefined = customerAccount ? customerAccount : this.props.orderDetail.customerAccount;
            required = (customerAccount && customerAccount.poRequired) ? true : false;
        }
        return required;
    }

    private setInitialValues = (): void => {
        if (this.props.orderDetail) {
            this.props.change("shipDate", this.props.orderDetail.shipDate);
            this.props.change("cancelDate", this.props.orderDetail.cancelDate);
            this.props.change("poNumber", this.props.orderDetail.poNumber);
            this.props.change("poRequired", this.requiresPO());
            this.props.change("shipVia", this.props.orderDetail.shipVia ? this.props.orderDetail.shipVia.code : null);
            this.props.change("freightTerms", this.props.orderDetail.freightTerms ? this.props.orderDetail.freightTerms.code : null);
            this.props.change("customShipping", this.props.orderDetail.customShipping.code ? this.props.orderDetail.customShipping.name : this.getDefaultCustomShippingOption());
            this.props.change("customShippingRequired", this.props.client.rules.customShippingSelectable ? true : false);
            this.props.change("instructions", this.props.orderDetail.instructions);
            this.props.change("description", this.props.orderDetail.description);
            this.props.change("useDropShipping", this.hasOrRequiresDropShipping() ? 1 : 0);
            this.props.change("dropShipAddressId", this.props.orderDetail.dropShipAddress ? this.props.orderDetail.dropShipAddress.id : null);

            this.setState({
                earliestCancelDate: getEarliestCancelDate(this.props.orderDetail, this.props.orderDates, this.props.orderDetail.shipDate),
                selectedDropShipping: (this.hasOrRequiresDropShipping() && this.props.isOpenOrder) ? 1 : 0,
                orderToDownload: null,
                shipDate: this.props.orderDetail.shipDate,
                cancelDate: this.props.orderDetail.cancelDate
            });
        }
    }

    private useSameLocation = (): boolean => {
        let useSame: boolean = false;
        if (this.props.orderDetail.customerAccount) {
            useSame = this.props.orderDetail.customerAccount.shipToLocations.length == 0;
        }
        return useSame;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        ordersState: state.orders,
        userState: state.user,
        dropShipAddressesState: state.dropShipAddresses
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            DropShipAddressesStore.actionCreators,
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
        }
    };
}

export default connect<{}, {}, OrderHeaderOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'orderHeaderForm',
    validate: validateOrderHeaderForm,
    onSubmitFail: onFormValidationFailed,
    enableReinitialize: true
})(OrderHeader as any));
