import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as OrderDatesStore from '../../store/OrderDates';
import * as OrdersStore from '../../store/Orders';
import * as UserStore from '../../store/User';
import { ClientCode } from '../../enums/ClientCode';
import { Order, BaseOrder } from '../../store/Orders';
import { OrderDateRange } from '../../store/Orders';
import { Role } from '../../enums/Role';
import { Button, Col, Container, Row } from 'reactstrap';
import Loader from '../loader/Loader';
import MaterialTable, { Column, Filter, MTableBodyRow, MTableToolbar, Options, Query, QueryResult } from 'material-table';
import { BaseTableFilter } from '../../common/BaseTypes';
import { FilterEx } from '../../common/ExtendedTypes';
import TableFilter from '../table-filter/TableFilter';
import { TableFilterListItem } from '../table-filter/TableFilter';
import * as TableIcons from '../table-icons/TableIcons';
import DragHandle from '@material-ui/icons/DragHandle';
import AddOrder from '../add-order/AddOrder';
import CopyOrder from '../copy-order/CopyOrder';
import DeleteOrder from '../delete-order/DeleteOrder';
import OrderCard from '../order-card/OrderCard';
import OrderDateRangeSelector from '../order-date-range-selector/OrderDateRangeSelector';
import OrderHistory from '../order-history/OrderHistory';
import OrderStatus from '../order-status/OrderStatus';
import { OrderDateType } from '../../enums/OrderDateType';
import { convertJSDateToString, convertSQLDateToJSDate, convertSQLDateToString } from '../../common/DateConverter';
import debounce from 'lodash.debounce';
import $ from 'jquery';

import './Orders.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    orderDatesState: {} as OrderDatesStore.OrderDatesState,
    ordersState: {} as OrdersStore.OrdersState,
    userState: {} as UserStore.UserState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrderDatesStore.actionCreators, OrdersStore.actionCreators )
};

interface OrdersAsyncActions {
    asyncActions: {
        requestOrdersAsync: (client: ClientStore.Client, query: Query<any>) => Promise<QueryResult<any>>;
    }
}

type OrdersProps =
    RouteComponentProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & OrdersAsyncActions;

// ----------------
// LOCAL STATE

interface OrdersState {
    isVisible: boolean;
    isTableInitialized: boolean;
    isTableLoading: boolean;
    isTableError: boolean;
    columns: any[];
    operators: FilterEx[];
    options: Options<any>;
    pageOverride: number | undefined;
    rowCount: number | undefined;
    total: number | undefined;
    useStoredQuery: boolean;
    addingOrder: boolean;
    orderToDelete: Order | null;
    orderDateRange: OrderDateRange | null;
    viewingOrderHistory: boolean;
    orderToCopy: BaseOrder | null;
    orderToViewHistory: Order | null;
}

// ----------------
// CONSTANTS

const ORDERS_PAGE_SIZE: number = 10;

class Orders extends React.PureComponent<OrdersProps, OrdersState> {

    // ----------------
    // VARIABLES

    public tableElement: any;
    public hiddenInputElement: React.RefObject<HTMLInputElement>;

    public ordersVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrdersProps, state: OrdersState) {
        super(props);
        this.state = {
            isVisible: false,
            isTableInitialized: false,
            isTableLoading: false,
            isTableError: false,
            columns: [],
            operators: [],
            options: {
                columnResizable: false,
                debounceInterval: 500,
                doubleHorizontalScroll: true,
                filtering: true,
                fixedColumns: {left: 1},
                initialPage: 0,
                pageSize: ORDERS_PAGE_SIZE,
                pageSizeOptions: [],
                rowStyle: (rowData) => {
                    return {
                        backgroundColor: rowData.orderStatus === 'Open' ? '#ffffff' : '#fafafa',
                        opacity: rowData.orderStatus === 'Open' ? '1' : '0.7'
                    }
                },
                showTitle: true,
                searchAutoFocus: false,
                searchText: this.getStoredSearch(),
                tableLayout: 'fixed',
                thirdSortClick: false 
            },
            rowCount: undefined,
            total: undefined,
            useStoredQuery: true,
            addingOrder: false,
            orderToDelete: null,
            orderDateRange: this.getStoredOrderDateRange(),
            viewingOrderHistory: false,
            orderToCopy: null,
            orderToViewHistory: null,
            pageOverride: this.getPageOverride(ORDERS_PAGE_SIZE)
        };
        this.tableElement = React.createRef();
        this.hiddenInputElement = React.createRef<HTMLInputElement>();
        this.ensureDataFetched();

        // let x: any = this.props.ordersState.orders;
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.props.actions.clearMessage();
        if (this.props.clientState.client && this.props.orderDatesState.orderDates) {
            this.setState({
                isVisible: true,
                columns: this.getColumnDefinitions()
            });
            this.configureTableColumns();
        }
        window.addEventListener('resize', this.onWindowResize);
    }

    public componentDidUpdate = () => {
        if (this.props.clientState.client && !this.state.isVisible) {
            setTimeout(() => {
                this.setState({
                    isVisible: true,
                    columns: this.getColumnDefinitions()
                });
                this.configureTableColumns();
            });
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={this.state.isTableLoading || !this.state.isTableInitialized} />
                <motion.div id="ordersPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"}
                    variants={this.ordersVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className="title justify-content-start d-block d-sm-none">
                            <Col className="pl-0 pr-0 text-left">
                                <h2 className="page-title">Orders</h2>
                            </Col>
                        </Row>
                        <Row className={"table-container" + (this.state.isTableError ? " load-error" : "") + (this.state.isTableLoading ? " loading" : "") + (this.state.isTableInitialized ? " initialized" : "")}>
                            <Col className="pl-0 pr-0">
                                {this.props.clientState.client && (
                                    <MaterialTable
                                        title={"Orders"}
                                        tableRef={this.tableElement}
                                        columns={this.state.columns}
                                        data={this.getOrders}
                                        icons={TableIcons.icons}
                                        options={this.state.options}
                                        onOrderChange={this.onOrderChange}
                                        components={{
                                            OverlayError: props => (
                                                <span></span>
                                            ),
                                            OverlayLoading: props => (
                                                <span></span>
                                            ),
                                            Toolbar: props => (
                                                <div>
                                                    <MTableToolbar {...props} />
                                                    <div className="MuiToolbar-child">
                                                        <OrderDateRangeSelector isLoading={this.state.isTableLoading || !this.state.isTableInitialized} orderDateRange={this.state.orderDateRange}
                                                            onSelectDateRange={this.onChangeDateRange} />
                                                    </div>
                                                </div>
                                            )
                                        }}
                                        actions={[
                                            {
                                                icon: TableIcons.getClipboardEditIcon,
                                                tooltip: "Start Order",
                                                isFreeAction: true,
                                                onClick: this.onAddOrder
                                            },
                                            (rowData: Order) => {
                                                return {
                                                    icon: TableIcons.getEditIcon,
                                                    tooltip: 'Edit Order',
                                                    hidden: rowData.orderStatus !== 'Open',
                                                    onClick: this.onEditOrder
                                                }
                                            },
                                            (rowData: Order) => {
                                                return {
                                                    icon: TableIcons.getSearchIcon,
                                                    tooltip: 'View Order',
                                                    hidden: rowData.orderStatus === 'Open',
                                                    onClick: this.onEditOrder
                                                };
                                            },
                                            (rowData: Order) => {
                                                return {
                                                    icon: TableIcons.getCopyIcon,
                                                    tooltip: 'Copy Order',
                                                    onClick: this.onCopyOrder
                                                }
                                            },
                                            (rowData: Order) => {
                                                return {
                                                    icon: TableIcons.getDeleteIcon,
                                                    tooltip: 'Cancel Order',
                                                    hidden: rowData.orderStatus !== 'Open',
                                                    onClick: this.onDeleteOrder
                                                };
                                            }
                                        ]}
                                        localization={{
                                            header: {
                                                actions: ''
                                            },
                                            body: {
                                                emptyDataSourceMessage: this.state.rowCount === 0 ? 'No orders to display' : ''
                                            }
                                        }}
                                        onChangePage={this.onChangePage}
                                    />
                                )}
                            </Col>
                        </Row>
                        {this.state.isTableError && (
                            <Row className="justify-content-center table-error">
                                <Col className="pt-4" xs={"auto"}>
                                    <Button color="primary" onClick={this.reloadTable}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                            <polyline points="1 4 1 10 7 10"></polyline>
                                            <polyline points="23 20 23 14 17 14"></polyline>
                                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                        </svg>
                                        Reload
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Container>
                    <AddOrder isOpen={this.state.addingOrder} customerNumber={this.getCustomerNumber()} onDismiss={this.hideAddOrder} />
                    <DeleteOrder orderToDelete={this.state.orderToDelete} clientCode={this.props.clientState.client ? this.props.clientState.client.code : ClientCode.Undefined}
                        onDelete={this.onDeleteOrderComplete} onDismiss={this.hideDeleteOrder}
                        onConcurrencyError={this.onDeleteOrderNotAllowed} />
                    <OrderHistory isOpen={this.state.viewingOrderHistory} order={this.state.orderToViewHistory} onDismiss={this.hideOrderHistory}
                        customerName={this.state.orderToViewHistory ? this.state.orderToViewHistory.customerNameOnly : ''} />
                    {this.props.clientState.client && (
                        <CopyOrder client={this.props.clientState.client} orderToCopy={this.state.orderToCopy} onDismiss={this.hideCopyOrder} />
                    )}
                </motion.div>                
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private configureTableColumns = (): void => {
        if (this.tableElement.current) {
            let isSmallScreen: boolean = this.isBreakpoint('sm');
            let columns: Column<any>[] = this.tableElement.current.props.columns;
            if (columns.length > 0) {
                for (let n: number = 0; n < columns.length; n++) {
                    let col: Column<any> = columns[n];
                    if (n == 0) {
                        this.tableElement.current.onChangeColumnHidden(col, !isSmallScreen);
                    }
                    else {
                        this.tableElement.current.onChangeColumnHidden(col, isSmallScreen);
                    }
                }
            }
            else {
                setTimeout(() => {
                    this.configureTableColumns();
                }, 400);
            }
        }
    }

    private ensureDataFetched = () => {
        if (this.props.clientState.client) {
            this.props.actions.requestOrderDates(this.props.clientState.client);
        }
        else {
            setTimeout(() => {
                this.ensureDataFetched();
            }, 250);
        }
    }

    private getColumnDefinitions = (): any[] => {
        let columns: any[] = [];
        if (this.props.clientState.client) {
            switch (this.props.clientState.client.code) {
                case ClientCode.GTN:
                    columns = this.getGTNColumnDefinitions();
                    break;
            }
        }
        return columns;
    }

    private getCurrentDate = (): Date => {
        return (this.props.orderDatesState.orderDates && this.props.orderDatesState.orderDates.currentDate) ?
            this.props.orderDatesState.orderDates.currentDate : new Date();
    }

    private getCustomerNumber = (): string | null => {
        let customerNumber: string | null = null;
        if (this.props.userState.user && this.props.userState.user.role === Role.Buyer) {
            customerNumber = this.props.userState.user.customerNumber;
        }
        return customerNumber;
    }

    private getFilter = (field: string): Filter<any> | undefined => {
        let filter: Filter<any> | undefined = undefined;
        if (this.props.ordersState.orders && this.props.ordersState.orders.query) {
            let matches: Filter<any>[] = this.props.ordersState.orders.query.filters.filter((filter: Filter<any>) => {
                return filter.column.field === field;
            });
            if (matches.length > 0) {
                filter = matches[0];
            }
        }
        return filter;
    }

    private getFilterOperator = (field: string | number | symbol | undefined): string | undefined => {
        let filterOperator: string | undefined = undefined;
        let index: number = this.state.operators.findIndex(f => f.column.field === field);
        if (index >= 0) {
            filterOperator = this.state.operators[index].operatorOverride;
        }
        return filterOperator;
    }

    private getGTNColumnDefinitions = (): any[] => {
        return [
            {
                title: "Order",
                field: "orderNumber",
                filtering: false,
                render: (data: Order, type: "row") => <OrderCard client={this.props.clientState.client} data={data} />
            },
            {
                title: "Order No",
                field: "orderNumber",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("orderNumber"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"} upperCaseInput={true}
                        initialValue={this.getStoredFilter("orderNumber")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "PO",
                field: "poNumber",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("poNumber"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("poNumber")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Customer Name",
                field: "customerNameOnly",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("customerNameOnly"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("customerNameOnly")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Ship Date",
                field: 'shipDate',
                type: 'date',
                dateSetting: {
                    locale: 'en-US',
                    format: 'MM/dd/yyyy'
                },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={'date-picker'}
                        initialValue={this.getStoredFilter('shipDate')}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Total",
                field: "total",
                type: "currency",
                currencySetting: {
                    currencyCode: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'},
                defaultSort: this.getStoredSortOrder("total"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"decimal"}
                        initialValue={this.getStoredFilter("total")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Description",
                field: "description",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("description"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("description")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Status",
                field: "orderStatus",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("orderStatus"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"list"}
                        initialValue={this.getStoredFilterListItem("orderStatus") || { label: 'All', value: '0' }}
                        listItems={[
                            { label: 'All', value: '0' },
                            { label: 'Open', value: 'Open' },
                            { label: 'Submitted', value: 'Submitted' }
                        ]}
                        onFilterChanged={props.onFilterChanged} />,
                render: (data: Order, type: "row") => <OrderStatus order={data} />
            },
            {
                title: "Started By",
                field: "createdBy",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("createdBy"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("createdBy")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Started",
                field: "dateCreated",
                type: "date",
                dateSetting: {
                    locale: "en-US",
                    format: "MM/dd/yyyy"
                },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={'date-picker'}
                        initialValue={this.getStoredFilter('dateCreated')}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Submitted",
                field: "dateSubmitted",
                type: "date",
                dateSetting: {
                    locale: "en-US",
                    format: "MM/dd/yyyy"
                },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={'date-picker'}
                        initialValue={this.getStoredFilter('dateSubmitted')}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Last Modified",
                field: "dateLastModified",
                type: "date",
                dateSetting: {
                    locale: "en-US",
                    format: "MM/dd/yyyy"
                },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={'date-picker'}
                        initialValue={this.getStoredFilter('dateLastModified')}
                        onFilterChanged={props.onFilterChanged} />,
                render: (rowData: Order) => <a href="#" onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.showOrderHistory(e, rowData)}>{convertSQLDateToString(rowData.dateLastModified, "MM/dd/yyyy")}</a>
            },
            {
                title: "NetSuite Id",
                field: "orderNumberERP",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("orderNumberERP"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("orderNumberERP")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Date Range",
                field: "dateRange",
                type: "string",
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"hidden"}
                        initialValue={this.getStoredFilter("dateRange")}
                        hiddenInputRef={this.hiddenInputElement}
                        onFilterChanged={props.onFilterChanged} />
            }
        ]
    }

    private getOrders = (query: Query<any>): Promise<QueryResult<any>> => {

        return new Promise((resolve, reject) => {

            if (!this.state.isTableInitialized && query.filters.length == 0) {
                resolve({
                    data: [],
                    page: 0,
                    totalCount: 0
                })
            }
            else if (this.props.clientState.client) {
                // this.showTableLoader(true, this.state.isTableInitialized, undefined, this.state.total);
                this.showTableLoader(true, true, undefined, this.state.total);
                let mergedQuery: Query<any> = this.mergeQuery(query);
                this.props.asyncActions.requestOrdersAsync(this.props.clientState.client, mergedQuery)
                    .then(result => {
                        if (this.state.pageOverride) {
                            setTimeout(() => {
                                this.tableElement.current.onChangePage({}, this.state.pageOverride);
                            });
                        }
                        resolve(result);
                        this.showTableLoader(false, true, result.data.length, result.totalCount);
                    },
                    err => {
                        this.showTableReloader();
                        resolve({
                            data: [],
                            page: 0,
                            totalCount: 0
                        })
                    })
            }
            else {
                reject("Client is undefined");
            }
        });
    }

    private getPageOverride = (pageSize: number): number | undefined => {
        let pageOverride: number | undefined = undefined;
        if (this.props.ordersState.orders && this.props.ordersState.orders.query) {
            if (this.props.ordersState.orders.query.page > 0) {
                pageOverride = this.props.ordersState.orders.query.page;
                let appliedPageSize: number = this.props.ordersState.orders.query.pageSize;
                if (appliedPageSize !== ORDERS_PAGE_SIZE) {
                    let lastRow: number = appliedPageSize * (pageOverride + 1);
                    if (lastRow <= pageSize) {
                        pageOverride = undefined;
                    }
                    else {
                        pageOverride = Math.floor(lastRow / pageSize);
                    }
                }
            }
        }
        return pageOverride;
    }

    private getStoredFilter = (field: string): string | undefined => {
        let filterFor: string = field;
        let filter: Filter<any> | undefined = this.getFilter(filterFor);
        return filter ? filter.value : undefined;
    }

    private getStoredFilterListItem = (field: string): TableFilterListItem | undefined => {
        let storedFilterListItem: TableFilterListItem | undefined = undefined;
        let storedFilter: string | undefined = this.getStoredFilter(field);
        if (storedFilter) {
            switch (field) {
                case "orderStatus":
                    switch (storedFilter) {
                        case "0":
                            storedFilterListItem = { label: 'All', value: '0' };
                            break;
                        case "Open":
                            storedFilterListItem = { label: 'Open', value: 'Open' };
                            break;
                        case "Submitted":
                            storedFilterListItem = { label: 'Submitted', value: 'Submitted' };
                            break;
                    }
                    break;
            }
        }
        return storedFilterListItem;
    }

    private getStoredFilterOperator = (field: string): string | undefined => {
        let filter: Filter<any> | undefined = this.getFilter(field);
        return filter ? (filter as FilterEx).operatorOverride : undefined;
    }

    private getStoredOrderDateRange = (): OrderDateRange | null => {
        let orderDateRange: OrderDateRange | null = null;
        let filterValue: string | undefined = this.getStoredFilter("dateRange");
        if (filterValue) {            
            let tokens: string[] = filterValue.split('|');
            let dateType: OrderDateType = tokens[0] === '2' ? OrderDateType.ShipDate : tokens[0] === '1' ? OrderDateType.Submitted : OrderDateType.Started;
            let dateFrom: Date = new Date();
            let dateTo: Date = new Date();
            for (let n: number = 1; n <= 2; n++) {
                let token: string = tokens[n];
                let dateTokens: string[] = token.split('-');
                let d: Date = new Date(parseInt(dateTokens[0]), parseInt(dateTokens[1]) - 1, parseInt(dateTokens[2]));
                if (n === 1) {
                    dateFrom = d;
                }
                else {
                    dateTo = d;
                }
                orderDateRange = {
                    dateType: dateType,
                    dateFrom: dateFrom,
                    dateTo: dateTo
                };
            }
        }
        return orderDateRange;
    }

    private getStoredSearch = (): string | undefined => {
        let searchText: string | undefined = undefined;
        if (this.props.ordersState.orders) {
            searchText = this.props.ordersState.orders.query.search;
        }
        return searchText;
    }

    private getStoredSortOrder = (field: string): "asc" | "desc" | undefined => {
        let sortOrder: "asc" | "desc" | undefined = undefined;
        if (this.props.ordersState.orders && this.props.ordersState.orders.query) {
            if (this.props.ordersState.orders.query.orderBy) {
                if (this.props.ordersState.orders.query.orderBy.field === field) {
                    sortOrder = this.props.ordersState.orders.query.orderDirection;
                }
            }
        }
        return sortOrder;
    }

    private goToCopy = (id: number) => {
        this.props.actions.clearMessage();
        let path: string = '/orders/edit/' + id.toString() + '/header';
        this.setState({
            isVisible: false
        });
        setTimeout(() => {
            this.props.history.push(path);
        }, 200);
    }

    private hideAddOrder = (id: number | null | undefined): void => {
        this.setState({
            addingOrder: false
        });
        if (id) {
            setTimeout(() => {
                let url: string = '/orders/edit/' + id + '/header';
                this.props.history.push(url);
            }, 400);
        }
    }

    private hideCopyOrder = (copyOrderId: number | null | undefined): void => {
        this.setState({
            orderToCopy: null
        });
        if (copyOrderId) {
            setTimeout(() => {
                this.goToCopy(copyOrderId as number);
            }, 400);
        }
        else {
            setTimeout(() => {
                this.reloadTable();
            }, 400);
        }
    }

    private hideDeleteOrder = (): void => {
        this.setState({
            orderToDelete: null
        });
    }

    private hideOrderHistory = (): void => {
        this.setState({
            viewingOrderHistory: false,
            orderToViewHistory: null
        });
    }

    private isBreakpoint = (alias: string): boolean => {
        let className: string = '.device-' + alias;
        let element: JQuery<HTMLElement> = $(className);
        return element.is(':visible');
    }

    private mergeQuery = (query: Query<any>): Query<any> => {
        if (this.state.useStoredQuery && this.props.ordersState.orders && !this.onSearchChange(query)) {
            let storedQuery = this.props.ordersState.orders.query;
            if (this.state.isTableInitialized) {
                storedQuery.page = query.page;
                storedQuery.filters = query.filters;
                storedQuery.orderDirection = query.orderDirection;
            }
            else {
                this.setState({
                    isTableInitialized: true
                });
            }
            query = storedQuery;
        }
  
        for (let n: number = 0; n < query.filters.length; n++) {
            (query.filters[n] as FilterEx).operatorOverride = this.getFilterOperator(query.filters[n].column.field);
        }

        return query;
    }

    private onAddOrder = (event: any): void => {
        this.props.actions.clearMessage();
        this.setState({
            addingOrder: true
        });
    }

    private onChangeDateRange = (dateRange: OrderDateRange | null): void => {
        this.setState({
            orderDateRange: dateRange
        });
        if (this.hiddenInputElement && this.hiddenInputElement.current) {
            let filterValue: any = this.serializeDateRange(dateRange);
            let inputValueProperty: PropertyDescriptor | undefined = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value");
            if (inputValueProperty) {
                let setter: any = inputValueProperty.set;
                if (setter) {
                    setter.call(this.hiddenInputElement.current, filterValue);
                    let event: Event = new Event('input', { bubbles: true });
                    this.hiddenInputElement.current.dispatchEvent(event);
                }
            }
        }
    }

    private onChangePage = (page: number, pageSize: number): void => {
        //this.updatePagination();
    }

    private onCopyOrder = (event: any, data: any): void => {
        let order: BaseOrder = data as BaseOrder;
        if (order && this.props.clientState.client) {
            this.setState({
                orderToCopy: order
            });            
        }
    }

    private onEditOrder = (event: any, data: any): void => {
        let order: Order = data as Order;
        if (order) {
            this.props.history.push('/orders/edit/' + order.id + '/header');
        }
    }

    private onDeleteOrder = (event: any, data: any): void => {
        let order: Order = data as Order;
        if (order && this.props.clientState.client) {
            this.setState({
                orderToDelete: order
            });
        }
    }

    private onDeleteOrderComplete = (): void => {
        this.hideDeleteOrder();
        setTimeout(() => {
            this.reloadTable();
        });
    }

    private onDeleteOrderNotAllowed = (): void => {
        this.reloadTable();
    }

    private onFilterOperatorChanged = (field: string, operator: string): void => {
        let operators: FilterEx[] = this.state.operators
        let index: number = operators.findIndex(f => f.column.field === field);
        if (index >= 0) {
            operators[index].operatorOverride = operator;
        }
        else {
            operators.push({
                column: {
                    field: field
                },
                operatorOverride: operator
            } as FilterEx);
        }
        this.setState({
            operators: operators
        });
    }

    private onOrderChange = (orderBy: number, orderDirection: "asc" | "desc"): void => {
        this.setState({
            useStoredQuery: false
        });
    }

    private onSearchChange = (query: Query<any>): boolean => {
        let searchChange: boolean = false;
        if (this.state.useStoredQuery && this.props.ordersState.orders) {
            searchChange = (query.search != this.props.ordersState.orders.query.search);
            if (searchChange) {
                this.setState({
                    useStoredQuery: false
                });
            }
        }
        return searchChange;
    }

    private onWindowResize = debounce((): void => {
        this.configureTableColumns();
    }, 100, { leading: false, trailing: true }).bind(this);

    private reloadTable = () => {
        this.props.actions.clearMessage();
        this.tableElement.current.onQueryChange();
    }

    private serializeDateRange = (dateRange: OrderDateRange | null): string | null => {
        let dateRangeString = null;
        if (dateRange) {
            dateRangeString = dateRange.dateType + '|';
            dateRangeString = dateRangeString + convertJSDateToString(dateRange.dateFrom, 'yyyy-MM-dd') + '|';
            dateRangeString = dateRangeString + convertJSDateToString(dateRange.dateTo, 'yyyy-MM-dd');
        }
        return dateRangeString;
    }

    private setTableLayout = (rowCount: number): void => {
    //    let table: JQuery<Element> = $('.MuiTable-root:first');
    //    table.css({ 'table-layout': rowCount > 1 ? 'fixed' : 'auto' });
    }

    private showOrderHistory = (event: React.MouseEvent | React.KeyboardEvent, order: Order): void => {
        event.preventDefault();
        this.setState({
            viewingOrderHistory: true,
            orderToViewHistory: order
        });
    }

    private showTableLoader = (active: boolean, initialized: boolean, rowCount: number | undefined, total: number | undefined): void => {
        if (this.state.isTableLoading != active) {
            setTimeout(() => {
                this.setState({
                    isTableInitialized: initialized ? true : false,
                    isTableLoading: active ? true : false,
                    isTableError: false,
                    pageOverride: initialized ? undefined : this.state.pageOverride,
                    rowCount: rowCount,
                    total: total
                });
            });
        }
    }

    private showTableReloader = (): void => {
        setTimeout(() => {
            this.setState({
                isTableInitialized: true,
                isTableLoading: false,
                isTableError: true
            });
        }, 500);
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        orderDatesState: state.orderDates,
        ordersState: state.orders,
        userState: state.user
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrderDatesStore.actionCreators,
            OrdersStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestOrdersAsync: (client: ClientStore.Client, query: Query<any>) => dispatch(OrdersStore.actionCreators.requestOrders(client, query))
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Orders as any);
