import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as ProductlistsStore from '../../store/Productlists';
import * as MessageStore from '../../store/Message';
import { ClientCode } from '../../enums/ClientCode';
import { Productlist, ProductlistDetail } from '../../store/Productlists';
import { ProductlistType } from '../../enums/ProductlistType';
import { Button, Col, Container, Row } from 'reactstrap';
import Loader from '../loader/Loader';
import MaterialTable, { Column, Filter, Options, Query, QueryResult } from 'material-table';
import { BaseTableFilter } from '../../common/BaseTypes';
import { FilterEx } from '../../common/ExtendedTypes';
import TableFilter from '../table-filter/TableFilter';
import { TableFilterListItem } from '../table-filter/TableFilter';
import * as TableIcons from '../table-icons/TableIcons';
import AddProductlist from '../add-productlist/AddProductlist';
import CopyProductlist from '../copy-productlist/CopyProductlist';
import DeleteProductlist from '../delete-productlist/DeleteProductlist';
import ProductlistCard from '../productlist-card/ProductlistCard';
import ProductlistHistory from '../productlist-history/ProductlistHistory';
import { convertJSDateToString, convertSQLDateToJSDate, convertSQLDateToString } from '../../common/DateConverter';
import debounce from 'lodash.debounce';
import $ from 'jquery';

import './Productlists.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    productlistsState: {} as ProductlistsStore.ProductlistsState,
    messageState: {} as MessageStore.MessageState
}

const actionCreators = {
    actions: Object.assign({}, ProductlistsStore.actionCreators, MessageStore.actionCreators)
};

interface ProductlistsAsyncActions {
    asyncActions: {
        requestProductlistsAsync: (clientCode: ClientCode, query: Query<Productlist>) => Promise<QueryResult<Productlists>>;
    }
}

type ProductlistsProps =
    RouteComponentProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & ProductlistsAsyncActions;

// ----------------
// LOCAL STATE

interface ProductlistsState {
    isVisible: boolean;
    isTableInitialized: boolean;
    isTableLoading: boolean;
    isTableError: boolean;
    columns: any[];
    operators: FilterEx[];
    options: Options<any>;
    pageOverride: number | undefined;
    rowCount: number | undefined;
    useStoredQuery: boolean;
    addingProductlist: boolean;   
    viewingProductlistHistory: boolean;
    productlistToViewHistory: Productlist | null;
    productlistToCopy: ProductlistDetail | null | undefined;
    productlistToDelete: Productlist | null;
}

// ----------------
// CONSTANTS

const PRODUCTLISTS_PAGE_SIZE: number = 10;

class Productlists extends React.PureComponent<ProductlistsProps, ProductlistsState> {

    // ----------------
    // VARIABLES

    public tableElement: any;

    public productlistsVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductlistsProps, state: ProductlistsState) {
        super(props);
        this.state = {
            isVisible: false,
            isTableInitialized: false,
            isTableLoading: false,
            isTableError: false,
            columns: [],
            operators: [],
            options: {
                columnResizable: false,
                debounceInterval: 500,
                doubleHorizontalScroll: true,
                filtering: true,
                fixedColumns: { left: 1 },
                initialPage: 0,
                pageSize: PRODUCTLISTS_PAGE_SIZE,
                pageSizeOptions: [],
                showTitle: true,
                searchAutoFocus: false,
                searchText: this.getStoredSearch(),
                tableLayout: 'fixed',
                thirdSortClick: false
            },
            rowCount: undefined,
            useStoredQuery: false,
            pageOverride: this.getPageOverride(PRODUCTLISTS_PAGE_SIZE),
            addingProductlist: false,
            viewingProductlistHistory: false,
            productlistToViewHistory: null,
            productlistToCopy: null,
            productlistToDelete: null
        };
        this.tableElement = React.createRef();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.props.actions.clearMessage();
        if (this.props.clientState.client) {
            this.setState({
                isVisible: true,
                columns: this.getColumnDefinitions()
            });
            this.configureTableColumns();
        }
        window.addEventListener('resize', this.onWindowResize);
    }

    public componentDidUpdate = (prevProps: ProductlistsProps) => {
        if (this.props.clientState && (!this.state.isVisible || this.state.columns.length === 0)) {
            setTimeout(() => {
                this.setState({
                    isVisible: true,
                    columns: this.getColumnDefinitions()
                });
                this.configureTableColumns();
            })
        }
    }

    public componentWillUnmount = () => {
        window.removeEventListener('resize', this.onWindowResize);
    }

    public convertProductlistToDetail = (productlist: Productlist): ProductlistDetail | null | undefined => {
        let productlistDetail: ProductlistDetail | null | undefined = undefined;
        if (productlist) {
            productlistDetail = {
                accountRequired: productlist.accountRequired,
                assignedEventLists: [],
                billto: productlist.billto,
                client: productlist.client,
                createdBy: productlist.createdBy,
                customerAccount: productlist.customerAccount,
                customerName: productlist.customerName,
                customerNameOnly: productlist.customerNameOnly,
                customerNumber: productlist.customerNumber,
                dateCreated: productlist.dateCreated,
                dateLastModified: productlist.dateLastModified,
                eventListId: undefined,
                id: productlist.id,
                isEditable: productlist.isEditable,
                listType: productlist.listType,
                name: productlist.name,
                shipto: productlist.shipto,
                userId: productlist.userId
            };
        }
        return productlistDetail;
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={(this.state.isTableLoading == true || this.state.isTableInitialized == false)} />
                <motion.div id="productlistsPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"}
                    variants={this.productlistsVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className="title justify-content-start d-block d-sm-none">
                            <Col className="pl-0 pr-0 text-left">
                                <h2 className="page-title">Product Lists</h2>
                            </Col>
                        </Row>
                        <Row className={"table-container" + (this.state.isTableError ? " load-error" : "") + (this.state.isTableLoading ? " loading" : "") + (this.state.isTableInitialized ? " initialized" : "")}>
                            <Col className="pl-0 pr-0">
                                {this.props.clientState.client && (
                                    <MaterialTable
                                        title={"Product Lists"}
                                        tableRef={this.tableElement}
                                        columns={this.state.columns}
                                        data={this.getProductlists}
                                        icons={TableIcons.icons}
                                        options={this.state.options}
                                        onOrderChange={this.onOrderChange}
                                        components={{
                                            OverlayError: props => (
                                                <span></span>
                                            ),
                                            OverlayLoading: props => (
                                                <span></span>
                                            )
                                        }}
                                        actions={[
                                            {
                                                icon: TableIcons.getAddIcon,
                                                tooltip: 'Add List',
                                                isFreeAction: true,
                                                onClick: this.onAddProductlist
                                            },
                                            (rowData: Productlist) => {
                                                return {
                                                    icon: TableIcons.getEditIcon,
                                                    tooltip: 'Edit List',
                                                    hidden: !rowData.isEditable,
                                                    onClick: this.onEditProductlist
                                                }
                                            },
                                            (rowData: Productlist) => {
                                                return {
                                                    icon: TableIcons.getCopyIcon,
                                                    tooltip: 'Copy List',
                                                    onClick: this.onCopyProductlist
                                                }
                                            },
                                            (rowData: Productlist) => {
                                                return {
                                                    icon: TableIcons.getDeleteIcon,
                                                    tooltip: 'Delete List',
                                                    hidden: !rowData.isEditable,
                                                    onClick: this.onDeleteProductlist
                                                }
                                            },
                                            (rowData: Productlist) => {
                                                return {
                                                    icon: TableIcons.getSearchIcon,
                                                    tooltip: 'View List',
                                                    hidden: rowData.isEditable,
                                                    onClick: this.onEditProductlist
                                                }
                                            }
                                        ]}
                                        localization={{
                                            header: {
                                                actions: ''
                                            },
                                            body: {
                                                emptyDataSourceMessage: this.state.rowCount === 0 ? 'No lists to display' : ''
                                            }
                                        }}
                                        onChangePage={this.onChangePage}
                                    />
                                )}
                            </Col>
                        </Row>
                        {this.state.isTableError && (
                            <Row className="justify-content-center table-error">
                                <Col className="pt-4" xs={"auto"}>
                                    <Button color="primary" onClick={this.reloadTable}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                            <polyline points="1 4 1 10 7 10"></polyline>
                                            <polyline points="23 20 23 14 17 14"></polyline>
                                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                        </svg>
                                        Reload
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Container>
                    <AddProductlist isOpen={this.state.addingProductlist} onDismiss={this.hideAddProductlist} />                    
                    <DeleteProductlist productlistToDelete={this.state.productlistToDelete} client={this.props.clientState.client}
                        onDelete={this.onDeleteProductlistComplete} onDismiss={this.hideDeleteProductlist}
                        onConcurrencyError={this.onDeleteProductlistNotAllowed} />
                    {this.props.clientState.client && (
                        <React.Fragment>
                            <CopyProductlist client={this.props.clientState.client} productlistToCopy={this.state.productlistToCopy}
                                onDismiss={this.hideCopyProductlist} />
                            <ProductlistHistory isOpen={this.state.viewingProductlistHistory} productlist={this.state.productlistToViewHistory}
                                client={this.props.clientState.client} onDismiss={this.hideProductlistHistory} />
                        </React.Fragment>
                    )}
                </motion.div>
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private configureTableColumns = (): void => {
        if (this.tableElement.current) {
            let isSmallScreen: boolean = this.isBreakpoint('sm');
            let columns: Column<any>[] = this.tableElement.current.props.columns;
            if (columns.length > 0) {
                for (let n: number = 0; n < columns.length; n++) {
                    let col: Column<any> = columns[n];
                    if (n == 0) {
                        this.tableElement.current.onChangeColumnHidden(col, !isSmallScreen);
                    }
                    else {
                        this.tableElement.current.onChangeColumnHidden(col, isSmallScreen);
                    }
                }
            }
            else {
                setTimeout(() => {
                    this.configureTableColumns();
                }, 400);
            }
        }
    }

    private getColumnDefinitions = (): any[] => {
        let columns: any[] = [];
        if (this.props.clientState.client) {
            switch (this.props.clientState.client.code) {
                default:
                    columns = this.getDefaultColumnDefinitions();
                    break;
            }
        }
        return columns;
    }

    private getDefaultColumnDefinitions = (): any[] => {
        return [
            {
                title: "List",
                field: "name",
                filtering: false,
                render: (data: Productlist, type: "row") => <ProductlistCard client={this.props.clientState.client} data={data} />                
            },
            {
                title: "List No",
                field: "id",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },                
                defaultSort: this.getStoredSortOrder("id"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"integer"}
                        initialValue={this.getStoredFilter("id")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "List Name",
                field: "name",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("name"),
                filterComponent: (props: BaseTableFilter) => 
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("name")}
                        onFilterChanged={props.onFilterChanged} />
                
            },
            {
                title: "Type",
                field: 'listType',
                type: "string",
                align: "left",
                defaultSort: this.getStoredSortOrder("type"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"list"}
                        initialValue={this.getStoredFilterListItem("type") || { label: 'All', value: '0' }}
                        listItems={[
                            { label: 'All', value: '0' },
                            { label: 'Public', value: '1' },
                            { label: 'Private', value: '2' }
                        ]}
                        onFilterChanged={props.onFilterChanged} />,
                render: (rowData: Productlist) => <span>{ProductlistType[rowData.listType]}</span>
            },
            {
                title: 'Created By',
                field: 'createdBy',
                type: 'string',
                align: 'left',
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("createdBy"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("createdBy")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Last Modified",
                field: "dateLastModified",
                type: "date",
                dateSetting: {
                    locale: "en-US",
                    format: "MM/dd/yyyy"
                },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={'date-picker'}
                        initialValue={this.getStoredFilter('dateLastModified')}
                        onFilterChanged={props.onFilterChanged} />,
                render: (rowData: Productlist) => <a href="#" onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.showProductlistHistory(e, rowData)}>{convertSQLDateToString(rowData.dateLastModified, "MM/dd/yyyy")}</a>
            },
            {
                title: "Customer Name",
                field: "customerNameOnly",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("customerNameOnly"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("customerNameOnly")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Customer No",
                field: "billto",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("billto"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("billto")}
                        onFilterChanged={props.onFilterChanged} />
            }
        ];
    }

    private getFilter = (field: string): Filter<any> | undefined => {
        let filter: Filter<any> | undefined = undefined;
        if (this.props.productlistsState.productlists && this.props.productlistsState.productlists.query) {
            let matches: Filter<any>[] = this.props.productlistsState.productlists.query.filters.filter((filter: Filter<any>) => {
                return filter.column.field === field;
            });
            if (matches.length > 0) {
                filter = matches[0];
            }
        }
        return filter;
    }

    private getFilterOperator = (field: string | number | symbol | undefined): string | undefined => {
        let filterOperator: string | undefined = undefined;
        let index: number = this.state.operators.findIndex(f => f.column.field === field);
        if (index >= 0) {
            filterOperator = this.state.operators[index].operatorOverride;
        }
        return filterOperator;
    }

    private getPageOverride = (pageSize: number): number | undefined => {
        let pageOverride: number | undefined = undefined;
        if (this.props.productlistsState.productlists && this.props.productlistsState.productlists.query) {
            if (this.props.productlistsState.productlists.query.page > 0) {
                pageOverride = this.props.productlistsState.productlists.query.page;
                let appliedPageSize: number = this.props.productlistsState.productlists.query.pageSize;
                if (appliedPageSize !== PRODUCTLISTS_PAGE_SIZE) {
                    let lastRow: number = appliedPageSize * (pageOverride + 1);
                    if (lastRow <= pageSize) {
                        pageOverride = undefined;
                    }
                    else {
                        pageOverride = Math.floor(lastRow / pageSize);
                    }
                }
            }
        }
        return pageOverride;
    }

    private getProductlists = (query: Query<any>): Promise<QueryResult<any>> => {

        return new Promise((resolve, reject) => {

            if (!this.state.isTableInitialized && query.filters.length == 0) {
                resolve({
                    data: [],
                    page: 0,
                    totalCount: 0
                });
            }
            else if (this.props.clientState.client) {
                this.showTableLoader(true, this.state.isTableInitialized, undefined);
                let mergedQuery: Query<any> = this.mergeQuery(query);
                this.props.asyncActions.requestProductlistsAsync(this.props.clientState.client.code, mergedQuery)
                    .then(result => {
                        resolve(result);
                        this.showTableLoader(false, true, result.data.length);
                    },
                    err => {
                        this.showTableReloader();
                        resolve({
                            data: [],
                            page: 0,
                            totalCount: 0
                        })
                    });
            }
            else {
                return ("Client is undefined");
            }
        })
    }

    private getStoredFilter = (field: string): string | undefined => {
        let filterFor: string = field;
        let filter: Filter<any> | undefined = this.getFilter(filterFor);
        return filter ? filter.value : undefined;
    }

    private getStoredFilterListItem = (field: string): TableFilterListItem | undefined => {
        let storedFilterListItem: TableFilterListItem | undefined = undefined;
        let storedFilter: string | undefined = this.getStoredFilter(field);
        if (storedFilter) {
            switch (field) {
                case "type":
                    switch (storedFilter) {
                        case "0":
                            storedFilterListItem = { label: 'All', value: ProductlistType.Undefined.toString() };
                            break;
                        case "1":
                            storedFilterListItem = { label: 'Private', value: ProductlistType.Private.toString() };
                            break;
                        case "2":
                            storedFilterListItem = { label: 'Public', value: ProductlistType.Public.toString() };
                            break;
                    }
                    break;
            }
        }
        return storedFilterListItem;
    }

    private getStoredFilterOperator = (field: string): string | undefined => {
        let filter: Filter<any> | undefined = this.getFilter(field);
        return filter ? (filter as FilterEx).operatorOverride : undefined;
    }

    private getStoredSearch = (): string | undefined => {
        let searchText: string | undefined = undefined;
        if (this.props.productlistsState.productlists) {
            searchText = this.props.productlistsState.productlists.query.search;
        }
        return searchText;
    }

    private getStoredSortOrder = (field: string): "asc" | "desc" | undefined => {
        let sortOrder: "asc" | "desc" | undefined = undefined;
        if (this.props.productlistsState.productlists && this.props.productlistsState.productlists.query) {
            if (this.props.productlistsState.productlists.query.orderBy) {
                if (this.props.productlistsState.productlists.query.orderBy.field === field) {
                    sortOrder = this.props.productlistsState.productlists.query.orderDirection;
                }
            }
        }
        return sortOrder;
    }

    private goToCopy = (id: number) => {
        this.props.actions.clearMessage();
        let path: string = '/productlists/edit/' + id.toString();
        setTimeout(() => {
            this.props.history.push(path);
        }, 200);
    }

    private hideAddProductlist = (id: number | null | undefined): void => {
        this.setState({
            addingProductlist: false
        });
        if (id) {
            setTimeout(() => {
                let url: string = '/productlists/edit/' + id;
                this.props.history.push(url);
            }, 400);
        }
    }

    private hideCopyProductlist = (copyProductlistId: number | null | undefined): void => {
        this.setState({
            productlistToCopy: null
        });
        if (copyProductlistId) {
            setTimeout(() => {
                this.goToCopy(copyProductlistId as number);
            }, 400);
        }
        else {
            setTimeout(() => {
                this.reloadTable();
            }, 400);
        }
    }

    private hideDeleteProductlist = (): void => {
        this.setState({
            productlistToDelete: null
        });
    }

    private hideProductlistHistory = (): void => {
        this.setState({
            viewingProductlistHistory: false,
            productlistToViewHistory: null
        });
    }

    private isBreakpoint = (alias: string): boolean => {
        let className: string = '.device-' + alias;
        let element: JQuery<HTMLElement> = $(className);
        return element.is(':visible');
    }

    private mergeQuery = (query: Query<any>): Query<any> => {
        if (this.state.useStoredQuery && this.props.productlistsState.productlists && !this.onSearchChange(query)) {
            let storedQuery = this.props.productlistsState.productlists.query;
            if (this.state.isTableInitialized) {
                storedQuery.page = query.page;
                storedQuery.filters = query.filters;
                storedQuery.orderDirection = query.orderDirection;
            }
            else {
                this.setState({
                    isTableInitialized: true
                });
            }
        }

        for (let n: number = 0; n < query.filters.length; n++) {
            (query.filters[n] as FilterEx).operatorOverride = this.getFilterOperator(query.filters[n].column.field);
        }

        return query;
    }

    private onAddProductlist = (event: any): void => {
        this.props.actions.clearMessage();
        this.setState({
            addingProductlist: true
        });
    }

    private onChangePage = (page: number, pageSize: number): void => {
    }

    private onCopyProductlist = (event: any, data: any): void => {
        let productlist: Productlist = data as Productlist;
        if (productlist && this.props.clientState.client) {
            let productlistDetail: ProductlistDetail | null | undefined = this.convertProductlistToDetail(productlist);
            if (productlistDetail) {
                this.setState({
                    productlistToCopy: productlistDetail
                });
            }
        }
    }

    private onDeleteProductlist = (event: any, data: any): void => {
        let productlist: Productlist = data as Productlist;
        if (productlist && this.props.clientState.client) {
            this.setState({
                productlistToDelete: productlist
            });
        }
    }

    private onDeleteProductlistComplete = (): void => {
        this.hideDeleteProductlist();
        setTimeout(() => {
            this.reloadTable();
        })
    }

    private onDeleteProductlistNotAllowed = (): void => {
        this.reloadTable();
    }

    private onEditProductlist = (event: any, data: any): void => {
        let productlist: Productlist = data as Productlist;
        if (productlist) {
            this.props.history.push('/productlists/edit/' + productlist.id);
        }
    }

    private onOrderChange = (orderBy: number, orderDirection: "asc" | "desc"): void => {
        this.setState({
            useStoredQuery: false
        });
    }

    private onSearchChange = (query: Query<any>): boolean => {
        let searchChange: boolean = false;
        if (this.state.useStoredQuery && this.props.productlistsState.productlists) {
            searchChange = (query.search != this.props.productlistsState.productlists.query.search);
            if (searchChange) {
                this.setState({
                    useStoredQuery: false
                });
            }
        }
        return searchChange;
    }

    private onViewDetail = (event: any, data: any): void => {
        let productlist: Productlist = data as Productlist;
        if (productlist) {
            //this.props.history.push('/productlists/edit/' + productlist.id);
        }
    }

    private onWindowResize = debounce((): void => {
        this.configureTableColumns();
    }, 100, { leading: false, trailing: true }).bind(this);

    private reloadTable = () => {
        this.props.actions.clearMessage();
        this.tableElement.current.onQueryChange();
    }

    private showProductlistHistory = (event: React.MouseEvent | React.KeyboardEvent, productlist: Productlist): void => {
        event.preventDefault();
        this.setState({
            viewingProductlistHistory: true,
            productlistToViewHistory: productlist
        });
    }

    private showTableLoader = (active: boolean, initialized: boolean, rowCount: number | undefined): void => {
        if (this.state.isTableLoading != active) {
            setTimeout(() => {
                this.setState({
                    isTableInitialized: initialized ? true : false,
                    isTableLoading: active ? true : false,
                    isTableError: false,
                    rowCount: rowCount
                });
            });
        }
    }

    private showTableReloader = (): void => {
        setTimeout(() => {
            this.setState({
                isTableInitialized: true,
                isTableLoading: false,
                isTableError: true
            });
        }, 500);
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        productlistsState: state.productlists,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            ProductlistsStore.actionCreators,
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestProductlistsAsync: (clientCode: ClientCode, query: Query<Productlist>) => dispatch(ProductlistsStore.actionCreators.requestProductlists(clientCode, query))
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Productlists as any);
