import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import { CustomerAccount } from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import { ClientCode } from '../../enums/ClientCode';
import { NewLogo } from '../../common/EmbroideryTypes';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import TapeImporter from '../tape-importer/TapeImporter';
import FormResult from '../form-result/FormResult';

import './AddLogo.scss'

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
};

interface AddLogoOwnProps {
    isOpen: boolean;
    clientCode: ClientCode;
    customer: CustomerAccount;
    onDismiss: (newLogo: NewLogo | null | undefined) => void;
}

type AddLogoProps =
    AddLogoOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;     // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface AddLogoState {
    submitting: boolean;
    changesAccepted: boolean,
    changesRejected: boolean,
    formResult: string | null;
    submitRequested: boolean;
}

// ----------------
// FORM VALIDATOR

class AddLogo extends React.PureComponent<AddLogoProps, AddLogoState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: AddLogoProps, state: AddLogoState) {
        super(props);
        this.state = {
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            submitRequested: false
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: AddLogoProps) => {
        if (this.state.submitting) {
            if (this.receivedErrorMessage()) {
                this.props.onDismiss(null);
            }
            else if (this.receivedValidationError()) {
                let formResult: string | null = this.getFormResult();
                setTimeout(() => {
                    this.props.actions.clearMessage();
                    this.setState({
                        submitting: false,
                        submitRequested: false,
                        changesRejected: true,
                        formResult: formResult
                    });
                })
            }
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <Modal id="add-logo" isOpen={this.props.isOpen} onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(undefined)}>
                    Add Logo
                </ModalHeader>
                <ModalBody>
                    <TapeImporter isOpen={this.props.isOpen} clientCode={this.props.clientCode} customer={this.props.customer}
                        submitRequested={this.state.submitRequested} onSubmit={this.onFormSubmit}
                        onSubmitFail={this.onFormSubmitFail} onSubmitSucceed={this.onFormSubmitSucceed} />
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0 pr-sm-3">
                                <Button type="button" color="primary" disabled={this.state.submitting || this.state.changesAccepted}
                                    onClick={this.requestSubmit}>
                                    {this.state.submitting ? "Working" : "Save"}
                                </Button>
                                <Button color="link" onClick={() => this.props.onDismiss(null)} disabled={this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private getFormResult = (): string | null => {
        let formResult: string | null = null;
        if (this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) {
                formResult = this.props.messageState.message.text || 'Form contains invalid entries';
            }
        }
        return formResult;
    }

    private initializeForm = (): void => { }

    private onFormSubmit = (submitting: boolean): void => {
        this.setState({
            formResult: null,
            submitRequested: false,
            submitting: submitting
        })
    }

    private onFormSubmitFail = (): void => {
        this.setState({
            formResult: null,
            submitRequested: false
        });
    }

    private onFormSubmitSucceed = (newLogo: NewLogo): void => {
        this.setState({
            changesAccepted: true,
            changesRejected: false,
            submitting: false,
            formResult: 'New logo request submitted'
        });
        setTimeout(() => {
            this.props.onDismiss(newLogo);
        }, 1500);
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private requestSubmit = (): void => {
        this.setState({
            submitRequested: true
        });
    }

    private resetForm = (): void => {
        this.setState({
            changesAccepted: false,
            changesRejected: false,
            submitRequested: false,
            submitting: false
        })
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators
        ), dispatch)
    };
}

export default connect<{}, {}, AddLogoOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(AddLogo as any);
