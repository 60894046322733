import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as CountriesStore from '../../store/Countries';
import * as DropShipAddressesStore from '../../store/DropShipAddresses';
import * as MessageStore from '../../store/Message';
import { Client } from '../../store/Client';
import { Country, State, DropShipAddress } from '../../common/AddressTypes';
import { ShippingOption } from '../../store/ShippingOptions';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Row } from 'reactstrap';
import Loader from '../loader/Loader';
import $ from 'jquery';

import './DropShipAddressForm.scss';
import AddDropShipAddress from '../add-dropship-address/AddDropShipAddress';

// ----------------
// PROPS

const applicationState = {
    countriesState: {} as CountriesStore.CountriesState,
    dropShipAddressesState: {} as DropShipAddressesStore.DropShipAddressesState,
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, CountriesStore.actionCreators, DropShipAddressesStore.actionCreators, MessageStore.actionCreators)
};

interface DropShipAddressAsyncActions {
}

interface DropShipAddressFormOwnProps {
    activated: boolean;
    client: Client;
    billto: string;
    orderId: number;
    dropShipAddress?: DropShipAddress | null | undefined;
    shippingOption: ShippingOption | null | undefined;
    onRetrievingCountries?: (retrieving: boolean) => void;
    onSubmit: (submitting: boolean) => void;
    onSubmitFail: () => void;
    onSubmitSucceed?: (newAddress: DropShipAddress) => void;
    submitRequested: boolean;
}

type DropShipAddressFormProps =
    DropShipAddressFormOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators    // ... plus action creators we've requested
    & DropShipAddressAsyncActions;

// ----------------
// LOCAL STATE

interface DropShipAddressFormState {
    initialized: boolean;
    submitting: boolean;
    countryOptions: FieldWrapper.OptionValue[];
    countryFlag: boolean;
    selectedCountry: Country | null | undefined;
    stateOptions: FieldWrapper.OptionValue[];
    stateFlag: boolean;
    selectedState: State | null | undefined;
    streetLines: number;
    retainAddress: boolean;
}

// ----------------
// FORM VALIDATOR

const validateDropShipAddressForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.name) {
        errors.name = 'Required';
    }
    if (!formValues.street1) {
        errors.street1 = 'Required';
    }
    if (!formValues.city) {
        errors.city = 'Required';
    }
    if (formValues.subdivisionRequired && !formValues.state) {
        errors.state = 'Required'
    }
    if (!formValues.postalCode) {
        errors.postalCode = 'Required';
    }
    else if (formValues.postalCodeRegex) {
        let re: RegExp = new RegExp(formValues.postalCodeRegex);
        if (!re.test(formValues.postalCode)) {
            errors.postalCode = 'Invalid format';
        }
    }
    return errors;
}

const onFormValidationFailed = (errors: any, dispatch: any, submiError: Error, props: DropShipAddressFormOwnProps) => {
    if (props.onSubmitFail) {
        props.onSubmitFail();
    }
}

class DropShipAddressForm extends React.PureComponent<DropShipAddressFormProps, DropShipAddressFormState> {

    // ----------------
    // VARIABLES

    public dropShipAddressFormVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', 'overflow': 'visible' }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: DropShipAddressFormProps, state: DropShipAddressFormState) {
        super(props);
        this.state = {
            initialized: this.props.countriesState.countries ? true : false,
            submitting: false,
            countryOptions: this.getCountryOptions(),
            countryFlag: false,
            selectedCountry: this.getDefaultCountry(),
            stateOptions: this.getStateOptions(this.getDefaultCountry()),
            stateFlag: false,
            selectedState: undefined,
            streetLines: 1,
            retainAddress: false
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.ensureDataFetched();
    }

    public componentDidUpdate = (prevProps: DropShipAddressFormProps) => {
        if (this.props.countriesState.countries && !this.state.initialized) {
            if (this.props.onRetrievingCountries) {
                this.props.onRetrievingCountries(false);
            }
            setTimeout(() => {
                this.initializeForm();
            }, 400);
        }
        else if (this.props.dropShipAddress != prevProps.dropShipAddress) {
            this.initializeForm();
        }
        if (this.props.activated && !prevProps.activated) {
            setTimeout(() => {
                $(':text[name="name"]').focus();
            });
        }
        else if (!this.props.activated && prevProps.activated) {
            this.resetForm();
        }
        if (this.props.activated && this.props.submitRequested && !prevProps.submitRequested) {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        }
        else if (this.state.submitting) {
            if (this.receivedValidationError()) {
                this.setState({
                    submitting: false
                })
            }
            else if (this.receivedActionCompleteMessage()) {
                this.returnDropShipAddress();
            }
        }        
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        this.props.onSubmit(true);
        this.setState({
            submitting: true
        });
        let newAddress: DropShipAddress = {
            billto: values.billto,
            city: values.city,
            country: this.state.selectedCountry as Country,
            id: values.id,
            name: values.name,
            phone: values.phone,
            postalCode: values.postalCode,
            retainAddress: values.retainAddress,
            state: this.state.selectedState ? this.state.selectedState : { code: '', name: values.state },
            street1: values.street1,
            street2: values.street2,
            street3: values.street3,
            shippingOption: this.props.shippingOption
        }
        if (values.id) {
            this.props.actions.updateDropShipAddress(this.props.client, newAddress);
        }
        else {
            this.props.actions.addDropShipAddress(this.props.client, this.props.orderId, newAddress);
        }
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={this.props.activated && !this.state.initialized} isChild={true} />
                <motion.div id="dropship-address-form" animate={this.state.initialized ? "visible" : "hidden"} initial={"hidden"}
                    variants={this.dropShipAddressFormVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                            <Row className="country">
                                <Col className="pl-0 pr-0 horizontal-field country">
                                    <div className="name">Country</div>
                                    <div className="value">
                                        {this.props.countriesState.countries && (
                                            <Field name="country" label="" component={FieldWrapper.renderDropdownField}
                                                defaultValue={this.getDefaultCountryOption()}
                                                disabled={this.state.submitting}
                                                open={this.state.countryFlag}
                                                options={this.state.countryOptions}
                                                onSelectOption={this.onSelectCountry}
                                                onToggle={this.onToggleCountry} />
                                        )}
                                        <Field name="subdivisionRequired" component="input" type="hidden" />
                                        <Field name="postalCodeRegex" component="input" type="hidden" />
                                        <Field name="billto" component="input" type="hidden" />
                                        <Field name="id" component="input" type="hidden" />
                                    </div>
                                </Col>
                            </Row>          
                            <Row className="name">
                                <Col className="pl-0 pr-0 pb-0 pt-2 horizontal-field">
                                    <div className="name">Name</div>
                                    <div className="value">
                                        <Field name="name" type="text" label="" component={FieldWrapper.renderTextField}
                                            maxLength={30} autoComplete={false} disabled={this.state.submitting} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pr-0 pb-0 pt-2 horizontal-field">
                                    <div className="name">Street</div>
                                    <div className="value">
                                        <Field name="street1" type="text" label="" component={FieldWrapper.renderTextField}
                                            maxLength={30} autoComplete={false} disabled={this.state.submitting} />
                                    </div>
                                    <div className="actions">
                                        <Button color="link" title="Add Line" onClick={this.addStreet} disabled={this.state.streetLines > 2 || this.state.submitting} tabIndex={-1}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-plus-circle">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                                <line x1="8" y1="12" x2="16" y2="12"></line>
                                            </svg>
                                        </Button>
                                        <Button color="link" title="Remove Line" onClick={this.removeStreet} disabled={this.state.streetLines === 1 || this.state.submitting} tabIndex={-1}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-x-circle">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="15" y1="9" x2="9" y2="15"></line>
                                                <line x1="9" y1="9" x2="15" y2="15"></line>
                                            </svg>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <motion.div animate={this.state.streetLines < 2 ? "hidden" : "visible"} initial={"hidden"}
                                variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                                <Row>
                                    <Col className="pl-0 pr-0 pb-0 pt-2 horizontal-field">
                                        <div className="name"></div>
                                        <div className="value">
                                            <Field name="street2" type="text" label="" component={FieldWrapper.renderTextField}
                                                maxLength={30} autoComplete={false} disabled={this.state.submitting}
                                                tabIndex={this.state.streetLines < 2 ? -1 : 0} />
                                        </div>
                                        <div className="actions"></div>
                                    </Col>
                                </Row>
                            </motion.div>
                            <motion.div animate={this.state.streetLines < 3 ? "hidden" : "visible"} initial={"hidden"}
                                variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                                <Row>
                                    <Col className="pl-0 pr-0 pb-0 pt-2 horizontal-field">
                                        <div className="name"></div>
                                        <div className="value">
                                            <Field name="street3" type="text" label="" component={FieldWrapper.renderTextField}
                                                maxLength={30} autoComplete={false} disabled={this.state.submitting}
                                                tabIndex={this.state.streetLines < 3 ? -1 : 0} />
                                        </div>
                                        <div className="actions"></div>
                                    </Col>
                                </Row>
                            </motion.div>
                            <Row>
                                <Col className="pl-0 pr-0 pb-0 pt-2 horizontal-field">
                                    <div className="name">City</div>
                                    <div className="value">
                                        <Field name="city" type="text" label="" component={FieldWrapper.renderTextField}
                                            maxLength={30} autoComplete={false} disabled={this.state.submitting} />
                                    </div>
                                    <div className="actions"></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pr-0 pb-0 pt-2 horizontal-field">
                                    {this.state.selectedCountry && (
                                        <React.Fragment>
                                            <div className="name">{this.state.selectedCountry.subdivision}</div>
                                            <div className="value">
                                                {this.state.selectedCountry.states.length > 0 && (
                                                    <Field name="state" label="" component={FieldWrapper.renderDropdownField}
                                                        defaultValue={"Select..."}
                                                        disabled={this.state.submitting}
                                                        open={this.state.stateFlag}
                                                        options={this.state.stateOptions}
                                                        onSelectOption={this.onSelectState}
                                                        onToggle={this.onToggleState} />
                                                )}
                                                {this.state.selectedCountry.states.length === 0 && (
                                                    <Field name="state" type="text" label="" component={FieldWrapper.renderTextField}
                                                        maxLength={30} autoComplete={false} disabled={this.state.submitting} />
                                                )}
                                            </div>
                                            <div className="actions"></div>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pr-0 pb-0 pt-2 horizontal-field">
                                    <div className="name">Zip</div>
                                    <div className="value">
                                        <Field name="postalCode" type="text" label="" component={FieldWrapper.renderTextField}
                                            maxLength={10} autoComplete={false} disabled={this.state.submitting} />
                                    </div>
                                    <div className="spacer"></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pr-0 pb-0 pt-2 horizontal-field">
                                    <div className="name">Phone</div>
                                    <div className="value">
                                        <Field name="phone" type="text" label="" component={FieldWrapper.renderTextField}
                                            maxLength={25} autoComplete={false} disabled={this.state.submitting} />
                                    </div>
                                    <div className="spacer"></div>
                                </Col>
                            </Row>
                            <Row className=" retention-option">
                                <Col className="pl-0 pr-0 pb-0 pt-2 horizontal-field">
                                    <div className="name"></div>
                                    <div className="value">
                                        <Field name="retainAddress" type="text" label="" component={FieldWrapper.renderCheckboxField}
                                            id={"retain-address-option"} selected={this.state.retainAddress} disabled={this.state.submitting}
                                            onCheck={this.onSelectRetainAddress} inlineLabel={"Keep on file for future orders"} />
                                    </div>
                                </Col>
                            </Row>
                            <button type="submit" ref={this.submitButton}>Submit</button>
                        </form>
                    </Container>
                </motion.div>
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private addStreet = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        let streetLines: number = this.state.streetLines + 1;
        if (streetLines <= 3) {
            this.setState({
                streetLines: streetLines
            });
        }
    }

    private ensureDataFetched = (): void => {
        if (this.props.countriesState.countries) {
            this.initializeForm();
        }
        else {
            if (this.props.onRetrievingCountries) {
                this.props.onRetrievingCountries(true);
            }
            this.props.actions.requestCountries(this.props.client.code);
        }
    }

    private getCountryOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        if (this.props.countriesState.countries) {
            for (let n: number = 0; n < this.props.countriesState.countries.length; n++) {
                let country: Country = this.props.countriesState.countries[n];
                options.push({ label: country.name, value: country.code });
            }
        }
        return options;
    }

    private getDefaultCountry = (): Country | null | undefined => {
        let defaultCountry: Country | null | undefined;
        if (this.props.countriesState.countries && this.props.countriesState.countries.length > 0) {
            defaultCountry = this.props.countriesState.countries[0];           
        }
        return defaultCountry;
    }

    private getDefaultCountryOption = (): string => {
        let defaultCountry: Country | null | undefined = this.getDefaultCountry();
        return defaultCountry ? defaultCountry.name : "Select...";
    }

    private getStateOptions = (country: Country | null | undefined): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        if (country) {
            for (let n: number = 0; n < country.states.length; n++) {
                let state: State = country.states[n];
                options.push({ label: state.name, value: state.code });
            }
        }
        return options;
    }

    private initializeForm = (): void => {
        this.props.reset();
        let defaultCountry: Country | null | undefined = undefined;

        if (this.props.dropShipAddress && this.props.countriesState.countries) {
            let countryCode: string = this.props.dropShipAddress.country.code;
            let matches: Country[] = this.props.countriesState.countries.filter((c: Country) => {
                return c.code === countryCode;
            });
            defaultCountry = matches.length > 0 ? matches[0] : this.props.dropShipAddress.country;
            this.setState({
                initialized: true,
                submitting: false,
                countryOptions: this.getCountryOptions(),
                selectedCountry: defaultCountry,
                countryFlag: false,
                stateOptions: this.getStateOptions(defaultCountry),
                selectedState: this.props.dropShipAddress.state,
                stateFlag: false,
                streetLines: this.props.dropShipAddress.street3 ? 3 : this.props.dropShipAddress.street2 ? 2 : 1,
                retainAddress: this.props.dropShipAddress.retainAddress ? true : false
            });            
        }
        else {
            defaultCountry = this.getDefaultCountry();
            this.setState({
                initialized: true,
                submitting: false,
                countryOptions: this.getCountryOptions(),
                selectedCountry: defaultCountry,
                countryFlag: false,
                stateOptions: this.getStateOptions(defaultCountry),
                selectedState: undefined,
                stateFlag: false,
                streetLines: 1,
                retainAddress: false
            });
        }

        this.props.change("billto", this.props.billto);
        this.props.change("id", this.props.dropShipAddress ? this.props.dropShipAddress.id : null);
        this.props.change("country", this.props.dropShipAddress ? this.props.dropShipAddress.country.name : defaultCountry ? defaultCountry.name : "");
        this.props.change("name", this.props.dropShipAddress ? this.props.dropShipAddress.name : null);
        this.props.change("street1", this.props.dropShipAddress ? this.props.dropShipAddress.street1 : null);
        this.props.change("street2", this.props.dropShipAddress ? this.props.dropShipAddress.street2 : null);
        this.props.change("street3", this.props.dropShipAddress ? this.props.dropShipAddress.street3 : null);
        this.props.change("city", this.props.dropShipAddress ? this.props.dropShipAddress.city : null);
        this.props.change("state", this.props.dropShipAddress ? this.props.dropShipAddress.state.name : null);
        this.props.change("postalCode", this.props.dropShipAddress ? this.props.dropShipAddress.postalCode : null);
        this.props.change("phone", this.props.dropShipAddress ? this.props.dropShipAddress.phone : null);
        this.props.change("retainAddress", this.props.dropShipAddress ? this.props.dropShipAddress.retainAddress : false);

        this.setCountrySpecificRules(defaultCountry);
    }

    private onSelectCountry = (selectedOption: FieldWrapper.OptionValue): void => {
        if (this.props.countriesState.countries) {
            let country: Country[] = this.props.countriesState.countries.filter((c: Country) => {
                return c.code === selectedOption.value;
            });
            this.props.change('country', selectedOption.label);
            this.props.change('state', null);
            this.setCountrySpecificRules(country.length > 0 ? country[0] : undefined);
            this.setState({
                selectedCountry: country[0],
                selectedState: undefined,
                stateOptions: this.getStateOptions(country[0])
            });            
        }
    }

    private onSelectRetainAddress = (checked: boolean): void => {
        this.setState({
            retainAddress: checked
        });
        this.props.change("retainAddress", checked);
    }

    private onSelectState = (selectedOption: FieldWrapper.OptionValue): void => {
        if (this.state.selectedCountry) {
            let state: State[] = this.state.selectedCountry.states.filter((s: State) => {
                return s.code === selectedOption.value;
            });
            this.props.change('state', selectedOption.label);
            this.setState({
                selectedState: state[0]
            });
        }
    }

    private onToggleCountry = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {
        event.preventDefault();
        let countryFlag: boolean = !this.state.countryFlag;
        if (countryFlag) {
            this.props.change('country', 'Select...');
        }
        this.setState({
            countryFlag: countryFlag
        });
    }

    private onToggleState = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {
        event.preventDefault();
        let stateFlag: boolean = !this.state.stateFlag;
        if (stateFlag) {
            this.props.change('state', 'Select...');
        }
        this.setState({
            stateFlag: stateFlag
        });
    }

    private receivedActionCompleteMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) ?
            true : false;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private removeStreet = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        let streetLines: number = this.state.streetLines;
        if (streetLines > 1) {
            this.props.change("street" + streetLines, null);
            this.setState({
                streetLines: (streetLines - 1)
            });
        }
    }

    private resetForm = (): void => {
        this.props.reset();
        this.setState({
            initialized: false
        });
    }

    private returnDropShipAddress = (): void => {
        if (this.props.onSubmitSucceed) {
            let returned: boolean = false;
            if (this.props.dropShipAddressesState.dropShipAddress) {
                if (this.props.dropShipAddressesState.dropShipAddress.id && this.props.dropShipAddressesState.dropShipAddress.id > 0) {
                    this.props.onSubmitSucceed(this.props.dropShipAddressesState.dropShipAddress);
                    returned = true;
                }
            }
            if (!returned) {
                setTimeout(() => {
                    this.returnDropShipAddress();
                }, 250);
            }
        }
    }

    private setCountrySpecificRules = (selectedCountry: Country | null | undefined): void => {
        this.props.change('subdivisionRequired', selectedCountry ? selectedCountry.subdivisionRequired : false);
        this.props.change('postalCodeRegex', selectedCountry ? selectedCountry.postalCodeRegex : undefined);
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        countriesState: state.countries,
        dropShipAddressesState: state.dropShipAddresses,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            CountriesStore.actionCreators,
            DropShipAddressesStore.actionCreators,
            MessageStore.actionCreators), dispatch),
        asyncActions: {}
    };
}

export default connect<{}, {}, DropShipAddressFormOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'dropShipAddressForm',
    validate: validateDropShipAddressForm,
    onSubmitFail: onFormValidationFailed,
    enableReinitialize: true
})(DropShipAddressForm as any));
