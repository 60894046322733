﻿
// ----------------- 
// METHODS

export const formatNumberAsCurrency = (value: number | null | undefined, includeDecimals: boolean, languageCode: string, currencySymbol: string): string => {
    let output: string = "";
    if (value != null && value != undefined && !isNaN(value)) {
        let numberFormat = new Intl.NumberFormat(languageCode, {
            style: 'currency',
            currency: currencySymbol,
            minimumFractionDigits: (includeDecimals ? 2 : 0)
        });
        output = numberFormat.format(value);
    }
    return output;
}

export const formatNumberAsCurrencyForCountry = (countryCode: string, value: number | null | undefined, includeDecimals: boolean): string => {
    let languageCode: string = "";
    let currencySymbol: string = "";

    switch (countryCode) {
        default:
            languageCode = 'en-US';
            currencySymbol = 'USD';
            break;
    }

    return formatNumberAsCurrency(value, includeDecimals, languageCode, currencySymbol);
}