import * as React from 'react';
import { OrderDetail } from '../../store/Orders';
import { OrderItem } from '../../store/OrderItems';
import { OrderInvoice, OrderInvoiceItem } from '../../store/OrderInvoice';
import { Drawer } from '@material-ui/core';
import { Button, Container, Row, Col } from 'reactstrap';
import DetailTitleBlock from '../detail-title-block/DetailTitleBlock';
import DeleteOrderItem from '../delete-order-item/DeleteOrderItem';
import OrderItemSummary from '../order-item-summary/OrderItemSummary';
import { TitleBlock } from '../../common/ComponentTypes';
import { convertJSDateToString } from '../../common/DateConverter';
import { formatNumberWithCommas } from '../../common/NumberFormatter';
import { formatNumberAsCurrencyForCountry } from '../../common/CurrencyFormatter';

import './OrderSidebar.scss';

// ----------------
// PROPS

interface OrderSidebarProps {
    orderDetail: OrderDetail;
    orderInvoice: OrderInvoice;
    onDismiss: () => void;
    onEdit: () => void;
    onViewProduct: (orderInvoiceItem: OrderInvoiceItem) => void;
}

// ----------------
// LOCAL STATE

interface OrderSidebarState {
    isOpen: boolean;
    titleBlock: TitleBlock;
    orderItemToDelete: OrderItem | null;
}

class OrderSidebar extends React.PureComponent<OrderSidebarProps, OrderSidebarState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderSidebarProps, state: OrderSidebarState) {
        super(props);
        this.state = {
            isOpen: false,
            titleBlock: this.getTitleBlock(this.props.orderDetail),
            orderItemToDelete: null
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: OrderSidebarProps) => {
        if (this.isOrderUpdated(prevProps)) {
            this.setState({
                titleBlock: this.getTitleBlock(this.props.orderDetail)
            })
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <Drawer id="order-sidebar" variant="temporary" anchor={"right"} open={this.state.isOpen}>
                <Container>
                    <Row className="drawer-header">
                        <Col>
                            <h2>Current Order</h2>
                            <button type="button" className="close" onClick={this.dismiss}>
                                <span>&times;</span>
                            </button>
                        </Col>
                    </Row>
                    <Row className="order-header">
                        <Col>
                            <DetailTitleBlock titleBlock={this.state.titleBlock} disabled={false} />
                            <div className="order-header-parameters">
                                <div className="totals">
                                    <div className="nvp">
                                        <label className="name">Ship Date</label>
                                        <label className="value">{convertJSDateToString(this.props.orderDetail.shipDate, 'M/d/yyyy')}</label>
                                    </div>
                                    <div className="nvp">
                                        <label className="name">Total</label>
                                        <label className="value">{formatNumberAsCurrencyForCountry('US', this.props.orderInvoice.netPrice, true)}</label>
                                    </div>
                                    <div className="nvp">
                                        <label className="name">Units</label>
                                        <label className="value">{formatNumberWithCommas(this.props.orderInvoice.units)}</label>
                                    </div>
                                </div>
                                <div className="actions">
                                    <Button color="secondary" onClick={this.props.onEdit}>Go to Cart</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="order-detail">
                        <Col className="mb-3">
                            <OrderItemSummary orderInvoice={this.props.orderInvoice} isOpenOrder={true}
                                onRemoveOrderItem={this.onDeleteOrderItem} orderItemToDelete={this.state.orderItemToDelete}
                                onViewProduct={this.props.onViewProduct} />
                        </Col>
                    </Row>
                </Container>
                <DeleteOrderItem orderItemToDelete={this.state.orderItemToDelete}
                    clientCode={this.props.orderDetail.client.code}
                    onDelete={this.onDeleteOrderItemComplete}
                    onDismiss={this.hideDeleteOrderItem}
                    onConcurrencyError={this.onDeleteOrderItemNotAllowed}
                    modalOnModal={true} />
                {this.state.orderItemToDelete && (
                    <div className="sidebar-modal-backdrop MuiBackdrop-root"></div>
                )}
            </Drawer>
        );
    }

    // ----------------
    // HELPERS

    private dismiss = (): void => {
        this.props.onDismiss();
    }

    private getTitleBlock = (orderDetail: OrderDetail): TitleBlock => {
        let titleBlock: TitleBlock = {
            header: orderDetail.orderNumber,
            title: orderDetail.customerAccount ? orderDetail.customerAccount.nameOnly : undefined,
            subtitle: orderDetail.poNumber ? orderDetail.poNumber : undefined,
            link: undefined
        };
        return titleBlock;
    }

    private hideDeleteOrderItem = (): void => {
        this.setState({
            orderItemToDelete: null
        });
    }

    private isOrderUpdated = (prevProps: OrderSidebarProps): boolean => {
        let updated: boolean = this.props.orderDetail.id != prevProps.orderDetail.id ? true : false;
        if (!updated) {
            updated = this.props.orderDetail.poNumber != prevProps.orderDetail.poNumber ? true : false;
        }
        return updated;
    }

    private onDeleteOrderItem = (orderInvoiceItem: OrderInvoiceItem): void => {
        // create partial order item (we don't need all properties populated for delete)
        let orderItem: OrderItem = {
            id: orderInvoiceItem.orderItemId,
            canEmbroider: false,
            chaseDate: null,
            clientCode: this.props.orderDetail.client.code,
            color: { code: orderInvoiceItem.colorCode, name: '', displayName: '' },
            customerNumber: this.props.orderDetail.customerNumber,
            dimension: {
                code: orderInvoiceItem.dimensionCode,
                productId: orderInvoiceItem.productId,
                sanitizedProductId: orderInvoiceItem.sanitizedProductId,
                name: '',
                inventoryDates: [],
                sizes: [],
                sortIndex: 0,
                unitsAvailable: []
            },
            dimensionDescription: '',
            earliestAvailable: null,
            embroiderySpecifications: [],
            garmentType: { code: '', name: '', description: '' },
            gender: { code: '', name: '' },
            imageUrl: orderInvoiceItem.imageUrl,
            isEditable: true,
            orderId: this.props.orderDetail.id as number,
            notes: '',
            placementOptions: [],
            productId: orderInvoiceItem.productId,
            sanitizedProductId: orderInvoiceItem.sanitizedProductId,
            season: { code: '', name: '' },
            shortDescription: '',
            sizeDescription: '',
            style: { code: orderInvoiceItem.styleCode, name: orderInvoiceItem.styleName, sku: '' },
            totalPrice: orderInvoiceItem.price,
            totalUnits: orderInvoiceItem.quantity,
            wholesale: orderInvoiceItem.wholesale
        };
        this.setState({
            orderItemToDelete: orderItem
        });
    }

    private onDeleteOrderItemComplete = (): void => {
        this.hideDeleteOrderItem();
    }

    private onDeleteOrderItemNotAllowed = (): void => { }
}

// ----------------
// EXPORT

export default OrderSidebar;
