import * as React from 'react';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { TitleBlock } from '../../common/ComponentTypes';

import './DetailTitleBlock.scss';

// ----------------
// PROPS

interface DetailTitleBlockProps {
    titleBlock: TitleBlock,
    disabled: boolean;
    small?: boolean;
    usePlaceholders?: boolean;
}

// ----------------
// LOCAL STATE

interface DetailTitleBlockState {}

class DetailTitleBlock extends React.PureComponent<DetailTitleBlockProps, DetailTitleBlockState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: DetailTitleBlockProps, state: DetailTitleBlockState) {
        super(props);
        this.state = {};
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <Container className={"detail-title-block" + (this.props.small ? " small" : "")}>
                <Row className="header">
                    <Col className="tag pl-0">
                        {this.props.titleBlock.header && (
                            <label>{this.props.titleBlock.header}</label>
                        )}
                        {this.props.usePlaceholders && !this.props.titleBlock.header && (
                            <label>&nbsp;</label>   
                        )}
                    </Col>
                    <Col className="link pr-0 text-right">
                        {this.props.titleBlock.link && (
                            <NavLink to={this.props.disabled ? "#" : this.props.titleBlock.link} className={this.props.disabled ? "disabled" : ""}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-left">
                                    <polyline points="15 18 9 12 15 6"></polyline>
                                </svg>
                                <span>Back</span>
                            </NavLink>
                        )}
                    </Col>
                </Row>
                <Row className="title-subtitle">
                    <Col className="title pl-0 pr-0">
                        {this.props.titleBlock.title && (
                            <h2>{this.props.titleBlock.title}</h2>
                        )}
                        {this.props.titleBlock.subtitle && (
                            <label className="subtitle">{this.props.titleBlock.subtitle}</label>
                        )}
                        {this.props.usePlaceholders && !this.props.titleBlock.subtitle && (
                            <label>&nbsp;</label>   
                        )}
                    </Col>
                </Row>
            </Container>
        )
    }

    // ----------------
    // HELPERS
}

// ----------------
// EXPORT

export default DetailTitleBlock;
