import * as React from 'react';
import { Table } from 'reactstrap';
import * as MessageStore from '../../store/Message';

import './MessagePanel.scss';

// ----------------
// PROPS

interface MessagePanelProps {
    message: MessageStore.Message | null | undefined
}

class MessagePanel extends React.PureComponent<MessagePanelProps> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: MessagePanelProps) {
        super(props);
    }

    // ----------------
    // METHODS

    public render = () => {
        return (
            <div id="messagePanel">
                <div className="message-panel-icon">
                    {(this.isErrorMessage() || this.isWarningMessage()) && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path strokeWidth="1" d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                            <line x1="12" y1="9" x2="12" y2="13"></line>
                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                    )}
                </div>
                <div className={"message-panel-text" + (this.isErrorMessage() ? "" : " mb")}>
                    <label className="message-text">{this.getMessageText()}</label>
                    {this.isErrorMessage() && (
                        <Table borderless size="sm" className="message-details">
                            <tbody>
                                {this.hasAction() && (
                                    <tr>
                                        <td className="detail-name">Action:</td>
                                        <td className="detail-value">{this.getAction()}</td>
                                    </tr>
                                )}
                                {this.hasReference() && (
                                    <tr>
                                        <td className="detail-name">Reference:</td>
                                        <td className="detail-value">{this.getReference()}</td>
                                    </tr>                                                
                                )}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private getAction = (): string => {
        let action: string = '';
        if (this.hasAction()) {
            if (this.props.message) {
                action = this.props.message.action || '';
            }
        }
        return action;
    }

    private getMessageText = (): string => {
        let text: string = '';
        if (this.props.message && this.props.message.text) {
            text = this.props.message.text;
        }
        return text;
    }

    private getReference = () => {
        let reference: string = '';
        if (this.hasReference()) {
            if (this.props.message) {
                reference = this.props.message.reference || '';
            }
        }
        return reference;
    }

    private hasAction = (): boolean => {
        let hasIt: boolean = false;
        if (this.isErrorMessage()) {
            if (this.props.message) {
                hasIt = this.props.message.action != null && this.props.message.action != '';
            }
        }
        return hasIt;
    }

    private hasReference = (): boolean => {
        return (this.isErrorMessage() && this.props.message && this.props.message.reference) ? true : false;
    }

    private isErrorMessage = (): boolean => {
        return (this.props.message && this.props.message.messageType === MessageStore.MessageType.ERROR) ? true : false;
    }

    private isWarningMessage = (): boolean => {
        return (this.props.message && this.props.message.messageType === MessageStore.MessageType.WARNING) ? true : false;
    }
}

// ----------------
// EXPORT

export default MessagePanel;
