import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as PasswordStore from '../../store/Password';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { bindActionCreators, Dispatch } from 'redux';
import { Field, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';

import './PasswordReset.scss';

// ----------------
// PROPS

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    passwordState: {} as PasswordStore.PasswordState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, PasswordStore.actionCreators)
};

interface PasswordResetOwnProps {    
    isOpen: boolean;
    onDismiss: (() => void)
}

type PasswordResetProps =
    PasswordResetOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface PasswordResetState {
    submitting: boolean,
    emailAccepted: boolean,
    emailRejected: boolean
}

// ----------------
// FORM VALIDATOR

const validatePasswordResetForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.email) {
        errors.email = 'Please enter email address';
    }
    else if (!/^[a-zA-Z0-9\-\']+[a-zA-Z0-9._\-\']+@[a-zA-Z0-9.\-]+\.[a-zA-Z.]{2,5}$/i.test(formValues.email)) {
        errors.email = 'Invalid email address';
    }
    return errors;
}

class PasswordReset extends React.PureComponent<PasswordResetProps, PasswordResetState> {

    // ----------------
    // VARIABLES
    public emailElement: React.RefObject<HTMLInputElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: PasswordResetProps, state: PasswordResetState) {
        super(props);
        this.state = {
            submitting: false,
            emailAccepted: false,
            emailRejected: false
        }
        this.emailElement = React.createRef<HTMLInputElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {       
    }

    public componentDidUpdate = () => {
        if (this.props.clientState.client) {
            setTimeout(() => {
                if (this.props.clientState.client) {
                    this.props.change('clientCode', this.props.clientState.client.code);
                }
            });
        }
        if (this.state.submitting) {
            if (this.receivedErrorMessage()) {
                setTimeout(() => {
                    this.setState({
                        submitting: false
                    });
                    this.props.onDismiss();
                })
            }
            else if (this.props.passwordState.passwordChange && this.props.passwordState.passwordChange.isComplete) {
                if (this.props.passwordState.passwordChange.isReset) {
                    setTimeout(() => {
                        this.setState({
                            submitting: false,
                            emailAccepted: true,
                            emailRejected: false
                        });
                        this.props.actions.clearPassword();
                    })
                }
                else {
                    setTimeout(() => {
                        this.setState({
                            submitting: false,
                            emailAccepted: false,
                            emailRejected: true
                        });
                        this.props.actions.clearPassword();
                    })
                }
            }
        }
    }

    public handleFormSubmit = (values: any) => {
        this.setState({
            submitting: true,
            emailAccepted: false,
            emailRejected: false
        });
        this.props.actions.clearMessage();
        this.props.actions.resetPassword(values);
    }

    public render = () => {
        return (
            <Modal id="password-reset" isOpen={this.props.isOpen}
                onOpened={this.initializeForm.bind(this)} onClosed={this.resetForm}>
                <ModalHeader toggle={this.props.onDismiss} className={this.state.submitting ? "disabled" : ""}>
                    Forgot Password?
                </ModalHeader>
                <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                <ModalBody>
                    <p>Enter your email address and we'll send you a new password.</p>
                    
                        <Container>
                            <Row>
                                <Col className="pl-0 pr-0">
                                    <Field name="email" type="text" label="Email"
                                        component={FieldWrapper.renderTextField}
                                        maxlength={255} noSpaces={true} autoComplete={false} autoFocus={true}
                                        reference={this.emailElement}
                                        disabled={this.state.submitting || this.state.emailAccepted} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field name="clientCode" component="input" type="hidden" />
                                </Col>
                            </Row>
                        </Container>
                    
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0 pr-sm-3">
                                    <Button type="submit" color="primary" 
                                    disabled={this.state.submitting || this.state.emailAccepted}>
                                    {this.state.submitting ? "Working" : "Reset Password" }
                                </Button>
                                <Button color="link" onClick={this.props.onDismiss}
                                    disabled={this.state.submitting}>
                                    Back to Sign In
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0 pr-sm-3">
                                <FormResult
                                    failureResult={this.state.emailRejected}
                                    successResult={this.state.emailAccepted}
                                    description={this.getFormResult()} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
                </form>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private getFormResult = (): string => {
        let formResult: string = '';
        if (this.state.emailRejected) {
            formResult = 'Not a registered email address';
        }
        else if (this.state.emailAccepted) {
            formResult = 'New password sent'
        }
        return formResult;
    }

    private initializeForm = (): void => {
        this.setState({
            submitting: false,
            emailAccepted: false,
            emailRejected: false
        });
        setTimeout(() => {
            if (this.emailElement.current) {
                this.emailElement.current.focus();
            }
        });
        this.resetForm();
    }

    private receivedErrorMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) ?
            true : false;
    }

    private resetForm = (): void => {
        this.props.reset();
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        passwordState: state.password
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            PasswordStore.actionCreators), dispatch)
    };
}

export default connect<{},{},PasswordResetOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'passwordResetForm',
    validate: validatePasswordResetForm,
})(PasswordReset as any));


