﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { ClientCode } from '../enums/ClientCode';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface OrderInvoiceState {
    clientCode: ClientCode,
    orderId: number | null;
    orderInvoice: OrderInvoice | null;
}

export interface OrderInvoice {
    discount: number;
    discountAmount: number;
    embroideryFees: number;
    items: OrderInvoiceItem[];
    lines: number;
    netPrice: number;
    netPriceEmbroidered: number;
    netPriceNotEmbroidered: number;
    orderId: number;
    orderStatus: string;
    price: number;
    priceEmbroidered: number;
    priceNotEmbroidered: number;
    totalPrice: number;
    units: number;
    unitsEmbroidered: number;
    unitsNotEmbroidered: number;
}

export interface OrderInvoiceItem {
    colorCode: string;
    dimensionCode: string;
    embroideryFee: number;
    imageUrl: string;
    isEmbroidered: boolean;
    orderItemId: number;
    price: number;
    productId: string;
    quantity: number;
    sanitizedProductId: string;
    styleCode: string;
    styleName: string;
    wholesale: number;
}   

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface ClearOrderInvoiceAction {
    type: 'CLEAR_ORDER_INVOICE';
}

export interface SetOrderInvoiceAction {
    type: 'SET_ORDER_INVOICE';
    clientCode: ClientCode;
    orderId: number;
    orderInvoice: OrderInvoice | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = ClearOrderInvoiceAction | SetOrderInvoiceAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    clearOrderInvoice: () => ({ type: 'CLEAR_ORDER_INVOICE' } as ClearOrderInvoiceAction),
    setOrderInvoice: (clientCode: ClientCode, orderId: number, orderInvoice: OrderInvoice) =>
        ({ type: 'SET_ORDER_INVOICE', clientCode: clientCode, orderId: orderId, orderInvoice: orderInvoice } as SetOrderInvoiceAction)
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: OrderInvoiceState = {
    clientCode: ClientCode.Undefined,
    orderId: null,
    orderInvoice: null
};

export const reducer: Reducer<OrderInvoiceState> = (state: OrderInvoiceState | undefined, incomingAction: Action): OrderInvoiceState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CLEAR_ORDER_INVOICE':
            return initialState;
        case 'SET_ORDER_INVOICE':
            return {
                clientCode: action.clientCode,
                orderId: action.orderId,
                orderInvoice: action.orderInvoice
            };
        default:
            return state;
    }
}