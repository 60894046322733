import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as ProductlistItemsStore from '../../store/ProductlistItems';
import { ProductlistItem } from '../../store/ProductlistItems';
import { ClientCode } from '../../enums/ClientCode';
import * as ErrorMessage from '../../common/ErrorMessage';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import $ from 'jquery';
 
import './DeleteProductlistItem.scss';

// ----------------
// PROPS

const applicationState = {
    productlistItemsState: {} as ProductlistItemsStore.ProductlistItemsState,
    messageState: {} as MessageStore.MessageState
}

const actionCreators = {
    actions: Object.assign({}, ProductlistItemsStore.actionCreators, MessageStore.actionCreators)
};

interface DeleteProductlistItemAsyncActions {
    asyncActions: {
        deleteProductlistItem: (clientCode: ClientCode, productlistItemToDelete: ProductlistItem, broadcastError: boolean) => Promise<ProductlistItem | null | undefined>;
    }
}

interface DeleteProductlistItemOwnProps {
    clientCode: ClientCode | null | undefined;
    productlistItemToDelete: ProductlistItem | null;
    onDelete: (() => void);
    onDismiss: (() => void);
}

type DeleteProductlistItemProps =
    DeleteProductlistItemOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & DeleteProductlistItemAsyncActions;

// ----------------
// LOCAL STATE

interface DeleteProductlistItemState {
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
}

class DeleteProductlistItem extends React.PureComponent<DeleteProductlistItemProps, DeleteProductlistItemState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: DeleteProductlistItemProps, state: DeleteProductlistItemState) {
        super(props);
        this.state = {
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        }
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: DeleteProductlistItemProps) => {
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <Modal className="delete-productlist-item" size="sm" isOpen={this.props.productlistItemToDelete != null}
                onOpened={this.initializeDialog.bind(this)} onClosed={this.resetDialog}>
                <ModalHeader toggle={this.props.onDismiss} className={this.state.submitting ? "disabled" : ""}>
                    Remove Item
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className="action-info pl-0 pr-0">
                                <span className="action-description">Item will be permanently deleted:</span>
                                <span className="action-target">{this.props.productlistItemToDelete ? this.props.productlistItemToDelete.style.name : ""}</span>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" disabled={this.state.submitting || this.state.changesAccepted}
                                    onClick={this.deleteProductlistItem}>
                                    {this.state.submitting ? "Working" : "Continue"}
                                </Button>
                                <Button color="link" onClick={this.props.onDismiss} disabled={this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private deleteProductlistItem = (): void => {
        if (this.props.clientCode && this.props.productlistItemToDelete) {
            this.props.actions.clearMessage();
            this.setState({
                submitting: true,
                changesRejected: false,
                formResult: null
            });
            this.props.asyncActions.deleteProductlistItem(this.props.clientCode, this.props.productlistItemToDelete, false)
                .then(result => {
                    if (result) {
                        this.setState({
                            submitting: false,
                            changesAccepted: true,
                            formResult: 'Item deleted'
                        });
                        setTimeout(() => {
                            this.props.onDelete();
                        }, 1500);
                    }
                },
                err => {
                    this.setState({
                        submitting: false,
                        changesRejected: true,
                        formResult: err
                    })
                }
            )
        }
    }

    private initializeDialog = (): void => {
        this.setState({
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        });
    }

    private resetDialog = (): void => {

    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        productlistItemsState: state.productlistItems,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            ProductlistItemsStore.actionCreators,
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
            deleteProductlistItem: (clientCode: ClientCode, productlistItemToDelete: ProductlistItem, broadcastError: boolean) =>
                dispatch(ProductlistItemsStore.actionCreators.deleteProductlistItem(clientCode, productlistItemToDelete, broadcastError))
        }
    };
}

export default connect<{}, {}, DeleteProductlistItemOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(DeleteProductlistItem as any);