import * as React from 'react';
import { formatNumberAsCurrencyForCountry } from '../../common/CurrencyFormatter';
import { OrderItem } from '../../store/OrderItems';
import { OrderInvoice, OrderInvoiceItem } from '../../store/OrderInvoice';
import ImageLoader from '../image-loader/ImageLoader';

import './OrderItemSummary.scss';

// ----------------
// PROPS

interface OrderItemSummaryProps {
    orderInvoice: OrderInvoice;
    onRemoveOrderItem: (orderInvoiceItem: OrderInvoiceItem) => void;
    onViewProduct: (orderInvoiceItem: OrderInvoiceItem) => void;
    orderItemToDelete?: OrderItem | null;
    isOpenOrder: boolean;
}

// ----------------
// LOCAL STATE

interface OrderItemSummaryState { }

class OrderItemSummary extends React.PureComponent<OrderItemSummaryProps, OrderItemSummaryState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderItemSummaryProps, state: OrderItemSummaryState) {
        super(props);
        this.state = {};
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: OrderItemSummaryProps) => { }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <div className="order-item-summary">
                {this.props.orderInvoice.items.length === 0 && (
                    <div className="order-item">
                        <label>No items selected</label>
                    </div>
                )}
                {this.props.orderInvoice.items.map((item: OrderInvoiceItem, itemIndex: number) => (
                    <div className="order-item" key={"orderItem-" + itemIndex.toString()}>
                        <label className="order-item-name">{item.styleName}</label>
                        <div className="order-item-description">
                            <a className="order-item-summary-image" href="#" onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.onViewProduct(e, item)} title="View Product">
                                <div className="order-item-image">
                                    <div className="image-stretcher">
                                        <div className="image-canvas">
                                            <ImageLoader url={item.imageUrl} isLoading={false} noFadeIn={false} thumbnail={true} />
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div className="order-item-summary-text">
                                <div className="price-quantity">
                                    <label className="price">{formatNumberAsCurrencyForCountry('US', item.price, true)}</label>
                                    <label className="quantity">{item.quantity} unit{this.isSingleUnit(item) ? '' : 's'}</label>
                                </div>
                                {item.isEmbroidered && (
                                    <label className="embroidery-flag">Embroidered</label>
                                )}
                            </div>
                            {this.props.isOpenOrder && (
                                <div className="order-item-summary-actions">
                                    <button type="button" className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" tabIndex={0} title="Remove Item"
                                        onClick={(e: React.MouseEvent) => this.onRemoveOrderItem(e, item)}>
                                        <span className="MuiIconButton-label">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-trash">
                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            )}
                        </div>
                        {this.isDeleting(item) && (
                            <div className="order-item-border" />
                        )}
                    </div>
                ))}
            </div>
        );
    }

    // ----------------
    // HELPERS

    private isDeleting = (item: OrderInvoiceItem): boolean => {
        return this.props.orderItemToDelete && this.props.orderItemToDelete.id === item.orderItemId ? true : false;
    }

    private isSingleUnit = (item: OrderInvoiceItem): boolean => {
        let isSingle: boolean = item.quantity === 1 ? true : false;
        return isSingle;
    }

    private onRemoveOrderItem = (event: React.KeyboardEvent | React.MouseEvent, orderInvoiceItem: OrderInvoiceItem): void => {
        event.preventDefault();
        this.props.onRemoveOrderItem(orderInvoiceItem);
    }

    private onViewProduct = (event: React.KeyboardEvent | React.MouseEvent, orderInvoiceItem: OrderInvoiceItem): void => {
        event.preventDefault();
        this.props.onViewProduct(orderInvoiceItem);
    }
}

// ----------------
// EXPORT

export default OrderItemSummary;
