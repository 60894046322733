import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as CategoriesStore from '../../store/Categories';
import * as ClientStore from '../../store/Client';
import * as CollectionsStore from '../../store/Collections';
import * as ColorsStore from '../../store/Colors';
import * as OrderDatesStore from '../../store/OrderDates';
import * as OrdersStore from '../../store/Orders';
import * as ProductsStore from '../../store/Products';
import * as MessageStore from '../../store/Message';
import * as UserStore from '../../store/User';
import { ClientCode } from '../../enums/ClientCode';
import { Color } from '../../common/ProductTypes';
import { Button, ButtonGroup, Col, Container, Row } from 'reactstrap';
import { Role } from '../../enums/Role';
import Loader from '../loader/Loader';
import MaterialTable, { Column, Filter, Options, Query, QueryResult, MTableBody, MTablePagination, MTableToolbar } from 'material-table';
import { LabelDisplayedRowsArgs } from '@material-ui/core';
import { BaseTableFilter } from '../../common/BaseTypes';
import { FilterEx } from '../../common/ExtendedTypes';
import TableFilter from '../table-filter/TableFilter';
import { TableFilterListItem } from '../table-filter/TableFilter';
import { TableViewType } from '../../enums/TableViewType';
import * as TableIcons from '../table-icons/TableIcons';
import ProductLightbox from '../product-lightbox/ProductLightbox';
import ProductListbox from '../product-listbox/ProductListbox';
import ProductCategories from '../product-categories/ProductCategories';
import TableFilterIndicator from '../table-filter-indicator/TableFilterIndicator';
import $ from 'jquery';

import './Products.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    categoriesState: {} as CategoriesStore.CategoriesState,
    clientState: {} as ClientStore.ClientState,
    collectionsState: {} as CollectionsStore.CollectionsState,
    colorsState: {} as ColorsStore.ColorsState,
    orderDatesState: {} as OrderDatesStore.OrderDatesState,
    ordersState: {} as OrdersStore.OrdersState,
    productsState: {} as ProductsStore.ProductsState,
    messageState: {} as MessageStore.MessageState,
    userState: {} as UserStore.UserState
}

const actionCreators = {
    actions: Object.assign({}, CategoriesStore.actionCreators, CollectionsStore.actionCreators, ColorsStore.actionCreators, OrderDatesStore.actionCreators, ProductsStore.actionCreators, MessageStore.actionCreators)
};

interface ProductsAsyncActions {
    asyncActions: {
        requestProductsAsync: (clientCode: ClientCode, query: Query<any>, view: TableViewType) => Promise<QueryResult<any>>;
    }
}

type ProductsProps =
    RouteComponentProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & ProductsAsyncActions;

// ----------------
// LOCAL STATE

interface ProductsState {
    isVisible: boolean;
    isTableInitialized: boolean;
    isTableLoading: boolean;
    isTableError: boolean;
    isDisplayingAllItems: boolean;
    columns: any[];
    operators: FilterEx[];
    options: Options<any>;
    rowCount: number | undefined;
    total: number | undefined;
    products: ProductsStore.Product[];
    useStoredQuery: boolean;
    selectedCategory: string | null | undefined;
    shipDate: Date | null | undefined;
    tableView: TableViewType;
}

class Products extends React.PureComponent<ProductsProps, ProductsState> {

    // ----------------
    // VARIABLES

    public tableElement: any;
    public hiddenInputElement: React.RefObject<HTMLInputElement>;    
    public categoriesComponent: any;
    public filterIndicatorComponent: any;
    public gridViewButton: React.RefObject<HTMLButtonElement>;
    public listViewButton: React.RefObject<HTMLButtonElement>;
    public addToOrderButton: React.RefObject<HTMLButtonElement>;
    public addToListButton: React.RefObject<HTMLButtonElement>;

    public productsVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductsProps, state: ProductsState) {
        super(props);
        let pageSize: number = this.getDefaultPageSize();
        this.state = {
            isVisible: false,
            isTableInitialized: false,
            isTableLoading: true,
            isTableError: false,
            isDisplayingAllItems: false,
            columns: [],
            operators: [
                {
                    column: {
                        field: 'earliestAvailable',                        
                    },
                    operatorOverride: '<='
                } as FilterEx
            ],
            options: {
                debounceInterval: 500,
                filtering: true,
                initialPage: 0,                
                pageSize: pageSize,
                pageSizeOptions: this.getPageSizeOptions(),
                showTitle: true,
                searchAutoFocus: false,                
                searchText: this.getStoredSearch(),
                thirdSortClick: true               
            },
            rowCount: undefined,
            total: undefined,
            products: [],
            useStoredQuery: this.props.productsState.products ? true : false,
            selectedCategory: undefined,
            shipDate: this.getShipDate(),
            tableView: this.getDefaultTableView()
        };
        this.tableElement = React.createRef();
        this.hiddenInputElement = React.createRef<HTMLInputElement>();
        this.categoriesComponent = React.createRef();
        this.filterIndicatorComponent = React.createRef();
        this.gridViewButton = React.createRef<HTMLButtonElement>();
        this.listViewButton = React.createRef<HTMLButtonElement>();
        this.addToOrderButton = React.createRef<HTMLButtonElement>();
        this.addToListButton = React.createRef<HTMLButtonElement>();
        this.ensureDataFetched();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.props.actions.clearMessage();
        this.setTableView(this.getDefaultTableView());        
        if (this.props.clientState.client &&
            this.props.orderDatesState.orderDates &&
            this.props.collectionsState.collections &&
            this.props.colorsState.colors) {
            this.setState({
                isVisible: true,
                columns: this.getColumnDefinitions()
            });
        }
    }

    public componentDidUpdate = (prevProps: ProductsProps) => {
        if (this.props.clientState.client &&
            this.props.orderDatesState.orderDates &&
            this.props.collectionsState.collections &&
            this.props.colorsState.colors &&
            !this.state.isVisible) {

            let options: Options<any> = this.state.options;
            options.pageSize = this.getDefaultPageSize();
            options.pageSizeOptions = this.getPageSizeOptions();
            setTimeout(() => {
                this.setState({
                    isVisible: true,
                    columns: this.getColumnDefinitions(),
                    options: options,
                    shipDate: this.getShipDate()
                });
            });
        }
        else if (this.props.ordersState.orderDetail && !prevProps.ordersState.orderDetail) {
            setTimeout(() => {
                this.setState({
                    shipDate: this.getShipDate()
                });
            })
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={(this.state.isTableLoading == true || this.state.isTableInitialized == false)} />
                <motion.div id="productsPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"}
                    variants={this.productsVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className="title justify-content-start d-block d-sm-none">
                            <Col className="pl-0 pr-0 text-left">
                                <h2 className="page-title">Products</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="view-options">
                                <motion.div animate={this.state.isTableInitialized ? "visible" : "hidden"} initial={"hidden"}
                                    variants={this.productsVariants} transition={{ duration: 0.5 }}>
                                    <ButtonGroup>
                                        <Button color="secondary" key="{TableViewType.Grid}" innerRef={this.gridViewButton}
                                            onClick={() => this.changeTableView(TableViewType.Grid)} title="Grid View">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-grid">
                                                <rect x="3" y="3" width="7" height="7"></rect>
                                                <rect x="14" y="3" width="7" height="7"></rect>
                                                <rect x="14" y="14" width="7" height="7"></rect>
                                                <rect x="3" y="14" width="7" height="7"></rect>
                                            </svg>
                                        </Button>
                                        <Button color="secondary" key="{TableViewType.List}" innerRef={this.listViewButton} 
                                            onClick={() => this.changeTableView(TableViewType.List)} title="List View">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-list">
                                                <line x1="8" y1="6" x2="21" y2="6"></line>
                                                <line x1="8" y1="12" x2="21" y2="12"></line>
                                                <line x1="8" y1="18" x2="21" y2="18"></line>
                                                <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                                <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                                <line x1="3" y1="18" x2="3.01" y2="18"></line>
                                            </svg>
                                        </Button>
                                    </ButtonGroup>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row className={"table-container" + (this.state.isTableError ? " load-error" : "") + (this.state.isTableLoading || !this.state.isTableInitialized ? " loading" : "") + (this.state.isTableInitialized ? " initialized" : "") + (this.state.isDisplayingAllItems ? " all-items": "")}>
                            <Col className="pl-0 pr-0">
                                {this.state.isVisible && (
                                    <MaterialTable
                                        title={"Products"}
                                        tableRef={this.tableElement}
                                        columns={this.state.columns}
                                        data={this.getProducts}
                                        icons={TableIcons.icons}
                                        options={this.state.options}
                                        components={{
                                            OverlayError: props => (
                                                <span></span>
                                            ),
                                            OverlayLoading: props => (
                                                <span></span>
                                            ),
                                            Row: props => (
                                                <tr className="MuiTableRow-root lightbox">
                                                    <td colSpan={props.columns.length}>
                                                        {props.index === 0 && this.props.clientState.client && (
                                                            <React.Fragment>
                                                                <TableFilterIndicator text={this.state.selectedCategory} total={this.state.total} isLoading={this.state.isTableLoading} onClear={this.onClearCategoryFilter} />
                                                                {this.state.tableView === TableViewType.Grid && (
                                                                    <div className="lightbox-wrapper">
                                                                        <ProductLightbox clientCode={this.props.clientState.client.code} products={this.state.products} isLoading={this.state.isTableLoading} onSelect={this.onSelectProduct} />
                                                                    </div>
                                                                )}
                                                                {this.state.tableView === TableViewType.List && (
                                                                    <div className="lightbox-wrapper">
                                                                        <ProductListbox client={this.props.clientState.client} products={this.state.products} shipDate={this.state.shipDate} isLoading={this.state.isTableLoading}
                                                                            onSelect={this.onSelectItem} addToOrderButton={this.addToOrderButton} onGoToOrder={this.goToOrder} addToListButton={this.addToListButton} />
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        }}
                                        localization={{
                                            body: {
                                                emptyDataSourceMessage: (
                                                    <React.Fragment>
                                                        {this.state.rowCount === 0 && (
                                                            <div className="no-products-message">
                                                                <TableFilterIndicator text={this.state.selectedCategory} total={this.state.total} isLoading={this.state.isTableLoading} onClear={this.onClearCategoryFilter} />
                                                                <label className="message">No products to display</label>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )
                                            },
                                            pagination: {
                                                labelRowsSelect: 'per page',
                                            }
                                        }}
                                        actions={[
                                            {
                                                icon: TableIcons.getCategoryIcon,
                                                tooltip: "Show Categories",
                                                isFreeAction: true,
                                                onClick: this.showCategories,
                                                disabled: this.state.isTableLoading ? true : false
                                            }
                                        ]}
                                        // onChangePage={this.onChangePage}
                                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                                    />
                                )}
                            </Col>
                        </Row>
                        {this.state.isTableError && (
                            <Row className="justify-content-center table-error">
                                <Col className="pt-4" xs={"auto"}>
                                    <Button color="primary" onClick={this.reloadTable}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                            <polyline points="1 4 1 10 7 10"></polyline>
                                            <polyline points="23 20 23 14 17 14"></polyline>
                                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                        </svg>
                                        Reload
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </motion.div>
                {this.state.tableView === TableViewType.List && (
                    <div id="productsPage-action-bar">
                        <div className="button-bar">
                            <Button type="button" color="primary" innerRef={this.addToOrderButton}>
                                Add to Order
                            </Button>
                            {this.isListable() && (
                                <Button type="button" color="secondary" innerRef={this.addToListButton} disabled={true}>
                                    Add to List
                                </Button>
                            )}
                        </div>
                    </div>
                )}
                <ProductCategories ref={this.categoriesComponent} onDismiss={this.hideCategories} onSelectCategory={this.onSelectCategory}
                    selectedCategoryName={this.state.selectedCategory} />
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private appendChildGarmentIds = (garmentIds: string, child: CategoriesStore.Category): string => {
        garmentIds = garmentIds + "," + child.groupId;
        for (let n: number = 0; n < child.items.length; n++) {
            garmentIds = this.appendChildGarmentIds(garmentIds, child.items[n]);
        }
        return garmentIds;
    }

    private changeTableView = (tableView: TableViewType): void => {
        let currentTableView: TableViewType = this.getCurrentTableView();
        if (tableView != currentTableView) {
            this.setTableView(tableView);
            this.reloadTable();
        }
    }

    private enableTableViewSelector = (enabled: boolean): void => {
        let gridButton: HTMLButtonElement | null = this.gridViewButton.current;
        let listButton: HTMLButtonElement | null = this.listViewButton.current;
        if (gridButton && listButton) {
            gridButton.disabled = !enabled;
            listButton.disabled = !enabled;
        }
    }

    private ensureDataFetched = () => {        
        if (this.props.clientState.client) {
            this.props.actions.requestOrderDates(this.props.clientState.client);
            this.props.actions.requestCollections(this.props.clientState.client.code);
            setTimeout(() => {
                if (this.props.clientState.client) {
                    this.props.actions.requestColors(this.props.clientState.client.code);
                    this.props.actions.requestCategories(this.props.clientState.client.code);
                }
            }, 800);
        }
        else {
            setTimeout(() => {
                this.ensureDataFetched();
            }, 250);
        }
    }

    private findCategory = (groupId: string): CategoriesStore.Category | null => {
        let category: CategoriesStore.Category | null = null;
        if (this.props.categoriesState && this.props.categoriesState.categories) {
            for (let n: number = 0; n < this.props.categoriesState.categories.length; n++) {
                category = this.findCategoryOrChild(groupId, this.props.categoriesState.categories[n]);
                if (category) {
                    break;
                }
            }
        }
        return category;
    }

    private findCategoryOrChild = (groupId: string, category: CategoriesStore.Category): CategoriesStore.Category | null => {
        let categoryOrChild: CategoriesStore.Category | null = null;
        if (category.groupId === groupId) {
            categoryOrChild = category;
        }
        else {
            for (let n: number = 0; n < category.items.length; n++) {
                categoryOrChild = this.findCategoryOrChild(groupId, category.items[n]);
                if (categoryOrChild) {
                    break;
                }
            }
        }
        return categoryOrChild;
    }

    private getCategoryFilterIndicator = (query: Query<any>): string| null | undefined => {
        let filterIndicator: string | null | undefined = undefined;
        let filter = query.filters.filter((f: Filter<any>) => {
            return f.column.field === 'garmentType.code';
        });
        if (filter.length > 0 && this.props.categoriesState.categories) {
            let groupId: string = filter[0].value.split(',')[0];
            let category: CategoriesStore.Category | null = null;
            for (let n = 0; n < this.props.categoriesState.categories.length; n++) {
                category = this.findCategory(groupId);
            }
            if (category) {
                filterIndicator = category.qualifiedName;
            }
        }
        return filterIndicator;
    }

    private getCollectionListItems = (): TableFilterListItem[] => {
        let listItems: TableFilterListItem[] = [];
        listItems.push({ label: 'All', value: '0' } as TableFilterListItem);
        if (this.props.collectionsState.collections) {
            for (let n: number = 0; n < this.props.collectionsState.collections.length; n++) {
                let collection: CollectionsStore.Collection = this.props.collectionsState.collections[n];
                listItems.push({ label: collection.displayName, value: collection.code } as TableFilterListItem);
            }
        }
        return listItems;
    }

    private getColorListItems = (): TableFilterListItem[] => {
        let listItems: TableFilterListItem[] = [];
        listItems.push({ label: 'All', value: '0' } as TableFilterListItem);
        if (this.props.colorsState.colors) {
            for (let n: number = 0; n < this.props.colorsState.colors.length; n++) {
                let color: Color = this.props.colorsState.colors[n];
                listItems.push({ label: color.displayName, value: color.name } as TableFilterListItem);
            }
        }
        return listItems;
    }

    private getColumnDefinitions = (): any[] => {
        let columns: any[] = [];
        if (this.props.clientState.client) {
            switch (this.props.clientState.client.code) {
                case ClientCode.GTN:
                    columns = this.getGTNColumnDefinitions()
                    break;
                default:
                    columns = this.getDefaultColumnDefinitions();
                    break;
            }
        }
        return columns;
    }

    private getCurrentDate = (): Date => {
        return (this.props.orderDatesState.orderDates && this.props.orderDatesState.orderDates.currentDate) ?
            this.props.orderDatesState.orderDates.currentDate : new Date();
    }

    private getCurrentTableView = (): TableViewType => {
        let view: TableViewType = this.getDefaultTableView();
        let gridButton: HTMLButtonElement | null = this.gridViewButton.current;
        let listButton: HTMLButtonElement | null = this.listViewButton.current;
        if (listButton && gridButton) {
            view = $(listButton).hasClass('active') ? TableViewType.List : TableViewType.Grid;
        }
        return view;
    }

    private getDefaultColumnDefinitions = (): any => {
        return [
            {
                title: "Available",
                field: "earliestAvailable",
                type: "date",
                cellStyle: { width: '14%' },
                filterCellStyle: { width: '14%' },
                headerCellStyle: { width: '14%' },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"date-picker"}
                        initialValue={this.getDefaultDate("earliestAvailable")}
                        currentDate={this.getCurrentDate()}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Style",
                field: "style.code",
                type: "string",
                cellStyle: { width: '18%' },
                filterCellStyle: { width: '18%' },
                headerCellStyle: { width: '18%' },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("style.code")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Name",
                field: "style.name",
                type: "string",
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("style.name")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Gender",
                field: "gender.code",
                type: "string",
                cellStyle: { width: '18%' },
                filterCellStyle: { width: '18%' },
                headerCellStyle: { width: '18%' },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"list"}
                        initialValue={this.getStoredFilterListItem("gender.code") || { label: 'All', value: '0' }}
                        listItems={[
                            { label: 'All', value: '0' },
                            { label: 'Mens', value: 'M' },
                            { label: 'Womens', value: 'F' },
                            { label: 'Youth', value: 'Y' },
                            { label: 'Unisex', value: 'N' }

                        ]}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Garment Type",
                field: "garmentType.code",
                type: "string",
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"hidden"}
                        initialValue={this.getStoredFilter("garmentType.code")}
                        hiddenInputRef={this.hiddenInputElement}
                        onFilterChanged={props.onFilterChanged} />
            }
        ];
    }

    private getGTNColumnDefinitions = (): any => {
        return [
            {
                title: "Available",
                field: "earliestAvailable",
                type: "date",
                cellStyle: { width: '14%' },
                filterCellStyle: { width: '14%' },
                headerCellStyle: { width: '14%' },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"date-picker"}
                        initialValue={this.getStoredFilterDate("earliestAvailable")}
                        currentDate={this.getCurrentDate()}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Collection",
                field: "season.code",
                type: "string",
                cellStyle: { width: '18%' },
                filterCellStyle: { width: '18%' },
                headerCellStyle: { width: '18% ' },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"list"}
                        initialValue={this.getStoredFilterListItem("season.code") || { label: 'All', value: '0' }}
                        listItems={this.getCollectionListItems()}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Color",
                field: "color.name",
                type: "string",
                cellStyle: { width: '21%' },
                filterCellStyle: { width: '21%' },
                headerCellStyle: { width: '21%' },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"color-picker"}
                        initialValue={this.getStoredFilterListItem("color.name") || { label: 'All', value: '0' }}
                        listItems={this.getColorListItems()}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Style",
                field: "style.name",
                type: "string",
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("style.name")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Garment Type",
                field: "garmentType.code",
                type: "string",
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"hidden"}
                        initialValue={this.getStoredFilter("garmentType.code")}
                        hiddenInputRef={this.hiddenInputElement}
                        onFilterChanged={props.onFilterChanged} />
            }
        ];
    }

    private getDefaultDate = (field: string): Date => {
        let defaultDate: Date = this.props.ordersState.orderDetail ?
            this.props.ordersState.orderDetail.shipDate :
            this.getCurrentDate();
        let storedDateFilter: Date | undefined = this.getStoredFilterDate(field);
        if (storedDateFilter && storedDateFilter > defaultDate) {
            defaultDate = storedDateFilter;
        }
        return defaultDate;
    }

    private getDefaultPageSize = (): number => {
        let defaultPageSize: number = 24;
        if (this.props.productsState.products) {
            defaultPageSize = this.props.productsState.products.query.pageSize;
        }
        else if (this.props.userState.user && this.props.userState.user.preferences && this.props.userState.user.preferences.productsPerPage) {
            let ppp: string = this.props.userState.user.preferences.productsPerPage;
            if (ppp === 'All' && this.props.clientState.client && this.props.clientState.client.settings.productSettings.maxPerPage) {
                defaultPageSize = this.props.clientState.client.settings.productSettings.maxPerPage as number;
            }
            else if (!isNaN(parseInt(ppp))) {
                defaultPageSize = parseInt(ppp);
            }
            else if (this.props.clientState.client && this.props.clientState.client.settings.productSettings.defaultPerPage) {
                defaultPageSize = this.props.clientState.client.settings.productSettings.defaultPerPage as number;
            }
        }
        else if (this.props.clientState.client && this.props.clientState.client.settings.productSettings.defaultPerPage) {
            defaultPageSize = this.props.clientState.client.settings.productSettings.defaultPerPage as number;
        }
        return defaultPageSize;
    }

    private getDefaultTableView = (): TableViewType => {
        return this.props.productsState.products ?
            this.props.productsState.products.view :
            TableViewType.Grid;
    }

    private getFilter = (field: string): Filter<any> | undefined => {
        let filter: Filter<any> | undefined = undefined;
        if (this.props.productsState.products && this.props.productsState.products.query) {
            let matches: Filter<any>[] = this.props.productsState.products.query.filters.filter((filter: Filter<any>) => {
                return filter.column.field === field;
            });
            if (matches.length > 0) {
                filter = matches[0];
            }
        }
        return filter;
    }

    private getFilterOperator = (field: string | number | symbol | undefined): string | undefined => {
        let filterOperator: string | undefined = undefined;
        let index: number = this.state.operators.findIndex(f => f.column.field === field);
        if (index >= 0) {
            filterOperator = this.state.operators[index].operatorOverride;
        }
        return filterOperator;
    }

    private getPageSizeOptions = (): number[] => {
        let pageSizeOptions: number[] = [12, 24, 96];
        if (this.props.clientState.client && this.props.clientState.client.settings.productSettings.maxPerPage) {
            pageSizeOptions.push(this.props.clientState.client.settings.productSettings.maxPerPage as number);
        }
        return pageSizeOptions;
    }

    private getProducts = (query: Query<any>): Promise<QueryResult<any>> => {

        return new Promise((resolve, reject) => {

            if (!this.state.isTableInitialized && query.filters.length === 0) {
                resolve({
                    data: [],
                    page: 0,
                    totalCount: 0
                });
            }
            else {
                if (this.props.clientState.client) {
                    this.showTableLoader(true, this.state.isTableInitialized, undefined, this.state.total);
                    let mergedQuery: Query<any> = this.mergeQuery(query);
                    let tableView: TableViewType = this.getCurrentTableView();
                    this.props.asyncActions.requestProductsAsync(this.props.clientState.client.code, mergedQuery, tableView)
                        .then(result => {
                            if (result) {
                                this.setState({
                                    products: result.data,                                    
                                    selectedCategory: this.getCategoryFilterIndicator(query),
                                    isDisplayingAllItems: this.isAllItemQuery(result),
                                    tableView: tableView
                                })
                                resolve(result);
                                this.showTableLoader(false, true, result.data.length, result.totalCount);
                                this.updateSelectedPageSize(mergedQuery.pageSize);
                                this.enableTableViewSelector(true);
                            }
                        },
                        err => {
                            this.showTableReloader();
                            resolve({
                                data: [],
                                page: 0,
                                totalCount: 0
                            })
                        });
                }
                else {
                    reject("Client is undefined");
                }
            }
        });
    }

    private getShipDate = (): Date | null | undefined => {
        let shipDate: Date | null | undefined = undefined;
        if (this.props.orderDatesState.orderDates) {
            shipDate = this.props.ordersState.orderDetail ?
                this.props.ordersState.orderDetail.shipDate :
                this.props.orderDatesState.orderDates.currentDate;
        }
        return shipDate;
    }

    private getStoredFilter = (field: string): string | undefined => {
        let filter: Filter<any> | undefined = this.getFilter(field);
        return filter ? filter.value : undefined;
    }

    private getStoredFilterDate = (field: string): Date | undefined => {
        let storedFilterDate: Date | undefined = undefined;
        let storedFilter: string | undefined = this.getStoredFilter(field);
        if (storedFilter) {
            storedFilterDate = new Date(storedFilter);
        }
        return storedFilterDate;
    }

    private getStoredFilterListItem = (field: string): TableFilterListItem | undefined => {
        let storedFilterListItem: TableFilterListItem | undefined = undefined;
        let storedFilter: string | undefined = this.getStoredFilter(field);
        if (storedFilter) {
            switch (field) {
                case "season.code":
                    if (storedFilter != '0' && this.props.collectionsState.collections) {
                        let matches: CollectionsStore.Collection[] = this.props.collectionsState.collections.filter((collection: CollectionsStore.Collection) => {
                            return collection.code === storedFilter;
                        });
                        if (matches.length > 0) {
                            storedFilterListItem = { label: matches[0].displayName, value: storedFilter };
                        }
                    }
                    break;
                case "color.name":
                    if (storedFilter != '0' && this.props.colorsState.colors) {
                        let matches: Color[] = this.props.colorsState.colors.filter((color: Color) => {
                            return color.name === storedFilter;
                        });
                        if (matches.length > 0) {
                            storedFilterListItem = { label: matches[0].displayName || '', value: storedFilter };
                        }
                    }
                    break;
                case "gender.code":
                    switch (storedFilter) {
                        case "F":
                            storedFilterListItem = { label: 'Womens', value: 'F' };
                            break;
                        case "M":
                            storedFilterListItem = { label: 'Mens', value: 'M' };
                            break;
                        case "Y":
                            storedFilterListItem = { label: "Youth", value: 'Y' };
                            break;
                        case "N":
                            storedFilterListItem = { label: "Unisex", value: 'N' };
                            break;
                    }
                    break;
            }
        }
        return storedFilterListItem;
    }

    private getStoredSearch = (): string | undefined => {
        let searchText: string | undefined = undefined;
        if (this.props.productsState.products) {
            searchText = this.props.productsState.products.query.search;
        }
        return searchText;
    }

    private goToOrder = (id: number): void => {
        this.props.history.push('/orders/edit/' + id + '/detail');
    }

    private hideCategories = (): void => {
        if (this.categoriesComponent && this.categoriesComponent.current) {
            this.categoriesComponent.current.setState({ isOpen: false });
        }
    }

    private isAllItemQuery = (queryResult: QueryResult<any>): boolean => {
        let allItemQuery: boolean = false;
        if (queryResult.data.length >= queryResult.totalCount) {
            allItemQuery = true;
        }
        return allItemQuery;
    }

    private isListable = (): boolean => {
        return (this.props.userState.user && this.props.userState.user.role != Role.Buyer ? true : false);
    }

    private mergeQuery = (query: Query<any>): Query<any> => {
        if (this.state.useStoredQuery && this.props.productsState.products && !this.onSearchChange(query)) {
            let pageSize: number = query.pageSize;
            let storedQuery = this.props.productsState.products.query;
            if (this.state.isTableInitialized) {
                storedQuery.page = query.page;
                storedQuery.filters = query.filters;
                storedQuery.orderDirection = query.orderDirection;
            }
            else {                
                this.setState({
                    isTableInitialized: true
                });
            }
            query = storedQuery;
            query.pageSize = pageSize;
        }

        for (let n: number = 0; n < query.filters.length; n++) {
            (query.filters[n] as FilterEx).operatorOverride = this.getFilterOperator(query.filters[n].column.field);
        }

        return query;
    }

    //private onChangePage = (page: number, pageSize: number): void => {
        // this.updatePagination();
    //}

    private onChangeRowsPerPage = (pageSize: number): void => {
        this.updateSelectedPageSize(pageSize);
    }

    private onClearCategoryFilter = (): void => {
        this.onSelectCategory(undefined);
    }

    private onSearchChange = (query: Query<any>): boolean => {
        let searchChange: boolean = false;
        if (this.state.useStoredQuery && this.props.productsState.products) {
            searchChange = (query.search != this.props.productsState.products.query.search);
            if (searchChange) {
                this.setState({
                    useStoredQuery: false
                });
            }
        }
        return searchChange;
    }

    private onSelectCategory = (category: CategoriesStore.Category | undefined): void => {
        this.hideCategories();
        let garmentIds: string | null = category ? "" : null;
        if (category) {
            garmentIds = category.groupId;
            for (let n: number = 0; n < category.items.length; n++) {
                garmentIds = this.appendChildGarmentIds(garmentIds, category.items[n]);
            }
        }
        if (this.hiddenInputElement && this.hiddenInputElement.current) {
            let inputValueProperty: PropertyDescriptor | undefined = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value");
            if (inputValueProperty) {
                let setter: any = inputValueProperty.set;
                if (setter) {
                    setter.call(this.hiddenInputElement.current, garmentIds);
                    let event: Event = new Event('input', { bubbles: true });
                    this.hiddenInputElement.current.dispatchEvent(event);
                }
            }
        }
    }

    private onSelectItem = (selectedItems: number): void => {

    }

    private onSelectProduct = (product: ProductsStore.Product): void => {
        this.props.history.push('/products/detail/' + encodeURIComponent(product.sanitizedId), { from: '/products' });
    }

    private reloadTable = () => {
        this.props.actions.clearMessage();
        this.tableElement.current.onQueryChange();
    }

    private setTableView = (tableView: TableViewType): void => {
        let gridButton: HTMLButtonElement | null = this.gridViewButton.current;
        let listButton: HTMLButtonElement | null = this.listViewButton.current;
        if (gridButton && listButton) {
            if (tableView === TableViewType.List) {
                if (!$(listButton).hasClass('active')) {
                    $(gridButton).removeClass('active');
                    $(listButton).addClass('active');
                    gridButton.disabled = true;
                    listButton.disabled = true;
                }
            }
            else {
                if (!$(gridButton).hasClass('active')) {
                    $(listButton).removeClass('active');
                    $(gridButton).addClass('active');
                    gridButton.disabled = true;
                    listButton.disabled = true;
                }
            }
        } 
    }

    private showCategories = (): void => {
        if (this.categoriesComponent && this.categoriesComponent.current) {
            this.categoriesComponent.current.setState({ isOpen: true });
        }
    }

    private showTableLoader = (active: boolean, initialized: boolean, rowCount: number | undefined, total: number | undefined): void => {
        if (this.state.isTableLoading != active) {
            setTimeout(() => {
                this.setState({
                    isTableInitialized: initialized ? true : false,
                    isTableLoading: active ? true : false,
                    isTableError: false,
                    rowCount: rowCount,
                    total: total
                });
            });
        }
    }

    private showTableReloader = (): void => {
        setTimeout(() => {
            this.setState({
                isTableInitialized: true,
                isTableLoading: false,
                isTableError: true
            });
        }, 500);
    }

    // private updatePagination = (pageSize: number): void => {
        //let paginationCaption: JQuery<HTMLElement> = $('.MuiTablePagination-root .MuiTypography-caption');
    //}

    private updateSelectedPageSize = (pageSize: number): void => {
        let paginationSelect: JQuery<HTMLElement> = $('.MuiTablePagination-select.MuiSelect-selectMenu');
        if (this.props.clientState.client && paginationSelect.length > 0) {
            let selectedPageSize: string = pageSize + " per page";
            if (this.props.clientState.client) {
                let maxPerPage: number | null = this.props.clientState.client.settings.productSettings.maxPerPage;
                if (maxPerPage && maxPerPage == pageSize) {
                    if (this.props.clientState.client.settings.productSettings.maxPerPageEqualsAll) {
                        selectedPageSize = "All items";
                        $("body").addClass("max-per-page-equals-all");
                    }
                }
            }
            paginationSelect.find('div').text(selectedPageSize);
        }
        else {
            setTimeout(() => {
                this.updateSelectedPageSize(pageSize);
            }, 400);
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        categoriesState: state.categories,
        clientState: state.client,
        collectionsState: state.collections,
        colorsState: state.colors,
        orderDatesState: state.orderDates,
        ordersState: state.orders,
        productsState: state.products,
        messageState: state.message,
        userState: state.user
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            CategoriesStore.actionCreators,
            CollectionsStore.actionCreators,
            ColorsStore.actionCreators,
            OrderDatesStore.actionCreators,
            ProductsStore.actionCreators,
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestProductsAsync: (clientCode: ClientCode, query: Query<any>, view: TableViewType) => dispatch(ProductsStore.actionCreators.requestProducts(clientCode, query, view))
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Products as any);
