import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import { BouncedOrder } from '../../store/Orders';
import { ClientCode } from '../../enums/ClientCode';
import { Button, Col, Container, Row } from 'reactstrap';
import MaterialTable, { Column, Filter, MTableBodyRow, MTableToolbar, Options, Query, QueryResult } from 'material-table';
import { BaseTableFilter } from '../../common/BaseTypes';
import { FilterEx } from '../../common/ExtendedTypes';
import TableFilter from '../table-filter/TableFilter';
import { TableFilterListItem } from '../table-filter/TableFilter';
import * as TableIcons from '../table-icons/TableIcons';
import DragHandle from '@material-ui/icons/DragHandle';
import BouncedOrderCard from '../bounced-order-card/BouncedOrderCard';
import ResubmitOrder from '../resubmit-order/ResubmitOrder';
import Loader from '../loader/Loader';
import debounce from 'lodash.debounce';
import $ from 'jquery';

import './BouncedOrders.scss';

// ----------------
// PROPS

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrdersStore.actionCreators)
};

interface BouncedOrdersAsyncActions {
    asyncActions: {
        requestBouncedOrdersAsync: (client: ClientStore.Client, query: Query<any>) => Promise<QueryResult<any>>;
    }
}

interface BouncedOrdersOwnProps {
    onGoToOrder: (id: number) => void;
}

type BouncedOrdersProps =
    BouncedOrdersOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators    // ... plus action creators we've requested
    & BouncedOrdersAsyncActions;

// ----------------
// LOCAL STATE

interface BouncedOrdersState {
    isVisible: boolean;
    isTableInitialized: boolean;
    isTableLoading: boolean;
    isTableError: boolean;
    columns: any[];
    operators: FilterEx[];
    options: Options<any>;
    pageOverride: number | undefined;
    rowCount: number | undefined;
    total: number | undefined;
    useStoredQuery: boolean;
    resubmitOrder: BouncedOrder | null | undefined;
}

// ----------------
// CONSTANTS

const BOUNCED_ORDERS_PAGE_SIZE: number = 10;

class BouncedOrders extends React.PureComponent<BouncedOrdersProps, BouncedOrdersState> {
 
    // ----------------
    // VARIABLES

    public tableElement: any;

    public bouncedOrdersVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: BouncedOrdersProps, state: BouncedOrdersState) {
        super(props);
        this.state = {
            isVisible: false,
            isTableInitialized: false,
            isTableLoading: false,
            isTableError: false,
            columns: [],
            operators: [],
            options: {
                columnResizable: false,
                debounceInterval: 500,
                doubleHorizontalScroll: true,
                filtering: true,
                fixedColumns: { left: 1 },
                initialPage: 0,
                pageSize: BOUNCED_ORDERS_PAGE_SIZE,
                pageSizeOptions: [],
                showTitle: false,
                searchAutoFocus: false,
                searchText: this.getStoredSearch(),
                tableLayout: 'fixed',
                thirdSortClick: false
            },
            pageOverride: this.getPageOverride(BOUNCED_ORDERS_PAGE_SIZE),
            rowCount: undefined,
            total: undefined,
            useStoredQuery: true,
            resubmitOrder: undefined
        };
        this.tableElement = React.createRef();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.props.actions.clearMessage();
        if (this.props.clientState.client) {
            this.setState({
                isVisible: true,
                columns: this.getColumnDefinitions()
            });
            this.configureTableColumns();
        }
        window.addEventListener('resize', this.onWindowResize);
    }

    public componentDidUpdate = (prevProps: BouncedOrdersProps) => {
        if (this.props.clientState.client && !this.state.isVisible) {
            this.setState({
                isVisible: true,
                columns: this.getColumnDefinitions()
            });
            this.configureTableColumns();
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <div className="bounced-orders">
                <Loader isLoading={this.state.isTableLoading || !this.state.isTableInitialized || !this.state.isVisible} isChild={false} />
                <motion.div animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"}
                    variants={this.bouncedOrdersVariants} transition={{ duration: 0.75, delay: 0.1 }}>
                    <Container>
                        <Row className={"table-container" + (this.state.isTableError ? " load-error" : "") + (this.state.isTableLoading ? " loading" : "") + (this.state.isTableInitialized ? " initialized" : "")}>
                            <Col className="pl-0 pr-0">
                                {this.props.clientState.client && (
                                    <MaterialTable
                                        tableRef={this.tableElement}
                                        columns={this.state.columns}
                                        data={this.getBouncedOrders}
                                        icons={TableIcons.icons}
                                        options={this.state.options}
                                        components={{
                                            OverlayError: props => (
                                                <span></span>
                                            ),
                                            OverlayLoading: props => (
                                                <span></span>
                                            )
                                        }}
                                        actions={[
                                            (rowData: BouncedOrder) => {
                                                return {
                                                    icon: TableIcons.getSearchIcon,
                                                    tooltip: 'View Order',
                                                    onClick: this.onViewOrder
                                                };
                                            },
                                            (rowData: BouncedOrder) => {
                                                return {
                                                    icon: TableIcons.getUploadIcon,
                                                    tooltip: 'Resubmit Order',
                                                    onClick: this.onResubmitOrder
                                                }
                                            }
                                        ]}
                                        localization={{
                                            header: {
                                                actions: ''
                                            },
                                            body: {
                                                emptyDataSourceMessage: this.state.rowCount === 0 ? 'No orders to display' : ''
                                            }
                                        }}
                                        onChangePage={this.onChangePage}
                                    />
                                )}
                            </Col>
                        </Row>
                        {this.state.isTableError && (
                            <Row className="justify-content-center table-error">
                                <Col className="pt-4" xs={"auto"}>
                                    <Button color="primary" onClick={this.reloadTable}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                            <polyline points="1 4 1 10 7 10"></polyline>
                                            <polyline points="23 20 23 14 17 14"></polyline>
                                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                        </svg>
                                        Reload
                                    </Button>                                    
                                </Col>
                            </Row>
                        )}
                        {this.props.clientState.client && (
                            <ResubmitOrder client={this.props.clientState.client} orderToResubmit={this.state.resubmitOrder} onDismiss={this.hideResubmitOrder} />
                        )}
                    </Container>
                </motion.div>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private configureTableColumns = (): void => {
        if (this.tableElement.current) {
            let isSmallScreen: boolean = this.isBreakpoint('sm');
            let columns: Column<any>[] = this.tableElement.current.props.columns;
            if (columns.length > 0) {
                for (let n: number = 0; n < columns.length; n++) {
                    let col: Column<any> = columns[n];
                    if (n == 0) {
                        this.tableElement.current.onChangeColumnHidden(col, !isSmallScreen);
                    }
                    else {
                        this.tableElement.current.onChangeColumnHidden(col, isSmallScreen);
                    }
                }
            }
            else {
                setTimeout(() => {
                    this.configureTableColumns();
                }, 400);
            }
        }
    }

    private getFilter = (field: string): Filter<any> | undefined => {
        let filter: Filter<any> | undefined = undefined;
        if (this.props.ordersState.bouncedOrders && this.props.ordersState.bouncedOrders.query) {
            let matches: Filter<any>[] = this.props.ordersState.bouncedOrders.query.filters.filter((filter: Filter<any>) => {
                return filter.column.field === field;
            });
            if (matches.length > 0) {
                filter = matches[0];
            }
        }
        return filter;
    }

    private getFilterOperator = (field: string | number | symbol | undefined): string | undefined => {
        let filterOperator: string | undefined = undefined;
        let index: number = this.state.operators.findIndex(f => f.column.field === field);
        if (index >= 0) {
            filterOperator = this.state.operators[index].operatorOverride;
        }
        return filterOperator;
    }

    private getBouncedOrders = (query: Query<any>): Promise<QueryResult<any>> => {

        return new Promise((resolve, reject) => {

            if (!this.state.isTableInitialized && query.filters.length > 0) {
                resolve({
                    data: [],
                    page: 0,
                    totalCount: 0
                })
            }
            else if (this.props.clientState.client) {
                this.showTableLoader(true, true, undefined, this.state.total);
                let mergedQuery: Query<any> = this.mergeQuery(query);
                this.props.asyncActions.requestBouncedOrdersAsync(this.props.clientState.client, mergedQuery)
                    .then(result => {
                        if (this.state.pageOverride) {
                            setTimeout(() => {
                                this.tableElement.current.onChangePage({}, this.state.pageOverride);
                            });
                        }
                        resolve(result);
                        this.showTableLoader(false, true, result.data.length, result.totalCount);
                    },
                    err => {
                        this.showTableReloader();
                        resolve({
                            data: [],
                            page: 0,
                            totalCount: 0
                        })
                    })
            }
        })
    }

    private getColumnDefinitions = (): any[] => {
        let columns: any[] = [];
        if (this.props.clientState.client) {
            switch (this.props.clientState.client.code) {
                case ClientCode.GTN:
                    columns = this.getGTNColumnDefinitions();
                    break;
            }
        }
        return columns;
    }

    private getGTNColumnDefinitions = (): any[] => {
        return [
            {
                title: "Order",
                field: "orderNumber",
                filtering: false,
                render: (data: BouncedOrder, type: "row") => <BouncedOrderCard client={this.props.clientState.client} data={data} hideCustomerName={false} /> 
            },
            {
                title: "Order No",
                field: "orderNumber",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("orderNumber"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"} upperCaseInput={true}
                        initialValue={this.getStoredFilter("orderNumber")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Customer Name",
                field: "customerNameOnly",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("customerNameOnly"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("customerNameOnly")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Submitted",
                field: "dateProcessed",
                type: "date",
                dateSetting: {
                    locale: 'en-US',
                    format: 'MM/dd/yyyy'
                },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={'date-picker'}
                        initialValue={this.getStoredFilter('dateSubmitted')}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Submitted By",
                field: "repName",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                filterComponent: (props: BaseTableFilter) => 
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter('repName')}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Error",
                field: "errorMessage",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter('errorMessage')}
                        onFilterChanged={props.onFilterChanged} />
            }
        ];
    }

    private getPageOverride = (pageSize: number): number | undefined => {
        let pageOverride: number | undefined = undefined;
        if (this.props.ordersState.bouncedOrders && this.props.ordersState.bouncedOrders.query) {
            if (this.props.ordersState.bouncedOrders.query.page > 0) {
                pageOverride = this.props.ordersState.bouncedOrders.query.page;
                let appliedPageSize: number = this.props.ordersState.bouncedOrders.query.pageSize;
                if (appliedPageSize !== BOUNCED_ORDERS_PAGE_SIZE) {
                    let lastRow: number = appliedPageSize * (pageOverride + 1);
                    if (lastRow <= pageSize) {
                        pageOverride = undefined;
                    }
                    else {
                        pageOverride = Math.floor(lastRow / pageSize);
                    }
                }
            }
        }
        return pageOverride;
    }

    private getStoredFilter = (field: string): string | undefined => {
        let filterFor: string = field;
        let filter: Filter<any> | undefined = this.getFilter(filterFor);
        return filter ? filter.value : undefined;
    }

    private getStoredFilterOperator = (field: string): string | undefined => {
        let filter: Filter<any> | undefined = this.getFilter(field);
        return filter ? (filter as FilterEx).operatorOverride : undefined;
    }

    private getStoredSearch = (): string | undefined => {
        let searchText: string | undefined = undefined;
        if (this.props.ordersState.bouncedOrders) {
            searchText = this.props.ordersState.bouncedOrders.query.search;
        }
        return searchText;
    }

    private getStoredSortOrder = (field: string): "asc" | "desc" | undefined => {
        let sortOrder: "asc" | "desc" | undefined = undefined;
        if (this.props.ordersState.bouncedOrders && this.props.ordersState.bouncedOrders.query) {
            if (this.props.ordersState.bouncedOrders.query.orderBy) {
                if (this.props.ordersState.bouncedOrders.query.orderBy.field === field) {
                    sortOrder = this.props.ordersState.bouncedOrders.query.orderDirection;
                }
            }
        }
        return sortOrder;
    }

    private hideResubmitOrder = (refresh: boolean): void => {
        this.setState({
            resubmitOrder: null
        });
        if (refresh) {
            setTimeout(() => {
                this.reloadTable();
            });
        }
    }

    private isBreakpoint = (alias: string): boolean => {
        let className: string = '.device-' + alias;
        let element: JQuery<HTMLElement> = $(className);
        return element.is(':visible');
    }

    private mergeQuery = (query: Query<any>): Query<any> => {
        if (this.state.useStoredQuery && this.props.ordersState.bouncedOrders && !this.onSearchChange(query)) {
            let storedQuery = this.props.ordersState.bouncedOrders.query;
            if (this.state.isTableInitialized) {
                storedQuery.page = query.page;
                storedQuery.filters = query.filters;
                storedQuery.orderDirection = query.orderDirection;
            }
            else {
                this.setState({
                    isTableInitialized: true
                });
            }
            query = storedQuery;
        }

        for (let n: number = 0; n < query.filters.length; n++) {
            (query.filters[n] as FilterEx).operatorOverride = this.getFilterOperator(query.filters[n].column.field);
        }

        return query;
    }

    private onChangePage = (page: number, pageSize: number): void => {
        //this.updatePagination();
    }

    private onResubmitOrder = (event: any, data: any): void => {
        let bouncedOrder: BouncedOrder = data as BouncedOrder;
        if (bouncedOrder && bouncedOrder.id) {
            this.setState({
                resubmitOrder: bouncedOrder
            });
        }
    }   

    private onSearchChange = (query: Query<any>): boolean => {
        let searchChange: boolean = false;
        if (this.state.useStoredQuery && this.props.ordersState.bouncedOrders) {
            searchChange = (query.search != this.props.ordersState.bouncedOrders.query.search);
            if (searchChange) {
                this.setState({
                    useStoredQuery: false
                });
            }
        }
        return searchChange;
    }

    private onViewOrder = (event: any, data: any): void => {
        let bouncedOrder: BouncedOrder = data as BouncedOrder;
        if (bouncedOrder && bouncedOrder.id) {
            this.props.onGoToOrder(bouncedOrder.id);
        }
    }

    private onWindowResize = debounce((): void => {
        this.configureTableColumns();
    }, 100, { leading: false, trailing: true }).bind(this);

    private reloadTable = () => {
        this.props.actions.clearMessage();
        this.tableElement.current.onQueryChange();
    }

    private showTableLoader = (active: boolean, initialized: boolean, rowCount: number | undefined, total: number | undefined): void => {
        if (this.state.isTableLoading != active) {
            setTimeout(() => {
                this.setState({
                    isTableInitialized: initialized ? true : false,
                    isTableLoading: active ? true : false,
                    isTableError: false,
                    pageOverride: initialized ? undefined : this.state.pageOverride,
                    rowCount: rowCount,
                    total: total
                });
            });
        }
    }

    private showTableReloader = (): void => {
        setTimeout(() => {
            this.setState({
                isTableInitialized: true,
                isTableLoading: false,
                isTableError: true
            });
        }, 500);
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        ordersState: state.orders
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrdersStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestBouncedOrdersAsync: (client: ClientStore.Client, query: Query<any>) => dispatch(OrdersStore.actionCreators.requestBouncedOrders(client, query))
        }
    }
}

export default connect<{}, {}, BouncedOrdersOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(BouncedOrders as any);
