import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import { ClientCode } from '../../enums/ClientCode';
import { FindByType } from '../../enums/FindByType';
import { ProductlistType } from '../../enums/ProductlistType';
import * as ErrorMessage from '../../common/ErrorMessage';
import { AsyncTypeahead, Highlighter, Typeahead, TypeaheadMenuProps, TypeaheadResult } from 'react-bootstrap-typeahead';
import sanitizeHTML from 'sanitize-html';
import $ from 'jquery';

import './ProductlistFinder.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
};

interface ProductlistFinderOwnProps {
    clearInput?: boolean | null | undefined;
    initialValue?: ProductlistName | null | undefined;
    onChange: (selected: ProductlistName | null) => void;
    disabled?: boolean | undefined;
    autoFocus?: boolean | undefined;
    editableListsOnly: boolean;
}

type ProductlistFinderProps =
    ProductlistFinderOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface ProductlistFinderState {
    findBy: FindByType;
    isLoading: boolean;
    openMenu: boolean | undefined;
    options: ProductlistName[];
    selected: ProductlistName[];
}

// ----------------
// LOCAL TYPES

interface ProductlistNameQuery {
    clientCode: ClientCode;
    keyword: string;
    findBy: FindByType;
    editableListsOnly: boolean;
}

export interface ProductlistName {
    billto: string;
    shipto: string;
    billto_shipto: string;
    number: string;
    name: string;
    customerName: string;
    listType: ProductlistType;
}

class ProductlistFinder extends React.PureComponent<ProductlistFinderProps, ProductlistFinderState> {

    // ----------------
    // VARIABLES

    public finderElement: any;

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductlistFinderProps, state: ProductlistFinderState) {
        super(props);
        this.state = {
            findBy: FindByType.Name,
            isLoading: false,
            openMenu: this.props.initialValue ? false : undefined,
            options: this.props.initialValue ? [this.props.initialValue] : [],
            selected: this.props.initialValue ? [this.props.initialValue] : []
        };
        this.finderElement = React.createRef();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: ProductlistFinderProps) => {
        if (this.props.autoFocus && !prevProps.autoFocus) {
            this.setFocus();
        }
        if (this.props.clearInput && !prevProps.clearInput) {
            if (this.finderElement.current) {
                this.finderElement.current.clear();
                this.handleChange([]);
            }
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        let isDisabled: boolean = this.props.disabled === true ? true : false;
        return (
            <div id="productlist-finder">
                <AsyncTypeahead
                    id="rbt-productlist-finder"
                    clearButton={true}
                    filterBy={() => true}
                    highlightOnlyResult={true}
                    isLoading={this.state.isLoading}
                    labelKey={this.formatOption}
                    minLength={this.state.findBy === FindByType.Number ? 1 : 2}
                    options={this.state.options}
                    onChange={this.handleChange}
                    onSearch={this.handleSearch}
                    onInputChange={this.handleInputChange}
                    open={this.state.openMenu}
                    placeholder="Find List..."
                    renderMenuItemChildren={this.renderOption}
                    selected={this.state.selected}
                    useCache={false}
                    disabled={isDisabled}
                    ref={node => this.finderElement.current = node}
                />
                <div className="finder-options">
                    <label className={"radio-button-wrapper" + (isDisabled ? " disabled" : "")}>
                        <input type="radio" name="customer-findby" value={FindByType.Name} checked={this.state.findBy === FindByType.Name}
                            onChange={this.selectFindByOption} disabled={isDisabled} />
                        <span>By Name</span>
                    </label>
                    <label className={"radio-button-wrapper" + (isDisabled ? " disabled" : "")}>
                        <input type="radio" name="customer-findby" value={FindByType.Number} checked={this.state.findBy === FindByType.Number}
                            onChange={this.selectFindByOption} disabled={isDisabled} />
                        <span>By Number</span>
                    </label>
                </div>
            </div>                               
        );
    }

    // ----------------
    // HELPERS

    private formatOption = (option: ProductlistName): string => {
        if (this.state.findBy === FindByType.Number) {
            return `${option.number}   -   ${option.name}`;
        }
        else {
            return `${option.name}   -   ${option.number}`;
        }
    }

    private handleChange = (selected: ProductlistName[]): void => {
        this.setState({
            options: selected.length > 0 ? this.state.options : [],
            findBy: selected.length > 0 ? this.state.findBy : FindByType.Name,
            selected: selected
        });
        this.props.onChange(selected.length > 0 ? selected[0] : null);
    }

    private handleInputChange = (input: string, e: Event): void => {
        if (this.state.openMenu === false) {
            this.setState({
                openMenu: undefined
            });
        }
    }

    private handleSearch = (query: string): void => {
        if (this.props.clientState.client) {
            this.lookupProductlistName({
                clientCode: this.props.clientState.client.code,
                keyword: query,
                findBy: this.state.findBy,
                editableListsOnly: this.props.editableListsOnly
            } as ProductlistNameQuery);
        }
    }

    private lookupProductlistName = (query: ProductlistNameQuery): void => {
        this.props.actions.clearMessage();
        this.setState({
            isLoading: true
        });
        let fetchError: boolean = false;
        let action: string = 'productlists/getproductlistname';
        let url: string = `${action}`;

        fetch(url,
            {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify(query)
            })
            .then(response => {
                if (response.status != 200) {
                    ErrorMessage.getFromResponse(response, action).then(
                        (errorMessage => {
                            this.props.actions.broadcastMessage(errorMessage);
                        })
                    );
                    fetchError = true;
                    throw new Error();
                }
                return response.json();
            })
            .then(data => {
                let result: ProductlistName[] = data as ProductlistName[];
                this.setState({
                    isLoading: false,
                    options: result
                });
            },
            err => {
                this.setState({
                    isLoading: false
                });
                if (!fetchError) {
                    this.props.actions.broadcastMessage(ErrorMessage.getFromError(err, action));
                }
            }
        )
    }

    private renderOption = (option: TypeaheadResult<ProductlistName>, props: TypeaheadMenuProps<ProductlistName>, index: number): React.ReactNode | undefined => {
        return (
            <React.Fragment>
                <div className={"productlist-option" + (index % 2 === 1 ? " odd" : "")}>
                    <div className="list-id">
                        <div className="list-number">
                            {this.state.findBy === FindByType.Number && (
                                <Highlighter search={props.text || ''}>
                                    {option.number}
                                </Highlighter>
                            )}
                            {this.state.findBy == FindByType.Name && (
                                <span>{option.number}</span>
                            )}
                        </div>
                        <div className="list-name">
                            {this.state.findBy === FindByType.Name && (
                                <Highlighter search={props.text || ''}>
                                    {option.name}
                                </Highlighter>
                            )}
                            {this.state.findBy === FindByType.Number && (
                                <span>{option.name}</span>
                            )}
                        </div>
                    </div>
                    <div className="list-details">
                        <label className="list-type">{ProductlistType[option.listType]}</label>
                        <label className="customer-name">{option.customerName || 'No customer assigned'}</label>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private selectFindByOption = (event: React.ChangeEvent): void => {
        let optionValue: string | null = event.target.getAttribute("value");
        if (optionValue) {
            let findBy: number = parseInt(optionValue);
            this.setState({
                findBy: findBy,
                options: [],
                selected: []
            });
            this.finderElement.current.clear();
            this.props.onChange(null);
        }
    }

    private setFocus = (): void => {
        if (this.finderElement.current) {
            this.finderElement.current.focus();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators
        ), dispatch)
    };
}

export default connect<{}, {}, ProductlistFinderOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ProductlistFinder as any);
