import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ColorDirectionsStore from '../../store/ColorDirections';
import * as CustomersStore from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import { ProductDetail } from '../../store/Products';
import { ClientCode } from '../../enums/ClientCode';
import { User } from '../../store/User';
import { Role } from '../../enums/Role';
import { EmbroideryTapeType } from '../../enums/EmbroideryTapeType';
import { CustomerName } from '../../common/AccountTypes';
import { ColorDirection, EmbroiderySpecification, Logo, NewLogo, PlacementOption } from '../../common/EmbroideryTypes';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Query, QueryResult } from 'material-table';
import { Button, Col, Container, Row } from 'reactstrap';
import Loader from '../loader/Loader';
import ImageLoader from '../image-loader/ImageLoader';
import TapeViewer from '../tape-viewer/TapeViewer';
import AddLogo from '../add-logo/AddLogo';
import { formatNumberAsCurrencyForCountry } from '../../common/CurrencyFormatter';
import cloneDeep from 'lodash/cloneDeep';
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash.debounce';

import './ProductEmbroidery.scss';

// ----------------
// PROPS

const applicationState = {
    colorDirectionsState: {} as ColorDirectionsStore.ColorDirectionsState,
    customersState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, ColorDirectionsStore.actionCreators, MessageStore.actionCreators)
};

interface ProductEmbroideryAsyncActions {
    asyncActions: {
        requestCustomerLogosAsync: (clientCode: ClientCode, billto: string, shipto: string, query: Query<any>, broadcastError: boolean) => Promise<QueryResult<any>>;
    }
}

interface ProductEmbroideryOwnProps {
    clientCode: ClientCode;
    customer: CustomersStore.CustomerAccount | null | undefined;
    productDetail: ProductDetail;
    embroiderySpecification: EmbroiderySpecification;
    onLoadCustomer: (customer: CustomerName) => void;
    onLoadCustomerLogos: (success: boolean) => void;
    onLoadingCustomerLogos: () => void;
    onSelectCustomer: () => void;
    onSelectLogo: (logo: Logo | null) => void;
    onSelectPlacement: (placementOption: PlacementOption | null) => void;
    onSelectColorDirection: (colorDirection: ColorDirection | null) => void;
    onAddEmbroideryNotes: (text: string | null) => void;
    validationRequested: boolean;
    user: User;
}

type ProductEmbroideryProps =
    ProductEmbroideryOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & ProductEmbroideryAsyncActions;

// ----------------
// LOCAL STATE

interface ProductEmbroideryState {
    isInitialized: boolean;
    isLoading: boolean;
    isLoadError: boolean;
    isViewingLogo: boolean;
    isAddingNewLogo: boolean;
    hasSelectedLogo: boolean;
    loadError: string | null;
    logoType: EmbroideryTapeType;
    newLogoAdded: NewLogo | null | undefined;
    noCustomerLogos: boolean;
    customerLogos: Logo[] | undefined;
    customerLogoQuery: Query<Logo> | undefined;
    placementOptions: FieldWrapper.OptionValue[];
    placementDropdownFlag: boolean;
    colorDirectionOptions: FieldWrapper.OptionValue[];
    colorDirectionDropdownFlag: boolean;
    viewingLogo: Logo | null | undefined;    
}

// ----------------
// FORM VALIDATOR

const validateProductEmbroideryForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.placement) {
        errors.placement = 'Required';
    }
    else if (formValues.placementRequiresNotes && !formValues.embroideryNotes) {
        errors.embroideryNotes = 'Required';
    }
    if (!formValues.colorDirection) {
        errors.colorDirection = 'Required';
    }
    else if (formValues.colorDirectionRequiresNotes && !formValues.embroideryNotes) {
        errors.embroideryNotes = 'Required';
    }
    return errors;
}

class ProductEmbroidery extends React.PureComponent<ProductEmbroideryProps, ProductEmbroideryState> {

    // ----------------
    // VARIABLES

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', 'overflow': 'visible' }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR
    constructor(props: ProductEmbroideryProps, state: ProductEmbroideryState) {
        super(props);
        this.state = {
            isInitialized: this.props.embroiderySpecification.logo ? true : false,
            isLoading: (this.userIsSingleAccountBuyer() && !this.props.customer) ? true : false,
            isLoadError: false,
            isViewingLogo: false,
            isAddingNewLogo: false,
            hasSelectedLogo: false,
            loadError: null,
            logoType: EmbroideryTapeType.Existing,
            newLogoAdded: undefined,
            noCustomerLogos: false,
            customerLogos: undefined,
            customerLogoQuery: undefined,
            placementOptions: this.getPlacementOptions(),
            placementDropdownFlag: false,
            colorDirectionOptions: this.getColorDirectionOptions(),
            colorDirectionDropdownFlag: false,
            viewingLogo: undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
        this.ensureDataFetched();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        if (this.props.customer) {
            if (!this.state.isInitialized && !this.state.customerLogos) {
                this.getCustomerLogos(undefined);
            }
            else {
                this.props.onLoadCustomerLogos(true);
            }
        }
    }

    public componentDidUpdate = (prevProps: ProductEmbroideryProps) => {
        if (this.props.customer) {
            if (!prevProps.customer) {
                if (!this.state.isInitialized && !this.state.customerLogos) {
                    this.getCustomerLogos(undefined);
                }
                else {
                    this.props.onLoadCustomerLogos(true);
                }
            }
            else if (this.props.customer.billto !== prevProps.customer.billto) {
                if (!this.state.isInitialized) {
                    this.setState({
                        customerLogos: undefined
                    });
                    this.getCustomerLogos(this.getDefaultCustomerLogoQuery());
                }
            }
        }
        if (this.props.colorDirectionsState.colorDirections && this.state.colorDirectionOptions.length === 0) {
            this.setState({
                colorDirectionOptions: this.getColorDirectionOptions()
            });
        }
        if (!this.props.embroiderySpecification.logo && prevProps.embroiderySpecification.logo) {        
            this.setState({
                hasSelectedLogo: false
            });
            this.props.reset();
            this.getCustomerLogos(this.getDefaultCustomerLogoQuery());
        }
        if (this.props.validationRequested && !prevProps.validationRequested) {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        }
        this.setFormValues();
    }

    public componentWillUnmount = () => {
    }

    public handleFormSubmit = (values: any): void => {
    }

    public render = () => {
        return (
            <Container className="product-embroidery">
                <Row>
                    <Col className="pl-0 pr-0 customer-selection collapsible-row">
                        <motion.div animate={this.props.customer || this.state.isLoading ? "hidden" : "visible"}
                            initial={this.props.customer || this.state.isLoading ? "hidden" : "visible"}
                            variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                            <Button color="link" onClick={this.onSelectCustomer}>Select customer for options</Button>
                        </motion.div>
                    </Col>
                </Row>
                <Row>
                    <Col className="pl-0 pr-0 embroidery-selection collapsible-row">
                        <Container className={"loader-wrapper" + (this.state.isLoading ? " visible" : " hidden")}>
                            <Row>
                                <Col className="pl-0 pr-0">
                                    <Loader isLoading={this.state.isLoading} isChild={true} />
                                </Col>
                            </Row>
                        </Container>
                        <motion.div animate={this.props.customer ? "visible" : "hidden"}
                            initial={this.props.customer ? "visible" : "hidden"}
                            variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                            <Container>
                                <Row>
                                    <Col className={"pl-0 pr-0 logos" + (this.state.loadError ? " error" : "") + (this.state.noCustomerLogos ? " no-logos" : "") + (this.state.hasSelectedLogo ? " selected-logo" : "")} xs={12}>
                                        {this.state.customerLogos && (
                                            <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                                <div className="logo-title">
                                                    <div className="logo-customer-name">{this.getCustomerName('Customer')} {this.state.hasSelectedLogo ? 'Logo' : 'Logos'}</div>
                                                    <div className="separator">|</div>
                                                    <div className="logo-add">
                                                        <Button color="link" onClick={this.onAddNewLogo}>Add New</Button>
                                                    </div>
                                                </div>
                                                <div className="logo-container">
                                                    <div className="logo-tape-selector">
                                                        <React.Fragment>
                                                            <div className="logo-list">
                                                                {this.state.customerLogos.map((logo: Logo, logoIndex: number) => (
                                                                    <div className="logo" key={"logo-" + logoIndex}>
                                                                        <a className={"logo-image" + (this.isSelectedLogo(logo) ? " selected" : "")} onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.onSelectTape(e, logo)}>
                                                                            <div className={"logo-image-stretcher" + (logo.tapeType === EmbroideryTapeType.New ? " new" : "")}>
                                                                                <div className="logo-image-canvas">
                                                                                    <ImageLoader url={logo.logoUrl} thumbnail={true} isLoading={this.state.isLoading} autoSize={true} />
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        <div className="logo-tag">
                                                                            <Button color="link" title={"View Detail"} onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.onViewLogo(e, logo)}>Tape # {logo.tapeNumber}</Button>
                                                                            {logo.tapeType === EmbroideryTapeType.New && (
                                                                                <a className="new-logo" onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.onViewLogo(e, logo)}>Pending</a>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="logo-list-pager">
                                                                {this.state.customerLogoQuery && this.state.customerLogoQuery.totalCount > 4 && !this.state.hasSelectedLogo && (
                                                                    <div className="logo-list-pager-wrapper">
                                                                        <Button color="link" onClick={this.getPreviousLogos} disabled={this.isFirstPage()}>
                                                                            <svg className="MuiSvgIcon-root" fill="currentColor" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                            </svg>
                                                                        </Button>
                                                                        <div className="pagination">
                                                                            <label>{this.getLogoPagination()}</label>
                                                                        </div>
                                                                        <Button color="link" onClick={this.getNextLogos} disabled={this.isLastPage()}>
                                                                            <svg className="MuiSvgIcon-root" fill="currentColor" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                                                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                            </svg>
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </React.Fragment>
                                                    </div>
                                                    <div className="logo-specifications">
                                                        <Field name="placement" label="" component={FieldWrapper.renderDropdownField}
                                                            defaultValue={this.getDefaultPlacement()}
                                                            open={this.state.placementDropdownFlag} disabled={false}
                                                            options={this.state.placementOptions}
                                                            onSelectOption={this.onSelectPlacement}
                                                            onToggle={this.onTogglePlacement} />
                                                        <Field name="colorDirection" label="" component={FieldWrapper.renderDropdownField}
                                                            defaultValue={this.getDefaultColorDirection()}
                                                            open={this.state.colorDirectionDropdownFlag} disabled={this.props.colorDirectionsState.colorDirections ? false : true}
                                                            options={this.state.colorDirectionOptions}
                                                            onSelectOption={this.onSelectColorDirection}
                                                            onToggle={this.onToggleColorDirection} />
                                                        {!this.props.colorDirectionsState.colorDirections && (
                                                            <div className="logo-load-error">
                                                                <div className="logo-load-error-message">
                                                                    <div className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path strokeWidth="1" d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                                                            <line x1="12" y1="9" x2="12" y2="13"></line>
                                                                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                                                        </svg>
                                                                    </div>
                                                                    <div className="text">Failed to load color directions</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <Field name="embroideryNotes" component={FieldWrapper.renderTextareaField}
                                                            placeholder={"Notes..."} disabled={false} rows={2} maxLength={254}
                                                            onChange={this.onAddEmbroideryNotes} />
                                                        <Field name="tapeNumber" component="input" type="hidden" />
                                                        <Field name="placementRequiresNotes" component="input" type="hidden" />
                                                        <Field name="colorDirectionRequiresNotes" component="input" type="hidden" />
                                                    </div>
                                                </div>
                                                <button type="submit" ref={this.submitButton}>Submit</button>
                                            </form>                                            
                                        )}
                                        {this.state.noCustomerLogos && (
                                            <div className="logo-undefined-error">
                                                <div className="logo-undefined-error-message">
                                                    No logos on file for {this.getCustomerName('customer')}
                                                </div>
                                                <div className="logo-undefined-actions">
                                                    <Button color="link" onClick={this.onAddNewLogo}>Add New Logo</Button>
                                                </div>
                                            </div>
                                        )}
                                        {this.state.isLoadError && (
                                            <div className="logo-load-error">
                                                <div className="logo-load-error-message">
                                                    <div className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                            <path strokeWidth="1" d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                                            <line x1="12" y1="9" x2="12" y2="13"></line>
                                                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                                        </svg>
                                                    </div>
                                                    <div className="text">{this.state.loadError}</div>
                                                </div>
                                                <div className="logo-load-error-actions">
                                                    <Button color="secondary" onClick={this.onReload}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                                            <polyline points="1 4 1 10 7 10"></polyline>
                                                            <polyline points="23 20 23 14 17 14"></polyline>
                                                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                                        </svg>
                                                        Reload
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                        </motion.div>
                    </Col>
                </Row>
                {this.state.viewingLogo && (
                    <TapeViewer isOpen={this.state.isViewingLogo} onDismiss={this.hideLogoViewer} tape={this.state.viewingLogo} />
                )}
                {this.props.customer && (
                    <AddLogo isOpen={this.state.isAddingNewLogo} clientCode={this.props.clientCode} customer={this.props.customer} onDismiss={this.hideAddNewLogo} />
                )}
            </Container>
        );
    }

    // ----------------
    // HELPERS

    private colorDirectionsInStore = (clientCode: ClientCode): boolean => {
        let inStore: boolean = false;
        if (this.props.colorDirectionsState.colorDirections) {
            inStore = (this.props.colorDirectionsState.clientCode === clientCode);
        }
        return inStore;
    }

    private ensureDataFetched = (): void => {
        if (!this.colorDirectionsInStore(this.props.clientCode)) {
            this.props.actions.requestColorDirections(this.props.clientCode);
        }
        if (this.state.isLoading) {
            this.props.onLoadCustomer(this.props.user.buyerAccounts[0]);
        }
    }

    private getColorDirectionOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        if (this.props.colorDirectionsState.colorDirections) {
            for (let n: number = 0; n < this.props.colorDirectionsState.colorDirections.length; n++) {
                let colorDirection: ColorDirection = this.props.colorDirectionsState.colorDirections[n];
                options.push({ label: colorDirection.titleCaseDescription, value: colorDirection.code });
            }
        }
        return options;
    }

    private getCustomerLogos = (query: Query<Logo> | undefined): void => {
        if (this.props.customer) {
            this.props.onLoadingCustomerLogos();
            this.setState({
                isLoading: true,
                isLoadError: false,
                loadError: null,
                noCustomerLogos: false
            });
            let q: Query<Logo> = query || this.state.customerLogoQuery || this.getDefaultCustomerLogoQuery();
            this.props.asyncActions.requestCustomerLogosAsync(this.props.clientCode, this.props.customer.billto, this.props.customer.shipToLocations[0].shipto || '', q, false)
                .then(result => {
                    if (result.totalCount > 0) {
                        q.totalCount = result.totalCount;
                        this.setState({
                            isLoading: false,
                            noCustomerLogos: false,
                            customerLogos: result.data,
                            customerLogoQuery: q                           
                        });
                    }
                    else {
                        this.setState({
                            isLoading: false,
                            noCustomerLogos: true,
                            customerLogos: undefined
                        })
                    }
                    this.props.onLoadCustomerLogos(true);
                },
                err => {
                    this.setState({
                        isLoading: false,
                        isLoadError: true,
                        loadError: err,
                        customerLogos: undefined
                    })
                    this.props.onLoadCustomerLogos(false);
                });
        }
    }

    private getCustomerName = (defaultName: string): string => {
        return this.props.customer ? this.props.customer.nameOnly : defaultName;
    }

    private getDefaultColorDirection = (): string => {
        let defaultValue: string = "Select Color Direction...";
        if (this.props.embroiderySpecification.colorDirection) {
            defaultValue = this.props.embroiderySpecification.colorDirection.description;
        }
        return defaultValue;
    }

    private getDefaultCustomerLogoQuery = (): Query<Logo> => {
        return {
            filters: [],
            page: 0,
            pageSize: 4,
            totalCount: 0,
            search: "",
            orderBy: {},
            orderDirection: "asc"
        }
    }

    private getDefaultPlacement = (): string => {
        let defaultValue = "Select Placement...";
        if (this.props.embroiderySpecification.placement) {
            defaultValue = this.props.embroiderySpecification.placement.description;
        }
        return defaultValue;
    }

    private getEmbroideryFee = (logo: Logo): string => {
        return logo.price > 0 ? formatNumberAsCurrencyForCountry('US', logo.price, true) : 'no fee';
    }

    private getNextLogos = (): void => {
        if (this.state.customerLogoQuery) {
            let pageSize: number = this.state.customerLogoQuery.pageSize;
            let pageCount: number = Math.ceil(this.state.customerLogoQuery.totalCount / pageSize);
            let nextPage: number = this.state.customerLogoQuery.page + 1;
            if (nextPage < pageCount) {
                let query: Query<Logo> = cloneDeep(this.state.customerLogoQuery);
                query.page = nextPage;
                this.getCustomerLogos(query);
            }
            this.props.onSelectLogo(null);
        }
    }

    private getPreviousLogos = (): void => {
        if (this.state.customerLogoQuery) {
            let pageSize: number = this.state.customerLogoQuery.pageSize;
            let pageCount: number = Math.ceil(this.state.customerLogoQuery.totalCount / pageSize);
            let prevPage: number = this.state.customerLogoQuery.page - 1;
            if (prevPage >= 0) {
                let query: Query<Logo> = cloneDeep(this.state.customerLogoQuery);
                query.page = prevPage;
                this.getCustomerLogos(query);
            }
        }
        this.props.onSelectLogo(null);
    }

    private getLogoPagination = (): string => {
        let pagination: string = "";
        if (this.state.customerLogoQuery && this.state.customerLogos) {
            let pageSize: number = this.state.customerLogoQuery.pageSize;
            let pageCount: number = Math.ceil(this.state.customerLogoQuery.totalCount / pageSize);
            let currentPage = this.state.customerLogoQuery.page;
            let start: number = (currentPage * pageSize) + 1;
            let end: number = (currentPage * pageSize) + this.state.customerLogos.length;
            pagination = start + "-" + end + " of " + this.state.customerLogoQuery.totalCount;
        }
        return pagination;
    }

    private getPlacementOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        for (let n: number = 0; n < this.props.productDetail.placementOptions.length; n++) {
            let placementOption: PlacementOption = this.props.productDetail.placementOptions[n];
            options.push({ label: placementOption.description, value: placementOption.code });
        }
        return options;
    }

    private hideAddNewLogo = (newLogo: NewLogo | null | undefined): void => {
        this.setState({
            isAddingNewLogo: false
        });
        if (newLogo) {
            let selectedLogo: Logo = {
                customerNumber: newLogo.customerNumber,
                description: newLogo.description,
                dimensions: newLogo.dimensions,
                height: newLogo.height,
                logoFileName: newLogo.previewFileName,
                logoUrl: newLogo.previewUrl,
                price: newLogo.price,
                selected: true,
                stitchCount: newLogo.stitchCount,
                tapeNumber: newLogo.tapeNumber,
                tapeType: EmbroideryTapeType.New,
                width: newLogo.width
            };
            setTimeout(() => {
                this.setState({
                    customerLogos: [selectedLogo],
                    noCustomerLogos: false,
                    hasSelectedLogo: true
                });
                this.props.onSelectLogo(selectedLogo);
            }, 400);
        }
    }

    private hideLogoViewer = (selected: boolean): void => {
        if (selected && this.state.viewingLogo) {
            this.props.onSelectLogo(this.state.viewingLogo);
        }
        this.setState({
            isViewingLogo: false,
            viewingLogo: undefined
        });
    }

    private isFirstPage = (): boolean => {
        let firstPage: boolean = false;
        if (this.state.customerLogoQuery) {
            firstPage = this.state.customerLogoQuery.page === 0 ? true : false;
        }
        return firstPage;
    }

    private isLastPage = (): boolean => {
        let lastPage: boolean = false;
        if (this.state.customerLogoQuery) {
            let pageSize: number = this.state.customerLogoQuery.pageSize;
            let pageCount: number = Math.ceil(this.state.customerLogoQuery.totalCount / pageSize);
            lastPage = this.state.customerLogoQuery.page === (pageCount - 1) ? true : false;
        }
        return lastPage;
    }

    private isNewSpecification = (): boolean => {
        return (this.props.customer && !this.props.embroiderySpecification.logo) ? true : true;
    }

    private isSelectedLogo = (logo: Logo): boolean => {
        let selected: boolean = false;
        if (this.props.embroiderySpecification.logo && !this.state.isLoading) {
            selected = logo.tapeNumber === this.props.embroiderySpecification.logo.tapeNumber;
        }
        return selected;
    }

    private onAddEmbroideryNotes = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        this.props.onAddEmbroideryNotes(event.target.value);
    }

    private onAddNewLogo = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        this.props.actions.clearMessage();
        this.setState({
            isAddingNewLogo: true
        });
    }

    private onReload = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        this.getCustomerLogos(undefined);
    }

    private onSelectColorDirection = (selectedOption: FieldWrapper.OptionValue): void => {
        if (this.props.colorDirectionsState.colorDirections) {
            let colorDirection: ColorDirection[] = this.props.colorDirectionsState.colorDirections.filter((cd: ColorDirection) => {
                return cd.code === selectedOption.value;
            });
            this.props.change('colorDirectionRequiresNotes', this.requiresNotes(colorDirection[0].description));
            this.props.onSelectColorDirection(colorDirection[0]);
        }
    }

    private onSelectCustomer = (event: React.KeyboardEvent | React.MouseEvent): void => {
        this.props.onSelectCustomer();
    }

    private onSelectPlacement = (selectedOption: FieldWrapper.OptionValue): void => {
        let placementOption: PlacementOption[] = this.props.productDetail.placementOptions.filter((po: PlacementOption) => {
            return po.code === selectedOption.value;
        })
        this.props.change('placementRequiresNotes', this.requiresNotes(placementOption[0].description));
        this.props.onSelectPlacement(placementOption[0]);
    }

    private onSelectTape = (event: React.KeyboardEvent | React.MouseEvent, logo: Logo): void => {
        event.preventDefault();
        if (this.state.customerLogos) {
            let selectedIndex: number = this.state.customerLogos.findIndex(function (l: Logo) {
                return l.tapeNumber === logo.tapeNumber;
            });
            if (!this.state.hasSelectedLogo) {
                this.setState({
                    customerLogos: this.state.customerLogos.slice(selectedIndex, selectedIndex + 1),
                    hasSelectedLogo: true
                })
            }
            else {
                this.setState({
                    isViewingLogo: true,
                    viewingLogo: logo
                });
            }
        }
        this.props.onSelectLogo(logo);
    }

    private onToggleColorDirection = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        let colorDirectionDropdownFlag: boolean = !this.state.colorDirectionDropdownFlag;
        if (colorDirectionDropdownFlag) {
            this.props.change('colorDirection', 'Select Color Direction...');
            this.props.onSelectColorDirection(null);
        }
        this.setState({
            colorDirectionDropdownFlag: colorDirectionDropdownFlag
        });
    }

    private onTogglePlacement = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        let placementDropdownFlag: boolean = !this.state.placementDropdownFlag;
        if (placementDropdownFlag) {
            this.props.change('placement', 'Select Placement...');
            this.props.onSelectPlacement(null);
        }
        this.setState({
            placementDropdownFlag: placementDropdownFlag
        });
    }

    private onViewLogo = (event: React.KeyboardEvent | React.MouseEvent, logo: Logo): void => {
        event.preventDefault();
        this.setState({
            isViewingLogo: true,
            viewingLogo: logo
        });
    }

    private requiresNotes = (description: string): boolean => {
        return (description.toLowerCase().indexOf('notes') > 0 ||
            description.toLowerCase().indexOf('instructions') > 0);
    }

    private setFormValues = (): void => {
        let logo: Logo | null = this.props.embroiderySpecification.logo;
        this.props.change('tapeNumber', logo ? logo.tapeNumber : undefined);

        let placementOption: PlacementOption | null = this.props.embroiderySpecification.placement;
        this.props.change('placement', placementOption ? placementOption.description : undefined);
        this.props.change('placementRequiresNotes', placementOption && this.requiresNotes(placementOption.description) ? true : false);

        let colorDirectionOption: ColorDirection | null = this.props.embroiderySpecification.colorDirection;
        this.props.change('colorDirection', colorDirectionOption ? colorDirectionOption.titleCaseDescription : undefined);
        this.props.change('colorDirectionRequiresNotes', colorDirectionOption && this.requiresNotes(colorDirectionOption.description) ? true : false);

        this.props.change('embroideryNotes', this.props.embroiderySpecification.notes);
    }

    private userIsBuyer = (): boolean => {
        let isBuyer: boolean = this.props.user.role === Role.Buyer ? true : false;
        return isBuyer;
    }

    private userIsMultipleAccountBuyer = (): boolean => {
        let isMultipleAccountBuyer: boolean = this.userIsBuyer();
        if (isMultipleAccountBuyer) {
            isMultipleAccountBuyer = this.props.user.buyerAccounts.length > 1;
        }
        return isMultipleAccountBuyer;
    }

    private userIsSingleAccountBuyer = (): boolean => {
        let isSingleAccountBuyer: boolean = this.userIsBuyer();
        if (isSingleAccountBuyer) {
            isSingleAccountBuyer = this.props.user.buyerAccounts.length === 1;
        }
        return isSingleAccountBuyer;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        colorDirectionsState: state.colorDirections,
        customersState: state.customers,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            ColorDirectionsStore.actionCreators,
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestCustomerLogosAsync: (clientCode: ClientCode, billto: string, shipto: string, query: Query<any>, broadcastError: boolean) => dispatch(CustomersStore.actionCreators.requestCustomerLogos(clientCode, billto, shipto, query, broadcastError))
        }
    };
}

export default connect<{}, {}, ProductEmbroideryOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: `productEmbroideryForm_${uniqueId()}`,
    validate: validateProductEmbroideryForm,
    enableReinitialize: true
})(ProductEmbroidery as any));
