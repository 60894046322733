import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Route, Redirect } from 'react-router';
import { Switch } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as ClientStore from '../store/Client';
import * as MessageStore from '../store/Message';
import * as UserStore from '../store/User';
import * as CSSVariables from '../common/CSSVariables';
import Layout from '../components/layout/Layout';
import AddUser from '../components/add-user/AddUser';
import AdminUsers from '../components/admin-users/AdminUsers';
import AdminExceptions from '../components/admin-exceptions/AdminExceptions';
import Counter from '../components/counter/Counter';
import Customers from '../components/customers/Customers';
import Dashboard from '../components/dashboard/Dashboard';
import EditCustomer from '../components/edit-customer/EditCustomer';
import EditOrder from '../components/edit-order/EditOrder';
import EditProductlist from '../components/edit-productlist/EditProductlist';
import EditUser from '../components/edit-user/EditUser';
import Error from '../components/error/Error';
import FetchData from '../components/fetch-data/FetchData';
import Home from '../components/home/Home';
import Login from '../components/login/Login';
import Logout from '../components/logout/Logout';
import Orders from '../components/orders/Orders';
import OrderUpdater from '../components/order-updater/OrderUpdater';
import PrivateRoute from '../components/private-route/PrivateRoute';
import Products from '../components/products/Products';
import ProductDetail from '../components/product-detail/ProductDetail';
import Productlists from '../components/productlists/Productlists';
import Profile from '../components/profile/Profile';

import '../custom.scss'
import './App.scss'

// ----------------
// PROPS
// At runtime, Redux will merge together...

const state = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    userState: {} as UserStore.UserState
};

const actionCreators = {
    actions: Object.assign({},
        ClientStore.actionCreators,
        MessageStore.actionCreators,
        UserStore.actionCreators)
};

type AppProps =
    typeof state                // ... state we've requested from the Redux store    
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface AppState {
    isAuthenticated: boolean;
    isUpdated: boolean;
}

class App extends React.PureComponent<AppProps, AppState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: AppProps) {
        super(props);
        this.state = {
            isAuthenticated: this.props.userState.user ? true : false,
            isUpdated: false
        };
    }

    // ----------------
    // METHODS

    // Called when component is first added to the document.
    public componentDidMount = () => {
        this.ensureDataFetched();        
    }

    public componentDidUpdate = () => {
        if (this.props.clientState.client) {
            this.setBrandTheme();
            this.setBrandFavicon();
            this.setBrandTitle();

            if (!this.props.userState.user) {
                this.setState({
                    isAuthenticated: false,
                    isUpdated: false
                });
                setTimeout(() => {
                    if (this.props.clientState.client) {
                        this.props.actions.requestUser(this.props.clientState.client.code);
                    }
                });
            }
            else {
                this.setState({
                    isAuthenticated: this.props.userState.user.isAuthenticated
                });
                this.updateUserLastLogin();
            }
        }
        else if (this.isAbended()) {
            this.setBrandFavicon();
            this.setBrandTitle();
        }
    }

    public componentWillUnmount = () => {
    }

    public isAbended = (): boolean => {
        let hasErrorMessage: boolean = false;
        if (this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                hasErrorMessage = true;
            }
        }
        return hasErrorMessage;
    }

    public render = () => {
        return (
            <Layout>
                <Switch>
                <Route exact path='/'>
                    <Redirect to='/dashboard' />                    
                </Route>                
                <Route path='/error' component={Error} />
                <Route path='/login' component={Login} />
                <Route path='/logout' component={Logout} />
                <PrivateRoute path="/admin/users/add" component={EditUser} isAuthenticated={this.state.isAuthenticated} />
                <PrivateRoute path="/admin/users/edit/:id" component={EditUser} isAuthenticated={this.state.isAuthenticated} />
                <PrivateRoute path="/admin/users" component={AdminUsers} isAuthenticated={this.state.isAuthenticated} />
                <PrivateRoute path="/admin/exceptions/:tab" component={AdminExceptions} isAuthenticated={this.state.isAuthenticated} />
                <PrivateRoute path="/profile" component={Profile} isAuthenticated={this.state.isAuthenticated} />
                <PrivateRoute path="/customers/edit/:id/:tab" component={EditCustomer} isAuthenticated={this.state.isAuthenticated} />
                <PrivateRoute path="/customers" component={Customers} isAuthenticated={this.state.isAuthenticated} />
                <PrivateRoute path="/dashboard" component={Dashboard} isAuthenticated={this.state.isAuthenticated} />
                <PrivateRoute path="/orders/edit/:id/:tab" component={EditOrder} isAuthenticated={this.state.isAuthenticated} />
                <PrivateRoute path="/orders" component={Orders} isAuthenticated={this.state.isAuthenticated} />
                <PrivateRoute path="/products/detail/:id" component={ProductDetail} isAuthenticated={this.state.isAuthenticated} />
                <PrivateRoute path="/products" component={Products} isAuthenticated={this.state.isAuthenticated} />
                    <PrivateRoute path="/productlists/edit/:id" component={EditProductlist} isAuthenticated={this.state.isAuthenticated} />
                <PrivateRoute path="/productlists" component={Productlists} isAuthenticated={this.state.isAuthenticated} />
                {/*<Route path='/counter' component={Counter} />*/}
                {/*<Route path='/fetch-data/:startDateIndex?' component={FetchData} />*/}
                <Route exact path="*">
                    <Redirect to='/dashboard' />
                    </Route>
                </Switch>
                <OrderUpdater />
            </Layout>
        );
    }

    // ----------------
    // HELPERS

    private newShade = (hexColor: string, magnitude: number) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            let decimalColor: number = parseInt(hexColor, 16);
            let r: number = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g: number = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b: number = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };

    private ensureDataFetched = (): void => {
        const hostName = window.location.hostname;
        this.props.actions.requestClient(hostName);
    }

    private setBrandFavicon = (): void => {
        let favicon: Element | null = document.querySelector("link[rel~='icon']");
        if (favicon) {
            let href: string = favicon.getAttribute('href') || '/';
            let suffix: string = this.props.clientState.client? this.props.clientState.client.code.toString() : '0';
            href = href.replace('favicon.ico', 'favicons/favicon' + suffix + '.ico');
            favicon.setAttribute('href', href);
        }
    }

    private setBrandTheme = (): void => {
        if (this.props.clientState.client) {
            let theme: ClientStore.ClientTheme = this.props.clientState.client.theme;
            let root: HTMLElement = document.documentElement;
            let styles: CSSStyleDeclaration = getComputedStyle(root);

            if (theme.brandPrimaryColor) {
                root.style.setProperty(CSSVariables.BRAND_PRIMARY, theme.brandPrimaryColor);
            }
            if (theme.brandSecondaryColor) {
                root.style.setProperty(CSSVariables.BRAND_SECONDARY, theme.brandSecondaryColor);
                root.style.setProperty(CSSVariables.BRAND_SECONDARY_LIGHT, this.newShade(theme.brandSecondaryColor, 80));
            }
            if (theme.brandTertiaryColor) {
                root.style.setProperty(CSSVariables.BRAND_TERTIARY, theme.brandTertiaryColor);
            }
            if (theme.brandInverseColor) {
                root.style.setProperty(CSSVariables.BRAND_INVERSE, theme.brandInverseColor);
            }
            if (theme.brandLogoHeight) {
                root.style.setProperty(CSSVariables.BRAND_LOGO_HEIGHT, theme.brandLogoHeight + 'px');
            }
            if (theme.brandLogoMarginTop) {
                root.style.setProperty(CSSVariables.BRAND_LOGO_MARGIN_TOP, theme.brandLogoMarginTop + 'px');
            }
            if (theme.brandTitleHeight) {
                root.style.setProperty(CSSVariables.BRAND_TITLE_HEIGHT, theme.brandTitleHeight + 'px');
            }
            if (theme.brandTitleMarginTop) {
                root.style.setProperty(CSSVariables.BRAND_TITLE_MARGIN_TOP, theme.brandTitleMarginTop + 'px');
            }
            if (theme.brandLogoHeightSmall) {
                root.style.setProperty(CSSVariables.BRAND_LOGO_HEIGHT_SMALL, theme.brandLogoHeightSmall + 'px');
            }
            if (theme.brandLogoMarginTopSmall) {
                root.style.setProperty(CSSVariables.BRAND_LOGO_MARGIN_TOP_SMALL, theme.brandLogoMarginTopSmall + 'px');
            }
            if (theme.brandTitleHeightSmall) {
                root.style.setProperty(CSSVariables.BRAND_TITLE_HEIGHT_SMALL, theme.brandTitleHeightSmall + 'px');
            }
            if (theme.brandTitleMarginTopSmall) {
                root.style.setProperty(CSSVariables.BRAND_TITLE_MARGIN_TOP_SMALL, theme.brandTitleMarginTopSmall + 'px');
            }
        }
    }

    private setBrandTitle = (): void => {
        let title: string = 'VRLink | Online Order Entry'
        if (this.props.clientState.client) {
            title = this.props.clientState.client.name + ' | ' + this.props.clientState.client.siteTitle;
        }
        document.title = title;
    }

    private updateUserLastLogin = (): void => {
        if (this.state.isAuthenticated && !this.state.isUpdated) {
            if (this.props.userState.user) {
                this.props.actions.updateUserLastLogin(this.props.userState.user);
                this.setState({
                    isUpdated: true
                });
            }
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        userState: state.user
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            ClientStore.actionCreators,
            MessageStore.actionCreators,
            UserStore.actionCreators),
            dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App as any);


