﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { Client } from './Client';
import * as MessageStore from './Message';
import { Product } from './Products';
import { ProductlistDetail } from './Productlists';
import { Color, Dimension, GarmentType, Gender, Season, Style } from '../common/ProductTypes';
import { EmbroiderySpecification, PlacementOption } from '../common/EmbroideryTypes';
import * as ErrorMessage from '../common/ErrorMessage';
import { ClientCode } from '../enums/ClientCode';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface NewProductlistItem {
    client: Client;
    customerNumber: string;
    product: Product;
    productlistId: number;
    dimension: Dimension;
    embroiderySpecifications: EmbroiderySpecification[];
}

export interface ProductlistItem {
    id: number | null | undefined;
    canEmbroider: boolean;
    clientCode: ClientCode;
    color: Color;
    dimension: Dimension;
    dimensionDescription: string;
    embroiderySpecifications: EmbroiderySpecification[];
    garmentType: GarmentType;
    gender: Gender;
    imageUrl: string;
    isEditable: boolean;
    placementOptions: PlacementOption[];
    productId: string;
    productlistId: number;
    sanitizedProductId: string;
    season: Season;
    shortDescription: string;
    sizeDescription: string;
    style: Style;
    userId: number;
    wholesale: number;
}

export interface ProductlistItemsState {
    isAdding: boolean;
    isDeleting: boolean;
    isLoading: boolean;    
    productlistId: number | null | undefined;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface AddProductlistItemAction {
    type: 'ADD_PRODUCTLIST_ITEM';
    clientCode: ClientCode;
    newProductlistItem: NewProductlistItem;
}

interface AddProductlistItemCompleteAction {
    type: 'ADD_PRODUCTLIST_ITEM_COMPLETE';
    clientCode: ClientCode;
    newProductlistItem: NewProductlistItem | null | undefined;
}

interface DeleteProductlistItemAction {
    type: 'DELETE_PRODUCTLIST_ITEM';
    clientCode: ClientCode;
    productlistItemToDelete: ProductlistItem
}

interface DeleteProductlistItemCompleteAction {
    type: 'DELETE_PRODUCTLIST_ITEM_COMPLETE';
    clientCode: ClientCode;
    productlistItemToDelete: ProductlistItem | null | undefined;
}

interface RequestProductlistItemsAction {
    type: 'REQUEST_PRODUCTLIST_ITEMS';
    clientCode: ClientCode;
    productlistDetail: ProductlistDetail;
}

interface ReceiveProductlistItemsAction {
    type: 'RECEIVE_PRODUCTLIST_ITEMS';
    clientCode: ClientCode;
    productlistItems: ProductlistItem[] | null | undefined;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = AddProductlistItemAction | AddProductlistItemCompleteAction |
    DeleteProductlistItemAction | DeleteProductlistItemCompleteAction |
    RequestProductlistItemsAction | ReceiveProductlistItemsAction |
    MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    addProductlistItem: (client: Client, newProductlistItem: NewProductlistItem): AppThunkAction<KnownAction> => (dispatch, getState): Promise<NewProductlistItem | null | undefined> => {
        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.productlistItems && !appState.productlistItems.isAdding) {

                let fetchError: boolean = false;
                let action: string = 'productlists/additem';
                let url: string = `${action}/${newProductlistItem.productlistId.toString()}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(newProductlistItem)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'ADD_PRODUCTLIST_ITEM_COMPLETE', clientCode: client.code, newProductlistItem: undefined });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let productlistItem: NewProductlistItem = data as NewProductlistItem;
                        resolve(productlistItem);
                        dispatch({ type: 'ADD_PRODUCTLIST_ITEM_COMPLETE', clientCode: client.code, newProductlistItem: productlistItem });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'ADD_PRODUCTLIST_ITEM_COMPLETE', clientCode: client.code, newProductlistItem: undefined });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    })

                dispatch({ type: 'ADD_PRODUCTLIST_ITEM', clientCode: client.code, newProductlistItem: newProductlistItem });
            }
            else {
                resolve(undefined);
            }
        });
    },
    deleteProductlistItem: (clientCode: ClientCode, productlistItemToDelete: ProductlistItem, broadcastError: boolean): AppThunkAction<KnownAction> => (dispatch, getState): Promise<ProductlistItem | null | undefined> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.productlistItems && !appState.productlistItems.isDeleting) {
                let fetchError: boolean = false;
                let action: string = 'productlists/removeItem';
                let url: string = `${action}/${productlistItemToDelete.productlistId}`;

                fetch(url,
                    {
                        method: 'DELETE',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(productlistItemToDelete)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'DELETE_PRODUCTLIST_ITEM_COMPLETE', clientCode: clientCode, productlistItemToDelete: null });
                                    if (broadcastError) {
                                        dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                    }
                                    reject(errorMessage.text);
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let productlistItem: ProductlistItem = data as ProductlistItem;
                        resolve(productlistItem);
                        dispatch({ type: 'DELETE_PRODUCTLIST_ITEM_COMPLETE', clientCode: clientCode, productlistItemToDelete: productlistItem });
                    },
                    err => {
                        if (!fetchError) {
                            dispatch({ type: 'DELETE_PRODUCTLIST_ITEM_COMPLETE', clientCode: clientCode, productlistItemToDelete: null });
                            if (broadcastError) {
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                            else {
                                reject(ErrorMessage.getFromError(err, action).text);
                            }
                        }
                    })

                dispatch({ type: 'DELETE_PRODUCTLIST_ITEM', clientCode: clientCode, productlistItemToDelete: productlistItemToDelete });
            }
            else {
                resolve(null);
            }
        });
    },
    requestProductlistItems: (client: Client, productlistDetail: ProductlistDetail, sortBy: string): AppThunkAction<KnownAction> => (dispatch, getState): Promise<ProductlistItem[] | null | undefined> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.productlistItems && !appState.productlistItems.isLoading) {

                productlistDetail.client = client;
                let fetchError: boolean = false;
                let action: string = 'productlists/getitems';
                let productlistId: number = productlistDetail.id as number;
                let url: string = `${action}/${productlistId}/${encodeURIComponent(sortBy)}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(productlistDetail)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_PRODUCTLIST_ITEMS', clientCode: client.code, productlistItems: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let productlistItems: ProductlistItem[] = data as ProductlistItem[];
                        resolve(productlistItems);
                        dispatch({ type: 'RECEIVE_PRODUCTLIST_ITEMS', clientCode: client.code, productlistItems: productlistItems });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'RECEIVE_PRODUCTLIST_ITEMS', clientCode: client.code, productlistItems: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

                dispatch({ type: 'REQUEST_PRODUCTLIST_ITEMS', clientCode: client.code, productlistDetail: productlistDetail });
            }
            else {
                resolve([]);
            }
        });
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState = {
    isAdding: false,
    isDeleting: false,
    isLoading: false,
    productlistId: undefined
};

export const reducer: Reducer<ProductlistItemsState> = (state: ProductlistItemsState | undefined, incomingAction: Action): ProductlistItemsState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PRODUCTLIST_ITEMS':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: true,
                productlistId: action.productlistDetail.id
            };
        case 'RECEIVE_PRODUCTLIST_ITEMS':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: false,
                productlistId: state.productlistId
            };
        case 'DELETE_PRODUCTLIST_ITEM':
            return {
                isAdding: state.isAdding,
                isDeleting: true,
                isLoading: state.isLoading,
                productlistId: state.productlistId
            };
        case 'DELETE_PRODUCTLIST_ITEM_COMPLETE':
            return {
                isAdding: state.isAdding,
                isDeleting: false,
                isLoading: state.isLoading,
                productlistId: state.productlistId
            };
        default:
            return state;
    }
}