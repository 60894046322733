import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as UsersStore from '../../store/Users';
import { User } from '../../store/User';
import { ClientCode } from '../../enums/ClientCode';
import * as ErrorMessage from '../../common/ErrorMessage';
import debounce from 'lodash.debounce';
import { Container, Col, Row, ListGroup, ListGroupItem } from 'reactstrap';

import './UsernameRules.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    usersState: {} as UsersStore.UsersState
}

const actionCreators = {
    actions: Object.assign({}, UsersStore.actionCreators)
}

interface UsernameRulesOwnProps {
    input: string | null;
    user: User | null | undefined;
    isValidUsername?: (isValid: boolean) => void
}

type UsernameRulesProps =
    UsernameRulesOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface UsernameRulesState {
    isValidLength: boolean;
    isUnique: boolean;    
    submitting: boolean;
    showErrors: boolean;
}

// ----------------
// LOCAL TYPES

interface UsernameQuery {
    clientCode: ClientCode;
    username: string;
    userId: number | null;
    exists: boolean;
}

class UsernameRules extends React.PureComponent<UsernameRulesProps, UsernameRulesState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: UsernameRulesProps, state: UsernameRulesState) {
        super(props);
        this.state = {
            isValidLength: false,
            isUnique: false,
            submitting: false,
            showErrors: false
        }
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.evaluateInput();
    }

    public componentDidUpdate = (prevProps: UsernameRulesProps) => {
        if (this.props.input != prevProps.input) {            
            this.evaluateInput();
        }
    }

    public render = () => {
        return (
            <div id="username-rules">
                <div className="username-rules-wrapper">
                    <div className="instructions">
                        <label>Username must be:</label>
                    </div>
                    <div className="ruleset">
                        <ListGroup>
                            <ListGroupItem>
                                <div className="icon">
                                    {!(this.state.isValidLength || this.state.showErrors) && (
                                        <span className="placeholder">&#45;</span>
                                    )}
                                    {this.state.isValidLength && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-check">
                                            <polyline points="20 6 9 17 4 12"></polyline>
                                        </svg>
                                    )}
                                    {this.state.showErrors && !this.state.isValidLength && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    )}
                                </div>
                                <label>at least 6 letters</label>
                            </ListGroupItem>
                            <ListGroupItem>
                                <div className="icon">
                                    {!(this.state.isUnique || this.state.showErrors) && (
                                        <span className="placeholder">&#45;</span>
                                    )}

                                    {this.state.isUnique && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-check">
                                            <polyline points="20 6 9 17 4 12"></polyline>
                                        </svg>
                                    )}
                                    {this.state.showErrors && !this.state.isUnique && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    )}
                                </div>
                                <label>unique for site</label>
                            </ListGroupItem>
                        </ListGroup>
                    </div>
                </div>
            </div>
        )
    }

    // ----------------
    // HELPERS

    private callback = (): void => {
        if (this.props.isValidUsername) {
            this.props.isValidUsername(this.state.isValidLength && (this.state.isUnique || this.state.submitting));
        }
    }

    private evaluateInput = (): void => {
        let input: string | null = this.props.input;
        let validLength: boolean = (input && input.length >= 6) ? true : false;
        if (validLength) {
            this.doEvaluateUniquess(input);
            this.setState({
                isValidLength: true
            });
        }
        else {
            this.setState({
                isValidLength: false,
                isUnique: false
            });
            setTimeout(() => {
                this.callback();
            }, 250);
        }        
    }

    private doEvaluateUniquess = debounce(input => {
        if (input && this.props.clientState.client) {
            let query: UsernameQuery = {
                clientCode: this.props.clientState.client.code,
                username: input,
                userId: (this.props.user ? this.props.user.id : null),
                exists: false
            };
            this.lookupUsername(query);
        }
        else {
            this.setState({
                isUnique: false
            });
            setTimeout(() => {
                this.callback();
            }, 250);
        }
    }, 750);

    private lookupUsername = (query: UsernameQuery): void => {
        this.setState({
            submitting: true
        });
        let fetchError: boolean = false;
        let action: string = 'users/getusername';
        let url: string = `${action}`;

        fetch(url,
            {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify(query)
            })
            .then(response => {
                if (response.status != 200) {
                    ErrorMessage.getFromResponse(response, action).then(
                        (errorMessage => {
                            console.log(errorMessage.text);
                        })
                    );
                    fetchError = true;                        
                    throw new Error();
                }
                return response.json();
            })
            .then(data => {
                let result: UsernameQuery = data as UsernameQuery;
                this.setState({
                    isUnique: !result.exists,
                    showErrors: true
                });
                this.callback();
            },
            err => {
                console.log(err.message);
            }
        )            
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        usersState: state.users
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            UsersStore.actionCreators
        ), dispatch)
    };
}

export default connect<{}, {}, UsernameRulesOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(UsernameRules as any);
