import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import * as UserStore from '../../store/User';
import { Client } from '../../store/Client';
import { Role } from '../../enums/Role';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import OrderPicker from '../order-picker/OrderPicker';
import AddOrderForm from '../add-order-form/AddOrderForm';

import './OrderContextSelector.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState,
    userState: {} as UserStore.UserState
}

const actionCreators = {
    actions: Object.assign({}, OrdersStore.actionCreators, MessageStore.actionCreators)
};

interface OrderContextSelectorOwnProps {
    isOpen: boolean;
    client: Client;
    onDismiss: (id: number | null | undefined) => void;
}

type OrderContextSelectorProps =
    OrderContextSelectorOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface OrderContextSelectorState {
    submitting: boolean;
    retrieving: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    submitExistingOrderRequested: boolean;
    submitNewOrderRequested: boolean;
    formResult: string | null;
    actions: FieldWrapper.OptionValue[];
    selectedAction: number;
    selectedOrder: OrdersStore.Order | null | undefined;
    newOrder: OrdersStore.BaseOrder | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateSelectOrderContextForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (formValues.action === 0) {
        errors.action = 'Select action';
    }
    return errors;
}

class OrderContextSelector extends React.PureComponent<OrderContextSelectorProps, OrderContextSelectorState> {

    // ----------------
    // VARIABLES

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', 'overflow': 'visible' }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderContextSelectorProps, state: OrderContextSelectorState) {
        super(props);
        this.state = {
            submitting: false,
            retrieving: false,
            changesAccepted: false,
            changesRejected: false,
            submitExistingOrderRequested: false,
            submitNewOrderRequested: false,
            formResult: null,
            actions: this.getActions(),
            selectedAction: 0,
            selectedOrder: undefined,
            newOrder: undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: OrderContextSelectorProps) => {
        if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
            this.props.onDismiss(null);
        }
        else if (this.state.submitting) {
            if (this.selectedOrderInStore()) {
                this.setState({
                    submitting: false,
                    changesAccepted: true,
                });
                this.props.onDismiss(this.state.selectedOrder ? this.state.selectedOrder.id : null);
            }
            else if (this.newOrderInStore()) {
                this.setState({
                    submitting: false,
                    changesAccepted: true
                });
                this.props.onDismiss(this.state.newOrder ? this.state.newOrder.id : null);
            }
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        let getOrder: boolean = false;
        switch (this.state.selectedAction) {
            case 1:
                if (this.state.selectedOrder) {
                    getOrder = true;
                }
                else {
                    this.setState({
                        submitExistingOrderRequested: true
                    })
                }
                break;
            case 2:
                if (this.state.newOrder) {
                    getOrder = true;
                }
                else {
                    this.setState({
                        submitNewOrderRequested: true
                    });
                }
                break;
            case 0:
            default:
                this.setState({
                    changesRejected: true,
                    formResult: 'Please select an action'
                })
                break;
        }

        if (getOrder && this.state.selectedOrder) {
            this.setState({
                submitting: true
            });
            this.props.actions.requestOrderDetail(this.props.client, this.state.selectedOrder.id as number);
        }
    }

    public render = () => {
        return (
            <Modal id="order-context-selector" isOpen={this.props.isOpen}
                onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(null)} className={(this.state.retrieving || this.state.submitting) ? "disabled" : ""}>
                    Select Order
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className="pl-3 pt-2 pb-2 pr-4 text-center action-bar">
                                <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                    <Field name="action" type="text" label=""
                                        component={FieldWrapper.renderRadioButtonField}
                                        options={this.state.actions} onChange={this.onSelectAction}
                                        disabled={this.state.retrieving || this.state.submitting || this.state.changesAccepted} />
                                    <button type="submit" ref={this.submitButton}>Submit</button>
                                </form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0">
                                <motion.div animate={this.state.selectedAction === 1 ? "visible" : "hidden"} initial={this.state.selectedAction === 1 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction !== 1 ? 0 : 0.25), delay: (this.state.selectedAction === 1 ? 0.1 : 0) }}>
                                    <Container className="component-wrapper flush-bottom">
                                        <OrderPicker activated={this.state.selectedAction === 1} disabled={this.state.submitting || this.state.changesAccepted}
                                            submitRequested={this.state.submitExistingOrderRequested}
                                            onSubmit={this.onSelectOrderFormSubmit}
                                            onSubmitFail={this.onSelectOrderFormSubmitFail}
                                            onRetrievingOrder={this.onRetrievingOrder}
                                            onSelectOrder={this.onSelectOrder} />
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0">
                                <motion.div animate={this.state.selectedAction === 2 ? "visible" : "hidden"} initial={this.state.selectedAction === 2 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction !== 2 ? 0 : 0.25), delay: (this.state.selectedAction === 2 ? 0.1 : 0) }}>
                                    <Container className="component-wrapper">
                                        <AddOrderForm submitRequested={this.state.submitNewOrderRequested} onSubmit={this.onAddOrderFormSubmit}
                                            onSubmitFail={this.onAddOrderFormSubmitFail} onSubmitSucceed={this.onAddOrderFormSubmitSucceed}
                                            onRetrievingAccount={this.onRetrievingAccount} onChange={this.onAddOrderFormChange}
                                            customerNumber={this.getCustomerNumber()} disabled={this.state.submitting || this.state.changesAccepted}
                                            resetForm={this.state.selectedAction === 2} />
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={this.submitSelectOrderContextForm} disabled={this.state.retrieving || this.state.submitting || this.state.changesAccepted}>
                                    {this.state.submitting ? "Working ..." : "Apply"}
                                </Button>
                                <Button color="link" onClick={() => this.props.onDismiss(null)} disabled={this.state.retrieving || this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        )
    }

    // ----------------
    // HELPERS

    private getActions = (): FieldWrapper.OptionValue[] => {
        let actions: FieldWrapper.OptionValue[] = [];
        actions.push({ label: 'Open Existing Order', value: 1 });
        actions.push({ label: 'Start New Order', value: 2 });
        return actions;
    }

    private getCustomerNumber = (): string | null => {
        let customerNumber: string | null = null;
        if (this.props.userState.user && this.props.userState.user.role === Role.Buyer) {
            customerNumber = this.props.userState.user.customerNumber;
        }
        return customerNumber;
    }

    private initializeForm = (): void => {
        this.setState({
            actions: this.getActions(),
            selectedAction: 0
        });
        this.props.change("action", 0);
    }

    private newOrderInStore = (): boolean => {
        let inStore: boolean = false;
        if (this.props.ordersState.orderDetail && this.state.newOrder) {
            inStore = (this.props.ordersState.orderDetail.id === this.state.newOrder.id) ? true : false;
        }
        return inStore;
    }

    private selectedOrderInStore = (): boolean => {
        let inStore: boolean = false;
        if (this.props.ordersState.orderDetail && this.state.selectedOrder) {
            inStore = (this.props.ordersState.orderDetail.id === this.state.selectedOrder.id) ? true : false;
        }       
        return inStore;
    }

    private onAddOrderFormChange = (): void => {
        this.setState({
            formResult: null
        });
    }

    private onAddOrderFormSubmit = (submitting: boolean): void => {
        this.setState({
            formResult: null,
            submitNewOrderRequested: false,
            submitting: submitting
        });
    }

    private onAddOrderFormSubmitFail = (): void => {
        this.setState({
            formResult: null,
            submitNewOrderRequested: false
        })
    }

    private onAddOrderFormSubmitSucceed = (newOrder: OrdersStore.BaseOrder): void => {
        this.setState({
            newOrder: newOrder
        });
        this.props.actions.requestOrderDetail(this.props.client, newOrder.id as number);
    }

    private onRetrievingAccount = (retrieving: boolean): void => {
        this.setState({
            retrieving: retrieving
        });
    }

    private onRetrievingOrder = (retrieving: boolean): void => {
        this.setState({
            retrieving: retrieving
        });
    }

    private onSelectAction = (event: React.ChangeEvent): void => {
        let selectedValue = event.target.getAttribute("value");
        if (selectedValue && parseInt(selectedValue) != this.state.selectedAction) {
            this.setState({
                selectedAction: parseInt(selectedValue)
            });
        }
    }

    private onSelectOrder = (order: OrdersStore.Order | null | undefined): void => {
        this.setState({
            selectedOrder: order
        });
    }

    private onSelectOrderFormSubmit = (submitting: boolean): void => {
        this.setState({
            formResult: null,
            submitExistingOrderRequested: false,
            submitting: submitting
        });
    }

    private onSelectOrderFormSubmitFail = (): void => {
        this.setState({
            formResult: null,
            submitExistingOrderRequested: false
        });
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private resetForm = (): void => {
        this.setState({
            submitting: false,
            retrieving: false,
            changesAccepted: false,
            changesRejected: false,
            submitExistingOrderRequested: false,
            formResult: null,
            selectedAction: 0,
            selectedOrder: undefined
        });
        this.props.reset();
    }

    private submitSelectOrderContextForm = (): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        ordersState: state.orders,
        userState: state.user
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            OrdersStore.actionCreators,
            MessageStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, OrderContextSelectorOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'selectOrderContextForm',
    validate: validateSelectOrderContextForm,
    enableReinitialize: true
})(OrderContextSelector as any));
