import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as OrderItemsStore from '../../store/OrderItems';
import { OrderItem } from '../../store/OrderItems';
import { OrderInvoice } from '../../store/OrderInvoice';
import { ClientCode } from '../../enums/ClientCode';
import * as ErrorMessage from '../../common/ErrorMessage';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import $ from 'jquery';

import './DeleteOrderItem.scss';

// ----------------
// PROPS

const applicationState = {
    orderItemsState: {} as OrderItemsStore.OrderItemsState,
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, OrderItemsStore.actionCreators, MessageStore.actionCreators)
};

interface DeleteOrderItemAsyncActions {
    asyncActions: {
        deleteOrderItem: (clientCode: ClientCode, orderItemToDelete: OrderItem, broadcastError: boolean) => Promise<OrderInvoice | null | undefined>;
    }
}

interface DeleteOrderItemOwnProps {
    clientCode: ClientCode | null | undefined;
    orderItemToDelete: OrderItem | null;
    onDelete: (() => void);
    onDismiss: (() => void);
    onConcurrencyError: (() => void);
    modalOnModal?: boolean;
}

type DeleteOrderItemProps =
    DeleteOrderItemOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & DeleteOrderItemAsyncActions;

// ----------------
// LOCAL STATE
interface DeleteOrderItemState {
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
}

class DeleteOrderItem extends React.PureComponent<DeleteOrderItemProps, DeleteOrderItemState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: DeleteOrderItemProps, state: DeleteOrderItemState) {
        super(props);
        this.state = {
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: DeleteOrderItemProps) => {
        if (this.state.submitting) {
            if (this.receivedValidationError()) {
                let formResult: string | null = this.getFormResult();
                setTimeout(() => {
                    this.setState({
                        submitting: false,
                        changesRejected: true,
                        formResult: formResult
                    });
                    setTimeout(() => {
                        this.props.onConcurrencyError();
                    }, 1500);
                })
            }
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <Modal id="delete-order-item" size="sm" modalClassName={this.props.modalOnModal ? "modal-on-modal" : ""} isOpen={this.props.orderItemToDelete != null}
                onOpened={this.initializeDialog.bind(this)} onClosed={this.resetDialog}>
                <ModalHeader toggle={this.props.onDismiss} className={this.state.submitting ? "disabled" : ""}>
                    Remove Item
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className="action-info pl-0 pr-0">
                                <span className="action-description">Item will be permanently deleted:</span>
                                <span className="action-target">{this.props.orderItemToDelete ? this.props.orderItemToDelete.style.name : ""}</span>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" disabled={this.state.submitting || this.state.changesAccepted}
                                    onClick={this.deleteOrderItem}>
                                    {this.state.submitting ? "Working" : "Continue"}
                                </Button>
                                <Button color="link" onClick={this.props.onDismiss} disabled={this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private deleteOrderItem = (): void => {
        if (this.props.clientCode && this.props.orderItemToDelete) {
            this.props.actions.clearMessage();
            this.setState({
                submitting: true,
                changesRejected: false,
                formResult: null
            });
            this.props.asyncActions.deleteOrderItem(this.props.clientCode, this.props.orderItemToDelete, false)
                .then(result => {
                    if (result) {
                        this.setState({
                            submitting: false,
                            changesAccepted: true,
                            formResult: 'Item deleted'
                        });
                        setTimeout(() => {
                            this.props.onDelete();
                        }, 1500);
                    }
                },
                err => {
                    this.setState({
                        submitting: false,
                        changesRejected: true,
                        formResult: err
                    });
                });
        }
    }

    private getFormResult = (): string | null => {
        let formResult: string | null = null;
        if (this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) {
                formResult = this.props.messageState.message.text || 'Form contains invalid entries';
            }
            else if (this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) {
                formResult = 'Order item deleted';
            }
        }
        return formResult;
    }

    private initializeDialog = (): void => {
        if (this.props.modalOnModal) {
            let modal: JQuery<HTMLElement> = $('#delete-order-item').parent();
            if (modal.length > 0) {
                let modalParent = modal.parent().parent();
                modalParent.css({ 'zIndex': 1500 });
            }
        }
        this.setState({
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        });
    }

    private receivedActionCompleteMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) ?
            true : false;
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private resetDialog = (): void => {
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        orderItemsState: state.orderItems,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            OrderItemsStore.actionCreators,
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
            deleteOrderItem: (clientCode: ClientCode, orderItemToDelete: OrderItem, broadcastError: boolean) =>
                dispatch(OrderItemsStore.actionCreators.deleteOrderItem(clientCode, orderItemToDelete, broadcastError))
        }
    };
}

export default connect<{}, {}, DeleteOrderItemOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(DeleteOrderItem as any);
