import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as OrderDatesStore from '../../store/OrderDates';
import * as OrderItemsStore from '../../store/OrderItems';
import * as OrdersStore from '../../store/Orders';
import * as ShippingOptionsStore from '../../store/ShippingOptions';
import * as UserStore from '../../store/User';
import { OrderInvoiceItem } from '../../store/OrderInvoice';
import { CustomerAccount, CustomerDetail } from '../../store/Customers';
import { ClientCode } from '../../enums/ClientCode';
import { DropShipAddress } from '../../common/AddressTypes';
import { Role } from '../../enums/Role';
import { Button, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import Loader from '../loader/Loader';
import DetailTitleBlock from '../detail-title-block/DetailTitleBlock';
import { TitleBlock } from '../../common/ComponentTypes';
import FormResult from '../form-result/FormResult';
import OrderHeader from '../order-header/OrderHeader';
import OrderSummary from '../order-summary/OrderSummary';
import OrderDetail from '../order-detail/OrderDetail';
import OrderCustomer from '../order-customer/OrderCustomer';
import OrderCustomerLocation from '../order-customer-location/OrderCustomerLocation';
import DeleteOrder from '../delete-order/DeleteOrder';
import SubmitOrder from '../submit-order/SubmitOrder';
import AddOrder from '../add-order/AddOrder';
import { areEqual } from '../../common/OrderFunctions';
import cloneDeep from 'lodash/cloneDeep';
import endsWith from 'lodash/endsWith';
import $ from 'jquery'

import './EditOrder.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    orderDatesState: {} as OrderDatesStore.OrderDatesState,
    ordersState: {} as OrdersStore.OrdersState,
    shippingOptionsState: {} as ShippingOptionsStore.ShippingOptionsState,
    userState: {} as UserStore.UserState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrderDatesStore.actionCreators, OrdersStore.actionCreators, ShippingOptionsStore.actionCreators)
}

interface EditOrderAsyncActions {
    asyncActions: {
        requestOrderDetailAsync: (client: ClientStore.Client, id: number) => Promise<OrdersStore.OrderDetail | null | undefined>;
        changeOrderCustomerAsync: (client: ClientStore.Client, orderDetail: OrdersStore.OrderDetail, customer: CustomerAccount) => Promise<OrdersStore.OrderDetail | null | undefined>;
        changeOrderShippingAsync: (client: ClientStore.Client, orderDetail: OrdersStore.OrderDetail) => Promise<OrdersStore.OrderDetail | null | undefined>;
        saveOrderDetailAsync: (client: ClientStore.Client, orderDetail: OrdersStore.OrderDetail) => Promise<OrdersStore.OrderDetail | null | undefined>;
    }
}

interface MatchParams {
    id: string;
    tab: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> { }

type EditOrderProps =
    MatchProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & EditOrderAsyncActions;

// ----------------
// LOCAL STATE

interface EditOrderState {
    isVisible: boolean;
    isLoadError: boolean;
    isLoadWarning: boolean;
    isReloading: boolean;
    isOpenOrder: boolean;
    isSelectingCustomer: boolean;
    isSelectingCustomerLocation: boolean;
    isChangingCustomer: boolean;
    isSaved: boolean;
    customerChanged: boolean;
    saving: boolean;    
    submitting: boolean;
    validating: boolean;
    addingOrder: boolean;
    orderDetail: OrdersStore.OrderDetail | null | undefined;
    orderToSubmit: OrdersStore.OrderDetail | null;
    orderToDelete: OrdersStore.Order | null;
    orderItemToDelete: OrderItemsStore.OrderItem | null | undefined;
    orderItemToEdit: OrderItemsStore.OrderItem | null | undefined;
    titleBlock: TitleBlock;
    activeTab: string;
}

class EditOrder extends React.PureComponent<EditOrderProps, EditOrderState> {

    // ----------------
    // VARIABLES

    public saveButton: React.RefObject<HTMLButtonElement>;
    public shipDatePicker: React.RefObject<any>;

    public editOrderVariants = {
        hidden: { opacity: 0, height: 0, overflow: "hidden" },
        visible: { opacity: 1, height: "auto", overflow: "visible" }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: EditOrderProps, state: EditOrderState) {
        super(props);
        this.state = {
            isVisible: (this.hasSelectedOrder() && this.props.orderDatesState.orderDates) ? true : false,
            isLoadError: false,
            isLoadWarning: false,
            isReloading: false,
            isOpenOrder: this.isOpenOrder(),
            isSelectingCustomer: false,
            isSelectingCustomerLocation: false,
            isChangingCustomer: false,
            isSaved: false,
            customerChanged: false,
            saving: false,
            submitting: false,
            validating: false,
            addingOrder: false,
            orderDetail: this.hasSelectedOrder() ? this.props.ordersState.orderDetail : undefined,
            orderToSubmit: null,
            orderToDelete: null,
            orderItemToDelete: null,
            orderItemToEdit: null,
            titleBlock: this.getTitleBlock(this.props.ordersState.orderDetail),
            activeTab: this.props.match.params.tab
        };
        this.saveButton = React.createRef<HTMLButtonElement>();
        this.shipDatePicker = React.createRef<any>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.ensureDataFetched();
    }

    public componentDidUpdate = (prevProps: EditOrderProps) => {
        if (!this.state.isVisible) {
            if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
                this.setState({
                    isReloading: false
                });
                setTimeout(() => {
                    this.setState({
                        isLoadError: true,
                        isLoadWarning: this.receivedWarningMessage()
                    });
                }, 400);
            }
            else if (this.hasSelectedOrder() && this.props.orderDatesState.orderDates && this.props.shippingOptionsState.shippingOptions) {
                this.setState({
                    isVisible: true,
                    isLoadError: false,
                    isLoadWarning: false,
                    isReloading: false,
                    submitting: false,
                    orderDetail: this.props.ordersState.orderDetail,
                    titleBlock: this.getTitleBlock(this.props.ordersState.orderDetail)
                })
            }
        }
        else if (!areEqual(prevProps.ordersState.orderDetail, this.props.ordersState.orderDetail)) {
            if (!this.state.orderToDelete) {
                this.setState({
                    orderDetail: cloneDeep(this.props.ordersState.orderDetail),
                    titleBlock: this.getTitleBlock(this.props.ordersState.orderDetail),
                    isOpenOrder: this.isOpenOrder(),
                    activeTab: endsWith(this.props.history.location.pathname, 'detail') ? 'detail' : 'header'
                })
            }
        }
        else if (this.refreshRequired()) {
            this.props.actions.clearMessage();
            this.props.actions.closeOrder();
            this.setState({
                isVisible: false
            });
            setTimeout(() => {
                this.reloadDetail();
            })
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={(!this.state.isVisible && !this.state.isLoadError) || this.state.isReloading} />
                <motion.div id="editOrderPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"} variants={this.editOrderVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className="justify-content-start">
                            <Col className="pl-0 pr-0">
                                <DetailTitleBlock titleBlock={this.state.titleBlock} disabled={this.state.submitting} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pr-0 order-tabs">
                                <Nav tabs className="right-align">
                                    <NavItem>
                                        <NavLink className={this.state.activeTab === "header" ? "active" : ''} onClick={() => this.toggleTabs("header")}
                                            disabled={this.state.saving || this.state.submitting}>
                                            Header
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={this.state.activeTab === "detail" ? "active" : ''} onClick={() => this.toggleTabs("detail")}
                                            disabled={this.state.saving || this.state.submitting}>
                                            Detail
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pr-0 order-tabs-content">
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="header">
                                        <Container>
                                            {this.state.isOpenOrder && (
                                                <Row>
                                                    <Col className="pr-0 text-right order-header-button-bar">
                                                        <Button type="button" color="secondary" onClick={this.onAddOrderItem}>
                                                            <span className="icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-plus">
                                                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                                                </svg>
                                                            </span>
                                                            <label>Add Item</label>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row>
                                                <Col className={"pl-0 pr-0 pr-lg-4" + (this.state.isOpenOrder ? "" : " pt-2")} sm={12} md={12} lg={8}>
                                                    {this.props.clientState.client && this.props.orderDatesState.orderDates &&
                                                        this.props.shippingOptionsState.shippingOptions && this.state.orderDetail && (
                                                        <OrderHeader client={this.props.clientState.client}
                                                            orderDates={this.props.orderDatesState.orderDates}
                                                            orderDetail={this.state.orderDetail}
                                                            isOpenOrder={this.state.isOpenOrder}
                                                            validationRequested={this.state.saving || this.state.validating}
                                                            onValidationSucceeded={this.onHeaderValidationSucceeded}
                                                            onValidationFailed={this.onHeaderValidationFailed}
                                                            onChangeAccount={this.changeAccount}
                                                            onChangeDropShipAddress={this.changeDropShipAddress}
                                                            onChangeShippingOption={this.changeShippingOption}
                                                            onChangeShippingLocation={this.changeShippingLocation}
                                                            onChangeShipDate={(shipDate: Date, cancelDate: Date | null | undefined) => this.changeShipDate(shipDate, cancelDate, "header")}
                                                            onChangeCancelDate={this.changeCancelDate}
                                                            onGoToCopy={this.goToCopy}
                                                            shipDateReference={this.shipDatePicker}
                                                            shippingOptions={this.props.shippingOptionsState.shippingOptions} />
                                                    )}
                                                </Col>
                                                <Col className={"pl-0 pr-0" + (this.state.isOpenOrder ? "" : " pt-2")} sm={12} md={12} lg={4}>
                                                    {this.state.orderDetail && (
                                                        <OrderSummary orderDetail={this.state.orderDetail} onViewProduct={this.goToOrderItemProduct} submitting={this.state.saving} isOpenOrder={this.state.isOpenOrder} />
                                                    )}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </TabPane>
                                    <TabPane tabId="detail">
                                        {this.props.orderDatesState.orderDates && this.state.orderDetail && (
                                            <OrderDetail isActive={this.state.activeTab === 'detail'}
                                                isOpenOrder={this.state.isOpenOrder}
                                                orderItemToEdit={this.state.orderItemToEdit}
                                                orderItemToDelete={this.state.orderItemToDelete}
                                                orderDates={this.props.orderDatesState.orderDates}
                                                orderDetail={this.state.orderDetail}
                                                onAddItem={this.onAddOrderItem}
                                                onViewProduct={this.goToProduct}
                                                onChangeShipDate={(shipDate: Date, cancelDate: Date | null | undefined) => this.changeShipDate(shipDate, cancelDate, "detail")} />
                                        )}
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                        {this.state.orderDetail && this.state.isOpenOrder && (
                            <Row className="order-actions">
                                <Col className="pl-0 pr-0">
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Container className="button-bar-container">
                                                    <div className="button-bar">
                                                        <Button type="button" color="primary" disabled={this.state.saving || this.state.submitting} onClick={this.requestSave} innerRef={this.saveButton}>
                                                            {this.state.saving ? 'Working' : 'Save'}
                                                        </Button>
                                                        <Button color="link" onClick={this.cancelOrder} disabled={this.state.saving || this.state.submitting}>
                                                            Cancel
                                                        </Button>
                                                        <AnimatePresence>
                                                            {this.state.isSaved && (
                                                                <motion.div className="save-confirmation" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.75 }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                        <polyline points="20 6 9 17 4 12"></polyline>
                                                                    </svg>
                                                                    <label></label>
                                                                </motion.div>
                                                            )}
                                                        </AnimatePresence>
                                                        <div className="auxiliary-bar">
                                                            <Button type="button" color="success" disabled={this.state.saving || this.state.submitting} onClick={this.submitOrder}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-upload-cloud">
                                                                    <polyline points="16 16 12 12 8 16"></polyline>
                                                                    <line x1="12" y1="12" x2="12" y2="21"></line>
                                                                    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                                                                    <polyline points="16 16 12 12 8 16"></polyline>
                                                                </svg>
                                                                <label>{this.state.submitting ? "Working" : "Submit Order"}</label>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Container>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        )}
                        {this.state.orderDetail && (
                            <React.Fragment>
                                <OrderCustomer isOpen={this.state.isSelectingCustomer} clientCode={this.state.orderDetail.client.code}
                                    isSaving={this.state.isChangingCustomer} isSaved={this.state.customerChanged}
                                    onDismiss={this.hideOrderCustomer} />
                                <DeleteOrder orderToDelete={this.state.orderToDelete} clientCode={this.state.orderDetail.client.code}
                                    onDelete={this.onDeleteOrderComplete} onDismiss={this.hideDeleteOrder}
                                    onConcurrencyError={this.onDeleteOrderNotAllowed} />                                
                                <SubmitOrder isOpen={this.state.submitting} orderDetail={this.state.orderToSubmit} onDismiss={this.hideSubmitOrder}
                                    onStartNewOrder={this.onStartNewOrder} onReloadOrder={this.onReloadDetailAsync}
                                    onEditItem={this.onEditItem} onAddItem={this.onAddOrderItemAsync}
                                    onDeleteItem={this.onDeleteItem} onAbend={this.abendOrder} />
                                <AddOrder isOpen={this.state.addingOrder} customerNumber={this.getCustomerNumber()} onDismiss={this.hideAddOrder} />
                                {this.state.orderDetail.customerAccount && (
                                    <OrderCustomerLocation isOpen={this.state.isSelectingCustomerLocation} clientCode={this.state.orderDetail.client.code}
                                        isSaving={this.state.isChangingCustomer} isSaved={this.state.customerChanged}
                                        onDismiss={this.hideOrderCustomerLocation} customerAccount={this.state.orderDetail.customerAccount} />
                                )}
                            </React.Fragment>
                        )}
                    </Container>
                </motion.div>
                {this.state.isLoadError && (
                    <Container>
                        <Row className="justify-content-center">
                            <Col className={"pt-4 page-error" + (this.state.isLoadWarning ? " warning" : "")} xs={"auto"}>
                                <Button color="primary" onClick={this.reloadDetail}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                        <polyline points="1 4 1 10 7 10"></polyline>
                                        <polyline points="23 20 23 14 17 14"></polyline>
                                        <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                    </svg>
                                    Reload
                                </Button>
                                <Button color="link" onClick={this.goBack}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-left">
                                        <polyline points="15 18 9 12 15 6"></polyline>
                                    </svg>
                                    Go Back
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                )}
            </React.Fragment>
        )
    }

    // ----------------
    // HELPERS

    private abendOrder = (): void => {
        this.setState({
            saving: false,
            submitting: false,
            validating: false,
        });
        setTimeout(() => {
            this.props.history.push('/logout');
        }, 400);
    }

    private cancelOrder = (): void => {
        if (this.state.orderDetail) {
            let o: OrdersStore.OrderDetail = this.state.orderDetail;
            let orderToDelete: OrdersStore.Order = {
                billto: o.billto,
                cancelDate: o.cancelDate,
                client: o.client,
                clientCode: o.client.code,
                createdBy: o.createdBy,
                customerAccount: o.customerAccount,
                customerName: o.customerAccount ? o.customerAccount.name : '',
                customerNameOnly: o.customerAccount ? o.customerAccount.nameOnly : '',
                customerNumber: o.customerNumber,
                dateCreated: o.dateCreated,
                dateLastModified: o.dateLastModified,
                dateSubmitted: o.dateSubmitted,
                description: o.description,
                id: o.id,
                orderDate: o.orderDate,
                orderNumber: o.orderNumber,
                orderNumberERP: null,
                orderStatus: o.orderStatus,
                poNumber: o.poNumber,
                poRequired: o.poRequired,
                repName: null,
                season: { code: o.seasonCode, name: '' },
                seasonCode: o.seasonCode,
                shipDate: o.shipDate,
                shipto: o.shipto,
                total: 0
            };
            this.setState({
                orderToDelete: orderToDelete
            });
        }
    }

    private changeAccount = (): void => {
        this.setState({
            isSelectingCustomer: true,
            isChangingCustomer: false,
            customerChanged: false
        });
    }

    private changeCancelDate = (cancelDate: Date): void => {
        if (this.state.orderDetail && this.state.orderDetail.cancelDate != cancelDate) {
            let orderDetail: OrdersStore.OrderDetail = this.state.orderDetail;
            orderDetail.cancelDate = cancelDate;
            this.setState({
                orderDetail: cloneDeep(orderDetail)
            });
        }
    }

    private changeDropShipAddress = (dropShipAddress: DropShipAddress | null | undefined, shippingOption: ShippingOptionsStore.ShippingOption | null | undefined): void => {
        if (this.props.clientState.client && this.props.ordersState.orderDetail) {
            let updatedOrderDetail: OrdersStore.OrderDetail = cloneDeep(this.props.ordersState.orderDetail);
            updatedOrderDetail.dropShipAddress = dropShipAddress;
            if (shippingOption) {
                updatedOrderDetail.customShipping = {
                    code: shippingOption.code,
                    name: shippingOption.description
                };
            }
            this.props.actions.setOrderDetail(this.props.clientState.client.code, updatedOrderDetail);
            this.setState({
                orderDetail: updatedOrderDetail
            });
        }
    }

    private changeOrderCustomer = (account: CustomerAccount): void => {
        if (this.state.orderDetail && this.props.clientState.client) {
            this.setState({
                isChangingCustomer: true
            });
            this.props.asyncActions.changeOrderCustomerAsync(this.props.clientState.client, this.state.orderDetail, account)
                .then(result => {
                    this.setState({
                        orderDetail: cloneDeep(result),
                        titleBlock: this.getTitleBlock(result),
                        isChangingCustomer: false,
                        customerChanged: true
                    })
                    setTimeout(() => {
                        this.setState({
                            isSelectingCustomer: false,
                            isSelectingCustomerLocation: false
                        })
                    }, 1500);
                },
                err => {
                    this.setState({
                        isSelectingCustomer: false,
                        isSelectingCustomerLocation: false,
                        isChangingCustomer: false
                    })
                }
            )
        }
    }

    private changeShipDate = (shipDate: Date, cancelDate: Date | null | undefined, source: string): void => {
        if (this.state.orderDetail && this.state.orderDetail.shipDate != shipDate) {
            let orderDetail: OrdersStore.OrderDetail = this.state.orderDetail;
            orderDetail.shipDate = shipDate;
            if (cancelDate) {
                orderDetail.cancelDate = cancelDate;
            }
            this.setState({
                orderDetail: cloneDeep(orderDetail),
            });
            if (source === 'detail' && this.state.activeTab === 'detail') {
                if (this.shipDatePicker.current) {
                    this.shipDatePicker.current.setSelected(shipDate);
                }
            }
        }
    }

    private changeShippingLocation = (): void => {
        this.setState({
            isSelectingCustomerLocation: true,
            isChangingCustomer: false,
            customerChanged: false
        });
    }

    private changeShippingOption = (shippingOption: ShippingOptionsStore.ShippingOption | null | undefined): void => {
        if (shippingOption && this.state.orderDetail) {
            let updatedOrderDetail: OrdersStore.OrderDetail = cloneDeep(this.state.orderDetail);
            updatedOrderDetail.customShipping = {
                code: shippingOption.code,
                name: shippingOption.description
            };
            this.updateOrderShipping(updatedOrderDetail);
        }
    }

    private ensureDataFetched = (): void => {
        let orderId: number = parseInt(this.props.match.params.id);
        let orderInStore: boolean = this.orderInStore(orderId);
        if (orderInStore && this.props.orderDatesState.orderDates && this.props.shippingOptionsState.shippingOptions) {
            this.setState({
                isVisible: true,
                isLoadError: false,
                isLoadWarning: false,
                isReloading: false,
                orderDetail: cloneDeep(this.props.ordersState.orderDetail)
            });
            this.setDefaultFocus();
        }
        else {
            if (this.props.clientState.client) {
                if (!this.props.orderDatesState.orderDates) {
                    if (!this.state.isLoadError) {
                        this.props.actions.requestOrderDates(this.props.clientState.client);
                        setTimeout(() => {
                            this.ensureDataFetched();
                        }, 200);
                    }
                }
                else if (!this.props.shippingOptionsState.shippingOptions) {
                    if (!this.state.isLoadError) {
                        this.props.actions.requestShippingOptions(this.props.clientState.client);
                        setTimeout(() => {
                            this.ensureDataFetched();
                        }, 200);
                    }
                }
                else {
                    this.getOrder(orderId, this.props.clientState.client);
                }
            }
            else {
                setTimeout(() => {
                    this.ensureDataFetched();
                }, 200);
            }
        }
    }

    private getCustomerNumber = (): string | null => {
        let customerNumber: string | null = null;
        if (this.props.userState.user && this.props.userState.user.role === Role.Buyer) {
            customerNumber = this.props.userState.user.customerNumber;
        }
        return customerNumber;
    }

    private getOrder = (orderId: number, client: ClientStore.Client): void => {
        this.props.asyncActions.requestOrderDetailAsync(client, orderId)
            .then(result => {
                this.setState({
                    isVisible: true,
                    isLoadError: false,
                    isLoadWarning: false,
                    isReloading: false,
                    isOpenOrder: (result ? result.orderStatus === 'Open' ? true : false : this.state.isOpenOrder),
                    orderDetail: cloneDeep(result),
                    titleBlock: this.getTitleBlock(result)
                });
                this.setDefaultFocus();
            },
            err => {
            });
    }

    private getReturnPath = (): string => {
        let path: string = '/orders';
        if (this.props.history.location.state) {
            let state: any = this.props.history.location.state;
            if (state.from) {
                path = state.from;
            }
        }
        return path;
    }

    private getSelectedCustomerNumber = (selectedAccount: CustomerAccount): string => {
        let customerNumber: string = selectedAccount.billto;
        let shipto: CustomerDetail[] = selectedAccount.shipToLocations.filter((detail: CustomerDetail) => {
            return detail.selected === true;
        });
        if (shipto.length > 0) {
            customerNumber = shipto[0].billto + '-' + shipto[0].shipto;
        }
        return customerNumber;
    }

    private getTitleBlock = (orderDetail: OrdersStore.OrderDetail | null | undefined): TitleBlock => {
        let titleBlock: TitleBlock = {
            header: undefined,
            title: undefined,
            subtitle: undefined,
            link: this.getReturnPath()
        };
        if (orderDetail) {
            titleBlock.header = orderDetail.orderNumber;
            titleBlock.title = orderDetail.customerAccount ? orderDetail.customerAccount.nameOnly : orderDetail.shipToCustomer.nameOnly;
            titleBlock.subtitle = orderDetail.orderStatus;
        }
        return titleBlock;
    }

    private goBack = () => {
        this.props.actions.clearMessage();
        setTimeout(() => {
            this.props.history.push('/orders');
        }, 400);
    }

    private goToCopy = (id: number) => {
        this.props.actions.clearMessage();
        let path: string = '/orders/edit/' + id.toString() + '/header';        
        this.setState({
            isVisible: false
        });
        setTimeout(() => {
            this.props.history.push(path);
        }, 200);
    }

    private goToProduct = (orderItem: OrderItemsStore.OrderItem): void => {
        if (this.state.orderDetail) {
            let currentPath: string = '/orders/edit/' + this.state.orderDetail.id + '/detail';
            this.props.history.push('/products/detail/' + encodeURIComponent(orderItem.sanitizedProductId), { from: currentPath });
        }
    }

    private goToOrderItemProduct = (orderInvoiceItem: OrderInvoiceItem): void => {
        if (this.state.orderDetail) {
            let currentPath: string = '/orders/edit/' + this.state.orderDetail.id + '/header';
            this.props.history.push('/products/detail/' + encodeURIComponent(orderInvoiceItem.sanitizedProductId),
                { from: currentPath });
        }
    }

    private hasSelectedOrder = (): boolean => {
        let isSelected: boolean = false;
        if (this.props.ordersState.orderDetail) {
            let orderId: number = parseInt(this.props.match.params.id);
            isSelected = this.props.ordersState.orderDetail.id === orderId;
        }
        return isSelected;
    }

    private hideAddOrder = (id: number | null | undefined): void => {
        this.setState({
            addingOrder: false
        });
        if (id) {
            let url: string = '/orders/edit/' + id + '/header';
            this.props.history.push(url);
            setTimeout(() => {
                this.reloadDetail();
            }, 400);
        }
    }

    private hideDeleteOrder = (): void => {
        this.setState({
            orderToDelete: null
        });
    }

    private hideOrderCustomer = (selectedAccount: CustomerAccount | null | undefined): void => {
        if (selectedAccount && this.state.orderDetail) {
            let currentCustomerNumber: string = this.state.orderDetail.billto + '-' + this.state.orderDetail.shipto;
            let selectedCustomerNumber: string = this.getSelectedCustomerNumber(selectedAccount);
            if (selectedCustomerNumber != currentCustomerNumber) {
                this.changeOrderCustomer(selectedAccount);
            }
            else {
                this.setState({
                    isSelectingCustomer: false
                });
            }
        }
        else {
            this.setState({
                isSelectingCustomer: false
            });
        }
    }

    private hideOrderCustomerLocation = (selectedAccount: CustomerAccount | null | undefined): void => {
        if (selectedAccount && this.state.orderDetail && this.state.orderDetail.customerAccount) {
            let currentCustomerNumber: string = this.getSelectedCustomerNumber(this.state.orderDetail.customerAccount);
            let selectedCustomerNumber: string = this.getSelectedCustomerNumber(selectedAccount);
            if (selectedCustomerNumber != currentCustomerNumber) {
                this.changeOrderCustomer(selectedAccount);
            }
            else {
                this.setState({
                    isSelectingCustomerLocation: false
                });
            }
        }
        else {
            this.setState({
                isSelectingCustomerLocation: false
            });
        }
    }

    private hideSubmitOrder = (goBack: boolean): void => {
        this.setState({
            saving: false,
            submitting: false,
            validating: false,
            isOpenOrder: this.isOpenOrder()
        });
        if (goBack) {
            this.goBack();
        }
    }

    private isOpenOrder = (): boolean => {
        let isOpen: boolean = this.hasSelectedOrder() ? true : false;
        if (isOpen && this.props.ordersState.orderDetail) {
            isOpen = this.props.ordersState.orderDetail.orderStatus === 'Open' ? true : false;
        }
        else {
            isOpen = false;
        }
        return isOpen;
    }

    private onAddOrderItem = () => {
        this.props.history.push('/products');
    }

    private onAddOrderItemAsync = () => {
        this.hideSubmitOrder(false);
        setTimeout(() => {
            this.onAddOrderItem();
        }, 400);
    }

    private onDeleteItem = (orderItem: OrderItemsStore.OrderItem | null | undefined): void => {
        this.setState({
            orderItemToDelete: orderItem
        });
    }

    private onEditItem = (orderItem: OrderItemsStore.OrderItem | null | undefined): void => {
        if (orderItem) {
            this.setState({
                submitting: false,
                validating: false
            });
            setTimeout(() => {
                this.setState({
                    activeTab: "detail",
                    orderItemToEdit: orderItem
                });
            }, 400);
        }
    }

    private onDeleteOrderComplete = (): void => {
        this.hideDeleteOrder();
        setTimeout(() => {
            this.goBack();
        }, 400);
    }

    private onDeleteOrderNotAllowed = (): void => {
        this.hideDeleteOrder();
    }

    private onHeaderValidationFailed = () => {
        this.setState({
            saving: false,
            submitting: false,
            validating: false,
            activeTab: this.state.activeTab === 'header' ? this.state.activeTab : 'header'
        })
    }

    private onHeaderValidationSucceeded = (updatedOrderDetail: OrdersStore.OrderDetail):void => {
        if (this.state.saving) {
            this.saveOrder(updatedOrderDetail);
        }
        else if (this.state.validating) {
            this.setState({
                submitting: true,
                orderToSubmit: updatedOrderDetail
            })
        }
    }

    private onReloadDetailAsync = (): void => {
        this.setState({
            submitting: false
        });
        setTimeout(() => {
            this.reloadDetail();
        });
    }

    private onStartNewOrder = (): void => {
        this.setState({
            submitting: false,
        });
        setTimeout(() => {
            this.setState({
                addingOrder: true
            });
        }, 500);
    }

    private orderInStore = (id: number): boolean => {
        let inStore: boolean = false;
        if (this.props.ordersState.orderDetail) {
            inStore = (this.props.ordersState.orderDetail.id === id);
        }
        return inStore;
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private refreshRequired = (): boolean => {
        let routeParam: number = parseInt(this.props.match.params.id);
        return (this.props.ordersState.orderDetail && this.props.ordersState.orderDetail.id != routeParam) ? true : false;
    }

    private reloadDetail = (): void => {
        this.props.actions.clearMessage();
        let orderId: number = parseInt(this.props.match.params.id);
        if (this.props.orderDatesState.orderDates && this.props.shippingOptionsState.shippingOptions) {
            setTimeout(() => {
                this.setState({
                    isReloading: true,
                    isLoadError: false,
                    isLoadWarning: false
                });
                this.getOrder(orderId, this.props.clientState.client as ClientStore.Client);
            }, 200);
        }
        else {
            this.setState({
                isLoadError: false
            });
            setTimeout(() => {
                this.ensureDataFetched();
            }, 200);
        }
    }

    private requestSave = (): void => {
        this.props.actions.clearMessage();
        if (this.state.activeTab === 'detail') {
            this.toggleTabs('header');
            setTimeout(() => {
                this.setState({
                    saving: true
                });
            }, 400);
        }
        else {
            this.setState({
                saving: true
            });
        }
    }   

    private saveOrder = (updatedOrderDetail: OrdersStore.OrderDetail): void => {
        if (this.state.orderDetail && this.props.clientState.client) {
            this.props.asyncActions.saveOrderDetailAsync(this.props.clientState.client, updatedOrderDetail)
                .then(result => {
                    this.setState({
                        orderDetail: result,
                        isSaved: true,
                        saving: false
                    })
                    setTimeout(() => {
                        this.setState({
                            isSaved: false
                        });
                    }, 2000);
                },
                err => {
                    this.setState({
                        saving: false
                    })
                }
            )
        }
    }

    private setDefaultFocus = (): void => {
        if (this.saveButton && this.saveButton.current) {
            this.saveButton.current.focus();
        }
        else {
            setTimeout(() => {
                this.setDefaultFocus();
            }, 400);
        }
    }

    private submitOrder = (): void => {
        this.props.actions.clearMessage();
        if (this.state.activeTab === 'detail') {
            this.toggleTabs('header');
            setTimeout(() => {
                this.setState({
                    validating: true,
                    orderItemToEdit: undefined
                })
            }, 400);
        }
        else {
            this.setState({
                validating: true,
                orderItemToEdit: undefined
            })
        }
    }

    private toggleTabs = (selectedTab: string): void => {
        if (this.state.activeTab != selectedTab) {
            this.setState({
                activeTab: selectedTab
            });
            if (this.state.orderDetail) {
                this.props.history.replace('/orders/edit/' + this.state.orderDetail.id + '/' + selectedTab);
            }
        }
    }

    private updateOrderShipping = (updatedOrderDetail: OrdersStore.OrderDetail): void => {
        if (this.state.orderDetail && this.props.clientState.client) {
            this.props.asyncActions.changeOrderShippingAsync(this.props.clientState.client, updatedOrderDetail)
                .then(result => {
                    this.setState({
                        orderDetail: result
                    })
                },
                    err => {
                    }
                )
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        orderDatesState: state.orderDates,
        ordersState: state.orders,
        shippingOptionsState: state.shippingOptions,
        userState: state.user
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrderDatesStore.actionCreators,
            OrdersStore.actionCreators,
            ShippingOptionsStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestOrderDetailAsync: (client: ClientStore.Client, id: number) => dispatch(OrdersStore.actionCreators.requestOrderDetail(client, id)),
            changeOrderCustomerAsync: (client: ClientStore.Client, orderDetail: OrdersStore.OrderDetail, customer: CustomerAccount) => dispatch(OrdersStore.actionCreators.changeOrderCustomer(client, orderDetail, customer)),
            changeOrderShippingAsync: (client: ClientStore.Client, orderDetail: OrdersStore.OrderDetail) => dispatch(OrdersStore.actionCreators.changeOrderShipping(client, orderDetail)),
            saveOrderDetailAsync: (client: ClientStore.Client, orderDetail: OrdersStore.OrderDetail) => dispatch(OrdersStore.actionCreators.saveOrderDetail(client, orderDetail))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditOrder as any);