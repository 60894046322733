import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import { Client } from '../../store/Client';
import { OrderDetail } from '../../store/Orders';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import { ShippingOption } from '../../store/ShippingOptions';
import { DropShipAddress } from '../../common/AddressTypes';
import DropShipAddressForm from '../dropship-address-form/DropShipAddressForm';
import DropShipAddressSelector from '../dropship-address-selector/DropShipAddressSelector';
import Checkbox from '../checkbox/Checkbox';
import FormResult from '../form-result/FormResult';
import $ from 'jquery';

import './AddDropShipAddress.scss';

// ----------------
// PROPS 

const applicationState = {
    messageState: {} as MessageStore.MessageState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
};

interface AddDropShipAddressAsyncActions {
}

interface AddDropShipAddressOwnProps {
    isOpen: boolean;
    client: Client;
    orderDetail: OrderDetail;
    shippingOption: ShippingOption | null | undefined;
    onDismiss: (dropShipAddress: DropShipAddress | null) => void;
}

type AddDropShipAddressProps =
    AddDropShipAddressOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & AddDropShipAddressAsyncActions;

// ----------------
// LOCAL STATE

interface AddDropShipAddressState {
    submitting: boolean;
    retrieving: boolean;
    retrievingAddresses: boolean;
    retrievingCountries: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
    actions: FieldWrapper.OptionValue[];
    selectedAction: number;
    animationComplete: boolean;
    submitNewAddressRequested: boolean;
    submitExistingAddressRequested: boolean;
    editAddress: DropShipAddress | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateAddDropShipAddressForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (formValues.action === 0) {
        errors.action = 'Select action';
    }
    return errors;
}

class AddDropShipAddress extends React.PureComponent<AddDropShipAddressProps, AddDropShipAddressState> {

    // ----------------
    // VARIABLES

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', overflow: 'hidden' },
        visibleOverflow: { height: 'auto', overflow: 'visible' }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR
    constructor(props: AddDropShipAddressProps, state: AddDropShipAddressState) {
        super(props);
        this.state = {
            submitting: false,
            retrieving: false,
            retrievingAddresses: false,
            retrievingCountries: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            actions: this.getActions(),
            selectedAction: this.props.orderDetail.dropShipAddress ? 3 : 0,
            animationComplete: false,
            submitNewAddressRequested: false,
            submitExistingAddressRequested: false,
            editAddress: this.props.orderDetail.dropShipAddress
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: AddDropShipAddressProps) => {
        if (this.receivedErrorMessage()) {
            if (this.state.retrievingCountries || this.state.submitting) {
                this.props.onDismiss(null);
            }
        }
        else if (this.state.submitting) {
            if (this.receivedValidationError()) {
                let formResult: string | null = this.getFormResult();
                setTimeout(() => {
                    this.props.actions.clearMessage();
                    this.setState({
                        submitting: false,
                        submitNewAddressRequested: false,
                        changesRejected: true,
                        formResult: formResult
                    });
                })
            }
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        switch (this.state.selectedAction) {
            case 1:
                this.setState({
                    submitExistingAddressRequested: true,
                    submitNewAddressRequested: false
                });
                break;
            case 2:
            case 3:
                this.setState({
                    submitExistingAddressRequested: false,
                    submitNewAddressRequested: true
                });
                break;
        }
    }

    public render = () => {
        return (
            <Modal id="add-dropship-address" isOpen={this.props.isOpen}
                onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(null)} className={(this.state.retrieving || this.state.submitting || this.state.changesAccepted) ? "disabled" : ""}>
                    Drop Ship Address
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className="action-bar pl-3 pr-4 text-center">
                                <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                    <Field name="action" type="text" label="" component={FieldWrapper.renderRadioButtonField}
                                        options={this.state.actions} onChange={this.onSelectAction}
                                        disabled={this.state.retrieving || this.state.submitting || this.state.changesAccepted} />
                                    <button type="submit" ref={this.submitButton}>Submit</button>
                                </form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0">
                                <motion.div animate={this.state.selectedAction === 1 ? "visible" : "hidden"} initial={this.state.selectedAction === 1 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                                    <Container className="component-wrapper">
                                        <DropShipAddressSelector activated={this.state.selectedAction === 1}
                                            client={this.props.client}
                                            billto={this.props.orderDetail.billto}
                                            addressId={this.props.orderDetail.dropShipAddress ? this.props.orderDetail.dropShipAddress.id : undefined}
                                            orderId={this.props.orderDetail.id as number}
                                            shippingOption={this.props.shippingOption}
                                            submitRequested={this.state.submitExistingAddressRequested}
                                            onSubmit={this.onAddressSelectorSubmit} 
                                            onSubmitFail={this.onAddressSelectorSubmitFail}
                                            onSubmitSucceed={this.onAddressSelectorSubmitSucceed} />
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0">
                                <motion.div animate={this.state.selectedAction >= 2 ? this.state.animationComplete ? "visibleOverflow" : "visible" : "hidden"}
                                    initial={this.state.selectedAction >= 2 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}
                                    onAnimationComplete={this.onAddAddressRowChange}>
                                    <Container className="component-wrapper flush">
                                        <DropShipAddressForm activated={this.state.selectedAction >= 2}
                                            client={this.props.client}
                                            billto={this.props.orderDetail.billto}
                                            dropShipAddress={this.state.editAddress}
                                            orderId={this.props.orderDetail.id as number}
                                            shippingOption={this.props.shippingOption}
                                            onRetrievingCountries={this.onRetrievingCountries}
                                            submitRequested={this.state.submitNewAddressRequested}
                                            onSubmit={this.onAddAddressFormSubmit}
                                            onSubmitFail={this.onAddAddressFormSubmitFail}
                                            onSubmitSucceed={this.onAddAddressFormSubmitSucceed} />
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>                    
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={this.submitAddDropShipAddressForm}
                                    disabled={this.state.retrievingAddresses || this.state.retrievingCountries || this.state.retrieving || this.state.submitting || this.state.changesAccepted}>
                                    {this.state.submitting ? "Working..." : "Save"}                                    
                                </Button>
                                <Button color="link" onClick={() => this.props.onDismiss(null)}
                                    disabled={this.state.retrievingAddresses || this.state.retrievingCountries || this.state.retrieving || this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private getActions = (): FieldWrapper.OptionValue[] => {
        let actions: FieldWrapper.OptionValue[] = [];
        if (this.props.orderDetail.dropShipAddress) {
            actions.push({ label: 'Edit Current', value: 3 });
            actions.push({ label: 'Use Another', value: 1 });
            actions.push({ label: 'Add New', value: 2 })
        }
        else {
            actions.push({ label: 'Use Existing Address', value: 1 });
            actions.push({ label: 'Add New Address', value: 2 });
        }
        return actions;
    }

    private getFormResult = (): string | null => {
        let formResult: string | null = null;
        if (this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) {
                formResult = this.props.messageState.message.text || 'Form contains invalid entries';
            }
            else if (this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) {
                formResult = 'Changes saved';
            }
        }
        return formResult;
    }

    private initializeForm = (): void => {
        let selectedAction: number = this.props.orderDetail.dropShipAddress ? 3 : 0;
        this.setState({
            submitting: false,
            retrieving: false,
            retrievingAddresses: false,
            retrievingCountries: this.state.retrievingCountries,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            actions: this.getActions(),
            selectedAction: selectedAction,
            submitNewAddressRequested: false,
            submitExistingAddressRequested: false,
            editAddress: this.props.orderDetail.dropShipAddress
        });
        this.props.change("action", selectedAction);
    }

    private onAddAddressFormSubmit = (submitting: boolean): void => {
        this.setState({
            formResult: null,
            submitNewAddressRequested: false,
            submitting: submitting
        });
    }

    private onAddAddressFormSubmitFail = (): void => {
        this.setState({
            formResult: null,
            submitNewAddressRequested: false
        });
    }

    private onAddAddressFormSubmitSucceed = (newAddress: DropShipAddress): void => {
        if (this.state.submitting) {
            let formResult = this.getFormResult();
            this.props.actions.clearMessage();
            this.setState({
                changesAccepted: true,
                submitting: false,
                formResult: formResult
            });
            setTimeout(() => {
                this.props.onDismiss(newAddress);
            }, 1500);
        }
    }

    private onAddAddressRowChange = (): void => {
        setTimeout(() => {
            this.setState({
                animationComplete: this.state.selectedAction === 2 ? true : false
            });
        }, 400);
    }

    private onAddressSelectorSubmit = (submitting: boolean): void => {
        this.setState({
            formResult: null,
            submitExistingAddressRequested: false,
            submitting: submitting
        });
    }

    private onAddressSelectorSubmitFail = (): void => {
        this.setState({
            formResult: null,
            submitExistingAddressRequested: false
        })
    }

    private onAddressSelectorSubmitSucceed = (newAddress: DropShipAddress): void => {
        if (this.state.submitting) {
            let formResult = this.getFormResult();
            this.props.actions.clearMessage();
            this.setState({
                changesAccepted: true,
                submitting: false,
                formResult: formResult
            });
            setTimeout(() => {
                this.props.onDismiss(newAddress);
            }, 1500);
        }
    }

    private onRetrievingAddresses = (retrieving: boolean): void => {
        this.setState({
            retrievingAddresses: retrieving
        });
    }

    private onRetrievingCountries = (retrieving: boolean): void => {
        this.setState({
            retrievingCountries: retrieving
        });
    }

    private onSelectAction = (event: React.ChangeEvent): void => {
        let selectedValue = event.target.getAttribute("value");
        if (selectedValue) {
            let optionValue = parseInt(selectedValue || '0');
            if (optionValue != this.state.selectedAction) {
                this.setState({
                    selectedAction: optionValue,
                    editAddress: optionValue === 3 ? this.props.orderDetail.dropShipAddress : undefined
                });
            }
        }
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private resetForm = (): void => {
        this.setState({
            submitting: false,
            retrieving: false,
            retrievingAddresses: false,
            retrievingCountries: false,
            changesRejected: false,
            formResult: null,
            selectedAction: 0,
            editAddress: undefined
        })
        this.props.reset();
    }

    private submitAddDropShipAddressForm = (): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
        }
    };
}

export default connect<{}, {}, AddDropShipAddressOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'addDropShipAddressForm',
    validate: validateAddDropShipAddressForm,
    enableReinitialize: true
})(AddDropShipAddress as any));
