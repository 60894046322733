import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as CategoriesStore from '../../store/Categories';
import * as CollectionsStore from '../../store/Collections';
import * as MessageStore from '../../store/Message';
import * as OrderDatesStore from '../../store/OrderDates';
import * as ProductsStore from '../../store/Products';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { Filter, Query, QueryResult } from 'material-table';
import { Button, Container, Col, ListGroup, ListGroupItem, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { FilterEx } from '../../common/ExtendedTypes';
import { TableViewType } from '../../enums/TableViewType';
import Loader from '../loader/Loader';
import ImageLoader from '../image-loader/ImageLoader';
import SearchBox from '../search-box/SearchBox';
import { convertJSDateToString, convertSQLDateToString } from '../../common/DateConverter';
import debounce from 'lodash.debounce';
import $ from 'jquery';

import './FindProduct.scss'

// ----------------
// PROPS

const applicationState = {
    categoriesState: {} as CategoriesStore.CategoriesState,
    collectionsState: {} as CollectionsStore.CollectionsState,
    orderDatesState: {} as OrderDatesStore.OrderDatesState,
    productsState: {} as ProductsStore.ProductsState,
    messageState: {} as MessageStore.MessageState
}

const actionCreators = {
    actions: Object.assign({}, CategoriesStore.actionCreators, CollectionsStore.actionCreators, OrderDatesStore.actionCreators, ProductsStore.actionCreators, MessageStore.actionCreators)
};

interface FindProductAsyncActions {
    asyncActions: {
        requestProductsAsync: (clientCode: ClientCode, query: Query<any>, view: TableViewType) => Promise<QueryResult<any>>;
    }
}

interface FindProductOwnProps {
    client: Client;
    isOpen: boolean;
    onDismiss: (productId: string | null | undefined) => void;
}

type FindProductProps =
    FindProductOwnProps
    & InjectedFormProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & FindProductAsyncActions;

// ----------------
// LOCAL STATE

interface FindProductState {
    initialized: boolean;
    submitting: boolean;
    selected: boolean;
    filtered: boolean;
    filterFlag: boolean;
    animationComplete: boolean;
    earliestAvailable: Date | null | undefined;
    collectionOptions: FieldWrapper.OptionValue[];
    selectedCollection: CollectionsStore.Collection | null | undefined;
    collectionFlag: boolean;
    selectedCategory: CategoriesStore.Category | null | undefined;
    categoryFlag: boolean;
    expandedCategories: string[];
    selectedCategories: string[];
    selectedPage: number;
    pageCount: number;
    clearSearch: boolean;
    activeQuery: Query<ProductsStore.Product> | null | undefined;
    queryResults: ProductsStore.Product[];
    selectedIndex: number | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateFindProductForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    return errors;
}

class FindProduct extends React.PureComponent<FindProductProps, FindProductState> {

    // ----------------
    // VARIABLES

    public findProductFormVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', overflow: 'hidden' },
        visibleOverflow: { height: 'auto', overflow: 'visible' }
    }

    public categoriesDropdown: any;
    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: FindProductProps, state: FindProductState) {
        super(props);
        this.state = {
            initialized: (this.props.categoriesState.categories && this.props.collectionsState.collections) ? true : false,
            submitting: false,
            selected: false,
            filtered: false,
            filterFlag: false,
            animationComplete: false,
            earliestAvailable: undefined,
            collectionOptions: this.getCollectionOptions(),
            selectedCollection: undefined,
            collectionFlag: false, 
            selectedCategory: undefined,
            categoryFlag: false,
            expandedCategories: [],
            selectedCategories: [],
            selectedPage: 0,
            pageCount: 0,
            clearSearch: false,
            activeQuery: undefined,
            queryResults: [],
            selectedIndex: undefined
        };
        this.categoriesDropdown = React.createRef();
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        setTimeout(() => {
            this.ensureDataFetched();
        }, 800);
    }

    public componentDidUpdate = (prevProps: FindProductProps) => {
        if (this.props.categoriesState.categories && this.props.collectionsState.collections && this.props.orderDatesState.orderDates && !this.state.initialized) {
            this.setState({
                initialized: true,
                collectionOptions: this.getCollectionOptions(),
                selectedCollection: undefined
            });
        }
    }

    public componentWillUnmount = () => {
    }

    public handleFormSubmit = (values: any): void => {
        this.setState({
            submitting: true
        });
        let query: Query<ProductsStore.Product> = this.getQuery(values, true);
        this.getProducts(query);
    }

    public render = () => {
        return (
            <Modal id="find-product" isOpen={this.props.isOpen} onOpened={this.initializeForm} onClosed={() => this.resetForm(true)}>
                <ModalHeader toggle={() => this.cancelFind()} className={(this.state.submitting || this.state.selected) ? "disabled" : ""}>
                    Find Product
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Loader isLoading={!this.state.initialized} isChild={true} />
                        <motion.div animate={this.state.initialized ? "visible" : "hidden"} initial={"hidden"}
                            variants={this.findProductFormVariants} transition={{ duration: 0.75 }}>
                            <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                <Row className="search-bar">
                                    <Col className="search-input pl-0" xs={10}>
                                        <SearchBox autoFocus={true} onChange={this.onSearch} clearInput={this.state.clearSearch} />
                                    </Col>
                                    <Col className="search-actions pl-0 pr-0" xs={2}>
                                        <Button color="link" disabled={this.state.submitting} onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.toggleFilterPanel(e)}>
                                            <label>Filter</label>
                                            {this.state.filterFlag && (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-up">
                                                    <polyline points="18 15 12 9 6 15"></polyline>
                                                </svg>
                                            )}
                                            {!this.state.filterFlag && (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-down">
                                                    <polyline points="6 9 12 15 18 9"></polyline>
                                                </svg>
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="search-filters">
                                    <Col className="collapsible-row pl-0 pr-0">
                                        <motion.div animate={this.state.filterFlag ? this.state.animationComplete ? "visibleOverflow" : "visible" : "hidden"}
                                            variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }} onAnimationComplete={this.onSearchFiltersRowChange}>
                                            <Container>
                                                <Row>
                                                    <Col className="pl-0" xs={4}>
                                                        <Field name="earliestAvailable" label="Available" component={FieldWrapper.renderDatePickerField}
                                                            selectedDate={this.state.earliestAvailable} onSelectDate={this.onChangeEarliestAvailable}
                                                            minDate={this.props.orderDatesState.orderDates? this.props.orderDatesState.orderDates.currentDate : undefined}
                                                            disabled={this.state.selected} />
                                                    </Col>
                                                    <Col className="pr-0" xs={8}>
                                                        <Field name="collection" label="Collection" component={FieldWrapper.renderDropdownField}
                                                            // defaultValue={this.getDefaultCollectionOption()}
                                                            disabled={this.state.selected}
                                                            open={this.state.collectionFlag}
                                                            options={this.state.collectionOptions}
                                                            onSelectOption={this.onSelectCollection}
                                                            onToggle={this.onToggleCollection} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="pl-0 sku" xs={4}>
                                                        <Field name="sku" type="text" label="SKU" component={FieldWrapper.renderTextField}
                                                            maxLength={25} autoCapitalize={false} onChange={this.onChangeStyleOrSKU}
                                                            disabled={this.state.selected} />
                                                    </Col>
                                                    <Col className="pr-0" xs={8}>
                                                        <Field name="category" label="Category" component={FieldWrapper.renderCategoryListField}
                                                            defaultValue={this.getDefaultCategoryOption()}
                                                            disabled={this.state.selected}
                                                            open={this.state.categoryFlag}
                                                            categories={this.props.categoriesState.categories}
                                                            expandedCategories={this.state.expandedCategories}
                                                            selectedCategories={this.state.selectedCategories}
                                                            onSelectCategory={this.onSelectCategory}
                                                            onToggle={this.onToggleCategory}
                                                            onToggleCategory={this.onToggleCategoryTreeItem}
                                                            reference={this.categoriesDropdown} />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </motion.div>
                                    </Col>
                                </Row>
                                <button type="submit" ref={this.submitButton}>SUBMIT</button>
                            </form>
                            <Row className="query-results">
                                <Col className="pl-0 pr-0">
                                    <Loader isLoading={this.state.submitting} isChild={true} />
                                    {this.state.activeQuery && !(this.state.submitting || this.hasFindResults()) && (
                                        <label className="no-results">No matching products found</label>
                                    )}
                                    {this.state.activeQuery && this.hasFindResults() && (
                                        <ListGroup flush>
                                            {this.state.queryResults.map((product: ProductsStore.Product, index: number) => (
                                                <ListGroupItem key={"product" + index} className={(index % 2 === 1 ? "odd" : "") + (index === this.state.selectedIndex ? " selected" : "")}>
                                                    <div className="product-container">
                                                        <div className="action">
                                                            <Button color="secondary" onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.selectProduct(e, index, product)}
                                                                disabled={this.state.selected} title={'View Product'}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-search">
                                                                    <circle cx="11" cy="11" r="8"></circle>
                                                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                                </svg>
                                                            </Button>
                                                        </div>
                                                        <div className="description">
                                                            <div className="image">
                                                                <div className="product-image">
                                                                    <div className="product-image-stretcher">
                                                                        <div className="product-image-canvas">
                                                                            <ImageLoader url={product.imageUrl} isLoading={false} noFadeIn={false} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text">
                                                                <label className="short-description">{product.shortDescription}</label>
                                                                <label className="style-name">{product.style.name}</label>
                                                                <div className="two-column-label">
                                                                    <label className="price">${product.wholesale.toFixed(2)}</label>
                                                                    <label className="color">
                                                                        {product.colorCount > 1 && (
                                                                            <span>{product.colorCount} colors</span>
                                                                        )}
                                                                    </label>
                                                                </div>
                                                                {product.availability && (
                                                                    <label className="availability">{product.availability}</label>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup>
                                    )}
                                </Col>
                            </Row>
                            <Row className="query-pager">
                                <Col className="pl-0 pr-0 pt-2">
                                    <Button color="link" disabled={this.state.selectedPage <= 1 || this.state.submitting || this.state.selected} onClick={this.showFirst}>
                                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                        </svg>
                                    </Button>
                                    <Button color="link" disabled={this.state.selectedPage <= 1 || this.state.submitting || this.state.selected} onClick={this.showPrev}>
                                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                        </svg>
                                    </Button>
                                    <label className="page-number">{this.state.selectedPage} of {this.state.pageCount}</label>
                                    <Button color="link" disabled={this.isLast() || this.state.submitting || this.state.selected} onClick={this.showNext}>
                                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                        </svg>
                                    </Button>
                                    <Button color="link" disabled={this.isLast() || this.state.submitting || this.state.selected} onClick={this.showLast}>
                                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                        </svg>
                                    </Button>
                                </Col>
                            </Row>
                        </motion.div>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={this.cancelFind} disabled={this.state.submitting}>
                                    Close
                                </Button>
                                <Button color="link" onClick={this.clearFilters} disabled={this.state.submitting}>
                                    Clear
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private cancelFind = (): void => {
        this.props.actions.clearProductsQuery();
        this.props.onDismiss(null);
    }

    private clearFilters = (): void => {
        this.resetForm(false);
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        })
    }

    private ensureDataFetched = (): void => {
        if (this.props.client) {
            this.props.actions.requestCollections(this.props.client.code);
            setTimeout(() => {
                if (this.props.client) {
                    this.props.actions.requestCategories(this.props.client.code);
                }
            }, 800);
        }
        else {
            setTimeout(() => {
                this.ensureDataFetched();
            }, 250);
        }
    }

    private ensureSelectedCategoryVisible = (): void => {
        let dropdownElement: any = $(this.categoriesDropdown.current);
        if (this.categoriesDropdown.current) {
            let menuJQueryElement: any = $(this.categoriesDropdown.current.menuRef.current);
            if (menuJQueryElement.length > 0) {
                let selectedElement: any = menuJQueryElement.find('.Mui-selected');
                if (selectedElement.length > 0) {
                    selectedElement[0].scrollIntoView();
                }
            }
        }
    }

    private findCategory = (groupId: string): CategoriesStore.Category | null => {
        let category: CategoriesStore.Category | null = null;
        if (this.props.categoriesState && this.props.categoriesState.categories) {
            for (let n: number = 0; n < this.props.categoriesState.categories.length; n++) {
                category = this.findCategoryOrChild(groupId, this.props.categoriesState.categories[n]);
                if (category) {
                    break;
                }
            }
        }
        return category;
    }

    private findCategoryOrChild = (groupId: string, category: CategoriesStore.Category): CategoriesStore.Category | null => {
        let categoryOrChild: CategoriesStore.Category | null = null;
        if (category.groupId === groupId) {
            categoryOrChild = category;
        }
        else {
            for (let n: number = 0; n < category.items.length; n++) {
                categoryOrChild = this.findCategoryOrChild(groupId, category.items[n]);
                if (categoryOrChild) {
                    break;
                }
            }
        }
        return categoryOrChild;
    }

    private findProduct = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }

    private getCategoryIds = (category: CategoriesStore.Category | null): string[] => {
        let categoryIds: string[] = [];
        if (category) {
            categoryIds.push(category.groupId);
            for (let n: number = 0; n < category.items.length; n++) {
                let subcategoryIds: string[] = this.getCategoryIds(category.items[n]);
                if (subcategoryIds.length > 0) {
                    categoryIds = categoryIds.concat(subcategoryIds);
                }
            }
        }
        return categoryIds;
    }

    private getCollectionOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        options.push({ label: 'All', value: '0' });
        if (this.props.collectionsState.collections) {
            for (let n: number = 0; n < this.props.collectionsState.collections.length; n++) {
                let collection: CollectionsStore.Collection = this.props.collectionsState.collections[n];
                options.push({ label: collection.displayName, value: collection.code });
            }
        }
        return options;
    }

    private getDefaultCategoryOption = (): string => {
        return "All";
    }

    private getDefaultCollectionOption = (): string => {
        return "All";
    }

    private getQuery = (values: any, newQuery: boolean): Query<ProductsStore.Product> => {
        let query: Query<ProductsStore.Product> | null | undefined = newQuery ? undefined :
            this.state.activeQuery;
        if (!query) {
            query = {
                page: 0,
                pageSize: 10,
                totalCount: 0,
                filters: this.getQueryFilters(values)
            } as Query<ProductsStore.Product>;
        }
        return query;
    }

    private getQueryFilters = (values: any): Filter<ProductsStore.Product>[] => {
        let filters: Filter<ProductsStore.Product>[] = [];
        if (values.sku) {
            filters.push({
                column: {
                    field: "style.code",
                    type: "string"
                },
                operator: "=",
                value: values.sku
            });
        }
        if (values.productName) {
            filters.push({
                column: {
                    field: "style.name",
                    type: "string"
                },
                operator: "=",
                value: values.productName
            });
        }
        if (this.state.selectedCollection) {
            filters.push({
                column: {
                    field: "season.code",
                    type: "string"
                },
                operator: "=",
                value: this.state.selectedCollection.code
            });
        }
        if (this.state.selectedCategory) {
            filters.push({
                column: {
                    field: "garmentType.code",
                    type: "string"
                },
                operator: "=",
                value: this.state.selectedCategories.join(",")
            });
        }
        if (this.state.earliestAvailable) {
            let fe: FilterEx = {
                column: {
                    field: "earliestAvailable",
                    type: "date"
                },
                operator: "=",
                operatorOverride: "<=",
                value : this.state.earliestAvailable
            } as FilterEx;
            filters.push(fe);
        }
        return filters;
    }

    private getProducts = (query: Query<ProductsStore.Product>): void => {
        let view: TableViewType = this.props.productsState.products ? this.props.productsState.products.view : TableViewType.Grid;
        this.props.asyncActions.requestProductsAsync(this.props.client.code, query, view)
            .then(result => {
                this.scrollGridResults();
                this.setState({
                    submitting: false,
                    activeQuery: query,
                    selectedPage: result.totalCount > 0 ? result.page + 1 : 0,
                    queryResults: result.data,
                    pageCount: Math.ceil(result.totalCount / query.pageSize)
                })
            },
            err => {
                this.props.onDismiss(null);
            });
    }

    private hasFindResults = (): boolean => {
        return this.state.activeQuery ? this.state.pageCount > 0 ? true : false : false;
    }

    private initializeForm = (): void => {
        this.setState({
            submitting: true,
            filtered: false,
            filterFlag: false,
            earliestAvailable: undefined,
            clearSearch: false,
            selectedPage: 0,
            activeQuery: undefined,
            queryResults: [],
            selectedIndex: undefined
        });

        this.props.change('sku', null);
        this.props.change('collection', 'All');

        setTimeout(() => {
            this.getProducts(this.getQuery([], true));
        })
    }

    private isCurrent = (page: number): boolean => {
        return page === this.state.selectedPage ? true : false;
    }

    private isLast = (): boolean => {
        return this.state.selectedPage >= this.state.pageCount;
    }

    private isNext = (page: number): boolean => {
        return page > this.state.selectedPage ? true : false;
    }

    private onChangeEarliestAvailable = (date: Date): void => {
        this.setState({
            earliestAvailable: date
        });
        this.props.change("earliestAvailable", date);
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        })
    }

    private onChangeStyleOrSKU = debounce((): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }, 400, { leading: false, trailing: true });

    private onSearch = (search: string | null | undefined): void => {
        let query: Query<ProductsStore.Product> | null | undefined = this.state.activeQuery;
        if (!query) {
            query = {
                page: 0,
                pageSize: 10,
                totalCount: 0,
                search: search || ''
            } as Query<ProductsStore.Product>;
        }
        else {
            query.search = search || '';
            query.page = 0;
        }
        this.setState({
            submitting: true
        });
        this.getProducts(query);
    }

    private onSearchFiltersRowChange = (): void => {
        setTimeout(() => {
            this.setState({
                animationComplete: this.state.filterFlag
            });
        }, 400);
    }

    private onSelectCategory = (event: React.ChangeEvent<{}>, nodeIds: string[]): void => {
        event.persist();
        let iconClicked: boolean = $(event.target).closest(".MuiTreeItem-iconContainer").length > 0;
        if (!iconClicked) {
            let selectedCategory: CategoriesStore.Category | null = this.findCategory($.isArray(nodeIds) ? nodeIds[0] : nodeIds);
            this.props.change("category", selectedCategory ? selectedCategory.qualifiedName : 'All');
            this.setState({
                categoryFlag: false,
                selectedCategory: selectedCategory,
                selectedCategories: this.getCategoryIds(selectedCategory)
            });
            setTimeout(() => {
                if (this.submitButton.current) {
                    this.submitButton.current.click();
                }
            })
        } 
    }

    private onSelectCollection = (selectedOption: FieldWrapper.OptionValue): void => {
        if (this.props.collectionsState.collections) {
            let collection: CollectionsStore.Collection[] = this.props.collectionsState.collections.filter((c: CollectionsStore.Collection) => {
                return c.code === selectedOption.value;
            });
            this.props.change('collection', selectedOption.label);
            this.setState({
                selectedCollection: collection[0]
            });
        }
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        })
    }

    private onToggleCategory = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {
        event.preventDefault();
        let categoryFlag: boolean = !this.state.categoryFlag;
        if (categoryFlag) {
            this.props.change('category', 'Select...');
        }
        this.setState({
            categoryFlag: categoryFlag
        });
        if (categoryFlag) {
            if (this.state.selectedCategory) {
                setTimeout(() => {
                    this.ensureSelectedCategoryVisible();
                }, 100);
            }
        }
        else {
            setTimeout(() => {
                if (this.state.selectedCategory) {
                    this.props.change('category', this.state.selectedCategory.qualifiedName);
                }
            });
        }
    }

    private onToggleCategoryTreeItem = (event: React.ChangeEvent<{}>, nodeIds: string[]): void => {
        event.persist();
        let iconClicked: boolean = $(event.target).closest(".MuiTreeItem-iconContainer").length > 0;
        if (iconClicked) {
            setTimeout(() => {
                this.setState({
                    expandedCategories: nodeIds
                });
            });
        }
    }

    private onToggleCollection = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {
        event.preventDefault();
        let collectionFlag: boolean = !this.state.collectionFlag;
        if (collectionFlag) {
            this.props.change('collection', 'Select...')
        }
        this.setState({
            collectionFlag: collectionFlag
        });
        if (!collectionFlag) {
            setTimeout(() => {
                if (this.state.selectedCollection) {
                    this.props.change('collection', this.state.selectedCollection.displayName);
                }
            });
        }
    }

    private resetForm = (closeFilter: boolean): void => {
        this.props.reset();
        this.props.change('collection', 'All');
        this.setState({
            submitting: false,
            filterFlag: closeFilter ? false : this.state.filterFlag,
            animationComplete: false,
            earliestAvailable: undefined,
            selectedCollection: undefined,
            selectedCategory: undefined,
            selectedCategories: [],
            expandedCategories: [],
            activeQuery: undefined
        });
    }

    private scrollGridResults = (): void => {
        let queryResults: any = $('#find-product .query-results');
        if (queryResults.length > 0) {
            queryResults[0].scrollTop = 0;
        }
    }

    private selectProduct = (event: React.KeyboardEvent | React.MouseEvent, index: number, product: ProductsStore.Product): void => {
        event.preventDefault();
        this.setState({
            selected: true,
            selectedIndex: index
        });
        setTimeout(() => {
            this.props.onDismiss(product.sanitizedId);
        }, 400);
    }

    private showFirst = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.stopPropagation();
        if (this.state.activeQuery) {
            this.setState({
                submitting: true,
                selectedIndex: undefined
            });
            let query: Query<ProductsStore.Product> = this.state.activeQuery;
            query.page = 0;
            this.getProducts(query);
        }
    }

    private showLast = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.stopPropagation();
        if (this.state.activeQuery) {
            this.setState({
                submitting: true,
                selectedIndex: undefined
            });
            let query: Query<ProductsStore.Product> = this.state.activeQuery;
            query.page = this.state.pageCount - 1;
            this.getProducts(query);
        }
    }

    private showNext = (event: React.MouseEvent): void => {
        event.stopPropagation();
        if (this.state.activeQuery && this.state.activeQuery.page < this.state.pageCount) {
            this.setState({
                submitting: true,
                selectedIndex: undefined
            });
            let query: Query<ProductsStore.Product> = this.state.activeQuery;
            query.page++;
            this.getProducts(query);
        }
    }

    private showPrev = (event: React.MouseEvent): void => {
        event.stopPropagation();
        if (this.state.activeQuery && this.state.activeQuery.page > 0) {
            this.setState({
                submitting: true,
                selectedIndex: undefined
            });
            let query: Query<ProductsStore.Product> = this.state.activeQuery;
            query.page--;
            this.getProducts(query);
        }
    }

    private toggleFilterPanel = (event: React.MouseEvent | React.KeyboardEvent): void => {
        event.preventDefault();
        let filterFlag: boolean = this.state.filterFlag ? false : true;
        this.setState({
            filterFlag: filterFlag
        });
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        categoriesState: state.categories,
        collectionsState: state.collections,
        orderDatesState: state.orderDates,
        productsState: state.products,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            CategoriesStore.actionCreators,
            CollectionsStore.actionCreators,
            OrderDatesStore.actionCreators,
            ProductsStore.actionCreators,
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestProductsAsync: (clientCode: ClientCode, query: Query<any>, view: TableViewType) => dispatch(ProductsStore.actionCreators.requestProducts(clientCode, query, view))
        }
    };
}

export default connect<{}, {}, FindProductOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'findProductForm',
    validate: validateFindProductForm,
    enableReinitialize: true
})(FindProduct as any));
