import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button } from 'reactstrap';
import { motion } from 'framer-motion';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import { Col, Container, Row } from 'reactstrap';
import MessagePanel from '../message-panel/MessagePanel';
import TechSupport from '../tech-support/TechSupport';

import './Error.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
};

type ErrorProps =
    RouteComponentProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators;        // ... plus action creators we've requested

// ----------------
// STATE

interface ErrorState {
    isReloading: boolean
}

class Error extends React.PureComponent<ErrorProps, ErrorState> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: ErrorProps) {
        super(props);
        this.state = {
            isReloading: false
        };        
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        if (!this.props.messageState.message) {
            this.props.history.push('/');
        }
    }

    public render = () => {
        return (
            <React.Fragment>
                <motion.div id="errorPage" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className="align-items-center justify-content-center">
                            <Col className="error-message" xs="auto">
                                <MessagePanel message={this.props.messageState.message} />
                                <TechSupport isBordered={true} isErrorMessage={this.isErrorMessage()} client={null} />
                                {!this.isWarningMessage() && (
                                    <div className="button-bar">
                                        <Button color="primary" onClick={this.reload} disabled={this.state.isReloading}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                                <polyline points="1 4 1 10 7 10"></polyline>
                                                <polyline points="23 20 23 14 17 14"></polyline>
                                                <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                            </svg>
                                            Reload
                                        </Button>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
            </React.Fragment>
        )
    }

    // ----------------
    // HELPERS

    private isErrorMessage = (): boolean => {
        let isError: boolean = true;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType !== MessageStore.MessageType.ERROR) {
                isError = false;                
            }
        }
        return isError;
    }

    private isWarningMessage = (): boolean => {
        let isWarning: boolean = true;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType !== MessageStore.MessageType.WARNING) {
                isWarning = false;
            }
        }
        return isWarning;
    }

    private reload = () => {
        this.setState({
            isReloading: true
        });
        setTimeout(() => {
            window.location.reload(true);
        }, 500);
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, MessageStore.actionCreators), dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Error as any);


//export default withRouter(connect(
//    (state: ApplicationState) => {
//        return {
//            messageState: state.message
//        }
//    },
//    (dispatch: Dispatch) => {                   // Selects which action creators are merged into the component's props
//        return {
//            actions: bindActionCreators(Object.assign({}, MessageStore.actionCreators), dispatch)
//        }
//    }
//)(Error as any));