import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import * as CategoriesStore from '../../store/Categories';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import { ClientCode } from '../../enums/ClientCode';
/*import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';*/
import { Drawer } from '@material-ui/core';
import { Container, Row, Col } from 'reactstrap';
import Loader from '../loader/Loader';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import TreeItemContentProps from '@material-ui/lab/TreeItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import $ from 'jquery';

import './ProductCategories.scss';

// ----------------
// PROPS

interface ProductCategoriesApplicationState {
    categoriesState?: CategoriesStore.CategoriesState,
    clientState?: ClientStore.ClientState,
    messageState?: MessageStore.MessageState
};

interface ProductCategoriesActionCreators {
    actions?: {
        clearMessage: () => void;
        broadcastMessage: (message: MessageStore.Message) => void;
        requestCategories: (clientCode: ClientCode) => void;
    }
}

interface ProductCategoriesOwnProps {
    onDismiss: () => void;
    onSelectCategory: (category: CategoriesStore.Category) => void;
    selectedCategoryName?: string | null | undefined;
}

type ProductCategoriesProps =
    ProductCategoriesOwnProps
    & ProductCategoriesApplicationState     // ... state we've requested from Redux store
    & ProductCategoriesActionCreators;      // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface ProductCategoriesState {
    isOpen: boolean;
    isLoaded: boolean;
    expandedCategories: string[];
    selectedCategories: string[];
}

class ProductCategories extends React.PureComponent<ProductCategoriesProps, ProductCategoriesState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductCategoriesProps, state: ProductCategoriesState) {
        super(props);
        this.state = {
            isOpen: false,
            isLoaded: false,
            expandedCategories: this.props.selectedCategoryName ? this.getExpandedCategoriesFromProps() : [],
            selectedCategories: this.props.selectedCategoryName ? this.getSelectedCategoriesFromProps() : []
        };
        this.ensureDataFetched();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        if (this.props.categoriesState && this.props.categoriesState.categories && !this.state.isLoaded) {
            this.setState({
                isLoaded: true
            });
        }
    }

    public componentDidUpdate = (prevProps: ProductCategoriesProps) => {
        if (this.props.categoriesState && this.props.categoriesState.categories) {
            let updateCategory = this.props.selectedCategoryName != prevProps.selectedCategoryName;
            let expandedCategories: string[] = updateCategory ? this.getExpandedCategoriesFromProps() : this.state.expandedCategories;
            let selectedCategories: string[] = updateCategory ? this.getSelectedCategoriesFromProps() : this.state.selectedCategories;
            if (!this.state.isLoaded || updateCategory) {
                this.setState({
                    isLoaded: true,
                    expandedCategories: expandedCategories,
                    selectedCategories: selectedCategories
                });
            }
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <Drawer id="product-categories" variant="temporary" anchor={"right"}
                open={this.state.isOpen}>
                <Container>
                    <Row className="drawer-header">
                        <Col>
                            <h2>Categories</h2>
                            <button type="button" className="close" onClick={this.dismiss}>
                                <span>&times;</span>
                            </button>
                        </Col>
                    </Row>
                    <Row className="drawer-body">
                        <Col>
                            <Loader isLoading={!this.state.isLoaded} isChild={true} />
                            {this.state.isLoaded && this.props.categoriesState && this.props.categoriesState.categories && this.props.categoriesState.categories.length > 0 && (
                                <div className="categories">
                                    <TreeView
                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                        defaultExpandIcon={<ChevronRightIcon />}
                                        expanded={this.state.expandedCategories}
                                        onNodeToggle={this.handleTreeItemToggle}
                                        selected={this.state.selectedCategories}
                                        onNodeSelect={this.handleTreeItemSelect}>

                                        {this.props.categoriesState.categories.map((category: CategoriesStore.Category, categoryIndex: number) => (
                                            <TreeItem nodeId={category.groupId} key={"category-" + categoryIndex} label={category.name} className="category">

                                                {category.items.map((subcategory1: CategoriesStore.Category, subcategory1Index: number) => (
                                                    <TreeItem nodeId={subcategory1.groupId} key={"subcategory1-" + subcategory1Index} label={subcategory1.name} className="subcategory">

                                                        {subcategory1.items.map((subcategory2: CategoriesStore.Category, subcategory2Index: number) => (
                                                            <TreeItem nodeId={subcategory2.groupId} key={"subcategory2-" + subcategory2Index} label={subcategory2.name} className="subcategory">

                                                                {subcategory2.items.map((subcategory3: CategoriesStore.Category, subcategory3Index: number) => (
                                                                    <TreeItem nodeId={subcategory3.groupId} key={"subcategory3-" + subcategory3Index} label={subcategory3.name} className="subcategory">
                                                                    </TreeItem>
                                                                ))}

                                                            </TreeItem>
                                                        ))}

                                                    </TreeItem>
                                                ))}

                                            </TreeItem>
                                        ))}


                                    </TreeView>
                                </div>
                            )}
                            {this.state.isLoaded && this.props.categoriesState && this.props.categoriesState.categories && this.props.categoriesState.categories.length === 0 && (
                                <div className="undefined-categories">
                                    <label>No categories defined</label>
                                </div>
                            )}
                        </Col>
                    </Row>           
                </Container>
            </Drawer>
        );
    }

    // ----------------
    // HELPERS

    private dismiss = (): void => {
        this.props.onDismiss();
    }

    private ensureDataFetched = (): void => {
        if (this.props.clientState && this.props.clientState.client) {
            if (this.props.actions) {
                this.props.actions.requestCategories(this.props.clientState.client.code);
            }
        }
        else {
            setTimeout(() => {
                this.ensureDataFetched();
            }, 100);
        }
    }

    private findCategory = (groupId: string): CategoriesStore.Category | null => {
        let category: CategoriesStore.Category | null = null;
        if (this.props.categoriesState && this.props.categoriesState.categories) {
            for (let n: number = 0; n < this.props.categoriesState.categories.length; n++) {
                category = this.findCategoryOrChild(groupId, this.props.categoriesState.categories[n]);
                if (category) {
                    break;
                }
            }
        }
        return category;
    }

    private findCategoryByName = (name: string): CategoriesStore.Category | null => {
        let category: CategoriesStore.Category | null = null;
        if (this.props.categoriesState && this.props.categoriesState.categories) {
            for (let n: number = 0; n < this.props.categoriesState.categories.length; n++) {
                category = this.findCategoryOrChildByName(name, this.props.categoriesState.categories[n]);
                if (category) {
                    break;
                }
            }
        }
        return category;
    }

    private findCategoryOrChild = (groupId: string, category: CategoriesStore.Category): CategoriesStore.Category | null => {
        let categoryOrChild: CategoriesStore.Category | null = null;
        if (category.groupId === groupId) {
            categoryOrChild = category;
        }
        else {
            for (let n: number = 0; n < category.items.length; n++) {
                categoryOrChild = this.findCategoryOrChild(groupId, category.items[n]);
                if (categoryOrChild) {
                    break;
                }
            }
        }
        return categoryOrChild;
    }

    private findCategoryOrChildByName = (name: string, category: CategoriesStore.Category): CategoriesStore.Category | null => {
        let categoryOrChild: CategoriesStore.Category | null = null;
        if (category.name === name) {
            categoryOrChild = category;
        }
        else {
            for (let n: number = 0; n < category.items.length; n++) {
                categoryOrChild = this.findCategoryOrChildByName(name, category.items[n]);
                if (categoryOrChild) {
                    break;
                }
            }
        }
        return categoryOrChild;
    }

    private findChildCategory = (name: string, category: CategoriesStore.Category | null): CategoriesStore.Category | null => {
        let child: CategoriesStore.Category | null = null;
        if (category && category.items.length > 0) {
            let matches: CategoriesStore.Category[] = category.items.filter((i: CategoriesStore.Category) => {
                return i.name === name;
            });
            if (matches.length > 0) {
                child = matches[0];
            }
        }
        return child;
    }

    private getExpandedCategoriesFromProps = (): string[] => {
        let expandedCategories: string[] = [];
        if (this.props.selectedCategoryName && this.props.categoriesState && this.props.categoriesState.categories) {
            let categoryNames: string[] = this.props.selectedCategoryName.split(' > ');
            if (categoryNames.length > 1) {
                let matches: CategoriesStore.Category[] = this.props.categoriesState.categories.filter((c: CategoriesStore.Category) => {
                    return c.name === categoryNames[0];
                });                
                if (matches.length > 0) {
                    let category: CategoriesStore.Category | null = matches[0];
                    expandedCategories.push(category.groupId);
                    for (let n: number = 1; n < (categoryNames.length - 1); n++) {
                        category = this.findChildCategory(categoryNames[n], category);
                        if (category) {
                            expandedCategories.push(category.groupId);
                        }
                        else {
                            break;
                        }
                    }
                }
            }
        }
        return expandedCategories;
    }

    private getSelectedCategoriesFromProps = (): string[] => {
        let selectedCategories: string[] = [];        
        if (this.props.selectedCategoryName && this.props.categoriesState && this.props.categoriesState.categories) {
            let categoryNames: string[] = this.props.selectedCategoryName.split(' > ');
            if (categoryNames.length > 0) {
                let matches: CategoriesStore.Category[] = this.props.categoriesState.categories.filter((c: CategoriesStore.Category) => {
                    return c.name === categoryNames[0];
                })
                if (matches.length > 0) {
                    let category: CategoriesStore.Category | null = matches[0];
                    for (let n: number = 1; n < categoryNames.length; n++) {
                        let temp: CategoriesStore.Category | null = this.findChildCategory(categoryNames[n], category);
                        if (temp) {
                            category = temp as CategoriesStore.Category;
                        }
                        else {
                            break;
                        }
                    }
                    selectedCategories.push(category.groupId);
                }
            }
        }
        return selectedCategories;
    }

    private handleTreeItemSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]): void => {
        event.persist();
        let iconClicked: boolean = $(event.target).closest(".MuiTreeItem-iconContainer").length > 0;
        if (!iconClicked) {
            let selectedCategory: CategoriesStore.Category | null = this.findCategory($.isArray(nodeIds) ? nodeIds[0] : nodeIds);
            if (selectedCategory) {
                this.setState({
                    selectedCategories: nodeIds
                });                
                this.props.onSelectCategory(selectedCategory);
            }
        }
    }

    private handleTreeItemToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]): void => {
        event.persist();
        let iconClicked: boolean = $(event.target).closest(".MuiTreeItem-iconContainer").length > 0;
        if (iconClicked) {
            setTimeout(() => {
                this.setState({
                    expandedCategories: nodeIds
                });
            });
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        categoriesState: state.categories,
        clientState: state.client,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            CategoriesStore.actionCreators,
            MessageStore.actionCreators), dispatch)
    };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: ProductCategoriesOwnProps) {
    return ownProps;
}

export default connect < {}, {}, ProductCategoriesOwnProps>(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ProductCategories);




