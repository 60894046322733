import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import { CustomerName } from '../../common/AccountTypes';
import { Address, Country } from '../../common/AddressTypes';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Dropdown } from 'reactstrap';

import './BuyerAccountPicker.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
}

interface BuyerAccountPickerOwnProps {
    placeholder: string;
    buyerAccounts: CustomerName[];
    initialValue?: CustomerName | null | undefined;
    onSelectBuyerAccount: (selected: CustomerName | null) => void;
    nameOnly?: boolean;
    disabled?: boolean;
}

type BuyerAccountPickerProps =
    BuyerAccountPickerOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface BuyerAccountPickerState {
    displayShipToNumber: boolean;
    isDropdownOpen: boolean;
    selectedBuyerAccount: CustomerName | undefined;
}

class BuyerAccountPicker extends React.PureComponent<BuyerAccountPickerProps, BuyerAccountPickerState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: BuyerAccountPickerProps, state: BuyerAccountPickerState) {
        super(props);
        this.state = {
            displayShipToNumber: this.props.clientState.client ? this.props.clientState.client.rules.displayShipToNumber : true,
            selectedBuyerAccount: this.props.initialValue || undefined,
            isDropdownOpen: false
        };        
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: BuyerAccountPickerProps) => {
        if (this.props.clientState.client && !prevProps.clientState.client) {
            if (!this.props.clientState.client.rules.displayShipToNumber) {
                this.setState({
                    displayShipToNumber: false
                })
            }
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <Dropdown id="buyer-account-picker" isOpen={this.state.isDropdownOpen} toggle={this.toggleDropdownMenu}>
                <DropdownToggle disabled={this.props.disabled ? true : false}>
                    <div className="dropdown-label">{ this.state.selectedBuyerAccount ? this.formatName(this.state.selectedBuyerAccount) : this.props.placeholder.toString() }</div>
                    <div className="dropdown-toggler">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-down">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-up">
                            <polyline points="18 15 12 9 6 15"></polyline>
                        </svg>
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    {this.props.buyerAccounts.map((buyerAccount: CustomerName, buyerAccountIndex: number) => (
                        <DropdownItem key={"buyerAccount" + buyerAccountIndex} itemProp={buyerAccount.number} className={(buyerAccountIndex % 2 === 1 && !this.props.nameOnly) ? "odd" : ""}
                            onClick={() => this.onSelectBuyerAccount(buyerAccount)}>
                            {!this.props.nameOnly && (
                                <div className="two-column">
                                    <div className="billto">
                                        {this.state.displayShipToNumber && (
                                            <span>{buyerAccount.number}</span>
                                        )}
                                        {!this.state.displayShipToNumber && (
                                            <span>{buyerAccount.billto}</span>
                                        )}
                                    </div>
                                    <div className="description">
                                        {buyerAccount.locationDescription && !this.state.displayShipToNumber && (
                                            <span>{buyerAccount.locationDescription}</span>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="customer-name">
                                <span>{buyerAccount.nameOnly}</span>
                            </div>
                            {!this.props.nameOnly && buyerAccount.address && (
                                <div className="customer-location">
                                    {buyerAccount.address.street1 && (
                                        <label className="street">{buyerAccount.address.street1}</label>
                                    )}
                                    {buyerAccount.address.street2 && (
                                        <label className="street">{buyerAccount.address.street2}</label>
                                    )}
                                    {buyerAccount.address.street3 && (
                                        <label className="street">{buyerAccount.address.street3}</label>
                                    )}
                                    {(buyerAccount.address.city || buyerAccount.address.state.code) && (
                                        <label className="city-state" dangerouslySetInnerHTML={this.formatCityStateZip(buyerAccount)}></label>
                                    )}
                                    {buyerAccount.address.country.code && this.isInternational(buyerAccount.address.country) && (
                                        <label className="country">{buyerAccount.address.country.name}</label>
                                    )}
                                </div>
                            )}
                        </DropdownItem>
                    ))} 
                </DropdownMenu>
            </Dropdown>
        );
    }

    // ----------------
    // HELPERS

    private formatCityStateZip = (buyerAccount: CustomerName): { __html: string } | undefined => {
        let output: string | undefined = undefined;
        if (buyerAccount.address) {
            if (buyerAccount.address.city) {
                output = buyerAccount.address.city;
                if (buyerAccount.address.state) {
                    output = output + ', ' + buyerAccount.address.state.code;
                    if (buyerAccount.address.postalCode) {
                        output = output + " &nbsp;" + buyerAccount.address.postalCode;
                    }
                }
            }
            else if (buyerAccount.address.state) {
                output = buyerAccount.address.state.code;
                if (buyerAccount.address.postalCode) {
                    output = output + " &nbsp;" + buyerAccount.address.postalCode;
                }
            }
        }
        return output ? { __html: output } : undefined;
    }

    private formatName = (buyerAccount: CustomerName | undefined): string => {
        let output: string = '';
        if (buyerAccount) {
            output = buyerAccount.nameOnly;
            if (buyerAccount.locationDescription) {
                output = output + '  -  ' + buyerAccount.locationDescription;
            }
        }
        return output;
    }

    private isInternational = (country: Country): boolean => {
        return country.code !== 'US' ? true : false;
    }

    private onSelectBuyerAccount = (selected: CustomerName | null): void => {
        this.setState({
            selectedBuyerAccount: selected || undefined
        });
        setTimeout(() => {
            this.props.onSelectBuyerAccount(selected);
        });
    }

    private toggleDropdownMenu = () => {
        let isOpen: boolean = this.state.isDropdownOpen;
        if (isOpen) {
            this.setState({
                isDropdownOpen: false
            });
        }
        else {
            this.setState({
                isDropdownOpen: true,
                selectedBuyerAccount: undefined
            })
            this.props.onSelectBuyerAccount(null);
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators
        ), dispatch)
    };
}

export default connect<{}, {}, BuyerAccountPickerOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(BuyerAccountPicker as any);
