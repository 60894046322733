import * as React from 'react';
import { CustomerContact } from '../../common/AccountTypes';
import { ClientCode } from '../../enums/ClientCode';
import { Container, Col, Row } from 'reactstrap';

import './ContactForm.scss';


// ----------------
// PROPS

interface ContactFormProps {
    contact: CustomerContact;
    clientCode: ClientCode;
}

// ----------------
// LOCAL STATE

interface ContactFormState { }

class ContactForm extends React.PureComponent<ContactFormProps, ContactFormState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: ContactFormProps, state: ContactFormState) {
        super(props);
        this.state = {};
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <div className="contact-form">
                <Container>
                    <Row>
                        <Col className="contact-info text-left pl-0 pr-0 pr-xl-3" xs={6} md={12} xl={6}>
                            <label className="name">{this.props.contact.name}</label>
                            {this.props.contact.title && (
                                <label className="title">{this.props.contact.title}</label>
                            )}
                            {this.props.contact.email && (
                                <a href={"mailto:" + this.props.contact.email}>
                                    <label className="email">{this.props.contact.email}</label>
                                </a>
                            )}
                        </Col>
                        <Col className="contact-role text-left pl-0 pl-xl-3 pr-0" xs={6} md={12} xl={6}>
                            {this.props.clientCode === ClientCode.GTN && (
                                <div className="contact-receives">
                                    <ul>
                                        <li>
                                            <div className="indicator">
                                                {this.props.contact.receivesAccountingTransactions && (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-check">
                                                        <polyline points="20 6 9 17 4 12"></polyline>
                                                    </svg>
                                                )}
                                                {!this.props.contact.receivesAccountingTransactions && (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                    </svg>
                                                )}
                                            </div>
                                            <label className={this.props.contact.receivesAccountingTransactions ? "" : "unassigned"}>Accounting Transactions</label>
                                        </li>
                                        <li>
                                            <div className="indicator">
                                                {this.props.contact.receivesNonAccountingTransactions && (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-check">
                                                        <polyline points="20 6 9 17 4 12"></polyline>
                                                    </svg>
                                                )}
                                                {!this.props.contact.receivesNonAccountingTransactions && (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                    </svg>
                                                )}
                                            </div>
                                            <label className={this.props.contact.receivesNonAccountingTransactions ? "" : "unassigned"}>Non-Accounting Transactions</label>
                                        </li>
                                        <li>
                                            <div className="indicator">
                                                {this.props.contact.receivesMonthlyStatements && (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-check">
                                                        <polyline points="20 6 9 17 4 12"></polyline>
                                                    </svg>
                                                )}
                                                {!this.props.contact.receivesMonthlyStatements && (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                    </svg>
                                                )}
                                            </div>
                                            <label className={this.props.contact.receivesMonthlyStatements ? "" : "unassigned"}>Monthly Statements</label>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    // ----------------
    // HELPERS

}

// ----------------
// EXPORT

export default ContactForm;
