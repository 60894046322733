import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as CustomersStore from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import * as OrderItemsStore from '../../store/OrderItems';
import * as ProductsStore from '../../store/Products';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { ColorOption, Dimension } from '../../common/ProductTypes';
import { Button, Col, Container, Row } from 'reactstrap';
import { toTitleCase } from '../../common/StringFormatter';
import Loader from '../loader/Loader';
import ProductForm from '../product-form/ProductForm';
import AddOrderItem from '../add-order-item/AddOrderItem';
import AddProductlistItem from '../add-productlist-item/AddProductlistItem';

import './ProductDetail.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    customersState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState,
    productsState: {} as ProductsStore.ProductsState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, ProductsStore.actionCreators)    
}

interface ProductDetailAsyncActions {
    asyncActions: {
        requestProductDetailAsync: (client: Client, id: string) => Promise<ProductsStore.ProductDetail | null | undefined>;
    }
}

interface MatchParams {
    id: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> { }

type ProductDetailProps =
    MatchProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators        // ... plus action creators we've requested
    & ProductDetailAsyncActions;

// ----------------
// LOCAL STATE

interface ProductDetailState {
    isVisible: boolean;
    isLoadError: boolean;
    isLoadWarning: boolean;
    isReloading: boolean;
    addingOrderItem: boolean;
    addingProductlistItem: boolean;
    newOrderItem: OrderItemsStore.NewOrderItem | null | undefined;
    productDetail: ProductsStore.ProductDetail | null | undefined;
}

class ProductDetail extends React.PureComponent<ProductDetailProps, ProductDetailState> {
 
    // ----------------
    // VARIABLES

    public productDetailVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR
    constructor(props: ProductDetailProps, state: ProductDetailState) {
        super(props);
        this.state = {
            isVisible: false,
            isLoadError: false,
            isLoadWarning: false,
            isReloading: false,
            addingOrderItem: false,
            addingProductlistItem: false,
            newOrderItem: undefined,
            productDetail: null
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.ensureDateFetched();
    }

    public componentDidUpdate = (prevProps: ProductDetailProps) => {
        if (!this.state.isVisible) {
            if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
                this.setState({
                    isReloading: false
                });
                setTimeout(() => {
                    this.setState({
                        isLoadError: true,
                        isLoadWarning: this.receivedWarningMessage()
                    })
                }, 400);
            }
        }
        else if (this.isNewColor(prevProps) && !this.state.isReloading) {
            this.reloadDetail();
        }
    }

    public componentWillUnmount = () => {}

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={(!this.state.isVisible && !this.state.isLoadError) || this.state.isReloading} />
                <motion.div id="productDetailPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"}
                    variants={this.productDetailVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className="title with-return-link justify-content-start">
                            <Col className="pl-0 pr-0">
                                <h2 className="page-title">&nbsp;</h2>
                                <NavLink to={this.getReturnPath}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-left">
                                        <polyline points="15 18 9 12 15 6"></polyline>
                                    </svg>
                                    <span>Back</span>
                                </NavLink>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            {this.state.productDetail && (
                                <ProductForm productDetail={this.state.productDetail}
                                    onAddToOrder={this.addToOrder}
                                    onAddToProductList={this.addToProductlist}
                                    onChangeColor={this.onChangeColor}
                                    onChangeDimension={this.onChangeDimension}
                                    onClose={this.goBack} />
                            )}
                        </Row>
                        {this.props.clientState.client && this.state.productDetail && this.state.newOrderItem && (
                            <React.Fragment>
                                <AddOrderItem isOpen={this.state.addingOrderItem}
                                    client={this.props.clientState.client}
                                    customer={this.props.customersState.customerDetail}
                                    newOrderItem={this.state.newOrderItem}
                                    productDetail={this.state.productDetail}
                                    onDismiss={this.hideAddOrderItem}
                                    onGoBack={() => this.hideAddOrderItem(-1)}
                                    onReviewOrder={(id: number) => this.hideAddOrderItem(id)} />
                                <AddProductlistItem isOpen={this.state.addingProductlistItem}
                                    client={this.props.clientState.client}
                                    customer={this.props.customersState.customerDetail}
                                    newOrderItem={this.state.newOrderItem}
                                    productDetail={this.state.productDetail}
                                    onDismiss={this.hideAddProductlistItem}
                                    onGoBack={() => this.hideAddProductlistItem(-1)} />
                            </React.Fragment>
                        )}
                    </Container>
                </motion.div>
                {this.state.isLoadError && (
                    <Container>
                        <Row className="justify-content-center">
                            <Col className={"pt-4 page-error" + (this.state.isLoadWarning ? " warning" : "")} xs={"auto"}>
                                <Button color="primary" onClick={this.reloadDetail}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                        <polyline points="1 4 1 10 7 10"></polyline>
                                        <polyline points="23 20 23 14 17 14"></polyline>
                                        <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                    </svg>
                                    Reload
                                </Button>
                                <Button color="link" onClick={this.goBack}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-left">
                                        <polyline points="15 18 9 12 15 6"></polyline>
                                    </svg>
                                    Go Back
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                )}
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private addToOrder = (orderItem: OrderItemsStore.NewOrderItem): void => {
        this.setState({
            addingOrderItem: true,
            newOrderItem: orderItem
        })
    }

    private addToProductlist = (orderItem: OrderItemsStore.NewOrderItem): void => {
        this.setState({
            addingProductlistItem: true,
            newOrderItem: orderItem
        });
    }

    private ensureDateFetched = (): void => {
        let productId: string = this.props.match.params.id;
        if (this.productInStore(productId)) {
            this.setState({
                isVisible: true,
                isLoadError: false,
                isLoadWarning: false,
                isReloading: false,
                productDetail: this.props.productsState.productDetail
            });
        }
        else {
            let clientCode: ClientCode = this.props.clientState.client ? this.props.clientState.client.code : ClientCode.Undefined;
            if (clientCode != ClientCode.Undefined) {
                this.getProductDetail(productId, clientCode);
            }
            else {
                setTimeout(() => {
                    this.ensureDateFetched();
                }, 200);
            }
        }
    }

    private getProductDetail = (productId: string, clientCode: ClientCode): void => {
        this.props.asyncActions.requestProductDetailAsync(this.props.clientState.client as Client, productId)
            .then(result => {
                this.setState({
                    isVisible: true,
                    isLoadError: false,
                    isLoadWarning: false,
                    isReloading: false,
                    productDetail: result
                })
            },
            err => {
            });
    }

    private getReturnPath = (): string => {
        let path: string = '/products';
        if (this.props.history.location.state) {
            let state: any = this.props.history.location.state;
            if (state.from) {
                path = state.from;
            }
        }
        return path;
    }

    private goBack = () => {
        this.props.history.push('/products');
    }

    private hideAddOrderItem = (id: number | null | undefined): void => {
        this.setState({
            addingOrderItem: false
        })
        if (id) {
            setTimeout(() => {
                if (id < 0) {
                    this.goBack();
                }
                else {
                    this.props.history.push('/orders/edit/' + id.toString() + '/detail');
                }
            }, 400);
        }
    }

    private hideAddProductlistItem = (id: number | null | undefined): void => {
        this.setState({
            addingProductlistItem: false
        });
        if (id) {
            setTimeout(() => {
                if (id < 0) {
                    this.goBack();
                }
                else {
                    this.props.history.push('/productlists/edit/' + id.toString());
                }
            })
        }
    }

    private isNewColor = (prevProps: ProductDetailProps): boolean => {
        let newColor: boolean = false;
        if (prevProps.productsState.productDetail) {
            newColor = prevProps.productsState.productDetail.sanitizedId != decodeURIComponent(this.props.match.params.id);
        }
        return newColor;
    }

    private onChangeColor = (colorOption: ColorOption): void => {
        this.props.history.push('/products/detail/' + encodeURIComponent(colorOption.sanitizedProductId));
    }

    private onChangeDimension = (dimension: Dimension): void => {
        this.props.history.push('/products/detail/' + encodeURIComponent(dimension.sanitizedProductId));
    }

    private productInStore = (sanitizedId: string): boolean => {
        let inStore: boolean = false;
        if (this.props.productsState.productDetail) {
            inStore = (this.props.productsState.productDetail.sanitizedId === sanitizedId);
        }
        return inStore;
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private reloadDetail = (): void => {
        this.props.actions.clearMessage();
        let productId: string = this.props.match.params.id;
        let clientCode: ClientCode = this.props.clientState.client ? this.props.clientState.client.code : ClientCode.Undefined;
        setTimeout(() => {
            this.setState({
                isReloading: true,
                isLoadError: false,
                isLoadWarning: false
            });
            this.getProductDetail(productId, clientCode);
        }, 200);
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        customersState: state.customers,
        messageState: state.message,
        ordersState: state.orders,
        productsState: state.products
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            ProductsStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestProductDetailAsync: (client: Client, id: string) => dispatch(ProductsStore.actionCreators.requestProductDetail(client, id))
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetail as any);
