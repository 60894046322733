import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as OrderItemsStore from '../../store/OrderItems';
import { OrderItem } from '../../store/OrderItems';
import { ClientCode } from '../../enums/ClientCode';
import cloneDeep from 'lodash/cloneDeep';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    orderItemsState: {} as OrderItemsStore.OrderItemsState
};

const actionCreators = {
    actions: Object.assign({}, OrderItemsStore.actionCreators)
}

interface OrderUpdaterOwnProps { }

type OrderUpdaterProps =
    OrderUpdaterOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;     // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface OrderUpdaterState {
    queue: OrderItem[];
}

class OrderUpdater extends React.PureComponent<OrderUpdaterProps, OrderUpdaterState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderUpdaterProps) {
        super(props);
        this.state = {
            queue: []
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: OrderUpdaterProps) => {
        if (this.props.clientState && this.props.clientState.client) {
            if (this.props.orderItemsState && this.props.orderItemsState.orderItem) {
                let orderItem: OrderItem = cloneDeep(this.props.orderItemsState.orderItem);
                this.props.actions.queueOrderItemComplete();
                this.updateOrderItemQuantities(orderItem);
            }
            else if (prevProps.orderItemsState.isUpdatingQuantities && !this.props.orderItemsState.isUpdatingQuantities) {
                this.updateOrderItemQuantities(null);
            }
        }
    }

    public componentWillUnmount = () => {}

    public render = () => {
        return null;
    }

    // ----------------
    // HELPERS

    private addOrderItemToQueue = (orderItem: OrderItem): void => {
        let queuedItems: OrderItem[] = cloneDeep(this.state.queue);
        let index: number = queuedItems.findIndex((oi: OrderItem) => {
            return oi.clientCode === orderItem.clientCode && oi.id === orderItem.id;
        });
        if (index >= 0) {
            queuedItems.splice(index, 1, orderItem);
        }
        else {
            queuedItems.push(orderItem);
        }
        this.setState({
            queue: queuedItems
        });
    }

    private updateOrderItemQuantities = (orderItem: OrderItem | null): void => {
        if (this.props.clientState.client && !this.props.orderItemsState.isUpdatingQuantities) {
            if (orderItem || this.state.queue.length > 0) {
                let queuedItems: OrderItem[] = cloneDeep(this.state.queue);                
                let orderId: number = orderItem ? orderItem.orderId : queuedItems[0].orderId;

                let holdItems: OrderItem[] = queuedItems.filter((oi: OrderItem) => {
                    return oi.orderId !== orderId ? true : false;
                });
                this.setState({
                    queue: holdItems
                });

                let saveItems: OrderItem[] = queuedItems.filter((oi: OrderItem) => {
                    return oi.orderId === orderId ? true : false;
                });
                if (orderItem) {
                    saveItems.push(orderItem);
                }
                saveItems = this.validateOrderItems(saveItems);
                if (saveItems.length > 0) {
                    this.props.actions.updateOrderItemQuantities(this.props.clientState.client.code, orderId, saveItems);
                }
            }
        }
        else if (orderItem) {
            this.addOrderItemToQueue(orderItem);
        }
    }

    private validateOrderItems = (orderItems: OrderItem[]): OrderItem[] => {
        let validatedItems: OrderItem[] = [];
        if (this.props.clientState.client) {
            let clientCode: ClientCode = this.props.clientState.client.code;
            validatedItems = orderItems.filter((oi: OrderItem) => {
                return oi.clientCode === clientCode;
            })
        }
        return validatedItems;
    }
}

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        orderItemsState: state.orderItems
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            OrderItemsStore.actionCreators
        ), dispatch)
    };
}

export default connect<{}, {}, OrderUpdaterOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(OrderUpdater as any);

