import * as React from 'react';
import { Order } from '../../store/Orders';

import './OrderStatus.scss';

// ----------------
// PROPS

interface OrderStatusProps {
    order: Order;
}

class OrderStatus extends React.PureComponent<OrderStatusProps> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderStatusProps) {
        super(props);
    }

    // ----------------
    // METHODS

    public render = () => {
        return (
            <div className="order-status">
                <span className="icon">
                    {this.props.order.orderStatus === 'Submitted' && this.props.order.orderNumberERP && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather-check-circle">
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                        </svg>
                    )}
                </span>
                <span className="text">{this.props.order.orderStatus}</span>
            </div>
       );
    }

    // ----------------
    // HELPERS

}

// ----------------
// EXPORT

export default OrderStatus;
