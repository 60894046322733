import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from "react-router-dom";
import fetchIntercept, { FetchInterceptorResponse } from 'fetch-intercept';
import { ApplicationState } from '../../store';
import * as UserStore from '../../store/User';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    userState: {} as UserStore.UserState
};

type HTTPInterceptorProps =
    RouteComponentProps
    & typeof applicationState;     // ... state we've requested from Redux store

class HTTPInterceptor extends React.PureComponent<HTTPInterceptorProps> implements fetchIntercept.FetchInterceptor {

    // ----------------
    // VARIABLES

    public unregisterFetchListener: (() => void) | undefined;
    public test: string = "My test property";

    // ----------------
    // CONSTRUCTOR

    constructor(props: HTTPInterceptorProps) {
        super(props);
    }

    // -------------------------
    // FETCH INTERCEPTOR METHODS

    public request = (url: string, config: any): Promise<any> | any[] => {
        if (this.props.userState.user && this.props.userState.user.isAuthenticated) {
            let authToken: string = "Bearer " + this.props.userState.user.token;
            config = config || {};
            config.headers = Object.assign({}, (config.headers || {}), { 'Authorization': authToken });
        }
        return [url, config];
    }

    public requestError = (error: any): Promise<any> => {
        return Promise.reject(error);
    }

    public response = (response: FetchInterceptorResponse): FetchInterceptorResponse => {
        if (response.status === 401) {
            setTimeout(() => {
                this.props.history.push('/login');
            })
        }
        return response;        
    }

    public responseError = (error: any): Promise<any> => {
        return Promise.reject(error);
    }

    // -------------------------
    // PURECOMPONENT METHODS

    public componentDidMount = () => {
        this.unregisterFetchListener = fetchIntercept.register(this);
    }

    public componentWillUnmount = () => {
        if (this.unregisterFetchListener) {
            this.unregisterFetchListener();
        }
    }

    public render = () => {
        return null;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        userState: state.user
    };
}

export default withRouter(connect(
    mapStateToProps
)(HTTPInterceptor as any));


//export default withRouter(connect(
//    (state: ApplicationState) => {
//        return {
//            userState: state.user
//        }
//    }
//)(HTTPInterceptor as any));