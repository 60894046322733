import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import { OrderDetail } from '../../store/Orders';
import { OrderItem } from '../../store/OrderItems';
import * as OrderInvoiceStore from '../../store/OrderInvoice';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { motion } from 'framer-motion';
import OrderHistory from '../order-history/OrderHistory';
import OrderItemSummary from '../order-item-summary/OrderItemSummary';
import DeleteOrderItem from '../delete-order-item/DeleteOrderItem';
import { formatNumberAsCurrency, formatNumberAsCurrencyForCountry } from '../../common/CurrencyFormatter';
import { formatNumberWithCommas } from '../../common/NumberFormatter';

import './OrderSummary.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    orderInvoiceState: {} as OrderInvoiceStore.OrderInvoiceState
}

const actionCreators = {
    actions: Object.assign({}, OrderInvoiceStore.actionCreators)
}

interface OrderSummaryOwnProps {
    orderDetail: OrderDetail | null | undefined;
    onViewProduct: (orderInvoiceItem: OrderInvoiceStore.OrderInvoiceItem) => void;
    submitting?: boolean;
    isOpenOrder: boolean;
}

type OrderSummaryProps =
    OrderSummaryOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface OrderSummaryState {
    viewingOrderHistory: boolean;
    orderToViewHistory: OrderDetail | null;
    orderItemToDelete: OrderItem | null;
}

class OrderSummary extends React.PureComponent<OrderSummaryProps, OrderSummaryState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderSummaryProps, state: OrderSummaryState) {
        super(props);
        this.state = {
            viewingOrderHistory: false,
            orderToViewHistory: null,
            orderItemToDelete: null
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: OrderSummaryProps) => { }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <Container className="order-summary">
                <Row>
                    <Col className="pl-0 pr-0">
                        <div className="info-panel explanatory">
                            <div className="title-bar">
                                <div className="heading">
                                    <label>Summary</label>
                                </div>
                                {this.props.orderDetail && (
                                    <div className="button-bar">
                                        <Button color="link" onClick={this.showOrderHistory} disabled={this.props.submitting}>
                                            <label>View History</label>
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className="content">
                                <Container>
                                    <Row>
                                        <Col className="pl-0" xs={4}>
                                            <div className="total lines">
                                                <label className="name">Lines</label>
                                                <label className="value">{this.getLines()}</label>
                                            </div>
                                        </Col>
                                        <Col className="pl-0 pr-0" xs={8}>
                                            <div className="total price">
                                                <label className="name">Total</label>
                                                <label className="value">{this.getTotalPrice()}</label>
                                            </div>
                                            {this.props.orderInvoiceState.orderInvoice && this.props.orderInvoiceState.orderInvoice.discount > 0 && (
                                                <div className="discount-applied">
                                                    includes {this.getDiscountPercentage()}% discount
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pl-0 pr-0 item-breakdown">
                                            <Table size="sm" borderless>
                                                <thead>
                                                    <tr>
                                                        <th>&nbsp;</th>
                                                        <th>Units</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Not Embroidered</td>
                                                        <td>{this.getBreakdownUnits('NotEmbroidered')}</td>
                                                        <td>{this.getBreakdownPrice('NotEmbroidered')}</td>
                                                    </tr>
                                                    <tr className="underscored">
                                                        <td>Embroidered</td>
                                                        <td>{this.getBreakdownUnits('Embroidered')}</td>
                                                        <td>{this.getBreakdownPrice('Embroidered')}</td>
                                                    </tr>
                                                    <tr className="overscored">
                                                        <td>{this.getSubtotalLabel()}</td>
                                                        <td>{this.getBreakdownUnits('All')}</td>
                                                        <td>{this.getBreakdownPrice('All')}</td>
                                                    </tr>
                                                    {this.hasEmbroideryFees() && (
                                                        <tr className={this.hasDiscount() ? "" : "underscored"}>
                                                            <td>Embroidery Fees</td>
                                                            <td>&nbsp;</td>
                                                            <td>{this.getEmbroideryFees()}</td>
                                                        </tr>
                                                    )}
                                                    {this.hasDiscount() && (
                                                        <tr className="underscored">
                                                            <td>Discount</td>
                                                            <td>&nbsp;</td>
                                                            <td>({this.getDiscountAmount()})</td>
                                                        </tr>
                                                    )}
                                                    {this.hasSubtotal() && (
                                                        <tr className="overscored">
                                                            <td>Total</td>
                                                            <td>&nbsp;</td>
                                                            <td>{this.getTotalPrice()}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    {this.props.orderInvoiceState.orderInvoice && (
                                        <Row>
                                            <Col className="pl-0 pr-0">
                                                <OrderItemSummary orderInvoice={this.props.orderInvoiceState.orderInvoice} isOpenOrder={this.props.isOpenOrder}
                                                    onRemoveOrderItem={this.onDeleteOrderItem} orderItemToDelete={this.state.orderItemToDelete}
                                                    onViewProduct={this.props.onViewProduct} />
                                            </Col>
                                        </Row>
                                    )}
                                </Container>
                            </div>
                        </div>
                    </Col>
                </Row>
                {this.state.orderToViewHistory && (
                    <OrderHistory isOpen={this.state.viewingOrderHistory} order={this.state.orderToViewHistory} onDismiss={this.hideOrderHistory}
                        customerName={this.state.orderToViewHistory ? this.state.orderToViewHistory.shipToCustomer.nameOnly : ''} />
                )}
                {this.props.orderDetail && (
                    <DeleteOrderItem orderItemToDelete={this.state.orderItemToDelete}
                        clientCode={this.props.orderDetail.client.code}
                        onDelete={this.onDeleteOrderItemComplete}
                        onDismiss={this.hideDeleteOrderItem}
                        onConcurrencyError={this.onDeleteOrderItemNotAllowed} />
                )}
            </Container>
        );
    }

    // ----------------
    // HELPERS

    private getBreakdownPrice = (type: string) => {
        let netPrice: string = '';
        if (this.props.orderInvoiceState.orderInvoice) {
            let price: number = 0;
            switch (type) {
                case 'NotEmbroidered':
                    price = this.props.orderInvoiceState.orderInvoice.priceNotEmbroidered;
                    break;
                case 'Embroidered':
                    price = this.props.orderInvoiceState.orderInvoice.priceEmbroidered;
                    break;
                default:
                    price = this.props.orderInvoiceState.orderInvoice.price;
                    break;
            }
            netPrice = formatNumberAsCurrencyForCountry('US', price, true);
        }
        return netPrice;
    }

    private getBreakdownUnits = (type: string): string => {
        let unitCount: string = '';
        if (this.props.orderInvoiceState.orderInvoice) {
            let units: number = 0;
            switch (type) {
                case 'NotEmbroidered':
                    units = this.props.orderInvoiceState.orderInvoice.unitsNotEmbroidered;
                    break;
                case 'Embroidered':
                    units = this.props.orderInvoiceState.orderInvoice.unitsEmbroidered;
                    break;
                default:
                    units = this.props.orderInvoiceState.orderInvoice.units;
                    break;
            }                            
            unitCount = units > 0 ? formatNumberWithCommas(units) : '0';
        }
        return unitCount;
    }

    private getDiscountAmount = (): string => {
        let discountAmount: number = 0;
        if (this.props.orderInvoiceState.orderInvoice) {
            discountAmount = this.props.orderInvoiceState.orderInvoice.discountAmount;
        }
        return formatNumberAsCurrencyForCountry('US', discountAmount, true);
    }

    private getDiscountPercentage = (): string => {
        let discountPercentage: string = '';
        if (this.props.orderInvoiceState.orderInvoice) {
            discountPercentage = this.props.orderInvoiceState.orderInvoice.discount.toString();
        }
        return discountPercentage;
    }

    private getEmbroideryFees = (): string => {
        let embroideryFees: number = 0;
        if (this.props.orderInvoiceState.orderInvoice) {
            embroideryFees = this.props.orderInvoiceState.orderInvoice.embroideryFees;
        }
        return formatNumberAsCurrencyForCountry('US', embroideryFees, true);;
    }

    private getLines = (): string => {
        let lineCount: string = '';
        if (this.props.orderInvoiceState.orderInvoice) {
            lineCount = this.props.orderInvoiceState.orderInvoice.lines > 0 ?
                formatNumberWithCommas(this.props.orderInvoiceState.orderInvoice.lines) : '0';
        }
        return lineCount;
    }

    private getSubtotalLabel = (): string => {
        let subtotalLabel: string = "Total";
        if (this.props.orderInvoiceState.orderInvoice) {
            let p: number = this.props.orderInvoiceState.orderInvoice.price;
            let t: number = this.props.orderInvoiceState.orderInvoice.totalPrice;
            if (t != p) {
                subtotalLabel = "Subtotal";
            }
        }
        return subtotalLabel;
    }

    private getUnits = (): string => {
        let unitCount: string = '';
        if (this.props.orderInvoiceState.orderInvoice) {
            unitCount = this.props.orderInvoiceState.orderInvoice.units > 0 ?
                formatNumberWithCommas(this.props.orderInvoiceState.orderInvoice.units) : '0';
        }
        return unitCount;
    }

    private getTotalPrice = (): string => {
        let totalPrice: string = '';
        if (this.props.orderInvoiceState.orderInvoice) {
            totalPrice = formatNumberAsCurrencyForCountry('US', this.props.orderInvoiceState.orderInvoice.totalPrice, true);
        }
        return totalPrice;
    }

    private hasDiscount = (): boolean => {
        let hasDiscount: boolean = false;
        if (this.props.orderInvoiceState.orderInvoice) {
            hasDiscount = this.props.orderInvoiceState.orderInvoice.discountAmount > 0 ? true : false;
        }
        return hasDiscount;
    }

    private hasEmbroideryFees = (): boolean => {
        let hasFees: boolean = false;
        if (this.props.orderInvoiceState.orderInvoice) {
            hasFees = this.props.orderInvoiceState.orderInvoice.embroideryFees > 0 ? true : false;
        }
        return hasFees;
    }

    private hasSubtotal = (): boolean => {
        return this.getSubtotalLabel() === 'Subtotal';
    }

    private hideDeleteOrderItem = (): void => {
        this.setState({
            orderItemToDelete: null
        });
    }

    private hideOrderHistory = (): void => {
        this.setState({
            viewingOrderHistory: false,
            orderToViewHistory: null
        });
    }

    private onDeleteOrderItem = (orderInvoiceItem: OrderInvoiceStore.OrderInvoiceItem): void => {
        if (this.props.orderDetail) {
            // create partial order item (we don't need all properties populated for delete)        
            let orderItem: OrderItem = {
                id: orderInvoiceItem.orderItemId,
                canEmbroider: false,
                chaseDate: null,
                clientCode: this.props.orderDetail.client.code,
                color: { code: orderInvoiceItem.colorCode, name: '', displayName: '' },
                customerNumber: this.props.orderDetail.customerNumber,
                dimension: {
                    code: orderInvoiceItem.dimensionCode,
                    productId: orderInvoiceItem.productId,
                    sanitizedProductId: orderInvoiceItem.sanitizedProductId,
                    name: '',
                    inventoryDates: [],
                    sizes: [],
                    sortIndex: 0,
                    unitsAvailable: []
                },
                dimensionDescription: '',
                earliestAvailable: null,
                embroiderySpecifications: [],
                garmentType: { code: '', name: '', description: '' },
                gender: { code: '', name: '' },
                imageUrl: orderInvoiceItem.imageUrl,
                isEditable: true,
                notes: '',
                orderId: this.props.orderDetail.id as number,
                placementOptions: [],
                productId: orderInvoiceItem.productId,
                sanitizedProductId: orderInvoiceItem.sanitizedProductId,
                season: { code: '', name: '' },
                shortDescription: '',
                sizeDescription: '',
                style: { code: orderInvoiceItem.styleCode, name: orderInvoiceItem.styleName, sku: '' },
                totalPrice: orderInvoiceItem.price,
                totalUnits: orderInvoiceItem.quantity,
                wholesale: orderInvoiceItem.wholesale
            };
            this.setState({
                orderItemToDelete: orderItem
            });
        }
    }

    private onDeleteOrderItemComplete = (): void => {
        this.hideDeleteOrderItem();
    }

    private onDeleteOrderItemNotAllowed = (): void => { }

    private showOrderHistory = (): void => {
        if (this.props.orderDetail) {
            this.setState({
                viewingOrderHistory: true,
                orderToViewHistory: this.props.orderDetail
            });
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        orderInvoiceState: state.orderInvoice
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            OrderInvoiceStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, OrderSummaryOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(OrderSummary as any);
