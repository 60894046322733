import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import { User } from '../../store/User';
import * as UsersStore from '../../store/Users';
import { ClientCode } from '../../enums/ClientCode';
import { Button, Col, Container, Row } from 'reactstrap';
import Loader from '../loader/Loader';
import MaterialTable, { Column, Filter, Options, Query, QueryResult} from 'material-table';
import { BaseTableFilter } from '../../common/BaseTypes';
import { FilterEx } from '../../common/ExtendedTypes';
import TableFilter from '../table-filter/TableFilter';
import { TableFilterListItem } from '../table-filter/TableFilter';
import * as TableIcons from '../table-icons/TableIcons';
import UserCard from '../user-card/UserCard';
import DeleteUser from '../delete-user/DeleteUser';
import debounce from 'lodash.debounce';
import $ from 'jquery';

import './AdminUsers.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    usersState: {} as UsersStore.UsersState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, UsersStore.actionCreators)
};

interface AdminUsersAsyncActions {
    asyncActions: {
        requestUsersAsync: (clientCode: ClientCode, query: Query<any>) => Promise<QueryResult<any>>;
    }
}

type AdminUsersProps =
    RouteComponentProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & AdminUsersAsyncActions;

// ----------------
// LOCAL STATE

interface AdminUsersState {
    isVisible: boolean;
    isTableInitialized: boolean;
    isTableLoading: boolean;
    isTableError: boolean;
    columns: any[];
    operators: FilterEx[];
    options: Options<any>;
    pageOverride: number | undefined;
    rowCount: number | undefined;
    useStoredQuery: boolean;
    userToDelete: User | null;
}

// ----------------
// CONSTANTS

const USERS_PAGE_SIZE: number = 10;

class AdminUsers extends React.PureComponent<AdminUsersProps, AdminUsersState> {

    // ----------------
    // VARIABLES
    public tableElement: any;

    public adminUsersVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: AdminUsersProps, state: AdminUsersState) {
        super(props);
        this.state = {
            isVisible: false,
            isTableInitialized: false,
            isTableLoading: false,
            isTableError: false,
            columns: [
                {
                    title: "User",
                    field: "fullName",
                    cellStyle: { width: '80%' },
                    render: (data: User, type: "row") => <UserCard data={data} />
                },
                {
                    title: "Id",
                    field: "id",
                    type: "numeric",
                    align: "left",
                    cellStyle: { width: '100px' },
                    filterCellStyle: { width: '100px' },
                    headerCellStyle: { width: '100px'},
                    defaultSort: this.getStoredSortOrder("id"),
                    filterComponent: (props: BaseTableFilter) =>
                        <TableFilter columnDef={props.columnDef} filterType={"integer"} showOperators={false}
                            initialValue={this.getStoredFilter("id")}
                            initialOperator={this.getStoredFilterOperator("id")}
                            onFilterChanged={props.onFilterChanged}
                            onFilterOperatorChanged={this.onFilterOperatorChanged} />
                },
                {
                    title: "Role",
                    field: "description",
                    type: "string",
                    cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                    defaultSort: this.getStoredSortOrder("description"),
                    filterComponent: (props: BaseTableFilter) =>
                        <TableFilter columnDef={props.columnDef} filterType={"list"}
                            initialValue={this.getStoredFilterListItem("description") || { label: 'All', value: '0' }}
                            listItems={[
                                { label: 'All', value: '0' },
                                { label: 'Admin', value: '1' },
                                { label: 'CSR', value: '2' },
                                { label: 'Rep', value: '4' },
                                { label: 'Buyer', value: '8' }
                            ]}
                            onFilterChanged={props.onFilterChanged} />
                },
                {
                    title: "First Name",
                    field: "firstName",
                    type: "string",
                    cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                    defaultSort: this.getStoredSortOrder("firstName"),
                    filterComponent: (props: BaseTableFilter) =>
                        <TableFilter columnDef={props.columnDef} filterType={"string"}
                            initialValue={this.getStoredFilter("firstName")}
                            onFilterChanged={props.onFilterChanged} />
                },
                {
                    title: "Last Name",
                    field: "lastName",
                    type: "string",
                    cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'},
                    defaultSort: this.getStoredSortOrder("lastName"),
                    filterComponent: (props: BaseTableFilter) =>
                        <TableFilter columnDef={props.columnDef} filterType={"string"}
                            initialValue={this.getStoredFilter("lastName")}
                            onFilterChanged={props.onFilterChanged} />                            
                },
                {
                    title: "Email",
                    field: "email",
                    type: "string",
                    cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '25%' },
                    filterCellStyle: { width: '25%' },
                    headerCellStyle: { width: '25%' },
                    defaultSort: this.getStoredSortOrder("email"),
                    filterComponent: (props: BaseTableFilter) =>
                        <TableFilter columnDef={props.columnDef} filterType={"string"}
                            initialValue={this.getStoredFilter("email")}
                            onFilterChanged={props.onFilterChanged} />
                },
                {
                    title: "Company",
                    field: "affiliation",
                    type: "string",
                    cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '25%' },
                    filterCellStyle: { width: '25%' },
                    headerCellStyle: { width: '25%' },
                    defaultSort: this.getStoredSortOrder("affiliation"),
                    filterComponent: (props: BaseTableFilter) =>
                        <TableFilter columnDef={props.columnDef} filterType={"string"}
                            initialValue={this.getStoredFilter("affiliation")}
                            onFilterChanged={props.onFilterChanged} />
                }
            ],
            operators: [],
            options: {
                columnResizable: false,
                debounceInterval: 500,
                doubleHorizontalScroll: true,
                filtering: true,
                fixedColumns: { left: 1 },
                initialPage: 0,
                pageSize: USERS_PAGE_SIZE,
                pageSizeOptions: [],
                showTitle: true,
                searchAutoFocus: false,
                searchText: this.getStoredSearch(),
                tableLayout: 'fixed',
                thirdSortClick: false
            },
            pageOverride: this.getPageOverride(USERS_PAGE_SIZE),
            rowCount: undefined,
            useStoredQuery: this.props.usersState.users ? true : false,
            userToDelete: null
        };
        this.tableElement = React.createRef();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.props.actions.clearMessage();
        if (this.props.clientState.client) {
            this.setState({
                isVisible: true
            });
            this.configureTableColumns();
        }
        window.addEventListener('resize', this.onWindowResize);
    }

    public componentDidUpdate = () => {
        if (this.props.clientState.client && !this.state.isVisible) {
            setTimeout(() => {
                this.setState({
                    isVisible: true
                });
                this.configureTableColumns();
            });
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={this.state.isTableLoading || !this.state.isTableInitialized} />
                <motion.div id="adminUsersPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"} variants={this.adminUsersVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className="title justify-content-start d-block d-sm-none">
                            <Col className="pl-0 pr-0 text-left">
                                <h2 className="page-title">Users</h2>
                            </Col>
                        </Row>
                        <Row className={"table-container" + (this.state.isTableError ? " load-error" : "") + (this.state.isTableLoading ? " loading" : "") + (this.state.isTableInitialized ? " initialized" : "")}>
                            <Col className="pl-0 pr-0">
                                {this.props.clientState.client && (
                                    <MaterialTable
                                        title={"Users"}
                                        tableRef={this.tableElement}
                                        columns={this.state.columns}
                                        data={this.getUsers}
                                        icons={TableIcons.icons}
                                        options={this.state.options}
                                        onOrderChange={this.onOrderChange}
                                        components={{
                                            OverlayError: props => (
                                                <span></span>
                                            ),
                                            OverlayLoading: props => (
                                                <span></span>
                                            )
                                        }}
                                        actions={[
                                            {
                                                icon: TableIcons.getAddIcon,
                                                tooltip: "Add User",
                                                isFreeAction: true,
                                                onClick: this.onAddUser
                                            },
                                            {
                                                icon: TableIcons.getEditIcon,
                                                tooltip: 'Edit User',
                                                onClick: this.onEditUser
                                            },
                                            {
                                                icon: TableIcons.getDeleteIcon,
                                                tooltip: 'Delete User',
                                                onClick: this.onDeleteUser
                                            }
                                        ]}
                                        localization={{
                                            header: {
                                                actions: ''
                                            },
                                            body: {
                                                emptyDataSourceMessage: this.state.rowCount === 0 ? 'No records to display' : ''
                                            }
                                        }}
                                        onChangePage={this.onChangePage}
                                    />
                                )}
                            </Col>
                        </Row>
                        {this.state.isTableError && (
                            <Row className="justify-content-center table-error">
                                <Col className="pt-4" xs={"auto"}>
                                    <Button color="primary" onClick={this.reloadTable}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                            <polyline points="1 4 1 10 7 10"></polyline>
                                            <polyline points="23 20 23 14 17 14"></polyline>
                                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                        </svg>
                                        Reload
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <DeleteUser userToDelete={this.state.userToDelete} onDelete={this.onDeleteUserComplete} onDismiss={this.hideDeleteUser} />
                    </Container>
                </motion.div>
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private configureTableColumns = (): void => {
        if (this.tableElement.current) {
            let isSmallScreen: boolean = this.isBreakpoint('sm');
            let columns: Column<any>[] = this.tableElement.current.props.columns;
            if (columns.length > 0) {
                for (let n: number = 0; n < columns.length; n++) {
                    let col: Column<any> = columns[n];
                    if (n == 0) {
                        this.tableElement.current.onChangeColumnHidden(col, !isSmallScreen);
                    }
                    else {
                        this.tableElement.current.onChangeColumnHidden(col, isSmallScreen);
                    }
                }
            }
            else {
                setTimeout(() => {
                    this.configureTableColumns();
                }, 400);
            }
        }
    }

    private getFilter = (field: string): Filter<any> | undefined => {
        let filter: Filter<any> | undefined = undefined;
        if (this.props.usersState.users && this.props.usersState.users.query) {
            let matches: Filter<any>[] = this.props.usersState.users.query.filters.filter((filter: Filter<any>) => {
                return filter.column.field === field;
            });
            if (matches.length > 0) {
                filter = matches[0];
            }
        }
        return filter;
    }

    private getFilterOperator = (field: string | number | symbol | undefined): string | undefined => {
        let filterOperator: string | undefined = undefined;
        let index: number = this.state.operators.findIndex(f => f.column.field === field);
        if (index >= 0) {
            filterOperator = this.state.operators[index].operatorOverride;
        }
        return filterOperator;
    }

    private getPageOverride = (pageSize: number): number | undefined => {
        let pageOverride: number | undefined = undefined;
        if (this.props.usersState.users && this.props.usersState.users.query) {
            if (this.props.usersState.users.query.page > 0) {
                pageOverride = this.props.usersState.users.query.page;
                let appliedPageSize: number = this.props.usersState.users.query.pageSize;
                if (appliedPageSize !== USERS_PAGE_SIZE) {
                    let lastRow: number = appliedPageSize * (pageOverride + 1);
                    if (lastRow <= pageSize) {
                        pageOverride = undefined;
                    }
                    else {
                        pageOverride = Math.floor(lastRow / pageSize);
                    }
                }
            }
        }
        return pageOverride;
    }

    private getStoredFilter = (field: string): string | undefined => {
        let filter: Filter<any> | undefined = this.getFilter(field);
        return filter ? filter.value : undefined;
    }

    private getStoredFilterListItem = (field: string): TableFilterListItem | undefined => {
        let storedFilterListItem: TableFilterListItem | undefined = undefined;
        let storedFilter: string | undefined = this.getStoredFilter(field);
        if (storedFilter) {
            switch (field) {
                case "description":
                    switch (storedFilter) {
                        case "0":
                            storedFilterListItem = { label: 'All', value: '0' };
                            break;
                        case "1":
                            storedFilterListItem = { label: 'Admin', value: '1' };
                            break;
                        case "2":
                            storedFilterListItem = { label: 'CSR', value: '2' };
                            break;
                        case '4':
                            storedFilterListItem = { label: 'Rep', value: '4' };
                            break;
                        case '8':
                            storedFilterListItem = { label: 'Buyer', value: '8' };
                            break;
                    }
                    break;
            }
        }
        return storedFilterListItem;
    }

    private getStoredFilterOperator = (field: string): string | undefined => {
        let filter: Filter<any> | undefined = this.getFilter(field);
        return filter ? (filter as FilterEx).operatorOverride : undefined;
    }

    private getStoredSearch = (): string | undefined => {
        let searchText: string | undefined = undefined;
        if (this.props.usersState.users) {
            searchText = this.props.usersState.users.query.search;
        }
        return searchText;
    }

    private getStoredSortOrder = (field: string): string | undefined => {
        let sortOrder: string | undefined = undefined;
        if (this.props.usersState.users && this.props.usersState.users.query) {
            if (this.props.usersState.users.query.orderBy) {
                if (this.props.usersState.users.query.orderBy.field === field) {
                    sortOrder = this.props.usersState.users.query.orderDirection;
                }
            }
        }
        return sortOrder;
    }

    private getUsers = (query: Query<any>): Promise<QueryResult<any>> => {

        return new Promise((resolve, reject) => {

            if (this.props.clientState.client) {                
                
                this.showTableLoader(true, this.state.isTableInitialized, undefined);
                let mergedQuery: Query<any> = this.mergeQuery(query);
                this.props.asyncActions.requestUsersAsync(this.props.clientState.client.code, mergedQuery)
                    .then(result => {
                        if (this.state.pageOverride) {
                            setTimeout(() => {
                                this.tableElement.current.onChangePage({}, this.state.pageOverride);
                            })
                        }
                        resolve(result);
                        this.showTableLoader(false, true, result.data.length);
                    },
                    err => {
                        this.showTableReloader();
                        resolve({
                            data: [],
                            page: 0,
                            totalCount: 0
                        })
                    });
            }
            else {
                reject("Client is undefined");
            }
        })
    }

    private hideDeleteUser = (): void => {
        this.setState({
            userToDelete: null
        });
    }

    private isBreakpoint = (alias: string): boolean => {
        let className: string = '.device-' + alias;
        let element: JQuery<HTMLElement> = $(className);
        return element.is(':visible');
    }

    private mergeQuery = (query: Query<any>): Query<any> => {
        if (this.state.useStoredQuery && this.props.usersState.users && !this.onSearchChange(query)) {
            let storedQuery = this.props.usersState.users.query;
            if (this.state.isTableInitialized) {
                storedQuery.page = query.page;
                storedQuery.filters = query.filters;
                storedQuery.orderDirection = query.orderDirection;
            }
            else {
                this.setState({
                    isTableInitialized: true
                });
            }
            query = storedQuery;
        }

        for (let n: number = 0; n < query.filters.length; n++) {
            (query.filters[n] as FilterEx).operatorOverride = this.getFilterOperator(query.filters[n].column.field);
        }

        return query;
    }

    private onAddUser = (event: any): void => {
        this.props.history.push('/admin/users/add');
    }

    private onChangePage = (page: number, pageSize: number): void => {
//        this.updatePagination();
    }

    private onFilterOperatorChanged = (field: string, operator: string): void => {
        let operators: FilterEx[] = this.state.operators
        let index: number = operators.findIndex(f => f.column.field === field);
        if (index >= 0) {
            operators[index].operatorOverride = operator;
        }
        else {
            operators.push({
                column: {
                    field: field
                },
                operatorOverride: operator
            } as FilterEx);
        }
        this.setState({
            operators: operators
        });
    }

    private onDeleteUser = (event: any, data: any): void => {
        let user: User = data as User;
        if (user && this.props.clientState.client) {
            user.clientCode = this.props.clientState.client.code;
            this.setState({
                userToDelete: user
            })
        }
    }

    private onDeleteUserComplete = (): void => {
        this.hideDeleteUser();
        setTimeout(() => {
            this.reloadTable();
        });
    }

    private onEditUser = (event: any, data: any): void => {
        let user: User = data as User;
        if (user) {
            this.props.history.push('/admin/users/edit/' + user.id);
        }
    }

    private onOrderChange = (orderBy: number, orderDirection: "asc" | "desc"): void => {
        this.setState({
            useStoredQuery: false
        });
    }

    private onSearchChange = (query: Query<any>): boolean => {
        let searchChange: boolean = false;
        if (this.state.useStoredQuery && this.props.usersState.users) {
            searchChange = (query.search != this.props.usersState.users.query.search);
            if (searchChange) {
                this.setState({
                    useStoredQuery: false
                });
            }
        }
        return searchChange;
    }

    private onWindowResize = debounce((): void => {
        this.configureTableColumns();
    }, 100, { leading: false, trailing: true }).bind(this);

    private reloadTable = () => {
        this.props.actions.clearMessage();
        this.tableElement.current.onQueryChange();
    }

    private setTableLayout = (rowCount: number): void => {
        //let table: JQuery<Element> = $('.MuiTable-root:first');
        //table.css({ 'table-layout': rowCount > 0 ? 'fixed' : 'auto' });
    }

    private showTableLoader = (active: boolean, initialized: boolean, rowCount: number | undefined): void => {
        if (!this.state.isTableLoading === active) {
            setTimeout(() => {
                this.setState({
                    isTableInitialized: true,
                    isTableLoading: active ? true : false,
                    isTableError: false,
                    pageOverride: initialized ? undefined: this.state.pageOverride,
                    rowCount: rowCount
                });
            });
        }
    }

    private showTableReloader = (): void => {
        setTimeout(() => {
            this.setState({
                isTableLoading: false,
                isTableError: true
            });
        }, 500);
    }

    private updatePagination = (): void => {
        let paginationCaption: JQuery<HTMLElement> = $('.MuiTablePagination-root .MuiTypography-caption');
        if (paginationCaption.length > 0) {
            let caption: string = paginationCaption.text();
            let tokens: string[] = caption.split(/\s/);
            if (tokens.length === 3) {
                for (let n: number = 0; n < tokens.length; n++) {
                    if (n === 0) {
                        let numbers: string[] = tokens[n].split('-');
                        if (numbers.length === 2) {
                            for (let m: number = 0; m < numbers.length; m++) {
                                if (numbers[m].length > 3 && numbers[m].indexOf(',') < 0) {
                                    numbers[m] = Number(numbers[m]).toLocaleString();
                                }
                            }
                            tokens[n] = numbers.join('-');
                        }
                    }
                    else if (n === 2 && tokens[n].length > 3 && tokens[n].indexOf(',') < 0) {
                        tokens[n] = Number(tokens[n]).toLocaleString();
                    }
                }
                paginationCaption.text((tokens.join(' ')));
            }
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        usersState: state.users
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            UsersStore.actionCreators 
        ), dispatch),
        asyncActions: {
            requestUsersAsync: (clientCode: ClientCode, query: Query<any>) => dispatch(UsersStore.actionCreators.requestUsers(clientCode, query))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminUsers as any);
