﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as MessageStore from './Message';
import * as ErrorMessage from '../common/ErrorMessage';
import { ClientCode } from '../enums/ClientCode';
import { AffiliationType } from '../enums/AffiliationType';
import { CustomerName } from '../common/AccountTypes';
import { Role } from '../enums/Role';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserState {
    user: User | null;
    clientCode: ClientCode;
    isLoadingBuyerAccounts: boolean;
    isLoggingIn: boolean;
    isUpdating: boolean;
}

export interface UserCredentials {
    username: string;
    password: string;
    clientCode: ClientCode;
}

export interface UserPreferences {
    productsPerPage: string | null | undefined;
}

export interface User {
    active: boolean;
    clientCode: ClientCode;
    id: number;
    isAuthenticated: boolean;
    created?: Date;
    lastLogin?: Date;
    token: string;
    username: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    affiliation: string;
    affiliationType: AffiliationType;
    salesRepId: string;
    customerNumber: string;
    role: Role;
    description: string;
    preferences: UserPreferences | null | undefined;
    customer: CustomerName | null | undefined;
    buyerAccounts: CustomerName[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface LoginUserAction {
    type: 'LOGIN_USER';
    credentials: UserCredentials;
}

interface LogoutUserAction {
    type: 'LOGOUT_USER';
}

interface RequestUserAction {
    type: 'REQUEST_USER';
    clientCode: ClientCode;
}

interface ReceiveUserAction {
    type: 'RECEIVE_USER';
    clientCode: ClientCode;
    user: User | null
}

interface RequestBuyerAccountsAction {
    type: 'REQUEST_BUYER_ACCOUNTS',
    clientCode: ClientCode;
    user: User;
}

interface ReceiveBuyerAccountsAction {
    type: 'RECEIVE_BUYER_ACCOUNTS',
    clientCode: ClientCode;
    user: User;
}

interface UpdateUserAction {
    type: 'UPDATE_USER';
    user: User;
}

interface UpdateUserLastLoginAction {
    type: 'UPDATE_USER_LAST_LOGIN';
    user: User;
}

interface UpdateUserPreferencesAction {
    type: 'UPDATE_USER_PREFERENCES';
    clientCode: ClientCode;
    user: User;
    userPreferences: UserPreferences;
}

interface ClearUserAction {
    type: 'CLEAR_USER';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = LoginUserAction | LogoutUserAction |
    RequestUserAction | ReceiveUserAction |
    RequestBuyerAccountsAction | ReceiveBuyerAccountsAction |
    UpdateUserAction | UpdateUserLastLoginAction | UpdateUserPreferencesAction |
    ClearUserAction | MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const mergeData = (storedUser: User, updatedUser: User): User => {
    return {
        affiliation: storedUser.affiliation,
        affiliationType: storedUser.affiliationType,
        clientCode: storedUser.clientCode,
        email: updatedUser.email,
        firstName: updatedUser.firstName,
        lastName: updatedUser.lastName,
        id: storedUser.id,
        isAuthenticated: storedUser.isAuthenticated,
        salesRepId: storedUser.salesRepId,
        customerNumber: storedUser.customerNumber,
        buyerAccounts: storedUser.buyerAccounts,
        token: storedUser.token,
        username: storedUser.username,
        role: storedUser.role,
        created: storedUser.created,
        lastLogin: storedUser.lastLogin,
        active: storedUser.active,
        preferences: storedUser.preferences
    } as User;
}

const mergePreferences = (storedUser: User, userPreferences: UserPreferences): User => {
    return {
        affiliation: storedUser.affiliation,
        affiliationType: storedUser.affiliationType,
        clientCode: storedUser.clientCode,
        email: storedUser.email,
        firstName: storedUser.firstName,
        lastName: storedUser.lastName,
        id: storedUser.id,
        isAuthenticated: storedUser.isAuthenticated,
        salesRepId: storedUser.salesRepId,
        customerNumber: storedUser.customerNumber,
        buyerAccounts: storedUser.buyerAccounts,
        token: storedUser.token,
        username: storedUser.username,
        role: storedUser.role,
        created: storedUser.created,
        lastLogin: storedUser.lastLogin,
        active: storedUser.active,
        preferences: userPreferences
    } as User;
}

export const actionCreators = {
    loginUser: (credentials: UserCredentials): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let isLoggingIn: boolean = appState.user ? appState.user.isLoggingIn : false;
        if (!isLoggingIn) {

            let fetchError: boolean = false;
            let action: string = 'user/login';
            let url: string = `${action}`;

            localStorage.removeItem('user');

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(credentials)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'RECEIVE_USER', clientCode: credentials.clientCode, user: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    let user: User = data as User;
                    if (user && user.isAuthenticated) {
                        localStorage.setItem('user', JSON.stringify(user));
                    }
                    dispatch({ type: 'RECEIVE_USER', clientCode: credentials.clientCode, user: data })
                },
                    err => {
                        if (!fetchError) {
                            dispatch({ type: 'RECEIVE_USER', clientCode: credentials.clientCode, user: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

            dispatch({ type: 'LOGIN_USER', credentials: credentials });
        }
    },
    logoutUser: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.user && appState.user.user) {
            localStorage.removeItem('user');
            dispatch({ type: 'RECEIVE_USER', clientCode: appState.user.clientCode, user: null });
        }
        dispatch({ type: 'LOGOUT_USER' });

    },
    requestUser: (clientCode: ClientCode): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Verify user in store has correct client code
        const appState = getState();
        if (appState && appState.user && clientCode != appState.user.clientCode) {
            // TODO dispatch LOGOUT_USER
        }
        else {
            dispatch({ type: 'REQUEST_USER', clientCode: clientCode });
        }
    },
    requestBuyerAccounts: (user: User): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.user && appState.user.user && !appState.user.isLoadingBuyerAccounts) {

            let fetchError: boolean = false;
            let action: string = 'user/getbuyeraccounts'
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'GET',
                    headers: { 'Accept': 'application/json' }
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'RECEIVE_BUYER_ACCOUNTS', clientCode: user.clientCode, user: user });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error;
                    }
                    return response.json();
                })
                .then(data => {
                    let buyerAccounts: CustomerName[] = data as CustomerName[];
                    if (buyerAccounts) {
                        user.buyerAccounts = buyerAccounts;
                        localStorage.setItem('user', JSON.stringify(user));
                    }
                    dispatch({ type: 'RECEIVE_BUYER_ACCOUNTS', clientCode: user.clientCode, user: user});
                },
                    err => {
                        if (!fetchError) {
                            dispatch({ type: 'RECEIVE_BUYER_ACCOUNTS', clientCode: user.clientCode, user: user });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

            dispatch({ type: 'REQUEST_BUYER_ACCOUNTS', clientCode: user.clientCode, user: user });
        }
    },
    updateUser: (user: User): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.user && appState.user.user && !appState.user.isUpdating) {

            let updatedUser: User = mergeData(appState.user.user, user);
            let fetchError: boolean = false;
            let action: string = 'user/update';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'PUT',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedUser)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                if (appState.user && appState.user.user) {
                                    dispatch({ type: 'RECEIVE_USER', clientCode: updatedUser.clientCode, user: appState.user.user });
                                }
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    let user: User = data as User;
                    if (user && user.isAuthenticated) {
                        localStorage.setItem('user', JSON.stringify(user));
                        let actionComplete: MessageStore.Message = {
                            messageType: MessageStore.MessageType.ACTIONCOMPLETE,
                            action: action,
                            reference: null,
                            text: 'Update complete'
                        };
                        dispatch({ type: 'BROADCAST_MESSAGE', message: actionComplete });
                    }
                    dispatch({ type: 'RECEIVE_USER', clientCode: data.clientCode, user: data })
                },
                    err => {
                        if (!fetchError) {
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });
            dispatch({ type: 'UPDATE_USER', user: appState.user.user });
        }
    },
    updateUserLastLogin: (user: User): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.user && appState.user.user && !appState.user.isUpdating) {

            let updatedUser: User = mergeData(appState.user.user, user);
            let fetchError: boolean = false;
            let action: string = 'user/updatelastlogin';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'PUT',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedUser)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                if (appState.user && appState.user.user) {
                                    dispatch({ type: 'RECEIVE_USER', clientCode: updatedUser.clientCode, user: appState.user.user });
                                }
                                if (response.status > 401) {
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                }
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    let user: User = data as User;
                    if (user && user.isAuthenticated) {
                        localStorage.setItem('user', JSON.stringify(user));                        
                    }
                    dispatch({ type: 'RECEIVE_USER', clientCode: data.clientCode, user: data })
                },
                    err => {
                        if (!fetchError) {
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action, true) });
                        }
                    });
            dispatch({ type: 'UPDATE_USER_LAST_LOGIN', user: appState.user.user });
        }
    },
    updateUserPreferences: (user: User, userPreferences: UserPreferences): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.user && appState.user.user && !appState.user.isUpdating) {

            let updatedUser: User = mergePreferences(appState.user.user, userPreferences);
            let fetchError: boolean = false;
            let action: string = 'user/updatepreferences';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'PUT',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedUser)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                if (appState.user && appState.user.user) {
                                    dispatch({ type: 'RECEIVE_USER', clientCode: updatedUser.clientCode, user: appState.user.user });
                                }
                                if (response.status > 401) {
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                }
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    let user: User = data as User;
                    if (user && user.isAuthenticated) {
                        localStorage.setItem('user', JSON.stringify(user));
                        let actionComplete: MessageStore.Message = {
                            messageType: MessageStore.MessageType.ACTIONCOMPLETE,
                            action: action,
                            reference: null,
                            text: 'Update complete'
                        };
                        dispatch({ type: 'BROADCAST_MESSAGE', message: actionComplete });
                    }
                    dispatch({ type: 'RECEIVE_USER', clientCode: data.clientCode, user: data })
                },
                    err => {
                        if (!fetchError) {
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });
            dispatch({ type: 'UPDATE_USER_PREFERENCES', clientCode: user.clientCode, user: user, userPreferences: userPreferences });
        }
    },
    clearUser: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.user && appState.user.user) {
            dispatch({ type: 'RECEIVE_USER', clientCode: appState.user.clientCode, user: null });
        }
        dispatch({ type: 'CLEAR_USER' });
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// initial state
const getInitialUserState = (): UserState => {
    let storageItem: string | null = localStorage.getItem('user');
    let storedUser: User | null = storageItem ? JSON.parse(storageItem) as User : null;
    return {
        user: storedUser,
        clientCode: storedUser ? storedUser.clientCode : ClientCode.Undefined,
        isLoggingIn: false,
        isUpdating: false
    } as UserState;
}

const initialState: UserState = getInitialUserState();

// reducer
export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOGIN_USER':
            return {
                clientCode: action.credentials.clientCode,
                user: state.user,
                isLoggingIn: true,
                isLoadingBuyerAccounts: state.isLoadingBuyerAccounts,
                isUpdating: state.isUpdating
            };
        case 'REQUEST_USER':
            return {
                clientCode: action.clientCode,
                user: state.user,
                isLoggingIn: state.isLoggingIn,
                isLoadingBuyerAccounts: state.isLoadingBuyerAccounts,
                isUpdating: state.isUpdating,
            };
        case 'RECEIVE_USER':
            return {
                clientCode: action.clientCode,
                user: action.user,
                isLoggingIn: false,
                isLoadingBuyerAccounts: state.isLoadingBuyerAccounts,
                isUpdating: false
            };
        case 'REQUEST_BUYER_ACCOUNTS':
            return {
                clientCode: action.clientCode,
                user: action.user,
                isLoggingIn: false,
                isLoadingBuyerAccounts: true,
                isUpdating: false
            };
        case 'RECEIVE_BUYER_ACCOUNTS':
            return {
                clientCode: action.clientCode,
                user: action.user,
                isLoggingIn: false,
                isLoadingBuyerAccounts: false,
                isUpdating: false
            };
        case 'UPDATE_USER':
            return {
                clientCode: state.clientCode,
                user: state.user,
                isLoggingIn: false,
                isLoadingBuyerAccounts: state.isLoadingBuyerAccounts,
                isUpdating: true
            };
        case 'UPDATE_USER_LAST_LOGIN':
            return {
                clientCode: state.clientCode,
                user: state.user,
                isLoggingIn: false,
                isLoadingBuyerAccounts: state.isLoadingBuyerAccounts,
                isUpdating: true
            }
        case 'UPDATE_USER_PREFERENCES':
            return {
                clientCode: state.clientCode,
                user: state.user,
                isLoggingIn: false,
                isLoadingBuyerAccounts: state.isLoadingBuyerAccounts,
                isUpdating: true
            }
        default:
            return state;
    }
}