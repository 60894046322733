import * as React from "react";
import { Role } from '../../enums/Role';

// ----------------
// PROPS

interface AuthorizedContentProps {
    roleRequired: Role;
    userRole: Role;
}

// ----------------
// LOCAL STATE

interface AuthorizedContentState {
    isAuthorized: boolean;
}

class AuthorizedContent extends React.PureComponent<AuthorizedContentProps, AuthorizedContentState> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: AuthorizedContentProps, state: AuthorizedContentState) {
        super(props);
        this.state = {
            isAuthorized: this.userInRole()
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        if (!this.state.isAuthorized) {
            this.setState({
                isAuthorized: this.userInRole()
            });
        }
    }

    public componentDidUpdate = (prevProps: AuthorizedContentProps) => {
        if (this.props.userRole != prevProps.userRole) {
            this.setState({
                isAuthorized: this.userInRole()
            });
        }
    }

    public render = () => {
        return (
            <React.Fragment>
                {this.state.isAuthorized && (
                    <React.Fragment>
                        {this.props.children}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    // ----------------
    // METHODS

    private userInRole = (): boolean => {
        let flag: number = this.props.roleRequired;
        let value: number = this.props.userRole;
        let result: number = (flag & value);
        return (result > 0 && result === value);
    }
}


// ----------------
// EXPORT

export default AuthorizedContent;
