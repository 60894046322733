﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as MessageStore from './Message';
import * as ErrorMessage from '../common/ErrorMessage';
import { ClientCode } from '../enums/ClientCode';
import { Country } from '../common/AddressTypes';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CountriesState {
    isLoading: boolean;
    clientCode: ClientCode;
    countries: Country[] | null | undefined;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestCountriesAction {
    type: 'REQUEST_COUNTRIES';
    clientCode: ClientCode;    
}

interface ReceiveCountriesAction {
    type: 'RECEIVE_COUNTRIES',
    clientCode: ClientCode,
    countries: Country[] | null | undefined
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = RequestCountriesAction | ReceiveCountriesAction | MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestCountries: (clientCode: ClientCode): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.countries && !appState.countries.countries && !appState.countries.isLoading) {

            let fetchError: boolean = false;
            let action: string = 'countries/get'
            let url: string = `${action}/${clientCode}`;

            fetch(url,
                {
                    method: 'GET',
                    headers: { 'Accept': 'application/json' }
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'RECEIVE_COUNTRIES', clientCode: clientCode, countries: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error;
                    }
                    return response.json();
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_COUNTRIES', clientCode: clientCode, countries: data });
                },
                err => {
                    if (!fetchError) {
                        dispatch({ type: 'RECEIVE_COUNTRIES', clientCode: clientCode, countries: null });
                        dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action)});
                    }
                });

            dispatch({ type: 'REQUEST_COUNTRIES', clientCode: clientCode });
        }
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: CountriesState = { isLoading: false, clientCode: ClientCode.Undefined, countries: null };

export const reducer: Reducer<CountriesState> = (state: CountriesState | undefined, incomingAction: Action): CountriesState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_COUNTRIES':
            return {
                countries: state.countries,
                clientCode: state.clientCode,
                isLoading: true
            };
        case 'RECEIVE_COUNTRIES':
            return {
                countries: action.countries,
                clientCode: action.clientCode,
                isLoading: false
            };
        default:
            return state;
    }
}