import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as DropShipAddressesStore from '../../store/DropShipAddresses';
import * as MessageStore from '../../store/Message';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { DropShipAddress } from '../../common/AddressTypes';
import { ShippingOption } from '../../store/ShippingOptions';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Row } from 'reactstrap';
import MaterialTable, { Column, Filter, Options, Query, QueryResult, MTableBody, MTableToolbar } from 'material-table';
import { LabelDisplayedRowsArgs } from '@material-ui/core';
import { BaseTableFilter } from '../../common/BaseTypes';
import { FilterEx } from '../../common/ExtendedTypes';
import TableFilter from '../table-filter/TableFilter';
import * as TableIcons from '../table-icons/TableIcons';
import DropShipAddressDescription from '../dropship-address-description/DropShipAddressDescription';
import Loader from '../loader/Loader';
import cloneDeep from 'lodash/cloneDeep';
import $ from 'jquery';

import './DropShipAddressSelector.scss';

// ----------------
// PROPS

const applicationState = {
    dropShipAddressesState: {} as DropShipAddressesStore.DropShipAddressesState,
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, DropShipAddressesStore.actionCreators, MessageStore.actionCreators)
};

interface DropShipAddressSelectorAsyncActions {
    asyncActions: {
        requestDropShipAddressesAsync: (client: Client, billto: string, addressId: number | null | undefined, query: Query<DropShipAddress>, broadcastError: boolean) => Promise<QueryResult<DropShipAddress>>;
    }
}

interface DropShipAddressSelectorOwnProps {
    activated: boolean;
    client: Client;
    billto: string;
    orderId: number;
    addressId: number | null | undefined;
    shippingOption: ShippingOption | null | undefined;
    onRetrievingAddresses?: (retrieving: boolean) => void;
    onSubmit: (submitting: boolean) => void;
    onSubmitFail: () => void;
    onSubmitSucceed?: (newAddress: DropShipAddress) => void;
    submitRequested: boolean;
}

type DropShipAddressSelectorProps =
    DropShipAddressSelectorOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & DropShipAddressSelectorAsyncActions;

// ----------------
// LOCAL STATE

interface DropShipAddressSelectorState {
    isVisible: boolean;
    isTableInitialized: boolean;
    isTableLoading: boolean;
    isTableError: boolean;
    submitting: boolean;
    columns: any[];
    operators: FilterEx[];
    options: Options<any>;
    rowCount: number | undefined;
    total: number | undefined;
    useStoredQuery: boolean;
    selectedDropShipAddress: DropShipAddress | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateDropShipAddressSelectorForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.id) {
        errors.id = 'No address selected';
    }
    return errors;
}

const onFormValidationFailed = (errors: any, dispatch: any, submitError: Error, props: DropShipAddressSelectorOwnProps) => {
    if (props.onSubmitFail) {
        props.onSubmitFail();
    }
}

class DropShipAddressSelector extends React.PureComponent<DropShipAddressSelectorProps, DropShipAddressSelectorState> {

    // ----------------
    // VARIABLES

    public tableElement: any;
    public submitButton: React.RefObject<HTMLButtonElement>;

    public dropShipAddressSelectorVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: DropShipAddressSelectorProps, state: DropShipAddressSelectorState) {
        super(props);
        this.state = {
            isVisible: false,
            isTableInitialized: false,
            isTableLoading: false,
            isTableError: false,
            submitting: false,
            columns: this.getColumnDefinitions(),
            operators: [],
            options: {
                debounceInterval: 500,
                filtering: true,
                initialPage: 0,
                pageSize: 2,
                pageSizeOptions: [],
                rowStyle: (rowData: any, index: number) => {
                    return {
                        backgroundColor: index % 2 === 1 ? "#fcfcfc" : "#ffffff"
                    };
                },
                search: false,
                showTitle: false,
                toolbar: false,
                thirdSortClick: false
            },
            rowCount: undefined,
            total: undefined,
            useStoredQuery: false,
            selectedDropShipAddress: undefined
        };
        this.tableElement = React.createRef();
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: DropShipAddressSelectorProps) => {
        if (this.props.activated && !prevProps.activated) {
            this.initializeForm();
        }
        else if (prevProps.activated && !this.props.activated) {
            setTimeout(() => {
                this.resetForm();
            }, 400);
        }
        if (this.props.activated && this.props.submitRequested && !prevProps.submitRequested) {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        }
        else if (this.state.submitting) {
            if (this.receivedValidationError()) {
                this.setState({
                    submitting: false
                });
            }
            else if (this.receivedActionCompleteMessage()) {
                if (this.state.selectedDropShipAddress && this.props.onSubmitSucceed) {
                    this.props.onSubmitSucceed(this.state.selectedDropShipAddress);
                }
                else {
                    this.setState({
                        submitting: false,
                        isTableLoading: false
                    });
                }
            }
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        if (this.state.selectedDropShipAddress) {
            this.props.onSubmit(true);
            this.setState({
                submitting: true,
                isTableLoading: true
            });
            let assignment: DropShipAddressesStore.DropShipAddressAssignment =
            {
                orderId: this.props.orderId,
                dropShipAddress: this.state.selectedDropShipAddress,
                assigned: true,
                shippingOption: this.props.shippingOption
            };
            this.props.actions.assignDropShipAddress(this.props.client, assignment);
        }
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={this.props.activated && (this.state.isTableLoading || !this.state.isTableInitialized || !this.state.isVisible)} isChild={true} />
                <motion.div id="dropship-address-selector" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"}
                    variants={this.dropShipAddressSelectorVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className={"table-container" + (this.state.isTableError ? " load-error" : "") + (this.state.isTableLoading || !this.state.isTableInitialized ? " loading" : "") + (this.state.isTableInitialized ? " initialized" : "")}>
                            <Col className="pl-0 pr-0">
                                <MaterialTable
                                    tableRef={this.tableElement}
                                    columns={this.state.columns}
                                    data={this.getDropShipAddresses}
                                    icons={TableIcons.icons}
                                    options={this.state.options}
                                    components={{
                                        OverlayError: props => (
                                            <span></span>
                                        ),
                                        OverlayLoading: props => (
                                            <span></span>
                                        ),
                                        Cell: props => (
                                            <React.Fragment>
                                                {props.columnDef.tableData.id === 0 && (
                                                    <td className="MuiTableCell-root MuiTableCell-body multi-span" colSpan={this.state.columns.length}>
                                                        <DropShipAddressDescription dropShipAddress={props.rowData} isLoading={this.state.isTableLoading || this.state.submitting}
                                                            selectedAddressId={this.state.selectedDropShipAddress ? this.state.selectedDropShipAddress.id : undefined}
                                                            onSelect={this.onSelect} />
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        )
                                    }}
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: this.state.rowCount === 0 ? 'No other addresses on file' : ''
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        {this.state.isTableError && (
                            <Row className="justify-content-center table-error">
                                <Col className="pt-4" xs={"auto"}>
                                    <Button color="primary" onClick={this.reloadTable}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                            <polyline points="1 4 1 10 7 10"></polyline>
                                            <polyline points="23 20 23 14 17 14"></polyline>
                                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                        </svg>
                                        Reload
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col className="pt-2">
                                <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                    <Field name="id" component={FieldWrapper.renderHiddenField} type={"hidden"} />
                                    <button type="submit" ref={this.submitButton}>Submit</button>
                                </form>
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
            </React.Fragment>
        )
    }

    // ----------------
    // HELPERS

    private getColumnDefinitions = (): any[] => {
        let columns: any[] = [];
        switch (this.props.client.code) {
            case ClientCode.GTN:
            default:
                columns = this.getDefaultColumnDefinitions();
                break;
        }
        return columns;
    }

    private getDefaultColumnDefinitions = (): any => {
        return [
            {
                title: "Name",
                field: "name",
                type: "string",
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType="string"
                        initialValue={null} onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "City",
                field: "city",
                type: "string",
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType="string"
                        initialValue={null} onFilterChanged={props.onFilterChanged} />
            }
        ];
    }

    private getDropShipAddresses = (query: Query<any>): Promise<QueryResult<any>> => {
        this.props.change("id", 0);
        return new Promise((resolve, reject) => {
            if (!this.state.isVisible) {
                resolve({
                    data: [],
                    page: 0,
                    totalCount: 0
                });
            }
            else {
                this.showTableLoader(true, this.state.isTableInitialized, undefined, this.state.total);
                let mergedQuery: Query<any> = this.mergeQuery(query);
                this.props.asyncActions.requestDropShipAddressesAsync(this.props.client, this.props.billto, this.props.addressId, mergedQuery, false)
                    .then(result => {
                        resolve(result);
                        this.showTableLoader(false, true, result.data.length, result.totalCount);
                        setTimeout(() => {
                            $('#dropship-address-selector').find(':checkbox:first').focus().blur();
                        }, 250);
                    },
                    err => {
                        this.showTableReloader(err);
                        resolve({
                            data: [],
                            page: 0,
                            totalCount: 0
                        });
                    });              
            }
        });
    }

    private initializeForm = (): void => {
        this.props.reset();
        this.setState({
            isVisible: false,
            isTableInitialized: false,
            rowCount: undefined,
            total: undefined,
            selectedDropShipAddress: undefined
        });
        setTimeout(() => {
            this.setState({
                isVisible: true
            });
            this.reloadTable();
        }, 800);
    }

    private mergeQuery = (query: Query<any>): Query<any> => {
        if (!this.state.isTableInitialized) {
            query.page = 0;
            query.totalCount = 0;
        }
        return query;
    }

    private onSelect = (dropShipAddress: DropShipAddress, selected: boolean): void => {
        this.props.change("id", dropShipAddress ? dropShipAddress.id as number : 0);
        if (this.state.selectedDropShipAddress) {
            this.setState({
                selectedDropShipAddress: (this.state.selectedDropShipAddress.id === dropShipAddress.id && !selected) ?
                    null : dropShipAddress
            });
        }
        else if (selected) {
            this.setState({
                selectedDropShipAddress: dropShipAddress
            })
        }        
    }

    private receivedActionCompleteMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) ?
            true : false;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private reloadTable = () => {
        this.props.actions.clearMessage();
        this.tableElement.current.onQueryChange();
    }

    private resetForm = (): void => {
        this.props.reset();
        this.setState({
            isVisible: false
        });
    }

    private showTableLoader = (active: boolean, initialized: boolean, rowCount: number | undefined, total: number | undefined): void => {
        if (this.state.isTableLoading != active) {
            setTimeout(() => {
                this.setState({
                    isTableInitialized: initialized ? true : false,
                    isTableLoading: active ? true : false,
                    isTableError: false,
                    rowCount: rowCount,
                    total: total
                });
            });
        }
        if (this.props.onRetrievingAddresses) {
            this.props.onRetrievingAddresses(active);
        }
    }

    private showTableReloader = (errorMessage: string): void => {
        setTimeout(() => {
            this.setState({
                isTableInitialized: true,
                isTableLoading: false,
                isTableError: true
            });
        }, 500);
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        dropShipAddressesState: state.dropShipAddresses,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            DropShipAddressesStore.actionCreators,
            MessageStore.actionCreators), dispatch),
        asyncActions: {
            requestDropShipAddressesAsync: (client: Client, billto: string, addressId: number | null | undefined, query: Query<DropShipAddress>, broadcastError: boolean) =>
                dispatch(DropShipAddressesStore.actionCreators.requestDropShipAddresses(client, billto, addressId, query, broadcastError))
        }
    };
}

export default connect<{}, {}, DropShipAddressSelectorOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'dropShipAddressSelectorForm',
    validate: validateDropShipAddressSelectorForm,
    onSubmitFail: onFormValidationFailed,
    enableReinitialize: true
})(DropShipAddressSelector as any));