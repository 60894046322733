import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as UserStore from '../../store/User';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { BaseImage } from '../../common/BaseTypes';
import { Button, Col, Container, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import Loader from '../loader/Loader';
import PasswordReset from '../password-reset/PasswordReset';
import SlideShow from '../slide-show/SlideShow';

import './Login.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    userState: {} as UserStore.UserState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, UserStore.actionCreators)
};

type LoginProps =
    RouteComponentProps
    & InjectedFormProps
    & typeof applicationState     // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface LoginState {
    isSlideShowLoaded: boolean,
    isVisible: boolean,
    passwordIsVisible: boolean,
    submitting: boolean,
    credentialsAccepted: boolean,
    credentialsRejected: boolean,
    resettingPassword: boolean    
}

// ----------------
// FORM VALIDATOR

const validateLoginForm = (formValues: any): { title?: string, description?: string } => {    
    let errors: any = {};    
    if (!formValues.username) {
        errors.username = "Please enter your username";
    }
    if (!formValues.password) {
        errors.password = "Please enter your password";
    }    
    return errors;
}

class Login extends React.PureComponent<LoginProps, LoginState> {

    // ----------------
    // VARIABLES
    public passwordElement: any;

    public loginVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: LoginProps, state: LoginState) {
        super(props);
        let slideShowLoaded: boolean = this.getSlideShowImages().length == 0;
        this.state = {
            isSlideShowLoaded: slideShowLoaded,
            isVisible: (slideShowLoaded && this.props.clientState.client) ? true : false,
            passwordIsVisible: false,
            submitting: false,
            credentialsAccepted: false,
            credentialsRejected: false,
            resettingPassword: false
        };
        this.passwordElement = React.createRef();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
        if (this.state.isSlideShowLoaded && this.props.clientState.client) {
            this.setState({
                isVisible: true,
            });
            setTimeout(() => {
                if (this.props.clientState.client) {
                    this.props.change('clientCode', this.props.clientState.client.code);
                }
            });
        }
        if (this.state.submitting) {
            if (this.receivedErrorMessage()) {
                setTimeout(() => {
                    this.setState({
                        submitting: false,
                        credentialsAccepted: false,
                        credentialsRejected: false,
                    });
                });
            }
            else if (this.props.userState.user) {
                if (this.props.userState.user.isAuthenticated) {
                    setTimeout(() => {
                        this.setState({
                            submitting: false,
                            credentialsAccepted: true,
                            credentialsRejected: false,
                        });
                        setTimeout(() => {
                            this.props.history.push('/dashboard');
                        }, 1500);
                    });
                }
                else {
                    setTimeout(() => {
                        this.setState({
                            submitting: false,
                            credentialsAccepted: false,
                            credentialsRejected: true,
                        });
                        this.props.actions.clearUser();
                    });
                }
            }
        }
    }

    public componentWillUnmount = () => {
    }

    public handleFormSubmit = (values: any) => {
        this.setState({
            submitting: true,
            credentialsAccepted: false,
            credentialsRejected: false
        });
        this.props.actions.clearMessage();
        this.props.actions.loginUser(values);
    };

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={!this.state.isVisible} />
                <motion.div id="loginPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"} variants={this.loginVariants} transition={{ duration: 1.75 }}>
                    <Container>
                        <Row className="d-sm-none">
                            <Col className="login-hero-mobile pb-3 pl-0 pr-0">
                                <img src={this.getSmallHeroImage()} />
                            </Col>
                        </Row>
                        <Row className="justify-content-center justify-content-sm-start flex-nowrap">
                            <Col className="login-form pl-0 pr-0" sm={6} xs={12}>
                                <h2 className="page-title">Welcome</h2>
                                <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                    <Container>
                                        <Row>
                                            <Col className="pl-0 pr-0 pr-sm-3" lg={12} md={12}>
                                                <Field name="username" type="text" label="Username"
                                                    component={FieldWrapper.renderTextField}
                                                    maxLength={75} noSpaces={true} autoComplete={false} autoFocus={true}
                                                    disabled={this.state.submitting || this.state.credentialsAccepted}
                                                    onChange={this.onFieldChange} />
                                            </Col>
                                            <Col className="pl-0 pl-lg-0 pr-0 pr-sm-3" lg={12} md={12}>
                                                <Field name="password" type={this.state.passwordIsVisible ? "text" : "password"} label="Password"
                                                    component={FieldWrapper.renderPasswordField} maxLength={25}
                                                    passwordVisible={this.state.passwordIsVisible}
                                                    showPasswordHandler={this.showPassword}
                                                    reference={this.passwordElement}
                                                    disabled={this.state.submitting || this.state.credentialsAccepted}
                                                    onChange={this.onFieldChange} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Field name="clientCode" component="input" type="hidden" />
                                                <Field name="formName" component="input" type="hidden" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="button-bar pl-0 pr-0 pr-sm-3 pt-2">
                                                <Button type="submit" color="primary"
                                                    disabled={this.state.submitting || this.state.credentialsAccepted}>
                                                    {this.state.submitting ? "Working" : "Sign In"}
                                                </Button>
                                                <Button color="link" onClick={this.showPasswordReset}
                                                    disabled={this.state.submitting || this.state.credentialsAccepted}>
                                                    Forgot password?
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pl-0 pt-4 pr-0 pr-sm-3">
                                                <FormResult
                                                    failureResult={this.state.credentialsRejected}
                                                    successResult={this.state.credentialsAccepted}
                                                    description={this.getFormResult()} />
                                            </Col>
                                        </Row>
                                    </Container>
                                </form>
                                <PasswordReset isOpen={this.state.resettingPassword} onDismiss={this.hidePasswordReset} />
                            </Col>
                            <Col className="d-none d-sm-block login-hero">
                                <SlideShow showArrows={false} duration={3000} slides={this.getSlideShowImages()} onLoad={this.onLoadSlideShow} initialFadeIn={false} />
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private onFieldChange = (): void => {
        this.setState({
            credentialsRejected: false
        });
    }

    private getFormResult = (): string => {
        let formResult: string = '';
        if (this.state.credentialsRejected) {
            formResult = 'Invalid username or password';
        }
        else if (this.state.credentialsAccepted) {
            formResult = 'Complete';
        }
        return formResult;
    }

    private getSlideShowImages = (): BaseImage[] => {
        let slides: BaseImage[] = [];
        if (this.props.clientState.client) {
            slides = this.props.clientState.client.heroes.filter((h: ClientStore.ClientHero) => {
                return !h.smallScreen;
            });
        }
        return slides;
    }

    private getSmallHeroImage = (): string => {
        let url: string = '';
        if (this.props.clientState.client) {
            let smallHeroes: ClientStore.ClientHero[] = this.props.clientState.client.heroes.filter((h: ClientStore.ClientHero) => {
                return h.smallScreen === true;
            });
            if (smallHeroes.length > 0) {
                url = smallHeroes[0].url;
            }
        }
        return url;
    }

    private hidePasswordReset = (): void => {
        this.setState({
            resettingPassword: false
        });
    }

    private onLoadSlideShow = () => {
        setTimeout(() => {
            this.setState({
                isSlideShowLoaded: true,
                isVisible: this.props.clientState.client ? true : false
            });
        }, 1000);
    }

    private receivedErrorMessage = (): boolean | null => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ERROR);
    }

    private showPassword = (): void => {
        this.setState({
            passwordIsVisible: !this.state.passwordIsVisible,
        });
        this.passwordElement.current.focus();
    }

    private showPasswordReset = (): void => {
        this.props.actions.clearMessage();
        this.setState({
            resettingPassword: !this.state.resettingPassword
        });
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        userState: state.user,
        initialValues: {
            clientCode: (state.client && state.client.client) ? state.client.client.code : null
        }
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            UserStore.actionCreators), dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'loginForm',
    validate: validateLoginForm
})(Login as any));


