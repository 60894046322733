import * as React from 'react';
import { Product } from '../../store/Products';
import { Size, Style } from '../../common/ProductTypes';
import { ClientCode } from '../../enums/ClientCode';
import Checkbox from '../checkbox/Checkbox';
import ImageLoader from '../image-loader/ImageLoader';
import SizeRun from '../size-run/SizeRun';

import './ProductListboxRow.scss';

// ----------------
// PROPS

interface ProductListboxRowProps {
    product: Product;
    clientCode: ClientCode;
    shipDate: Date | null | undefined;
    isLoading: boolean;
    onSelectProduct: (product: Product, selected: boolean) => void;
    onChangeQuantity: (product: Product, size: Size) => void;
    clearSelections: boolean;
}

// ----------------
// LOCAL STATE

interface ProductListboxRowState {
    selected: boolean;
}

class ProductListboxRow extends React.PureComponent<ProductListboxRowProps, ProductListboxRowState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductListboxRowProps, state: ProductListboxRowState) {
        super(props);
        this.state = {
            selected: false
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: ProductListboxRowProps) => {
        if (this.props.clearSelections && !prevProps.clearSelections) {
            if (this.state.selected) {
                this.setState({
                    selected: false
                });
            }
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <div className={"product-listbox-row" + (this.state.selected ? " selected" : "")}>
                <div className="product-selector">
                    <Checkbox id={"checkbox_product_" + this.props.product.id} selected={this.state.selected} disabled={this.props.isLoading}
                        onChange={this.onSelectProduct} />
                </div>
                <div className="product-descriptor">
                    <div className="tag">
                        <div className="image">
                            <div className="product-image">
                                <div className="image-stretcher">
                                    <div className="image-canvas">
                                        <ImageLoader url={this.props.product.imageUrl} isLoading={this.props.isLoading} onLoad={this.onProductImageLoad} noFadeIn={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text">
                            <label className="category">{this.props.product.shortDescription}</label>
                            <label className="style-name">{this.props.product.style.name}</label>
                            <label className="color-description">{this.props.product.colorDescription}</label>
                            <div className="two-column">
                                <label className="price">${this.props.product.wholesale.toFixed(2)}</label>
                                <label className="availability">{this.props.product.availability}</label>
                            </div>
                        </div>
                    </div>
                    <div className="sizes">
                        <SizeRun dimension={this.props.product.dimensions[0]} description={this.props.product.sizeDescription}
                            displayOnly={!this.state.selected} readOnly={false} shipDate={this.props.shipDate} chaseDate={this.props.product.chaseDate}
                            useEmbroideredDate={false} onChangeQuantity={this.onChangeQuantity} />
                    </div>
                </div>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private onChangeQuantity = (size: Size): void => {
        this.props.onChangeQuantity(this.props.product, size);
    }

    private onProductImageLoad = (): void => {
    }

    private onSelectProduct = (checked: boolean): void => {
        this.setState({
            selected: checked
        });
        this.props.onSelectProduct(this.props.product, checked);
    }
}

// ----------------
// EXPORT

export default ProductListboxRow;
