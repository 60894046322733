import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as OrderInvoiceStore from '../../store/OrderInvoice';
import { Client } from '../../store/Client';
import { OrderDetail } from '../../store/Orders';
import { Message } from '../../store/Message';
import * as ErrorMessage from '../../common/ErrorMessage';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import Loader from '../loader/Loader';
import fileDownload from 'js-file-download';
import cloneDeep from 'lodash/cloneDeep';

import './DownloadOrder.scss';

// ----------------
// PROPS

const applicationState = {
    orderInvoiceState: {} as OrderInvoiceStore.OrderInvoiceState
}

const actionCreators = {
    actions: Object.assign({}, OrderInvoiceStore.actionCreators)
}

interface DownloadOrderOwnProps {
    client: Client;
    orderToDownload: OrderDetail | null;
    onDismiss: () => void;
}

type DownloadOrderProps =
    DownloadOrderOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface DownloadOrderState {
    emptyOrder: boolean;
    submitting: boolean;
    downloadSuccessful: boolean;
    downloadFailed: boolean;
    formResult: string | null;
}

class DownloadOrder extends React.PureComponent<DownloadOrderProps, DownloadOrderState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: DownloadOrderProps, state: DownloadOrderState) {
        super(props);
        this.state = {
            emptyOrder: false,
            submitting: false,
            downloadSuccessful: false,
            downloadFailed: false,
            formResult: null
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: DownloadOrderProps) => {
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <Modal id="download-order" size="sm" isOpen={this.props.orderToDownload != null}
                onOpened={this.initializeDialog.bind(this)} onClosed={this.resetDialog}>
                <ModalHeader toggle={this.props.onDismiss} className={this.state.submitting ? "disabled" : ""}>
                    Download Order
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className="pl-0 pr-0 order-identifier">
                                {this.props.orderToDownload && (
                                    <React.Fragment>
                                        <label>{this.props.orderToDownload.orderNumber}</label>
                                        <label>{this.props.orderToDownload.customerAccount? this.props.orderToDownload.customerAccount.nameOnly : this.props.orderToDownload.billToCustomer.nameOnly }</label>
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pr-0 mt-2 download-status">
                                <Loader isLoading={this.state.submitting} isChild={true} />
                                {this.state.emptyOrder && (
                                    <div className="invalid-order-message">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path strokeWidth="1" d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                            <line x1="12" y1="9" x2="12" y2="13"></line>
                                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                        </svg>
                                        <label>Order has no line items</label>
                                    </div>
                                )}
                                {this.state.downloadSuccessful && (
                                    <div className="download-message">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 4C4 3.44772 4.44772 3 5 3H14H14.5858C14.851 3 15.1054 3.10536 15.2929 3.29289L19.7071 7.70711C19.8946 7.89464 20 8.149 20 8.41421V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4Z" stroke="currentColor" strokeWidth="1" strokeLinecap="round" />
                                            <path d="M20 8H15V3" stroke="#200E32" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12 9L12 17" stroke="#200E32" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9 14L12 17L15 14" stroke="#200E32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <label>PDF saved to downloads folder</label>
                                    </div>
                                )}
                                {this.state.downloadFailed && (
                                    <Button color="primary" onClick={this.retry}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                            <polyline points="1 4 1 10 7 10"></polyline>
                                            <polyline points="23 20 23 14 17 14"></polyline>
                                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                        </svg>
                                        Retry
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={this.props.onDismiss} disabled={this.state.submitting || this.state.downloadFailed || this.state.emptyOrder}>
                                    OK
                                </Button>
                                {!this.state.downloadSuccessful && (
                                    <Button color="link" onClick={this.props.onDismiss} disabled={this.state.submitting}>
                                        Cancel
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.downloadFailed}
                                    successResult={this.state.downloadSuccessful}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private downloadFile = (): void => {
        if (this.props.orderToDownload) {
            let fetchError: boolean = false;
            let action: string = "orders/download";
            let url: string = `${action}/${this.props.orderToDownload.id}`;
            let orderDetail: OrderDetail = cloneDeep(this.props.orderToDownload);
            orderDetail.client = this.props.client;

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(orderDetail)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                this.setState({
                                    submitting: false,
                                    downloadFailed: true,
                                    formResult: errorMessage.text
                                });
                            })
                        )
                        fetchError = true;
                        throw new Error();
                    }
                    return response.blob();
                })
                .then(data => {
                    let fileName: string = this.formatFileName();
                    fileDownload(data, fileName);
                    this.setState({
                        submitting: false,
                        downloadSuccessful: true,
                        downloadFailed: false,
                        formResult: 'Download complete'
                    });
                    setTimeout(() => {
                        this.props.onDismiss();
                    }, 2000);
                },
                err => {
                    if (!fetchError) {
                        let errorMessage: Message = ErrorMessage.getFromError(err, action);
                        alert(errorMessage.text);
                    }
                });            
        }
    }

    private formatFileName = (): string => {
        let fileName: string = '';
        if (this.props.orderToDownload) {
            fileName = this.props.orderToDownload.orderNumber + '.pdf';
        }
        return fileName;
    }

    private initializeDialog = (): void => {
        if (this.props.orderInvoiceState.orderInvoice) {
            if (this.props.orderInvoiceState.orderInvoice.items.length > 0) {
                this.setState({
                    submitting: true,
                    downloadSuccessful: false,
                    downloadFailed: false,
                    formResult: null
                });
                this.downloadFile();
            }
            else {
                this.setState({
                    emptyOrder: true,
                    submitting: false,
                    downloadSuccessful: false,
                    downloadFailed: false,
                    formResult: null
                });
            }
        }
        else {
            setTimeout(() => {
                this.initializeDialog();
            }, 250);
        }
    }

    private resetDialog = (): void => {
    }

    private retry = () => {
        this.initializeDialog();
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        orderInvoiceState: state.orderInvoice
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            OrderInvoiceStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, DownloadOrderOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(DownloadOrder as any);
