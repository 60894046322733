import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as OrderInvoiceStore from '../../store/OrderInvoice';
import { OrderItem } from '../../store/OrderItems';
import { motion } from 'framer-motion';
import { formatNumberAsCurrency, formatNumberAsCurrencyForCountry } from '../../common/CurrencyFormatter';
import { formatNumberWithCommas } from '../../common/NumberFormatter';

import './OrderItemTotal.scss';

// ----------------
// PROPS

const applicationState = {
    orderInvoiceState: {} as OrderInvoiceStore.OrderInvoiceState
}

const actionCreators = {
    actions: Object.assign({}, OrderInvoiceStore.actionCreators)
}

interface OrderItemTotalOwnProps {
    orderItem: OrderItem;
}

type OrderItemTotalProps =
    OrderItemTotalOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface OrderItemTotalState {
    isVisible: boolean;
    invoiceItem: OrderInvoiceStore.OrderInvoiceItem | undefined;
}

class OrderItemTotal extends React.PureComponent<OrderItemTotalProps, OrderItemTotalState> {

    // ----------------
    // VARIABLES

    public orderItemTotalVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderItemTotalProps, state: OrderItemTotalState) {
        super(props);
        this.state = {
            isVisible: this.props.orderInvoiceState.orderInvoice ? true : false,
            invoiceItem: this.getInvoiceItem()
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: OrderItemTotalProps) => {
        if (!this.state.isVisible && this.props.orderInvoiceState.orderInvoice) {
            this.setState({
                isVisible: true,
                invoiceItem: this.getInvoiceItem()
            });
        }
        else if (this.props.orderInvoiceState.orderInvoice) {
            this.setState({
                invoiceItem: this.getInvoiceItem()
            });
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <motion.div className="order-item-total" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"}
                variants={this.orderItemTotalVariants} transition={{ duration: 0.75 }}>
                <label className="price">{this.getTotalPrice()}</label>
                <label className="quantity">{this.getTotalUnits()} unit{this.isSingleUnit() ? '' : 's'}</label>
            </motion.div>
        );
    }

    // ----------------
    // HELPERS

    private getInvoiceItem = (): OrderInvoiceStore.OrderInvoiceItem | undefined => {
        let orderInvoiceItem: OrderInvoiceStore.OrderInvoiceItem | undefined = undefined;
        if (this.props.orderInvoiceState.orderInvoice) {
            let matches: OrderInvoiceStore.OrderInvoiceItem[] = this.props.orderInvoiceState.orderInvoice.items.filter((item: OrderInvoiceStore.OrderInvoiceItem) => {
                return item.orderItemId === this.props.orderItem.id;
            });
            if (matches.length > 0) {
                orderInvoiceItem = matches[0];
            }
        }
        return orderInvoiceItem;
    }

    private getTotalPrice = (): string => {
        let price: number = this.state.invoiceItem ? this.state.invoiceItem.price : 0;
        return formatNumberAsCurrencyForCountry("US", price, true);
    }

    private getTotalUnits = (): string => {
        let units: number = this.state.invoiceItem ? this.state.invoiceItem.quantity : 0;
        return formatNumberWithCommas(units);
    }

    private isSingleUnit = (): boolean => {
        let isSingle: boolean = false;
        if (this.state.invoiceItem) {
            isSingle = this.state.invoiceItem.quantity === 1 ? true : false;
        }
        return isSingle;
    }
}


// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        orderInvoiceState: state.orderInvoice
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            OrderInvoiceStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, OrderItemTotalOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(OrderItemTotal as any);
