import * as React from 'react';
import { CustomerAccount } from '../../store/Customers';
import { Logo } from '../../common/EmbroideryTypes';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row, Table} from 'reactstrap';
import ImageLoader from '../image-loader/ImageLoader';
import { formatNumberAsCurrency, formatNumberAsCurrencyForCountry } from '../../common/CurrencyFormatter';
import { formatNumberWithCommas } from '../../common/NumberFormatter';

import './TapeViewer.scss';
import { EmbroideryTapeType } from '../../enums/EmbroideryTapeType';

// ----------------
// PROPS

interface TapeViewerProps {
    isOpen: boolean;
    onDismiss: (selected: boolean) => void;
    tape: Logo;
    readonly?: boolean;
}

// ----------------
// LOCAL STATE

interface TapeViewerState {
    initialized: boolean;
}

class TapeViewer extends React.PureComponent<TapeViewerProps, TapeViewerState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: TapeViewerProps, state: TapeViewerState) {
        super(props);
        this.state = {
            initialized: false
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: TapeViewerProps) => { }

    public componentWillUnmount = () => {}

    public render = () => {
        return (
            <Modal id="tape-viewer" isOpen={this.props.isOpen} onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(false)}>
                    Embroidery Detail
                </ModalHeader>
                <ModalBody>
                    <Container>
                        {this.props.tape.logoFileName && (
                            <Row>
                                <Col className="pl-0 pr-0 customer-tape">
                                    <div className={"tape-image" + (this.props.tape.width > this.props.tape.height ? " max-width" : "")}>
                                        <div className="tape-image-stretcher">
                                            <ImageLoader url={this.props.tape.logoUrl} isLoading={false} onLoad={this.onSpacerImageLoad} />
                                            <div className="tape-image-canvas">
                                                {this.state.initialized && (
                                                    <ImageLoader url={this.props.tape.logoUrl} isLoading={false} autoSize={true} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col className={"pl-0 pr-0 pb-0 customer-tape-parameters" + (this.props.tape.logoFileName ? "" : " flush-top")}>
                                <Table size="sm" borderless>
                                    <tbody>
                                        {this.props.tape.tapeType === EmbroideryTapeType.New && (
                                            <tr>
                                                <td colSpan={4} className="new-logo">New Logo - Approval Pending</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td className="name">Tape #</td>
                                            <td className="value" colSpan={3}>{this.props.tape.tapeNumber}</td>
                                        </tr>
                                        {this.props.tape.description && (
                                            <tr>
                                                <td className="name">Description</td>
                                                <td className="value" colSpan={3}>{this.props.tape.description}</td>
                                            </tr>
                                        )}
                                        {this.hasDimensions(this.props.tape) && (
                                            <tr>
                                                <td className="name">Dimensions</td>
                                                <td className="value first-of-two">{this.props.tape.dimensions}</td>
                                                <td className="name">Stitches</td>
                                                <td className="value">{formatNumberWithCommas(this.props.tape.stitchCount)}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td className="name">Price</td>
                                            <td className="name" colSpan={3}>{this.getPrice(this.props.tape)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={() => this.props.onDismiss(true)}>
                                    {this.props.readonly ? 'OK' : 'Select'}
                                </Button>
                                {!this.props.readonly && (
                                    <Button color="link" onClick={() => this.props.onDismiss(false)}>
                                        Cancel
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private getPrice = (logo: Logo): string => {
        let price: string = logo.price > 0 ?
            formatNumberAsCurrencyForCountry('US', logo.price, true) :
            'No additional fee';
        return price;
    }

    private hasDimensions = (logo: Logo): boolean => {
        let dimensions: boolean = logo.logoFileName ? true : false;
        if (dimensions) {
            dimensions = logo.width && logo.width > 0 ? true : false;
        }
        return dimensions;
    }

    private initializeForm = (): void => { }

    private onSpacerImageLoad = (): void => {
        this.setState({
            initialized: true
        });
    }

    private resetForm = (): void => {
        this.setState({
            initialized: false
        })
    }
}

// ----------------
// EXPORT

export default TapeViewer;
