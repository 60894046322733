import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as ProductlistItemsStore from '../../store/ProductlistItems';
import * as ProductlistsStore from '../../store/Productlists';
import { ProductlistDetail } from '../../store/Productlists';
import { ProductlistItem } from '../../store/ProductlistItems';
import { ClientCode } from '../../enums/ClientCode';
import { ProductlistType } from '../../enums/ProductlistType';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps, BaseFieldProps } from 'redux-form';
import { Button, Col, Container, Row } from 'reactstrap';
import Loader from '../loader/Loader';
import DetailTitleBlock from '../detail-title-block/DetailTitleBlock';
import { TitleBlock } from '../../common/ComponentTypes';
import AddProductlist from '../add-productlist/AddProductlist';
import CopyProductlist from '../copy-productlist/CopyProductlist';
import PublishProductlist from '../publish-productlist/PublishProductlist';
import ProductlistItemDescription from '../productlist-item-description/ProductlistItemDescription';
import { convertJSDateToString } from '../../common/DateConverter';
import cloneDeep from 'lodash/cloneDeep';
import $ from 'jquery';

import './EditProductlist.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    productlistsState: {} as ProductlistsStore.ProductlistsState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, ProductlistsStore.actionCreators)
};

interface EditProductlistAsyncActions {
    asyncActions: {
        requestProductlistDetailAsync: (client: ClientStore.Client, id: number) => Promise<ProductlistDetail | null | undefined>,
        requestProductlistItemsAsync: (client: ClientStore.Client, productlistDetail: ProductlistDetail, sortBy: string) => Promise<ProductlistItem[] | null | undefined>
    }
}

interface MatchParams {
    id: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> { }

type EditProductlistProps =
    MatchProps
    & InjectedFormProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & EditProductlistAsyncActions;

// ----------------
// LOCAL STATE

interface EditProductlistState {
    isVisible: boolean;
    isLoadError: boolean;
    isLoadWarning: boolean;
    isReloading: boolean;
    isListLoading: boolean;
    isListInitialized: boolean;
    isEditing: boolean;
    isPublishing: boolean;
    productlistDetail: ProductlistDetail | null | undefined;
    productlistItems: ProductlistItem[] | null | undefined;
    productlistToCopy: ProductlistDetail | null | undefined;
    sortOptions: FieldWrapper.OptionValue[];
    sortOptionFlag: boolean;
    selectedSortOption: FieldWrapper.OptionValue | null | undefined;
    titleBlock: TitleBlock;
}

// ----------------
// FORM VALIDATOR

const validateEditProductListForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    return errors;
}

class EditProductlist extends React.PureComponent<EditProductlistProps, EditProductlistState> {

    // ----------------
    // VARIABLES

    public menubar: JQuery<HTMLElement>;
    public headerElement: any;
    public submitButton: React.RefObject<HTMLButtonElement>;

    public editProductlistVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: EditProductlistProps, state: EditProductlistState) {
        super(props);
        this.state = {
            isVisible: this.hasSelectedProductlist() ? true : false,
            isLoadError: false,
            isLoadWarning: false,
            isReloading: false,
            isListLoading: false,
            isListInitialized: false,
            isEditing: false,
            isPublishing: false,
            productlistDetail: this.hasSelectedProductlist() ? this.props.productlistsState.productlistDetail : undefined,
            productlistItems: undefined,
            productlistToCopy: undefined,
            sortOptions: [],
            sortOptionFlag: false,
            selectedSortOption: undefined,
            titleBlock: this.getTitleBlock(this.props.productlistsState.productlistDetail)
        }
        this.menubar = $('#top-nav-menubar');
        this.headerElement = React.createRef<Element>();
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.ensureDataFetched();
        this.configureMutationObserver();
        if (this.props.clientState.client && !this.state.selectedSortOption) {
            let defaultSortOption: FieldWrapper.OptionValue | null | undefined = this.getDefaultSortOption();
            this.setState({
                sortOptions: this.getSortOptions(),
                selectedSortOption: defaultSortOption
            })
            this.props.change('sortOrder', defaultSortOption ? defaultSortOption.label : null);
        }
    }

    public componentDidUpdate = (prevProps: EditProductlistProps) => {
        if (!this.state.isVisible) {
            if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
                this.setState({
                    isReloading: false
                });
                setTimeout(() => {
                    this.setState({
                        isLoadError: true,
                        isLoadWarning: this.receivedWarningMessage()
                    })
                })
            }
            else if (this.hasSelectedProductlist()) {
                this.setState({
                    isVisible: true,
                    isLoadError: false,
                    isLoadWarning: false,
                    isReloading: false,
                    productlistDetail: this.props.productlistsState.productlistDetail
                })
            }
        }
        else if (this.refreshRequired()) {
            this.props.actions.clearMessage();
            this.props.actions.closeProductlist();
            this.setState({
                isVisible: false,
                productlistDetail: null
            });
            setTimeout(() => {
                this.reloadDetail();
            }, );
        }
        else if (this.props.clientState.client && !this.state.selectedSortOption) {
            setTimeout(() => {
                let defaultSortOption: FieldWrapper.OptionValue | null | undefined = this.getDefaultSortOption();
                this.setState({
                    sortOptions: this.getSortOptions(),
                    selectedSortOption: defaultSortOption
                });                
                this.props.change('sortOrder', defaultSortOption ? defaultSortOption.label : null);
            });
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        if (this.props.clientState.client && this.state.productlistDetail) {
            this.getProductlistItems(this.props.clientState.client, this.state.productlistDetail);
        }
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={(!this.state.isVisible && !this.state.isLoadError) || this.state.isReloading || !this.state.isListInitialized} />
                <motion.div id="editProductlistPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"}
                    initial={"hidden"} variants={this.editProductlistVariants} transition={{ duration: 0.75 }}>
                    <div className="fixed-content" ref={this.headerElement}>                        
                        <Container>
                            <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                <Row className="justify-content-start">
                                    <Col className="pl-0 pr-0">
                                        <DetailTitleBlock titleBlock={this.state.titleBlock} disabled={false} />
                                        {this.state.productlistDetail && this.state.productlistDetail.isEditable && (
                                            <div className="button-bar">
                                                <Button type="button" className="btn-copy" color="secondary" onClick={this.copyList}>
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-copy">
                                                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                                        </svg>
                                                    </span>
                                                    <label>Copy</label>
                                                </Button>
                                                <Button type="button" className="btn-edit" color="secondary" onClick={this.editList}>
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-edit-3">
                                                            <path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                                        </svg>
                                                    </span>
                                                    <label>Edit</label>
                                                </Button>
                                                <Button type="button" className="btn-add-item" color="secondary" onClick={this.addItem}>
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-plus">
                                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                                        </svg>
                                                    </span>
                                                    <label>Add</label>
                                                </Button>
                                                <Button type="button" className="btn btn-publish" color="success" onClick={this.publishList}
                                                    disabled={(this.state.productlistItems && this.state.productlistItems.length == 0) ? true : false}>
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-arrow-up-circle">
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                            <polyline points="16 12 12 8 8 12"></polyline>
                                                            <line x1="12" y1="16" x2="12" y2="8" strokeWidth="3"></line>
                                                        </svg>
                                                    </span>
                                                    <label>Publish</label>
                                                </Button>
                                            </div>
                                        )}
                                        {this.state.productlistDetail && !this.state.productlistDetail.isEditable && (
                                            <div className="last-updated">
                                                <label className="owner">{this.state.productlistDetail.createdBy}<span>|</span></label>
                                                <label>Updated {convertJSDateToString(this.state.productlistDetail.dateLastModified, "M/d/yy")}</label>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="list-header">                                
                                    <Col className="customer-name pl-0 pt-0 pb-0 text-sm-left d-none d-sm-block" sm={7} md={8} lg={9}>
                                        {this.getCustomerAssignment()}
                                    </Col>
                                    <Col className="sort-order pr-0 pt-0 pb-0 text-xs-center text-sm-right" xs={12} sm={5} md={4} lg={3}>
                                        <div className="field-container">
                                            <div className="name">Sort by</div>
                                            <div className="value">
                                                <Field name="sortOrder" label="" component={FieldWrapper.renderDropdownField}
                                                    open={this.state.sortOptionFlag}
                                                    options={this.state.sortOptions} 
                                                    onToggle={this.onToggleSortOptions} onSelectOption={this.onSelectSortOption}
                                                    disabled={this.state.isListLoading || !this.state.isListInitialized} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <button type="submit" ref={this.submitButton}>Submit</button>
                            </form>
                        </Container>
                    </div>
                    <Container className="scrolling-content">
                        <Row>
                            <Col className="pl-0 pr-0">
                                {this.state.productlistItems && !this.hasItems() && (
                                    <label className="no-items">No items selected</label>
                                )}
                                {this.state.productlistItems && this.hasItems() && (
                                    <React.Fragment>
                                        {this.state.productlistItems.map((productlistItem: ProductlistItem, itemIndex: number) => (
                                            <div className="item" key={"listItem-" + itemIndex}>
                                                <ProductlistItemDescription productlistItem={productlistItem}                                                     
                                                    onDelete={(deletedItem: ProductlistItem) => this.onDeleteProductlistItem(deletedItem, itemIndex)}
                                                    onViewProduct={this.goToProduct} />
                                            </div>
                                        ))}
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
                {this.state.isLoadError && (
                    <Container id="editProductlistPageError" className={this.state.productlistDetail ? "no-margin" : ""}>
                        <Row className="justify-content-center">
                            <Col className={"page-error" + (this.state.isLoadWarning ? " warning" : "") + (this.state.productlistDetail ? "" : " pt-4")} xs={"auto"}>
                                <Button color="primary" onClick={this.reloadDetail}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                        <polyline points="1 4 1 10 7 10"></polyline>
                                        <polyline points="23 20 23 14 17 14"></polyline>
                                        <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                    </svg>
                                    Reload
                                </Button>
                                <Button color="link" onClick={this.goBack}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-left">
                                        <polyline points="15 18 9 12 15 6"></polyline>
                                    </svg>
                                    Go Back
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                )}
                {this.props.productlistsState.productlistDetail && (
                    <React.Fragment>
                        <AddProductlist isOpen={this.state.isEditing} onDismiss={this.hideEditProductlist}
                            editProductlist={this.props.productlistsState.productlistDetail} />
                        <CopyProductlist client={this.props.productlistsState.productlistDetail.client}
                            productlistToCopy={this.state.productlistToCopy} onDismiss={this.hideCopyProductlist} />
                        <PublishProductlist isOpen={this.state.isPublishing} onDismiss={this.hidePublishProductlist}
                            productlistDetail={this.props.productlistsState.productlistDetail} />
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }

    // ----------------
    // HELPERS

    private addItem = (): void => {
        this.props.history.push('/products');
    }

    private configureMutationObserver = (): void => {
        if (this.menubar.length > 0) {
            let options: any = {
                attributes: true
            };
            const observer = new window.MutationObserver(this.mutationHandler);
            observer.observe(this.menubar[0], options);        }
        else {
            this.menubar = $('#top-nav-menubar');
            setTimeout(() => {
                this.configureMutationObserver();
            }, 400);
        }
    }

    private copyList = (): void => {
        this.props.actions.clearMessage();
        if (this.state.productlistDetail) {
            this.setState({
                productlistToCopy: cloneDeep(this.state.productlistDetail)
            });
        }
    }

    private editList = (): void => {
        this.props.actions.clearMessage();
        this.setState({
            isEditing: true
        });
    }

    private ensureDataFetched = (): void => {
        let productlistId: number = parseInt(this.props.match.params.id);
        let productlistInStore: boolean = this.productlistInStore(productlistId);
        if (productlistInStore) {
            this.setState({
                isVisible: true,
                isLoadError: false,
                isLoadWarning: false,
                isReloading: false,
                productlistDetail: cloneDeep(this.props.productlistsState.productlistDetail)
            });
            this.setDefaultFocus();
            if (this.props.productlistsState.productlistDetail && this.props.clientState.client) {
                this.getProductlistItems(this.props.clientState.client, this.props.productlistsState.productlistDetail);
            }
            else {
                setTimeout(() => {
                    this.ensureDataFetched();
                }, 200);
            }
        }
        else {
            if (this.props.clientState.client) {
                this.getProductlist(productlistId, this.props.clientState.client);
            }
            else {
                setTimeout(() => {
                    this.ensureDataFetched();
                }, 200);
            }
        }
    }

    private getCustomerAssignment = (): string => {
        let customerAssignment: string = '';
        if (this.state.productlistDetail) {
            customerAssignment = this.state.productlistDetail.customerNameOnly || 'No Customer Assigned';
        }
        return customerAssignment;
    }

    private getDefaultSortOption = (): FieldWrapper.OptionValue | null | undefined => {
        let defaultOption: FieldWrapper.OptionValue | null | undefined = undefined;
        if (this.state.sortOptions.length > 0) {
            defaultOption = this.state.sortOptions[0];
        }
        return defaultOption;
    }

    private getDefaultSortOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        options.push({ label: 'Name', value: 'style.name' });
        options.push({ label: 'Style Code', value: 'style.code' })
        options.push({ label: 'Garment Type', value: 'garmentType.code' });
        return options;
    }

    private getGTNSortOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        options.push({ label: 'Style', value: 'style.name' });
        options.push({ label: 'SKU', value: 'style.code' });
        options.push({ label: 'Category', value: 'garmentType.code' })
        return options;
    }

    private getProductlist = (productlistId: number, client: ClientStore.Client): void => {
        this.props.asyncActions.requestProductlistDetailAsync(client, productlistId)
            .then(result => {
                this.setState({
                    isVisible: true,
                    isLoadError: false,
                    isLoadWarning: false,
                    isReloading: false,
                    productlistDetail: cloneDeep(result),
                    titleBlock: this.getTitleBlock(result)
                });
                this.setDefaultFocus();
                this.getProductlistItems(client, result as ProductlistDetail);
            },
            err => {
            });
    }

    private getProductlistItems = (client: ClientStore.Client, productlistDetail: ProductlistDetail): void => {
        this.showListLoader(true, this.state.isListInitialized, false);
        let sortBy: string = this.state.selectedSortOption ? this.state.selectedSortOption.value : "style.name";
        this.props.asyncActions.requestProductlistItemsAsync(client, productlistDetail, sortBy)
            .then(result => {
                this.setState({
                    productlistItems: result
                });
                this.showListLoader(false, true, false);
            },
            err => {
                this.showListLoader(false, true, true);
            }
        )
    }

    private getSortOptionByName = (label: string): FieldWrapper.OptionValue | null | undefined => {
        let sortOption: FieldWrapper.OptionValue | null | undefined = undefined;
        if (this.state.sortOptions) {
            let matches: FieldWrapper.OptionValue[] = this.state.sortOptions.filter((fo: FieldWrapper.OptionValue) => {
                return fo.label === label;
            });
            if (matches.length > 0) {
                sortOption = matches[0];
            }
        }
        return sortOption;
    }

    private getSortOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        if (this.props.clientState.client) {
            switch (this.props.clientState.client.code) {
                case ClientCode.GTN:
                    options = this.getGTNSortOptions();
                    break;
                default:
                    options = this.getDefaultSortOptions();
                    break;
            }
        }
        return options;
    }

    private getTitleBlock = (productlistDetail: ProductlistDetail | null | undefined): TitleBlock => {
        let titleBlock: TitleBlock = {
            header: undefined,
            title: undefined,
            subtitle: undefined,
            link: '/productlists'
        };
        if (productlistDetail) {
            titleBlock.header = "List " + productlistDetail.id;
            titleBlock.title = productlistDetail.name;
            titleBlock.subtitle = ProductlistType[productlistDetail.listType]
        }
        return titleBlock;
    }

    private goBack = () => {
        this.props.actions.clearMessage();
        this.setState({
            isVisible: false,
            isLoadError: false,
            isLoadWarning: false,
            isReloading: false,
            productlistDetail: undefined
        })
        setTimeout(() => {
            this.props.history.push('/productlists');
        }, 400);
    }

    private goToCopy = (id: number) => {
        this.props.actions.clearMessage();
        let path: string = '/productlists/edit/' + id.toString();
        setTimeout(() => {
            this.props.history.push(path);
        }, 200);
    }

    private goToProduct = (productlistItem: ProductlistItem): void => {
        if (this.state.productlistDetail) {
            let currentPath: string = '/productlists/edit/' + this.state.productlistDetail.id;
            this.props.history.push('/products/detail/' + encodeURIComponent(productlistItem.sanitizedProductId), { from: currentPath });
        }
    }

    private hasItems = (): boolean => {
        return (this.state.isListInitialized && this.state.productlistItems && this.state.productlistItems.length > 0 ? true : false);
    }

    private hasSelectedProductlist = (): boolean => {
        let isSelected: boolean = false;

        return isSelected;
    }

    private hideCopyProductlist = (copyProductlistId: number | null | undefined): void => {
        this.setState({
            productlistToCopy: null
        });
        if (copyProductlistId) {
            setTimeout(() => {
                this.goToCopy(copyProductlistId as number);
            }, 400);
        }
    }

    private hideEditProductlist = (id: number | null | undefined): void => {
        this.setState({
            isEditing: false,
            productlistDetail: id ? this.props.productlistsState.productlistDetail : this.state.productlistDetail,
            titleBlock: id ? this.getTitleBlock(this.props.productlistsState.productlistDetail) : this.state.titleBlock
        });
    }

    private hidePublishProductlist = (): void => {
        this.setState({
            isPublishing: false
        });
    }

    private mutationHandler = (entries: any, observer: any) => {
        if (entries && entries.length > 0) {
            for (let n: number = 0; n < entries.length; n++) {
                if (entries[n].type === 'attributes' && entries[n].attributeName === 'class') {
                    if (this.headerElement.current) {
                        let x = this.headerElement.current;
                        let h: number = this.headerElement.current.clientHeight;
                        let p: number = this.headerElement.current.parentElement.style.paddingTop || 0;
                        let t: number = $('#top-nav-menubar').height() || 0;

                        if (this.menubar.hasClass('fixed')) {
                            this.headerElement.current.style.position = 'fixed';
                            this.headerElement.current.style.top = t + 'px';
                            this.headerElement.current.className = 'fixed-content fixed';
                            this.headerElement.current.parentElement.style.paddingTop = h + 'px';                           
                        }
                        else {
                            this.headerElement.current.style.position = 'relative';
                            this.headerElement.current.style.top = '0px';
                            this.headerElement.current.className = 'fixed-content';
                            this.headerElement.current.parentElement.style.paddingTop ='0px';
                        }
                    }
                }
            }
        }
    }

    private onDeleteProductlistItem = (deletedItem: ProductlistItem, itemIndex: number): void => {
        let listItems: ProductlistItem[] | null | undefined = cloneDeep(this.state.productlistItems);
        if (listItems) {
            listItems.splice(itemIndex, 1);
            this.setState({
                productlistItems: listItems
            });
        }
    }

    private onSelectSortOption = (selectedOption: FieldWrapper.OptionValue): void => {
        if (selectedOption) {
            this.props.change('sortOrder', selectedOption.label);
            this.setState({
                selectedSortOption: selectedOption
            })
        }
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        }, 200);
    }

    private onToggleSortOptions = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {       
        let sortOptionFlag: boolean = this.state.sortOptionFlag ? false : true;
        if (sortOptionFlag) {
            this.props.change('sortOrder', 'Select...');
        }
        this.setState({
            sortOptionFlag: sortOptionFlag
        });
        if (!sortOptionFlag) {
            setTimeout(() => {
                if (this.state.selectedSortOption) {
                    this.props.change('sortOrder', this.state.selectedSortOption.label);
                }
            })
        }
    }

    private productlistInStore = (id: number): boolean => {
        let inStore: boolean = false;
        if (this.props.productlistsState.productlistDetail) {
            inStore = (this.props.productlistsState.productlistDetail.id === id);
        }
        return inStore;
    }

    private publishList = (): void => {
        this.props.actions.clearMessage();
        this.setState({
            isPublishing: true
        });
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private refreshRequired = (): boolean => {
        let routeParam: number = parseInt(this.props.match.params.id);
        return (this.props.productlistsState.productlistDetail && this.props.productlistsState.productlistDetail.id != routeParam) ? true : false;
    }

    private reloadDetail = (): void => {
        this.props.actions.clearMessage();
        if (!this.state.productlistDetail) {
            let productlistId: number = parseInt(this.props.match.params.id);
            setTimeout(() => {
                this.setState({
                    isReloading: true,
                    isLoadError: false,
                    isLoadWarning: false
                });
                this.getProductlist(productlistId, this.props.clientState.client as ClientStore.Client);
            }, 200);
        }
        else {
            setTimeout(() => {
                this.setState({
                    isLoadError: false,
                    isLoadWarning: false
                });
                if (this.props.clientState.client) {
                    this.getProductlistItems(this.props.clientState.client, this.state.productlistDetail as ProductlistDetail);
                }
            });            
        }
    }

    private setDefaultFocus = (): void => {
    }

    private showListLoader = (active: boolean, initialized: boolean, loadError: boolean) => {
        this.setState({
            isListLoading: active ? true : false,
            isListInitialized: initialized ? true : false,
            isLoadError: loadError ? true : false
        });
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        productlistsState: state.productlists
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            ProductlistsStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestProductlistDetailAsync: (client: ClientStore.Client, id: number) =>
                dispatch(ProductlistsStore.actionCreators.requestProductlistDetail(client, id)),
            requestProductlistItemsAsync: (client: ClientStore.Client, productlistDetail: ProductlistDetail, sortBy: string) =>
                dispatch(ProductlistItemsStore.actionCreators.requestProductlistItems(client, productlistDetail, sortBy))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'editProductListForm',
    validate: validateEditProductListForm,
    enableReinitialize: true
})(EditProductlist as any));
