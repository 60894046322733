import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import * as OrderItemsStore from '../../store/OrderItems';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { OrderDates } from '../../store/OrderDates';
import { AvailableUnits, Dimension, InventoryDate, Size } from '../../common/ProductTypes';
import { Button, Col, Container, Row } from 'reactstrap';
import MaterialTable, { Column, Filter, Options, Query, QueryResult, MTableToolbar } from 'material-table';
import { BaseTableFilter } from '../../common/BaseTypes';
import { FilterEx } from '../../common/ExtendedTypes';
import TableFilter from '../table-filter/TableFilter';
import * as TableIcons from '../table-icons/TableIcons';
import Loader from '../loader/Loader';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../date-picker-input/DatePickerInput';
import OrderItemForm from '../order-item-form/OrderItemForm';
import { getEarliestShipDate, getEarliestCancelDate } from '../../common/OrderFunctions';
import cloneDeep from 'lodash/cloneDeep';

import './OrderDetail.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrdersStore.actionCreators, OrderItemsStore.actionCreators)
}

interface OrderDetailAsyncActions {
    asyncActions: {
        requestOrderItemsAsync: (orderDetail: OrdersStore.OrderDetail, query: Query<any>) => Promise<QueryResult<any>>;
    }
}

interface OrderDetailOwnProps {
    isActive: boolean;
    isOpenOrder: boolean;
    orderDates: OrderDates;
    orderDetail: OrdersStore.OrderDetail;
    onAddItem: () => void;
    onChangeShipDate: (shipDate: Date, cancelDate: Date | null | undefined) => void;
    onViewProduct: (orderItem: OrderItemsStore.OrderItem) => void;
    orderItemToDelete: OrderItemsStore.OrderItem | null | undefined;
    orderItemToEdit: OrderItemsStore.OrderItem | null | undefined;    
}

type OrderDetailProps =
    OrderDetailOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & OrderDetailAsyncActions;

// ----------------
// LOCAL STATE

interface OrderDetailState {
    isVisible: boolean;
    isTableInitialized: boolean;
    isTableLoading: boolean;
    isTableError: boolean;
    columns: any[],
    operators: FilterEx[];
    options: Options<any>;
    rowCount: number | undefined;
    total: number | undefined;
    orderItems: OrderItemsStore.OrderItem[];
}

class OrderDetail extends React.PureComponent<OrderDetailProps, OrderDetailState> {

    // ----------------
    // VARIABLES

    public tableElement: any;

    public orderItemsVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderDetailProps, state: OrderDetailState) {
        super(props);
        this.state = {
            isVisible: false,
            isTableInitialized: false,
            isTableLoading: false,
            isTableError: false,
            columns: this.getColumnDefinitions(),
            operators: [],
            options: {
                debounceInterval: 500,
                filtering: true,
                initialPage: 0,
                pageSize: 10,
                pageSizeOptions: [],
                showTitle: false,
                search: false,
                searchAutoFocus: false,
                tableLayout: "fixed",
                toolbar: false,
                thirdSortClick: false
            },
            rowCount: undefined,
            total: undefined,
            orderItems: [],
        };
        this.tableElement = React.createRef();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.setState({
            isVisible: true
        })
    }

    public componentDidUpdate = (prevProps: OrderDetailProps) => {
        if (this.props.orderDetail.id != prevProps.orderDetail.id) {
            this.reloadTable();
        }
        else if (this.editItemChanged(prevProps) || this.deleteItemChanged(prevProps)) {
            this.reloadTable();
        }
        else if (this.props.isOpenOrder != prevProps.isOpenOrder) {
            this.reloadTable();
        }
    }
    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <div className="order-detail">
                <Loader isLoading={this.state.isTableLoading || !this.state.isTableInitialized || !this.state.isVisible} isChild={false} />
                <motion.div animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"}
                    variants={this.orderItemsVariants} transition={{ duration: 0.75, delay: 0.1 }}>
                    <Container>
                        {this.props.isOpenOrder && (
                            <Row className={"table-actions " + (this.state.isTableLoading ? " loading" : "")}>
                                <Col className="pl-0 pb-0 mb-2 status-bar">
                                    <div className="ship-date">
                                        <label>Ship Date</label>
                                        <DatePicker selected={this.props.orderDetail.shipDate} onChange={this.onChangeShipDate}
                                            customInput={React.createElement(React.forwardRef(this.getDatePickerInput))}
                                            autoComplete={"autocomplete_order_detail_shipdate"} isClearable={true} clearButtonClassName={"datepicker-input-clear-button"}
                                            dateFormat={"M/d/yyyy"} minDate={getEarliestShipDate(this.props.orderDetail, this.props.orderDates)}
                                            wrapperClassName={"ship-date-wrapper"} />
                                    </div>
                                </Col>
                                <Col className="pr-0 mb-2 button-bar text-right" xs={"auto"}>
                                    <Button type="button" color="secondary" onClick={this.props.onAddItem}>
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-plus">
                                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                            </svg>
                                        </span>
                                        <label>Add Item</label>
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <Row className={"table-container" + (this.state.isTableError ? " load-error" : "") + (this.state.isTableLoading ? " loading" : "") + (this.props.isOpenOrder ? "" : " no-input")}>
                            <Col className="pl-0 pr-0">
                                <MaterialTable
                                    tableRef={this.tableElement}
                                    columns={this.state.columns}
                                    data={this.getOrderItems}
                                    icons={TableIcons.icons}
                                    options={this.state.options}
                                    components={{
                                        OverlayError: props => (
                                            <span></span>
                                        ),
                                        OverlayLoading: props => (
                                            <span></span>
                                        ),
                                        Cell: props => (
                                            <React.Fragment>
                                                {props.columnDef.tableData.id === 0 && (
                                                    <td className="MuiTableCell-root MuiTableCell-body multi-span" colSpan={this.state.columns.length}>
                                                        <OrderItemForm orderItem={props.rowData} customerAccount={this.props.orderDetail.customerAccount}
                                                            client={this.props.orderDetail.client} shipDate={this.props.orderDetail.shipDate}
                                                            isLoading={this.state.isTableLoading} isOpenOrder={this.props.isOpenOrder}
                                                            isSelected={this.props.orderItemToEdit && this.props.orderItemToEdit.id === props.rowData["id"] ? true : false}
                                                            onChangeOrderItemQuantity={this.onChangeOrderItemQuantity}
                                                            onAddOrderItemEmbroidery={this.onAddOrderItemEmbroidery}
                                                            onRemoveOrderItemEmbroidery={this.onRemoveOrderItemEmbroidery}
                                                            onViewProduct={this.props.onViewProduct}
                                                            onCopyOrderItem={this.onCopyOrderItem}
                                                            onRemoveOrderItem={this.onRemoveOrderItem}
                                                            onImageLoad={this.onInitialImageLoad} />
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        )
                                    }}
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: (
                                                <React.Fragment>
                                                    {this.state.rowCount === 0 && (
                                                        <div className="no-order-items-message">
                                                            <label className="message">No items selected</label>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        {this.state.isTableError && (
                            <Row className="justify-content-center table-error">
                                <Col className="pt-4" xs={"auto"}>
                                    <Button color="primary" onClick={this.reloadTable}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                            <polyline points="1 4 1 10 7 10"></polyline>
                                            <polyline points="23 20 23 14 17 14"></polyline>
                                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                        </svg>
                                        Reload
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </motion.div>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private deleteItemChanged = (prevProps: OrderDetailProps): boolean => {
        let itemChanged: boolean = false;
        if (this.props.orderItemToDelete && !prevProps.orderItemToDelete) {
            itemChanged = true;
        }
        else if (prevProps.orderItemToDelete && !this.props.orderItemToDelete) {
            itemChanged = true;
        }
        else if (this.props.orderItemToDelete && prevProps.orderItemToDelete) {
            itemChanged = this.props.orderItemToDelete.id != prevProps.orderItemToDelete.id ? true : false;
        }
        return itemChanged;
    }

    private editItemChanged = (prevProps: OrderDetailProps): boolean => {
        let itemChanged: boolean = this.props.orderItemToEdit && !prevProps.orderItemToEdit ? true : false;
        if (!itemChanged) {
            itemChanged = prevProps.orderItemToEdit && !this.props.orderItemToEdit ? true : false;
        }
        return itemChanged;
    }

    private getColumnDefinitions = (): any[] => {
        let columns: any[] = [];
        switch (this.props.orderDetail.client.code) {
            case ClientCode.GTN:
                columns = this.getGTNColumnDefinitions();
                break;
            default:
                columns = this.getDefaultColumnDefinitions();
                break;
        }
        return columns;
    }

    private getDatePickerInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
        return (
            <DatePickerInput datePickerProps={props} readOnly={true} tooltipText="Change Date" hideClearButton={false} />
        );
    }

    private getDefaultColumnDefinitions = (): any => {
        return [
            {
                title: "Id",
                field: "id",
                filtering: false,
                type: "number"
            }
        ];
    }

    private getGTNColumnDefinitions = (): any => {
        return [
            {
                title: "SKU",
                field: "sku",
                type: "string",
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={""} onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Description",
                field: "style.name",
                type: "string",
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={""} onFilterChanged={props.onFilterChanged} />
            }
        ];
    }

    private getOrderItems = (query: Query<any>): Promise<QueryResult<any>> => {

        return new Promise((resolve, reject) => {

            if (this.props.orderDetail.client) {
                this.showTableLoader(true, this.state.isTableInitialized, undefined, undefined);
                let mergedQuery: Query<any> = this.mergeQuery(query);
                this.props.asyncActions.requestOrderItemsAsync(this.props.orderDetail, mergedQuery)
                    .then(result => {
                        if (result) {
                            resolve(result);
                            this.showTableLoader(false, true, result.data.length, result.totalCount);
                        }
                    },
                    err => {
                        this.showTableReloader();
                        resolve({
                            data: [],
                            page: 0,
                            totalCount: 0
                        });
                    });
            }
        })
    }

    private mergeQuery = (query: Query<any>): Query<any> => {
        if (this.props.orderItemToEdit && this.props.orderItemToEdit.id) {
            query.filters = [];
            query.page = 0;
            query.orderBy = { title: this.props.orderItemToEdit.id.toString(), field: "selectedid", type: "numeric" };
        }
        else if (query.orderBy && query.orderBy.field === 'selectedid') {
            (query.orderBy as any) = undefined; //{ title: "Id", field: "id", type: "numeric" };
        }
        return query;
    }

    private onAddOrderItemEmbroidery = (orderItem: OrderItemsStore.OrderItem): void => {
        this.updateTableData(orderItem);
    }

    private onChangeOrderItemQuantity = (orderItem: OrderItemsStore.OrderItem): void => {
        this.props.actions.queueOrderItemForUpdate(cloneDeep(orderItem));
        this.updateTableData(orderItem);
    }

    private onChangeShipDate = (date: Date): void => {
        let earliestCancelDate: Date | null | undefined = getEarliestCancelDate(this.props.orderDetail, this.props.orderDates, date);
        let cancelDate: Date | null | undefined = this.props.orderDetail.cancelDate;
        if (earliestCancelDate && cancelDate) {
            if (cancelDate != earliestCancelDate) {
                cancelDate = earliestCancelDate;
            }
        }
        else {
            cancelDate = earliestCancelDate;
        }
        this.props.onChangeShipDate(date, cancelDate);
    }

    private onCopyOrderItem = (orderItem: OrderItemsStore.OrderItem): void => {
        this.reloadTable();
    }

    private onInitialImageLoad = (): void => {
        if (this.props.isActive && !this.state.isVisible) {
            this.setState({
                isVisible: true
            });
        }
    }

    private onRemoveOrderItem = (): void => {
        setTimeout(() => {
            this.reloadTable();
        });
    }

    private onRemoveOrderItemEmbroidery = (orderItem: OrderItemsStore.OrderItem): void => {
        this.updateTableData(orderItem);
    }

    private onSearchChange = (query: Query<any>): boolean => {
        let searchChange: boolean = false;
        return searchChange;
    }

    private reloadTable = () => {
        this.props.actions.clearMessage();
        this.tableElement.current.onQueryChange();
    }

    private showTableLoader = (active: boolean, initialized: boolean, rowCount: number | undefined, total: number | undefined): void => {
        if (this.state.isTableLoading != active) {
            setTimeout(() => {
                this.setState({
                    isTableInitialized: initialized ? true : false,
                    isTableLoading: active ? true : false,
                    isTableError: false,
                    isVisible: (rowCount === 0 ? true : this.state.isVisible),
                    rowCount: rowCount,
                    total: total
                });
            });
        }
    }

    private showTableReloader = (): void => {
        setTimeout(() => {
            this.setState({
                isTableInitialized: true,
                isTableLoading: false,
                isTableError: true
            });
        }, 500);
    }

    private updateTableData = (orderItem: OrderItemsStore.OrderItem): void => {
        let tableRef: any = this.tableElement.current;
            if (tableRef) {
            let index: number = tableRef.state.data.findIndex((d: OrderItemsStore.OrderItem) => {
                return d.id === orderItem.id;
            })
            tableRef.state.data[index] = orderItem;
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        ordersState: state.orders
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrdersStore.actionCreators,
            OrderItemsStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestOrderItemsAsync: (orderDetail: OrdersStore.OrderDetail, query: Query<any>) => dispatch(OrderItemsStore.actionCreators.requestOrderItems(orderDetail, query))
        }
    }
}

export default connect<{}, {}, OrderDetailOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(OrderDetail as any);
