import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Collapse, Container, Table } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import MessagePanel from '../message-panel/MessagePanel';
import TechSupport from '../tech-support/TechSupport';
import $ from 'jquery';

import './MessageBar.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
};

type MessageBarProps =
    RouteComponentProps
    & typeof applicationState     // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// STATE

interface MessageBarState {
    isOpen: boolean,
    message: MessageStore.Message | null
}

class MessageBar extends React.PureComponent<MessageBarProps, MessageBarState> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: MessageBarProps) {
        super(props);
        this.state = {
            isOpen: false,
            message: null,
        };
    }

    // ----------------
    // METHODS

    // Called when component is first added to the document.
    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {       
 
        if (this.props.messageState.message && !this.props.clientState.client) {
            setTimeout(() => {
                this.props.history.push('/error');
            });
        }
        else {
            if (this.isValidMessage()) {
                setTimeout(() => {
                    this.setState({
                        isOpen: true,
                        message: this.props.messageState.message || this.state.message
                    })
                }, 400);
            }
            else {
                this.setState({
                    isOpen: false,
                    message: this.props.messageState.message || this.state.message
                });
            }
        }
    };

    public render = () => {
        return (
            <Collapse id="message-bar" className={ this.getMessageClass() } isOpen={this.state.isOpen}>
                <Container>
                    <Button outline color="secondary" onClick={this.closeMessage}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </Button>
                    <div className="message-bar-content">
                        <div className="message-bar-content-row">
                            <MessagePanel message={this.props.messageState.message} />
                        </div>
                        <div className="message-bar-content-row">
                            {this.isErrorMessage() && (
                                <div className="message-bar-contact">
                                    <TechSupport isBordered={true} isErrorMessage={true} client={this.props.clientState.client} />
                                </div>
                            )}
                        </div>
                    </div>
                </Container>
            </Collapse>
        );
    }

    // ----------------
    // HELPERS

    private closeMessage = () => {
        this.props.actions.clearMessage();
    }

    public getMessageClass = () => {
        let className: string = '';
        if (this.state.message) {
            className = this.state.message.messageType.toString().replace(/\_/, '-').toLowerCase();
        }
        return className;
    }

    private isErrorMessage = (): boolean => {
        return (this.state.message && this.state.message.messageType == MessageStore.MessageType.ERROR) ? true : false;
    }

    private isValidMessage = (): boolean => {
        let isValid: boolean = false;
        if (this.props.messageState.message) {
            isValid = (this.props.messageState.message.messageType == MessageStore.MessageType.ERROR ||
                // this.props.messageState.message.messageType == MessageStore.MessageType.UNAUTHORIZED ||
                this.props.messageState.message.messageType == MessageStore.MessageType.WARNING ||
                this.props.messageState.message.messageType == MessageStore.MessageType.SUCCESS);
        }
        return isValid;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, MessageStore.actionCreators), dispatch)
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MessageBar as any));



