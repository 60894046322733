import * as React from 'react';
import { Button } from 'reactstrap';

import './TableFilterIndicator.scss';

// ----------------
// PROPS

interface TableFilterIndicatorProps {
    text: string | null | undefined;
    total: number | undefined;
    isLoading: boolean;
    onClear: () => void;
}

// ----------------
// LOCAL STATE

interface TableFilterIndicatorState {
}

class TableFilterIndicator extends React.PureComponent<TableFilterIndicatorProps, TableFilterIndicatorState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: TableFilterIndicatorProps, state: TableFilterIndicatorState) {
        super(props);
        this.state = {
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: TableFilterIndicatorProps) => {
        if (prevProps.total && !this.props.total) {
            this.setState({
            });
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <React.Fragment>
                {this.props.text && (
                    <div className={"table-filter-indicator" + (this.props.isLoading ? ' loading' : '')}>
                        <label className="name">Category: </label>
                        <label className="value">{this.props.text}</label>
                        {this.props.total != undefined && (
                            <label className="total">({this.props.total})</label>
                        )}
                        <Button color="link" onClick={this.clearFilter}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </Button>
                    </div>
                )}
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private clearFilter = (): void => {
        this.props.onClear();
    }
}

// ----------------
// EXPORT

export default TableFilterIndicator;
