import * as React from 'react';
import debounce from 'lodash.debounce';

import './SearchBox.scss';

// ----------------
// PROPS

interface SearchBoxProps {
    autoFocus?: boolean;
    defaultValue?: string | null | undefined;
    onChange: (inputValue: string | null | undefined) => void;
    clearInput?: boolean | null | undefined;
}

// ----------------
// State

interface SearchBoxState {
    hasFocus: boolean;
    hasInputValue: boolean;
}

class SearchBox extends React.PureComponent<SearchBoxProps, SearchBoxState> {

    // ----------------
    // VARIABLES

    public textInput: React.RefObject<HTMLInputElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: SearchBoxProps, state: SearchBoxState) {
        super(props);
        this.state = {
            hasFocus: false,
            hasInputValue: this.props.defaultValue && this.props.defaultValue.length > 0 ? true : false
        };
        this.textInput = React.createRef<HTMLInputElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        setTimeout(() => {
            if (this.props.autoFocus && this.textInput.current) {
                this.textInput.current.focus();
            }
        })
    }

    public componentDidUpdate = (prevProps: SearchBoxProps) => {
        if (this.props.clearInput && !prevProps.clearInput) {
            if (this.textInput.current) {
                this.textInput.current.value = '';
                this.setState({
                    hasInputValue: false
                });
            }
        }
    }

    public render = () => {
        return (
            <div className="search-box">
                <div className="MuiToolbar-root MuiToolbar-regular">
                    <div className="MuiFormControl-root MuiTextField-root">
                        <div className={"MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart MuiInputBase-adornedEnd" + (this.state.hasFocus ? " Mui-focused": "")}>
                            <div className="MuiInputAdornment-root MuiInputAdornment-positionStart" title="Search">
                                <svg fontSize={"small"} className="feather-search" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                </svg>                            
                            </div>
                            <input className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd"
                                placeholder="Search" type="text" ref={this.textInput} defaultValue={this.props.defaultValue || ""}
                                onFocus={this.onFocus} onBlur={this.onBlur}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChange(e.target.value)} spellCheck={false} />
                            <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd">
                                <button className="MuiButtonBase-root MuiIconButton-root Mui-disabled Mui-disabled" tabIndex={-1} type="button" disabled={!this.state.hasInputValue}
                                    onClick={this.onClear}>
                                    <span className="MuiIconButton-label">
                                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24">
                                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // ----------------
    // HELPERS

    private onBlur = (): void => {
        this.setState({
            hasFocus: false
        });
    }

    private onChange = debounce((inputValue: string): void => {
        this.onChangeDebounced(inputValue);
    }, 400, { leading: false, trailing: true });

    private onChangeDebounced = (inputValue: string): void => {
        let hasInputValue: boolean = inputValue && inputValue.length > 0 ? true : false;
        if (this.state.hasInputValue != hasInputValue) {
            this.setState({
                hasInputValue: hasInputValue
            });            
        }
        this.props.onChange(inputValue);
    }

    private onClear = (e: React.MouseEvent | React.KeyboardEvent): void => {
        if (this.textInput.current) {
            this.textInput.current.value = '';
            this.setState({
                hasInputValue: false
            });
            setTimeout(() => {
                this.props.onChange(null);
            }, 400);
        }
    }

    private onFocus = (): void => {
        this.setState({
            hasFocus: true
        });
    }
}

// ----------------
// EXPORT

export default SearchBox;
