import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as CustomersStore from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import { ClientCode } from '../../enums/ClientCode';
import { CustomerType } from '../../enums/CustomerType';
import { Customer } from '../../store/Customers';
import { Button, Col, Container, Row } from 'reactstrap';
import Loader from '../loader/Loader';
import MaterialTable, { Column, Filter, Options, Query, QueryResult } from 'material-table';
import { BaseTableFilter } from '../../common/BaseTypes';
import { FilterEx } from '../../common/ExtendedTypes';
import TableFilter from '../table-filter/TableFilter';
import { TableFilterListItem } from '../table-filter/TableFilter';
import * as TableIcons from '../table-icons/TableIcons';
import DragHandle from '@material-ui/icons/DragHandle';
import CustomerCard from '../customer-card/CustomerCard';
import debounce from 'lodash.debounce';
import $ from 'jquery';

import './Customers.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    customersState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState
}

const actionCreators = {
    actions: Object.assign({}, CustomersStore.actionCreators, MessageStore.actionCreators)
};

interface CustomersAsyncActions {
    asyncActions: {
        requestCustomersAsync: (clientCode: ClientCode, query: Query<any>) => Promise<QueryResult<any>>;
    }
}

type CustomersProps =
    RouteComponentProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & CustomersAsyncActions;

// ----------------
// LOCAL STATE

interface CustomersState {
    isVisible: boolean;
    isTableInitialized: boolean;
    isTableLoading: boolean;
    isTableError: boolean;
    columns: any[];
    operators: FilterEx[];
    options: Options<any>;
    pageOverride: number | undefined,
    rowCount: number | undefined;
    useStoredQuery: boolean;
    selectedCountryCode: string | undefined;
}

// ----------------
// CONSTANTS

const CUSTOMERS_PAGE_SIZE: number = 10;

class Customers extends React.PureComponent<CustomersProps, CustomersState> {

    // ----------------
    // VARIABLES

    public tableElement: any;

    public customersVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: CustomersProps, state: CustomersState) {
        super(props);
        this.state = {
            isVisible: false,
            isTableInitialized: false,
            isTableLoading: false,
            isTableError: false,
            columns: [],
            operators: [],
            options: {
                columnResizable: false,
                debounceInterval: 500,
                doubleHorizontalScroll: true,
                filtering: true,
                fixedColumns: {left: 1},
                initialPage: 0,
                pageSize: CUSTOMERS_PAGE_SIZE,
                pageSizeOptions: [],
                showTitle: true,
                searchAutoFocus: false,
                searchText: this.getStoredSearch(),
                tableLayout: 'fixed',
                thirdSortClick: false                
            },
            rowCount: undefined,
            useStoredQuery: false, //this.props.customersState.customers ? true : false,
            selectedCountryCode: undefined,
            pageOverride: this.getPageOverride(CUSTOMERS_PAGE_SIZE)
        };
        this.tableElement = React.createRef();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.props.actions.clearMessage();        
        if (this.props.clientState.client) {
            this.setState({
                isVisible: true,
                columns: this.getColumnDefinitions()
            });
            this.configureTableColumns();
        }
        window.addEventListener('resize', this.onWindowResize);
    }

    public componentDidUpdate = () => {
        if (this.props.clientState.client && !this.state.isVisible) {
            setTimeout(() => {
                this.setState({
                    isVisible: true,
                    columns: this.getColumnDefinitions()
                });
                this.configureTableColumns();
            });
        }
    }

    public componentWillUnmount = () => {
        window.removeEventListener('resize', this.onWindowResize);
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={(this.state.isTableLoading==true || this.state.isTableInitialized==false)} />
                <motion.div id="customersPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"}
                    variants={this.customersVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className="title justify-content-start d-block d-sm-none">
                            <Col className="pl-0 pr-0 text-left">
                                <h2 className="page-title">Customers</h2>
                            </Col>
                        </Row>
                        <Row className={"table-container" + (this.state.isTableError ? " load-error" : "") + (this.state.isTableLoading ? " loading" : "") + (this.state.isTableInitialized ? " initialized" : "")}>
                            <Col className="pl-0 pr-0">
                                {this.props.clientState.client && (
                                    <MaterialTable
                                        title={"Customers"}
                                        tableRef={this.tableElement}
                                        columns={this.state.columns}
                                        data={this.getCustomers}
                                        icons={TableIcons.icons}
                                        options={this.state.options}
                                        onOrderChange={this.onOrderChange}
                                        components={{
                                            OverlayError: props => (
                                                <span></span>
                                            ),
                                            OverlayLoading: props => (
                                                <span></span>
                                            )
                                        }}
                                        actions={[
                                            {
                                                icon: TableIcons.getEditIcon,
                                                tooltip: 'View Detail',
                                                onClick: this.onViewDetail
                                            }
                                        ]}
                                        localization={{
                                            header: {
                                                actions: ''
                                            },
                                            body: {
                                                emptyDataSourceMessage: this.state.rowCount === 0 ? 'No customers to display': ''
                                            }
                                        }}
                                        onChangePage={this.onChangePage}
                                    />
                                )}
                            </Col>
                        </Row>
                        {this.state.isTableError && (
                            <Row className="justify-content-center table-error">
                                <Col className="pt-4" xs={"auto"}>
                                    <Button color="primary" onClick={this.reloadTable}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                            <polyline points="1 4 1 10 7 10"></polyline>
                                            <polyline points="23 20 23 14 17 14"></polyline>
                                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                        </svg>
                                        Reload
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </motion.div>
                <div className="image-cache">
                    <span className="fi fi-us"></span>
                    <span className="fi fi-ca"></span>
                </div>
            </React.Fragment>
        )
    }

    // ----------------
    // HELPERS

    private configureTableColumns = (): void => {
        if (this.tableElement.current) {
            let isSmallScreen: boolean = this.isBreakpoint('sm');
            let columns: Column<any>[] = this.tableElement.current.props.columns;
            if (columns.length > 0) {
                for (let n: number = 0; n < columns.length; n++) {
                    let col: Column<any> = columns[n];
                    if (n == 0) {
                        this.tableElement.current.onChangeColumnHidden(col, !isSmallScreen);
                    }
                    else {
                        this.tableElement.current.onChangeColumnHidden(col, isSmallScreen);
                    }
                }
            }
            else {
                setTimeout(() => {
                    this.configureTableColumns();
                }, 400);
            }        
        }
    }

    private getColumnDefinitions = (): any[] => {
        let columns: any[] = [];
        if (this.props.clientState.client) {
            switch (this.props.clientState.client.code) {
                case ClientCode.GTN:
                    columns = this.getGTNColumnDefinitions();
                    break;
                default:
                    columns = this.getDefaultColumnDefinitions();
                    break;
            }
        }
        return columns;
    }

    private getCustomers = (query: Query<any>): Promise<QueryResult<any>> => {

        return new Promise((resolve, reject) => {

            if (!this.state.isTableInitialized && query.filters.length == 0) {
                resolve({
                    data: [],
                    page: 0,
                    totalCount: 0
                })
            }
            else if (this.props.clientState.client) {
                this.showTableLoader(true, this.state.isTableInitialized, undefined);
                let mergedQuery: Query<any> = this.mergeQuery(query);
                this.props.asyncActions.requestCustomersAsync(this.props.clientState.client.code, mergedQuery)
                    .then(result => {
                        // this.setTableLayout(result.data.length);
                        resolve(result);
                        if (!this.saveSelectedCountry(query)) {
                            this.showTableLoader(false, true, result.data.length);
                        }
                    },
                    err => {
                        this.showTableReloader();
                        resolve({
                            data: [],
                            page: 0,
                            totalCount: 0
                        })
                    });
            }
            else {
                reject("Client is undefined");
            }
        });
    }

    private getDefaultColumnDefinitions = (): any[] => {
        return [
            {
                title: "Customer",
                field: "name",
                cellStyle: { width: '80%' },
                render: (data: Customer, type: "row") => <CustomerCard client={this.props.clientState.client} data={data} />
            },
            {
                title: "Number",
                field: "number",
                type: "string",
                align: "left",
                cellStyle: { width: '16%' },
                filterCellStyle: { width: '16%' },
                headerCellStyle: { width: '16%' },
                defaultSort: this.getStoredSortOrder("number"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("number")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Name",
                field: "name",
                type: "string",
                align: "left",
                cellStyle: { 'whiteSpace': 'nowrap'},
                defaultSort: this.getStoredSortOrder("name"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("name")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Type",
                field: "type",
                type: "string",
                align: "left",
                cellStyle: { width: '16%' },
                filterCellStyle: { width: '16%' },
                headerCellStyle: { width: '16%' },
                defaultSort: this.getStoredSortOrder("type"),
                render: (data: CustomersStore.Customer, type: "row") => CustomerType[data.type],
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"list"}
                        initialValue={this.getStoredFilterListItem("type") || { label: 'All', value: CustomerType.Undefined.toString() }}
                        listItems={[
                            { label: 'All', value: CustomerType.Undefined.toString() },
                            { label: 'Billing', value: CustomerType.Billing.toString() },
                            { label: 'Shipping', value: CustomerType.Shipping.toString() }
                        ]}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Country",
                field: "country.name",
                type: "string",
                align: "left",
                cellStyle: { 'whiteSpace': 'nowrap' },
                defaultSort: this.getStoredSortOrder("country"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"list"}
                        initialValue={this.getStoredFilterListItem("country") || { label: 'All', value: '0' }}
                        listItems={[
                            { label: 'All', value: '0' },
                            { label: 'United States', value: 'US' },
                            { label: 'Canada', value: 'CA' },
                            { label: 'Other', value: '00' }
                        ]}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "State",
                field: "state.name",
                type: "string",
                align: "left",
                cellStyle: { 'whiteSpace': 'nowrap' },
                defaultSort: this.getStoredSortOrder("state"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"state-picker"}
                        initialValue={this.getStoredFilter("state")}
                        filterValue={this.state.selectedCountryCode}
                        onFilterChanged={props.onFilterChanged} />                        
            }
        ]
    }

    private getGTNColumnDefinitions = (): any[] => {
        return [
            {
                title: "Customer",
                field: "name",
                filtering: false,
                cellStyle: {},
                render: (data: Customer, type: "row") => <CustomerCard client={this.props.clientState.client} data={data} />
            },
            {
                title: "Customer No",
                field: "billto",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("billto"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("billto")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Customer Name",
                field: "nameOnly",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("nameOnly"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("nameOnly")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Label",
                field: "locationDescription",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("shipto"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("shipto")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Country",
                field: "country.name",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("country.name"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"list"}
                        initialValue={this.getStoredFilterListItem("country.name") || { label: 'All', value: '0' }}
                        listItems={[
                            { label: 'All', value: '0' },
                            { label: 'United States', value: 'US' },
                            { label: 'Canada', value: 'CA' },
                            { label: 'Mexico', value: 'MX' },
                            { label: 'Aruba', value: 'AW' },
                            { label: 'Australia', value: 'AU' },
                            { label: 'Bahamas', value: 'BS' },
                            { label: 'Barbados', value: 'BB' },
                            { label: 'Belgium', value: 'BE' },
                            { label: 'Bermuda', value: 'BM' },
                            { label: 'China', value: 'CN' },
                            { label: 'Costa Rica', value: 'CR' },
                            { label: 'Czechia', value: 'CZ' },
                            { label: 'Great Britain', value: 'GB' },
                            { label: 'Iceland', value: 'IS' },
                            { label: 'Ireland', value: 'IE'},
                            { label: 'Italy', value: 'IT' },
                            { label: 'Jamaica', value: 'JM' },
                            { label: 'Malaysia', value: 'MY' },
                            { label: 'New Zealand', value: 'NZ' },
                            { label: 'Puerto Rico', value: 'PR' },
                            { label: 'Spain', value: 'ES' },
                            { label: 'Turks and Caicos', value: 'TC'}
                        ]}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "State",
                field: "state.name",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("state"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"state-picker"}
                        initialValue={this.getStoredFilter("state")}
                        filterValue={this.state.selectedCountryCode}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "City",
                field: "city",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("city"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("city")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Address",
                field: "street",
                type: "string",
                align: "left",
                cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
                defaultSort: this.getStoredSortOrder("street"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"string"}
                        initialValue={this.getStoredFilter("street")}
                        onFilterChanged={props.onFilterChanged} />
            },
            {
                title: "Channel",
                field: "salesChannel.description",
                type: "string",
                align: "left",
                defaultSort: this.getStoredSortOrder("salesChannel"),
                filterComponent: (props: BaseTableFilter) =>
                    <TableFilter columnDef={props.columnDef} filterType={"list"}
                        initialValue={this.getStoredFilterListItem("salesChannel") || { label: 'All', value: '0' }}
                        listItems={[
                            { label: 'All', value: '0' },
                            { label: 'Brick & Mortar', value: '4' },
                            { label: 'Online Partner', value: '2' },
                            { label: 'Big Box', value: '5' },
                            { label: 'Corporate', value: '9' },
                            { label: 'International', value: '10' },
                            { label: 'Resort', value: '8' },
                            { label: 'Green Grass', value: '1' },
                            { label: 'Closeout', value: '3' },
                            { label: 'Other', value: '6' }
                        ]}
                        onFilterChanged={props.onFilterChanged} />
            }
        ];
    }

    private getFilter = (field: string): Filter<any> | undefined => {
        let filter: Filter<any> | undefined = undefined;
        if (this.props.customersState.customers && this.props.customersState.customers.query) {
            let matches: Filter<any>[] = this.props.customersState.customers.query.filters.filter((filter: Filter<any>) => {
                return filter.column.field === field;
            });
            if (matches.length > 0) {
                filter = matches[0];
            }
        }
        return filter;
    }

    private getFilterOperator = (field: string | number | symbol | undefined): string | undefined => {
        let filterOperator: string | undefined = undefined;
        let index: number = this.state.operators.findIndex(f => f.column.field === field);
        if (index >= 0) {
            filterOperator = this.state.operators[index].operatorOverride;
        }
        return filterOperator;
    }

    private getPageOverride = (pageSize: number): number | undefined => {
        let pageOverride: number | undefined = undefined;
        if (this.props.customersState.customers && this.props.customersState.customers.query) {
            if (this.props.customersState.customers.query.page > 0) {
                pageOverride = this.props.customersState.customers.query.page;
                let appliedPageSize: number = this.props.customersState.customers.query.pageSize;
                if (appliedPageSize !== CUSTOMERS_PAGE_SIZE) {
                    let lastRow: number = appliedPageSize * (pageOverride + 1);
                    if (lastRow <= pageSize) {
                        pageOverride = undefined;
                    }
                    else {
                        pageOverride = Math.floor(lastRow / pageSize);
                    }
                }
            }
        }
        return pageOverride;
    }

    private getStoredFilter = (field: string): string | undefined => {
        let filterFor: string = field === 'country' ? 'country.name' : field === 'state' ? 'state.name' : field;
        let filter: Filter<any> | undefined = this.getFilter(filterFor);
        return filter ? filter.value : undefined;
    }

    private getStoredFilterListItem = (field: string): TableFilterListItem | undefined => {
        let storedFilterListItem: TableFilterListItem | undefined = undefined;
        let storedFilter: string | undefined = this.getStoredFilter(field);
        if (storedFilter) {
            switch (field) {
                case "type":
                    switch (storedFilter) {
                        case "0":
                            storedFilterListItem = { label:'All', value: CustomerType.Undefined.toString() };
                            break;
                        case "1":
                            storedFilterListItem = { label: 'Billing', value: CustomerType.Billing.toString() };
                            break;
                        case "2":
                            storedFilterListItem = { label: 'Shipping', value: CustomerType.Shipping.toString() };
                            break;
                    }
                    break;
                case 'country.name':
                    switch (storedFilter) {
                        case "":
                            storedFilterListItem = { label: 'All', value: '0' };
                            break;
                        case "US":
                            storedFilterListItem = { label: 'United States', value: 'US' };
                            break;
                        case "CA":
                            storedFilterListItem = { label: 'Canada', value: 'CA' };
                            break;
                        case "MX":
                            storedFilterListItem = { label: 'Mexico', value: 'MX' };
                            break;
                        case "AW":
                            storedFilterListItem = { label: 'Aruba', value: 'AW' };
                            break;
                        case "AU":
                            storedFilterListItem = { label: 'Australia', value: 'AU' };
                            break;
                        case "BS":
                            storedFilterListItem = { label: 'Bahamas', value: 'BS' };
                            break;
                        case "BB":
                            storedFilterListItem = { label: 'Barbados', value: 'BB' };
                            break;
                        case "BE":
                            storedFilterListItem = { label: 'Belgium', value: 'BE' };
                            break;
                        case "BM":
                            storedFilterListItem = { label: 'Bermuda', value: 'BM' };
                            break;
                        case "CN":
                            storedFilterListItem = { label: 'China', value: 'CN' };
                            break;
                        case "CR":
                            storedFilterListItem = { label: 'Costa Rica', value: 'CR' };
                            break;
                        case "CZ":
                            storedFilterListItem = { label: 'Czechia', value: 'CZ' };
                            break;
                        case "GB":
                            storedFilterListItem = { label: 'Great Britain', value: 'GB' };
                            break;
                        case "IS":
                            storedFilterListItem = { label: 'Iceland', value: 'IS' };
                            break;
                        case "IE":
                            storedFilterListItem = { label: 'Ireland', value: 'IE' };
                            break;
                        case "IT":
                            storedFilterListItem = { label: 'Italy', value: 'IT' };
                            break;
                        case "JM":
                            storedFilterListItem = { label: 'Jamaica', value: 'JM' };
                            break;
                        case "MY":
                            storedFilterListItem = { label: 'Malyasia', value: 'MY' };
                            break;
                        case "NZ":
                            storedFilterListItem = { label: 'New Zealand', value: 'NZ' };
                            break;
                        case "PR":
                            storedFilterListItem = { label: 'Puerto Rico', value: 'PR' };
                            break;
                        case "ES":
                            storedFilterListItem = { label: 'Spain', value: 'ES' };
                            break;
                        case "TC":
                            storedFilterListItem = { label: 'Turks and Caicos', value: 'TC' };
                            break;
                        case "00":
                            storedFilterListItem = { label: 'Other', value: '00' };
                            break;
                    }
            }
        }
        return storedFilterListItem;
    }

    private getStoredFilterOperator = (field: string): string | undefined => {
        let filter: Filter<any> | undefined = this.getFilter(field);
        return filter ? (filter as FilterEx).operatorOverride : undefined;
    }

    private getStoredSearch = (): string | undefined => {
        let searchText: string | undefined = undefined;
        if (this.props.customersState.customers) {
            searchText = this.props.customersState.customers.query.search;
        }
        return searchText;
    }

    private getStoredSortOrder = (field: string): "asc" | "desc" | undefined => {
        let sortOrder: "asc" | "desc" | undefined = undefined;
        if (this.props.customersState.customers && this.props.customersState.customers.query) {
            if (this.props.customersState.customers.query.orderBy) {
                if (this.props.customersState.customers.query.orderBy.field === field) {
                    sortOrder = this.props.customersState.customers.query.orderDirection;
                }
            }
        }
        return sortOrder;
    }

    private isBreakpoint = (alias: string): boolean => {
        let className: string = '.device-' + alias;
        let element: JQuery<HTMLElement> = $(className);
        return element.is(':visible');
    }

    private mergeQuery = (query: Query<any>): Query<any> => {
        if (this.state.useStoredQuery && this.props.customersState.customers && !this.onSearchChange(query)) {
            let storedQuery = this.props.customersState.customers.query;
            if (this.state.isTableInitialized) {
                storedQuery.page = query.page;
                storedQuery.filters = query.filters;
                storedQuery.orderDirection = query.orderDirection;
            }
            else {
                this.setState({
                    isTableInitialized: true
                });
            }
            query = storedQuery;
        }

        for (let n: number = 0; n < query.filters.length; n++) {
            (query.filters[n] as FilterEx).operatorOverride = this.getFilterOperator(query.filters[n].column.field);
        }

        return query;
    }

    private onChangePage = (page: number, pageSize: number): void => {
        //this.updatePagination();
    }

    private onFilterOperatorChanged = (field: string, operator: string): void => {
        let operators: FilterEx[] = this.state.operators
        let index: number = operators.findIndex(f => f.column.field === field);
        if (index >= 0) {
            operators[index].operatorOverride = operator;
        }
        else {
            operators.push({
                column: {
                    field: field
                },
                operatorOverride: operator
            } as FilterEx);
        }
        this.setState({
            operators: operators
        });
    }

    private onOrderChange = (orderBy: number, orderDirection: "asc" | "desc"): void => {
        this.setState({
            useStoredQuery: false
        });
    }

    private onWindowResize = debounce((): void => {
        this.configureTableColumns();
    }, 100, {leading: false, trailing: true}).bind(this);

    private onSearchChange = (query: Query<any>): boolean => {
        let searchChange: boolean = false;
        if (this.state.useStoredQuery && this.props.customersState.customers) {
            searchChange = (query.search != this.props.customersState.customers.query.search);
            if (searchChange) {
                this.setState({
                    useStoredQuery: false
                });
            }
        }
        return searchChange;
    }

    private onViewDetail = (event: any, data: any): void => {
        let customer: Customer = data as Customer;
        if (customer) {
            this.props.history.push('/customers/edit/' + customer.number + '/account');
        }
    }

    private reloadTable = () => {
        this.props.actions.clearMessage();
        this.tableElement.current.onQueryChange();
    }

    private saveSelectedCountry = (query: Query<any>): boolean => {
        let selectedCountryCode: string | undefined = undefined;
        if (query.filters != null) {
            let matchingFilter: Filter<any>[] = query.filters.filter((f: Filter<any>) => {
                return f.column.field === "country.name";
            });
            if (matchingFilter.length > 0) {
                let filterValue: string = matchingFilter[0].value;
                if (!filterValue.startsWith('0')) {
                    selectedCountryCode = filterValue;
                }
            }
        }
        let updateCountry: boolean = (selectedCountryCode != this.state.selectedCountryCode);
        if (updateCountry) {
            this.setState({
                selectedCountryCode: selectedCountryCode
            });
        }
        return updateCountry;
    }

    private setTableLayout = (rowCount: number): void => {
        //let table: JQuery<Element> = $('.MuiTable-root:first');
        //table.css({ 'table-layout': rowCount >= 0 ? 'fixed' : 'auto' });
    }

    private showTableLoader = (active: boolean, initialized: boolean, rowCount: number | undefined): void => {
        if (this.state.isTableLoading != active) {
            setTimeout(() => {
                this.setState({
                    isTableInitialized: initialized ? true : false,
                    isTableLoading: active ? true : false,
                    isTableError: false,
                    rowCount: rowCount
                });
            });
        }
    }

    private showTableReloader = (): void => {
        setTimeout(() => {
            this.setState({
                isTableInitialized: true,
                isTableLoading: false,
                isTableError: true
            });
        }, 500);
    }

    private updatePagination = (): void => {
        let paginationCaption: JQuery<HTMLElement> = $('.MuiTablePagination-root .MuiTypography-caption');
        if (paginationCaption.length > 0) {
            let caption: string = paginationCaption.text();
            let tokens: string[] = caption.split(/\s/);
            if (tokens.length === 3) {
                for (let n: number = 0; n < tokens.length; n++) {
                    if (n === 0) {
                        let numbers: string[] = tokens[n].split('-');
                        if (numbers.length === 2) {
                            for (let m: number = 0; m < numbers.length; m++) {
                                if (numbers[m].length > 3 && numbers[m].indexOf(',') < 0) {
                                    numbers[m] = Number(numbers[m]).toLocaleString();
                                }
                            }
                            tokens[n] = numbers.join('-');
                        }
                    }
                    else if (n === 2 && tokens[n].length > 3 && tokens[n].indexOf(',') < 0) {
                        tokens[n] = Number(tokens[n]).toLocaleString();
                    }
                }
                paginationCaption.text((tokens.join(' ')));
            }
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        customersState: state.customers,
        messageState: state.message
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            CustomersStore.actionCreators,
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestCustomersAsync: (clientCode: ClientCode, query: Query<any>) => dispatch(CustomersStore.actionCreators.requestCustomers(clientCode, query))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Customers as any);
