import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as OrderDatesStore from '../../store/OrderDates';
import * as OrdersStore from '../../store/Orders';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { OrderDetail } from '../../store/Orders';
import { OrderDates } from '../../store/OrderDates';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps, BaseFieldProps } from 'redux-form';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import { areEqual, getEarliestShipDate, getEarliestCancelDate } from '../../common/OrderFunctions';
import FormResult from '../form-result/FormResult';
import Loader from '../loader/Loader';
import cloneDeep from 'lodash/cloneDeep';

import './EditOrderDates.scss';

// ----------------
// PROPS

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    orderDatesState: {} as OrderDatesStore.OrderDatesState,
    ordersState: {} as OrdersStore.OrdersState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrderDatesStore.actionCreators, OrdersStore.actionCreators)
}

interface EditOrderDatesAsyncActions {
    asyncActions: {
        saveOrderDetailAsync: (client: ClientStore.Client, orderDetail: OrdersStore.OrderDetail) => Promise<OrdersStore.OrderDetail | null | undefined>
    }
}

interface EditOrderDatesOwnProps {    
    isOpen: boolean;
    orderDetail: OrderDetail;
    onDismiss: (() => void);
}

type EditOrderDatesProps =
    EditOrderDatesOwnProps
    & InjectedFormProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & EditOrderDatesAsyncActions;

// ----------------
// LOCAL STATE

interface EditOrderDatesState {
    isVisible: boolean;
    isLoadError: boolean;
    isLoadWarning: boolean;
    isReloading: boolean;
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
    shipDate: Date;
    cancelDate: Date | null | undefined;
    earliestCancelDate: Date | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateEditOrderDatesForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.shipDate) {
        errors.shipDate = 'Required';
    }
    if (!formValues.cancelDate) {
        errors.cancelDate = 'Required';
    }
    else if (formValues.shipDate) {
        if (formValues.cancelDate <= formValues.shipDate) {
            errors.cancelDate = 'Must be after ship date';
        }
    }
    return errors;
}

class EditOrderDates extends React.PureComponent<EditOrderDatesProps, EditOrderDatesState> {

    // ----------------
    // VARIABLES

    public submitButton: React.RefObject<HTMLButtonElement>;

    public editOrderDatesFormVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: EditOrderDatesProps, state: EditOrderDatesState) {
        super(props);
        this.state = {
            isVisible: this.props.orderDatesState.orderDates ? true : false,
            isLoadError: false,
            isLoadWarning: false,
            isReloading: false,
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            shipDate: this.props.orderDetail.shipDate,
            cancelDate: this.props.orderDetail.cancelDate,
            earliestCancelDate: undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.ensureDataFetched();
    }

    public componentDidUpdate = (prevProps: EditOrderDatesProps) => {
        if (!this.state.isVisible) {
            if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
                this.setState({
                    isReloading: false
                });
                setTimeout(() => {
                    this.setState({
                        isLoadError: true,
                        isLoadWarning: this.receivedWarningMessage()
                    });
                }, 400);
            }
            else if (this.props.orderDatesState.orderDates) {
                this.setState({
                    isVisible: true,
                    isLoadError: false,
                    isLoadWarning: false,
                    isReloading: false,
                    submitting: false,
                    earliestCancelDate: getEarliestCancelDate(this.props.orderDetail, this.props.orderDatesState.orderDates, this.props.orderDetail.shipDate)
                });
            }
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        let updatedOrderDetail: OrderDetail = cloneDeep(this.props.orderDetail);
        updatedOrderDetail.shipDate = values.shipDate;
        updatedOrderDetail.cancelDate = values.cancelDate;
        this.setState({
            submitting: true
        });
        setTimeout(() => {
            this.saveOrder(updatedOrderDetail);
        });
    }

    public render = () => {
        return (
            <Modal id="edit-order-dates" size="sm" isOpen={this.props.isOpen} onOpened={this.initializeDialog.bind(this)} onClosed={this.resetDialog}>
                <ModalHeader toggle={this.props.onDismiss} className={this.state.submitting ? "disabled" : ""}>
                    Change Ship Date
                </ModalHeader>
                <ModalBody>
                    <Loader isLoading={(!this.state.isVisible && !this.state.isLoadError) || this.state.isReloading} isChild={true} />
                    <motion.div id="edit-orderdates-form" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"}
                        variants={this.editOrderDatesFormVariants} transition={{duration: 0.75 }}>
                        <Container>
                            <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                <Row>
                                    <Col className="pl-0 pr-0 form-group-container">
                                        <div className="name">Ship Date</div>
                                        <div className="value">
                                            <Field name="shipDate" label="" component={FieldWrapper.renderDatePickerField} 
                                                disabled={this.state.submitting} required={true} onSelectDate={this.changeShipDate}
                                                selectedDate={this.state.shipDate} minDate={getEarliestShipDate(this.props.orderDetail, this.props.orderDatesState.orderDates as OrderDates)}
                                                wrapperClass="shipdate-picker" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pl-0 pr-0 form-group-container">
                                        <div className="name">Cancel By</div>
                                        <div className="value">
                                            <Field name="cancelDate" label="" component={FieldWrapper.renderDatePickerField}
                                                disabled={this.state.submitting} required={true} onSelectDate={this.changeCancelDate}
                                                selectedDate={this.state.cancelDate} minDate={this.state.earliestCancelDate}
                                                wrapperClass="canceldate-picker" />
                                        </div>
                                    </Col>
                                </Row>
                                <button type="submit" ref={this.submitButton}>Submit</button>
                            </form>
                        </Container>
                    </motion.div>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" disabled={this.state.submitting || this.state.changesAccepted}
                                    onClick={this.submitEditOrderDatesForm}>
                                    {this.state.submitting ? "Working" : "Save"}
                                </Button>
                                <Button color="link" onClick={this.props.onDismiss} disabled={this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0 pr-sm-3">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private changeCancelDate = (date: Date): void => {
        this.setState({
            cancelDate: date
        });
        this.props.change("cancelDate", date);
    }

    private changeShipDate = (date: Date): void => {
        if (this.props.orderDatesState.orderDates) {
            let earliestCancelDate: Date | null | undefined = getEarliestCancelDate(this.props.orderDetail, this.props.orderDatesState.orderDates, date);
            let cancelDate: Date | null | undefined = this.state.cancelDate;
            if (earliestCancelDate && cancelDate) {
                if (cancelDate != earliestCancelDate) {
                    cancelDate = earliestCancelDate;
                }
            }
            this.setState({
                shipDate: date,
                cancelDate: cancelDate,
                earliestCancelDate: earliestCancelDate
            });
            this.props.change("shipDate", date);
            this.props.change("cancelDate", cancelDate);
        }
    }

    private ensureDataFetched = (): void => {
        if (this.props.orderDatesState.orderDates) {
            this.setState({
                isVisible: true,
                isLoadError: false,
                isReloading: false
            });
        }
        else {
            if (!this.state.isLoadError) {
                this.props.actions.requestOrderDates(this.props.orderDetail.client);
                setTimeout(() => {
                    this.ensureDataFetched();
                }, 200);
            }
        }
    }

    private initializeDialog = (): void => {
        this.props.change("shipDate", this.props.orderDetail.shipDate);
        this.props.change("cancelDate", this.props.orderDetail.cancelDate);

        if (this.props.orderDatesState.orderDates) {
            this.setState({
                isVisible: true,
                isLoadError: false,
                isLoadWarning: false,
                isReloading: false,
                submitting: false,
                changesAccepted: false,
                changesRejected: false,
                formResult: null,
                shipDate: this.props.orderDetail.shipDate,
                cancelDate: this.props.orderDetail.cancelDate,
                earliestCancelDate: getEarliestCancelDate(this.props.orderDetail, this.props.orderDatesState.orderDates, this.props.orderDetail.shipDate)
            });
        }
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private resetDialog = (): void => {
        this.setState({
            isVisible: true,
            isLoadError: false,
            isLoadWarning: false,
            isReloading: false,
            formResult: null,
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            earliestCancelDate: undefined
        });
    }

    private saveOrder = (updatedOrderDetail: OrdersStore.OrderDetail): void => {
        if (this.props.orderDetail && this.props.clientState.client) {
            this.props.asyncActions.saveOrderDetailAsync(this.props.clientState.client, updatedOrderDetail)
                .then(result => {
                    this.setState({
                        changesAccepted: true,
                        submitting: false,
                        formResult: 'Changes saved'
                    })
                    setTimeout(() => {
                        this.props.onDismiss()
                    }, 2000);
                },
                err => {
                    this.setState({
                        changesRejected: true,
                        submitting: false,
                        formResult: err
                    });
                }
            )
        }
    }

    private submitEditOrderDatesForm = (): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        orderDatesState: state.orderDates,
        ordersState: state.orders
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrderDatesStore.actionCreators,
            OrdersStore.actionCreators
        ), dispatch),
        asyncActions: {
            saveOrderDetailAsync: (client: ClientStore.Client, orderDetail: OrdersStore.OrderDetail) => dispatch(OrdersStore.actionCreators.saveOrderDetail(client, orderDetail))
        }
    }
}

export default connect<{}, {}, EditOrderDatesOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'editOrderDatesForm',
    validate: validateEditOrderDatesForm,
    enableReinitialize: true
})(EditOrderDates as any));
