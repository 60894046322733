import * as React from 'react';

import './Checkbox.scss';

// ----------------
// PROPS

interface CheckboxProps {
    id: string;
    label?: string | null | undefined;
    selected: boolean;
    disabled: boolean;
    onChange: (checked: boolean) => void;
}

// ----------------
// STATE

interface CheckboxState { }

class Checkbox extends React.PureComponent<CheckboxProps, CheckboxState> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: CheckboxProps, state: CheckboxState) {
        super(props);
        this.state = {};
    }

    // ----------------
    // METHODS

    public render = () => {
        return (
            <a className={"checkbox" + (this.props.disabled ? " disabled" : "")}>
                {this.props.selected && (
                    <input type="checkbox" id={this.props.id} tabIndex={0} disabled={this.props.disabled} checked={true}
                        onChange={this.onChecked} />
                )}
                {!this.props.selected && (                
                    <input type="checkbox" id={this.props.id} tabIndex={0} disabled={this.props.disabled}
                        onChange={this.onChecked} />
                )}
                <div className="checkable-label">
                    <label htmlFor={this.props.id}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" className="feather-check">
                            <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                    </label>
                </div><div className={"readable-label" + (this.props.label ? "" : " hidden")}>
                    <label htmlFor={this.props.id}>{this.props.label}</label>
                </div>
            </a>
        );
    }

    // ----------------
    // HELPERS

    private onChecked = (event: React.ChangeEvent): void => {
        event.stopPropagation();
        let target: any = event.nativeEvent.target;
        this.props.onChange(target.checked);
    }
}

// ----------------
// EXPORT

export default Checkbox;
