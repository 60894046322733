import * as React from 'react';
import { ProductlistItem } from '../../store/ProductlistItems';
import { Client } from '../../store/Client';
import { EmbroiderySpecification, PlacementOption } from '../../common/EmbroideryTypes';
import SizeRun from '../size-run/SizeRun';
import ImageLoader from '../image-loader/ImageLoader';
import DeleteProductlistItem from '../delete-productlist-item/DeleteProductlistItem';
import { toTitleCase } from '../../common/StringFormatter';
import cloneDeep from 'lodash/cloneDeep';

import './ProductlistItemDescription.scss';

// ----------------
// PROPS

interface ProductlistItemDescriptionProps {
    productlistItem: ProductlistItem;
    onDelete: (productlistItem: ProductlistItem) => void;
    onViewProduct: (productlistItem: ProductlistItem) => void;
}

// ----------------
// LOCAL STATE

interface ProductlistItemDescriptionState {
    productlistItem: ProductlistItem;
    productlistItemToDelete: ProductlistItem | null;
}

class ProductlistItemDescription extends React.PureComponent<ProductlistItemDescriptionProps, ProductlistItemDescriptionState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductlistItemDescriptionProps, state: ProductlistItemDescription) {
        super(props);
        this.state = {
            productlistItem: cloneDeep(props.productlistItem),
            productlistItemToDelete: null
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: ProductlistItemDescriptionProps) => {
        if (this.props.productlistItem.id != prevProps.productlistItem.id) {
            this.setState({
                productlistItem: cloneDeep(this.props.productlistItem)
            });
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <React.Fragment>
                <div className={"productlist-item-description" + (this.state.productlistItem.isEditable ? "" : " read-only") + (this.state.productlistItemToDelete ? " selected" : "")}>
                    <div className="productlist-item-actions">
                        <div className="actions">
                            {!this.state.productlistItem.isEditable && (
                                <button type="button" className="btn-view MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" tabIndex={0} title="View Item"
                                    onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.onEditProductlistItem(e, true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-search">
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                </button>
                            )}
                            {this.state.productlistItem.isEditable && (
                                <React.Fragment>
                                    <button type="button" className="btn-edit MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" tabIndex={0} title="Edit Item"
                                        onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.onEditProductlistItem(e, false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-edit-3">
                                            <path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                        </svg>
                                    </button>
                                    <button type="button" className="btn-copy MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" tabIndex={0} title="Copy Item"
                                        onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.onCopyProductlistItem(e)}>
                                        <span className="MuiIconButton-label">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-copy">
                                                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                            </svg>
                                        </span>
                                    </button>
                                    <button type="button" className="btn-remove MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" tabIndex={0} title="Remove Item"
                                        onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.onRemoveProductlistItem(e)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-trash">
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                        </svg>
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div className="productlist-item-image">
                        <a href="#" className="image" onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.onViewProduct(e)}>
                            <div className="item-image">
                                <div className="image-stretcher">
                                    <div className="image-canvas">
                                        <ImageLoader url={this.state.productlistItem.imageUrl} isLoading={false} noFadeIn={false} />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="productlist-item-style">
                        <label className="style-code">
                            {this.state.productlistItem.style.sku ? "" : "Style "}
                            {this.state.productlistItem.style.sku ? this.state.productlistItem.style.sku : this.state.productlistItem.style.code}
                        </label>
                        <h4 className="style-name">{this.state.productlistItem.style.name}</h4>
                        <label className="color-name">
                            {this.getColorDescription()}
                            {this.state.productlistItem.embroiderySpecifications.length > 0 && (
                                <span className="embroidery-summary">{this.getEmbroiderySummary()}</span>
                            )}
                        </label>
                    </div>
                    <div className="productlist-item-embroidery">
                        <label className={"title" + (this.state.productlistItem.dimensionDescription ? " flush-top" : "")}>Embroidery</label>
                        {this.state.productlistItem.embroiderySpecifications.length === 0 && (
                            <label className="no-logos">None</label>
                        )}
                        {this.state.productlistItem.embroiderySpecifications.length > 0 && (
                            <ul>
                                {this.state.productlistItem.embroiderySpecifications.map((spec: EmbroiderySpecification, specIndex: number) => (
                                    <li key={"spec-" + specIndex}><span>{spec.placement ? spec.placement.description : 'Placement undefined'}</span></li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="productlist-item-quantity">
                        {this.state.productlistItem.dimensionDescription && (
                            <div className="dimension">
                                <label className="name">{this.state.productlistItem.dimensionDescription}</label>
                                <label className="value">{this.state.productlistItem.dimension.code.toLowerCase()}</label>
                            </div>
                        )}
                        <SizeRun dimension={this.state.productlistItem.dimension} description={this.state.productlistItem.sizeDescription}
                            displayOnly={false} readOnly={true} minSize={true} />
                    </div>
                </div>
                <DeleteProductlistItem clientCode={this.props.productlistItem.clientCode}
                    productlistItemToDelete={this.state.productlistItemToDelete}
                    onDelete={this.onDeleteProductlistItem}
                    onDismiss={this.hideDeleteProductlistItem} />
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private getColorDescription = (): string => {
        let color: string | null | undefined = this.state.productlistItem.color.name;
        if (color) {
            if (color != 'PATTERNED') {
                color = toTitleCase(color);
            }
            else {
                color = "";
            }
        }
        else {
            color = this.state.productlistItem.color.code;
        }
        return color || "";
    }

    private getEmbroiderySummary = (): string => {
        let summary: string = '';
        if (this.state.productlistItem.embroiderySpecifications.length > 0) {
            summary = '(with ';
            if (this.state.productlistItem.embroiderySpecifications.length == 1) {
                summary += 'logo)';
            }
            else {
                summary = summary + this.state.productlistItem.embroiderySpecifications.length + ' logos)';
            }
        }
        return summary;
    }

    private hideDeleteProductlistItem = (): void => {
        this.setState({
            productlistItemToDelete: null
        });
    }

    private onCopyProductlistItem = (event: React.MouseEvent | React.KeyboardEvent): void => {
        event.preventDefault();
        alert('onCopyProductlistItem');
    }

    private onDeleteProductlistItem = (): void => {
        this.hideDeleteProductlistItem();
        this.props.onDelete(this.state.productlistItem);
    }

    private onEditProductlistItem = (event: React.MouseEvent | React.KeyboardEvent, readonly: boolean): void => {
        event.preventDefault();
        alert('onEditProductlistItem (readonly=' + readonly + ")");
    }

    private onRemoveProductlistItem = (event: React.MouseEvent | React.KeyboardEvent): void => {
        event.preventDefault();
        this.setState({
            productlistItemToDelete: cloneDeep(this.state.productlistItem)
        });
    }

    private onViewProduct = (event: React.MouseEvent | React.KeyboardEvent): void => {
        event.preventDefault();
        this.props.onViewProduct(this.state.productlistItem);
    }
}

// ----------------
// EXPORT

export default ProductlistItemDescription;
