import * as React from 'react';
import { OrderSubmissionError } from '../../store/Orders';
import { OrderItem } from '../../store/OrderItems';
import ImageLoader from '../image-loader/ImageLoader';

import './OrderItemError.scss';

// ----------------
// PROPS

interface OrderItemErrorProps {
    error: OrderSubmissionError;
    updating: boolean;
    onEditItem: (error: OrderSubmissionError) => void;
    onRemoveItem: (error: OrderSubmissionError) => void;
}

// ----------------
// LOCAL STATE

interface OrderItemErrorState { }

class OrderItemError extends React.PureComponent<OrderItemErrorProps, OrderItemErrorState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderItemErrorProps, state: OrderItemErrorState) {
        super(props);
        this.state = {};
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: OrderItemErrorProps) => {
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <div className="order-item-error">
                {this.props.error.orderItem && (
                    <React.Fragment>
                        <div className="actions">
                            <button type="button" className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" disabled={this.props.updating}
                                tabIndex={0} title="Edit Item" onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.props.onEditItem(this.props.error)}>
                        <span className="MuiIconButton-label">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-edit-3">
                                <path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                            </svg>
                        </span>
                    </button>
                            <button type="button" className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" disabled={this.props.updating}
                                tabIndex={0} title="Remove Item" onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.props.onRemoveItem(this.props.error)}>
                        <span className="MuiIconButton-label">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-trash">
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            </svg>
                        </span>
                    </button>
                </div>
                        <div className="image">
                            <div className="order-item-error-image">
                                <div className="image-stretcher">
                                    <div className="image-canvas">
                                        <ImageLoader url={this.props.error.orderItem.imageUrl} isLoading={false} thumbnail={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text">
                            <label className="style-code">
                                {this.props.error.orderItem.style.sku ? "" : "Style "}
                                {this.props.error.orderItem.style.sku ? this.props.error.orderItem.style.sku : this.props.error.orderItem.style.code}
                            </label>
                            <h4 className="style-name">{this.props.error.orderItem.style.name}</h4>
                            <div className="two-column">
                                <label className="price">${this.props.error.orderItem.wholesale.toFixed(2)}</label>
                                <label className="embroidery">{this.props.error.orderItem.embroiderySpecifications.length > 0 ? "Embroidered" : ""}</label>
                            </div>
                        </div>
                        <div className="qty">
                            <label>{this.props.error.orderItem.totalUnits} unit{this.props.error.orderItem.totalUnits === 1 ? "" : "s"}</label>                            
                        </div>
                    </React.Fragment>
                )}
            </div>
            
        );
    }

    // ----------------
    // HELPERS
}

// ----------------
// EXPORT

export default OrderItemError;
