﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as MessageStore from './Message';
import * as ErrorMessage from '../common/ErrorMessage';
import { ClientCode } from '../enums/ClientCode';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface PasswordState {
    passwordChange: PasswordChange | null;
    isResetting: boolean;
    isChanging: boolean;
    isUnlocking: boolean;
}

export interface PasswordChange {
    clientCode: ClientCode;
    email: string | null;
    userId: number | null;
    username: string | null;
    password: string | null;
    passwordConfirmation: string | null;
    isReset: boolean;
    isChanged: boolean;
    isUnlocked: boolean;
    isComplete: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface ChangePasswordAction {
    type: 'CHANGE_PASSWORD',
    passwordChange: PasswordChange
}

interface ResetPasswordAction {
    type: 'RESET_PASSWORD',
    passwordChange: PasswordChange
}

interface ReceivePasswordAction {
    type: 'RECEIVE_PASSWORD'
    passwordChange: PasswordChange | null
}

interface ClearPasswordAction {
    type: 'CLEAR_PASSWORD'
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = ChangePasswordAction | ResetPasswordAction | ReceivePasswordAction | ClearPasswordAction | MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    changePassword: (change: PasswordChange): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let isChanging = appState && appState.password && appState.password.isChanging;
        if (!isChanging) {

            let fetchError: boolean = false;
            let action: string = 'user/changepassword';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(change)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage) => {
                                dispatch({ type: 'RECEIVE_PASSWORD', passwordChange: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            }
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    let actionComplete: MessageStore.Message = {
                        messageType: MessageStore.MessageType.ACTIONCOMPLETE,
                        action: action,
                        reference: null,
                        text: 'Update complete'
                    };
                    dispatch({ type: 'RECEIVE_PASSWORD', passwordChange: change });
                    dispatch({ type: 'BROADCAST_MESSAGE', message: actionComplete });
                },
                err => {
                    if (!fetchError) {
                        dispatch({ type: 'RECEIVE_PASSWORD', passwordChange: null });
                        dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                    }
                });
                       
            dispatch({ type: 'CHANGE_PASSWORD', passwordChange: change });
        }
    },
    resetPassword: (change: PasswordChange): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let isResetting = appState && appState.password && appState.password.isResetting;
        if (!isResetting) {

            let fetchError: boolean = false;
            let action: string = 'user/resetpassword';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(change)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage) => {
                                dispatch({ type: 'RECEIVE_PASSWORD', passwordChange: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            }
                        )
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_PASSWORD', passwordChange: data });                    
                },
                err => {
                    if (!fetchError) {
                        dispatch({ type: 'RECEIVE_PASSWORD', passwordChange: null });
                        dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                    }
                });
            
            dispatch({ type: 'RESET_PASSWORD', passwordChange: change })
        }
    },
    clearPassword: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.password && appState.password.passwordChange) {
            dispatch({ type: 'RECEIVE_PASSWORD', passwordChange: null });
        }
        dispatch({ type: 'CLEAR_PASSWORD' });
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: PasswordState = {
    passwordChange: null,
    isChanging: false,
    isResetting: false,
    isUnlocking: false
}

// reducer
export const reducer: Reducer<PasswordState> = (state: PasswordState | undefined, incomingAction: Action): PasswordState => {
    if (state == undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CHANGE_PASSWORD':
            return {
                passwordChange: action.passwordChange,
                isChanging: true,
                isResetting: false,
                isUnlocking: false
            };
        case 'RESET_PASSWORD':
            return {
                passwordChange: action.passwordChange,
                isChanging: false,
                isResetting: true,
                isUnlocking: false,
            };
        case 'RECEIVE_PASSWORD':
            return {
                passwordChange: action.passwordChange,
                isChanging: false,
                isResetting: false,
                isUnlocking: false
            };
        default:
            return state;
    }
}
