﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ClientStore from './Client';
import * as MessageStore from './Message';
import { ClientCode } from '../enums/ClientCode';
import { DropShipAddress } from '../common/AddressTypes';
import * as ErrorMessage from '../common/ErrorMessage';
import { Query, QueryResult } from 'material-table';
import { ShippingOption } from './ShippingOptions';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DropShipAddressAssignment {
    orderId: number;
    dropShipAddress: DropShipAddress,    
    assigned: boolean;
    shippingOption: ShippingOption | null | undefined;
}

export interface DropShipAddressesState {
    dropShipAddress: DropShipAddress | null | undefined;
    clientCode: ClientCode;
    orderId: number | null | undefined;
    isAdding: boolean;
    isLoading: boolean;
    isUpdating: boolean;
    isAssigning: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface AddDropShipAddressAction {
    type: 'ADD_DROPSHIP_ADDRESS';
    clientCode: ClientCode;
    orderId: number;
    newAddress: DropShipAddress;
}

interface AddDropShipAddressCompleteAction {
    type: 'ADD_DROPSHIP_ADDRESS_COMPLETE';
    clientCode: ClientCode;
    orderId: number;
    newAddress: DropShipAddress | null | undefined;
}

interface UpdateDropShipAddressAction {
    type: 'UPDATE_DROPSHIP_ADDRESS';
    clientCode: ClientCode;
    address: DropShipAddress;
}

interface UpdateDropShipAddressCompleteAction {
    type: 'UPDATE_DROPSHIP_ADDRESS_COMPLETE';
    clientCode: ClientCode;
    address: DropShipAddress | null | undefined;
}

interface AssignDropShipAddressAction {
    type: 'ASSIGN_DROPSHIP_ADDRESS';
    clientCode: ClientCode;
    assignment: DropShipAddressAssignment;
}

interface AssignDropShipAddressCompleteAction {
    type: 'ASSIGN_DROPSHIP_ADDRESS_COMPLETE';
    clientCode: ClientCode;
    assignment: DropShipAddressAssignment | null | undefined;
}

interface RequestDropShipAddressesAction {
    type: 'REQUEST_DROPSHIP_ADDRESSES';
    clientCode: ClientCode;
    billto: string;
    addressId: number | null | undefined;
}

interface ReceiveDropShipAddressesAction {
    type: 'RECEIVE_DROPSHIP_ADDRESSES';
    clientCode: ClientCode;
    billto: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = AddDropShipAddressAction | AddDropShipAddressCompleteAction |
    UpdateDropShipAddressAction | UpdateDropShipAddressCompleteAction |
    AssignDropShipAddressAction | AssignDropShipAddressCompleteAction |
    RequestDropShipAddressesAction | ReceiveDropShipAddressesAction |
    MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    addDropShipAddress: (client: ClientStore.Client, orderId: number, newAddress: DropShipAddress): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dropShipAddresses && !appState.dropShipAddresses.isAdding) {

            let clientAwareAddress: object = Object.assign({}, newAddress, { clientCode: client.code });
            let fetchError: boolean = false;
            let action: string = 'dropshipaddresses/add';
            let url: string = `${action}/${orderId.toString()}`;

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(clientAwareAddress)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'ADD_DROPSHIP_ADDRESS_COMPLETE', clientCode: client.code, orderId: orderId, newAddress: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    dispatch({ type: 'ADD_DROPSHIP_ADDRESS_COMPLETE', clientCode: client.code, orderId: orderId, newAddress: data as DropShipAddress });
                    let actionComplete: MessageStore.Message = {
                        messageType: MessageStore.MessageType.ACTIONCOMPLETE,
                        action: action,
                        reference: null,
                        text: 'Add complete'
                    };
                    dispatch({ type: 'BROADCAST_MESSAGE', message: actionComplete });
                },
                err => {
                    if (!fetchError) {
                        dispatch({ type: 'ADD_DROPSHIP_ADDRESS_COMPLETE', clientCode: client.code, orderId: orderId, newAddress: null });
                        dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                    }
                });

            dispatch({ type: 'ADD_DROPSHIP_ADDRESS', clientCode: client.code, orderId: orderId, newAddress: newAddress });
        }
    },
    updateDropShipAddress: (client: ClientStore.Client, address: DropShipAddress): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dropShipAddresses && !appState.dropShipAddresses.isUpdating) {

            let clientAwareAddress: object = Object.assign({}, address, { clientCode: client.code });
            let fetchError: boolean = false;
            let action: string = 'dropshipaddresses/update';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'PUT',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(clientAwareAddress)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'UPDATE_DROPSHIP_ADDRESS_COMPLETE', clientCode: client.code, address: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    dispatch({ type: 'UPDATE_DROPSHIP_ADDRESS_COMPLETE', clientCode: client.code, address: data as DropShipAddress });
                    let actionComplete: MessageStore.Message = {
                        messageType: MessageStore.MessageType.ACTIONCOMPLETE,
                        action: action,
                        reference: null,
                        text: 'Update complete'
                    };
                    dispatch({ type: 'BROADCAST_MESSAGE', message: actionComplete });
                },
                err => {
                    if (!fetchError) {
                        if (!fetchError) {
                            dispatch({ type: 'UPDATE_DROPSHIP_ADDRESS_COMPLETE', clientCode: client.code, address: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    }
                  });

            dispatch({ type: 'UPDATE_DROPSHIP_ADDRESS', clientCode: client.code, address: address });
        }
    },
    assignDropShipAddress: (client: ClientStore.Client, assignment: DropShipAddressAssignment): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dropShipAddresses && !appState.dropShipAddresses.isAssigning) {

            let clientAwareAssignment: object = Object.assign({}, assignment, { clientCode: client.code });
            let fetchError: boolean = false;
            let action: string = 'dropshipaddresses/assign';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(clientAwareAssignment)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'ASSIGN_DROPSHIP_ADDRESS_COMPLETE', clientCode: client.code, assignment: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    dispatch({ type: 'ASSIGN_DROPSHIP_ADDRESS_COMPLETE', clientCode: client.code, assignment: assignment });
                    let actionComplete: MessageStore.Message = {
                        messageType: MessageStore.MessageType.ACTIONCOMPLETE,
                        action: action,
                        reference: null,
                        text: 'Assignment complete'
                    };
                    dispatch({ type: 'BROADCAST_MESSAGE', message: actionComplete });
                },
                    err => {
                    if (!fetchError) {
                        dispatch({ type: 'ASSIGN_DROPSHIP_ADDRESS_COMPLETE', clientCode: client.code, assignment: null });
                        dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                    }
                });

            dispatch({ type: 'ASSIGN_DROPSHIP_ADDRESS', clientCode: client.code, assignment: assignment });
        }
    },
    requestDropShipAddresses: (client: ClientStore.Client, billto: string, addressId: number | null | undefined, query: Query<DropShipAddress>, broadcastError: boolean): AppThunkAction<KnownAction> => (dispatch, getState): Promise<QueryResult<DropShipAddress>> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.dropShipAddresses && !appState.dropShipAddresses.isLoading) {

                let clientAwareQuery: object = Object.assign({}, query, { clientCode: client.code }, { billto: billto });
                let fetchError: boolean = false;
                let action: string = 'dropshipaddresses/get';
                let url: string = addressId ? `${action}/${addressId.toString()}` : `${action}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(clientAwareQuery)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_DROPSHIP_ADDRESSES', clientCode: client.code, billto: billto });
                                    if (broadcastError) {
                                        dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                    }
                                    else {
                                        reject(errorMessage.text);
                                    }
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                        dispatch({ type: 'RECEIVE_DROPSHIP_ADDRESSES', clientCode: client.code, billto: billto });
                    },
                    err => {
                        if (!fetchError) {
                            reject(err.message);
                            dispatch({ type: 'RECEIVE_DROPSHIP_ADDRESSES', clientCode: client.code, billto: billto });
                            if (broadcastError) {
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                        }
                    });
                
                dispatch({ type: 'REQUEST_DROPSHIP_ADDRESSES', clientCode: client.code, billto: billto, addressId: addressId });
            }
            else {
                resolve({ data: [], query: query, page: query.page, totalCount: 0 } as QueryResult<DropShipAddress>);
            }
        });
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: DropShipAddressesState = {
    dropShipAddress: null,
    clientCode: ClientCode.Undefined,
    orderId: null,
    isAdding: false,
    isLoading: false,
    isUpdating: false,
    isAssigning: false,
};

export const reducer: Reducer<DropShipAddressesState> = (state: DropShipAddressesState | undefined, incomingAction: Action): DropShipAddressesState => {
    if (state == undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_DROPSHIP_ADDRESS':
            return {
                clientCode: action.clientCode,
                orderId: action.orderId,
                dropShipAddress: action.newAddress,
                isAdding: true,
                isLoading: state.isLoading,
                isUpdating: state.isUpdating,
                isAssigning: state.isAssigning
            }
        case 'ADD_DROPSHIP_ADDRESS_COMPLETE':
            return {
                clientCode: action.clientCode,
                orderId: action.orderId,
                dropShipAddress: action.newAddress,
                isAdding: false,
                isLoading: state.isLoading,
                isUpdating: state.isUpdating,
                isAssigning: state.isAssigning
            }
        case 'UPDATE_DROPSHIP_ADDRESS':
            return {
                clientCode: action.clientCode,
                orderId: state.orderId,
                dropShipAddress: action.address,
                isAdding: state.isAdding,
                isLoading: state.isLoading,
                isUpdating: true,
                isAssigning: state.isAssigning
            }
        case 'UPDATE_DROPSHIP_ADDRESS_COMPLETE':
            return {
                clientCode: action.clientCode,
                orderId: state.orderId,
                dropShipAddress: action.address,
                isAdding: state.isAdding,
                isLoading: state.isLoading,
                isUpdating: false,
                isAssigning: state.isAssigning
            }
        case 'ASSIGN_DROPSHIP_ADDRESS':
            return {
                clientCode: action.clientCode,
                orderId: state.orderId,
                dropShipAddress: state.dropShipAddress,
                isAdding: state.isAdding,
                isLoading: state.isLoading,
                isUpdating: state.isUpdating,
                isAssigning: true
            }
        case 'ASSIGN_DROPSHIP_ADDRESS_COMPLETE':
            return {
                clientCode: action.clientCode,
                orderId: state.orderId,
                dropShipAddress: state.dropShipAddress,
                isAdding: state.isAdding,
                isLoading: state.isLoading,
                isUpdating: state.isUpdating,
                isAssigning: false
            }
        case 'REQUEST_DROPSHIP_ADDRESSES':
            return {
                clientCode: action.clientCode,
                orderId: state.orderId,
                dropShipAddress: state.dropShipAddress,
                isAdding: state.isAdding,
                isLoading: true,
                isUpdating: state.isUpdating,
                isAssigning: state.isAssigning
            }
        case 'RECEIVE_DROPSHIP_ADDRESSES':
            return {
                clientCode: action.clientCode,
                orderId: state.orderId,
                dropShipAddress: state.dropShipAddress,
                isAdding: state.isAdding,
                isLoading: false,
                isUpdating: state.isUpdating,
                isAssigning: state.isAssigning
            }
        default:
            return state;
    }

}