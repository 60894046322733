import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { motion } from 'framer-motion';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as OrderItemsStore from '../../store/OrderItems';
import { OrderItem } from '../../store/OrderItems';
import * as ErrorMessage from '../../common/ErrorMessage';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { Button } from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';

import './OrderItemNotes.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    orderItemsState: {} as OrderItemsStore.OrderItemsState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
}

interface OrderItemNotesAsyncActions {
    asyncActions: {
        updateOrderItemNotesAsync: (clientCode: ClientCode, orderId: number, orderItem: OrderItem, broadcastError: boolean) => Promise<OrderItem | null | undefined>;
    }
}

interface OrderItemNotesOwnProps {
    client: Client;
    orderItem: OrderItemsStore.OrderItem;
    orderItemNotes: string;
    onEditNotes: (event: React.KeyboardEvent | React.MouseEvent, orderItem: OrderItem) => void;
    onRemoveNotes: (orderItem: OrderItem | null | undefined) => void;
}

type OrderItemNotesProps =
    OrderItemNotesOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & OrderItemNotesAsyncActions;

// ----------------
// LOCAL STATE

interface OrderItemNotesState {
    submitting: boolean;
}

class OrderItemNotes extends React.PureComponent<OrderItemNotesProps, OrderItemNotesState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR
    constructor(props: OrderItemNotesProps, state: OrderItemNotesState) {
        super(props);
        this.state = {
            submitting: false
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: OrderItemNotesProps) => {
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <div className="order-item-notes">
                <div className="order-item-notes-label">
                    <label>Notes</label>
                </div>
                <div className="order-item-notes-text">
                    {this.props.orderItem.isEditable && (
                        <div className="order-item-notes-text-header">
                            <Button color="link" href={"#"} onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.props.onEditNotes(e, this.props.orderItem)}
                                disabled={this.state.submitting} >
                                Edit
                            </Button>
                            <span className="divider">|</span>
                            <Button color="link" href={"#"} onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.onRemoveNotes(e)}
                                disabled={this.state.submitting}>
                                Remove
                            </Button>
                        </div>
                    )}
                    <div className="order-item-notes-text-body">
                        {this.props.orderItemNotes}
                    </div>
                </div>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private onRemoveNotes = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        this.setState({
            submitting: true
        });

        let updatedOrderItem: OrderItem = cloneDeep(this.props.orderItem);
        updatedOrderItem.notes = "";

        this.props.asyncActions.updateOrderItemNotesAsync(this.props.client.code, this.props.orderItem.orderId, updatedOrderItem, true)
            .then(result => {
                this.setState({
                    submitting: false
                });
                this.props.onRemoveNotes(updatedOrderItem);
            },
            err => {
                this.setState({
                    submitting: false
                });
            }
        )
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        orderItemsState: state.orderItems
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators), dispatch),
        asyncActions: {
            updateOrderItemNotesAsync: (clientCode: ClientCode, orderId: number, orderItem: OrderItem, broadcastError: boolean) =>
                dispatch(OrderItemsStore.actionCreators.updateOrderItemNotes(clientCode, orderId, orderItem, broadcastError))
        }
    };
}

export default connect<{}, {}, OrderItemNotesOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(OrderItemNotes as any);
