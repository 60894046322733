import * as React from 'react';
import { Product } from '../../store/Products';
import { Style } from '../../common/ProductTypes';
import { ClientCode } from '../../enums/ClientCode';
import { Container, Col, Row } from 'reactstrap';
import ImageLoader from '../image-loader/ImageLoader';
import $ from 'jquery';

import './ProductLightbox.scss';

// ----------------
// PROPS

interface ProductLightboxProps {
    clientCode: ClientCode;
    products: Product[];
    isLoading: boolean;
    onSelect: (product: Product) => void;
}

// ----------------
// LOCAL STATE

interface ProductLightboxState {
}

class ProductLightbox extends React.PureComponent<ProductLightboxProps, ProductLightboxState> {
 
    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductLightboxProps, state: ProductLightboxState) {
        super(props);
        this.state = {
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: ProductLightboxProps) => {
        if (this.props.isLoading && !prevProps.isLoading) {
            $('.product-lightbox .lightbox-item').removeClass('loaded');
        }
    }

    public componentWillUnmount = () => {}

    public render = () => {
        return (
            <Container className="product-lightbox">
                <Row>
                    {this.props.products.map((product: Product, columnIndex: number) => (
                        <Col className={"lightbox-item" + (this.props.isLoading ? " loading" : "")} lg={3} md={4} sm={6} xs={6} key={"col" + columnIndex.toString()}>
                            <a href="#" onClick={(e: React.MouseEvent) => this.onSelectProduct(e, product)}>
                                <div className="lightbox-image">
                                    <div className="image-stretcher">
                                        <div className="image-canvas">
                                            <ImageLoader url={product.imageUrl} isLoading={this.props.isLoading} onLoad={() => this.onProductImageLoad(columnIndex)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="lightbox-tag">
                                    <label className="short-description">{product.shortDescription}</label>
                                    <label className="style-name">{product.style.name}</label>
                                    <div className="two-column-label">
                                        <label className="price">${product.wholesale.toFixed(2)}</label>
                                        <label className="color">
                                            {product.dimensionCount > 1 && (
                                                <React.Fragment>
                                                    <span>{product.dimensionCount} lengths</span>
                                                    {product.colorCount > 1 && (
                                                        <span> \ </span>
                                                    )}
                                                </React.Fragment>
                                            )}           
                                            {product.colorCount > 1 && (
                                                <span>{product.colorCount} colors</span>
                                            )}
                                        </label>
                                    </div>
                                    {product.availability && (
                                        <label className="availability">{product.availability}</label>
                                    )}
                                </div>
                            </a>
                        </Col>
                    ))}
                </Row>
            </Container>
        )
    }

    // ----------------
    // HELPERS

    private getColumnClassname = (index: number): string => {
        let className: string = "mr-4";
        return className;
    }

    private onProductImageLoad = (columnIndex: number): void => {
        let lightboxItem = $('.product-lightbox .lightbox-item:nth-child(' + (columnIndex + 1) + ")");
        lightboxItem.addClass('loaded');
    }

    private onSelectProduct = (event: React.MouseEvent, product: Product): void => {
        event.preventDefault();
        this.props.onSelect(product);        
    }
}

// ----------------
// EXPORT

export default ProductLightbox;

//export default React.forwardRef<ProductLightbox, ProductLightboxProps>((props, ref) => {
//    return <ProductLightbox {...props} ref={ref} />;
//});
