import * as React from 'react';
import { BaseImage } from '../../common/BaseTypes';
import { Fade } from 'react-slideshow-image';
import { motion } from 'framer-motion';
import sanitizeHTML from 'sanitize-html';

import './SlideShow.scss';

// ----------------
// PROPS

interface SlideShowProps {
    duration: number;
    showArrows: boolean;
    slides: BaseImage[];
    onLoad?: () => void;
    initialFadeIn?: boolean;
}

// ----------------
// LOCAL STATE

interface SlideShowState {
    isVisible: boolean;
    fadeIn: boolean;
}

class SlideShow extends React.PureComponent<SlideShowProps, SlideShowState> {

    // ----------------
    // VARIABLES

    public imageElement: any;

    public slideShowVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: SlideShowProps) {
        super(props);
        this.state = {
            isVisible: false,
            fadeIn: this.props.initialFadeIn ? true : false
        };
        this.imageElement = React.createRef<Element>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {        
    }

    public render = () => {
        return (
            <motion.div id="slideShow" className="slide-container" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"} variants={this.slideShowVariants} transition={{ duration: this.state.fadeIn ? 0.25 : 0 }}>
                <Fade arrows={this.props.showArrows} duration={this.props.duration}>
                    {this.props.slides.map((fadeImage: BaseImage, index: number) => (
                        <div className="each-fade" key={index}>
                            <img src={fadeImage.url} ref={node => this.imageElement.current = node} onLoad={this.onImageLoad} />
                            {fadeImage.caption && (
                                <div className="caption" dangerouslySetInnerHTML={this.sanitize(fadeImage.caption)}></div>
                            )}
                        </div>
                    ))}
                </Fade>
            </motion.div>
        );
    }

    // ----------------
    // HELPERS

    private onImageLoad = (): void => {
        if (!this.state.isVisible) {
            this.setState({
                isVisible: true
            });
            if (this.props.onLoad) {
                this.props.onLoad();
            }
        }
    }

    private sanitize = (input: string | null): { __html: string } => {
        let output: string = input || '';
        return {
            __html: sanitizeHTML(output)
        };
    }
}

// ----------------
// EXPORT
export default SlideShow;
