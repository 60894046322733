import * as React from 'react';
import { Client } from '../../store/Client';
import { Customer } from '../../store/Customers';
import { CustomerType } from '../../enums/CustomerType';
import { ClientCode } from '../../enums/ClientCode';

import './CustomerCard.scss';

// ----------------
// PROPS

interface CustomerCardProps {
    client: Client | null;
    data: Customer;
}

class CustomerCard extends React.PureComponent<CustomerCardProps> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: CustomerCardProps) {
        super(props);
    }

    // ----------------
    // METHODS

    public render = () => {
        return (
            <div className="customer-card">
                <div className="id-column">
                    <label>{this.getCustomerNumber()}</label>
                    <label>{this.getCustomerType()}</label>
                </div>
                <div>{this.props.data.nameOnly}</div>
                <div>{this.props.data.state.name} {this.props.data.country.code}</div>
            </div>
        )
    }

    // ----------------
    // HELPERS

    private getCustomerNumber = (): string => {
        let customerNumber: string = this.props.data.number;
        customerNumber = this.props.data.billto;
        if (this.props.client && this.props.client.rules.displayShipToNumber) {
            if (this.props.data.shipto) {
                customerNumber = customerNumber + '-' + this.props.data.shipto;
            }
        }        
        return customerNumber;
    }

    private getCustomerType = (): string | null | undefined => {
        let customerType: string | null | undefined = CustomerType[this.props.data.type];
        if (this.props.client && this.props.client.rules.displayShipToNumber) {
            customerType = this.props.data.salesChannel.description;
        }
        else if (this.props.data.locationDescription) {
            customerType = this.props.data.locationDescription;
        }
        return customerType;
    }
}

// ----------------
// EXPORT

export default CustomerCard;
