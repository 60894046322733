import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import * as OrderItemsStore from '../../store/OrderItems';
import * as UserStore from '../../store/User';
import { Client } from '../../store/Client';
import { CustomerAccount } from '../../store/Customers';
import { Role } from '../../enums/Role';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import OrderPicker from '../order-picker/OrderPicker';
import AddOrderForm from '../add-order-form/AddOrderForm';
import * as ErrorMessage from '../../common/ErrorMessage';
import Loader from '../loader/Loader';
import {convertBaseOrderToOrder, convertOrderDetailToOrder} from '../../common/OrderFunctions';
import cloneDeep from 'lodash/cloneDeep';

import './AddOrderItems.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState,
    orderItemsState: {} as OrderItemsStore.OrderItemsState,
    userState: {} as UserStore.UserState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrdersStore.actionCreators, OrderItemsStore.actionCreators)
};

interface AddOrderItemsAsyncActions {
    asyncActions: {
    }
}

interface AddOrderItemsOwnProps {
    isOpen: boolean;
    client: Client;
    customer: CustomerAccount | null | undefined;
    selectedItems: OrderItemsStore.NewOrderItem[];
    onDismiss: (id: number | null | undefined) => void;
    onGoBack: (orderDetail: OrdersStore.OrderDetail) => void;
}

type AddOrderItemsProps =
    RouteComponentProps
    & AddOrderItemsOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & AddOrderItemsAsyncActions;

// ----------------
// LOCAL STATE

interface AddOrderItemsState {
    submitting: boolean;
    retrieving: boolean;
    retrievingOrder: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    submitExistingOrderRequested: boolean;
    submitNewOrderRequested: boolean;
    formResult: string | null;
    actions: FieldWrapper.OptionValue[];
    selectedAction: number;
    selectedOrder: OrdersStore.Order | null | undefined;
    mostRecentOrder: OrdersStore.Order | null | undefined;
    orderDetail: OrdersStore.OrderDetail | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateAddOrderItemsForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (formValues.action === 0) {
        errors.action = 'Select action';
    }
    return errors;
}

class AddOrderItems extends React.PureComponent<AddOrderItemsProps, AddOrderItemsState> {

    // ----------------
    // VARIABLES

    public collapsibleActionBarVariants = {
        hidden: { height: 0, overflow: 'hidden', paddingBottom: '0', paddingTop: '0' },
        visible: { height: 'auto', overflow: 'visible', paddingBottom: '10px', paddingTop: '20px' }
    }

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', overflow: 'visible' }
    }

    public collapsibleFooterVariants = {
        hidden: { height: 0, overflow: 'hidden', width: '100%' },
        visible: { height: 'auto', overflow: 'hidden', width: '100%' }
    }

    public confirmationMessageVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: AddOrderItemsProps, state: AddOrderItemsState) {
        super(props);
        this.state = {
            submitting: false,
            retrieving: false,
            retrievingOrder: false,
            changesAccepted: false,
            changesRejected: false,
            submitExistingOrderRequested: false,
            submitNewOrderRequested: false,
            formResult: null,
            actions: this.getActions(undefined),
            selectedAction: 0,
            selectedOrder: undefined,
            mostRecentOrder: undefined,
            orderDetail: undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: AddOrderItemsProps) => {
        if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
            this.props.onDismiss(null);
        }
        else if (this.props.ordersState.orderDetail && !prevProps.ordersState.orderDetail) {
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        switch (this.state.selectedAction) {
            case 1:
                if (this.state.selectedOrder && this.state.selectedOrder.id) {
                    this.setState({
                        submitting: true,
                        selectedAction: 3
                    });
                    setTimeout(() => {
                        this.addOrderItems(this.state.selectedOrder as OrdersStore.BaseOrder);
                    }, 400);               
                }
                else {
                    this.setState({
                        changesRejected: true,
                        formResult: 'Please select an order'
                    });
                }
                break;
            case 2:
                if (this.state.selectedOrder && this.state.selectedOrder.id) {
                    this.setState({
                        submitting: true,
                        submitNewOrderRequested: false,
                        selectedAction: 3
                    });
                    setTimeout(() => {
                        this.addOrderItems(this.state.selectedOrder as OrdersStore.BaseOrder);
                    }, 400);
                }
                else {
                    setTimeout(() => {
                        this.setState({
                            submitNewOrderRequested: true
                        });
                    }, 250);
                }
                break;
            case 3:
                this.setState({
                    submitting: true,
                    changesRejected: false
                });
                setTimeout(() => {
                    this.addOrderItems(this.state.selectedOrder as OrdersStore.BaseOrder)
                }, 400);
                break;
            case 0:
            default:
                this.setState({
                    changesRejected: true,
                    formResult: 'Please select an action'
                });
                break;
        }
    }

    public render = () => {
        return (
            <Modal id="add-order-items" isOpen={this.props.isOpen}
                onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(null)} className={(this.state.retrieving || this.state.retrievingOrder || this.state.submitting) ? "disabled" : ""}>
                    Add to Order
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className={"collapsible-row pl-3 action-bar" + (this.props.ordersState.orderDetail ? " pr-3 text-left" : " pr-4 text-center")}>
                                <motion.div animate={this.state.submitting || this.state.changesAccepted ? "hidden" : "visible"} initial={this.state.submitting || this.state.changesAccepted ? "hidden" : "visible"}
                                    variants={this.collapsibleActionBarVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>
                                    <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                        <Field name="action" type="text" label=""
                                            component={FieldWrapper.renderRadioButtonField}
                                            options={this.state.actions} onChange={this.onSelectAction}
                                            disabled={this.state.retrieving || this.state.retrievingOrder || this.state.submitting || this.state.changesAccepted} />
                                        <button type="submit" ref={this.submitButton}>Submit</button>
                                    </form>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0">
                                <motion.div animate={this.state.selectedAction === 1 ? "visible" : "hidden"} initial={this.state.selectedAction === 1 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>
                                    <Container className="component-wrapper">
                                        <OrderPicker activated={this.state.selectedAction === 1}
                                            customerAccount={this.props.customer} disabled={this.state.retrievingOrder || this.state.submitting || this.state.changesAccepted}
                                            submitRequested={this.state.submitExistingOrderRequested} onSubmit={this.onSelectOrderFormSubmit}
                                            onSubmitFail={this.onSelectOrderFormSubmitFail} onRetrievingOrder={this.onRetrievingOrder}
                                            onSelectOrder={this.onSelectOrder} />
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0">
                                <motion.div animate={this.state.selectedAction === 2 ? "visible" : "hidden"} initial={this.state.selectedAction === 2 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>
                                    <Container className="component-wrapper">
                                        <AddOrderForm
                                            customerAccount={this.props.customer} disabled={this.state.retrievingOrder || this.state.submitting || this.state.changesAccepted}
                                            submitRequested={this.state.submitNewOrderRequested} onSubmit={this.onAddOrderFormSubmit}
                                            onSubmitFail={this.onAddOrderFormSubmitFail} onSubmitSucceed={this.onAddOrderFormSubmitSucceed}
                                            onRetrievingAccount={this.onRetrievingAccount} onChange={this.onAddOrderFormChange}
                                            customerNumber={this.getCustomerNumber()} resetForm={this.state.selectedAction === 2}
                                            stateless={true} />
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0">
                                <motion.div animate={this.state.selectedAction === 3 ? "visible" : "hidden"} initial={this.state.selectedAction === 3 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>
                                    <Container className={"component-wrapper small" + (this.state.actions.length === 2 ? " borderless" : "")}>
                                        <Row>
                                            <Col className="pb-2 order-items-description text-center">
                                                <div className="order-description-container">
                                                    <label className="subtitle">Adding selected {this.props.selectedItems.length > 1 ? 'items' : 'item'} to order:</label>
                                                    {this.state.selectedOrder && (
                                                        <div className="order-description">
                                                            <label className="order-number">{this.state.selectedOrder.orderNumber}</label>
                                                            <label className="order-customer">{this.state.selectedOrder.customerNameOnly}</label>
                                                            {this.state.selectedOrder.poNumber && (
                                                                <label className="order-po">PO: {this.state.selectedOrder.poNumber}</label>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={this.submitAddOrderItemsForm} disabled={this.state.retrieving || this.state.retrievingOrder || this.state.submitting }>
                                    {this.getSubmitButtonLabel()}
                                </Button>
                                <Button color="link" onClick={this.cancelOrderItemsForm} disabled={this.state.retrieving || this.state.retrievingOrder || this.state.submitting }>
                                    {this.getCancelButtonLabel()}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private addOrderItems = (order: OrdersStore.BaseOrder): void => {
        let fetchError: boolean = false;
        let action: string = 'orders/additems/' + order.id;
        let url: string = `${action}`;

        let newOrderItems: OrderItemsStore.NewOrderItems = {
            client: this.props.client,
            customerNumber: order.customerNumber,
            orderId: order.id as number,
            items: cloneDeep(this.props.selectedItems)
        };

        fetch(url,
            {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify(newOrderItems)
            })
            .then(response => {
                if (response.status >= 400) {
                    setTimeout(() => {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                this.setState({
                                    submitting: false,
                                    changesRejected: true,
                                    formResult: errorMessage.text
                                });
                            })
                        );
                    });
                    fetchError = true;
                    throw new Error();
                }
                return response.json();
            })
            .then(data => {
                this.setState({
                    submitting: false,
                    changesAccepted: true,
                    formResult: 'Changes saved',
                    orderDetail: data.orderDetail
                });
            },
            err => {
                if (!fetchError) {
                    this.setState({
                        submitting: false,
                        changesRejected: true,
                        formResult: err
                    });
                }
            });        
    }

    private cancelOrderItemsForm = (): void => {
        if (this.state.selectedAction === 3 && this.state.changesAccepted) {
            this.goToOrder();
        }
        else {
            this.props.onDismiss(null);
        }
    }

    private getCustomerNumber = (): string | null => {
        let customerNumber: string | null = null;
        if (this.props.userState.user && this.props.userState.user.role === Role.Buyer) {
            customerNumber = this.props.userState.user.customerNumber;
        }
        return customerNumber;
    }

    private getActions = (selectedOrder: OrdersStore.Order | null | undefined): FieldWrapper.OptionValue[] => {
        let actions: FieldWrapper.OptionValue[] = [];
        if (selectedOrder) {
            actions.push({ label: this.props.ordersState.orderDetail ? 'Current Order' : 'Most Recent Order', value: 3 });
            actions.push({ label: 'Another Order', value: 1 });
            actions.push({ label: 'New Order', value: 2 });
        }
        else {
            actions.push({ label: 'Select Existing Order', value: 1 });
            actions.push({ label: 'Start New Order', value: 2 });
        }
        return actions;
    }

    private getCancelButtonLabel = (): string => {
        let buttonLabel: string = 'Cancel';
        if (this.state.selectedAction == 3 && this.state.changesAccepted) {
            buttonLabel = 'Go to Cart';
        }
        return buttonLabel;
    }

    private getOrderNumber = (): string | null | undefined => {
        let orderNumber: string | null | undefined = '';
        if (this.state.selectedOrder) {
            orderNumber = this.state.selectedOrder.orderNumber;
        }
        return orderNumber;
    }

    private getSubmitButtonLabel = (): string => {
        let buttonLabel: string = 'Continue';
        if (this.state.retrievingOrder || this.state.submitting) {
            buttonLabel = 'Working...';
        }
        else if (this.state.selectedAction == 3 && this.state.changesRejected) {
            buttonLabel = 'Retry';
        }
        return buttonLabel;
    }

    private goBack = (): void => {
        if (this.state.orderDetail) {
            this.props.onGoBack(this.state.orderDetail);
        }
    }

    private goToOrder = (): void => {
        if (this.state.selectedOrder) {
            this.props.onDismiss(this.state.selectedOrder.id);
        }
    }

    private initializeForm = (): void => {        
        let selectedOrder: OrdersStore.Order | undefined = undefined;
        if (this.props.ordersState.orderDetail) {
            selectedOrder = convertOrderDetailToOrder(this.props.ordersState.orderDetail);
        }
        else if (this.state.selectedOrder) {
            selectedOrder = this.state.selectedOrder
        };
        let selectedAction: number = selectedOrder ? 3 : 0;
        this.setState({
            actions: this.getActions(selectedOrder),
            selectedAction: selectedAction,
            selectedOrder: selectedOrder,
            mostRecentOrder: selectedOrder,
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
        })
        this.props.change("action", selectedAction);
    }

    private isOpenOrder = (): boolean => {
        return (this.props.ordersState.orderDetail && this.props.ordersState.orderDetail.orderStatus === 'Open') ? true : false;
    }

    private onAddOrderFormChange = (): void => {
        this.setState({
            formResult: null
        });
    }

    private onAddOrderFormSubmit = (submitting: boolean): void => {
        this.setState({
            formResult: null,
            submitNewOrderRequested: false,
            submitting: submitting
        });
    }

    private onAddOrderFormSubmitFail = (): void => {
        this.setState({
            formResult: null,
            submitNewOrderRequested: false
        });
    }

    private onAddOrderFormSubmitSucceed = (newOrder: OrdersStore.BaseOrder): void => {
        if (newOrder) {
            this.setState({
                changesRejected: false,
                formResult: null,
                selectedOrder: convertBaseOrderToOrder(newOrder)
            });
            setTimeout(() => {
                if (this.submitButton.current) {
                    this.submitButton.current.click();
                }
            });
        }
    }    

    private onRetrievingAccount = (retrieving: boolean): void => {
        this.setState({
            retrieving: retrieving
        });
    }

    private onRetrievingOrder = (retrieving: boolean): void => {
        this.setState({
            retrieving: false,
            submitting: false
        });
    }

    private onSelectOrder = (order: OrdersStore.Order | null | undefined): void => {
        if (order) {
            this.setState({
                changesRejected: false,
                formResult: null,
                selectedOrder: order
            });
        }
    }

    private onSelectAction = (event: React.ChangeEvent): void => {
        let selectedValue = event.target.getAttribute("value");
        if (selectedValue && parseInt(selectedValue) != this.state.selectedAction) {
            this.setState({
                selectedAction: parseInt(selectedValue),
                selectedOrder: parseInt(selectedValue) === 3 ? this.state.mostRecentOrder : undefined
            });
        }
    }

    private onSelectOrderFormSubmit = (submitting: boolean): void => {
        this.setState({
            formResult: null,
            submitExistingOrderRequested: false,
            submitting: submitting
        });
    }

    private onSelectOrderFormSubmitFail = (): void => {
        this.setState({
            formResult: null,
            submitExistingOrderRequested: false
        });
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private resetForm = (): void => {
        this.setState({
            changesAccepted: false,
            changesRejected: false,
            submitting: false,
            submitExistingOrderRequested: false,
            submitNewOrderRequested: false,
            formResult: null,
            selectedAction: 0
        });
        this.props.reset();
    }

    private retrieveOrder = (orderId: number): void => {
        this.setState({
            retrievingOrder: true
        });
        setTimeout(() => {
            this.props.actions.requestOrderDetail(this.props.client, orderId);
        })
    }

    private submitAddOrderItemsForm = (): void => {
        if (this.state.selectedAction === 3 && this.state.changesAccepted) {
            this.goBack();
        }
        else if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        ordersState: state.orders,
        orderItemsState: state.orderItems,
        userState: state.user
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrdersStore.actionCreators,
            OrderItemsStore.actionCreators), dispatch),
        asyncActions: {
        }
    };
}

export default connect<{}, {}, AddOrderItemsOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'addOrderItemsForm',
    validate: validateAddOrderItemsForm,
    enableReinitialize: true
})(AddOrderItems as any));
