import * as React from 'react';
import { OrderDates } from '../../store/OrderDates';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { convertJSDateToString } from '../../common/DateConverter';

import './ShipDateRules.scss';

// ----------------
// PROPS

interface ShipDateRulesProps {
    orderDates: OrderDates;
}

// ----------------
// LOCAL STATE

interface ShipDateRulesState {
    singleShipDate: boolean;
}

class ShipDateRules extends React.PureComponent<ShipDateRulesProps, ShipDateRulesState> {

    // ----------------
    // CONSTRUCTOR
    constructor(props: ShipDateRulesProps, state: ShipDateRulesState) {
        super(props);
        this.state = {
            singleShipDate: this.hasSingleShipDate()
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
    }

    public render = () => {
        return (
            <div id="ship-date-rules" className={this.hasSingleShipDate() ? "single-date" : ""}>
                <div className="ship-date-rules-wrapper">
                    <label className="title">Earliest Ship Date{this.state.singleShipDate ? "" : "s"}</label>
                    <ListGroup>
                        {this.state.singleShipDate && (
                            <ListGroupItem>
                                <label className="item-value">{this.getSingleShipDate()}</label>
                            </ListGroupItem>
                        )}
                        {!this.state.singleShipDate && (
                            <React.Fragment>
                                <ListGroupItem>
                                    <label className="item-type">Non-embroidered</label>
                                    <label className="item-separator">&#45;</label>
                                    <label className="item-value">{this.getNonEmbroideredShipDate()}</label>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <label className="item-type">Embroidered</label>
                                    <label className="item-separator">&#45;</label>
                                    <label className="item-value">{this.getEmbroideredShipDate()}</label>
                                </ListGroupItem>
                            </React.Fragment>
                        )}
                    </ListGroup>
                </div>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private getEmbroideredShipDate = (): string => {
        let dateString: string = '';
        if (this.props.orderDates.earliestShipDateEmbroidered) {
            dateString = convertJSDateToString(this.props.orderDates.earliestShipDateEmbroidered as Date, "MMM d");
        }
        return dateString;
    }

    private getNonEmbroideredShipDate = (): string => {
        let dateString: string = '';
        if (this.props.orderDates.earliestShipDateNonEmbroidered) {
            dateString = convertJSDateToString(this.props.orderDates.earliestShipDateNonEmbroidered as Date, "MMM d");
        }
        return dateString;
    }

    private getSingleShipDate = (): string => {
        let dateString: string = '';
        let singleDate: Date | null | undefined = this.props.orderDates.earliestShipDateNonEmbroidered || this.props.orderDates.earliestShipDateEmbroidered;
        if (singleDate) {
            dateString = convertJSDateToString(singleDate as Date, "MMM d");
        }
        return dateString;
    }

    private hasSingleShipDate = (): boolean => {
        let noEmbroidered: boolean = (this.props.orderDates.earliestShipDateNonEmbroidered && !this.props.orderDates.earliestShipDateEmbroidered) ? true : false;
        let noNonEmbroidered: boolean = (this.props.orderDates.earliestShipDateEmbroidered && !this.props.orderDates.earliestShipDateNonEmbroidered) ? true : false;
        return noEmbroidered || noNonEmbroidered;
    }

}

// ----------------
// EXPORT

export default ShipDateRules;
