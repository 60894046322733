﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { Client } from './Client';
import * as MessageStore from './Message';
import * as ErrorMessage from '../common/ErrorMessage';
import { PlacementOption } from '../common/EmbroideryTypes';
import { ClientCode } from '../enums/ClientCode';
import { TableViewType } from '../enums/TableViewType';
import { AvailableUnits, Color, ColorOption, Dimension, Division, GarmentType, Gender, InventoryDate, Season, Size, Style } from '../common/ProductTypes';
import { Query, QueryResult } from 'material-table';
import { convertSQLDateToJSDate } from '../common/DateConverter';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ProductsState {
    products: Products | null;
    productDetail: ProductDetail | null | undefined;
    clientCode: ClientCode;
    isLoading: boolean;
    isLoadingDetail: boolean;
}

export interface Product {
    id: string;
    availability: string;
    brand: string;
    chaseDate: Date | null | undefined;
    color: Color;
    colorCount: number;
    colorDescription: string | null | undefined;
    dimensionCount: number;
    dimensions: Dimension[];
    division: Division;
    earliestAvailable: Date;
    garmentType: GarmentType;
    gender: Gender;
    imageUrl: string;
    inStock: boolean;
    sanitizedId: string;
    season: Season;
    shortDescription: string;
    sizeDescription: string;
    style: Style;
    wholesale: number;
}

export interface ProductDetail extends Product {
    canEmbroider: boolean;
    colorOptions: ColorOption[];
    dimensionDescription: string;
    longDescription: string;
    placementOptions: PlacementOption[];
    sizeScale: string;
    views: string[];
}

export interface Products extends QueryResult<Product> {
    query: Query<any>;
    view: TableViewType;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestProductsAction {
    type: 'REQUEST_PRODUCTS';
    clientCode: ClientCode;
    query: Query<any>;
}

interface ReceiveProductsAction {
    type: 'RECEIVE_PRODUCTS';
    clientCode: ClientCode;
    products: Products | null;
}

interface RequestProductDetailAction {
    type: 'REQUEST_PRODUCT_DETAIL';
    clientCode: ClientCode;
    id: string;
}

interface ReceiveProductDetailAction {
    type: 'RECEIVE_PRODUCT_DETAIL';
    clientCode: ClientCode;
    productDetail: ProductDetail | null | undefined;
}

interface ClearProductsQueryAction {
    type: 'CLEAR_PRODUCTS_QUERY';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = RequestProductsAction | ReceiveProductsAction |
    RequestProductDetailAction | ReceiveProductDetailAction |
    ClearProductsQueryAction | MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const formatProductDates = (products: Products): Products => {    
    for (let i: number = 0; i < products.data.length; i++) {
        if (products.data[i].chaseDate) {
            products.data[i].chaseDate = convertSQLDateToJSDate(products.data[i].chaseDate as Date);
        }
        for (let ii: number = 0; ii < products.data[i].dimensions.length; ii++) {
            let dimension: Dimension = products.data[i].dimensions[ii];
            for (let d: number = 0; d < dimension.inventoryDates.length; d++) {
                dimension.inventoryDates[d].date = convertSQLDateToJSDate(dimension.inventoryDates[d].date);
                dimension.inventoryDates[d].embroideredDate = convertSQLDateToJSDate(dimension.inventoryDates[d].embroideredDate);
            }
            for (let s: number = 0; s < dimension.sizes.length; s++) {
                let size: Size = dimension.sizes[s];
                for (let d: number = 0; d < size.unitsAvailable.length; d++) {
                    let units: AvailableUnits = size.unitsAvailable[d];
                    units.inventoryDate.date = convertSQLDateToJSDate(units.inventoryDate.date);
                    units.inventoryDate.embroideredDate = convertSQLDateToJSDate(units.inventoryDate.embroideredDate);
                }
            }
        }
    }
    return products;
}

const formatProductDetailDates = (productDetail: ProductDetail): ProductDetail => {
    if (productDetail.chaseDate) {
        productDetail.chaseDate = convertSQLDateToJSDate(productDetail.chaseDate);
    }
    for (let i: number = 0; i < productDetail.dimensions.length; i++) {
        let dimension: Dimension = productDetail.dimensions[i];
        for (let d: number = 0; d < dimension.inventoryDates.length; d++) {
            dimension.inventoryDates[d].date = convertSQLDateToJSDate(dimension.inventoryDates[d].date);
            dimension.inventoryDates[d].embroideredDate = convertSQLDateToJSDate(dimension.inventoryDates[d].embroideredDate);
        }
        for (let s: number = 0; s < dimension.sizes.length; s++) {
            let size: Size = dimension.sizes[s];
            for (let d: number = 0; d < size.unitsAvailable.length; d++) {
                let units: AvailableUnits = size.unitsAvailable[d];
                units.inventoryDate.date = convertSQLDateToJSDate(units.inventoryDate.date);
                units.inventoryDate.embroideredDate = convertSQLDateToJSDate(units.inventoryDate.embroideredDate);
            }
        }
    }
    return productDetail;
}

export const actionCreators = {
    requestProducts: (clientCode: ClientCode, query: Query<Product>, view: TableViewType): AppThunkAction<KnownAction> => (dispatch, getState): Promise<QueryResult<Product>> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.products && !appState.products.isLoading) {

                let clientAwareQuery: object = Object.assign({}, query, { clientCode: clientCode, view: view });
                let fetchError: boolean = false;
                let action: string = 'products/get/';
                let url: string = `${action}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(clientAwareQuery)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_PRODUCTS', clientCode: clientCode, products: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let products: Products = formatProductDates(data as Products);
                        products.view = view;
                        resolve(products);                        
                        dispatch({ type: 'RECEIVE_PRODUCTS', clientCode: clientCode, products: products });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'RECEIVE_PRODUCTS', clientCode: clientCode, products: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

                dispatch({ type: 'REQUEST_PRODUCTS', clientCode: clientCode, query: query });
            }
            else {
                resolve(appState.products ?
                    appState.products.products as QueryResult<Product> :
                    { data: [], query: query, page: query.page, totalCount: 0 } as QueryResult<Product>);
            }
        });
    },
    requestProductDetail: (client: Client, id: string): AppThunkAction<KnownAction> => (dispatch, getState): Promise<ProductDetail | null | undefined> => {
        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.products && !appState.products.isLoadingDetail) {

                let cleanId = decodeURIComponent(id);
                let fetchError: boolean = false;
                let action: string = 'products/getdetail';
                let url: string = `${action}/${encodeURIComponent(cleanId)}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(client)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_PRODUCT_DETAIL', clientCode: client.code, productDetail: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let productDetail: ProductDetail = formatProductDetailDates(data as ProductDetail);
                        resolve(productDetail);                        
                        dispatch({ type: 'RECEIVE_PRODUCT_DETAIL', clientCode: client.code, productDetail: productDetail });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'RECEIVE_PRODUCT_DETAIL', clientCode: client.code, productDetail: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    })

                dispatch({ type: 'REQUEST_PRODUCT_DETAIL', clientCode: client.code, id: id });                
            }
            else {
                resolve(appState.products ? appState.products.productDetail : undefined);
            }
        });
    },
    clearProductsQuery: () => ({ type: 'CLEAR_PRODUCTS_QUERY' } as ClearProductsQueryAction)
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: ProductsState = {
    products: null,
    productDetail: null,
    clientCode: ClientCode.Undefined,
    isLoading: false,
    isLoadingDetail: false
};

export const reducer: Reducer<ProductsState> = (state: ProductsState | undefined, incomingAction: Action): ProductsState => {
    if (state == undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PRODUCTS':
            return {
                clientCode: action.clientCode,
                products: state.products,
                productDetail: state.productDetail,
                isLoading: true,
                isLoadingDetail: false
            };
        case 'RECEIVE_PRODUCTS':
            return {
                clientCode: action.clientCode,
                products: action.products,
                productDetail: state.productDetail,
                isLoading: false,
                isLoadingDetail: false
            };
        case 'REQUEST_PRODUCT_DETAIL':
            return {
                clientCode: action.clientCode,
                products: state.products,
                productDetail: state.productDetail,
                isLoading: false,
                isLoadingDetail: true
            };
        case 'RECEIVE_PRODUCT_DETAIL':
            return {
                clientCode: action.clientCode,
                products: state.products,
                productDetail: action.productDetail,
                isLoading: false,
                isLoadingDetail: false
            }
        case 'CLEAR_PRODUCTS_QUERY':
            return {
                clientCode: state.clientCode,
                products: null,
                productDetail: state.productDetail,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail                
            }
        default:
            return state;
    }
}