import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as CustomersStore from '../../store/Customers';
import { ClientCode } from '../../enums/ClientCode';
import { CustomerType } from '../../enums/CustomerType';
import { EmbroideryTapeType } from '../../enums/EmbroideryTapeType';
import { Address } from '../../common/AddressTypes';
import { CustomerContact, FreightTerms, ShipVia, Terms } from '../../common/AccountTypes';
import { Logo, NewLogo } from '../../common/EmbroideryTypes';
import { Query, QueryResult } from 'material-table';
import { Button, Container, Col, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';
import { Select } from '@material-ui/core';
import AddressForm from '../address-form/AddressForm';
import ShipToPicker from '../shipto-picker/ShipToPicker';
import SalesSummary from '../sales-summary/SalesSummary';
import ContactForm from '../contact-form/ContactForm';
import Loader from '../loader/Loader';
import ImageLoader from '../image-loader/ImageLoader';
import TapeViewer from '../tape-viewer/TapeViewer';
import AddLogo from '../add-logo/AddLogo';
import cloneDeep from 'lodash/cloneDeep';
import { formatNumberAsCurrencyForCountry } from '../../common/CurrencyFormatter';

import './CustomerForm.scss';

// ----------------
// PROPS

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    customersState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, CustomersStore.actionCreators, MessageStore.actionCreators)
};

interface CustomerFormAsyncActions {
    asyncActions: {
        requestCustomerLogosAsync: (clientCode: ClientCode, billto: string, shipto: string, query: Query<any>, broadcastError: boolean) => Promise<QueryResult<any>>;
    }
}

interface CustomerFormOwnProps {    
    customerDetail: CustomersStore.CustomerAccount | null | undefined;
}

type CustomerFormProps =
    CustomerFormOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators    // ... plus action creators we've requested
    & CustomerFormAsyncActions;

// ----------------
// LOCAL STATE

interface CustomerFormState {
    initialized: boolean;
    selectedShipTo: CustomersStore.CustomerDetail | undefined;
    noCustomerLogos: boolean;
    customerLogos: Logo[] | undefined;
    customerLogoQuery: Query<Logo> | undefined;
    isLoadingLogos: boolean;
    isLogoLoadError: boolean;
    logoLoadError: string | null;
    isAddingNewLogo: boolean;
    isViewingLogo: boolean;
    viewingLogo: Logo | null | undefined;
}

class CustomerForm extends React.PureComponent<CustomerFormProps, CustomerFormState> {

    // ----------------
    // VARIABLES

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden'},
        visible: { height: 'auto' }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: CustomerFormProps, state: CustomerFormState) {
        super(props);
        this.state = {
            initialized: this.props.customerDetail ? true : false,
            selectedShipTo: this.getSelectedShipTo(),
            noCustomerLogos: false,
            customerLogos: undefined,
            customerLogoQuery: undefined,
            isLoadingLogos: false,
            isLogoLoadError: false,
            logoLoadError: null,
            isAddingNewLogo: false,
            isViewingLogo: false,
            viewingLogo: undefined
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.getCustomerLogos(undefined);
    }

    public componentDidUpdate = (prevProps: CustomerFormOwnProps) => {
        if (this.isDifferentCustomer(prevProps)) {
            this.setState({
                initialized: true,
                selectedShipTo: this.getSelectedShipTo()
            });            
            this.getCustomerLogos(undefined);
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <Container id="customer-form">
                {this.props.customerDetail && (
                    <Row>
                        <Col className="pl-0 pr-0 pr-lg-4" sm={12} md={12} lg={7}>
                            <Container>
                                <Row>
                                    <Col className="pl-0 pr-0 mb-4" xs={12} lg={6}>
                                        <div className="info-panel explanatory">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>Shipping</label>
                                                </div>
                                            </div>
                                            <Container className="content address-container">
                                                <motion.div animate={this.state.selectedShipTo ? "visible" : "hidden"} initial={"hidden"}
                                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.getShipToConfiguration() === 3 ? 0.5 : 0) }}>
                                                    <Row>
                                                        <Col>
                                                            {this.state.selectedShipTo && (
                                                                <AddressForm customerDetail={this.state.selectedShipTo} readonly={true}
                                                                    showLabels={true} showPlaceholder={true} />
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </motion.div>
                                                {this.getShipToConfiguration() >= 2 && (
                                                    <Row className="shipto-picker-container offset-bottom offset-top mr-sm-4">
                                                        <Col className="text-center mb-2 pl-1 pr-0 pt-0" xs={12}>
                                                            <label>Account has {this.props.customerDetail.shipToLocations.length} ship-to locations</label>
                                                        </Col>
                                                        <Col className="pr-0 pl-0 text-left" xs={12}>
                                                            <ShipToPicker shipToLocations={this.props.customerDetail.shipToLocations} placeholder="Select ..."
                                                                showSelectedItem={false} onSelectShipTo={this.onSelectShipTo} />
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Container>
                                        </div>
                                    </Col>
                                    <Col className="pl-0 pr-0 pl-lg-4 mb-4" xs={12} lg={6}>
                                        <div className="info-panel explanatory">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>Billing</label>
                                                </div>
                                            </div>
                                            <div className="content address-container">
                                                {this.billToSameAsShipTo() && (
                                                    <label>Same as Shipping</label>   
                                                )}
                                                {!this.billToSameAsShipTo() && (
                                                    <AddressForm customerDetail={this.props.customerDetail} readonly={true}
                                                        showLabels={true} showPlaceholder={true} />
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pl-0 pr-0 mb-4" xs={12}>
                                        <div className="info-panel explanatory">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>Contacts</label>
                                                </div>
                                            </div>
                                            <Container className="content">
                                                {this.props.customerDetail && this.props.customerDetail.contacts.length > 0 && (
                                                    <Row>
                                                        <Col className="pl-0 pr-0 pr-md-5 pt-2 pt-md-0">
                                                            {this.props.customerDetail.contacts.map((contact: CustomerContact, contactIndex: number) => (
                                                                <ContactForm key={"contactForm-" + contactIndex} contact={contact} clientCode={this.props.clientState.client ? this.props.clientState.client.code : ClientCode.Undefined} />
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Container>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pl-0 pr-0 mb-4" xs={12}>
                                        <div className="info-panel editable">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>Logos</label>
                                                </div>
                                                {this.customerLogosRetrieved() && (
                                                    <div className="button-bar">
                                                        <Button color="link" onClick={this.onAddNewLogo} disabled={this.state.isLoadingLogos}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-plus">
                                                                <line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line>
                                                            </svg>
                                                            <label>Add Logo</label>
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                            <Container className="content">
                                                <Row>
                                                    <Col className={"logo-container collapsible-row pl-0 pr-0 pt-2 pt-md-0" + (this.state.isLoadingLogos ? " loading" : "")}>
                                                        <Loader isLoading={this.state.isLoadingLogos} isChild={true} />
                                                        <motion.div animate={this.customerLogosRetrieved() ? "visible" : "hidden"} initial={"hidden"}
                                                            variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                                                            <Container>
                                                                <Row>
                                                                    <Col className={"pl-0 pr-0 pb-0 logos" + (this.state.isLogoLoadError ? " error" : "") + (this.state.noCustomerLogos ? " no-logos" : "")} xs={12}>
                                                                        {this.state.customerLogos && (
                                                                            <React.Fragment>
                                                                                <div className="logo-list">
                                                                                    {this.state.customerLogos.map((logo: Logo, logoIndex: number) => (
                                                                                        <div className="logo" key={"logo-" + logoIndex}>
                                                                                            <a className="logo-image" onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.onViewLogo(e, logo)}>
                                                                                                <div className={"logo-image-stretcher" + (logo.tapeType === EmbroideryTapeType.New ? " new" : "")}>
                                                                                                    <div className="logo-image-canvas">
                                                                                                        <ImageLoader url={logo.logoUrl} thumbnail={true} isLoading={this.state.isLoadingLogos} autoSize={true} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </a>
                                                                                            <div className="logo-tag">
                                                                                                <Button color="link" title={"View Detail"} onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.onViewLogo(e, logo)}>Tape # {logo.tapeNumber}</Button>
                                                                                                {logo.tapeType === EmbroideryTapeType.New && (
                                                                                                    <a className="new-logo" onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.onViewLogo(e, logo)}>Pending</a>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                                <div className="logo-list-pager">
                                                                                    {this.state.customerLogoQuery && this.state.customerLogoQuery.totalCount > 4 && (
                                                                                        <div className="logo-list-pager-wrapper">
                                                                                            <Button color="link" onClick={this.getPreviousLogos} disabled={this.isFirstLogoPage()}>
                                                                                                <svg className="MuiSvgIcon-root" fill="currentColor" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                                                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                                                </svg>
                                                                                            </Button>
                                                                                            <div className="pagination">
                                                                                                <label>{this.getLogoPagination()}</label>
                                                                                            </div>
                                                                                            <Button color="link" onClick={this.getNextLogos} disabled={this.isLastLogoPage()}>
                                                                                                <svg className="MuiSvgIcon-root" fill="currentColor" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                                                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                                                </svg>
                                                                                            </Button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                        {this.state.noCustomerLogos && (
                                                                            <div className="logo-undefined-error">
                                                                                <div className="logo-undefined-error-message">
                                                                                    No logos on file
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {this.state.logoLoadError && (
                                                                            <div className="logo-load-error">
                                                                                <div className="logo-load-error-message">
                                                                                    <div className="icon">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                                            <path strokeWidth="1" d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                                                                            <line x1="12" y1="9" x2="12" y2="13"></line>
                                                                                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                                                                        </svg>
                                                                                    </div>
                                                                                    <div className="text">{this.state.logoLoadError}</div>
                                                                                </div>
                                                                                <div className="logo-load-error-actions">
                                                                                    <Button color="secondary" onClick={this.onReloadLogos}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                                                                            <polyline points="1 4 1 10 7 10"></polyline>
                                                                                            <polyline points="23 20 23 14 17 14"></polyline>
                                                                                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                                                                        </svg>
                                                                                        Reload
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </motion.div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col className="pl-0 pr-0" sm={12} md={12} lg={5}>
                            <Container>
                                <Row>
                                    <Col className="pl-0 pr-0 mb-4">
                                        <div className="info-panel explanatory">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>Terms &amp; Conditions</label>
                                                </div>
                                            </div>
                                            <Container className="content account-container">
                                                <Row>
                                                    <Col className="text-left pl-3" xs={6} md={12} xl={6}>
                                                        <Table size="sm" borderless>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pl-0">Terms</td>
                                                                    <td className={!this.props.customerDetail.terms.code ? 'placeholder' : ''}>{this.formatTerms(this.props.customerDetail.terms)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                    <Col className="pl-3 pr-0 text-left" xs={6} md={12} xl={6}>
                                                        <Table size="sm" borderless>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pl-0">PO Req'd</td>
                                                                    <td>{this.props.customerDetail.poRequired ? "Yes" : "No"}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-left pl-3" xs={6} md={12} xl={6}>
                                                        <Table size="sm" borderless>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pl-0">Sales Rep</td>
                                                                    <td className={!this.props.customerDetail.salesRepNumber ? 'placeholder' : ''}>{this.addPlaceholder(this.props.customerDetail.salesRepNumber)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                    <Col className="pl-3 pr-0 text-left" xs={6} md={12} xl={6}>
                                                        <Table size="sm" borderless>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pl-0">Channel</td>
                                                                    <td className={!this.props.customerDetail.salesChannel.description ? 'placeholder' : ''}>{this.props.customerDetail.salesChannel.description}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="optional-fields">
                                                        <Container>
                                                            <Row>
                                                                {this.props.clientState.client && this.props.clientState.client.settings.customerSettings.showDiscount && (
                                                                    <Col className="optional-field text-left pl-0" xs={6} md={12} xl={6}>
                                                                        <Table size="sm" borderless>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="pl-0">Discount</td>
                                                                                    <td className={!this.props.customerDetail.discount ? 'placeholder' : ''}>{this.formatDiscount(this.props.customerDetail.discount)}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </Col>
                                                                )}
                                                                {this.props.clientState.client && this.props.clientState.client.settings.customerSettings.showClass && (
                                                                    <Col className="optional-field text-left pl-0" xs={6} md={12} xl={6}>
                                                                        <Table size="sm" borderless>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="pl-0">Class</td>
                                                                                    <td className={!this.props.customerDetail.class ? 'placeholder' : ''}>{this.addPlaceholder(this.props.customerDetail.class)}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </Col>
                                                                )}
                                                                {this.props.clientState.client && this.props.clientState.client.settings.customerSettings.showShipVia && (
                                                                    <Col className="optional-field text-left pl-0" xs={6} md={12} xl={6}>
                                                                        <Table size="sm" borderless>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="pl-0">Ship Via</td>
                                                                                    <td className={!this.props.customerDetail.shipVia.code ? 'placeholder' : ''}>{this.formatShipVia(this.props.customerDetail.shipVia)}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </Col>
                                                                )}
                                                                {this.props.clientState.client && this.props.clientState.client.settings.customerSettings.showFreight && (
                                                                    <Col className="optional-field text-left pl-0" xs={6} md={12} xl={6}>
                                                                        <Table size="sm" borderless>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="pl-0">Freight</td>
                                                                                    <td className={!this.props.customerDetail.freightTerms.code ? 'placeholder' : ''}>{this.formatFreightTerms(this.props.customerDetail.freightTerms)}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </Container>
                                                    </Col>
                                                </Row>                                     
                                            </Container>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pl-0 pr-0 mb-4">
                                        <div className="info-panel explanatory">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>Payments</label>
                                                </div>
                                            </div>
                                            <Container className="content account-container">
                                                <Row>
                                                    <Col className="text-left pl-3" xs={6} md={12} xl={6}>
                                                        <Table size="sm" borderless>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pl-0">Balance</td>
                                                                    <td>{this.addPlaceholder(this.formatCurrency(this.props.customerDetail.balance))}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                    <Col className="pl-3 pr-0 text-left" xs={6} md={12} xl={6}>
                                                        <Table size="sm" borderless>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pl-0">Consolidated</td>
                                                                    <td>{this.addPlaceholder(this.formatCurrency(this.props.customerDetail.consolidatedBalance))}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-left pl-3" xs={12}>
                                                        <Table size="sm" borderless>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pl-0">Overdue</td>
                                                                    <td>{this.addPlaceholder(this.formatCurrency(this.props.customerDetail.overdueBalance))}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    </Col>
                                </Row>
                                {this.props.clientState.client && this.props.clientState.client.settings.customerSettings.showCredit && (
                                    <Row>
                                        <Col className="pl-0 pr-0 mb-4">
                                            <div className="info-panel explanatory">
                                                <div className="title-bar">
                                                    <div className="heading">
                                                        <label>Credit</label>
                                                    </div>
                                                </div>
                                                <Container className="content account-container">
                                                    <Row>
                                                        <Col className="text-left pl-3" xs={6} md={12} xl={6}>
                                                            <Table size="sm" borderless>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="pl-0">Available</td>
                                                                        <td>{this.addPlaceholder(this.formatCurrency(this.props.customerDetail.creditAvailable))}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                        <Col className="pl-3 pr-0 text-left" xs={6} md={12} xl={6}>
                                                            <Table size="sm" borderless>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="pl-0">Limit</td>
                                                                        <td>{this.addPlaceholder(this.formatCurrency(this.props.customerDetail.creditLimit))}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="text-left pl-3" xs={12}>
                                                            <Table size="sm" borderless>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="pl-0">Open A/R</td>
                                                                        <td>{this.addPlaceholder(this.formatCurrency(this.props.customerDetail.openAccountsReceivable))}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col className="pl-0 pr-0 mb-4">
                                        <div className="info-panel explanatory">
                                            <div className="title-bar">
                                                <div className="heading">
                                                    <label>Sales</label>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <SalesSummary customerAccount={this.props.customerDetail} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                )}
                {this.state.viewingLogo && (
                    <TapeViewer isOpen={this.state.isViewingLogo} onDismiss={this.hideLogoViewer} tape={this.state.viewingLogo} readonly={true} />
                )}
                {this.props.customerDetail && this.props.clientState.client && (
                    <React.Fragment>
                        <AddLogo isOpen={this.state.isAddingNewLogo} clientCode={this.props.clientState.client.code} customer={this.props.customerDetail} onDismiss={this.hideAddNewLogo} />
                    </React.Fragment>
                )}
            </Container>
        );
    }

    // ----------------
    // HELPERS

    private addPlaceholder = (value: string | null | undefined): string => {
        return (value ? value : 'not available');
    }

    private billToSameAsShipTo = (): boolean => {
        let isSame: boolean = false;
        if (this.props.customerDetail && this.state.selectedShipTo) {
            let billto: Address = this.props.customerDetail.address;
            let shipto: Address = this.state.selectedShipTo.address;
            
            isSame = billto.street1 === shipto.street1;
            isSame = isSame && billto.street2 === shipto.street2;
            isSame = isSame && billto.street3 === shipto.street3;
            isSame = isSame && billto.city === shipto.city;
            isSame = isSame && billto.state.code === shipto.state.code;
            isSame = isSame && billto.country.code === shipto.country.code;
            isSame = isSame && billto.postalCode === shipto.postalCode;
        }
        return isSame;
    }

    private customerLogosRetrieved = (): boolean => {
        let retrieved: boolean = false;
        if (this.state.customerLogos && this.state.customerLogos.length > 0) {
            retrieved = true;
        }
        else if (this.state.noCustomerLogos === true) {
            retrieved = true;
        }
        return retrieved;
    }

    private formatCurrency = (value: number | null | undefined): string => {
        let includeDecimals: boolean = this.hasFractionalDigits(value);
        return formatNumberAsCurrencyForCountry('US', value, includeDecimals);
    }

    private formatDiscount = (value: number): string => {
        let discount: string = "none";
        if (value > 0) {
            discount = value + "%";            
        }
        return discount;
    }

    private formatFreightTerms = (value: FreightTerms): string => {
        let freightTerms = value.name || value.code;
        return this.addPlaceholder(freightTerms);
    }

    private formatShipVia = (value: ShipVia): string => {
        let shipVia = value.name || value.code;
        return this.addPlaceholder(shipVia);
    }

    private formatTerms = (value: Terms): string => {
        let terms = value.name || value.code;
        return this.addPlaceholder(terms);
    }

    private getCustomerLogos = (query: Query<Logo> | undefined): void => {
        if (this.props.customerDetail && this.props.clientState.client) {
            this.setState({
                isLoadingLogos: true,
                isLogoLoadError: false,
                logoLoadError: null
            });
            let q: Query<Logo> = query || this.state.customerLogoQuery || this.getDefaultCustomerLogoQuery();
            this.props.asyncActions.requestCustomerLogosAsync(this.props.clientState.client.code, this.props.customerDetail.billto, this.props.customerDetail.shipToLocations[0].shipto || '', q, true)
                .then(result => {
                    if (result.totalCount > 0) {
                        q.totalCount = result.totalCount;
                        this.setState({
                            isLoadingLogos: false,
                            noCustomerLogos: false,
                            customerLogos: result.data,
                            customerLogoQuery: q
                        });
                    }
                    else {
                        this.setState({
                            isLoadingLogos: false,
                            noCustomerLogos: true,
                            customerLogos: undefined
                        })
                    }                    
                },
                err => {
                    this.setState({
                        isLogoLoadError: true,
                        logoLoadError: err,
                        isLoadingLogos: false
                    })
                }
            )
        }
    }

    private getDefaultCustomerLogoQuery = (): Query<Logo> => {
        return {
            filters: [],
            page: 0,
            pageSize: 4,
            totalCount: 0,
            search: "",
            orderBy: {},
            orderDirection: "asc"
        }
    }

    private getLogoPagination = (): string => {
        let pagination: string = "";
        if (this.state.customerLogoQuery && this.state.customerLogos) {
            let pageSize: number = this.state.customerLogoQuery.pageSize;
            let pageCount: number = Math.ceil(this.state.customerLogoQuery.totalCount / pageSize);
            let currentPage = this.state.customerLogoQuery.page;
            let start: number = (currentPage * pageSize) + 1;
            let end: number = (currentPage * pageSize) + this.state.customerLogos.length;
            pagination = start + "-" + end + " of " + this.state.customerLogoQuery.totalCount;
        }
        return pagination;
    }

    private getNextLogos = (): void => {
        if (this.state.customerLogoQuery) {
            let pageSize: number = this.state.customerLogoQuery.pageSize;
            let pageCount: number = Math.ceil(this.state.customerLogoQuery.totalCount / pageSize);
            let nextPage: number = this.state.customerLogoQuery.page + 1;
            if (nextPage < pageCount) {
                let query: Query<Logo> = cloneDeep(this.state.customerLogoQuery);
                query.page = nextPage;
                this.getCustomerLogos(query);
            }
        }
    }

    private getPreviousLogos = (): void => {
        if (this.state.customerLogoQuery) {
            let pageSize: number = this.state.customerLogoQuery.pageSize;
            let pageCount: number = Math.ceil(this.state.customerLogoQuery.totalCount / pageSize);
            let prevPage: number = this.state.customerLogoQuery.page - 1;
            if (prevPage >= 0) {
                let query: Query<Logo> = cloneDeep(this.state.customerLogoQuery);
                query.page = prevPage;
                this.getCustomerLogos(query);
            }
        }
    }

    private getSelectedShipTo = (): CustomersStore.CustomerDetail | undefined => {
        let selectedShipTo: CustomersStore.CustomerDetail | undefined = (this.state && this.state.initialized) ? this.state.selectedShipTo : undefined;
        if (!selectedShipTo && this.props.customerDetail) {
            if (!this.props.customerDetail.selected) {
                let matches: CustomersStore.CustomerDetail[] = this.props.customerDetail.shipToLocations.filter((shipToLocation: CustomersStore.CustomerDetail) => {
                    return shipToLocation.selected;
                });
                if (matches.length > 0) {
                    selectedShipTo = matches[0];
                }
            }
            else if (this.props.customerDetail.shipToLocations.length === 1) {
                selectedShipTo = this.props.customerDetail.shipToLocations[0];
            }
        }
        return selectedShipTo;
    }

    private getShipToConfiguration = (): number => {
        let configuration: number = 0;
        if (this.props.customerDetail) {
            if (this.props.customerDetail.shipToLocations.length === 1) {
                configuration = 1;
            }
            else if (this.props.customerDetail.shipToLocations.length > 1) {
                configuration = 2;
            }
        }
        return configuration;
    }

    private hasFractionalDigits = (value: number | null | undefined): boolean => {
        let fractional: boolean = false;
        if (value && !isNaN(value)) {
            fractional = value.toString() != value.toFixed(0);
        }
        return fractional;
    }

    private hideAddNewLogo = (newLogo: NewLogo | null | undefined): void => {
        this.setState({
            isAddingNewLogo: false
        });
        if (newLogo) {
            setTimeout(() => {
                if (this.state.customerLogoQuery) {
                    let q: Query<Logo> = cloneDeep(this.state.customerLogoQuery);
                    let pageCount: number = Math.ceil(q.totalCount / q.pageSize);
                    q.page = pageCount - 1;
                    this.getCustomerLogos(q);
                }
                else {
                    this.getCustomerLogos(undefined);
                }
            }, 400);
        }
    }

    private hideLogoViewer = (selected: boolean): void => {
        this.setState({
            isViewingLogo: false,
            viewingLogo: undefined
        });
    }

    public isDifferentCustomer = (prevProps: CustomerFormOwnProps): boolean => {
        let isDifferent: boolean = false;
        if (this.props.customerDetail && !prevProps.customerDetail) {
            isDifferent = true;
        }
        else if (this.props.customerDetail && prevProps.customerDetail) {
            isDifferent = (this.props.customerDetail.number != prevProps.customerDetail.number);
        }
        return isDifferent;
    }

    private isFirstLogoPage = (): boolean => {
        let firstPage: boolean = false;
        if (this.state.customerLogoQuery) {
            firstPage = this.state.customerLogoQuery.page === 0 ? true : false;
        }
        return firstPage;
    }

    private isLastLogoPage = (): boolean => {
        let lastPage: boolean = false;
        if (this.state.customerLogoQuery) {
            let pageSize: number = this.state.customerLogoQuery.pageSize;
            let pageCount: number = Math.ceil(this.state.customerLogoQuery.totalCount / pageSize);
            lastPage = this.state.customerLogoQuery.page === (pageCount - 1) ? true : false;
        }
        return lastPage;
    }

    private onAddNewLogo = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        this.props.actions.clearMessage();
        this.setState({
            isAddingNewLogo: true
        });
    }

    private onReloadLogos = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        this.getCustomerLogos(undefined);
    }

    private onSelectShipTo = (shipTo: CustomersStore.CustomerDetail): void => {
        this.setState({
            selectedShipTo: shipTo
        });
    }

    private onViewLogo = (event: React.KeyboardEvent | React.MouseEvent, logo: Logo): void => {
        event.preventDefault();
        this.setState({
            isViewingLogo: true,
            viewingLogo: logo
        });
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        customersState: state.customers,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            CustomersStore.actionCreators,
            MessageStore.actionCreators),
            dispatch),
        asyncActions: {
            requestCustomerLogosAsync: (clientCode: ClientCode, billto: string, shipto: string, query: Query<any>, broadcastError: boolean) => dispatch(CustomersStore.actionCreators.requestCustomerLogos(clientCode, billto, shipto, query, broadcastError))
        }
    };
}

export default connect<{}, {}, CustomerFormOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(CustomerForm as any);
