﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as MessageStore from './Message';
import { Client } from './Client';
import * as ErrorMessage from '../common/ErrorMessage';
import { ShippingOptionType } from '../enums/ShippingOptionType';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ShippingOptionsState {
    isLoading: boolean;
    client: Client | null | undefined;
    lastUpdated: Date | null | undefined;
    shippingOptions: ShippingOptions | null | undefined;
}

export interface ShippingOption {
    code: string;
    description: string;
    dropshipRequired: boolean;
    isDefault: boolean;
    optionType: ShippingOptionType;
}

export interface ShippingOptions {
    expiration: number | null | undefined;
    customOptions: ShippingOption[];
    freightOptions: ShippingOption[];
    shipViaOptions: ShippingOption[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestShippingOptionsAction {
    client: Client;
    type: 'REQUEST_SHIPPING_OPTIONS';
}

interface ReceiveShippingOptionsAction {
    type: 'RECEIVE_SHIPPING_OPTIONS';
    client: Client;
    shippingOptions: ShippingOptions | null | undefined;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = RequestShippingOptionsAction | ReceiveShippingOptionsAction | MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const isExpired = (state: ShippingOptionsState): boolean => {
    let expired: boolean = true;
    if (state.lastUpdated && state.shippingOptions && state.shippingOptions.expiration) {
        let elapsed = Math.abs(new Date().getTime() - state.lastUpdated.getTime()) / 1000;
        elapsed = Math.round(elapsed / 60);
        expired = (elapsed >= state.shippingOptions.expiration);
    }
    return expired;
}

export const actionCreators = {
    requestShippingOptions: (client: Client): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.shippingOptions && !appState.shippingOptions.isLoading && isExpired(appState.shippingOptions)) {

            let fetchError: boolean = false;
            let action: string = 'shippingoptions/get';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(client)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'RECEIVE_SHIPPING_OPTIONS', client: client, shippingOptions: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error;
                    }
                    return response.json();
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_SHIPPING_OPTIONS', client: client, shippingOptions: data as ShippingOptions });
                },
                err => {
                    if (!fetchError) {
                        dispatch({ type: 'RECEIVE_SHIPPING_OPTIONS', client: client, shippingOptions: null });
                        dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                    }
                });

            dispatch({ type: 'REQUEST_SHIPPING_OPTIONS', client: client });
        }
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: ShippingOptionsState = { isLoading: false, client: null, shippingOptions: null, lastUpdated: null };

export const reducer: Reducer<ShippingOptionsState> = (state: ShippingOptionsState | undefined, incomingAction: Action): ShippingOptionsState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SHIPPING_OPTIONS':
            return {
                client: state.client,
                shippingOptions: state.shippingOptions,
                lastUpdated: state.lastUpdated,
                isLoading: true
            };
        case 'RECEIVE_SHIPPING_OPTIONS':
            return {
                client: action.client,
                shippingOptions: action.shippingOptions,
                lastUpdated: new Date(),
                isLoading: false
            };
        default:
            return state;
    }
}