import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { motion } from 'framer-motion';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import * as OrderItemsStore from '../../store/OrderItems';
import * as ErrorMessage from '../../common/ErrorMessage';
import { ClientCode } from '../../enums/ClientCode';
import { ColorDirection, EmbroiderySpecification, Logo, PlacementOption } from '../../common/EmbroideryTypes';
import { EmbroideryTapeType } from '../../enums/EmbroideryTapeType';
import { Button } from 'reactstrap';
import ImageLoader from '../image-loader/ImageLoader';
import TapeViewer from '../tape-viewer/TapeViewer';
import { formatNumberAsCurrencyForCountry } from '../../common/CurrencyFormatter';
import cloneDeep from 'lodash/cloneDeep';
import { toTitleCase } from '../../common/StringFormatter';

import './OrderItemEmbroidery.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
}

interface OrderItemEmbroideryAsyncActions {
    asyncActions: {
        updateOrderItemEmbroideriesAsync: (clientCode: ClientCode, orderId: number, orderItem: OrderItemsStore.OrderItem, broadcastError: boolean) =>
            Promise<OrderItemsStore.OrderItem | null | undefined>;
    }
}

interface OrderItemEmbroideryOwnProps {
    orderItem: OrderItemsStore.OrderItem;
    embroiderySpecifications: EmbroiderySpecification[];
    onEditEmbroidery: (index: number) => void;
    onRemoveEmbroidery: (index: number) => void;
}

type OrderItemEmbroideryProps =
    OrderItemEmbroideryOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & OrderItemEmbroideryAsyncActions;

// ----------------
// LOCAL STATE

interface OrderItemEmbroideryState {
    deleting: number | undefined;
    isViewingLogo: boolean;
    viewingLogo: Logo | null | undefined;
}

class OrderItemEmbroidery extends React.PureComponent<OrderItemEmbroideryProps, OrderItemEmbroideryState> {

    // ----------------
    // VARIABLES

    public overlayVariants = {
        hidden: { opacity: 0, zIndex: '-1' },
        visible: { opacity: 1, zIndex: 103 }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderItemEmbroideryProps, state: OrderItemEmbroideryState) {
        super(props);
        this.state = {
            deleting: undefined,
            isViewingLogo: false,
            viewingLogo: undefined
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: OrderItemEmbroideryProps) => {
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <div className={"order-item-embroidery" + (this.isSingleEmbroidery() ? "" : " multiple")}>
                <div className="embroidery-label">
                    <label>Embroidery</label>
                </div>
                <div className="embroidery-specifications">
                    {this.props.embroiderySpecifications.map((specification: EmbroiderySpecification, specificationIndex: number) => (
                        <div className="embroidery-specification" key={"specification" + specificationIndex}>
                            {this.props.orderItem.isEditable && (
                                <div className="embroidery-specification-header">
                                    <Button color="link" href={"#"} onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.onEditEmbroidery(e, specificationIndex)}>
                                        Edit
                                    </Button>
                                    <span className="divider">|</span>
                                    <Button color="link" href={"#"} onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.onRemoveEmbroidery(e, specificationIndex)}>
                                        Remove
                                    </Button>
                                </div>
                            )}
                            <div className="embroidery-specification-body">
                                <a className="embroidery-image" href={"#"} onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.onViewLogo(e, specification.logo)}>
                                    <div className="logo-image">
                                        <div className={"image-stretcher" + (specification.logo && specification.logo.tapeType === EmbroideryTapeType.New ? " new" : "")}>
                                            <div className="image-canvas">
                                                {specification.logo && (
                                                    <ImageLoader url={specification.logo.logoUrl} isLoading={false} autoSize={true} noFadeIn={false} thumbnail={true} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div className="embroidery-description">
                                    {specification.logo && (
                                        <label>{this.clipLabel(specification.logo.tapeNumber)}</label>
                                    )}
                                    {specification.placement && (
                                        <label>{this.clipLabel(specification.placement.description)}</label>
                                    )}
                                    {specification.colorDirection && (
                                        <label>{this.clipLabel(toTitleCase(specification.colorDirection.description))}</label>
                                    )}
                                    {specification.logo && (
                                        <label>{this.getEmbroideryFee(specification.logo)}</label>
                                    )}
                                </div>
                            </div>
                            {specification.notes && (
                                <div className="embroidery-specification-footer">
                                    {specification.notes}
                                </div>
                            )}
                            <motion.div className="embroidery-specification-overlay" animate={this.state.deleting === specificationIndex ? "visible" : "hidden"}
                                initial={"hidden"} variants={this.overlayVariants} transition={{ duration: 0.25 }}>
                                <label>Deleting...</label>
                            </motion.div>
                        </div>
                    ))}
                </div>
                {this.state.viewingLogo && (
                    <TapeViewer isOpen={this.state.isViewingLogo} onDismiss={this.hideLogoViewer} tape={this.state.viewingLogo} readonly={true} />
                )}
            </div>
        );
    }

    // ----------------
    // HELPERS 

    private clipLabel = (value: string): string => {
        let clippedValue: string = value;
        let truncateAt: number = clippedValue.indexOf(" (");
        if (truncateAt > 0) {
            clippedValue = clippedValue.substring(0, truncateAt);
        }
        if (clippedValue.length > 30) {
            clippedValue = clippedValue.substring(0, 27) + '...';
        }
        return clippedValue;
    }

    private getEmbroideryFee = (logo: Logo): string => {
        let fee: string = 'no additional fee';
        if (logo.price && logo.price > 0) {
            fee = formatNumberAsCurrencyForCountry('US', logo.price, true);
        }
        return fee;
    }

    private hasMaxEmbroideries = (): boolean => {
        return this.props.embroiderySpecifications.length === 3;
    }

    private hideLogoViewer = (selected: boolean): void => {
        this.setState({
            isViewingLogo: false,
            viewingLogo: undefined
        });
    }

    private isSingleEmbroidery = (): boolean => {
        return this.props.embroiderySpecifications.length == 1;
    }

    private onEditEmbroidery = (event: React.KeyboardEvent | React.MouseEvent, embroideryIndex: number): void => {
        event.preventDefault();
        this.props.onEditEmbroidery(embroideryIndex);
    }

    private onRemoveEmbroidery = (event: React.KeyboardEvent | React.MouseEvent, embroideryIndex: number): void => {
        event.preventDefault();
        this.props.actions.clearMessage();
        this.setState({
            deleting: embroideryIndex
        })

        let orderItem: OrderItemsStore.OrderItem = cloneDeep(this.props.orderItem);
        orderItem.embroiderySpecifications.splice(embroideryIndex, 1);

        this.props.asyncActions.updateOrderItemEmbroideriesAsync(orderItem.clientCode, orderItem.orderId, orderItem, true)
            .then(result => {
                this.props.onRemoveEmbroidery(embroideryIndex);
                this.setState({
                    deleting: undefined
                });
            },
            err => {
                this.setState({
                    deleting: undefined
                });
            }
        )
    }

    private onViewLogo = (event: React.MouseEvent | React.KeyboardEvent, logo: Logo | null): void => {
        event.preventDefault();
        if (logo) {
            this.setState({
                isViewingLogo: true,
                viewingLogo: logo
            });
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        ordersState: state.orders
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators), dispatch),
        asyncActions: {
            updateOrderItemEmbroideriesAsync: (clientCode: ClientCode, orderId: number, orderItem: OrderItemsStore.OrderItem, broadcastError: boolean) =>
                dispatch(OrderItemsStore.actionCreators.updateOrderItemEmbroideries(clientCode, orderId, orderItem, broadcastError))
        }
    };
}

export default connect<{}, {}, OrderItemEmbroideryOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(OrderItemEmbroidery as any);
