import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as CustomersStore from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import * as UserStore from '../../store/User';
import { ClientCode } from '../../enums/ClientCode';
import { User } from '../../store/User';
import { Role } from '../../enums/Role';
import { CustomerName } from '../../common/AccountTypes';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import AddressForm from '../address-form/AddressForm';
import CustomerFinder from '../customer-finder/CustomerFinder';
import FormResult from '../form-result/FormResult';
import Loader from '../loader/Loader';
import $ from 'jquery';

import './OrderCustomer.scss';

// ----------------
// PROPS

const applicationState = {
    customersState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState,
    userState: {} as UserStore.UserState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, UserStore.actionCreators)
};

interface OrderCustomerAsyncActions {
    asyncActions: {
        requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => Promise<CustomersStore.CustomerAccount | null | undefined>
    }
}

interface OrderCustomerOwnProps {
    isOpen: boolean;
    isSaving?: boolean;
    isSaved?: boolean;
    onDismiss: (account: CustomersStore.CustomerAccount | null | undefined) => void;
    clientCode: ClientCode;
}

type OrderCustomerProps =
    OrderCustomerOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & OrderCustomerAsyncActions;

// ----------------
// LOCAL STATE

interface OrderCustomerState {
    initialized: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
    retrievingAccount: boolean;
    selectedAccount: CustomersStore.CustomerAccount | undefined;
    selectedCustomer: CustomersStore.CustomerDetail | undefined;
    buyerAccounts: CustomerName[];
}

// ----------------
// FORM VALIDATOR

const validateOrderCustomerForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};

    return errors;
}

class OrderCustomer extends React.PureComponent<OrderCustomerProps, OrderCustomerState> {

    // ----------------
    // VARIABLES

    public orderCustomerFormVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderCustomerProps, state: OrderCustomerState) {
        super(props);
        this.state = {
            initialized: this.userHasAccounts() ? true : false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            retrievingAccount: false,
            selectedAccount: undefined,
            selectedCustomer: undefined,
            buyerAccounts: this.props.userState.user ? this.props.userState.user.buyerAccounts : [],
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.ensureDataFetched();
    }

    public componentDidUpdate = (prevProps: OrderCustomerProps) => {
        if (this.props.isSaved && prevProps.isSaving) {
            this.setState({
                changesAccepted: true,
                formResult: 'Changes saved'
            });
        }
    }

    public componentWillUnmount = () => {}

    public handleFormSubmit = (values: any): void => {
        if (this.state.selectedAccount) {
            this.props.onDismiss(this.state.selectedAccount);
        }
        else {
            this.setState({
                changesRejected: true,
                formResult: "Please select an account"
            });
        }
    }

    public render = () => {
        return (
            <Modal id="order-customer" isOpen={this.props.isOpen}
                onOpened={this.initializeForm.bind(this)} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(undefined)} className={this.state.retrievingAccount || this.props.isSaving || this.props.isSaved ? "disabled" : ""}>
                    Select Customer
                </ModalHeader>
                <ModalBody>
                    <React.Fragment>
                        <Loader isLoading={!this.state.initialized} isChild={true} />
                        <motion.div id="order-customer-form" animate={this.state.initialized? "visible" : "Hidden"} initial={this.state.initialized ? "visbile" : "hidden"}
                            variants={this.orderCustomerFormVariants} transition={{duration: 0.75}}>
                            <Container>
                                <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                    <Row>                                
                                        {this.props.userState.user && this.props.userState.user.role != Role.Buyer && (
                                            <Col className="pl-0 pr-0">
                                                <Field name="account" label="" component={FieldWrapper.renderCustomerFinderField}
                                                    disabled={this.state.retrievingAccount || this.props.isSaving || this.props.isSaved}
                                                    onSelectCustomer={this.onSelectAccount} />
                                            </Col>
                                        )}
                                        {this.props.userState.user && this.userIsMultipleAccountBuyer() && (
                                            <Col className="pl-0 pr-0">
                                                <Field name="account" label="" component={FieldWrapper.renderBuyerAccountListField}
                                                    disabled={this.state.retrievingAccount || this.props.isSaving || this.props.isSaved}
                                                    options={this.state.buyerAccounts}  onSelectBuyerAccount={this.onSelectAccount} />
                                            </Col>
                                        )}                                                           
                                    </Row>
                                    <Row>
                                        <Col className="pl-0 pr-0 selected-billto-preview">
                                            <AnimatePresence>
                                                {this.state.selectedCustomer && (
                                                    <motion.div initial={{ height: 0, overflow: 'hidden' }} animate={{ height: 'auto' }} transition={{ duration: 0.25 }}>
                                                        <AddressForm customerDetail={this.state.selectedCustomer} readonly={true} showLabels={true}
                                                            showPlaceholder={false} noMargin={false} />
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </Col>
                                    </Row>
                                    <button type="submit" ref={this.submitButton}>Submit</button>
                                </form>
                                {this.state.retrievingAccount && (
                                    <div className="retrieval-message">Retrieving account...</div>
                                )}
                            </Container>
                        </motion.div>
                    </React.Fragment>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0 pr-sm-3">
                                <Button type="button" color="primary" onClick={this.saveSelection} disabled={this.state.retrievingAccount || this.props.isSaving || this.props.isSaved}>
                                    {this.props.isSaving ? "Working" : "OK"}
                                </Button>
                                <Button color="link" onClick={() => this.props.onDismiss(null)} disabled={this.state.retrievingAccount || this.props.isSaving || this.props.isSaved}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0 pr-sm-3">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private ensureDataFetched = (): void => {
        if (this.props.userState.user) {
            if (!this.userHasAccounts()) {
                this.props.actions.requestBuyerAccounts(this.props.userState.user);
                setTimeout(() => {
                    this.ensureDataFetched();
                }, 400);
            }
            else if (!this.state.initialized) {
                this.setState({
                    initialized: true,
                    buyerAccounts: this.props.userState.user ? this.props.userState.user.buyerAccounts : []
                })
            }
        }
        else {
            setTimeout(() => {
                this.ensureDataFetched();
            }, 400);
        }
    }

    private getSelectedCustomerDetail = (account: CustomersStore.CustomerAccount): CustomersStore.CustomerDetail => {
        let matches: CustomersStore.CustomerDetail[] = account.shipToLocations.filter((shipToLocation: CustomersStore.CustomerDetail) => {
            return shipToLocation.selected;
        });
        return matches.length > 0 ? matches[0] : account as CustomersStore.CustomerDetail;
    }

    private initializeForm = (): void => {
        this.setState({
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            selectedAccount: undefined
        });
        this.props.change("account", null);
        $(".rbt-input-main").focus();
    }

    private onSelectAccount = (selectedCustomer: CustomerName | null): void => {
        if (selectedCustomer) {
            this.retrieveAccount(selectedCustomer);
        }
        else {
            this.setState({
                selectedAccount: undefined,
                selectedCustomer: undefined
            });
        }
    }

    private retrieveAccount = (selectedCustomer: CustomerName): void => {
        this.setState({
            changesRejected: false,
            retrievingAccount: true,
            formResult: null
        });
        this.props.asyncActions.requestCustomerDetailAsync(this.props.clientCode, selectedCustomer.billto, selectedCustomer.shipto, true)
            .then(result => {
                if (result) {
                    this.setState({
                        retrievingAccount: false,
                        selectedAccount: result,
                        selectedCustomer: this.getSelectedCustomerDetail(result)
                    });
                }
                else {
                    this.setState({
                        retrievingAccount: false
                    });
                    this.props.actions.broadcastMessage({
                        messageType: MessageStore.MessageType.ERROR,
                        text: 'Account not found',
                        action: 'requestCustomerDetail',
                        reference: null
                    });
                }
            },
            err => {
                this.setState({
                    retrievingAccount: false
                })
            }
        )
    }

    private resetForm = (): void => {
        this.setState({
            selectedAccount: undefined,
            selectedCustomer: undefined
        })
        this.props.change("account", null);
    }

    private saveSelection = (): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }

    private userHasAccounts = (): boolean => {
        let hasAccounts: boolean = false;
        if (this.props.userState.user) {
            if (this.props.userState.user.role == Role.Buyer) {
                hasAccounts = this.props.userState.user.buyerAccounts.length > 0;
            }
            else {
                hasAccounts = true;
            }
        }
        return hasAccounts;
    }

    private userIsBuyer = (): boolean => {
        let isBuyer: boolean = this.props.userState.user && this.props.userState.user.role === Role.Buyer ? true : false;
        return isBuyer;
    }

    private userIsMultipleAccountBuyer = (): boolean => {
        let isMultipleAccountBuyer: boolean = this.userIsBuyer();
        if (isMultipleAccountBuyer && this.props.userState.user) {
            isMultipleAccountBuyer = this.props.userState.user.buyerAccounts.length > 1;
        }
        return isMultipleAccountBuyer;
    }

    private userIsSingleAccountBuyer = (): boolean => {
        let isSingleAccountBuyer: boolean = this.userIsBuyer();
        if (isSingleAccountBuyer && this.props.userState.user) {
            isSingleAccountBuyer = this.props.userState.user.buyerAccounts.length === 1;
        }
        return isSingleAccountBuyer;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        customersState: state.customers,
        messageState: state.message,
        userState: state.user,
        initialValues: {}
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            UserStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => dispatch(CustomersStore.actionCreators.requestCustomerDetail(clientCode, billto, shipto, updateState))
        }
    };
}

export default connect<{}, {}, OrderCustomerOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'OrderCustomerForm',
    validate: validateOrderCustomerForm,
    enableReinitialize: true
})(OrderCustomer as any));
