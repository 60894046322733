﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ClientStore from './Client';
import * as CustomersStore from './Customers';
import * as MessageStore from './Message';
import * as ErrorMessage from '../common/ErrorMessage';
import { ClientCode } from '../enums/ClientCode';
import { CustomerAccount } from './Customers';
import { CustomerName } from '../common/AccountTypes';
import { ProductlistType } from '../enums/ProductlistType';
import { Query, QueryResult } from 'material-table';
import { convertSQLDateToJSDate } from '../common/DateConverter';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ProductlistsState {
    productlists: Productlists | null;
    productlistDetail: ProductlistDetail | null | undefined;
    newProductlist: BaseProductlist | null | undefined;
    clientCode: ClientCode;
    isLoading: boolean;
    isLoadingDetail: boolean;
    isAdding: boolean;
    isDeleting: boolean;
    isUpdating: boolean;
    isPublishing: boolean;
}

export interface BaseProductlist {
    id: number | null | undefined;
    accountRequired: boolean;
    billto: string | null | undefined;
    client: ClientStore.Client;
    createdBy: string;
    customerAccount: CustomerAccount | null | undefined;
    customerNumber: string | null | undefined;
    dateCreated: Date;
    name: string;
    listType: ProductlistType;
    shipto: string | null | undefined;
}

export interface Productlist extends BaseProductlist {
    customerName: string;
    customerNameOnly: string;
    customerNumber: string;
    dateLastModified: Date,
    isEditable: boolean;
    userId: number;
}

export interface ProductlistDetail extends Productlist {
    assignedEventLists: AssignedEventList[];
    eventListId: string | null | undefined;
}

export interface AssignedEventList {
    billto: string;
    customerNumber: string;
    documentId: string;
    shipto: string | null | undefined;
}

export interface ProductlistHistoryEntry {
    date: string,
    action: string,
    userName: string
}

export interface Productlists extends QueryResult<Productlist> {
    query: Query<any>;
}

export interface ProductlistCopy {
    copyQuantities: boolean;
    copyEmbroideries: boolean;
    customer: CustomerName | null | undefined;
    productlist: BaseProductlist;
    id?: number | null | undefined;
    itemsUnavailable: boolean;
    name: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface AddProductlistAction {
    type: 'ADD_PRODUCTLIST';
    clientCode: ClientCode;
    newProductlist: BaseProductlist;
}

interface AddProductlistCompleteAction {
    type: 'ADD_PRODUCTLIST_COMPLETE';
    clientCode: ClientCode;
    newProductlist: BaseProductlist | null;
}

interface CloseProductlistAction {
    type: 'CLOSE_PRODUCTLIST';
}

interface CopyProductlistAction {
    type: 'COPY_PRODUCTLIST';
    clientCode: ClientCode;
    productlistToCopy: ProductlistCopy;
}

interface CopyProductlistCompleteAction {
    type: 'COPY_PRODUCTLIST_COMPLETE';
    clientCode: ClientCode;
    productlistToCopy: ProductlistCopy;
}

interface DeleteProductlistAction {
    type: 'DELETE_PRODUCTLIST';
    clientCode: ClientCode;
    productlistToDelete: Productlist;
}

interface DeleteProductListCompleteAction {
    type: 'DELETE_PRODUCTLIST_COMPLETE';
    clientCode: ClientCode;
    productlistToDelete: Productlist | null;
}

interface RequestProductlistsAction {
    type: 'REQUEST_PRODUCTLISTS';
    clientCode: ClientCode;
    query: Query<any>;
}

interface ReceiveProductlistsAction {
    type: 'RECEIVE_PRODUCTLISTS';
    clientCode: ClientCode;
    productlists: Productlists | null;
}

interface RequestProductlistDetailAction {
    type: 'REQUEST_PRODUCTLIST_DETAIL';
    clientCode: ClientCode;
    id: number;
}

interface ReceiveProductlistDetailAction {
    type: 'RECEIVE_PRODUCTLIST_DETAIL';
    clientCode: ClientCode;
    productlistDetail: ProductlistDetail | null | undefined;
}

interface SaveProductlistDetailAction {
    type: 'SAVE_PRODUCTLIST_DETAIL';
    clientCode: ClientCode;
    productlistDetail: ProductlistDetail;
}

interface SaveProductlistDetailCompleteAction {
    type: 'SAVE_PRODUCTLIST_DETAIL_COMPLETE';
    clientCode: ClientCode;
    productlistDetail: ProductlistDetail | null | undefined;
}

interface PublishProductlistToEventAction {
    type: 'PUBLISH_PRODUCTLIST_TO_EVENT';
    clientCode: ClientCode;
    productlistDetail: ProductlistDetail;
}

interface PublishProductlistCompleteAction {
    type: 'PUBLISH_PRODUCTLIST_COMPLETE';
    clientCode: ClientCode;
    productlistDetail: ProductlistDetail | null | undefined;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = AddProductlistAction | AddProductlistCompleteAction |
    CopyProductlistAction | CopyProductlistCompleteAction |
    DeleteProductlistAction | DeleteProductListCompleteAction | CloseProductlistAction |
    RequestProductlistsAction | ReceiveProductlistsAction |
    RequestProductlistDetailAction | ReceiveProductlistDetailAction |
    SaveProductlistDetailAction | SaveProductlistDetailCompleteAction |
    PublishProductlistToEventAction | PublishProductlistCompleteAction |
    CustomersStore.ReceiveCustomerDetailAction |
    MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const formatProductlistDate = (productlist: Productlist): Productlist => {
    if (productlist.dateCreated) {
        productlist.dateCreated = convertSQLDateToJSDate(productlist.dateCreated);
    }
    if (productlist.dateLastModified) {
        productlist.dateLastModified = convertSQLDateToJSDate(productlist.dateLastModified);
    }
    return productlist;
}

export const formatProductlistDetailDate = (productlistDetail: ProductlistDetail): ProductlistDetail => {
    if (productlistDetail.dateCreated) {
        productlistDetail.dateCreated = convertSQLDateToJSDate(productlistDetail.dateCreated);
    }
    if (productlistDetail.dateLastModified) {
        productlistDetail.dateLastModified = convertSQLDateToJSDate(productlistDetail.dateLastModified);
    }
    return productlistDetail;
}

export const actionCreators = {
    requestProductlists: (clientCode: ClientCode, query: Query<Productlist>): AppThunkAction<KnownAction> => (dispatch, getState): Promise<QueryResult<Productlist>> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.productlists && !appState.productlists.isLoading) {

                let clientAwareQuery: object = Object.assign({}, query, { clientCode: clientCode });
                let fetchError: boolean = false;
                let action: string = 'productlists/get';
                let url: string = `${action}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(clientAwareQuery)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_PRODUCTLISTS', clientCode: clientCode, productlists: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                        let productlists: Productlists = data as Productlists;
                        dispatch({ type: 'RECEIVE_PRODUCTLISTS', clientCode: clientCode, productlists: productlists });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'RECEIVE_PRODUCTLISTS', clientCode: clientCode, productlists: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

                dispatch({ type: 'REQUEST_PRODUCTLISTS', clientCode: clientCode, query: query });
            }
            else {
                resolve(appState.productlists ? appState.productlists.productlists as QueryResult<Productlist> :
                    { data: [], query: query, page: query.page, totalCount: 0 } as QueryResult<Productlist>);
            }
        });
    },
    requestProductlistDetail: (client: ClientStore.Client, id: number): AppThunkAction<KnownAction> => (dispatch, getState): Promise<ProductlistDetail | null | undefined> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.productlists && !appState.productlists.isLoadingDetail) {

                let fetchError: boolean = false;
                let action: string = 'productlists/getdetail';
                let url: string = `${action}/${id.toString()}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(client)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_PRODUCTLIST_DETAIL', clientCode: client.code, productlistDetail: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let productlistDetail: ProductlistDetail = data as ProductlistDetail;
                        productlistDetail = formatProductlistDetailDate(productlistDetail);
                        resolve(productlistDetail);
                        dispatch({ type: 'RECEIVE_PRODUCTLIST_DETAIL', clientCode: client.code, productlistDetail: productlistDetail });
                        dispatch({ type: 'RECEIVE_CUSTOMER_DETAIL', clientCode: client.code, customerDetail: null });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'RECEIVE_PRODUCTLIST_DETAIL', clientCode: client.code, productlistDetail: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    })

                dispatch({ type: 'REQUEST_PRODUCTLIST_DETAIL', clientCode: client.code, id: id });
            }
            else {
                resolve(appState.productlists ? appState.productlists.productlistDetail : undefined);
            }
        });
    },
    addProductlist: (client: ClientStore.Client, customer: CustomerAccount | null | undefined, newProductlist: BaseProductlist): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.productlists && !appState.productlists.isAdding) {

            newProductlist.client = client;
            newProductlist.customerAccount = customer;
            let fetchError: boolean = false;
            let action: string = 'productlists/add';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(newProductlist)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'ADD_PRODUCTLIST_COMPLETE', clientCode: client.code, newProductlist: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    newProductlist = data as BaseProductlist;
                    dispatch({ type: 'ADD_PRODUCTLIST_COMPLETE', clientCode: client.code, newProductlist: newProductlist });
                    let actionComplete: MessageStore.Message = {
                        messageType: MessageStore.MessageType.ACTIONCOMPLETE,
                        action: action,
                        reference: null,
                        text: 'Add complete'
                    };
                    dispatch({ type: 'BROADCAST_MESSAGE', message: actionComplete });
                },
                err => {
                    if (!fetchError) {
                        dispatch({ type: 'ADD_PRODUCTLIST_COMPLETE', clientCode: client.code, newProductlist: null })
                        dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                    }
                });

            dispatch({ type: 'ADD_PRODUCTLIST', clientCode: client.code, newProductlist: newProductlist });
        }
    },
    deleteProductlist: (client: ClientStore.Client, productlistToDelete: Productlist): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.productlists && !appState.productlists.isDeleting) {

            productlistToDelete.client = client;
            let fetchError: boolean = false;
            let action: string = 'productlists/remove';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'DELETE',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(productlistToDelete)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'DELETE_PRODUCTLIST_COMPLETE', clientCode: client.code, productlistToDelete: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    productlistToDelete = data as Productlist;
                    dispatch({ type: 'DELETE_PRODUCTLIST_COMPLETE', clientCode: client.code, productlistToDelete: productlistToDelete });
                    let actionComplete: MessageStore.Message = {
                        messageType: MessageStore.MessageType.ACTIONCOMPLETE,
                        action: action,
                        reference: null,
                        text: 'Delete complete'
                    };
                    dispatch({ type: 'BROADCAST_MESSAGE', message: actionComplete });
                },
                err => {
                    if (!fetchError) {
                        dispatch({ type: 'DELETE_PRODUCTLIST_COMPLETE', clientCode: client.code, productlistToDelete: null });
                        dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                    }
                });

            dispatch({ type: 'DELETE_PRODUCTLIST', clientCode: client.code, productlistToDelete: productlistToDelete });
        }
    },
    saveProductlistDetail: (client: ClientStore.Client, productlistDetail: ProductlistDetail): AppThunkAction<KnownAction> => (dispatch, getState): Promise<ProductlistDetail | null | undefined> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.productlists && !appState.productlists.isUpdating) {

                let fetchError: boolean = false;
                let action: string = 'productlists/savedetail';
                let url: string = `${action}/${(productlistDetail.id as number).toString()}`;
                productlistDetail.client = client;

                fetch(url,
                    {
                        method: 'PUT',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(productlistDetail)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'SAVE_PRODUCTLIST_DETAIL_COMPLETE', clientCode: client.code, productlistDetail: productlistDetail });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let updatedProductlistDetail: ProductlistDetail = data as ProductlistDetail;
                        resolve(updatedProductlistDetail);
                        dispatch({ type: 'SAVE_PRODUCTLIST_DETAIL_COMPLETE', clientCode: client.code, productlistDetail: updatedProductlistDetail });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'SAVE_PRODUCTLIST_DETAIL_COMPLETE', clientCode: client.code, productlistDetail: productlistDetail });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    })

                dispatch({ type: 'SAVE_PRODUCTLIST_DETAIL', clientCode: client.code, productlistDetail: productlistDetail });
            }
            else {
                resolve(appState.productlists ? appState.productlists.productlistDetail : productlistDetail);
            }
        })
    },
    closeProductlist: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.productlists && appState.productlists.productlistDetail) {
            dispatch({ type: 'CLOSE_PRODUCTLIST' });
        }
    },
    copyProductlist: (client: ClientStore.Client, productlistToCopy: ProductlistCopy): AppThunkAction<KnownAction> => (dispatch, getState): Promise<ProductlistCopy | null | undefined> => {
        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.productlists && !appState.productlists.isAdding) {

                let fetchError: boolean = false;
                let action: string = 'productlists/copy';
                let url: string = `${action}/${(productlistToCopy.productlist.id as number).toString()}`;

                // make argument client-aware
                productlistToCopy.productlist.client = client;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(productlistToCopy)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'COPY_PRODUCTLIST_COMPLETE', clientCode: client.code, productlistToCopy: productlistToCopy });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let updatedProductlistToCopy: ProductlistCopy = data as ProductlistCopy;
                        resolve(updatedProductlistToCopy);
                        dispatch({ type: 'COPY_PRODUCTLIST_COMPLETE', clientCode: client.code, productlistToCopy: updatedProductlistToCopy });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'COPY_PRODUCTLIST_COMPLETE', clientCode: client.code, productlistToCopy: productlistToCopy });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

                dispatch({ type: 'COPY_PRODUCTLIST', clientCode: client.code, productlistToCopy: productlistToCopy });
            }
            else {
                resolve(null);
            }
        })
    },
    publishProductlistToEvent: (client: ClientStore.Client, productlistToPublish: ProductlistDetail): AppThunkAction<KnownAction> => (dispatch, getState): Promise<ProductlistDetail | null | undefined> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.productlists && !appState.productlists.isPublishing) {

                let fetchError: boolean = false;
                let action: string = 'productlists/publishtoevent';
                let url: string = `${action}/${(productlistToPublish.id as number).toString()}`;
                productlistToPublish.client = client;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(productlistToPublish)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'PUBLISH_PRODUCTLIST_COMPLETE', clientCode: client.code, productlistDetail: productlistToPublish });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let publishedProductlist: ProductlistDetail = data as ProductlistDetail;
                        resolve(publishedProductlist);
                        dispatch({ type: 'PUBLISH_PRODUCTLIST_COMPLETE', clientCode: client.code, productlistDetail: publishedProductlist });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'PUBLISH_PRODUCTLIST_COMPLETE', clientCode: client.code, productlistDetail: productlistToPublish });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    })

                dispatch({ type: 'PUBLISH_PRODUCTLIST_TO_EVENT', clientCode: client.code, productlistDetail: productlistToPublish });
            }
            else {
                resolve(productlistToPublish);
            }
        })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: ProductlistsState = {
    productlists: null,
    productlistDetail: null,
    newProductlist: null,
    clientCode: ClientCode.Undefined,
    isLoading: false,
    isLoadingDetail: false,
    isAdding: false,
    isDeleting: false,
    isUpdating: false,
    isPublishing: false
};

export const reducer: Reducer<ProductlistsState> = (state: ProductlistsState | undefined, incomingAction: Action): ProductlistsState => {
    if (state == undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_PRODUCTLIST':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: state.productlistDetail,
                newProductlist: action.newProductlist,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isAdding: true,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating,
                isPublishing: false
            };
        case 'ADD_PRODUCTLIST_COMPLETE':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: state.productlistDetail,
                newProductlist: action.newProductlist,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isAdding: false,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating,
                isPublishing: false
            };
        case 'CLOSE_PRODUCTLIST':
            return {
                clientCode: state.clientCode,
                productlists: state.productlists,
                productlistDetail: null,
                newProductlist: state.newProductlist,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isAdding: false,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating,
                isPublishing: false
            };
        case 'COPY_PRODUCTLIST':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: state.productlistDetail,
                newProductlist: state.newProductlist,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isAdding: true,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating,
                isPublishing: state.isPublishing
            };
        case 'COPY_PRODUCTLIST_COMPLETE':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: state.productlistDetail,
                newProductlist: state.newProductlist,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isAdding: false,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating,
                isPublishing: state.isPublishing
            };
        case 'DELETE_PRODUCTLIST':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: state.productlistDetail,
                newProductlist: state.newProductlist,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isAdding: state.isAdding,
                isDeleting: true,
                isUpdating: state.isUpdating,
                isPublishing: false
            };
        case 'DELETE_PRODUCTLIST_COMPLETE':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: state.productlistDetail,
                newProductlist: state.newProductlist,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isAdding: state.isAdding,
                isDeleting: false,
                isUpdating: state.isUpdating,
                isPublishing: false
            };
        case 'REQUEST_PRODUCTLISTS':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: state.productlistDetail,
                newProductlist: state.newProductlist,
                isLoading: true,
                isLoadingDetail: state.isLoadingDetail,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating,
                isPublishing: false
            };
        case 'RECEIVE_PRODUCTLISTS':
            return {
                clientCode: action.clientCode,
                productlists: action.productlists,
                productlistDetail: state.productlistDetail,
                newProductlist: state.newProductlist,
                isLoading: false,
                isLoadingDetail: state.isLoadingDetail,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating,
                isPublishing: false
            };
        case 'REQUEST_PRODUCTLIST_DETAIL':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: state.productlistDetail,
                newProductlist: state.newProductlist,
                isLoading: false,
                isLoadingDetail: true,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating,
                isPublishing: false
            };
        case 'RECEIVE_PRODUCTLIST_DETAIL':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: action.productlistDetail,
                newProductlist: state.newProductlist,
                isLoading: false,
                isLoadingDetail: false,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating,
                isPublishing: false
            };
        case 'SAVE_PRODUCTLIST_DETAIL':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: state.productlistDetail,
                newProductlist: state.newProductlist,
                isLoading: false,
                isLoadingDetail: false,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: true,
                isPublishing: false
            };
        case 'SAVE_PRODUCTLIST_DETAIL_COMPLETE':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: action.productlistDetail,
                newProductlist: state.newProductlist,
                isLoading: false,
                isLoadingDetail: false,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: false,
                isPublishing: false
            };
        case 'PUBLISH_PRODUCTLIST_TO_EVENT':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: state.productlistDetail,
                newProductlist: state.newProductlist,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating,
                isPublishing: true
            };
        case 'PUBLISH_PRODUCTLIST_COMPLETE':
            return {
                clientCode: action.clientCode,
                productlists: state.productlists,
                productlistDetail: state.productlistDetail,
                newProductlist: state.newProductlist,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isUpdating: state.isUpdating,
                isPublishing: false
            };
        default:
            return state;
    }

}