﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as MessageStore from './Message';
import * as ErrorMessage from '../common/ErrorMessage';
import { ClientCode } from '../enums/ClientCode';
import { BaseImage } from '../common/BaseTypes';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ClientState {
    isLoading: boolean;
    hostName: string | null;
    client: Client | null;
}

export interface ClientTheme {
    brandPrimaryColor: string;
    brandInverseColor: string;
    brandSecondaryColor: string;
    brandTertiaryColor: string;
    brandLogoHeight: number;
    brandLogoMarginTop: number;
    brandTitleHeight: number;
    brandTitleMarginTop: number;
    brandLogoHeightSmall: number;
    brandLogoMarginTopSmall: number;
    brandTitleHeightSmall: number;
    brandTitleMarginTopSmall: number;
}

export interface ClientHero extends BaseImage {
    smallScreen: boolean;
}

export interface CustomerSettings {
    showClass: boolean;
    showCredit: boolean;
    showDiscount: boolean;
    showFreight: boolean;
    showShipVia: boolean;
}

export interface ProductSettings {
    defaultPerPage: number | null;
    maxPerPageEqualsAll: boolean;
    maxPerPage: number | null;
}

export interface ClientRules {
    customShippingSelectable: boolean;
    displayShipToNumber: boolean;
    dropShippingSelectable: boolean;
    freightTermsSelectable: boolean;
    leadDaysEmbroidered: number;
    leadDaysNonEmbroidered: number;
    maxLogosPerItem: number;
    minDaysBeforeCancelDate: number;
    numericCustomerId: boolean;
    poRequired: boolean;
    seasonRequired: boolean;
    shippingOptionsFlag: number;
    shipViaSelectable: boolean;
    useBusinessDays: boolean;
}

export interface ClientSettings {
    customerSettings: CustomerSettings;
    productSettings: ProductSettings;
}

export interface Client {
    code: ClientCode;
    name: string;
    siteTitle: string;
    theme: ClientTheme;
    heroes: ClientHero[];
    rules: ClientRules;
    settings: ClientSettings;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestClientAction {
    type: 'REQUEST_CLIENT';
    hostName: string;
}

interface ReceiveClientAction {
    type: 'RECEIVE_CLIENT';
    hostName: string;
    client: Client | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = RequestClientAction | ReceiveClientAction | MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestClient: (hostName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.client && hostName != appState.client.hostName && !appState.client.isLoading) {

            let fetchError: boolean = false;
            let action: string = 'client/get';
            let url: string = `${action}/${encodeURIComponent(hostName)}`;

            fetch(url,
                {
                    method: 'GET',
                    headers: { 'Accept': 'application/json' }
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'RECEIVE_CLIENT', hostName: hostName, client: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_CLIENT', hostName: hostName, client: data });
                },
                err => {
                    if (!fetchError) {
                        dispatch({ type: 'RECEIVE_CLIENT', hostName: hostName, client: null });
                        dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                    }
                });

            dispatch({ type: 'REQUEST_CLIENT', hostName: hostName });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: ClientState = { client: null, hostName: null, isLoading: false }

export const reducer: Reducer<ClientState> = (state: ClientState | undefined, incomingAction: Action): ClientState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CLIENT':
            return {
                hostName: action.hostName,
                client: state.client,
                isLoading: true
            };
        case 'RECEIVE_CLIENT':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.hostName === state.hostName) {
                return {
                    hostName: action.hostName,
                    client: action.client,
                    isLoading: false
                }
            }
            break;
        default:
            return state;
    }
    return state;
};