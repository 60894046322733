import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as CustomersStore from '../../store/Customers';
import * as CountriesStore from '../../store/Countries';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { Country, State } from '../../common/AddressTypes';
import { Filter, Query, QueryResult } from 'material-table';
import { Button, Container, Col, ListGroup, ListGroupItem, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import Loader from '../loader/Loader';
import SearchBox from '../search-box/SearchBox';
import debounce from 'lodash.debounce';
import $ from 'jquery';

import './FindCustomer.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    countriesState: {} as CountriesStore.CountriesState,
    customersState: {} as CustomersStore.CustomersState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, CountriesStore.actionCreators, CustomersStore.actionCreators)
};

interface FindCustomerAsyncActions {
    asyncActions: {
        requestCustomersAsync: (clientCode: ClientCode, query: Query<any>) => Promise<QueryResult<any>>;
    }
}

interface FindCustomerOwnProps {
    client: Client;
    isOpen: boolean;
    onDismiss: (billto: string | null | undefined, shipto: string | null | undefined) => void;
}

type FindCustomerProps =
    FindCustomerOwnProps
    & InjectedFormProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & FindCustomerAsyncActions;

// ----------------
// LOCAL STATE

interface FindCustomerState {
    initialized: boolean;
    submitting: boolean;
    selected: boolean;
    filtered: boolean;
    filterFlag: boolean;
    animationComplete: boolean;
    countryOptions: FieldWrapper.OptionValue[];
    countryFlag: boolean;
    selectedCountry: Country | null | undefined;
    stateOptions: FieldWrapper.OptionValue[];
    stateFlag: boolean;
    selectedState: State | null | undefined;
    selectedPage: number;   
    pageCount: number;
    clearSearch: boolean;
    activeQuery: Query<CustomersStore.Customer> | null | undefined;
    queryResults: CustomersStore.Customer[];
    selectedIndex: number | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateFindCustomerForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    return errors;
}

class FindCustomer extends React.PureComponent<FindCustomerProps, FindCustomerState> {

    // ----------------
    // VARIABLES

    public findCustomerFormVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', overflow: 'hidden' },
        visibleOverflow: { height: 'auto', overflow: 'visible' }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: FindCustomerProps, state: FindCustomerState) {
        super(props);
        this.state = {
            initialized: this.props.countriesState.countries ? true : false,
            submitting: false,
            selected: false,
            filtered: false,
            filterFlag: false,
            animationComplete: false,
            countryOptions: this.getCountryOptions(),
            countryFlag: false,
            selectedCountry: this.getDefaultCountry(),
            stateOptions: this.getStateOptions(this.getDefaultCountry()),
            stateFlag: false,
            selectedState: undefined,
            selectedPage: 0,
            pageCount: 0,
            clearSearch: false,
            activeQuery: undefined,
            queryResults: [],
            selectedIndex: undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        setTimeout(() => {
            this.ensureDataFetched();
        }, 800);
    }

    public componentDidUpdate = (prevProps: FindCustomerProps) => {
        if (this.props.countriesState.countries && !this.state.initialized) {
            this.setState({
                initialized: true,
                countryOptions: this.getCountryOptions(),
                selectedCountry: this.getDefaultCountry()
            });
        }
    }

    public componentWillUnmount = () => {
    }

    public handleFormSubmit = (values: any): void => {
        this.setState({
            submitting: true,
            selectedIndex: undefined
        });
        let query: Query<CustomersStore.Customer> = this.getQuery(values, true);
        this.getCustomers(query);
    }

    public render = () => {
        return (
            <Modal id="find-customer" isOpen={this.props.isOpen} onOpened={this.initializeForm} onClosed={() => this.resetForm(true)}>
                <ModalHeader toggle={() => this.cancelFind()} className={(this.state.submitting || this.state.selected) ? "disabled" : ""}>
                    Find Customer
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Loader isLoading={!this.state.initialized} isChild={true} />
                        <motion.div animate={this.state.initialized ? "visible" : "hidden"} initial={"hidden"}
                            variants={this.findCustomerFormVariants} transition={{duration: 0.75 }}>
                            <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                <Row className="search-bar">
                                    <Col className="search-input pl-0" xs={10}>
                                        <SearchBox autoFocus={true} onChange={this.onSearch} clearInput={this.state.clearSearch} />
                                    </Col>
                                    <Col className="search-actions pl-0 pr-0" xs={2}>
                                        <Button color="link" disabled={this.state.submitting} onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.toggleFilterPanel(e)}>
                                            <label>Filter</label>
                                            {this.state.filterFlag && (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-up">
                                                    <polyline points="18 15 12 9 6 15"></polyline>
                                                </svg>
                                            )}
                                            {!this.state.filterFlag && (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-down">
                                                    <polyline points="6 9 12 15 18 9"></polyline>
                                                </svg>
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="search-filters">
                                    <Col className="collapsible-row pl-0 pr-0">
                                        <motion.div animate={this.state.filterFlag ? this.state.animationComplete ? "visibleOverflow" : "visible" : "hidden"}
                                            variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }} onAnimationComplete={this.onSearchFiltersRowChange}>
                                            <Container>
                                                <Row>
                                                    <Col className="pl-0" xs={7}>
                                                        <Field name="customerNameOnly" type="text" label="Customer Name" component={FieldWrapper.renderTextField}                                                            
                                                            onChange={this.onChangeCustomerNameOrNumberOrState} maxLength={50}
                                                            disabled={this.state.selected} />
                                                    </Col>
                                                    <Col className="pr-0" xs={5}>
                                                        <Field name="billto" type="text" label="Customer Number" component={FieldWrapper.renderTextField}
                                                            onChange={this.onChangeCustomerNameOrNumberOrState} maxLength={10} autoCapitalize={false}
                                                            numericOnly={this.props.client.rules.numericCustomerId}
                                                            disabled={this.state.selected} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="pl-0" xs={6}>
                                                        <Field name="country" label="Country" component={FieldWrapper.renderDropdownField}
                                                           // defaultValue={this.getDefaultCountryOption()}
                                                            disabled={this.state.selected}
                                                            open={this.state.countryFlag}
                                                            options={this.state.countryOptions}
                                                            onSelectOption={this.onSelectCountry}
                                                            onToggle={this.onToggleCountry} />                                           
                                                    </Col>
                                                    <Col className="pr-0" xs={6}>
                                                        {this.state.selectedCountry && (
                                                            <React.Fragment>
                                                                {this.state.selectedCountry.states.length > 0 && (
                                                                    <Field name="state" label={this.state.selectedCountry.subdivision}
                                                                        component={FieldWrapper.renderDropdownField}
                                                                   //     defaultValue={this.getDefaultState()}
                                                                        disabled={this.state.selected}
                                                                        open={this.state.stateFlag}
                                                                        options={this.state.stateOptions}
                                                                        onSelectOption={this.onSelectState}
                                                                        onToggle={this.onToggleState} />
                                                                )}
                                                                {this.state.selectedCountry.states.length === 0 && (
                                                                    <Field name="state" type="text" label={this.state.selectedCountry.subdivision}
                                                                        component={FieldWrapper.renderTextField}
                                                                        onChange={this.onChangeCustomerNameOrNumberOrState}
                                                                        maxLength={30} autoComplete={false}
                                                                        disabled={this.state.selected} />
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </motion.div>
                                    </Col>
                                </Row>
                                <button type="submit" ref={this.submitButton}>SUBMIT</button>
                            </form>
                            <Row className="query-results">
                                <Col className="pl-0 pr-0">
                                    <Loader isLoading={this.state.submitting} isChild={true} />
                                    {this.state.activeQuery && !(this.state.submitting || this.hasFindResults()) && (
                                        <label className="no-results">No matching customers found</label>
                                    )}
                                    {this.state.activeQuery && this.hasFindResults() && (
                                        <ListGroup flush>
                                            {this.state.queryResults.map((customer: CustomersStore.Customer, index: number) => (
                                                <ListGroupItem key={"customer" + index} className={(index % 2 === 1 ? "odd" : "") + (index === this.state.selectedIndex ? " selected" : "")}>
                                                    <div className="customer-container">
                                                        <div className="action">
                                                            <Button color="secondary" onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.selectCustomer(e, index, customer)}
                                                                disabled={this.state.selected} title={'View Customer'}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-search">
                                                                    <circle cx="11" cy="11" r="8"></circle>
                                                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                                </svg>
                                                            </Button>
                                                        </div>
                                                        <div className="description">
                                                            <div className="two-column">
                                                                <label className="billto">{customer.billto}</label>
                                                                <label className="shipto">{customer.locationDescription ? customer.locationDescription : ''}</label>
                                                            </div>
                                                            <label className="customer-name">{customer.nameOnly}</label>
                                                            {customer.street && (
                                                                <label className="street">{customer.street}</label>
                                                            )}
                                                            <div className="two-column">
                                                                <label className="city-state">{this.getCustomerCityState(customer)}</label>
                                                                <label className="country">
                                                                    {customer.country && customer.country.code && (
                                                                        <span className={"fi fi-" + customer.country.code.toLowerCase()}></span>
                                                                    )}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup>
                                    )}
                                </Col>
                            </Row>
                            <Row className="query-pager">
                                <Col className="pl-0 pr-0 pt-2">
                                    <Button color="link" disabled={this.state.selectedPage <= 1 || this.state.submitting || this.state.selected} onClick={this.showFirst}>
                                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                        </svg>
                                    </Button>
                                    <Button color="link" disabled={this.state.selectedPage <= 1 || this.state.submitting || this.state.selected} onClick={this.showPrev}>
                                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                        </svg>
                                    </Button>
                                    <label className="page-number">{this.state.selectedPage} of {this.state.pageCount}</label>
                                    <Button color="link" disabled={this.isLast() || this.state.submitting || this.state.selected} onClick={this.showNext}>
                                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                        </svg>
                                    </Button>
                                    <Button color="link" disabled={this.isLast() || this.state.submitting || this.state.selected} onClick={this.showLast}>
                                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                        </svg>
                                    </Button>
                                </Col>
                            </Row>
                        </motion.div>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={this.cancelFind} disabled={this.state.submitting}>
                                    Close
                                </Button>
                                <Button color="link" onClick={this.clearFilters} disabled={this.state.submitting}>
                                    Clear
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private cancelFind = (): void => {
        this.props.actions.clearCustomersQuery();
        this.props.onDismiss(null, null);
    }

    private clearFilters = (): void => {
        this.resetForm(false);
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        })
    }

    private ensureDataFetched = (): void => {
        if (!this.props.countriesState.countries) {
            this.props.actions.requestCountries(this.props.client.code);
        }
    }

    private findCustomer = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }

    private getCountryOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        if (this.props.countriesState.countries) {
            for (let n: number = 0; n < this.props.countriesState.countries.length; n++) {
                let country: Country = this.props.countriesState.countries[n];
                options.push({ label: country.name, value: country.code });
            }
        }
        return options;
    }

    private getCustomerCityState = (customer: CustomersStore.Customer): string => {
        let cityState: string = customer.city ? customer.city : "";
        if (customer.state && (customer.state.code || customer.state.name)) {
            if (cityState.length > 0) {
                cityState = cityState + ", ";
            }
            cityState = cityState + (customer.state.code ? customer.state.code : customer.state.name);
            if (customer.postalCode) {
                if (cityState.length > 0) {
                    cityState = cityState + " ";
                }
                cityState = cityState + customer.postalCode;
            }
        }
        return cityState;
    }

    private getCustomers = (query: Query<CustomersStore.Customer>): void => {
        this.props.asyncActions.requestCustomersAsync(this.props.client.code, query)
            .then(result => {
                this.scrollGridResults();
                this.setState({
                    submitting: false,
                    activeQuery: query,
                    selectedPage: result.totalCount > 0 ? result.page + 1 : 0,
                    queryResults: result.data,
                    pageCount: Math.ceil(result.totalCount / query.pageSize)
                })
            },
            err => {
                this.props.onDismiss(null, null);
            });
    }

    private getDefaultCountry = (): Country | null | undefined => {
        let defaultCountry: Country | null | undefined;
        if (this.props.countriesState.countries && this.props.countriesState.countries.length > 0) {
            defaultCountry = this.props.countriesState.countries[0];
        }
        return defaultCountry;
    }

    private getDefaultCountryOption = (): string => {
        let defaultCountry: Country | null | undefined = this.getDefaultCountry();
        return defaultCountry ? defaultCountry.name : "Select...";
    }

    private getDefaultState = (): string => {
        return "Select...";
    }

    private getQuery = (values: any, newQuery: boolean): Query<CustomersStore.Customer> => {
        let query: Query<CustomersStore.Customer> | null | undefined = newQuery ? undefined : this.state.activeQuery;
        if (!query) {
            query = {
                page: 0,
                pageSize: 10,
                totalCount: 0,
                filters: this.getQueryFilters(values)
            } as Query<CustomersStore.Customer>;
        }
        return query;
    }

    private getQueryFilters = (values: any): Filter<CustomersStore.Customer>[] => {
        let filters: Filter<CustomersStore.Customer>[] = [];
        if (values.billto) {
            filters.push({
                column: {
                    field: "billto",
                    type: "string"
                },
                operator: "=",
                value: values.billto
            });
        }
        if (values.shipto) {
            filters.push({
                column: {
                    field: "shipto",
                    type: "string"
                },
                operator: "=",
                value: values.shipto
            });
        }
        if (values.customerNameOnly) {
            filters.push({
                column: {
                    field: "nameOnly",
                    type: "string"
                },
                operator: "=",
                value: values.customerNameOnly
            });
        }
        if (values.country && this.state.selectedCountry) {
            filters.push({
                column: {
                    field: "country.name",
                    type: "string"
                },
                operator: "=",
                value: this.state.selectedCountry.code
            });
        }
        if (values.state && this.state.selectedState) {
            filters.push({
                column: {
                    field: "state.name",
                    type: "string"
                },
                operator: "=",
                value: this.state.selectedState ? this.state.selectedState.code : values.state
            });
        }
        return filters;
    }

    private getStateOptions = (country: Country | null | undefined): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        if (country) {
            for (let n: number = 0; n < country.states.length; n++) {
                let state: State = country.states[n];
                options.push({ label: state.name, value: state.code });
            }
        }
        return options;
    }

    private hasFindResults = (): boolean => {
        return this.state.activeQuery ? this.state.pageCount > 0 ? true : false : false;
    }

    private initializeForm = (): void => {
        let country: Country | null | undefined = this.getDefaultCountry();
        this.setState({
            selectedCountry: country,
            selectedState: undefined,
            stateOptions: country ? this.getStateOptions(country) : [],
            clearSearch: false,
            selectedPage: 0,
            pageCount: 0,
            activeQuery: undefined,
            queryResults: [],
            selectedIndex: undefined
        });

        this.props.change('billto', null);
        this.props.change('customerNameOnly', null);
        this.props.change('shipto', null);
        this.props.change('country', this.getDefaultCountryOption());
        this.props.change('state', 'Select...');

        setTimeout(() => {
            this.getCustomers(this.getQuery([], true));
        })
    }

    private isCurrent = (page: number): boolean => {
        return page === this.state.selectedPage ? true : false;
    }

    private isLast = (): boolean => {
        return this.state.selectedPage >= this.state.pageCount;
    }

    private isNext = (page: number): boolean => {
        return page > this.state.selectedPage ? true : false;
    }

    private onChangeCustomerNameOrNumberOrState = debounce((): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }, 400, { leading: false, trailing: true });

    private onSearch = (search: string | null | undefined): void => {
        let query: Query<CustomersStore.Customer> | null | undefined = this.state.activeQuery;
        if (!query) {
            query = {
                page: 0,
                pageSize: 10,
                totalCount: 0,
                search: search || ''
            } as Query<CustomersStore.Customer>;
        }
        else {
            query.search = search || '';
            query.page = 0;
        }
        this.setState({
            submitting: true
        });
        this.getCustomers(query);
    }

    private onSearchFiltersRowChange = (): void => {
        setTimeout(() => {
            this.setState({
                animationComplete: this.state.filterFlag
            });
        }, 400);
    }

    private onSelectCountry = (selectedOption: FieldWrapper.OptionValue): void => {
        if (this.props.countriesState.countries) {
            let country: Country[] = this.props.countriesState.countries.filter((c: Country) => {
                return c.code === selectedOption.value;
            });
            this.props.change('country', selectedOption.label);
            this.props.change('state', 'Select...');
            this.setState({
                selectedCountry: country[0],
                selectedState: undefined,
                stateOptions: this.getStateOptions(country[0])
            });
        }
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        })
    }

    private onSelectState = (selectedOption: FieldWrapper.OptionValue): void => {
        if (this.state.selectedCountry) {
            let state: State[] = this.state.selectedCountry.states.filter((s: State) => {
                return s.code === selectedOption.value;
            });
            this.props.change('state', selectedOption.label);
            this.setState({
                selectedState: state[0]
            });
        }
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        })
    }

    private onToggleCountry = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {
        event.preventDefault();
        let countryFlag: boolean = !this.state.countryFlag;
        if (countryFlag) {
            this.props.change('country', 'Select...');
        }
        this.setState({
            countryFlag: countryFlag
        });
        if (!countryFlag) {            
            setTimeout(() => {
                if (this.state.selectedCountry) {
                    this.props.change('country', this.state.selectedCountry.name);
                }
            })            
        }
    }

    private onToggleState = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {
        event.preventDefault();
        let stateFlag: boolean = !this.state.stateFlag;
        if (stateFlag) {
            this.props.change('state', 'Select...');
        }
        this.setState({
            stateFlag: stateFlag
        });
        if (!stateFlag) {
            setTimeout(() => {
                if (this.state.selectedState) {
                    this.props.change('state', this.state.selectedState.name);
                }
            })
        }
    }

    private resetForm = (closeFilter: boolean): void => {
        this.props.reset();
        this.props.change('country', this.getDefaultCountryOption());
        this.props.change('state', 'Select...');
        this.setState({
            submitting: false,
            filterFlag: closeFilter ? false : this.state.filterFlag,
            animationComplete: false,
            selectedCountry: this.getDefaultCountry(),
            selectedState: undefined,
            clearSearch: true,
            activeQuery: undefined
        });
    }

    private scrollGridResults = (): void => {
        let queryResults: any = $('#find-customer .query-results');
        if (queryResults.length > 0) {
            queryResults[0].scrollTop = 0;
        }
    }

    private selectCustomer = (event: React.KeyboardEvent | React.MouseEvent, index: number, customer: CustomersStore.Customer): void => {
        event.preventDefault();
        this.setState({
            selected: true,
            selectedIndex: index
        });
        setTimeout(() => {
            this.props.onDismiss(customer.billto, customer.shipto);
        }, 400);
    }

    private showFirst = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.stopPropagation();
        if (this.state.activeQuery) {
            this.setState({
                submitting: true,
                selectedIndex: undefined
            });
            let query: Query<CustomersStore.Customer> = this.state.activeQuery;
            query.page = 0;
            this.getCustomers(query);
        }
    }

    private showLast = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.stopPropagation();
        if (this.state.activeQuery) {
            this.setState({
                submitting: true,
                selectedIndex: undefined
            });
            let query: Query<CustomersStore.Customer> = this.state.activeQuery;
            query.page = this.state.pageCount - 1;
            this.getCustomers(query);
        }
    }

    private showNext = (event: React.MouseEvent): void => {
        event.stopPropagation();
        if (this.state.activeQuery && this.state.activeQuery.page < this.state.pageCount) {
            this.setState({
                submitting: true,
                selectedIndex: undefined
            });
            let query: Query<CustomersStore.Customer> = this.state.activeQuery;
            query.page++;
            this.getCustomers(query);
        }
    }

    private showPrev = (event: React.MouseEvent): void => {
        event.stopPropagation();
        if (this.state.activeQuery && this.state.activeQuery.page > 0) {
            this.setState({
                submitting: true,
                selectedIndex: undefined
            });
            let query: Query<CustomersStore.Customer> = this.state.activeQuery;
            query.page--;
            this.getCustomers(query);
        }
    }

    private toggleFilterPanel = (event: React.MouseEvent | React.KeyboardEvent): void => {
        event.preventDefault();
        let filterFlag: boolean = this.state.filterFlag ? false : true;
        this.setState({
            filterFlag: filterFlag
        });
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        countriesState: state.countries,
        customerState: state.customers
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            CountriesStore.actionCreators,
            CustomersStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestCustomersAsync: (clientCode: ClientCode, query: Query<any>) => dispatch(CustomersStore.actionCreators.requestCustomers(clientCode, query))
        }
    };
}

export default connect<{}, {}, FindCustomerOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'findCustomerForm',
    validate: validateFindCustomerForm,
    enableReinitialize: true
})(FindCustomer as any));
