﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ClientStore from './Client';
import * as MessageStore from './Message';
import * as OrderInvoiceStore from './OrderInvoice';
import { OrderDetail, OrderManifest } from './Orders';
import { OrderInvoice } from './OrderInvoice';
import { Product } from './Products';
import * as ErrorMessage from '../common/ErrorMessage';
import { ClientCode } from '../enums/ClientCode';
import { AvailableUnits, Color, GarmentType, Gender, Dimension, Season, Size, Style } from '../common/ProductTypes';
import { EmbroiderySpecification, PlacementOption } from '../common/EmbroideryTypes';
import { Query, QueryResult } from 'material-table';
import { formatOrderDates } from './Orders';
import { convertSQLDateToJSDate } from '../common/DateConverter';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface NewOrderItem {
    client: ClientStore.Client;
    customerNumber: string;
    orderId: number;
    product: Product;
    dimension: Dimension;
    embroiderySpecifications: EmbroiderySpecification[];
}

export interface NewOrderItems {
    client: ClientStore.Client;
    customerNumber: string;
    orderId: number;
    items: NewOrderItem[];
}

export interface OrderItem {
    id: number | null | undefined;
    canEmbroider: boolean;
    chaseDate: Date | null | undefined;
    clientCode: ClientCode;
    color: Color;
    customerNumber: string;
    dimension: Dimension;
    dimensionDescription: string;
    earliestAvailable: Date | null | undefined;
    garmentType: GarmentType;
    gender: Gender;
    imageUrl: string;
    isEditable: boolean;
    notes: string;
    orderId: number;
    productId: string;
    sanitizedProductId: string;
    season: Season;
    shortDescription: string;
    sizeDescription: string;
    style: Style;
    totalPrice: number;
    totalUnits: number;
    wholesale: number;
    embroiderySpecifications: EmbroiderySpecification[];
    placementOptions: PlacementOption[];
}

export interface CopyOrderItemRequest {
    client: ClientStore.Client;
    copyEmbroidery: boolean;
    copyQuantities: boolean;
    orderItem: OrderItem;
}

export interface CopyToOrderItem {
    canEmbroider: boolean;
    color: Color;
    id: number,
    imageUrl: string;
    notes: string;
    selected: boolean;
    sizeScale: string;
    style: Style;
}

export interface CopyToOrderItems {
    client: ClientStore.Client | null | undefined;
    copyEmbroidery: boolean;
    copyFromId: number;
    copyFromSizeScale: string;
    copyQuantities: boolean;
    items: CopyToOrderItem[];
    orderId: number;
    orderItem: OrderItem | null | undefined;
}

export interface OrderItems extends QueryResult<OrderItem> {
    query: Query<any>
};

export interface OrderItemsState {
    isAdding: boolean;
    isDeleting: boolean;
    isLoading: boolean;
    isRetrievingCopyToItems: boolean;
    isUpdatingEmbroidery: boolean;
    isUpdatingNotes: boolean;
    isUpdatingQuantities: boolean;
    isCopyingItem: boolean;
    orderId: number | null | undefined;
    orderItem: OrderItem | null | undefined;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface AddOrderItemAction {
    type: 'ADD_ORDER_ITEM';
    clientCode: ClientCode;
    newOrderItem: NewOrderItem;
}

interface AddOrderItemCompleteAction {
    type: 'ADD_ORDER_ITEM_COMPLETE';
    clientCode: ClientCode;
    orderDetail: OrderDetail | null | undefined;
}

interface CopyOrderItemAction {
    type: 'COPY_ORDER_ITEM';
    clientCode: ClientCode;
    copyToOrderItems: CopyToOrderItems;
}

interface CopyOrderItemCompleteAction {
    type: 'COPY_ORDER_ITEM_COMPLETE';
    clientCode: ClientCode;
    orderDetail: OrderDetail | null | undefined;
}

interface DeleteOrderItemAction {
    type: 'DELETE_ORDER_ITEM';
    clientCode: ClientCode;
    orderItemToDelete: OrderItem;
}

interface DeleteOrderItemCompleteAction {
    type: 'DELETE_ORDER_ITEM_COMPLETE';
    clientCode: ClientCode;
    orderItemToDelete: OrderItem | null | undefined;
}

interface QueueOrderItemForUpdateAction {
    type: 'QUEUE_ORDER_ITEM_FOR_UPDATE';
    clientCode: ClientCode;
    orderId: number;
    orderItem: OrderItem;
}

interface QueueOrderItemCompleteAction {
    type: 'QUEUE_ORDER_ITEM_COMPLETE';
}

interface RequestOrderItemsAction {
    type: 'REQUEST_ORDER_ITEMS';
    clientCode: ClientCode;
    orderDetail: OrderDetail,
    query: Query<any>;
}

interface ReceiveOrderItemsAction {
    type: 'RECEIVE_ORDER_ITEMS';
    clientCode: ClientCode;
    orderItems: OrderItems | null | undefined;
}

interface RequestCopyToOrderItemsAction {
    type: 'REQUEST_COPYTO_ORDER_ITEMS';
    clientCode: ClientCode;
    orderItemToCopy: OrderItem;
}

interface ReceiveCopyToOrderItemsAction {
    type: 'RECEIVE_COPYTO_ORDER_ITEMS';
    clientCode: ClientCode;
    orderItemToCopy: OrderItem | null | undefined;
}

interface UpdateOrderItemEmbroideriesAction {
    type: 'UPDATE_ORDER_ITEM_EMBROIDERIES';
    clientCode: ClientCode;
    orderId: number;
    orderItem: OrderItem;
}

interface UpdateOrderItemEmbroideriesCompleteAction {
    type: 'UPDATE_ORDER_ITEM_EMBROIDERIES_COMPLETE';
    clientCode: ClientCode;
}

interface UpdateOrderItemNotesAction {
    type: 'UPDATE_ORDER_ITEM_NOTES';
    clientCode: ClientCode;
    orderId: number;
    orderItem: OrderItem;
}

interface UpdateOrderItemNotesCompleteAction {
    type: 'UPDATE_ORDER_ITEM_NOTES_COMPLETE';
    clientCode: ClientCode;
}

interface UpdateOrderItemQuantitiesAction {
    type: 'UPDATE_ORDER_ITEM_QUANTITIES';
    clientCode: ClientCode;
    orderId: number;
    orderItems: OrderItem[];
}

interface UpdateOrderItemQuantitiesCompleteAction {
    type: 'UPDATE_ORDER_ITEM_QUANTITIES_COMPLETE';
    clientCode: ClientCode;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = AddOrderItemAction | AddOrderItemCompleteAction |
    CopyOrderItemAction | CopyOrderItemCompleteAction |
    DeleteOrderItemAction | DeleteOrderItemCompleteAction |
    QueueOrderItemForUpdateAction | QueueOrderItemCompleteAction |
    RequestOrderItemsAction | ReceiveOrderItemsAction |
    RequestCopyToOrderItemsAction | ReceiveCopyToOrderItemsAction |
    UpdateOrderItemEmbroideriesAction | UpdateOrderItemEmbroideriesCompleteAction |
    UpdateOrderItemNotesAction | UpdateOrderItemNotesCompleteAction |
    UpdateOrderItemQuantitiesAction | UpdateOrderItemQuantitiesCompleteAction |
    OrderInvoiceStore.SetOrderInvoiceAction |
    MessageStore.BroadcastMessageAction;

const formatOrderItemDates = (orderItems: OrderItem[]): OrderItem[] => {
    for (let i: number = 0; i < orderItems.length; i++) {
        let dimension: Dimension = orderItems[i].dimension;
        for (let d: number = 0; d < dimension.inventoryDates.length; d++) {
            dimension.inventoryDates[d].date = convertSQLDateToJSDate(dimension.inventoryDates[d].date);
            dimension.inventoryDates[d].embroideredDate = convertSQLDateToJSDate(dimension.inventoryDates[d].embroideredDate);
        }
        for (let s: number = 0; s < dimension.sizes.length; s++) {
            let size: Size = dimension.sizes[s];
            for (let d: number = 0; d < size.unitsAvailable.length; d++) {
                let units: AvailableUnits = size.unitsAvailable[d];
                units.inventoryDate.date = convertSQLDateToJSDate(units.inventoryDate.date);
                units.inventoryDate.embroideredDate = convertSQLDateToJSDate(units.inventoryDate.embroideredDate);
            }
        }
    }
    return orderItems;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    addOrderItem: (client: ClientStore.Client, newOrderItem: NewOrderItem): AppThunkAction<KnownAction> => (dispatch, getState): Promise<OrderDetail | null | undefined> => {
        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orderItems && !appState.orderItems.isAdding) {

                let fetchError: boolean = false;
                let action: string = 'orders/additem';
                let url: string = `${action}/${newOrderItem.orderId.toString()}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(newOrderItem)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'ADD_ORDER_ITEM_COMPLETE', clientCode: client.code, orderDetail: appState.orders ? appState.orders.orderDetail : undefined });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let orderManifest: OrderManifest = data as OrderManifest;
                        let orderDetail: OrderDetail = formatOrderDates(orderManifest.orderDetail);
                        resolve(orderDetail);
                        dispatch({ type: 'SET_ORDER_INVOICE', clientCode: client.code, orderId: orderDetail.id as number, orderInvoice: orderManifest.orderInvoice });
                        dispatch({ type: 'ADD_ORDER_ITEM_COMPLETE', clientCode: client.code, orderDetail: orderDetail });
                    },
                        err => {
                            reject(err.message);
                            if (!fetchError) {
                                dispatch({ type: 'ADD_ORDER_ITEM_COMPLETE', clientCode: client.code, orderDetail: appState.orders ? appState.orders.orderDetail : undefined });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                        })

                dispatch({ type: 'ADD_ORDER_ITEM', clientCode: client.code, newOrderItem: newOrderItem });
            }
            else {
                resolve(appState.orders ? appState.orders.orderDetail : undefined);
            }
        });
    },
    copyOrderItem: (clientCode: ClientCode, copyToOrderItems: CopyToOrderItems, broadcastError: boolean): AppThunkAction<KnownAction> => (dispatch, getState): Promise<OrderDetail | null | undefined> => {
        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orderItems && !appState.orderItems.isCopyingItem) {

                let fetchError: boolean = false;
                let action: string = 'orders/copyitem';
                let url: string = `${action}/${copyToOrderItems.orderId.toString()}`;

                fetch(url,
                    {
                        method: 'PUT',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(copyToOrderItems)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'COPY_ORDER_ITEM_COMPLETE', clientCode: clientCode, orderDetail: null });
                                    if (broadcastError) {
                                        dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                    }
                                    reject(errorMessage.text);
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let orderManifest: OrderManifest = data as OrderManifest;
                        let orderDetail: OrderDetail = formatOrderDates(orderManifest.orderDetail);
                        resolve(orderDetail);
                        dispatch({ type: 'SET_ORDER_INVOICE', clientCode: clientCode, orderId: orderDetail.id as number, orderInvoice: orderManifest.orderInvoice });
                        dispatch({ type: 'COPY_ORDER_ITEM_COMPLETE', clientCode: clientCode, orderDetail: orderDetail });
                    },
                        err => {
                            if (!fetchError) {
                                let msg: MessageStore.Message = ErrorMessage.getFromError(err, action);
                                dispatch({ type: 'COPY_ORDER_ITEM_COMPLETE', clientCode: clientCode, orderDetail: null });
                                if (broadcastError) {
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: msg });
                                }
                                reject(msg.text);
                            }
                        });

                dispatch({ type: 'COPY_ORDER_ITEM', clientCode: clientCode, copyToOrderItems: copyToOrderItems });
            }
            else {
                resolve(null);
            }
        });
    },
    deleteOrderItem: (clientCode: ClientCode, orderItemToDelete: OrderItem, broadcastError: boolean): AppThunkAction<KnownAction> => (dispatch, getState): Promise<OrderInvoice | null | undefined> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orderItems && !appState.orderItems.isDeleting) {

                let fetchError: boolean = false;
                let action: string = 'orders/removeItem';
                let url: string = `${action}/${orderItemToDelete.orderId}`;

                fetch(url,
                    {
                        method: 'DELETE',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(orderItemToDelete)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'DELETE_ORDER_ITEM_COMPLETE', clientCode: clientCode, orderItemToDelete: null });
                                    if (broadcastError) {
                                        dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                    }
                                    reject(errorMessage.text);
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let orderInvoice: OrderInvoiceStore.OrderInvoice = data as OrderInvoiceStore.OrderInvoice;
                        resolve(orderInvoice);
                        dispatch({ type: 'SET_ORDER_INVOICE', clientCode: clientCode, orderId: orderItemToDelete.orderId, orderInvoice: orderInvoice });
                        dispatch({ type: 'DELETE_ORDER_ITEM_COMPLETE', clientCode: clientCode, orderItemToDelete: orderItemToDelete });
                    },
                        err => {
                            if (!fetchError) {
                                dispatch({ type: 'DELETE_ORDER_ITEM_COMPLETE', clientCode: clientCode, orderItemToDelete: null });
                                if (broadcastError) {
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                                }
                                else {
                                    reject(ErrorMessage.getFromError(err, action).text);
                                }
                            }
                        })

                dispatch({ type: 'DELETE_ORDER_ITEM', clientCode: clientCode, orderItemToDelete: orderItemToDelete });
            }
            else {
                resolve(null);
            }
        });
    },
    queueOrderItemForUpdate: (orderItem: OrderItem) => ({ type: 'QUEUE_ORDER_ITEM_FOR_UPDATE', clientCode: orderItem.clientCode, orderId: orderItem.orderId, orderItem: orderItem } as QueueOrderItemForUpdateAction),
    queueOrderItemComplete: () => ({ type: 'QUEUE_ORDER_ITEM_COMPLETE' } as QueueOrderItemCompleteAction),
    requestOrderItems: (orderDetail: OrderDetail, query: Query<OrderItem>): AppThunkAction<KnownAction> => (dispatch, getState): Promise<QueryResult<OrderItem>> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orderItems && !appState.orderItems.isLoading) {

                let clientAwareQuery: object = Object.assign({}, query, { client: orderDetail.client, clientCode: orderDetail.client.code });
                let fetchError: boolean = false;
                let action: string = 'orders/getitems';
                let orderId: number = orderDetail.id as number;
                let openOrder: string = orderDetail.orderStatus === 'Open' ? 'True' : 'False';
                let url: string = `${action}/${orderId}/${openOrder}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(clientAwareQuery)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_ORDER_ITEMS', clientCode: orderDetail.client.code, orderItems: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let orderItems: OrderItems = data as OrderItems;
                        orderItems.data = formatOrderItemDates(orderItems.data);
                        resolve(orderItems);
                        dispatch({ type: 'RECEIVE_ORDER_ITEMS', clientCode: orderDetail.client.code, orderItems: orderItems });
                    },
                        err => {
                            reject(err.message);
                            if (!fetchError) {
                                dispatch({ type: 'RECEIVE_ORDER_ITEMS', clientCode: orderDetail.client.code, orderItems: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                        });

                dispatch({ type: 'REQUEST_ORDER_ITEMS', clientCode: orderDetail.client.code, orderDetail: orderDetail, query: query });
            }
            else {
                resolve({ data: [], query: query, page: query.page, totalCount: 0 } as QueryResult<OrderItem>);
            }
        });
    },
    requestCopyToOrderItems: (clientCode: ClientCode, request: CopyOrderItemRequest, broadcastError: boolean): AppThunkAction<KnownAction> => (dispatch, getState): Promise<CopyToOrderItems | null | undefined> => {
        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orderItems && !appState.orderItems.isRetrievingCopyToItems) {

                let fetchError: boolean;
                let action: string = 'orders/getcopytoitems';
                let url: string = `${action}/${request.orderItem.orderId}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(request)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_COPYTO_ORDER_ITEMS', clientCode: clientCode, orderItemToCopy: null });
                                    if (broadcastError) {
                                        dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                    }
                                    reject(errorMessage.text);
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        let copyToOrderItems: CopyToOrderItems = data as CopyToOrderItems;
                        resolve(copyToOrderItems);
                        dispatch({ type: 'RECEIVE_COPYTO_ORDER_ITEMS', clientCode: clientCode, orderItemToCopy: request.orderItem });
                    },
                        err => {
                            if (!fetchError) {
                                let msg: MessageStore.Message = ErrorMessage.getFromError(err, action);
                                dispatch({ type: 'RECEIVE_COPYTO_ORDER_ITEMS', clientCode: clientCode, orderItemToCopy: null });
                                if (broadcastError) {
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: msg });
                                }
                                reject(msg.text);
                            }
                        })

                dispatch({ type: 'REQUEST_COPYTO_ORDER_ITEMS', clientCode: clientCode, orderItemToCopy: request.orderItem });
            }
            else {
                resolve(null);
            }
        });
    },
    updateOrderItemEmbroideries: (clientCode: ClientCode, orderId: number, orderItem: OrderItem, broadcastError: boolean): AppThunkAction<KnownAction> => (dispatch, getState): Promise<OrderItem | null | undefined> => {
        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orderItems && !appState.orderItems.isUpdatingEmbroidery) {

                let fetchError: boolean = false;
                let action: string = 'orders/updateembroideries';
                let url: string = `${action}/${orderId.toString()}`;

                fetch(url,
                    {
                        method: 'PUT',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(orderItem)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'UPDATE_ORDER_ITEM_EMBROIDERIES_COMPLETE', clientCode: clientCode });
                                    if (broadcastError) {
                                        dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                    }
                                    else {
                                        reject(errorMessage.text);
                                    }
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(orderItem);
                        let orderInvoice: OrderInvoice = data as OrderInvoice;
                        dispatch({ type: 'SET_ORDER_INVOICE', clientCode: clientCode, orderId: orderId, orderInvoice: orderInvoice });
                        dispatch({ type: 'UPDATE_ORDER_ITEM_EMBROIDERIES_COMPLETE', clientCode: clientCode });
                    },
                        err => {
                            if (!fetchError) {
                                reject(err.message);
                                dispatch({ type: 'UPDATE_ORDER_ITEM_EMBROIDERIES_COMPLETE', clientCode: clientCode });
                                if (broadcastError) {
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                                }
                            }
                        }
                    )

                dispatch({ type: 'UPDATE_ORDER_ITEM_EMBROIDERIES', clientCode: clientCode, orderId: orderId, orderItem: orderItem });
            }
            else {
                resolve(orderItem);
            }
        });
    },
    updateOrderItemNotes: (clientCode: ClientCode, orderId: number, orderItem: OrderItem, broadcastError: boolean): AppThunkAction<KnownAction> => (dispatch, getState): Promise<OrderItem | null | undefined> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.orderItems && !appState.orderItems.isUpdatingNotes) {

                let fetchError: boolean = false;
                let action: string = 'orders/updatenotes';
                let url: string = `${action}/${orderId.toString()}`;

                fetch(url,
                    {
                        method: 'PUT',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(orderItem)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'UPDATE_ORDER_ITEM_NOTES_COMPLETE', clientCode: clientCode });
                                    if (broadcastError) {
                                        dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                    }
                                    else {
                                        reject(errorMessage.text);
                                    }
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(orderItem);
                        dispatch({ type: 'UPDATE_ORDER_ITEM_NOTES_COMPLETE', clientCode: clientCode });
                    },
                        err => {
                            if (!fetchError) {
                                reject(err.message);
                                dispatch({ type: 'UPDATE_ORDER_ITEM_NOTES_COMPLETE', clientCode: clientCode });
                                if (broadcastError) {
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                                }
                            }
                        }
                    )

                dispatch({ type: 'UPDATE_ORDER_ITEM_NOTES', clientCode: clientCode, orderId: orderId, orderItem: orderItem })
            }
            else {
                resolve(orderItem);
            }
        })
    },
    updateOrderItemQuantities: (clientCode: ClientCode, orderId: number, orderItems: OrderItem[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.orderItems && !appState.orderItems.isUpdatingQuantities) {

            let fetchError: boolean = false;
            let action: string = 'orders/updatequantities';
            let url: string = `${action}/${orderId.toString()}`;

            fetch(url,
                {
                    method: 'PUT',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(orderItems)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'UPDATE_ORDER_ITEM_QUANTITIES_COMPLETE', clientCode: clientCode });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    let orderInvoice: OrderInvoice = data as OrderInvoice;
                    dispatch({ type: 'SET_ORDER_INVOICE', clientCode: clientCode, orderId: orderId, orderInvoice: orderInvoice });
                    dispatch({ type: 'UPDATE_ORDER_ITEM_QUANTITIES_COMPLETE', clientCode: clientCode });
                },
                    err => {
                        if (!fetchError) {
                            dispatch({ type: 'UPDATE_ORDER_ITEM_QUANTITIES_COMPLETE', clientCode: clientCode });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    }
                )

            dispatch({ type: 'UPDATE_ORDER_ITEM_QUANTITIES', clientCode: clientCode, orderId: orderId, orderItems: orderItems });
        }
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState = {
    isAdding: false,
    isDeleting: false,
    isLoading: false,
    isRetrievingCopyToItems: false,
    isUpdatingEmbroidery: false,
    isUpdatingNotes: false,
    isUpdatingQuantities: false,
    isCopyingItem: false,
    orderId: undefined,
    orderItem: undefined
}

export const reducer: Reducer<OrderItemsState> = (state: OrderItemsState | undefined, incomingAction: Action): OrderItemsState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_ORDER_ITEM':
            return {
                isAdding: true,
                isDeleting: state.isDeleting,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: action.newOrderItem.orderId,
                orderItem: state.orderItem
            };
        case 'ADD_ORDER_ITEM_COMPLETE':
            return {
                isAdding: false,
                isDeleting: state.isDeleting,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: state.orderId,
                orderItem: state.orderItem
            };
        case 'COPY_ORDER_ITEM':
            return {
                isAdding: false,
                isDeleting: state.isDeleting,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: true,
                orderId: state.orderId,
                orderItem: state.orderItem
            };
        case 'COPY_ORDER_ITEM_COMPLETE':
            return {
                isAdding: false,
                isDeleting: state.isDeleting,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: false,
                orderId: state.orderId,
                orderItem: state.orderItem
            };
        case 'DELETE_ORDER_ITEM':
            return {
                isAdding: state.isAdding,
                isDeleting: true,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: state.orderId,
                orderItem: state.orderItem
            };
        case 'DELETE_ORDER_ITEM_COMPLETE':
            return {
                isAdding: state.isAdding,
                isDeleting: false,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: state.orderId,
                orderItem: state.orderItem
            }
        case 'QUEUE_ORDER_ITEM_FOR_UPDATE':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: action.orderId,
                orderItem: action.orderItem
            };
        case 'QUEUE_ORDER_ITEM_COMPLETE':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: undefined,
                orderItem: undefined
            };
        case 'REQUEST_ORDER_ITEMS':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: true,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: action.orderDetail.id,
                orderItem: undefined
            }
        case 'RECEIVE_ORDER_ITEMS':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: false,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: state.orderId,
                orderItem: undefined
            }
        case 'REQUEST_COPYTO_ORDER_ITEMS':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: false,
                isRetrievingCopyToItems: true,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: state.orderId,
                orderItem: undefined
            };
        case 'RECEIVE_COPYTO_ORDER_ITEMS':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: false,
                isRetrievingCopyToItems: false,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: state.orderId,
                orderItem: undefined
            };
        case 'UPDATE_ORDER_ITEM_EMBROIDERIES':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: true,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: action.orderId,
                orderItem: action.orderItem
            };
        case 'UPDATE_ORDER_ITEM_EMBROIDERIES_COMPLETE':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: false,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: undefined,
                orderItem: undefined
            };
        case 'UPDATE_ORDER_ITEM_QUANTITIES':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: true,
                isCopyingItem: state.isCopyingItem,
                orderId: action.orderId,
                orderItem: undefined
            };
        case 'UPDATE_ORDER_ITEM_QUANTITIES_COMPLETE':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: state.isUpdatingNotes,
                isUpdatingQuantities: false,
                isCopyingItem: state.isCopyingItem,
                orderId: undefined,
                orderItem: undefined
            };
        case 'UPDATE_ORDER_ITEM_NOTES':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: true,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: action.orderId,
                orderItem: undefined
            }
        case 'UPDATE_ORDER_ITEM_NOTES_COMPLETE':
            return {
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: state.isLoading,
                isRetrievingCopyToItems: state.isRetrievingCopyToItems,
                isUpdatingEmbroidery: state.isUpdatingEmbroidery,
                isUpdatingNotes: false,
                isUpdatingQuantities: state.isUpdatingQuantities,
                isCopyingItem: state.isCopyingItem,
                orderId: undefined,
                orderItem: undefined
            }
        default:
            return state;
    }
}