﻿import { format as formatDate } from 'date-fns';

// ----------------- 
// METHODS

export const convertSQLDateToJSDate = (sqlDate: Date): Date => {
    let stringifiedDate: string = sqlDate.toString();
    let y: number = parseInt(stringifiedDate.substring(0, 4));
    let m: number = parseInt(stringifiedDate.substring(5, 7)) - 1;
    let d: number = parseInt(stringifiedDate.substring(8, 10));
    let h: number
    return new Date(y, m, d);
}

export const convertSQLDateToString = (sqlDate: Date, format: string): string => {
    let stringDate: string = '';
    if (sqlDate) {
        let jsDate: Date = convertSQLDateToJSDate(sqlDate);
        stringDate = formatDate(jsDate, format);        
    }
    return stringDate;
}

export const convertJSDateToString = (jsDate: Date, format: string): string => {
    let stringDate: string = '';
    if (jsDate && !isNaN(jsDate.getTime())) {
        stringDate = formatDate(jsDate, format);
    }
    return stringDate;
}