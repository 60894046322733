import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as CustomersStore from '../../store/Customers';
import * as OrdersStore from '../../store/Orders';
import { Client } from '../../store/Client';
import { Product } from '../../store/Products';
import { NewOrderItem } from '../../store/OrderItems';
import { Dimension, Size, Style } from '../../common/ProductTypes';
import { Container, Col, Row } from 'reactstrap';
import ProductListboxRow from '../product-listbox-row/ProductListboxRow';
import AddOrderItems from '../add-order-items/AddOrderItems';
import NoSelectionsPrompt from '../no-selections-prompt/NoSelectionsPrompt';
import { convertSQLDateToJSDate } from '../../common/DateConverter';
import cloneDeep from 'lodash/cloneDeep';
import $ from 'jquery';

import './ProductListbox.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    customersState: {} as CustomersStore.CustomersState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, CustomersStore.actionCreators)
};

interface ProductListboxAsyncActions {
    asyncActions: {}
}

interface ProductListboxOwnProps {
    client: Client;
    products: Product[];
    isLoading: boolean;
    shipDate: Date | null | undefined;
    onSelect: (selectedItems: number) => void;
    onGoToOrder: (id: number) => void;
    addToOrderButton: React.RefObject<HTMLButtonElement>;
    addToListButton: React.RefObject<HTMLButtonElement>;
}

type ProductListboxProps =
    ProductListboxOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & ProductListboxAsyncActions;

// ----------------
// LOCAL STATE

interface ProductListboxState {
    addingToOrder: boolean;
    promptingForSelection: boolean;
    clearingSelections: boolean;
    selectedItems: NewOrderItem[];
    shipDate: Date | null | undefined;
}

class ProductListbox extends React.PureComponent<ProductListboxProps, ProductListboxState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductListboxProps, state: ProductListboxState) {
        super(props);
        this.state = {
            addingToOrder: false,
            promptingForSelection: false,
            clearingSelections: false,
            selectedItems: [],
            shipDate: this.props.shipDate
        }
        if (this.props.addToOrderButton.current) {
            this.props.addToOrderButton.current.onclick = this.addToOrder;
        }
        if (this.props.addToListButton.current) {
            this.props.addToListButton.current.onclick = this.addToList;
        }
    }

    // ----------------
    // METHODS
    
    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: ProductListboxProps) => { }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <Container className="product-listbox">
                {this.props.products.map((product: Product, rowIndex: number) => (
                    <Row key={"product_" + product.id}>
                        <Col className="pl-0 pr-0">
                            <ProductListboxRow product={product} clientCode={this.props.client.code} shipDate={this.state.shipDate} clearSelections={this.state.clearingSelections}
                                isLoading={this.props.isLoading} onSelectProduct={this.onSelectProduct} onChangeQuantity={this.onChangeQuantity} />
                        </Col>
                    </Row>
                ))}
                <AddOrderItems isOpen={this.state.addingToOrder} client={this.props.client} customer={this.props.customersState.customerDetail}
                    selectedItems={this.state.selectedItems} onDismiss={this.hideAddToOrder} onGoBack={this.onGoBack} />
                <NoSelectionsPrompt isOpen={this.state.promptingForSelection} onDismiss={this.hideNoSelectionsPrompt} />
            </Container>
        );
    }

    // ----------------
    // HELPERS

    private addItem = (newOrderItem: NewOrderItem): void => {
        let selectedItems: NewOrderItem[] = cloneDeep(this.state.selectedItems);
        let matchingIndex: number = selectedItems.findIndex(i => i.product.id === newOrderItem.product.id);
        if (matchingIndex >= 0) {
            selectedItems[matchingIndex] = newOrderItem;
        }
        else {
            selectedItems.push(newOrderItem);
        }
        this.setState({
            selectedItems: selectedItems
        });        
    }

    private addToList = (e: MouseEvent): void => {
        if (this.state.selectedItems.length > 0) {
            this.setActionButtons(false, false, true);
        }
        else {
            this.setState({
                promptingForSelection: true
            });
        }
    }

    private addToOrder = (e: MouseEvent): void => {
        if (this.state.selectedItems.length > 0) {
            this.setActionButtons(false, true, false);
            this.setState({
                addingToOrder: true
            })
        }
        else {
            this.setState({
                promptingForSelection: true
            });
        }
    }

    private clearQuantities = (newOrderItem: NewOrderItem): void => {
        for (let n: number = 0; n < newOrderItem.product.dimensions[0].sizes.length; n++) {
            newOrderItem.product.dimensions[0].sizes[n].quantity = 0;
        }
    }

    private hideAddToOrder = (id: number | null | undefined): void => {
        this.setState({
            addingToOrder: false
        });        
        this.setActionButtons(true, false, false);
        if (id) {
            this.props.onGoToOrder(id);
        }
    }

    private hideNoSelectionsPrompt = (): void => {
        this.setState({
            promptingForSelection: false
        });
    }

    private onChangeQuantity = (product: Product, size: Size): void => {
        let updatedDimension: Dimension = product.dimensions[0];
        let sizeIndex: number = updatedDimension.sizes.findIndex(sz => sz.code === size.code);
        updatedDimension.sizes[sizeIndex] = size;

        let newOrderItem: NewOrderItem = {
            client: this.props.client,
            customerNumber: '',
            dimension: updatedDimension,
            embroiderySpecifications: [],
            orderId: 0,
            product: product            
        }
        this.addItem(newOrderItem);
    }

    private onGoBack = (orderDetail: OrdersStore.OrderDetail): void => {
        for (let n: number = 0; n < this.state.selectedItems.length; n++) {
            this.clearQuantities(this.state.selectedItems[n]);
        }
        this.setState({
            addingToOrder: false,
            clearingSelections: true,
            selectedItems: [],
            shipDate: convertSQLDateToJSDate(orderDetail.shipDate)
        });
        this.setActionButtons(true, false, false);
        setTimeout(() => {
            this.setState({
                clearingSelections: false
            });
        }, 250);
    }

    private onSelectProduct = (product: Product, selected: boolean): void => {
        let newOrderItem: NewOrderItem = {
            client: this.props.client,
            customerNumber: '',
            dimension: product.dimensions[0],
            embroiderySpecifications: [],
            orderId: 0,
            product: product
        };
        if (selected) {
            this.addItem(newOrderItem);
        }
        else {
            this.removeItem(newOrderItem);
        }
        setTimeout(() => {
            this.props.onSelect(this.state.selectedItems.length);
        }, 250);
    }

    private removeItem = (newOrderItem: NewOrderItem): void => {
        let selectedItems: NewOrderItem[] = cloneDeep(this.state.selectedItems);
        let matchingIndex: number = selectedItems.findIndex(i => i.product.id === newOrderItem.product.id);
        if (matchingIndex >= 0) {
            selectedItems.splice(matchingIndex, 1);
            this.setState({
                selectedItems: selectedItems
            });
        }
        this.clearQuantities(newOrderItem);        
    }

    private setActionButtons = (enabled: boolean, addingToOrder: boolean, addingToList: boolean): void => {
        if (this.props.addToOrderButton.current) {
            this.props.addToOrderButton.current.disabled = !enabled;
            this.props.addToOrderButton.current.innerText = (addingToOrder && !enabled) ? "Working..." : "Add to Order";
        }
        if (this.props.addToListButton.current) {
            this.props.addToListButton.current.disabled = !enabled;
            this.props.addToListButton.current.innerText = (addingToList && !enabled) ? "Working..." : "Add to List";
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        customersState: state.customers,
        ordersState: state.orders
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            CustomersStore.actionCreators), dispatch),
        asyncActions: {}
    }
}

export default connect<{}, {}, ProductListboxOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ProductListbox as any);