import * as Categories from './Categories';
import * as Client from './Client';
import * as Collections from './Collections';
import * as ColorDirections from './ColorDirections';
import * as Colors from './Colors';
import * as Countries from './Countries';
import * as Customers from './Customers';
import * as CustomerOrders from './CustomerOrders';
import * as DropShipAddresses from './DropShipAddresses';
import * as Message from './Message';
import * as OrderDates from './OrderDates';
import * as OrderInvoice from './OrderInvoice';
import * as OrderItems from './OrderItems';
import * as Orders from './Orders';
import * as Password from './Password';
import * as ProductlistItems from './ProductlistItems';
import * as Productlists from './Productlists';
import * as Products from './Products';
import * as ShippingOptions from './ShippingOptions';
import * as TapeSelection from './TapeSelection';
import * as User from './User';
import * as Users from './Users';
import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';

// The top-level state object
export interface ApplicationState {
    categories: Categories.CategoriesState | undefined;
    client: Client.ClientState | undefined;
    collections: Collections.CollectionsState | undefined;
    colorDirections: ColorDirections.ColorDirectionsState | undefined;
    colors: Colors.ColorsState | undefined;
    countries: Countries.CountriesState | undefined;
    customers: Customers.CustomersState | undefined;
    customerOrders: CustomerOrders.CustomerOrdersState | undefined;
    dropShipAddresses: DropShipAddresses.DropShipAddressesState | undefined;
    message: Message.MessageState | undefined;
    orderDates: OrderDates.OrderDatesState | undefined;
    orderInvoice: OrderInvoice.OrderInvoiceState | undefined;
    orderItems: OrderItems.OrderItemsState | undefined;
    orders: Orders.OrdersState | undefined;
    password: Password.PasswordState | undefined;
    productlistItems: ProductlistItems.ProductlistItemsState | undefined;
    productlists: Productlists.ProductlistsState | undefined;
    products: Products.ProductsState | undefined;
    shippingOptions: ShippingOptions.ShippingOptionsState | undefined;
    tapeSelection: TapeSelection.TapeSelectionState | undefined;
    user: User.UserState | undefined;
    users: Users.UsersState | undefined;
    counter: Counter.CounterState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    categories: Categories.reducer,
    client: Client.reducer,
    collections: Collections.reducer,
    colorDirections: ColorDirections.reducer,
    colors: Colors.reducer,
    countries: Countries.reducer,
    customers: Customers.reducer,
    customerOrders: CustomerOrders.reducer,
    dropShipAddresses: DropShipAddresses.reducer,
    message: Message.reducer,
    orderDates: OrderDates.reducer,
    orderInvoice: OrderInvoice.reducer,
    orderItems: OrderItems.reducer,
    orders: Orders.reducer,
    password: Password.reducer,
    productlistItems: ProductlistItems.reducer,
    productlists: Productlists.reducer,
    products: Products.reducer,
    shippingOptions: ShippingOptions.reducer,
    tapeSelection: TapeSelection.reducer,
    user: User.reducer,
    users: Users.reducer,
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}


