import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as TapeSelectionStore from '../../store/TapeSelection';
import Checkbox from '../checkbox/Checkbox';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    tapeSelectionState: {} as TapeSelectionStore.TapeSelectionState
};

const actionCreators = {
    actions: Object.assign({}, TapeSelectionStore.actionCreators)
};

interface TapeCheckboxOwnProps {
    id: string;
    label?: string | null | undefined;
    selected: boolean;
    disabled: boolean;
    onChange: (checked: boolean) => void;
}

type TapeCheckboxProps =
    TapeCheckboxOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;     // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface TapeCheckboxState {
    disabled: boolean;
}

class TapeCheckbox extends React.PureComponent<TapeCheckboxProps, TapeCheckboxState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: TapeCheckboxProps, state: TapeCheckboxState) {
        super(props);
        this.state = {
            disabled: this.props.disabled
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: TapeCheckboxProps) => {
        if (this.props.tapeSelectionState.selected && !prevProps.tapeSelectionState.selected) {
            this.setState({
                disabled: true
            })
        }
        else if (prevProps.tapeSelectionState.selected && !this.props.tapeSelectionState.selected) {
            this.setState({
                disabled: false
            });
        }
    }

    public componentWillUnmount = () => {}

    public render = () => {
        return (
            <div className='tape-checkbox'>
                <Checkbox id={this.props.id} selected={this.props.selected} disabled={this.state.disabled}
                    onChange={this.props.onChange} />
            </div>
        );
    }

    // ----------------
    // HELPERS
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        tapeSelectionState: state.tapeSelection
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            TapeSelectionStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, TapeCheckboxOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(TapeCheckbox as any);
