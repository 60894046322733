import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as CustomersStore from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import { ClientCode } from '../../enums/ClientCode';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import FormResult from '../form-result/FormResult';
import AddressForm from '../address-form/AddressForm';
import cloneDeep from 'lodash/cloneDeep';

import './OrderCustomerLocation.scss';

// ----------------
// PROPS

const applicationState = {
    customersState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
};

interface OrderCustomerLocationAsyncActions {
    asyncActions: {
        requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => Promise<CustomersStore.CustomerAccount | null | undefined>
    }
}

interface OrderCustomerLocationOwnProps {
    isOpen: boolean;
    isSaving?: boolean;
    isSaved?: boolean;
    onDismiss: (account: CustomersStore.CustomerAccount | null | undefined) => void;
    clientCode: ClientCode;
    customerAccount: CustomersStore.CustomerAccount;
}

type OrderCustomerLocationProps =
    OrderCustomerLocationOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & OrderCustomerLocationAsyncActions;

// ----------------
// LOCAL STATE

interface OrderCustomerLocationState {
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
    retrievingAccount: boolean;
    selectedShipTo: CustomersStore.CustomerDetail | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateOrderCustomerLocationForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};

    return errors;
}

class OrderCustomerLocation extends React.PureComponent<OrderCustomerLocationProps, OrderCustomerLocationState> {

    // ----------------
    // VARIABLES

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderCustomerLocationProps, state: OrderCustomerLocationState) {
        super(props);
        this.state = {
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            retrievingAccount: false,
            selectedShipTo: undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: OrderCustomerLocationProps) => {
        if (this.props.isSaved && prevProps.isSaving) {
            this.setState({
                changesAccepted: true,
                formResult: 'Changes saved'
            });
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        if (this.state.selectedShipTo) {
            let shipTo: string = this.state.selectedShipTo.number;
            let customerAccount: CustomersStore.CustomerAccount = cloneDeep(this.props.customerAccount);
            let index: number = customerAccount.shipToLocations.findIndex((c: CustomersStore.CustomerDetail) => {
                return c.number === shipTo;
            });
            customerAccount.shipToLocations.splice(index, 1);
            for (let n = 0; n < customerAccount.shipToLocations.length; n++) {
                customerAccount.shipToLocations[n].selected = false;
            }            
            customerAccount.shipToLocations.unshift(this.state.selectedShipTo);
            customerAccount.shipToLocations[0].selected = true;
            this.props.onDismiss(customerAccount);
        }
        else {
            this.setState({
                changesRejected: true,
                formResult: "Please select an account"
            });
        }
    }

    public render = () => {
        return (
            <Modal id="order-customer-location" isOpen={this.props.isOpen}
                onOpened={this.initializeForm.bind(this)} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(undefined)} className={this.state.retrievingAccount || this.props.isSaving || this.props.isSaved ? "disabled" : ""}>
                    Select Shipping Location
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                            <Row>
                                <Col className="pl-0 pr-0">
                                    <Field name="shipto" label="" prompt="Select..." component={FieldWrapper.renderShipToListField}
                                        options={this.props.customerAccount.shipToLocations} onSelectShipTo={this.onSelectShippingLocation} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pr-0 selected-shipto-preview">
                                    <AnimatePresence>
                                        {this.state.selectedShipTo && (
                                            <motion.div initial={{ height: 0, overflow: 'hidden' }} animate={{ height: 'auto' }} transition={{ duration: 0.25 }}>
                                                <AddressForm customerDetail={this.state.selectedShipTo} readonly={true} showLabels={true}
                                                    showPlaceholder={false} noMargin={false} />
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </Col>
                            </Row>
                            <button type="submit" ref={this.submitButton}>Submit</button>
                        </form>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0 pr-sm-3">
                                <Button type="button" color="primary" onClick={this.saveSelection} disabled={this.state.retrievingAccount || this.props.isSaving || this.props.isSaved}>
                                    {this.props.isSaving ? "Working" : "OK"}
                                </Button>
                                <Button color="link" onClick={() => this.props.onDismiss(null)} disabled={this.state.retrievingAccount || this.props.isSaving || this.props.isSaved}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0 pr-sm-3">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private initializeForm = (): void => {
        this.setState({
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            selectedShipTo: undefined
        });
        this.props.change("account", null);
    }

    private onSelectShippingLocation = (selectedShipTo: CustomersStore.CustomerDetail): void => {
        this.setState({
            selectedShipTo: selectedShipTo
        })
    }

    private resetForm = (): void => {
        this.setState({
            selectedShipTo: undefined
        });
        this.props.change("account", null);
    }

    private saveSelection = (): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        customersState: state.customers,
        messageState: state.message,
        initialValues: {}
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => dispatch(CustomersStore.actionCreators.requestCustomerDetail(clientCode, billto, shipto, updateState))
        }
    };
}

export default connect<{}, {}, OrderCustomerLocationOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'OrderCustomerLocationForm',
    validate: validateOrderCustomerLocationForm,
    enableReinitialize: true
})(OrderCustomerLocation as any));
