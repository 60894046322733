import * as React from 'react';
import { css } from '@emotion/react';
import BeatLoader from 'react-spinners/BeatLoader';

// ----------------
// PROPS

interface LoaderProps {
    isLoading: boolean;
    isChild?: boolean;
}

class Loader extends React.PureComponent<LoaderProps> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: LoaderProps) {
        super(props);
    }

    // ----------------
    // METHODS

    public render = () => {

        let style = css`
            display: block;
            left: 50%;
            position: ${this.props.isChild ? 'absolute' : 'fixed'};   // fixed
            top: ${this.props.isChild ? '50%' : '50%'};
            transform: translate(-50%,-50%);
            z-index: 1005;
        `;

        return (
            <BeatLoader loading={this.props.isLoading} css={style} />
        )
    }
}

// ----------------
// EXPORT

export default Loader;
