import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import * as OrderItemsStore from '../../store/OrderItems';
import { Client } from '../../store/Client';
import { OrderDetail, OrderSubmissionError, OrderSubmissionSummary } from '../../store/Orders';
import { OrderInvoice } from '../../store/OrderInvoice';
import { OrderItem } from '../../store/OrderItems';
import { ClientCode } from '../../enums/ClientCode';
import { OrderSubmissionErrorType } from '../../enums/OrderSubmissionErrorType';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import DetailTitleBlock from '../detail-title-block/DetailTitleBlock';
import { TitleBlock } from '../../common/ComponentTypes';
import OrderItemError from '../order-item-error/OrderItemError';
import Loader from '../loader/Loader';
import cloneDeep from 'lodash/cloneDeep';

import './SubmitOrder.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrdersStore.actionCreators)
};

interface SubmitOrderAsyncActions {
    asyncActions: {
        submitOrderAsync: (client: ClientStore.Client, orderDetail: OrderDetail) => Promise<OrdersStore.OrderSubmissionSummary | null | undefined>,
        deleteOrderItem: (clientCode: ClientCode, orderItemToDelete: OrderItem, broadcastError: boolean) => Promise<OrderInvoice | null | undefined>
    }
}

interface SubmitOrderOwnProps {
    isOpen: boolean;
    orderDetail: OrderDetail | null;
    onDismiss: (goBack: boolean) => void;
    onAbend: () => void;
    onStartNewOrder: () => void;
    onReloadOrder: () => void;
    onDeleteItem: (orderItem: OrderItem | null | undefined) => void;
    onEditItem: (orderItem: OrderItem | null | undefined) => void;
    onAddItem: () => void;
}

type SubmitOrderProps =
    SubmitOrderOwnProps
    & RouteComponentProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators    // ... plus action creators we've requested
    & SubmitOrderAsyncActions;

// ----------------
// LOCAL STATE

interface SubmitOrderState {
    submitting: boolean;
    removingItem: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    titleBlock: TitleBlock;
    actionMessage: string | null | undefined;
    actionSuccess: boolean;
    submissionSummary: OrderSubmissionSummary | null | undefined;
}

class SubmitOrder extends React.PureComponent<SubmitOrderProps, SubmitOrderState> {

    // ----------------
    // VARIABLES

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', overflow: 'visible' }
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: SubmitOrderProps, state: SubmitOrderState) {
        super(props);
        this.state = {
            submitting: true,
            removingItem: false,
            changesAccepted: false,
            changesRejected: false,
            actionMessage: undefined,
            actionSuccess: false,
            titleBlock: this.getTitleBlock(this.props.orderDetail),
            submissionSummary: undefined
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: SubmitOrderProps) => {
        if (this.isNewOrder(prevProps)) {
            this.setState({
                changesAccepted: false,
                changesRejected: false,
                titleBlock: this.getTitleBlock(this.props.orderDetail),
                submissionSummary: undefined
            });
        }
    }

    public componentWillUnmount = () => {}

    public render = () => {
        return (
            <Modal id="submit-order" isOpen={this.props.isOpen}
                onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(false)} className={this.state.submitting || this.state.removingItem ? "disabled" : ""}>
                    Submit Order
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className="order-description">
                                <DetailTitleBlock titleBlock={this.state.titleBlock} disabled={this.state.submitting} small={true} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="order-confirmation">
                                <Loader isLoading={this.state.submitting} isChild={true} />
                                {this.state.changesAccepted && (
                                    <AnimatePresence>
                                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.75 }}>
                                            <Container className="full-height">
                                                <Row>
                                                    <Col className="pl-0 pr-0 confirmation-message">
                                                        <div className="confirmation-message-wrapper">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                <polyline points="20 6 9 17 4 12"></polyline>
                                                            </svg>
                                                            <label>Order Submitted</label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </motion.div>
                                    </AnimatePresence>
                                )}
                                {this.state.changesRejected && (
                                    <AnimatePresence>
                                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.75 }}>
                                            <Container className={this.hasActionableErrors() ? "" : "full-height"}>
                                                <Row>
                                                    <Col className="pl-0 pr-0 pt-3 error-message">
                                                        {this.state.submissionSummary && (
                                                            <div className="error-message-wrapper">
                                                                <div className="error-message-text">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path strokeWidth="1" d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                                                        <line x1="12" y1="9" x2="12" y2="13"></line>
                                                                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                                                    </svg>
                                                                    <label>{this.state.submissionSummary.errorMessage}</label>
                                                                </div>
                                                                {this.state.submissionSummary.instructions && (
                                                                    <label className="error-message-instructions">{this.state.submissionSummary.instructions}</label>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                                {this.state.submissionSummary && this.hasActionableErrors() && (
                                                    <Row>
                                                        <Col className="pl-0 pr-0 pt-2 actionable-errors">
                                                            <div className={"actionable-errors-wrapper" + (this.hasSingleActionableError() ? " min-height" : "")}>
                                                                {this.state.submissionSummary.errors.map((error: OrderSubmissionError, errorIndex: number) => (
                                                                    <div className="actionable-error" key={"error-" + errorIndex}>
                                                                        {error.orderItem && (
                                                                            <OrderItemError error={error} onRemoveItem={(error: OrderSubmissionError) => this.onRemoveItem(error, errorIndex)} 
                                                                                onEditItem={(error: OrderSubmissionError) => this.props.onEditItem(error.orderItem)}
                                                                                updating={this.state.removingItem} />
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}                          
                                            </Container>
                                        </motion.div>
                                    </AnimatePresence>
                                )}
                                {this.state.removingItem && (
                                    <div className={"order-action-message" + (this.state.actionSuccess ? " success" : "")}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <polyline points="20 6 9 17 4 12"></polyline>
                                        </svg>
                                        <label>{this.state.actionMessage}</label>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <AnimatePresence>
                    {this.state.submissionSummary && (
                        <motion.div className="save-confirmation" initial={{ height: 0, overflow: 'hidden' }} animate={{ height: 'auto', overflow: 'hidden' }} exit={{ opacity: 0 }} transition={{ duration: 0.75 }}>
                            <ModalFooter>
                                <Container>
                                    <Row>
                                        <Col className="button-bar pl-0 pr-0">
                                            <AnimatePresence>
                                                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.25, delay: 0.5 }}>
                                                    <React.Fragment>
                                                        {this.state.changesAccepted && (
                                                            <div>
                                                                <Button type="button" color="primary" onClick={() => this.props.onDismiss(true)}>
                                                                    Return to Orders
                                                                </Button>
                                                                <Button color="link" onClick={() => this.props.onStartNewOrder()}>
                                                                    Start New Order
                                                                </Button>
                                                            </div>
                                                        )}
                                                        {this.state.changesRejected && (
                                                            <div>
                                                                {this.state.submissionSummary.isAlreadySubmitted && (
                                                                    <Button type="button" color="primary" onClick={() => this.props.onReloadOrder()}>
                                                                        Reload
                                                                    </Button>
                                                                )}
                                                                {this.state.submissionSummary.hasNoItems && (
                                                                    <Button type="button" color="primary" onClick={() => this.props.onAddItem()}>
                                                                        Add Item
                                                                    </Button>
                                                                )}
                                                                {this.hasActionableErrors() && (
                                                                    <Button type="button" color="primary" onClick={() => this.submitOrder()} disabled={this.state.submitting || this.state.removingItem}>
                                                                        Resubmit
                                                                    </Button>
                                                                )}
                                                                <Button color="link" onClick={() => this.props.onDismiss(false)} disabled={this.state.submitting || this.state.removingItem}>
                                                                    Cancel
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                </motion.div>
                                            </AnimatePresence>
                                        </Col>
                                    </Row>
                                </Container>
                            </ModalFooter>
                        </motion.div>
                    )}
                </AnimatePresence>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private deleteOrderItem = (error: OrderSubmissionError, errorIndex: number): void => {
        if (error.orderItem && this.props.orderDetail) {
            this.props.asyncActions.deleteOrderItem(this.props.orderDetail.client.code, error.orderItem, true)
                .then(result => {
                    if (result && this.state.submissionSummary) {
                        this.props.onDeleteItem(error.orderItem);
                        let updatedSummary: OrderSubmissionSummary = cloneDeep(this.state.submissionSummary);
                        updatedSummary.errors.splice(errorIndex, 1);
                        this.setState({
                            changesRejected: false,
                            submissionSummary: updatedSummary,
                            actionMessage: 'Item removed',
                            actionSuccess: true
                        })
                        setTimeout(() => {
                            this.setState({
                                removingItem: false
                            });
                            if (updatedSummary.errors.length === 0) {
                                setTimeout(() => {
                                    this.submitOrder();
                                })                                
                            }
                        }, 1500);
                    }
                },
                err => {
                    this.props.onDismiss(false);
                }
            )
        }
    }

    private getTitleBlock = (orderDetail: OrdersStore.OrderDetail | null | undefined): TitleBlock => {
        let titleBlock: TitleBlock = {
            header: undefined,
            title: undefined,
            subtitle: undefined,
            link: null
        };
        if (orderDetail) {
            titleBlock.header = orderDetail.orderNumber;
            titleBlock.title = orderDetail.customerAccount ? orderDetail.customerAccount.nameOnly : undefined;            
        }
        return titleBlock;
    }

    private hasActionableErrors = (): boolean => {
        return this.state.submissionSummary && this.state.submissionSummary.errors.length > 0 ? true : false;
    }

    private hasSingleActionableError = (): boolean => {
        return this.state.submissionSummary && this.state.submissionSummary.errors.length === 1 ? true : false;
    }

    private initializeForm = (): void => {       
        setTimeout(() => {
            this.submitOrder();
        }, 800);
    }

    private isNewOrder = (prevProps: SubmitOrderOwnProps): boolean => {
        let newOrder: boolean = false;
        if (this.props.orderDetail && !prevProps.orderDetail) {
            newOrder = true;
        }
        else if (this.props.orderDetail && prevProps.orderDetail) {
            newOrder = this.props.orderDetail.id != prevProps.orderDetail.id;
        }
        return newOrder;
    }

    private onRemoveItem = (error: OrderSubmissionError, errorIndex: number): void => {
        if (error.orderItem) {
            this.setState({
                removingItem: true,
                actionMessage: 'Removing item...',
                actionSuccess: false
            });
            setTimeout(() => {
                this.deleteOrderItem(error, errorIndex);
            }, 400);
        }
    }

    private resetForm = (): void => {
        this.setState({
            changesAccepted: false,
            changesRejected: false,
            submissionSummary: undefined,
            submitting: false,
            removingItem: false,
            actionMessage: undefined,
            actionSuccess: false
        })
    }

    private submitOrder = (): void => {
        if (this.props.orderDetail) {
            this.setState({
                submitting: true,
                changesAccepted: false,
                changesRejected: false
            });
            this.props.asyncActions.submitOrderAsync(this.props.orderDetail.client, this.props.orderDetail)
                .then(result => {
                    let submissionSummary: OrderSubmissionSummary = result as OrderSubmissionSummary;
                    if (submissionSummary.isInactiveUser) {
                        this.props.onAbend();
                    }
                    else {
                        this.setState({
                            submitting: false,
                            submissionSummary: submissionSummary,
                            changesAccepted: submissionSummary.submitted ? true : false,
                            changesRejected: submissionSummary.submitted ? false : true
                        });
                    }
                },
                err => {
                    this.setState({
                        submitting: false
                    })
                    this.props.onDismiss(false);
                });
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        ordersState: state.orders
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrdersStore.actionCreators
        ), dispatch),
        asyncActions: {
            submitOrderAsync: (client: ClientStore.Client, orderDetail: OrdersStore.OrderDetail) => dispatch(OrdersStore.actionCreators.submitOrder(client, orderDetail)),
            deleteOrderItem: (clientCode: ClientCode, orderItemToDelete: OrderItem, broadcastError: boolean) =>
                dispatch(OrderItemsStore.actionCreators.deleteOrderItem(clientCode, orderItemToDelete, broadcastError))
        }
    };    
}

export default connect<{}, {}, SubmitOrderOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(SubmitOrder as any);
