import * as React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

import './PasswordRules.scss';

// ----------------
// PROPS

interface PasswordRulesProps {
    input: string | null;
    showErrors: boolean;
}

// ----------------
// LOCAL STATE

interface PasswordRulesState {
    isValidLength: boolean;
    hasUpperCaseLetter: boolean;
    hasLowerCaseLetter: boolean;
    hasNumber: boolean;
}

class PasswordRules extends React.PureComponent<PasswordRulesProps, PasswordRulesState> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: PasswordRulesProps, state: PasswordRulesState) {
        super(props);
        this.state = {
            isValidLength: false,
            hasUpperCaseLetter: false,
            hasLowerCaseLetter: false,
            hasNumber: false
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.evaluateInput();        
    }

    public componentDidUpdate = () => {
        this.evaluateInput();        
    }

    public render = () => {
        return (
            <div id="password-rules">
                <div className="password-rules-wrapper">
                    <label>Password must have at least:</label>
                    <ListGroup>
                        <ListGroupItem>
                            <div className="icon">
                                {!(this.state.isValidLength || this.props.showErrors) && (
                                    <span className="placeholder">&#45;</span>
                                )}
                                {this.state.isValidLength && (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                )}
                                {this.props.showErrors && !this.state.isValidLength && (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                )}
                            </div>
                            <label>8 characters in length</label>
                        </ListGroupItem>
                        <ListGroupItem>
                            <div className="icon">
                                {!(this.state.hasUpperCaseLetter || this.props.showErrors) && (
                                    <span className="placeholder">&#45;</span>
                                )}

                                {this.state.hasUpperCaseLetter && (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                )}
                                {this.props.showErrors && !this.state.hasUpperCaseLetter && (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                )}
                            </div>
                            <label>uppercase letter (A-Z)</label>
                        </ListGroupItem>
                        <ListGroupItem>
                            <div className="icon">
                                {!(this.state.hasLowerCaseLetter || this.props.showErrors) && (
                                    <span className="placeholder">&#45;</span>
                                )}

                                {this.state.hasLowerCaseLetter && (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                )}
                                {this.props.showErrors && !this.state.hasLowerCaseLetter && (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                )}
                            </div>
                            <label>lowercase letter (a-z)</label>
                        </ListGroupItem>
                        <ListGroupItem>
                            <div className="icon">
                                {!(this.state.hasNumber || this.props.showErrors) && (
                                    <span className="placeholder">&#45;</span>
                                )}

                                {this.state.hasNumber && (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                )}
                                {this.props.showErrors && !this.state.hasNumber && (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                )}
                            </div>
                            <label>number (0-9)</label>
                        </ListGroupItem>
                    </ListGroup>
                </div>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private evaluateInput = () => {
        let input: string | null = this.props.input;
        let validLength: boolean = (input && input.length >= 8) ? true : false;
        let hasUpperCase: boolean = (input && /[A-Z]/.test(input)) ? true : false;
        let hasLowerCase: boolean = (input && /[a-z]/.test(input)) ? true : false;
        let hasNumber: boolean = (input && /[0-9]/.test(input) ? true : false);
        this.setState({
            isValidLength: validLength,
            hasUpperCaseLetter: hasUpperCase,
            hasLowerCaseLetter: hasLowerCase,
            hasNumber: hasNumber
        });
    }
}

export default PasswordRules;
