﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ClientStore from './Client';
import * as MessageStore from './Message';
import { Order, Orders } from './Orders';
import * as ErrorMessage from '../common/ErrorMessage';
import { Query, QueryResult } from 'material-table';
import { ClientCode } from '../enums/ClientCode';
import { convertSQLDateToJSDate } from '../common/DateConverter';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CustomerOrdersState {
    orders: Orders | null;
    clientCode: ClientCode;
    billTo: string | null;
    isLoading: boolean;
    isAdding: boolean;
    isDeleting: boolean;    
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestCustomerOrdersAction {
    type: 'REQUEST_CUSTOMER_ORDERS';
    clientCode: ClientCode;
    billTo: string;
    query: Query<any>;
}

interface ReceiveCustomerOrdersAction {
    type: 'RECEIVE_CUSTOMER_ORDERS';
    clientCode: ClientCode;
    billTo: string;
    orders: Orders | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = RequestCustomerOrdersAction | ReceiveCustomerOrdersAction |
    MessageStore.BroadcastMessageAction;

export const actionCreators = {
    requestCustomerOrders: (client: ClientStore.Client, billTo: string, query: Query<Order>): AppThunkAction<KnownAction> => (dispatch, getState): Promise<QueryResult<Order>> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.customerOrders && !appState.customerOrders.isLoading) {

                let clientAwareQuery: object = Object.assign({}, query, { client: client, clientCode: client.code, billTo: billTo });
                let fetchError: boolean = false;
                let action: string = 'customerorders/get';
                let url: string = `${action}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(clientAwareQuery)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_CUSTOMER_ORDERS', clientCode: client.code, billTo: billTo, orders: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                        let orders: Orders = data as Orders;
                        dispatch({ type: 'RECEIVE_CUSTOMER_ORDERS', clientCode: client.code, billTo: billTo, orders: orders });
                    },
                        err => {
                            reject(err.message);
                            if (!fetchError) {
                                dispatch({ type: 'RECEIVE_CUSTOMER_ORDERS', clientCode: client.code, billTo: billTo, orders: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                        });

                dispatch({ type: 'REQUEST_CUSTOMER_ORDERS', clientCode: client.code, billTo: billTo, query });
            }
            else {
                resolve(appState.customerOrders ?
                    appState.customerOrders.orders as QueryResult<Order> :
                    { data: [], query: query, page: query.page, totalCount: 0 } as QueryResult<Order>);
            }
        })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: CustomerOrdersState = {
    billTo: null,
    clientCode: ClientCode.Undefined,
    orders: null,
    isAdding: false,
    isDeleting: false,
    isLoading: false
}

export const reducer: Reducer<CustomerOrdersState> = (state: CustomerOrdersState | undefined, incomingAction: Action): CustomerOrdersState => {
    if (state == undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CUSTOMER_ORDERS':
            return {
                billTo: action.billTo,
                clientCode: action.clientCode,
                orders: state.orders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: true
            };
        case 'RECEIVE_CUSTOMER_ORDERS':
            return {
                billTo: action.billTo,
                clientCode: action.clientCode,
                orders: action.orders,
                isAdding: state.isAdding,
                isDeleting: state.isDeleting,
                isLoading: false
            }
        default:
            return state;
    }
}