﻿
// ----------------- 
// METHODS

export const formatNumberWithCommas = (value: number | null | undefined): string => {
    let output: string = "";
    if (value != null && value != undefined && !isNaN(value)) {
        output = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return output;
}