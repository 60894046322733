import * as React from 'react';
import { isAsyncValidating } from 'redux-form';
import { AvailableUnits, Dimension, InventoryDate, Size } from '../../common/ProductTypes';
import { QueuePlayNext } from '@material-ui/icons';
import $ from 'jquery';

import './SizeRun.scss';

// ----------------
// PROPS

interface SizeRunProps {
    chaseDate?: Date | null | undefined;
    description: string;
    dimension: Dimension | null | undefined;
    displayOnly: boolean;
    readOnly?: boolean;
    minSize?: boolean;
    shipDate?: Date | null | undefined;
    useEmbroideredDate?: boolean | undefined;
    onChangeQuantity?: (size: Size) => void;
}

// ----------------
// LOCAL STATE

interface SizeRunState {
    availableUnits: number[];
    selectedSize: Size | null | undefined;
    templateRows: number[];
    totalUnitsOrdered: number;
}

class SizeRun extends React.PureComponent<SizeRunProps, SizeRunState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: SizeRunProps, state: SizeRunState) {
        super(props);
        this.state = {
            availableUnits: this.getAvailableUnits(),
            selectedSize: undefined,
            templateRows: this.getTemplateRows(),
            totalUnitsOrdered: this.getTotalUnitsOrdered(undefined, undefined)
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: SizeRunProps) => {
        if (!this.props.readOnly) {
            let newAvailableUnits: number[] = this.getAvailableUnits();
            let oldAvailableUnits: number[] = this.state.availableUnits;
            if (newAvailableUnits.join('|') != oldAvailableUnits.join('|')) {
                this.setState({
                    availableUnits: newAvailableUnits,
                    totalUnitsOrdered: this.getTotalUnitsOrdered(undefined, undefined)
                })
            }
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <React.Fragment>
                {this.props.dimension && (
                    <React.Fragment>
                        <div className={"size-run" + (this.props.displayOnly ? " no-input" : "") + (this.props.readOnly ? " read-only" : "") + (this.props.minSize ? " min-size" : "")}>
                            <div className="size-dates">
                                <label>{this.props.description}</label>
                                {!this.props.readOnly && (
                                    <div className="datestrip">
                                        {this.state.templateRows.map((n: number) => (
                                            <React.Fragment key={"templateSpacer-" + n}>
                                                <div className="size-spacer" />
                                                {this.props.dimension && this.props.dimension.inventoryDates.map((inventoryDate: InventoryDate, inventoryDateIndex: number) => (
                                                    <div className="inventory-date" key={"id-" + n + "-" + inventoryDateIndex}>
                                                        {this.props.useEmbroideredDate ? inventoryDate.embroideredDescription : inventoryDate.description}
                                                        {this.props.useEmbroideredDate && (
                                                            <React.Fragment>
                                                                &nbsp;<sup>*</sup>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="size-values">
                                {this.props.dimension.sizes.map((size: Size, sizeIndex: number) => (
                                    <React.Fragment key={"size-value-" + sizeIndex}>
                                        {this.props.dimension && sizeIndex === (this.props.dimension.sizes.length - 1) && (
                                            <div className="last-size-value">
                                                <div className="size-value" key={"sizeValue-" + sizeIndex}>
                                                    <div className={"size-wrapper" + ((this.state.selectedSize && this.state.selectedSize.code === size.code) ? " selected" : "") + (size.quantity > 0 ? " ordered" : "") + (this.isOutOfStock(sizeIndex) ? " out-of-stock" : "") + (this.isUnavailable(sizeIndex) ? " quantity-unavailable" : "")}
                                                        onClick={(e: React.MouseEvent) => this.handleClick(e, size, sizeIndex)}>
                                                        <div className="size-spacer">
                                                            <label className="size-label">{size.code}</label>
                                                            {!this.props.displayOnly && (
                                                                <input type="text" className="form-control" maxLength={4} defaultValue={size.quantity > 0 ? size.quantity : ''} readOnly={this.props.readOnly}
                                                                    onBlur={(e: React.FocusEvent) => this.handleBlur(e, size, sizeIndex)}
                                                                    onFocus={(e: React.FocusEvent) => this.handleFocus(e, size, sizeIndex)}
                                                                    onChange={(e: React.ChangeEvent) => this.handleChange(e, size, sizeIndex)} />
                                                            )}
                                                        </div>
                                                        {!this.props.readOnly && size.unitsAvailable.map((a: AvailableUnits, unitIndex: number) => (
                                                            <div className="inventory-date" key={"si-" + size.code + '-' + unitIndex}>{a.displayUnits}</div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="size-value summary">
                                                    <div className="size-wrapper">
                                                        <div className="size-spacer">
                                                            <label className="size-label">Total</label>
                                                            {!this.props.displayOnly && (
                                                                <input type="text" className="form-control" readOnly={true} value={this.state.totalUnitsOrdered > 0 ? this.state.totalUnitsOrdered.toString() : ''} />
                                                            )}
                                                        </div>
                                                        {!this.props.readOnly && this.props.dimension.unitsAvailable.map((a: AvailableUnits, unitIndex: number) => (
                                                            <div className="inventory-date" key={"total-" + unitIndex}>{a.displayUnits}</div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {this.props.dimension && sizeIndex < (this.props.dimension.sizes.length - 1) && (
                                            <div className="size-value" key={"sizeValue-" + sizeIndex}>
                                                <div className={"size-wrapper" + ((this.state.selectedSize && this.state.selectedSize.code === size.code) ? " selected" : "") + (size.quantity > 0 ? " ordered" : "") + (this.isOutOfStock(sizeIndex) ? " out-of-stock" : "") + (this.isUnavailable(sizeIndex) ? " quantity-unavailable" : "")}
                                                    onClick={(e: React.MouseEvent) => this.handleClick(e, size, sizeIndex)}>
                                                    <div className="size-spacer">
                                                        <label className="size-label">{size.code}</label>
                                                        {!this.props.displayOnly && (
                                                            <input type="text" className="form-control" maxLength={4} value={size.quantity > 0 ? size.quantity : ''} readOnly={this.props.readOnly}
                                                                onBlur={(e: React.FocusEvent) => this.handleBlur(e, size, sizeIndex)}
                                                                onFocus={(e: React.FocusEvent) => this.handleFocus(e, size, sizeIndex)}
                                                                onChange={(e: React.ChangeEvent) => this.handleChange(e, size, sizeIndex)} />
                                                        )}
                                                    </div>
                                                    {!this.props.readOnly && size.unitsAvailable.map((a: AvailableUnits, unitIndex: number) => (
                                                        <div className="inventory-date" key={"si-" + size.code + '-' + unitIndex}>{a.displayUnits}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        {this.props.useEmbroideredDate && (
                            <div className="size-run-notes">
                                <sup>*</sup> includes lead time for embroidery
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }

    // ----------------
    // HELPERS 

    private getAvailableUnits = (): number[] => {
        let availableUnits: number[] = [];
        if (!this.props.readOnly && this.props.shipDate) {
            let cutoff: Date = this.props.shipDate as Date;
            if (this.props.dimension) {
                let inventoryDates: InventoryDate[] = this.props.dimension.inventoryDates;
                let eligibleDates: InventoryDate[] = inventoryDates.filter((d: InventoryDate) => {
                    if (this.props.useEmbroideredDate) {
                        return d.embroideredDate.valueOf() <= cutoff.valueOf();
                    }
                    else {
                        return d.date.valueOf() <= cutoff.valueOf();
                    }
                })
                if (eligibleDates.length > 0) {
                    let maxDate: Date = eligibleDates[eligibleDates.length - 1].date;
                    for (let s: number = 0; s < this.props.dimension.sizes.length; s++) {
                        let size: Size = this.props.dimension.sizes[s];
                        let sizeUnits: AvailableUnits[] = size.unitsAvailable.filter((a: AvailableUnits) => {
                            return a.inventoryDate.date.valueOf() === maxDate.valueOf();
                        })
                        availableUnits.push(sizeUnits.length > 0 ? sizeUnits[0].units : 0);
                    }
                }
            }
        }
        return availableUnits;
    }

    private getTemplateRows = () => {
        let templateRows: number[] = [];
        for (let n: number = 0; n < 20; n++) {
            templateRows.push(n);
        }
        return templateRows;
    }

    private getTotalUnitsOrdered = (updateSize: Size | undefined, updateQuantity: number | undefined): number => {
        let totalUnits: number = 0;
        if (this.props.dimension) {
            for (let sz: number = 0; sz < this.props.dimension.sizes.length; sz++) {
                let size: Size = this.props.dimension.sizes[sz];
                if (updateSize && updateSize.code === size.code && updateQuantity != undefined) {
                    totalUnits += updateQuantity;
                }
                else {
                    totalUnits += size.quantity;
                }
            }
        }
        return totalUnits;
    }

    private getUnitsOnHand = (index: number): number => {
        let units: number = 0;
        if (this.state.availableUnits.length > index) {
            units = this.state.availableUnits[index];
        }
        return units;
    }

    private handleBlur = (event: any, size: Size, index: number): void => {
        if (!this.props.readOnly) {
            let quantity: number = event.target.value ? parseInt(event.target.value.replace(/\D/g, '')) : 0;
            if (quantity === 0) {
                event.target.value = '';
            }
            if (this.state.selectedSize && this.state.selectedSize.code === size.code) {
                this.setState({
                    selectedSize: undefined
                });
            }
        }
    }

    private handleChange = (event: any, size: Size, index: number): void => {
        if (!this.props.readOnly) {
            let quantity: number = parseInt(event.target.value.replace(/\D/g, ''));
            if (event.target.value !== quantity.toString()) {
                event.target.value = isNaN(quantity) ? '' : quantity.toString();
            }
            quantity = isNaN(quantity) ? 0 : quantity;

            let unitsOnHand: number = this.getUnitsOnHand(index);
            let totalUnitsOrdered: number = this.getTotalUnitsOrdered(size, quantity);

            if (this.props.dimension && this.props.dimension.sizes[index].quantity != quantity) {
                if (this.props.onChangeQuantity) {
                    size.quantity = quantity;
                    this.props.onChangeQuantity(size);
                }
            }

            if (totalUnitsOrdered != this.state.totalUnitsOrdered) {
                this.setState({
                    totalUnitsOrdered: totalUnitsOrdered
                });
            }
        }
    }

    private handleClick = (event: any, size: Size, index: number): void => {
        if (!this.props.displayOnly && !this.props.readOnly) {
            let target: JQuery<any> = $(event.target);
            if (target.length > 0 && target[0].tagName != 'INPUT') {
                let wrapper: JQuery<any> = target.closest('.size-wrapper');
                if (wrapper.length > 0) {
                    let input: JQuery<any> = wrapper.find(':text:visible');
                    setTimeout(() => {
                        input.trigger('focus').triggerHandler('focus');
                    });
                }
            }
        }
    }

    private handleFocus = (event: any, size: Size, index: number): void => {
        event.stopPropagation();
        if (!this.props.readOnly) {
            let quantity: number = parseInt(event.target.value.replace(/\D/g, ''));
            if (quantity === 0) {
                event.target.value = '';
            }
            else {
                event.target.select();
            }
            this.setState({
                selectedSize: size
            });
        }
    }

    private isOutOfStock = (index: number): boolean => {
        let outOfStock: boolean = false;
        if (!this.props.displayOnly && !this.props.readOnly) {
            let unitsOnHand: number = this.getUnitsOnHand(index);
            outOfStock = unitsOnHand < 1 ? true : false;
            //if (outOfStock && this.props.chaseDate) {
            //    outOfStock = false;
            //}
        }
        return outOfStock;
    }

    private isUnavailable = (index: number): boolean => {
        let unavailable: boolean = false;
        if (!this.props.displayOnly && !this.props.readOnly && this.props.dimension) {
            let unitsOnHand: number = this.getUnitsOnHand(index);
            let unitsOrdered: number = this.props.dimension.sizes[index].quantity;
            unavailable = unitsOnHand > 0 && unitsOrdered > unitsOnHand;
        }
        return unavailable;
    }
}

// ----------------
// EXPORT

export default SizeRun;
