import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import AddOrderForm from '../add-order-form/AddOrderForm';
import FormResult from '../form-result/FormResult';

import './AddOrder.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
};

interface AddOrderOwnProps {
    isOpen: boolean;
    customerNumber?: string | null;
    onDismiss: (id: number | null | undefined) => void;    
}

type AddOrderProps =
    RouteComponentProps
    & AddOrderOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface AddOrderState {
    submitting: boolean;
    retrievingAccount: boolean;
    changesAccepted: boolean,
    changesRejected: boolean,
    formResult: string | null;
    submitRequested: boolean;
}

class AddOrder extends React.PureComponent<AddOrderProps, AddOrderState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: AddOrderProps, state: AddOrderState) {
        super(props);
        this.state = {
            submitting: false,
            retrievingAccount: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            submitRequested: false
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
        if (this.state.submitting) {
            if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
                this.props.onDismiss(null);
            }
            else if (this.receivedValidationError()) {
                let formResult: string | null = this.getFormResult();
                setTimeout(() => {
                    this.props.actions.clearMessage();
                    this.setState({
                        submitting: false,
                        submitRequested: false,
                        changesRejected: true,
                        formResult: formResult
                    });
                })
            }
            else if (this.receivedActionCompleteMessage()) {
                let formResult: string | null = this.getFormResult();
                setTimeout(() => {
                    this.props.actions.clearMessage();
                    this.setState({
                        submitting: false,
                        submitRequested: false,
                        changesRejected: false,
                        changesAccepted: true,
                        formResult: formResult
                    });
                    setTimeout(() => {
                        this.props.onDismiss(this.props.ordersState.newOrder ? this.props.ordersState.newOrder.id : null);
                    }, 1500);
                })
            }
        }
        else if (this.receivedErrorMessage()) {
            this.props.onDismiss(null);
        }
    }

    public componentWillUnmount = () => {        
    }

    public render = () => {
        return (
            <Modal id="add-order" isOpen={this.props.isOpen}
                onOpened={this.initializeForm.bind(this)} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(null)} className={(this.state.retrievingAccount || this.state.submitting) ? "disabled" : ""}>
                    Start Order
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className="pl-0 pr-0">
                                <AddOrderForm submitRequested={this.state.submitRequested} onSubmit={this.onFormSubmit}
                                    onSubmitFail={this.onFormSubmitFail} onRetrievingAccount={this.onRetrievingAccount}
                                    onChange={this.onFormChange} customerNumber={this.props.customerNumber} />
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0 pr-sm-3">
                                <Button type="button" color="primary" disabled={this.state.retrievingAccount || this.state.submitting || this.state.changesAccepted} onClick={this.requestSubmit}>
                                    {this.state.submitting ? "Working" : "Continue"}
                                </Button>
                                <Button color="link" onClick={() => this.props.onDismiss(null)} disabled={this.state.retrievingAccount || this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0 pr-sm-3">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private getFormResult = (): string | null => {
        let formResult: string | null = null;
        if (this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) {
                formResult = this.props.messageState.message.text || 'Form contains invalid entries';
            }
            else if (this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) {
                formResult = 'Changes saved';
            }
        }
        return formResult;
    }

    private initializeForm = (): void => {
    }

    private onFormChange = (): void => {
        this.setState({
            formResult: null
        });
    }

    private onFormSubmit = (submitting: boolean): void => {
        this.setState({
            formResult: null,
            submitRequested: false,
            submitting: submitting
        });
    }

    private onFormSubmitFail = (): void => {
        this.setState({
            formResult: null,
            submitRequested: false
        });
    }

    private onRetrievingAccount = (retrieving: boolean): void => {
        this.setState({
            retrievingAccount: retrieving
        });
    }

    private receivedActionCompleteMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) ?
            true : false;
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private requestSubmit = (): void => {
        this.setState({
            submitRequested: true
        });
    }

    private resetForm = (): void => {
        setTimeout(() => {
            this.setState({
                retrievingAccount: false,
                submitting: false,
                submitRequested: false,
                changesAccepted: false,
                changesRejected: false
            })
        }, 400);
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        ordersState: state.orders
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, AddOrderOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(AddOrder as any);
