import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as CustomersStore from '../../store/Customers';
import * as OrdersStore from '../../store/Orders';
import * as ProductlistsStore from '../../store/Productlists';
import * as ProductlistItemsStore from '../../store/ProductlistItems';
import { Client } from "../../store/Client";
import { CustomerAccount } from '../../store/Customers';
import { Product, ProductDetail } from '../../store/Products';
import { NewOrderItem } from '../../store/OrderItems';
import { NewProductlistItem } from '../../store/ProductlistItems';
import { EmbroiderySpecification, Logo } from '../../common/EmbroideryTypes';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import AddProductlistForm from '../add-productlist-form/AddProductlistForm';
import ProductlistFinder, { ProductlistName } from '../productlist-finder/ProductlistFinder';
import TapeSelector from '../tape-selector/TapeSelector';
import ImageLoader from '../image-loader/ImageLoader';
import Loader from '../loader/Loader';
import cloneDeep from 'lodash/cloneDeep';
import { toTitleCase } from '../../common/StringFormatter';
import $ from 'jquery';

import './AddProductlistItem.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    customersState: {} as CustomersStore.CustomersState,
    ordersState: {} as OrdersStore.OrdersState,
    productlistsState: {} as ProductlistsStore.ProductlistsState
}

const actionCreators = {
    actions: Object.assign({}, ProductlistsStore.actionCreators, MessageStore.actionCreators)
}

interface AddProductlistItemAsyncActions {
    asyncActions: {
        addProductlistItemAsync: (client: Client, newProductlistItem: NewProductlistItem) => Promise<NewProductlistItem | null | undefined>,
        requestProductlistDetailAsync: (client: Client, id: number) => Promise<ProductlistsStore.ProductlistDetail | null | undefined>
    }
}

interface AddProductlistItemOwnProps {
    isOpen: boolean;
    client: Client;
    customer: CustomerAccount | null | undefined;
    newOrderItem: NewOrderItem;
    productDetail: ProductDetail;
    onDismiss: (id: number | null | undefined) => void;
    onGoBack: () => void;
}

type AddProductlistItemProps =
    RouteComponentProps
    & AddProductlistItemOwnProps
    & InjectedFormProps    
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & AddProductlistItemAsyncActions;

// ----------------
// LOCAL STATE

interface AddProductlistItemState {
    submitting: boolean;
    retrieving: boolean;
    retrievingProductlist: boolean;
    changingLogo: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    submitNewProductlistRequested: boolean;
    formResult: string | null;
    actions: FieldWrapper.OptionValue[];
    selectedAction: number;
    updateAction: number;
    newProductlist: ProductlistsStore.BaseProductlist | null | undefined;
    excludeEmbroidery: boolean;
    replaceEmbroidery: Logo | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateAddProductlistItemForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (formValues.action === 0) {
        errors.action = 'Select action';
    }
    else if (formValues.action && parseInt(formValues.action) === 1) {
        if (!formValues.selectedProductlistId) {
            errors.productlist = 'Select list';
        }
    }
    return errors;
}

class AddProductlistItem extends React.PureComponent<AddProductlistItemProps, AddProductlistItemState> {

    // ----------------
    // VARIABLES

    public collapsibleActionBarVariants = {
        hidden: { height: 0, overflow: 'hidden', paddingBottom: '0', paddingTop: '0' },
        visible: { height: 'auto', overflow: 'visible', paddingBottom: '10px', paddingTop: '20px' }
    }

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', overflow: 'visible' }
    }

    public collapsibleFooterVariants = {
        hidden: { height: 0, overflow: 'hidden', width: '100%' },
        visible: { height: 'auto', overflow: 'visible', width: '100%' }
    }

    public confirmationMessageVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    public popupVariants = {
        hidden: { left: '50%', top: '50%', height: 0, width: 0, zIndex: -1 },
        visible: { left: '10px', top: '10px', height: '500px', width: 'calc(100% - 20px)', zIndex: 105}
    }

    public popupButtonBarVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: AddProductlistItemProps, state: AddProductlistItemState) {
        super(props);
        this.state = {
            submitting: false,
            retrieving: false,
            retrievingProductlist: false,
            changingLogo: false,
            changesAccepted: false,
            changesRejected: false,
            submitNewProductlistRequested: false,
            formResult: null,
            actions: this.getActions(),
            selectedAction: this.isOpenList() ? 3 : 0,
            updateAction: 0,
            newProductlist: undefined,
            excludeEmbroidery: false,
            replaceEmbroidery: undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: AddProductlistItemProps) => {
        if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
            this.props.onDismiss(undefined);
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        if (values.selectedProductlistId && this.selectedProductlistInStore(values.selectedProductlistId as number)) {
            this.setState({
                retrievingProductlist: false,               
                selectedAction: 3,
                updateAction: this.getUpdateAction()
            });
            setTimeout(() => {
                this.addProductlistItem();
            }, 400);
        }
        else {
            switch (parseInt(values.action)) {
                case 1:
                    this.retrieveProductlist(values.selectedProductlistId as number);                
                    break;
                case 2:
                    if (values.selectedProductlistId) {
                        this.retrieveProductlist(values.selectedProductlistId as number);
                    }
                    else {
                        this.setState({
                            submitNewProductlistRequested: true
                        });
                    }
                    break;
            }
        }
    }

    public render = () => {
        return (
            <Modal id="add-productlist-item" isOpen={this.props.isOpen} className={this.state.changingLogo ? "shaded" : ""} onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(null)} className={((this.state.retrieving || this.state.submitting || this.state.changingLogo) ? "disabled" : "")}>
                    Add to List
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                            <Row>
                                <Col className={"collapsible-row pl-3 action-bar" + (this.props.productlistsState.productlistDetail ? " pr-3 text-left" : " pr-4 text-center")}>
                                    <motion.div animate={this.state.selectedAction >= 3 ? "hidden" : "visible"} initial={this.state.selectedAction >= 3 ? "hidden" : "visible"}
                                        variants={this.collapsibleActionBarVariants} transition={{ duration: (this.state.selectedAction >= 3 ? 0 : 0.25) }}>
                                        <Field name="action" type="text" label="" component={FieldWrapper.renderRadioButtonField}
                                            options={this.state.actions} onChange={this.onSelectAction}
                                            disabled={this.state.retrieving || this.state.retrievingProductlist || this.state.submitting || this.state.changesAccepted} />                                        
                                        <button type="submit" ref={this.submitButton}>Submit</button>
                                    </motion.div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="collapsible-row pl-0 pr-0">
                                    <motion.div animate={this.state.selectedAction === 1 ? "visible" : "hidden"} initial={this.state.selectedAction === 1 ? "visible" : "hidden"}
                                        variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction !== 1 ? 0 : 0.25), delay: (this.state.selectedAction === 1 ? 0.25 : 0) }}>
                                        <Container className="component-wrapper flush-bottom">
                                            <Row>
                                                <Col>
                                                    <label className="title">Available Lists</label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="productlist-finder-wrapper">
                                                    <Field name="productlist" type="text" label="" editableListsOnly={true}
                                                        component={FieldWrapper.renderProductlistFinderField}                                                        
                                                        onSelectProductlist={this.onSelectProductlist}
                                                        resetFinder={this.state.selectedAction === 1 ? true : false}
                                                        disabled={this.state.retrieving || this.state.retrievingProductlist || this.state.submitting || this.state.changesAccepted} />
                                                    <Field name="selectedProductlistId" component="input" type="hidden" />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </motion.div>
                                </Col>
                            </Row>
                        </form>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0 add-productlist">
                                <motion.div animate={this.state.selectedAction === 2 ? "visible" : "hidden"} initial={this.state.selectedAction === 2 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction !== 2 ? 0 : 0.25), delay: (this.state.selectedAction === 2 ? 0.25 : 0) }}
                                    onAnimationComplete={this.onAddProductListExpand}>
                                    <Container className="component-wrapper">
                                        <AddProductlistForm submitRequested={this.state.submitNewProductlistRequested}
                                            customerAccount={this.getCurrentCustomer()}
                                            onSubmit={this.onAddProductlistFormSubmit}
                                            onSubmitFail={this.onAddProductlistFormSubmitFail}
                                            onSubmitSucceed={this.onAddProductlistFormSubmitSucceed}
                                            disabled={this.state.submitting || this.state.changesAccepted}
                                            resetForm={this.state.selectedAction === 2} />
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0">
                                <motion.div animate={this.state.selectedAction >= 3 ? "visible" : "hidden"}
                                    initial={this.state.selectedAction >= 3 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction !== 3 ? 0 : 0.25), delay: (this.state.selectedAction === 3 ? 0.25 : 0) }}>
                                    <Container className="component-wrapper borderless">
                                        <Row>
                                            <Col className="productlist-item-description">
                                                <div className="image">
                                                    <div className="productlist-item-image">
                                                        <div className="image-stretcher">
                                                            <div className="image-canvas">
                                                                <ImageLoader url={this.props.productDetail.imageUrl} isLoading={false} noFadeIn={false} thumbnail={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text">
                                                    <label className="style-code">
                                                        {this.props.productDetail.style.sku ? "" : "Style "}
                                                        {this.props.productDetail.style.sku ? this.props.productDetail.style.sku : this.props.productDetail.style.code}
                                                    </label>
                                                    <label className="category">{this.props.productDetail.shortDescription}</label>
                                                    <h4 className="style-name">{this.props.productDetail.style.name}</h4>
                                                    <div className="two-column">
                                                        <label className="price">${this.props.productDetail.wholesale.toFixed(2)}</label>
                                                        <label className="color">{this.getColorDescription()}</label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row> 
                                            <Col className="collapsible-row pl-0 pr-0 productlist-item-confirmation">
                                                <motion.div animate={this.state.updateAction > 0 ? "hidden" : "visible"} initial={this.state.updateAction > 0 ? "hidden" : "visible"}
                                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.updateAction > 0 ? 0 : 0.25), delay: (this.state.updateAction === 0 ? 0.25 : 0) }}>
                                                    <Loader isLoading={this.state.submitting} isChild={true} />
                                                    <motion.div animate={this.state.changesAccepted ? "visible" : "hidden"} initial={"hidden"} variants={this.confirmationMessageVariants} transition={{ duration: 0.25 }}>
                                                        <React.Fragment>
                                                            <div className="confirmation-message">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                    <polyline points="20 6 9 17 4 12"></polyline>
                                                                </svg>
                                                                <label>Added to list {this.props.productlistsState.productlistDetail ? this.props.productlistsState.productlistDetail.name : ""}</label>
                                                            </div>
                                                            <div className="button-bar">                                                                
                                                                <Button color="primary" onClick={this.goBack}>
                                                                    Continue
                                                                </Button>
                                                                <Button color="link" onClick={this.goToProductList}>
                                                                    View List
                                                                </Button>
                                                            </div>
                                                        </React.Fragment>
                                                    </motion.div>
                                                </motion.div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="collapsible-row pl-0 pr-0 pt-0 pb-0 productlist-item-issue">
                                                <motion.div animate={this.state.updateAction !== 1 ? "hidden" : "visible"} initial={this.state.updateAction !== 1 ? "hidden" : "visible"}
                                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.updateAction !== 1 ? 0 : 0.25), delay: (this.state.updateAction === 1 ? 0.25 : 0) }}>
                                                    <div className="issue-description">
                                                        <div className="icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle">
                                                                <circle cx="12" cy="12" r="10"></circle>
                                                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                                            </svg>
                                                        </div>
                                                        <div className="text">
                                                            <span>Adding item with embroidery automatically links</span>
                                                            <span>product list to current customer</span>
                                                        </div>
                                                    </div>
                                                    <div className="issue-resolution">
                                                        <Button type="button" color="primary" onClick={() => this.confirmAddProductlistItem(true)}>
                                                            Add with Logo
                                                        </Button>
                                                        <Button color="link" onClick={() => this.confirmAddProductlistItem(false)}>
                                                            Add without Logo
                                                        </Button>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="collapsible-row pl-0 pr-0 productlist-item-issue">
                                                <motion.div animate={this.state.updateAction !== 2 ? "hidden" : "visible"} initial={this.state.updateAction !== 2 ? "hidden" : "visible"}
                                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.updateAction !== 2 ? 0 : 0.25), delay: (this.state.updateAction === 2 ? 0.25 : 0) }}>
                                                    <div className="issue-description">
                                                        <div className="icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle">
                                                                <circle cx="12" cy="12" r="10"></circle>
                                                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                                            </svg>
                                                        </div>
                                                        <div className="text">
                                                            <span>Embroidery selections not valid for current list:</span>
                                                            <span>logo belongs to different customer</span>
                                                        </div>
                                                    </div>
                                                    <div className="issue-resolution">
                                                        <Button type="button" color="primary" onClick={this.changeProductlistItemLogo}>
                                                            Change Logo
                                                        </Button>
                                                        <Button color="link" onClick={() => this.confirmAddProductlistItem(false)}>
                                                            Add without Logo
                                                        </Button>
                                                    </div>
                                                </motion.div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>
                    </Container>
                    <motion.div className="tape-selector" animate={this.state.changingLogo ? "visible" : "hidden"} initial={"hidden"}
                        variants={this.popupVariants} transition={{ duration: 0.5 }}>
                        <div className="popup">
                            <div className="popup-content">
                                {this.props.productlistsState.productlistDetail && this.props.productlistsState.productlistDetail.customerAccount && (
                                    <TapeSelector isOpen={this.state.changingLogo} client={this.props.client}
                                        customerAccount={this.props.productlistsState.productlistDetail.customerAccount}
                                        onSelect={this.hideTapeSelector} />
                                )}
                            </div>
                            <motion.div className="popup-button-bar" animate={this.state.changingLogo ? "visible" : "hidden"} initial={"hidden"}
                                variants={this.popupButtonBarVariants} transition={{ duration: 0.5, delay: 0.8 }}>
                                <Button color="secondary" onClick={() => this.hideTapeSelector(undefined)} disabled={!this.state.changingLogo}>
                                    Close
                                </Button>
                            </motion.div>
                        </div>
                    </motion.div>
                </ModalBody>
                <ModalFooter className={this.state.selectedAction >= 3 ? "collapsed" : ""}>
                    <motion.div animate={this.state.selectedAction >= 3 ? "hidden" : "visible"} initial={this.state.selectedAction >= 3 ? "hidden" : "visible"}
                        variants={this.collapsibleFooterVariants} transition={{ duration: (this.state.selectedAction === 0 || this.state.selectedAction === 4) ? 0 : 0.25 }}>
                        <Container>
                            <Row>
                                <Col className="button-bar pl-0 pr-0">
                                    <Button type="button" color="primary" onClick={this.submitAddProductlistItemForm}
                                        disabled={this.state.retrieving || this.state.retrievingProductlist || this.state.submitting || this.state.changesAccepted}>
                                        {this.state.retrievingProductlist || this.state.submitting ? "Working..." : "Continue"}
                                    </Button>
                                    <Button color="link" onClick={() => this.props.onDismiss(null)}
                                        disabled={this.state.retrieving || this.state.retrievingProductlist || this.state.submitting || this.state.changesAccepted}>
                                        Cancel
                                    </Button>
                                </Col>
                                </Row>
                            <Row>
                                <Col className="pl-0 pt-4 pr-0">
                                    <FormResult
                                        failureResult={this.state.changesRejected}
                                        successResult={this.state.changesAccepted}
                                        description={this.state.formResult} />
                                </Col>
                            </Row>
                            </Container>
                    </motion.div>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private addProductlistItem = (): void => {
        if (this.state.updateAction === 0) {
            this.setState({
                submitting: true
            });
            let productlistItem: NewProductlistItem | undefined = this.getNewProductlistItem();
            if (productlistItem) {
                this.props.asyncActions.addProductlistItemAsync(this.props.client, productlistItem)
                    .then(result => {
                        this.setState({
                            submitting: false,
                            changesAccepted: true
                        });
                    },
                    err => {
                        this.props.onDismiss(null);
                    }
                )
            }
            else {
                this.setState({
                    submitting: false
                });
                this.props.actions.broadcastMessage({
                    messageType: MessageStore.MessageType.ERROR,
                    text: 'New productlist item is not defined',
                    action: 'addProductlistItem',
                    reference: null
                });
                this.props.onDismiss(null);
            }
        }
    }

    private changeProductlistItemLogo = (): void => {
        this.setState({
            changingLogo: true
        });
    }

    private confirmAddProductlistItem = (withEmbroidery: boolean): void => {
        this.setState({
            updateAction: 0,
            excludeEmbroidery: withEmbroidery ? false : true
        });
        setTimeout(() => {
            this.addProductlistItem();
        })
    }

    private getActions = (): FieldWrapper.OptionValue[] => {
        let actions: FieldWrapper.OptionValue[] = [];
        actions.push({ label: 'Use Existing List', value: 1 });
        actions.push({ label: 'Start New List', value: 2 });
        return actions;
    }

    private getColorDescription = (): string => {
        let color: string | null | undefined = this.props.productDetail.color.name;
        if (color) {
            if (color != 'PATTERNED') {
                color = toTitleCase(color);
            }
            else {
                color = "";
            }
        }
        else {
            color = this.props.productDetail.color.code;
        }
        return color || "";
    }

    private getCurrentCustomer = (): CustomersStore.CustomerAccount | null | undefined => {
        let currentCustomer: CustomersStore.CustomerAccount | null | undefined = this.props.customersState.customerDetail;
        if (!currentCustomer && this.props.ordersState.orderDetail) {
            currentCustomer = this.props.ordersState.orderDetail.customerAccount;
        }
        return currentCustomer;
    }

    private getNewProductlistItem = (): NewProductlistItem | undefined => {
        let productlistItem: NewProductlistItem | undefined = undefined;
        if (this.props.productlistsState.productlistDetail) {
            let newOrderItem: NewOrderItem = cloneDeep(this.props.newOrderItem);
            productlistItem = {
                client: newOrderItem.client,
                customerNumber: this.props.productlistsState.productlistDetail.customerNumber,
                dimension: newOrderItem.dimension,
                embroiderySpecifications: newOrderItem.embroiderySpecifications,
                product: newOrderItem.product,
                productlistId: this.props.productlistsState.productlistDetail.id as number
            };
            if (this.state.replaceEmbroidery) {
                for (let n: number = 0; n < productlistItem.embroiderySpecifications.length; n++) {
                    productlistItem.embroiderySpecifications[n].logo = this.state.replaceEmbroidery;
                }
            }
        }
        return productlistItem;
    }

    private getUpdateAction = (): number => {
        let updateAction: number = 0;
        if (this.props.productlistsState.productlistDetail) {
            let listAccount: string | null | undefined = this.props.productlistsState.productlistDetail.billto;
            if (this.props.newOrderItem.embroiderySpecifications.length > 0) {
                if (!listAccount) {
                    updateAction = 1;
                }
                else {
                    let logoAccount: string = this.state.replaceEmbroidery ? listAccount : '';
                    if (!logoAccount && this.props.newOrderItem.embroiderySpecifications[0].logo) {
                        logoAccount = this.props.newOrderItem.embroiderySpecifications[0].logo.customerNumber.split('-')[0];
                    }
                    else if (this.props.customersState.customerDetail) {
                        logoAccount = this.props.customersState.customerDetail.number.split('-')[0];
                    }
                    if (logoAccount != listAccount) {
                        updateAction = 2;
                    }
                }
            }
        }
        return updateAction;
    }

    private goBack = (): void => {
        this.props.onGoBack();
    }

    private goToProductList = (): void => {
        this.props.onDismiss(this.props.productlistsState.productlistDetail ?
            this.props.productlistsState.productlistDetail.id : null);
    }

    private hideTapeSelector = (logo: Logo | undefined): void => {
        this.setState({
            changingLogo: false,
            replaceEmbroidery: logo,
            updateAction: logo ? 0 : this.state.updateAction
        });
        if (logo) {
            this.addProductlistItem();
        }
    }

    private initializeForm = (): void => {
        let selectedAction: number = this.isOpenList() ? 4 : 0;
        let updateAction: number = this.getUpdateAction();
        this.setState({
            submitting: false,
            retrieving: false,
            retrievingProductlist: false,
            changingLogo: false,
            actions: this.getActions(),
            selectedAction: selectedAction,
            updateAction: updateAction
        });
        this.props.change("action", selectedAction);
        if (this.isOpenList()) {
            setTimeout(() => {
                this.addProductlistItem();
            }, 400);
        };
    }

    private isOpenList = (): boolean => {
        return (this.props.productlistsState.productlistDetail ? true : false);
    }

    private onAddProductListExpand = (): void => {
        setTimeout(() => {
            $('.collapsible-row.add-productlist').css({ 'overflow': this.state.selectedAction === 2 ? 'visible' : 'hidden' });
        }, 400);
    }

    onAddProductlistFormChange = (): void => {
        this.setState({
            formResult: null
        });
    }

    onAddProductlistFormSubmit = (submitting: boolean): void => {
        this.setState({
            formResult: null,
            submitNewProductlistRequested: false,
            submitting: submitting
        });
    }

    onAddProductlistFormSubmitFail = (): void => {
        this.setState({
            formResult: null,
            submitNewProductlistRequested: false
        });
    }

    onAddProductlistFormSubmitSucceed = (newList: ProductlistsStore.BaseProductlist): void => {
        this.setState({
            newProductlist: newList,
        });
        this.props.change('selectedProductlistId', newList.id as number);
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        });
    }

    private onSelectAction = (event: React.ChangeEvent): void => {
        let selectedValue = event.target.getAttribute("value");
        if (selectedValue && parseInt(selectedValue) != this.state.selectedAction) {
            this.setState({
                selectedAction: parseInt(selectedValue)
            });
            this.props.reset();
            switch (parseInt(selectedValue)) {
                case 1:
                    this.setFocusOnProductlistFinder();
                    break;
                case 2:
                    this.setFocusOnAddProductlistForm();
                    break;
            }
        }
    }

    private onSelectProductlist = (productlist: ProductlistName | null): void => {
        this.props.change('selectedProductlistId', productlist ? parseInt(productlist.number) : null);
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private resetForm = (): void => {
        this.setState({
            submitting: false,
            retrieving: false,
            retrievingProductlist: false,
            changingLogo: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            selectedAction: 0
        });
        this.props.reset();
    }

    private retrieveProductlist = (productlistId: number): void => {
        this.setState({
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            retrievingProductlist: true
        });
        this.props.asyncActions.requestProductlistDetailAsync(this.props.client, productlistId)
            .then(result => {
                if (this.submitButton.current) {
                    this.submitButton.current.click();
                }
            },
            err => {
            });        
    }

    private selectedProductlistInStore = (productlistId: number): boolean => {
        let listInStore: boolean = false;
        if (this.props.productlistsState.productlistDetail) {
            listInStore = (this.props.productlistsState.productlistDetail.id === productlistId);
        }
        return listInStore;
    }

    private setFocusOnAddProductlistForm = (): void => {
        let productlistNameInput: JQuery<Element> = $('#add-productlist-form :text[name="name"]');
        if (productlistNameInput.length > 0) {
            setTimeout(() => {
                productlistNameInput.focus();
            })
        }
        else if (this.state.selectedAction === 2) {
            setTimeout(() => {
                this.setFocusOnAddProductlistForm();
            }, 250);
        }
    }

    private setFocusOnProductlistFinder = (): void => {
        let productlistFinder: JQuery<Element> = $('#productlist-finder .rbt-input');
        if (productlistFinder.length > 0) {
            setTimeout(() => {
                productlistFinder.focus();
            }, 250);
        }
        else if (this.state.selectedAction === 1) {
            setTimeout(() => {
                this.setFocusOnProductlistFinder();
            }, 250);
        }
    }

     private submitAddProductlistItemForm = (): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        customersState: state.customers,
        ordersState: state.orders,
        productlistsState: state.productlists
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            ProductlistsStore.actionCreators,
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
            addProductlistItemAsync: (client: Client, newProductlistItem: NewProductlistItem) =>
                dispatch(ProductlistItemsStore.actionCreators.addProductlistItem(client, newProductlistItem)),
            requestProductlistDetailAsync: (client: Client, id: number) =>                
                dispatch(ProductlistsStore.actionCreators.requestProductlistDetail(client, id))
        }
    };
}

export default connect<{}, {}, AddProductlistItemOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'addProductlistItemForm',
    validate: validateAddProductlistItemForm,
    enableReinitialize: true
})(AddProductlistItem as any));

