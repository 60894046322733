import * as React from 'react';
import { motion } from 'framer-motion';
import InnerImageZoom from 'react-inner-image-zoom';
import $ from 'jquery';

import './ImageLoader.scss';

// ----------------
// PROPS

interface ImageLoaderProps {
    isLoading: boolean;
    url: string | null | undefined;
    thumbnail?: boolean | null | undefined;
    useMagnifier?: boolean | null | undefined;
    noFadeIn?: boolean | null | undefined;
    autoSize?: boolean | null | undefined;
    onLoad?: () => void;
}

// ----------------
// LOCAL STATE

interface ImageLoaderState {
    loaded: boolean;
    missing: boolean;
}

class ImageLoader extends React.PureComponent<ImageLoaderProps, ImageLoaderState> {

    // ----------------
    // VARIABLES

    public overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    public imageElement: any;

    // ----------------
    // CONSTRUCTOR

    constructor(props: ImageLoaderProps, state: ImageLoaderState) {
        super(props);
        this.state = {
            loaded: false,
            missing: props.url ? false : true
        };
        this.imageElement = React.createRef<Element>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
    }

    public render = () => {
        return (
            <div className={"image-loader" + (this.state.missing ? " missing-image" : "")}>
                {this.props.url && !this.props.isLoading && !this.props.useMagnifier && (
                    <img className="no-magnifier" src={this.props.url} ref={node => this.imageElement.current = node} onLoad={this.onLoad} onError={this.onLoadError} />
                )}
                {this.props.url && !this.props.isLoading && this.props.useMagnifier && (
                    <div className={"img-with-magnifier" + (this.state.missing ? " no-magnifier" : "")}>
                        <img className="load-monitor" src={this.props.url} ref={node => this.imageElement.current = node} onLoad={this.onLoad} onError={this.onLoadError} />
                        <InnerImageZoom src={this.props.url} zoomSrc={this.props.url} hideHint={this.state.missing ? true : false} />
                    </div>
                )}
                <motion.div className="image-overlay" animate={this.state.loaded ? "hidden" : "visible"} initial={"visible"}
                    variants={this.overlayVariants} transition={{ duration: this.props.noFadeIn ? 0 : 0.25 }}>
                    <motion.div className="image-placeholder" animate={this.state.missing ? "visible" : "hidden"} initial={"hidden"}
                        variants={this.overlayVariants} transition={{ duration: 0 }}>
                        <label className={"image-placeholder" + (this.props.thumbnail ? " small" : "")}>Image Unavailable</label>
                    </motion.div>
                </motion.div>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private autoSize = (): void => {
        if (this.imageElement.current) {
            if (this.imageElement.current.width > this.imageElement.current.height) {
                this.imageElement.current.className += ' height-constrained';
            }
            else {
                this.imageElement.current.className = this.imageElement.current.className.replace(' height-constrained', '');
            }
        }
    }

    private onLoad = (): void => {
        if (this.props.autoSize) {
            this.autoSize();
        }
        this.setState({
            loaded: true,
            missing: false
        });
        if (this.props.onLoad) {
            this.props.onLoad();
        }
    }

    private onLoadError = (): void => {
        this.setState({
            loaded: false,
            missing: true
        });
        if (this.props.onLoad) {
            this.props.onLoad();
        }
    }
}

// ----------------
// EXPORT

export default ImageLoader;
