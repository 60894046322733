import * as React from 'react';
import { User } from '../../store/User';

import './UserCard.scss';

// ----------------
// PROPS

interface UserCardProps {
    data: User
}


class UserCard extends React.PureComponent<UserCardProps> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: UserCardProps) {
        super(props);
    }

    // ----------------
    // METHODS

    public render = () => {
        return (
            <div className="user-card">
                <div className="id-column">
                    <label>{this.props.data.fullName}</label>
                    <label>{this.props.data.id}</label>
                </div>
                <div>{this.props.data.affiliation}</div>
                <div>{this.props.data.email}</div>
            </div>
        )
    }        
}

// ----------------
// EXPORT

export default UserCard;
