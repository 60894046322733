import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as UsersStore from '../../store/Users';
import { User } from '../../store/User';
import { ClientCode } from '../../enums/ClientCode';
import { CustomerName } from '../../common/AccountTypes';
import { AffiliationType } from '../../enums/AffiliationType';
import { Role } from '../../enums/Role';
import { Button, Col, Container, Row } from 'reactstrap';
import Loader from '../loader/Loader';
import DetailTitleBlock from '../detail-title-block/DetailTitleBlock';
import { TitleBlock } from '../../common/ComponentTypes';
import UserForm from '../user-form/UserForm';
import UserFormOld from '../user-form-old/UserFormOld';
import PasswordReissue from '../password-reissue/PasswordReissue';
import cloneDeep from 'lodash/cloneDeep';

import './EditUser.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    usersState: {} as UsersStore.UsersState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, UsersStore.actionCreators)
}

interface AddUserAsyncActions {
    asyncActions: {
        requestUserDetailAsync: (clientCode: ClientCode, id: number) => Promise<User | null | undefined>;
    }
}

interface MatchParams {
    id: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> { }

type EditUserProps =
    MatchProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & AddUserAsyncActions;

// ----------------
// LOCAL STATE

interface EditUserState {
    isVisible: boolean;
    isLoadError: boolean;
    isLoadWarning: boolean;
    isReloading: boolean;
    isResettingPassword: boolean;
    submitting: boolean;
    userDetail: User | null | undefined;
    userDetailBackup: User | null | undefined;
    titleBlock: TitleBlock;
}

class EditUser extends React.PureComponent<EditUserProps, EditUserState> {

    // ----------------
    // VARIABLES

    public editUserVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: EditUserProps, state: EditUserState) {
        super(props);
        this.state = {
            isVisible: false,
            isLoadError: false,
            isLoadWarning: false,
            isReloading: false,
            isResettingPassword: false,
            submitting: false,
            userDetail: null,
            userDetailBackup: null,
            titleBlock: this.getTitleBlock(undefined)
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.ensureDataFetched();
    }

    public componentDidUpdate = () => {
        if (!this.state.isVisible) {
            if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
                this.setState({
                    isReloading: false
                });
                setTimeout(() => {
                    this.setState({
                        isLoadError: true,
                        isLoadWarning: this.receivedWarningMessage()
                    })
                }, 400);
            }
        }
        else if (this.state.submitting) {
            if (this.receivedErrorMessage() || this.receivedWarningMessage() || this.receivedValidationError()) {
                this.setState({
                    submitting: false
                });
            }
            else if (this.receivedActionCompleteMessage()) {
                let titleBlock: TitleBlock = this.state.titleBlock;
                if (this.state.userDetail && this.props.usersState.userDetail) {
                    if (this.state.userDetail.id == 0 && this.props.usersState.userDetail.id > 0) {
                        titleBlock = this.getTitleBlock(this.props.usersState.userDetail);
                        let path: string = '/admin/users/edit/' + this.props.usersState.userDetail.id;
                        this.props.history.push(path, { shallow: true });
                    }
                }
                this.setState({
                    submitting: false,
                    userDetail: this.props.usersState.userDetail,
                    titleBlock: titleBlock
                });
            }
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={(!this.state.isVisible && !this.state.isLoadError) || this.state.isReloading} />
                <motion.div id="editUserPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"} variants={this.editUserVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className="justify-content-start">
                            <DetailTitleBlock titleBlock={this.state.titleBlock} disabled={this.state.submitting || this.state.isReloading} usePlaceholders={true} />
                        </Row>
                        <Row className="user-detail-content">
                            {this.props.clientState.client && this.state.userDetail && (
                                <UserForm client={this.props.clientState.client} userDetail={this.state.userDetail} onCancel={this.onCancel} onSave={this.onSave}
                                    onResetPassword={this.onResetPassword} />
                            )}
                        </Row>
                        {this.state.isLoadError && (
                            <Container>
                                <Row className="justify-content-center">
                                    <Col className={"pt-4 page-error" + (this.state.isLoadWarning ? " warning" : "")} xs={"auto"}>
                                        <Button color="primary" onClick={this.reloadDetail}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                                <polyline points="1 4 1 10 7 10"></polyline>
                                                <polyline points="23 20 23 14 17 14"></polyline>
                                                <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                            </svg>
                                            Reload
                                        </Button>
                                        <Button color="link" onClick={this.goBack}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-left">
                                                <polyline points="15 18 9 12 15 6"></polyline>
                                            </svg>
                                            Go Back
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        )}
                    </Container>
                    <PasswordReissue isOpen={this.state.isResettingPassword} onDismiss={this.hidePasswordReset} />
                </motion.div>
                {this.state.isLoadError && (
                    <Container>
                        <Row className="justify-content-center">
                            <Col className={"pt-4 page-error" + (this.state.isLoadWarning ? " warning" : "")} xs={"auto"}>
                                <Button color="primary" onClick={this.reloadDetail}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                        <polyline points="1 4 1 10 7 10"></polyline>
                                        <polyline points="23 20 23 14 17 14"></polyline>
                                        <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                    </svg>
                                    Reload
                                </Button>
                                <Button color="link" onClick={this.goBack}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-left">
                                        <polyline points="15 18 9 12 15 6"></polyline>
                                    </svg>
                                    Go Back
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                )}
            </React.Fragment>
        )
    }

    // ----------------
    // HELPERS

    private ensureDataFetched = (): void => {
        let userId: number = parseInt(this.props.match.params.id);      // will be NaN if adding new
        if (isNaN(userId)) {
            if (this.props.clientState.client) {
                let newUser: User = this.getNewUser(this.props.clientState.client);
                this.setState({
                    isVisible: true,
                    isLoadError: false,
                    isLoadWarning: false,
                    isReloading: false,
                    userDetail: newUser,
                    userDetailBackup: cloneDeep(newUser),
                    titleBlock: this.getTitleBlock(newUser)
                });
            }
            else {
                setTimeout(() => {
                    this.ensureDataFetched();
                }, 200);
            }
        }
        else {
            if (this.userInStore(userId)) {
                this.setState({
                    isVisible: true,
                    isLoadError: false,
                    isLoadWarning: false,
                    isReloading: false,
                    userDetail: this.props.usersState.userDetail,
                    titleBlock: this.getTitleBlock(this.props.usersState.userDetail)
                });
            }
            else {
                let clientCode: ClientCode = this.props.clientState.client ? this.props.clientState.client.code : ClientCode.Undefined;
                if (clientCode != ClientCode.Undefined) {
                    this.getUser(userId, clientCode);
                }
                else {
                    setTimeout(() => {
                        this.ensureDataFetched();
                    }, 200);
                }
            }
        }        
    }

    private getNewUser = (client: ClientStore.Client): User => {
        return {
            active: false,
            affiliation: this.props.clientState.client ? this.props.clientState.client.name : "",
            affiliationType: AffiliationType.Undefined,
            buyerAccounts: [],
            clientCode: client.code,
            customer: null,
            customerNumber: "",
            description: "",
            email: "",
            firstName: "",
            fullName: "",
            id: 0,
            isAuthenticated: false,
            lastName: "",
            preferences: null,
            role: Role.Undefined,
            salesRepId: "",
            token: "",
            username: "",
            created: undefined,
            lastLogin: undefined            
        };
    }

    private getTitleBlock = (userDetail: User | null | undefined): TitleBlock => {
        let titleBlock: TitleBlock = {
            header: undefined,
            title: undefined,
            subtitle: undefined,
            link: '/admin/users'
        };
        if (userDetail && userDetail.id > 0) {
            titleBlock.header = userDetail.id.toString();
            titleBlock.title = userDetail.fullName;
            titleBlock.subtitle = userDetail.description;
        }
        else if (userDetail) {
            titleBlock.header = "";
            titleBlock.title = "Add User";
            titleBlock.subtitle = "";
        }
        return titleBlock;
    }

    private getUser = (userId: number, clientCode: ClientCode): void => {
        this.props.asyncActions.requestUserDetailAsync(clientCode, userId)
            .then(result => {
                this.setState({
                    isVisible: true,
                    isLoadError: false,
                    isLoadWarning: false,
                    isReloading: false,
                    userDetail: result,
                    userDetailBackup: cloneDeep(result),
                    titleBlock: this.getTitleBlock(result)
                });
            },
            err => {
            });
    }

    private goBack = () => {
        this.props.history.push('/admin/users');
    }

    private hidePasswordReset = (): void => {
        this.setState({
            isResettingPassword: false
        });
    }

    private onCancel = (): void => {
        let backup: User | null | undefined = cloneDeep(this.state.userDetailBackup);
        this.setState({
            userDetail: backup
        });
    }

    private onResetPassword = (): void => {
        this.props.actions.clearMessage();
        this.setState({
            isResettingPassword: true
        });
    }

    private onSave = (user: User): void => {
        this.props.actions.clearMessage();
        if (this.props.clientState.client) {
            let clientCode: ClientCode = this.props.clientState.client.code;            
            this.setState({
                submitting: true
            });
            if (user.id == 0) {
                this.props.actions.addUser(clientCode, user);
            }
            else {
                this.props.actions.editUser(clientCode, user);
            }
        }
    }

    private receivedActionCompleteMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) ?
            true : false;
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private reloadDetail = (): void => {
        this.props.actions.clearMessage();
        let userId: number = parseInt(this.props.match.params.id);
        let clientCode: ClientCode = this.props.clientState.client ? this.props.clientState.client.code : ClientCode.Undefined;
        setTimeout(() => {
            this.setState({
                isReloading: true,
                isLoadError: false,
                isLoadWarning: false
            });
            this.getUser(userId, clientCode);
        }, 200);
    }

    private userInStore = (id: number): boolean => {
        let inStore: boolean = false;
        if (this.props.usersState.userDetail) {
            inStore = (this.props.usersState.userDetail.id == id);
            //if (this.props.usersState.userDetail.affiliationType == AffiliationType.Customer) {
            //    inStore = this.props.usersState.userDetail.customer ? true : false;
            //}
        }
        return inStore;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        usersState: state.users
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            UsersStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestUserDetailAsync: (clientCode: ClientCode, id: number) => dispatch(UsersStore.actionCreators.requestUserDetail(clientCode, id))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUser as any);
