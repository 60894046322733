import * as React from 'react';
import { BaseTableFilter } from '../../common/BaseTypes';
import { handleInputKeyDown, handleInputKeyDownRegex, handleInputPaste, handleInputPasteRegex } from '../field-wrapper/FieldWrapper';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Dropdown } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import StatePicker from '../state-picker/StatePicker';
import { State } from '../../common/AddressTypes'
import VisibilitySensor from 'react-visibility-sensor';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../date-picker-input/DatePickerInput';
import './TableFilter.scss';
import $ from 'jquery';

// ----------------
// TYPES
export interface TableFilterListItem {
    label: string;
    value: string;
}

// ----------------
// PROPS

interface TableFilterProps extends BaseTableFilter {
    filterType: string;   
    initialValue: any;
    filterValue?: any | undefined;
    showOperators?: boolean;
    initialOperator?: string;
    onFilterOperatorChanged?: (field: string, operator: string) => void;
    listItems?: TableFilterListItem[];
    currentDate?: Date;
    hiddenInputRef?: React.RefObject<HTMLInputElement> | undefined;
    upperCaseInput?: boolean;
}

// ----------------
// LOCAL STATE

interface TableFilterState {
    isFocused: boolean;
    isPopulated: boolean;
    operator: string;
    observerAttempts: number;
    selectedListItem: TableFilterListItem | undefined;
    selectedDate: Date | undefined;
}

class TableFilter extends React.PureComponent<TableFilterProps, TableFilterState> {

    // ----------------
    // VARIABLES
    public allowedIntegerCharacters: number[] = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
    public suppressedIntegerCharacters: number[] = [];

    public decimalRegex: RegExp = /^\s*-?(\d+(\.\d{0,2})?|\.\d{0,2})\s*$/;

    public inputElement: React.RefObject<HTMLInputElement> | undefined;
    public dropdownElement: React.RefObject<HTMLDivElement> | undefined;
    public datePickerComponent: any;

    public datePickerInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
        return (
            <DatePickerInput datePickerProps={props} readOnly={true} tooltipText="Filter" selectedDate={this.state.selectedDate} />
        )
    }

    // ----------------
    // CONSTRUCTOR

    constructor(props: TableFilterProps, state: TableFilterState) {
        super(props);
        this.state = {
            isFocused: false,
            isPopulated: props.initialValue ? true : false,
            operator: props.initialOperator || '=',
            observerAttempts: 0,            
            selectedListItem: props.initialValue as TableFilterListItem,
            selectedDate: (props.initialValue && props.initialValue instanceof Date) ? props.initialValue : undefined
        };        
        this.inputElement = React.createRef<HTMLInputElement>();
        this.dropdownElement = React.createRef<HTMLDivElement>();
        this.datePickerComponent = React.createRef();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        if (this.props.initialOperator && this.props.onFilterOperatorChanged) {
            this.props.onFilterOperatorChanged(this.props.columnDef.field, this.props.initialOperator);
        }
        if (this.props.initialValue) {
            if (this.props.filterType === 'list' || this.props.filterType === 'color-picker') {
                this.props.onFilterChanged(this.props.columnDef.tableData.id, this.props.initialValue.value);
            }
            else if (this.props.filterType === 'date-picker') {
                this.props.onFilterChanged(this.props.columnDef.tableData.id, this.props.initialValue);
            }
            else {
                this.props.onFilterChanged(this.props.columnDef.tableData.id, this.props.initialValue);
            }
        }
    }

    public render = () => {
        return (
            <React.Fragment>
                {this.props.filterType === 'string' && (
                    <VisibilitySensor onChange={this.onChangeVisibility}>
                        <div id={"filter" + this.props.columnDef.field} className="table-filter MuiFormControl-root MuiTextField-root">
                            <div className={"MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart MuiInputBase-adornedEnd" + (this.state.isFocused ? " Mui-focused" : "")}>
                                <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                                    <Tooltip title="Filter" placement="bottom" className="table-filter-tooltip">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-filter">
                                            <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                                        </svg>
                                    </Tooltip>
                                </div>
                                <input placeholder="" type="text" className={"MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd" + (this.props.upperCaseInput ? " upper-case" : "")}
                                    onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange} defaultValue={this.props.initialValue} 
                                        ref={this.inputElement} />
                                <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd">
                                    <button className={"MuiButtonBase-root MuiIconButton-root" + (this.state.isPopulated ? "" : " Mui-disabled")}
                                        tabIndex={-1} type="button" disabled={!this.state.isPopulated} onClick={this.clear}>
                                        <span className="MuiIconButton-label">
                                            <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                         </div>
                    </VisibilitySensor>
                )}
                {this.props.filterType === 'integer' && (
                    <VisibilitySensor onChange={this.onChangeVisibility}>
                        <div className={"table-filter" + (this.props.showOperators ? " with-operator" : " MuiFormControl-root MuiTextField-root")}>
                            <div className="MuiFormControl-root MuiTextField-root">                               
                                <div className={"MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart MuiInputBase-adornedEnd" + (this.state.isFocused ? " Mui-focused" : "")}>
                                    <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                                        <Tooltip title="Filter" placement="bottom">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-filter">
                                                <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                                            </svg>
                                        </Tooltip>
                                    </div>                                    
                                    <input placeholder="" type="text" aria-label="Filter" className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd"
                                        onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange}
                                        onKeyDown={(e) => handleInputKeyDown(e, this.allowedIntegerCharacters, this.suppressedIntegerCharacters)}
                                        onPaste={(e) => this.onPaste(e)}
                                        defaultValue={this.props.initialValue}
                                        ref={this.inputElement} />                                   
                                    <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd">
                                        <button className={"MuiButtonBase-root MuiIconButton-root" + (this.state.isPopulated ? "" : " Mui-disabled")}
                                            tabIndex={-1} type="button" disabled={!this.state.isPopulated} onClick={this.clear}>
                                            <span className="MuiIconButton-label">
                                                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>                               
                            </div>
                            {this.props.showOperators && (
                                <UncontrolledDropdown title="Select Operator">
                                    <DropdownToggle>
                                        <div className="dropdown-label">{this.state.operator}</div>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key={"="} onClick={this.onSelectOperator} title="Equals">=</DropdownItem>
                                        <DropdownItem key={"<"} onClick={this.onSelectOperator} title="Less Than">&lt;</DropdownItem>
                                        <DropdownItem key={">"} onClick={this.onSelectOperator} title="Greater Than">&gt;</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>                                
                            )}
                        </div>
                    </VisibilitySensor>
                )}
                {this.props.filterType === 'decimal' && (
                    <div className={"table-filter" + (this.props.showOperators ? " with-operator" : " MuiFormControl-root MuiTextField-root")}>
                        <div className="MuiFormControl-root MuiTextField-root">
                            <div className={"MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart MuiInputBase-adornedEnd" + (this.state.isFocused ? " Mui-focused" : "")}>
                                <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                                    <Tooltip title="Filter" placement="bottom">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-filter">
                                            <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                                        </svg>
                                    </Tooltip>
                                </div>
                                <input placeholder="" type="text" aria-label="Filter" className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd"
                                    onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange}
                                    onKeyDown={(e) => handleInputKeyDownRegex(e, this.decimalRegex)}
                                    onPaste={(e) => handleInputPasteRegex(e, this.decimalRegex)}
                                    defaultValue={this.props.initialValue}
                                    ref={this.inputElement} />
                                <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd">
                                    <button className={"MuiButtonBase-root MuiIconButton-root" + (this.state.isPopulated ? "" : " Mui-disabled")}
                                        tabIndex={-1} type="button" disabled={!this.state.isPopulated} onClick={this.clear}>
                                        <span className="MuiIconButton-label">
                                            <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.props.filterType === 'list' && (
                    <VisibilitySensor onChange={this.onChangeVisibility}>
                        <div className="table-filter with-dropdown">
                            <UncontrolledDropdown>
                                <DropdownToggle>
                                    <div className="dropdown-label">{this.state.selectedListItem? this.state.selectedListItem.label : ""}</div>
                                    <div className="dropdown-toggler" ref={this.dropdownElement}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-down">
                                            <polyline points="6 9 12 15 18 9"></polyline>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-up">
                                            <polyline points="18 15 12 9 6 15"></polyline>
                                        </svg>
                                    </div>
                                </DropdownToggle>
                                {this.props.listItems && (
                                    <DropdownMenu>
                                        {this.props.listItems.map((i, index) => (
                                            <DropdownItem key={"item" + index} itemProp={i.value} onClick={this.onSelectListItem}>{i.label}</DropdownItem>
                                        ))}
                                    </DropdownMenu> 
                                )}
                            </UncontrolledDropdown>
                        </div>
                    </VisibilitySensor>
                )}
                {this.props.filterType === 'state-picker' && (
                    <StatePicker countryCode={this.props.filterValue} stateCode={this.props.initialValue}
                        onSelectState={this.onSelectState} />
                )}                
                {this.props.filterType === 'date-picker' && (
                    <div className={"table-filter" + (this.props.showOperators ? " with-operator" : "")}>
                        <DatePicker onChange={this.onSelectDate} autoComplete={"autocomplete_datepicker"}
                            customInput={React.createElement(React.forwardRef(this.datePickerInput))}
                            selected={this.state.selectedDate} minDate={this.props.currentDate}
                            dateFormat={"M/d/yyyy"} ref={this.datePickerComponent} placeholderText={"Any"}
                            isClearable={true} clearButtonClassName="btn-clear" />
                        {this.props.showOperators && (
                            <UncontrolledDropdown title="Select Operator">
                                <DropdownToggle>
                                    <div className="dropdown-label">{this.state.operator}</div>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem key={"="} onClick={this.onSelectOperator} title="Equals">=</DropdownItem>
                                    <DropdownItem key={"<"} onClick={this.onSelectOperator} title="Less Than">&lt;</DropdownItem>
                                    <DropdownItem key={">"} onClick={this.onSelectOperator} title="Greater Than">&gt;</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )}
                    </div>
                )}
                {this.props.filterType === 'color-picker' && (
                    <div className="table-filter with-dropdown">
                        <UncontrolledDropdown>
                            <DropdownToggle>
                                <div className="dropdown-label">
                                    {this.state.selectedListItem && (
                                        this.state.selectedListItem.label
                                    )}
                                </div>
                                <div className="dropdown-toggler" ref={this.dropdownElement}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-down">
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-up">
                                        <polyline points="18 15 12 9 6 15"></polyline>
                                    </svg>
                                </div>
                            </DropdownToggle>
                            {this.props.listItems && (
                                <DropdownMenu>
                                    {this.props.listItems.map((i, index) => (
                                        <DropdownItem key={"item" + index} itemProp={i.value} onClick={this.onSelectListItem}>
                                            <span className={"color-sample " + this.getColorClassName(i.label)}></span>
                                            <label>{i.label}</label>
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            )}
                        </UncontrolledDropdown>
                    </div>
                )}
                {this.props.filterType === 'hidden' && (
                    <input type="text" ref={this.props.hiddenInputRef} defaultValue={this.props.initialValue}  onChange={this.onChange} />
                )}
            </React.Fragment>
        )
    }

    // ----------------
    // HELPERS

    private clear = () => {
        if (this.inputElement && this.inputElement.current) {
            this.inputElement.current.value = '';
            this.props.onFilterChanged(this.props.columnDef.tableData.id, this.inputElement.current.value);
            this.setState({
                isPopulated: this.inputElement.current.value ? true : false
            });
        }
        else if (this.dropdownElement && this.dropdownElement.current && this.props.listItems) {
            let defaultListItem: TableFilterListItem = this.props.listItems[0];
            this.setState({
                isPopulated: false,
                selectedListItem: defaultListItem
            });
            this.props.onFilterChanged(this.props.columnDef.tableData.id, defaultListItem.value);
        }
    }

    private getColorClassName = (name: string) => {
        return name.toLowerCase().replace(/\s/g, '-');
    }

    private onBlur = (): void => {
        this.setState({
            isFocused: false
        });
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {        
        this.props.onFilterChanged(this.props.columnDef.tableData.id, event.target.value);
        this.setState({
            isPopulated: event.target.value ? true : false
        });
    }

    private onChangeVisibility = (visible: boolean): void => {
        if (!visible && this.state.isPopulated) {
            if (this.inputElement && this.inputElement.current && this.inputElement.current.clientHeight === 0) {
                this.clear();
                this.updateObserverAttempts(0);
            }
            else if (this.dropdownElement && this.dropdownElement.current && this.dropdownElement.current.clientHeight === 0) {
                this.clear();
                this.updateObserverAttempts(0);
            }
            else if (this.state.observerAttempts < 2) {
                this.updateObserverAttempts(1);
                setTimeout(() => {
                    this.onChangeVisibility(visible);
                }, 400);
            }
            else {
                this.updateObserverAttempts(0);
            }
        }
    }

    private onFocus = (): void => {
        this.setState({
            isFocused: true
        });
    }

    private onPaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
        let allowedCharacters: number[] = [];
        let suppressedCharacters: number[] = [];
        switch (this.props.filterType) {
            case 'integer':
            default:
                allowedCharacters = this.allowedIntegerCharacters;
                suppressedCharacters = this.suppressedIntegerCharacters;
                break;
        }
        handleInputPaste(event, allowedCharacters, suppressedCharacters);
        this.props.onFilterChanged(this.props.columnDef.tableData.id, event.currentTarget.value);
        this.setState({
            isPopulated: event.currentTarget.value ? true : false
        });
    }

    private onSelectDate = (date: Date) => {
        this.props.onFilterChanged(this.props.columnDef.tableData.id, date);
        this.setState({
            selectedDate: date
        });
    }

    private onSelectListItem = (event: React.MouseEvent): void => {
        let listItemLabel: string | null = event.currentTarget.textContent;
        let listItemValue: string | null = event.currentTarget.getAttribute("itemprop");
        if (listItemLabel && listItemValue) {
            this.setState({
                isPopulated: listItemValue != '0',
                selectedListItem: { label: listItemLabel, value: listItemValue } as TableFilterListItem
            });
            this.props.onFilterChanged(this.props.columnDef.tableData.id, listItemValue);
        }
    }

    private onSelectOperator = (event: React.MouseEvent): void => {
        let operator: string = event.currentTarget.textContent || '=';
        this.setState({
            operator: operator
        });
        if (this.props.onFilterOperatorChanged) {
            this.props.onFilterOperatorChanged(this.props.columnDef.field, operator);
            if (this.inputElement && this.inputElement.current && this.inputElement.current.value) {
                this.props.onFilterChanged(this.props.columnDef.tableData.id, this.inputElement.current.value);
            }
            else if (this.datePickerComponent && this.datePickerComponent.current && this.datePickerComponent.current.props.selected) {
                this.props.onFilterChanged(this.props.columnDef.tableData.id, this.datePickerComponent.current.props.selected);
            }
        }
    }

    private onSelectState = (state: State | undefined): void => {
        this.props.onFilterChanged(this.props.columnDef.tableData.id, state ? state.code : undefined);
    }

    private updateObserverAttempts = (increment: number): void => {
        this.setState({
            observerAttempts: increment > 0 ? this.state.observerAttempts + 1 : 0
        });
    }
}

// ----------------
// EXPORT

export default TableFilter;
