import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import { Button, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import BouncedOrders from '../bounced-orders/BouncedOrders';
import Loader from '../loader/Loader';

import './AdminExceptions.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrdersStore.actionCreators)
}

interface MatchParams {
    id: string;
    tab: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> { }

type AdminExceptionsProps =
    MatchProps
    & RouteComponentProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators;        // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface AdminExceptionsState {
    isVisible: boolean;
    activeTab: string;
}

class AdminExceptions extends React.PureComponent<AdminExceptionsProps, AdminExceptionsState> {

    // ----------------
    // VARIABLES

    public bouncedOrdersTableElement: any;

    public adminExceptionsVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: AdminExceptionsProps, state: AdminExceptionsState) {
        super(props);
        this.state = {
            isVisible: false,
            activeTab: this.props.match.params.tab
        };
        this.bouncedOrdersTableElement = React.createRef();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        if (this.props.clientState.client) {
            this.setState({
                isVisible: true
            })
        }
    }

    public componentDidUpdate = (prevProps: AdminExceptionsProps) => {
        if (this.props.clientState.client && !prevProps.clientState.client) {
            this.setState({
                isVisible: true
            });
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={!this.state.isVisible} />
                <motion.div id="adminExceptionsPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"}
                    variants={this.adminExceptionsVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className="title justify-content-start d-block">
                            <Col className="pl-0 pr-0 text-left">
                                <h2 className="page-title">Exceptions</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pr-0 exceptions-tabs">
                                <Nav tabs className="right-align">
                                    <NavItem>
                                        <NavLink className={this.state.activeTab === "bouncedorders" ? "active" : ''}
                                            onClick={() => this.toggleTabs("bouncedorders")}
                                            disabled={false}>
                                            Bounced Orders
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pr-0 pt-4">
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="bouncedorders">
                                        <Container>
                                            <Row>
                                                <Col className="pl-0 pr-0">
                                                    {this.props.clientState.client && (
                                                        <BouncedOrders onGoToOrder={this.goToOrder} />
                                                    )}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private goToOrder = (id: number): void => {
        let currentPath: string = '/admin/exceptions/' + this.state.activeTab;
        this.props.history.push('/orders/edit/' + id + '/header', { from: currentPath });
    }

    private toggleTabs = (selectedTab: string): void => {
        if (this.state.activeTab != selectedTab) {
            this.setState({
                activeTab: selectedTab
            });
            this.props.history.replace('/admin/exceptions/' + this.props.match.params.id + '/' + selectedTab);            
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        ordersState: state.orders
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrdersStore.actionCreators
        ), dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminExceptions as any);
