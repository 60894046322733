import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as ErrorMessage from '../../common/ErrorMessage';
import { User } from '../../store/User';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';

import './DeleteUser.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
};

interface DeleteUserOwnProps {
    userToDelete: User | null;
    onDelete: (() => void);
    onDismiss: (() => void);
}

type DeleteUserProps =
    DeleteUserOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface DeleteUserState {
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
    errorMessage: MessageStore.Message | null | undefined;
}

class DeleteUser extends React.PureComponent<DeleteUserProps, DeleteUserState> {
  
    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: DeleteUserProps, state: DeleteUserState) {
        super(props);
        this.state = {
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            errorMessage: null
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
    }

    public render = () => {
        return (
            <Modal id="delete-user" size="sm" isOpen={this.props.userToDelete != null}
                onOpened={this.initializeDialog.bind(this)} onClosed={this.resetDialog}>
                <ModalHeader toggle={this.props.onDismiss} className={this.state.submitting ? "disabled" : ""}>
                    Delete User
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className="action-info pl-0 pr-0">
                                <span className="action-description">All access rights will be revoked for</span>
                                <span className="action-target">{this.props.userToDelete ? this.props.userToDelete.fullName : ""}</span>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" diabled={this.state.submitting || this.state.changesAccepted}
                                    onClick={this.deleteUser}>
                                    {this.state.submitting ? "Working" : "Continue"}
                                </Button>
                                <Button color="link" onClick={this.props.onDismiss} disabled={this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        )
    }

    // ----------------
    // HELPERS

    private deleteUser = (): void => {
        if (this.props.userToDelete) { 
            this.setState({
                submitting: true
            });

            let fetchError: boolean = false;
            let action: string = 'users/remove';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'DELETE',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(this.props.userToDelete)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                this.setState({
                                    errorMessage: errorMessage
                                });
                                this.props.onDismiss();
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    this.setState({
                        submitting: false,
                        changesAccepted: true,
                        formResult: 'User deleted'
                    });
                    setTimeout(() => {
                        this.props.onDelete();
                    }, 1500);
                },
                err => {
                    if (!fetchError) {
                        this.setState({
                            errorMessage: ErrorMessage.getFromError(err, action)
                        });
                        this.props.onDismiss();
                    }
                }
            )
        }
    }

    private initializeDialog = (): void => {
        this.setState({
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            errorMessage: null
        })
    }

    private resetDialog = (): void => {
        setTimeout(() => {
            if (this.state.errorMessage) {
                this.props.actions.broadcastMessage(this.state.errorMessage);
            }
        })
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, DeleteUserOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(DeleteUser as any);
