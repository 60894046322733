import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import { ClientCode } from '../../enums/ClientCode';
import { CustomerName } from '../../common/AccountTypes';
import { Address, Country } from '../../common/AddressTypes';
import { FindByType } from '../../enums/FindByType';
import * as ErrorMessage from '../../common/ErrorMessage';
import { AsyncTypeahead, Highlighter, Typeahead, TypeaheadMenuProps, TypeaheadResult } from 'react-bootstrap-typeahead';
import sanitizeHTML from 'sanitize-html';
import $ from 'jquery';

import './CustomerFinder.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
}

interface CustomerFinderOwnProps {
    clearInput?: boolean | null | undefined;
    initialValue?: CustomerName | null | undefined;
    onChange: (selected: CustomerName | null) => void;
    disabled?: boolean | undefined;
    autoFocus?: boolean | undefined;
}

type CustomerFinderProps =
    CustomerFinderOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface CustomerFinderState {
    displayShipTo: boolean;
    findBy: FindByType;
    isLoading: boolean;
    openMenu: boolean | undefined;
    options: CustomerName[];
    selected: CustomerName[];    
}


// ----------------
// LOCAL TYPES

interface CustomerNameQuery {
    clientCode: ClientCode;
    keyword: string;
    findBy: FindByType;
}

class CustomerFinder extends React.PureComponent<CustomerFinderProps, CustomerFinderState> {

    // ----------------
    // VARIABLES

    public finderElement: any;

    // ----------------
    // CONSTRUCTOR

    constructor(props: CustomerFinderProps, state: CustomerFinderState) {
        super(props);
        this.state = {
            displayShipTo: this.props.clientState.client ? this.props.clientState.client.rules.displayShipToNumber : true,
            findBy: FindByType.Name,
            isLoading: false,
            openMenu: this.props.initialValue ? false : undefined,
            options: this.props.initialValue ? [this.props.initialValue] : [],
            selected: this.props.initialValue ? [this.props.initialValue] : []
        };
        this.finderElement = React.createRef();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: CustomerFinderProps) => {
        if (this.props.clientState.client && !prevProps.clientState.client) {
            if (!this.props.clientState.client.rules.displayShipToNumber) {
                this.setState({
                    displayShipTo: false
                })
            }
        }
        if (this.props.clearInput === true && !prevProps.clearInput) {
            this.finderElement.current.clear();
            this.handleChange([]);
        }
        if (this.props.initialValue && !prevProps.initialValue) {
            if (this.props.initialValue.number && this.props.initialValue.name) {
                this.setState({
                    openMenu: false,
                    options: [this.props.initialValue],
                    selected: [this.props.initialValue]
                });
            }
        }
    }

    public render = () => {
        let isDisabled: boolean = this.props.disabled === true ? true : false;
        return (
            <div id="customer-finder">
                <AsyncTypeahead
                    id="rbt-customer-finder"
                    clearButton={true}
                    filterBy={() => true}
                    highlightOnlyResult={true}
                    isLoading={this.state.isLoading}
                    labelKey={this.formatOption}
                    minLength={2}
                    options={this.state.options}
                    onChange={this.handleChange}
                    onSearch={this.handleSearch}
                    onInputChange={this.handleInputChange}
                    open={this.state.openMenu}
                    placeholder="Find Customer..."
                    renderMenuItemChildren={this.renderOption}
                    selected={this.state.selected}
                    useCache={false}
                    disabled={isDisabled}
                    ref={node => this.finderElement.current = node}                    
                />
                <div className="finder-options">
                    <label className={"radio-button-wrapper" + (isDisabled ? " disabled" : "")}>
                        <input type="radio" name="customer-findby" value={FindByType.Name} checked={this.state.findBy === FindByType.Name}
                            onChange={this.selectFindByOption} disabled={isDisabled} />
                        <span>By Name</span>
                    </label>
                    <label className={"radio-button-wrapper" + (isDisabled ? " disabled" : "")}>
                        <input type="radio" name="customer-findby" value={FindByType.Number} checked={this.state.findBy === FindByType.Number}
                            onChange={this.selectFindByOption} disabled={isDisabled} />
                        <span>By Number</span>
                    </label>
                </div>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private formatCityStateZip = (option: TypeaheadResult<CustomerName>): { __html: string } | undefined => {
        let output: string | undefined = undefined;
        if (option.address) {
            if (option.address.city) {
                output = option.address.city;
                if (option.address.state) {
                    output = output + ', ' + option.address.state.code;
                    if (option.address.postalCode) {
                        output = output + " &nbsp;" + option.address.postalCode;
                    }
                }
            }
            else if (option.address.state) {
                output = option.address.state.code;
                if (option.address.postalCode) {
                    output = output + " &nbsp;" + option.address.postalCode;
                }
            }
        }
        return output ? { __html: output } : undefined;
    }

    private formatOption = (option: CustomerName): string => {
        if (this.state.displayShipTo) {
            return `${option.nameOnly}   -   ${option.billto_shipto}`;
        }
        else {
            if (option.locationDescription) {
                return `${option.nameOnly}   -   ${option.billto}-${option.locationDescription}`;
            }
            else {
                return `${option.nameOnly}   -   ${option.billto}`;
            }
        }        
    }

    private handleChange = (selected: CustomerName[]): void => {
        this.setState({
            options: selected.length > 0 ? this.state.options : [],
            selected: selected
        });
        this.props.onChange(selected.length > 0 ? selected[0] : null);
    }

    private handleInputChange = (input: string, e: Event): void => {
        if (this.state.openMenu === false) {
            this.setState({
                openMenu: undefined
            });
        }
    }

    private handleSearch = (query: string): void => {
        if (this.props.clientState.client) {
            this.lookupCustomerName({
                clientCode: this.props.clientState.client.code,
                keyword: query,
                findBy: this.state.findBy
            } as CustomerNameQuery);
        }
    }

    private isInternational = (country: Country): boolean => {
        return country.code !== 'US' ? true : false;
    }

    private lookupCustomerName = (query: CustomerNameQuery): void => {
        this.props.actions.clearMessage();
        this.setState({
            isLoading: true,
        });
        let fetchError: boolean = false;
        let action: string = 'customers/getcustomername';
        let url: string = `${action}`;

        fetch(url,
            {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify(query)
            })
            .then(response => {
                if (response.status != 200) {
                    ErrorMessage.getFromResponse(response, action).then(
                        (errorMessage => {
                            this.props.actions.broadcastMessage(errorMessage);
                        })
                    );
                    fetchError = true;
                    throw new Error();
                }
                return response.json();
            })
            .then(data => {
                let result: CustomerName[] = data as CustomerName[];
                this.setState({
                    isLoading: false,
                    options: result
                });
            },
            err => {
                this.setState({
                    isLoading: false
                });
                if (!fetchError) {
                    this.props.actions.broadcastMessage(ErrorMessage.getFromError(err, action));
                }
            }
        )        
    }

    private renderOption = (option: TypeaheadResult<CustomerName>, props: TypeaheadMenuProps<CustomerName>, index: number): React.ReactNode | undefined => {
        return (
            <React.Fragment>
                <div className={"customer-option" + (index % 2 === 1 ? " odd" : "")}>
                    <div className="two-column">
                        <div className="billto">
                            {this.state.findBy === FindByType.Number && this.state.displayShipTo && (
                                <Highlighter search={props.text || ''}>                                     
                                    { option.billto_shipto }
                                </Highlighter>
                            )}
                            {this.state.findBy === FindByType.Number && !this.state.displayShipTo && (
                                <Highlighter search={props.text || ''}>
                                    { option.billto }
                                </Highlighter>
                            )}
                            {this.state.findBy === FindByType.Name && this.state.displayShipTo && (
                                <span>{ option.billto_shipto }</span>
                            )}
                            {this.state.findBy === FindByType.Name && !this.state.displayShipTo && (
                                <span>{option.billto}</span>
                            )}
                        </div>
                        <div className="description">
                            {option.locationDescription && !this.state.displayShipTo && (
                                <span>{ option.locationDescription}</span>
                            )}
                        </div>
                    </div>
                    <div className="customer-name">
                        {this.state.findBy === FindByType.Name && (
                            <Highlighter search={props.text || ''}>
                                {option.nameOnly}
                            </Highlighter>
                        )}
                        {this.state.findBy === FindByType.Number && (
                            <span>{option.nameOnly}</span>
                        )}
                    </div>
                    {option.address && (
                        <div className="customer-location">
                            {option.address.street1 && (
                                <label className="street">{option.address.street1}</label>
                            )}
                            {option.address.street2 && (
                                <label className="street">{option.address.street2}</label>
                            )}
                            {option.address.street3 && (
                                <label className="street">{option.address.street3}</label>
                            )}
                            {(option.address.city || option.address.state.code) && (
                                <label className="city-state" dangerouslySetInnerHTML={this.formatCityStateZip(option)}></label>
                            )}
                            {option.address.country.code && this.isInternational(option.address.country) && (
                                <label className="country">{option.address.country.name}</label>
                            )}
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }

    private selectFindByOption = (event: React.ChangeEvent): void => {
        let optionValue: string | null = event.target.getAttribute("value");
        if (optionValue) {
            let findBy: number = parseInt(optionValue);
            this.setState({
                findBy: findBy,               
                options: [],
                selected: []
            });
            this.finderElement.current.clear();
            this.props.onChange(null);
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators
        ), dispatch)
    };
}

export default connect <{},{}, CustomerFinderOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(CustomerFinder as any);
