import * as React from 'react';
import { Button } from 'reactstrap';
import { Tooltip } from '@material-ui/core';

import './DatePickerInput.scss';

// ----------------
// PROPS

interface DatePickerInputProps {
    datePickerProps: React.HTMLProps<HTMLInputElement>;
    readOnly: boolean;
    selectedDate?: Date | undefined;
    tooltipText: string;
    hideClearButton?: boolean;
}

// ----------------
// STATE

interface DatePickerInputState {
    selected: boolean;
}

class DatePickerInput extends React.PureComponent<DatePickerInputProps, DatePickerInputState> {

    // ----------------
    // VARIABLES

    public inputElement: React.RefObject<HTMLInputElement>;

    // ----------------
    // CONSTRUCTOR
    constructor(props: DatePickerInputProps, state: DatePickerInputState) {
        super(props);
        this.state = {
            selected: this.props.datePickerProps.value ? true : false
        };
        this.inputElement = React.createRef<HTMLInputElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
        if (this.inputElement.current) {
            let selected: boolean = this.inputElement.current.value ? true : false;
            if (selected != this.state.selected) {
                this.setState({
                    selected: selected
                });
            }
        }
    }

    public render = () => {
        return (
            <div className={"date-picker-input" + ((this.state.selected && !this.props.hideClearButton) ? " date-selected" : "")}>
                <input type="text" {...this.props.datePickerProps} className="form-control" readOnly={this.props.readOnly}
                    ref={this.inputElement} />
                <Tooltip title={this.props.tooltipText} placement="bottom" className="table-filter-tooltip">
                    <Button color="secondary" onClick={(e: React.MouseEvent) => this.onButtonClick(e)} onFocus={() => this.onButtonFocus()}>
                        {(!this.state.selected || this.props.hideClearButton) && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-calendar">
                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                <line x1="16" y1="2" x2="16" y2="6"></line>
                                <line x1="8" y1="2" x2="8" y2="6"></line>
                                <line x1="3" y1="10" x2="21" y2="10"></line>
                            </svg>
                        )}
                        {this.state.selected && !this.props.hideClearButton && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        )}
                    </Button>
                </Tooltip>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private onButtonClick = (event: React.MouseEvent) => {
        if (this.inputElement.current) {
            this.inputElement.current.click();
        }
    }

    private onButtonFocus = () => {
        if (this.inputElement.current) {
            this.inputElement.current.click();
        }
    }
}

// ----------------
// EXPORT

export default DatePickerInput;
