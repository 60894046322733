import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as PasswordStore from '../../store/Password';
import * as UserStore from '../../store/User';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Field, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps, submit } from 'redux-form';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import PasswordRules from '../password-rules/PasswordRules';

import './PasswordChange.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    passwordState: {} as PasswordStore.PasswordState,
    userState: {} as UserStore.UserState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, PasswordStore.actionCreators)
};

interface PasswordChangeOwnProps {
    isOpen: boolean;
    onDismiss: (() => void);
}

type PasswordChangeProps =
    PasswordChangeOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface PasswordChangeState {
    submitting: boolean;
    submitAttempted: boolean;
    newPassword: string | null;
    passwordIsVisible: boolean;
    passwordConfirmationIsVisible: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
}

// ----------------
// FORM VALIDATOR

const validatePasswordChangeForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.password) {
        errors.password = 'Required';
    }
    else {
        let validLength: boolean = (formValues.password.length >= 8) ? true : false;
        let hasUpperCase: boolean = (/[A-Z]/.test(formValues.password)) ? true : false;
        let hasLowerCase: boolean = (/[a-z]/.test(formValues.password)) ? true : false;
        let hasNumber: boolean = (/[0-9]/.test(formValues.password) ? true : false);
        if (!(validLength && hasUpperCase && hasLowerCase && hasNumber)) {
            errors.password = 'Invalid';
        }
        else {
            if (!formValues.passwordConfirmation) {
                errors.passwordConfirmation = 'Required';
            }
            else if (formValues.passwordConfirmation != formValues.password) {
                errors.passwordConfirmation = 'Must match password';
            }
        }
    }
    return errors;
}

class PasswordChange extends React.PureComponent<PasswordChangeProps, PasswordChangeState> {

    // ----------------
    // VARIABLES

    public passwordElement: React.RefObject<HTMLInputElement>;
    public passwordConfirmationElement: React.RefObject<HTMLInputElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: PasswordChangeProps, state: PasswordChangeState) {
        super(props);
        this.state = {
            submitting: false,
            submitAttempted: false,
            newPassword: null,
            passwordIsVisible: false,
            passwordConfirmationIsVisible: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        };
        this.passwordElement = React.createRef<HTMLInputElement>();
        this.passwordConfirmationElement = React.createRef<HTMLInputElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {        
    }

    public componentDidUpdate = () => {
        if (this.state.submitting) {
            if (this.receivedErrorMessage()) {
                setTimeout(() => {
                    this.setState({
                        submitting: false
                    });
                    this.props.onDismiss();
                });
            }
            else if (this.receivedValidationError()) {
                let formResult: string | null = this.getFormResult();
                this.props.actions.clearMessage();
                setTimeout(() => {
                    this.setState({
                        submitting: false,
                        changesRejected: true,
                        formResult: formResult
                    });
                });
            }
            else if (this.receivedActionCompleteMessage()) {
                let formResult: string | null = this.getFormResult();
                this.props.actions.clearMessage();
                setTimeout(() => {
                    this.setState({
                        submitting: false,
                        changesAccepted: true,
                        formResult: formResult
                    });
                    setTimeout(() => {
                        this.props.onDismiss();
                    }, 1500);
                })
            }
        }
    }

    public handleFormSubmit = (values: any): void => {
        this.setState({
            submitting: true,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        });
        this.props.actions.clearMessage();
        this.props.actions.changePassword(values);
    }

    public render = () => {
        return (
            <Modal id="password-change" isOpen={this.props.isOpen}
                onOpened={this.initializeForm.bind(this)} onClosed={this.resetForm}>
                <ModalHeader toggle={this.props.onDismiss} className={this.state.submitting ? "disabled" : ""}>
                    Change Password
                </ModalHeader>
                <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                    <ModalBody>
                        <Container>
                            <Row className="justify-content-center justify-content-sm-start">
                                <Col className="password pl-0 pr-0 pr-sm-3" sm={6} xs={12}>
                                    <Container>
                                        <Row>
                                            <Col className="pl-0 pr-0">
                                                <Field name="password" type={this.state.passwordIsVisible ? "text" : "password"} label="New Password"
                                                    component={FieldWrapper.renderPasswordField} maxLength={25} autoFocus={true}
                                                    passwordVisible={this.state.passwordIsVisible}
                                                    showPasswordHandler={this.showPassword}
                                                    reference={this.passwordElement}
                                                    disabled={this.state.submitting || this.state.changesAccepted}
                                                    onChange={this.onPasswordChange} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pl-0 pr-0">
                                                <Field name="passwordConfirmation" type={this.state.passwordConfirmationIsVisible ? "text" : "password"} label="Confirm New Password"
                                                    component={FieldWrapper.renderPasswordField} maxLength={25}
                                                    passwordVisible={this.state.passwordConfirmationIsVisible}
                                                    showPasswordHandler={this.showPasswordConfirmation}
                                                    reference={this.passwordConfirmationElement}
                                                    disabled={this.state.submitting || this.state.changesAccepted} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Field name="clientCode" component="input" type="hidden" />
                                                <Field name="userId" component="input" type="hidden" />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col className="password-rules pl-0 pr-0 pl-sm-3 mb-4 mb-sm-0" sm={6} xs={8}>
                                    <PasswordRules input={this.state.newPassword} showErrors={this.state.submitAttempted} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Container>
                            <Row>
                                <Col className="button-bar pl-0 pr-0 pr-sm-3">
                                    <Button type="submit" color="primary" disabled={this.state.submitting || this.state.changesAccepted} onClick={this.captureFormSubmit}>
                                        {this.state.submitting ? "Working" : "Save"}
                                    </Button>
                                    <Button color="link" onClick={this.props.onDismiss} disabled={this.state.submitting || this.state.changesAccepted}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pt-4 pr-0 pr-sm-3">
                                    <FormResult
                                        failureResult={this.state.changesRejected}
                                        successResult={this.state.changesAccepted}
                                        description={this.state.formResult} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalFooter>
                </form>
            </Modal>
        )
    }

    // ----------------
    // HELPERS

    private captureFormSubmit = (): void => {
        this.setState({
            submitAttempted: true
        });
    }

    private getFormResult = (): string | null => {
        let formResult: string | null = null;
        if (this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) {
                formResult = this.props.messageState.message.text || 'Form contains invalid entries';
            }
            else if (this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) {
                formResult = 'Password saved';
            }
        }
        return formResult;
    }


    private initializeForm = (): void => {
        this.resetForm();
        this.props.change('password', null);
        this.props.change('passwordConfirmation', null);

        this.setState({
            submitting: false,
            submitAttempted: false,
            newPassword: null,
            passwordIsVisible: false,
            passwordConfirmationIsVisible: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        });
        setTimeout(() => {
            if (this.passwordElement.current) {
                this.passwordElement.current.focus();
                this.passwordElement.current.setSelectionRange(0, 0);                
            }
        });
    }

    private onPasswordChange = (): void => {
        let newPassword: string | null = this.passwordElement.current ?
            this.passwordElement.current.value : null;
        this.setState({
            newPassword: newPassword
        });
    }

    private receivedActionCompleteMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) ?
            true : false;
    }

    private receivedErrorMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) ?
            true : false;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private resetForm = (): void => {
        this.props.reset();
    }

    private showPassword = (): void => {
        this.setState({
            passwordIsVisible: !this.state.passwordIsVisible
        });
        if (this.passwordElement.current) {
            this.passwordElement.current.focus();
        }
    }

    private showPasswordConfirmation = (): void => {
        this.setState({
            passwordConfirmationIsVisible: !this.state.passwordConfirmationIsVisible
        });
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        passwordState: state.password,
        userState: state.user,
        initialValues: {
            password: '.',
            passwordConfirmation: '.',
            clientCode: state.user.user ? state.user.user.clientCode : null,
            userId: state.user.user ? state.user.user.id : null
        }
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            PasswordStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, PasswordChangeOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'passwordChangeForm',
    validate: validatePasswordChangeForm
})(PasswordChange as any));

