import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { OrderDateType } from '../../enums/OrderDateType';
import { Filter, Query, QueryResult } from 'material-table';
import { Button, Container, Col, ListGroup, ListGroupItem, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import Loader from '../loader/Loader';
import SearchBox from '../search-box/SearchBox';
import { convertJSDateToString, convertSQLDateToString } from '../../common/DateConverter';
import debounce from 'lodash.debounce';
import $ from 'jquery';

import './FindOrder.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrdersStore.actionCreators)
};

interface FindOrderAsyncActions {
    asyncActions: {
        requestOrdersAsync: (client: Client, query: Query<any>) => Promise<QueryResult<any>>;
    }
}

interface FindOrderOwnProps {
    client: Client;
    isOpen: boolean;
    onDismiss: (id: number | null | undefined) => void;
}

type FindOrderProps =
    FindOrderOwnProps
    & InjectedFormProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & FindOrderAsyncActions;

// ----------------
// LOCAL STATE

interface FindOrderState {
    submitting: boolean;
    selected: boolean;
    filtered: boolean;
    filterFlag: boolean;
    animationComplete: boolean;
    orderStatusOptions: FieldWrapper.OptionValue[];
    orderStatusFlag: boolean;
    selectedOrderStatus: string | null | undefined;
    dateFrom: Date | null | undefined;
    dateTo: Date | null | undefined;
    clearSearch: boolean;
    selectedPage: number;
    pageCount: number;    
    activeQuery: Query<OrdersStore.Order> | null | undefined;
    queryResults: OrdersStore.Order[];
    selectedIndex: number | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateFindOrderForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    return errors;
}

class FindOrder extends React.PureComponent<FindOrderProps, FindOrderState> {

    // ----------------
    // VARIABLES

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', overflow: 'hidden' },
        visibleOverflow: { height: 'auto', overflow: 'visible' }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: FindOrderProps, state: FindOrderState) {
        super(props);
        this.state = {
            submitting: false,
            selected: false,
            filtered: false,
            filterFlag: false,
            animationComplete: false,
            orderStatusOptions: this.getOrderStatusOptions(),
            orderStatusFlag: false,
            selectedOrderStatus: undefined,
            dateFrom: undefined,
            dateTo: undefined,
            clearSearch: false,
            selectedPage: 0,
            pageCount: 0,
            activeQuery: undefined,
            queryResults: [],
            selectedIndex: undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: FindOrderProps) => {
    }

    public componentWillUnmount = () => {
    }

    public handleFormSubmit = (values: any): void => {
        this.setState({
            submitting: true,
            selectedIndex: undefined
        });
        let query: Query<OrdersStore.Order> = this.getQuery(values, true);
        this.getOrders(query);
    }

    public render = () => {
        return (
            <Modal id="find-order" isOpen={this.props.isOpen}
                onOpened={this.initializeForm} onClosed={()=> this.resetForm(true)}>
                <ModalHeader toggle={() => this.cancelFind()} className={(this.state.submitting || this.state.selected) ? "disabled" : ""}>
                    Find Order
                </ModalHeader>
                <ModalBody>                                       
                    <Container>
                        <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                            <Row className="search-bar">
                                <Col className="search-input pl-0" xs={10}>
                                    <SearchBox autoFocus={true} onChange={this.onSearch} clearInput={this.state.clearSearch} />
                                </Col>
                                <Col className="search-actions pl-0 pr-0" xs={2}>
                                        <Button color="link" disabled={this.state.submitting} onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.toggleFilterPanel(e)}>
                                            <label>Filter</label>
                                            {this.state.filterFlag && (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-up">
                                                    <polyline points="18 15 12 9 6 15"></polyline>
                                                </svg>
                                            )}
                                            {!this.state.filterFlag && (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-down">
                                                    <polyline points="6 9 12 15 18 9"></polyline>
                                                </svg>
                                            )}
                                        </Button>
                                </Col>
                            </Row>
                            <Row className="search-filters">
                                <Col className="collapsible-row pl-0 pr-0">
                                    <motion.div animate={this.state.filterFlag ? this.state.animationComplete ? "visibleOverflow" : "visible" : "hidden"}
                                        initial={this.state.filterFlag ? "visible" : "hidden"}
                                        variants={this.collapsibleRowVariants} transition={{ duration: 0.25, delay: (this.state.filterFlag ? 0.25 : 0)}}
                                        onAnimationComplete={this.onSearchFiltersRowChange}>
                                        <Container>
                                            <Row>
                                                <Col className="pl-0" xs={7}>
                                                    <Field name="customerNameOnly" type="text" label="Customer Name" component={FieldWrapper.renderTextField}
                                                        onChange={this.onChangeCustomerNameOrNumber} maxLength={50}
                                                        disabled={this.state.selected} />
                                                </Col>
                                                <Col className="pr-0" xs={5}>
                                                    <Field name="billto" type="text" label="Customer Number" component={FieldWrapper.renderTextField}
                                                        onChange={this.onChangeCustomerNameOrNumber} maxLength={10}
                                                        numericOnly={this.props.client.rules.numericCustomerId}
                                                        disabled={this.state.selected} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pl-0" xs={4}>
                                                    <Field name="orderStatus" type="text" label="Status" component={FieldWrapper.renderDropdownField}
                                                        // defaultValue={this.getDefaultOrderStatus()}
                                                        disabled={this.state.selected}
                                                        open={this.state.orderStatusFlag}
                                                        options={this.state.orderStatusOptions}
                                                        onSelectOption={this.onSelectOrderStatus}
                                                        onToggle={this.onToggleOrderStatus} />
                                                </Col>
                                                <Col className="pr-0" xs={8}>
                                                    <label className="date-range-label">Date Started</label>
                                                    <div className="date-range">
                                                        <div className="value first">
                                                            <Field name="dateFrom" label="" component={FieldWrapper.renderDatePickerField}
                                                                selectedDate={this.state.dateFrom} onSelectDate={this.onChangeDateFrom}
                                                                disabled={this.state.selected} placeholder="From" />
                                                        </div>
                                                        <div className="value second">
                                                            <Field name="dateTo" label="" component={FieldWrapper.renderDatePickerField}
                                                                selectedDate={this.state.dateTo} onSelectDate={this.onChangeDateTo}
                                                                disabled={this.state.selected} placeholder="To" />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </motion.div>
                                </Col>
                            </Row>
                            <button type="submit" ref={this.submitButton}>SUBMIT</button>
                        </form>
                        <Row className={"query-results" + (this.state.filterFlag ? " minimized" : "")} >
                            <Loader isLoading={this.state.submitting} isChild={true} />
                            <Col className="pl-0 pr-0">
                                {this.state.activeQuery && !(this.state.submitting || this.hasFindResults()) && (
                                    <label className="no-results">No matching orders found</label>
                                )}
                                {this.state.activeQuery && this.hasFindResults() && (
                                    <ListGroup flush>
                                        {this.state.queryResults.map((order: OrdersStore.Order, index: number) => (
                                            <ListGroupItem key={"order" + index} className={(index % 2 === 1 ? "odd" : "") + (index === this.state.selectedIndex ? " selected" : "")}>
                                                <div className="order-container">
                                                    <div className="action">
                                                        <Button color="secondary" onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.selectOrder(e, index, order)} disabled={this.state.selected}
                                                            title={order.orderStatus === 'Open' ? 'Edit Order' : 'View Order'}>
                                                            {order.orderStatus === 'Open' && (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-edit-3">
                                                                    <path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                                                </svg>
                                                            )}
                                                            {order.orderStatus !== 'Open' && (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-search">
                                                                    <circle cx="11" cy="11" r="8"></circle>
                                                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                                </svg>
                                                            )}
                                                        </Button>
                                                    </div>
                                                    <div className="description">
                                                        <div className="two-column">
                                                            <label className="order-number">{order.orderNumber}</label>
                                                            <label className="start-date">{convertSQLDateToString(order.dateCreated, "M/d/yyyy")}</label>
                                                        </div>
                                                        <label className="customer">{order.customerNameOnly}</label>
                                                        <div className="two-column">
                                                            <label className="status">{order.orderStatus}</label>
                                                            {order.poNumber && (
                                                                <label className="po-number"><span>PO:</span>{order.poNumber}</label>
                                                            )}
                                                            {!order.poNumber && (
                                                                <label>&nbsp;</label>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                )}
                            </Col>
                        </Row>
                        <Row className="query-pager">
                            <Col className="pl-0 pr-0 pt-2">                                
                                <Button color="link" disabled={this.state.selectedPage <= 1 || this.state.submitting || this.state.selected} onClick={this.showFirst}>
                                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                    </svg>
                                </Button>
                                <Button color="link" disabled={this.state.selectedPage <= 1 || this.state.submitting || this.state.selected} onClick={this.showPrev}>
                                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                    </svg>
                                </Button>
                                <label className="page-number">{this.state.selectedPage} of {this.state.pageCount}</label>
                                <Button color="link" disabled={this.isLast() || this.state.submitting || this.state.selected} onClick={this.showNext}>
                                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                    </svg>
                                </Button>
                                <Button color="link" disabled={this.isLast() || this.state.submitting || this.state.selected} onClick={this.showLast}>
                                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                    </svg>
                                </Button>
                            </Col>
                        </Row>
                    </Container>                    
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={this.cancelFind} disabled={this.state.submitting}>
                                    Close
                                </Button>
                                <Button color="link" onClick={this.clearFilters} disabled={this.state.submitting}>
                                    Clear
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private cancelFind = ():void => {
        this.props.actions.clearOrdersQuery();
        this.props.onDismiss(null);
    }

    private clearFilters = (): void => {
        this.resetForm(false);
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        })
    }

    private findOrder = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.preventDefault();
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }

    private getDefaultOrderStatus = (): string => {
        return "All";
    }

    private getFilter = (field: string): Filter<any> | undefined => {
        let filter: Filter<any> | undefined = undefined;
        if (this.props.ordersState.orders && this.props.ordersState.orders.query) {
            let matches: Filter<any>[] = this.props.ordersState.orders.query.filters.filter((filter: Filter<any>) => {
                return filter.column.field === field;
            });
            if (matches.length > 0) {
                filter = matches[0];
            }
        }
        return filter;
    }

    private getFilterValue = (field: string): string | undefined => {
        let filterValue: string | undefined = undefined;
        let filter: Filter<any> | undefined = this.getFilter(field);
        if (filter) {
            filterValue = filter.value;
        }
        return filterValue;
    }

    private getOrderStatusOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [
            { label: 'All', value: '0' },
            { label: 'Open', value: 'Open' },
            { label: 'Submitted', value: 'Submitted' }
        ];
        return options;
    }

    private getOrders = (query: Query<OrdersStore.Order>): void => {
        this.setState({
            submitting: true
        });
        this.props.asyncActions.requestOrdersAsync(this.props.client, query)
            .then(result => {
                this.scrollGridResults();
                this.setState({
                    submitting: false,
                    activeQuery: query,
                    selectedPage: result.totalCount > 0 ? result.page + 1 : 0,
                    queryResults: result.data,
                    pageCount: Math.ceil(result.totalCount / query.pageSize)
                })
            },
            err => {
                this.props.onDismiss(null);
            });
    }

    private getQuery = (values: any, newQuery: boolean): Query<OrdersStore.Order> => {
        let query: Query<OrdersStore.Order> | null | undefined = newQuery ? undefined : this.state.activeQuery;
        if (!query) {
            query = {
                page: 0,
                pageSize: 10,
                totalCount: 0,
                filters: this.getQueryFilters(values)
            } as Query<OrdersStore.Order>;
            if (this.state.activeQuery) {
                query.search = this.state.activeQuery.search;
            }
        }
        return query;
    }

    private getQueryFilters = (values: any): Filter<OrdersStore.Order>[] => {
        let filters: Filter<OrdersStore.Order>[] = [];
        if (values.orderNumber) {
            filters.push({
                column: {
                    field: "orderNumber",
                    type: "string"
                },
                operator: "=",
                value: values.orderNumber
            });
        }
        if (values.orderStatus && values.orderStatus != 'All') {
            filters.push({
                column: {
                    field: "orderStatus",
                    type: "string"
                },
                operator: "=",
                value: values.orderStatus
            });
        }
        if (values.customerNameOnly) {
            filters.push({
                column: {
                    field: "customerNameOnly",
                    type: "string"
                },
                operator: "=",
                value: values.customerNameOnly
            });
        }
        if (values.billto) {
            filters.push({
                column: {
                    field: "billto",
                    type: "string"
                },
                operator: "=",
                value: values.billto
            })
        }
        if (values.dateFrom || values.dateTo) {
            let dateRangeString: string = OrderDateType.Started + '|';
            dateRangeString = dateRangeString + (values.dateFrom ? convertJSDateToString(values.dateFrom, 'yyyy-MM-dd') : '');
            dateRangeString = dateRangeString + '|';
            dateRangeString = dateRangeString + (values.dateTo ? convertJSDateToString(values.dateTo, 'yyyy-MM-dd') : '');
            filters.push({
                column: {
                    field: "dateRange",
                    type: "string"
                },
                operator: "=",
                value: dateRangeString
            });
        }
        return filters;
    }

    private hasFindResults = (): boolean => {
        return this.state.activeQuery ? this.state.pageCount > 0 ? true : false : false;
    }

    private initializeForm = (): void => {

        this.setState({
            orderStatusFlag: false,
            selectedOrderStatus: 'All',
            dateFrom: undefined,
            dateTo: undefined,
            clearSearch: false,
            selectedPage: 0,
            pageCount: 0,
            activeQuery: undefined,
            queryResults: [],
            selectedIndex: undefined
        })

        this.props.change('orderNumber', null);
        this.props.change('customerName', null);
        this.props.change('customerId', null);
        this.props.change('orderStatus', 'All');
        this.props.change('dateFrom', null);
        this.props.change('dateTo', null);

        setTimeout(() => {
            this.getOrders(this.getQuery([], true));
        });
    }

    private isCurrent = (page: number): boolean => {
        return page === this.state.selectedPage ? true : false;
    }

    private isLast = (): boolean => {
        return this.state.selectedPage >= this.state.pageCount;
    }

    private isNext = (page: number): boolean => {
        return page > this.state.selectedPage ? true : false;
    }

    private onChangeDateFrom = (date: Date): void => {
        this.setState({
            dateFrom: date
        });
        this.props.change("dateFrom", date);
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        })
    }

    private onChangeDateTo = (date: Date): void => {
        this.setState({
            dateTo: date
        });
        this.props.change("dateTo", date);
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        })
    }

    private onChangeCustomerNameOrNumber = debounce((): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }, 400, { leading: false, trailing: true });

    private onSearch = (search: string | null | undefined): void => {
        let query: Query<OrdersStore.Order> | null | undefined = this.state.activeQuery;
        if (!query) {
            query = {
                page: 0,
                pageSize: 10,
                totalCount: 0,
                search: search || ''
            } as Query<OrdersStore.Order>;
        }
        else {
            query.search = search || '';
            query.page = 0;
        }
        this.setState({
            submitting: true
        });
        this.getOrders(query);        
    }

    private onSearchFiltersRowChange = (): void => {
        setTimeout(() => {
            this.setState({
                animationComplete: this.state.filterFlag
            });
        }, 400);
    }

    private onSelectOrderStatus = (selectedOption: FieldWrapper.OptionValue): void => {
        this.props.change('orderStatus', selectedOption.label);
        this.setState({
            selectedOrderStatus: selectedOption.value
        })
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        })
    }

    private onToggleOrderStatus = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {
        event.preventDefault();
        let orderStatusFlag: boolean = !this.state.orderStatusFlag;
        if (orderStatusFlag) {
            this.props.change('orderStatus', 'Select...');
        }
        this.setState({
            orderStatusFlag: orderStatusFlag
        });
        if (!orderStatusFlag) {
            setTimeout(() => {
                if (this.state.selectedOrderStatus) {
                    this.props.change('orderStatus', this.state.selectedOrderStatus === '0' ? 'All' : this.state.selectedOrderStatus);
                }
            })
        }
    }

    private resetForm = (closeFilter: boolean): void => {
        this.props.reset();
        this.props.change('orderStatus', 'All');
        this.setState({
            submitting: false,
            filterFlag: closeFilter ? false : this.state.filterFlag,
            animationComplete: false,
            activeQuery: undefined,
            clearSearch: true,
            dateFrom: undefined,
            dateTo: undefined
        });
    }

    private scrollGridResults = (): void => {
        let queryResults: any = $('#find-order .query-results');
        if (queryResults.length > 0) {
            queryResults[0].scrollTop = 0;
        }
    }

    private selectOrder = (event: React.KeyboardEvent | React.MouseEvent, index: number, order: OrdersStore.Order): void => {
        event.preventDefault();
        this.setState({
            selected: true,
            selectedIndex: index
        });
        setTimeout(() => {
            this.props.onDismiss(order.id);
        }, 400);
    }

    private showFirst = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.stopPropagation();
        if (this.state.activeQuery) {
            this.setState({
                submitting: true,
                selectedIndex: undefined
            });
            let query: Query<OrdersStore.Order> = this.state.activeQuery;
            query.page = 0;
            this.getOrders(query);
        }
    }

    private showLast = (event: React.KeyboardEvent | React.MouseEvent): void => {
        event.stopPropagation();
        if (this.state.activeQuery) {
            this.setState({
                submitting: true,
                selectedIndex: undefined
            });
            let query: Query<OrdersStore.Order> = this.state.activeQuery;
            query.page = this.state.pageCount - 1;
            this.getOrders(query);
        }
    }

    private showNext = (event: React.MouseEvent): void => {
        event.stopPropagation();
        if (this.state.activeQuery && this.state.activeQuery.page < this.state.pageCount) {
            this.setState({
                submitting: true,
                selectedIndex: undefined
            });
            let query: Query<OrdersStore.Order> = this.state.activeQuery;
            query.page++;
            this.getOrders(query);
        }
    }

    private showPrev = (event: React.MouseEvent): void => {
        event.stopPropagation();
        if (this.state.activeQuery && this.state.activeQuery.page > 0) {
            this.setState({
                submitting: true,
                selectedIndex: undefined
            });
            let query: Query<OrdersStore.Order> = this.state.activeQuery;
            query.page--;
            this.getOrders(query);
        }
    }

    private toggleFilterPanel = (event: React.MouseEvent | React.KeyboardEvent): void => {
        event.preventDefault();
        let filterFlag: boolean = this.state.filterFlag ? false : true;
        this.setState({
            filterFlag: filterFlag
        });
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        ordersState: state.orders
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrdersStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestOrdersAsync: (client: Client, query: Query<any>) => dispatch(OrdersStore.actionCreators.requestOrders(client, query))
        }
    };
}

export default connect<{}, {}, FindOrderOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'findOrderForm',
    validate: validateFindOrderForm,
    enableReinitialize: true
})(FindOrder as any));
