import * as React from 'react';
import { motion } from 'framer-motion';

import './FormResult.scss';

// ----------------
// PROPS

interface FormResultProps {
    failureResult: boolean;
    successResult: boolean;
    description: string | null;
}

class FormResult extends React.PureComponent<FormResultProps> {

    // ----------------
    // VARIABLES

    public formResultVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: FormResultProps) {
        super(props);
    }

    // ----------------
    // METHODS

    public render = () => {
        return (
            <motion.div id="formResult" className={'form-result' + (this.props.failureResult ? ' failure' : this.props.successResult ? ' success' : '')}
                animate={this.props.description ? "visible" : "hidden"} variants={this.formResultVariants} transition={{ duration: 0.5 }}>

                {this.props.failureResult && this.props.description && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path strokeWidth="1" d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                        <line x1="12" y1="9" x2="12" y2="13"></line>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                )}

                {this.props.successResult && this.props.description && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                )}

                {this.props.description && (
                    <label>{this.props.description}</label>
                )}                
            </motion.div>
        );
    }
}

export default FormResult;
