import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as CustomersStore from '../../store/Customers';
import { Button, Container, Col, Row } from 'reactstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, TooltipItem, Legend, ChartData, ChartOptions } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { Bar } from 'react-chartjs-2';

import './SalesSummary.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    customersState: {} as CustomersStore.CustomersState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, CustomersStore.actionCreators)
};

interface SalesSummaryOwnProps {
    customerAccount: CustomersStore.CustomerAccount,
}

type SalesSummaryProps =
    SalesSummaryOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface SalesSummaryState {
    dataAvailable: boolean;
}

class SalesSummary extends React.PureComponent<SalesSummaryProps, SalesSummaryState> {

    // ----------------
    // VARIABLES

    public options: ChartOptions<"bar"> = {
        responsive: true,
        layout: {
            padding: 0
        },
        font: {
            family: "'Helvetica Neue','Helvetica','Arial',sans-serif",
            size: 14
        }, 
        plugins: {
            legend: {
                display: false              
            },
            title: {
                display: false             
            },
            tooltip: {
                callbacks: {
                    label: function (context: TooltipItem<"bar">) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                        }
                        return label;
                    }
                }
            }
        }        
    };

    public labels: string[] = ["Last Year", "Current YTD"];

    // ----------------
    // CONSTRUCTOR

    constructor(props: SalesSummaryProps, state: SalesSummaryState) {
        super(props);
        this.state = {
            dataAvailable: this.hasSalesData()
        };

        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            Title,
            Tooltip,
            Legend
        );
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
    }

    public render = () => {
        return (
            <React.Fragment>
                {this.state.dataAvailable && (
                    <div className="chart-container">
                        <Bar options={this.options} data={this.getDatasets()} />
                    </div>
                )}
                {!this.state.dataAvailable && (
                    <div className="no-data">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-alert-circle">
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="12"></line>
                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                        <label>No sales data available</label>
                    </div>
                )}
            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private getDatasets = (): ChartData<"bar", number[], unknown> => {
        let salesLYR: number = this.props.customerAccount.salesLastYear ? this.props.customerAccount.salesLastYear as number : 0;
        let salesYTD: number = this.props.customerAccount.salesYearToDate ? this.props.customerAccount.salesYearToDate as number : 0;
        return {
            labels: this.labels,
            datasets: [
                {
                    label: "Sales",
                    data: [salesLYR, salesYTD],
                    backgroundColor: 'rgba(130,224,170,0.4)',
                    borderColor: 'rgb(169,166,164)',
                    borderWidth: 1,
                    maxBarThickness: 75,
                    
                }
            ]
        };
    }

    private hasSalesData = (): boolean => {
        let salesLYR: boolean = this.props.customerAccount.salesLastYear != null && this.props.customerAccount.salesLastYear != undefined;
        let salesYTD: boolean = this.props.customerAccount.salesYearToDate != null && this.props.customerAccount.salesYearToDate != undefined;
        return (salesLYR || salesYTD) ? true : false;
    }

    private initializeForm = (): void => {
    }

    private resetForm = (): void => {
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        customersState: state.customers
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            CustomersStore.actionCreators), dispatch)
    }
}

export default connect<{}, {}, SalesSummaryOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(SalesSummary as any);
