import * as React from 'react';
import { Order, OrderDetail } from '../../store/Orders';
import { Table } from 'reactstrap';

import './OrderDescription.scss';

// ----------------
// PROPS

interface OrderDescriptionProps {
    order?: Order | null | undefined;
    orderDetail?: OrderDetail | null | undefined;
    hideAccount?: boolean;
    disabled?: boolean;
}

// ----------------
// LOCAL STATE

interface OrderDescriptionState { }

class OrderDescription extends React.PureComponent<OrderDescriptionProps, OrderDescriptionState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderDescriptionProps, state: OrderDescriptionState) {
        super(props);
        this.state = {};
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <div className={"order-description" + (this.props.disabled ? " disabled" : "")}>
                {this.props.order && (
                    <Table size="sm" borderless>
                        <tbody>
                            <tr>
                                <td className="pl-0 pr-4 pt-0 pb-0">Number</td>
                                <td className="pr-0 pt-0 pb-0">{this.props.order.orderNumber}</td>
                            </tr>
                            {!this.props.hideAccount && (
                                <tr>
                                    <td className="pl-0 pr-4 pt-0 pb-0">Account</td>
                                    <td className="pr-0 pt-0 pb-0">{this.props.order.customerNameOnly}</td>
                                </tr>
                            )}
                            <tr>
                                <td className="pl-0 pr-4 pt-0 pb-0">PO</td>
                                <td className={"pr-0 pt-0 pb-0" + (this.props.order.poNumber ? "" : " placeholder")}>{this.addPlaceholder(this.props.order.poNumber)}</td>
                            </tr>
                        </tbody>
                    </Table>
                )}
                {this.props.orderDetail && (
                    <Table size="sm" borderless>
                        <tbody>
                            <tr>
                                <td className="pl-0 pr-4 pt-0 pb-0">Number</td>
                                <td className="pr-0 pt-0 pb-0">{this.props.orderDetail.orderNumber}</td>
                            </tr>
                            {this.props.orderDetail.customerAccount && !this.props.hideAccount && (
                                <tr>
                                    <td className="pl-0 pr-4 pt-0 pb-0">Account</td>
                                    <td className="pr-0 pt-0 pb-0">{this.props.orderDetail.customerAccount.nameOnly}</td>
                                </tr>
                            )}
                            <tr>
                                <td className="pl-0 pr-4 pt-0 pb-0">PO</td>
                                <td className={"pr-0 pt-0 pb-0" + (this.props.orderDetail.poNumber ? "" : " placeholder")}>{this.addPlaceholder(this.props.orderDetail.poNumber)}</td>
                            </tr>
                        </tbody>
                    </Table>
                )}                
            </div>
        )
    }

    // ----------------
    // HELPERS

    private addPlaceholder = (value: string | null | undefined): string => {
        return (value ? value : 'none assigned');
    }
}

// ----------------
// EXPORT

export default OrderDescription;
