import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Dropdown } from 'reactstrap';
import { State } from '../../common/AddressTypes'

import './StatePicker.scss';

// ----------------
// TYPES

interface CountryStates {
    countryCode: string;
    countryName: string;
    states: State[];
}

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState
};

const actionCreators = {
    actions: Object.assign({}, ClientStore.actionCreators)
};

interface StatePickerOwnProps {
    countryCode: string | undefined;
    stateCode: string | undefined;
    onSelectState: (state: State | undefined) => void;
}

type StatePickerProps =
    StatePickerOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface StatePickerState {
    initialized: boolean;
    statesUS: CountryStates;
    statesCanada: CountryStates;
    allStates: CountryStates[] | undefined;
    selectedState: State | undefined;
}

class StatePicker extends React.PureComponent<StatePickerProps, StatePickerState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: StatePickerProps, state: StatePickerState) {
        super(props);
        this.state = {
            initialized: false,
            statesUS: this.getStatesForUS(),
            statesCanada: this.getStatesForCanada(),
            allStates: undefined,
            selectedState: undefined
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        if (this.props.clientState.client) {
            this.setState({
                initialized: true,
                allStates: this.getAllStates(this.props.countryCode),
                selectedState: this.getStateFromCode(this.props.stateCode)
            });
        }
    }

    public componentDidUpdate = (prevProps: StatePickerProps) => {
        if (this.props.clientState.client) {
            if (!this.state.initialized) {
                this.setState({
                    initialized: true,
                    allStates: this.getAllStates(this.props.countryCode),
                    selectedState: this.getStateFromCode(this.props.stateCode)
                })
            }
            else if (this.props.countryCode != prevProps.countryCode) {
                let selectedState: State | undefined = this.isSelectedStateInCountry(this.props.countryCode) ?
                    this.state.selectedState : { code: '0', name: 'All' } as State;
                this.setState({
                    allStates: this.getAllStates(this.props.countryCode),
                    selectedState: selectedState
                });
                this.props.onSelectState(selectedState);
            }
        }
    }

    public render = () => {
        return (
            <UncontrolledDropdown id="state-picker">
                <DropdownToggle>
                    <div className="dropdown-label">{this.state.selectedState ? this.state.selectedState.name : ""}</div>
                    <div className="dropdown-toggler">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-down">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-up">
                            <polyline points="18 15 12 9 6 15"></polyline>
                        </svg>
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem key={"itemDefault"} itemProp="0" onClick={this.onSelectState}>All</DropdownItem>
                    {this.state.allStates && this.state.allStates.map((i: CountryStates, countryIndex: number) => (
                        <React.Fragment key={"fragment" + countryIndex}>
                            {this.state.allStates && this.state.allStates.length > 1 && (
                                <DropdownItem key={"header" + countryIndex} header>{i.countryName}</DropdownItem>
                            )}
                            {this.state.allStates && this.state.allStates[countryIndex].states.map((s: State, stateIndex: number) => (
                                <DropdownItem key={"state" + s.code.toLowerCase() + '-' + stateIndex} itemProp={s.code} onClick={this.onSelectState}>{s.name}</DropdownItem>
                            ))}
                        </React.Fragment>
                    ))}                
                </DropdownMenu>               
            </UncontrolledDropdown>
        );
    }

    // ----------------
    // HELPERS

    private isSelectedStateInCountry = (countryCode: string | undefined): boolean => {
        let inCountry: boolean = true;
        if (countryCode && this.state.selectedState) {
            let allCountryStates: CountryStates[] | undefined = this.getAllStates(undefined);
            if (allCountryStates) {
                let selectedCountryStates: CountryStates[] = allCountryStates.filter((cs: CountryStates) => {
                    return cs.countryCode === countryCode;
                });
                if (selectedCountryStates.length > 0) {
                    let matchingState: State[] = selectedCountryStates[0].states.filter((s: State) => {
                        return s.code === (this.state.selectedState ? this.state.selectedState.code : '');
                    });
                    inCountry = matchingState.length > 0;
                }
                else {
                    inCountry = false;
                }
            }
        }
        return inCountry;
    }

    private getAllStates = (countryCode: string | undefined): CountryStates[] | undefined => {
        let allStates: CountryStates[] | undefined = undefined;
        if (this.props.clientState.client) {
            switch (this.props.clientState.client.code) {
                default:
                    allStates = [this.state.statesUS, this.state.statesCanada];
                    break;
            }
            if (allStates && countryCode) {
                allStates = allStates.filter((cs: CountryStates) => {
                    return cs.countryCode === countryCode;
                });
            }
        }
        return allStates;
    }

    private getStatesForCanada = (): CountryStates => {
        return {
            countryCode: 'CA',
            countryName: 'Canada',
            states: [
                { code: "AB", name: "Alberta" },
                { code: "BC", name: "British Columbia" },
                { code: "MB", name: "Manitoba" },
                { code: "NB", name: "New Brunswick" },
                { code: "NL", name: "Newfoundland and Labrador" },
                { code: "NS", name: "Nova Scotia" },
                { code: "NT", name: "Northwest Territories" },
                { code: "NU", name: "Nunavut" },
                { code: "ON", name: "Ontario" },
                { code: "PE", name: "Prince Edward Island" },
                { code: "SK", name: "Saskatchewan" },
                { code: "QC", name: "Quebec" },
                { code: "YT", name: "Yukon" }
            ]
        }
    }

    private getStatesForUS = (): CountryStates => {
        return {
            countryCode: 'US',
            countryName: 'United States',
            states: [
                { code: "AK", name: "Alaska" },
                { code: "AL", name: "Alabama" },
                { code: "AR", name: "Arkansas" },
                { code: "AZ", name: "Arizona" },
                { code: "CA", name: "California" },
                { code: "CO", name: "Colorado" },
                { code: "CT", name: "Connecticut" },
                { code: "DC", name: "District of Columbia" },
                { code: "DE", name: "Delaware" },
                { code: "FL", name: "Florida" },
                { code: "GA", name: "Georgia" },
                { code: "GU", name: "Guam" },
                { code: "HI", name: "Hawaii" },
                { code: "IA", name: "Iowa" },
                { code: "ID", name: "Idaho" },
                { code: "IL", name: "Illinois" },
                { code: "IN", name: "Indiana" },
                { code: "KS", name: "Kansas" },
                { code: "KY", name: "Kentucky" },
                { code: "LA", name: "Louisiana" },
                { code: "MA", name: "Massachusetts" },
                { code: "MD", name: "Maryland" },
                { code: "ME", name: "Maine" },
                { code: "MI", name: "Michigan" },
                { code: "MN", name: "Minnesota" },
                { code: "MO", name: "Missouri" },
                { code: "MS", name: "Mississippi" },
                { code: "MT", name: "Montana" },
                { code: "NC", name: "North Carolina" },
                { code: "ND", name: "North Dakota" },
                { code: "NE", name: "Nebraska" },
                { code: "NH", name: "New Hampshire" },
                { code: "NJ", name: "New Jersey" },
                { code: "NM", name: "New Mexico" },
                { code: "NV", name: "Nevada" },
                { code: "NY", name: "New York" },
                { code: "OH", name: "Ohio" },
                { code: "OK", name: "Oklahoma" },
                { code: "OR", name: "Oregon" },
                { code: "PA", name: "Pennsylvania" },
                { code: "PR", name: "Puerto Rico" },
                { code: "RI", name: "Rhode Island" },
                { code: "SC", name: "South Carolina" },
                { code: "SD", name: "South Dakota" },
                { code: "TN", name: "Tennessee" },
                { code: "TX", name: "Texas" },
                { code: "UT", name: "Utah" },
                { code: "VA", name: "Virginia" },
                { code: "VT", name: "Vermont" },
                { code: "WA", name: "Washington" },
                { code: "WV", name: "West Virginia" }                
            ]
        } as CountryStates;
    }

    private getStateFromCode = (code: string | undefined): State | undefined => {
        let state: State | undefined = { code: '0', name: 'All' } as State;
        if (code) {
            let allCountryStates: CountryStates[] | undefined = this.getAllStates(undefined);
            if (allCountryStates) {
                for (let n: number = 0; n < allCountryStates.length; n++) {
                    let matchingState: State[] = allCountryStates[n].states.filter((s: State) => {
                        return s.code === code;
                    });
                    if (matchingState.length > 0) {
                        state = matchingState[0];
                        break;
                    }
                }
            }
        }
        return state;
    }

    private onSelectState = (event: React.MouseEvent): void => {
        let stateName: string | null = event.currentTarget.textContent;
        let stateCode: string | null = event.currentTarget.getAttribute("itemprop");
        let selectedState: State = { code: stateCode, name: stateName } as State;
        if (stateName && stateCode) {
            this.setState({
                selectedState: selectedState
            });
            this.props.onSelectState(selectedState);
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            ClientStore.actionCreators), dispatch)
    }
}

export default connect<{}, {}, StatePickerOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(StatePicker as any);
