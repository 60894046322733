import * as React from 'react';
import { Logo } from '../../common/EmbroideryTypes';
import TapeCheckbox from '../tape-checkbox/TapeCheckbox';
import ImageLoader from '../image-loader/ImageLoader';
import { formatNumberAsCurrencyForCountry } from '../../common/CurrencyFormatter';

import './TapeDescription.scss'

// ----------------
// PROPS

interface TapeDescriptionProps {
    isLoading: boolean;
    logo: Logo;
    selected: boolean;    
    onImageLoad: () => void;
    onSelect: (logo: Logo) => void;
}

// ----------------
// LOCAL STATE

interface TapeDescriptionState {}

class TapeDescription extends React.PureComponent<TapeDescriptionProps, TapeDescriptionState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: TapeDescriptionProps, state: TapeDescriptionState) {
        super(props);
        this.state = {};
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: TapeDescriptionProps) => {
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <div className="tape-description">
                <div className="action">
                    <TapeCheckbox id={this.getUniqueIdentifier()} selected={this.props.selected} disabled={this.props.selected} onChange={this.selectTape} />
                </div>
                <a className="image" href={"#"} onClick={this.onClickLogo}>
                    <div className="logo-image">
                        <div className="image-stretcher">
                            <div className="image-canvas">
                                <ImageLoader url={this.props.logo.logoUrl} isLoading={this.props.isLoading} autoSize={true} noFadeIn={false} onLoad={this.props.onImageLoad} />
                            </div>
                        </div>
                    </div>
                </a>
                <a className="text" href={"#"} onClick={this.onClickLogo}>
                    <div className="text-wrapper">
                        <label className={"tape-number" + (this.hasValidDescription() ? "" : " spaced")}>{this.props.logo.tapeNumber}</label>
                        {this.hasValidDescription() && (
                            <label className="description spaced">{this.props.logo.description}</label>
                        )}
                        {this.hasValidDimensions() && (
                            <label className="dimensions">{this.props.logo.dimensions}</label>
                        )}
                        {!this.hasValidDimensions() && (
                            <label className="dimensions">Dimensions unavailable</label>   
                        )}
                        <div className="two-column">
                            <div className="col left">
                                {this.hasValidStitchCount() && (
                                    <label>{this.props.logo.stitchCount} stitches</label>
                                )}
                            </div>
                            {this.hasValidPrice() && (
                                <div className="col right">
                                    <label>{formatNumberAsCurrencyForCountry('US', this.props.logo.price, true)}</label>
                                </div>
                            )}
                        </div>
                    </div>
                </a>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private getUniqueIdentifier = (): string => {
        return this.props.logo.tapeNumber.replace(/\s/g, '-').toLowerCase();
    }

    private hasValidDescription = (): boolean => {
        let isValid: boolean = this.props.logo.description ? true : false;
        if (isValid) {
            isValid = this.props.logo.description !== this.props.logo.tapeNumber;
        }
        return isValid;
    }

    private hasValidDimensions = (): boolean => {
        let isValid: boolean = this.props.logo.dimensions ? true : false;
        if (isValid) {
            isValid = this.props.logo.height > 0 || this.props.logo.width > 0;
        }
        return isValid;
    }

    private hasValidPrice = (): boolean => {
        let isValid: boolean = this.props.logo.price ? true : false;
        if (isValid) {
            isValid = this.props.logo.price > 0;
        }
        return isValid;
    }

    private hasValidStitchCount = (): boolean => {
        let isValid: boolean = this.props.logo.stitchCount > 0;
        return isValid;
    }

    private onClickLogo = (event: React.MouseEvent): void => {
        event.preventDefault();
        let row: HTMLTableRowElement | null = event.currentTarget.closest('tr');
        if (row) {
            let checkbox: HTMLInputElement | null = row.querySelector('input[type="checkbox"]');
            if (checkbox) {
                checkbox.click();
            }
        }
    }

    private selectTape = (selected: boolean): void => {
        if (selected) {
            this.props.onSelect(this.props.logo);
        }
    }
}

// ----------------
// EXPORT

export default TapeDescription;
