import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as CustomersStore from '../../store/Customers';
import * as OrdersStore from '../../store/Orders';
import * as OrderItemsStore from '../../store/OrderItems';
import * as ProductlistsStore from '../../store/Productlists';
import * as UserStore from '../../store/Users';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { CustomerName } from '../../common/AccountTypes';
import { NewOrderItem } from '../../store/OrderItems';
import { Productlist, ProductlistDetail, AssignedEventList } from '../../store/Productlists';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import CustomerFinder from '../customer-finder/CustomerFinder';
import FormResult from '../form-result/FormResult';
import OrderDescription from '../order-description/OrderDescription';
import OrderPicker from '../order-picker/OrderPicker';
import AddOrderForm from '../add-order-form/AddOrderForm';
import ImageLoader from '../image-loader/ImageLoader';
import Loader from '../loader/Loader';
import cloneDeep from 'lodash/cloneDeep';
import { toTitleCase } from '../../common/StringFormatter';
import $ from 'jquery';

import './PublishProductlist.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    customersState: {} as CustomersStore.CustomersState,
    ordersState: {} as OrdersStore.OrdersState,
    orderItemsState: {} as OrderItemsStore.OrderItemsState,
    productlistsState: {} as ProductlistsStore.ProductlistsState,
    userState: {} as UserStore.UsersState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrdersStore.actionCreators, OrderItemsStore.actionCreators, ProductlistsStore.actionCreators )
}

interface PublishProductlistAsyncActions {
    asyncActions: {
        addOrderItemAsync: (client: Client, newOrderItem: NewOrderItem) => Promise<OrdersStore.OrderDetail | null | undefined>,
        requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => Promise<CustomersStore.CustomerAccount | null | undefined>,
        publishProductlistToEventAsync: (client: Client, productlistToPublish: ProductlistDetail) => Promise<ProductlistDetail | null | undefined>
    }
}

interface PublishProductlistOwnProps {
    isOpen: boolean;
    productlistDetail: ProductlistDetail;
    onDismiss: () => void;
}

type PublishProductlistProps =
    PublishProductlistOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & PublishProductlistAsyncActions;

// ----------------
// LOCAL STATE

interface PublishProductlistState {
    productlistDetail: ProductlistDetail;
    submitting: boolean;
    retrieving: boolean;
    retrievingAccount: boolean;
    retrievingOrder: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
    actions: FieldWrapper.OptionValue[];
    selectedAction: number;
    eventListAnimationComplete1: boolean;
    eventListAnimationComplete2: boolean;
}

// FORM VALIDATOR

const validatePublishProductlistForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (formValues.action === 0) {
        errors.action = 'Select action';
    }
    else if (parseInt(formValues.action) === 1) {
        if (!formValues.account) {
            errors.account = 'Required';
        }
    }
    return errors;
}

class PublishProductlist extends React.PureComponent<PublishProductlistProps, PublishProductlistState> {

    // ----------------
    // VARIABLES

    public collapsibleActionBarVariants = {
        hidden: { height: 0, overflow: 'hidden', paddingBottom: '0', paddingTop: '0' },
        visible: { height: 'auto', overflow: 'visible', paddingBottom: '10px', paddingTop: '20px' }
    }

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', overflow: 'hidden' },
        visibleOverflow: { height: 'auto', overflow: 'visible' }
    }

    public collapsibleFooterVariants = {
        hidden: { height: 0, overflow: 'hidden', width: '100%' },
        visible: { height: 'auto', overflow: 'visible', width: '100%' }
    }

    public confirmationMessageVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: PublishProductlistProps, state: PublishProductlistState) {
        super(props);
        this.state = {
            productlistDetail: cloneDeep(this.props.productlistDetail),
            submitting: false,
            retrieving: false,
            retrievingOrder: false,
            retrievingAccount: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            actions: this.getActions(),
            selectedAction: 0,
            eventListAnimationComplete1: false,
            eventListAnimationComplete2: false
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: PublishProductlistProps) => {
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        switch (this.state.selectedAction) {
            case 1:
                if (this.hasCustomer()) {
                    this.publishToEventList();
                }
                break;
        }
    }

    public render = () => {
        return (
            <Modal id="publish-productlist" isOpen={this.props.isOpen}
                onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={this.props.onDismiss} className={(this.state.retrieving || this.state.retrievingOrder || this.state.retrievingAccount || this.state.submitting) ? "disabled" : ""}>
                    Publish List
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                            <Row>
                                <Col className="collapsible-row pl-3 pr-4 action-bar text-center">
                                    <motion.div animate={this.state.selectedAction === 4 ? "hidden" : "visible"} initial={this.state.selectedAction === 4 ? "hidden" : "visible"}
                                        variants={this.collapsibleActionBarVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>                                   
                                            <Field name="action" type="text" label="" disabledOptions={[0,1,1]}
                                                component={FieldWrapper.renderRadioButtonField}
                                                options={this.state.actions} onChange={this.onSelectAction}
                                                disabled={this.state.retrieving || this.state.retrievingOrder || this.state.retrievingAccount || this.state.submitting || this.state.changesAccepted} />
                                    </motion.div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="collapsible-row pl-0 pr-0">
                                    <motion.div animate={(this.state.selectedAction === 1 && this.hasCustomer()) ? this.state.eventListAnimationComplete1 ? "visibleOverflow" : "visible" : "hidden"}
                                        initial={(this.state.selectedAction === 1 && this.hasCustomer()) ? "visible" : "hidden"} onAnimationComplete={this.onEventListRowChange1}
                                        variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>
                                        <Container className="component-wrapper flush-bottom">
                                            <div className="productlist-customer-confirmation">
                                                <label className="confirmation-message">Event list will be {this.hasEventList() ? 'updated' : 'created'} for</label>
                                                <label className="customer-name">{this.state.productlistDetail.customerAccount ? this.state.productlistDetail.customerAccount.nameOnly : ""}</label>
                                            </div>
                                        </Container>
                                    </motion.div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="collapsible-row pl-0 pr-0">
                                    <motion.div animate={(this.state.selectedAction === 1 && !this.hasCustomer()) ? this.state.eventListAnimationComplete2 ? "visibleOverflow" : "visible" : "hidden"}
                                        initial={(this.state.selectedAction === 1 && !this.hasCustomer()) ? "visible" : "hidden"} onAnimationComplete={this.onEventListRowChange2}
                                        variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>
                                        <Container className="component-wrapper">
                                            <Field name="account" label={""} component={FieldWrapper.renderCustomerFinderField} disabled={this.state.retrievingAccount}
                                                onSelectCustomer={this.onSelectCustomer} />
                                            {this.state.retrievingAccount && (
                                                <div className="retrieval-message">Retrieving account...</div>
                                            )}
                                        </Container>
                                    </motion.div>
                                </Col>
                                </Row>
                            <button type="submit" ref={this.submitButton}>Submit</button>
                        </form>
                    </Container>
                </ModalBody>
                <ModalFooter className={this.state.selectedAction === 4 ? "collapsed" : ""}>
                    <motion.div animate={this.state.selectedAction === 4 ? "hidden" : "visible"} initial={this.state.selectedAction === 4 ? "hidden" : "visible"}
                        variants={this.collapsibleFooterVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>
                        <Container>
                            <Row>
                                <Col className="button-bar pl-0 pr-0">
                                    <Button type="button" color="primary" onClick={this.submitPublishProductlistForm} disabled={this.state.retrieving || this.state.retrievingOrder || this.state.retrievingAccount || this.state.submitting || this.state.changesAccepted}>
                                        {this.state.retrievingOrder || this.state.submitting ? "Working ..." : "Continue"}
                                    </Button>
                                    <Button color="link" onClick={this.props.onDismiss} disabled={this.state.retrieving || this.state.retrievingOrder || this.state.retrievingAccount || this.state.submitting || this.state.changesAccepted}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pt-4 pr-0">
                                    <FormResult
                                        failureResult={this.state.changesRejected}
                                        successResult={this.state.changesAccepted}
                                        description={this.state.formResult} />
                                </Col>
                            </Row>
                        </Container>
                    </motion.div>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private getActions = (): FieldWrapper.OptionValue[] => {
        let actions: FieldWrapper.OptionValue[] = [];
        actions.push({ label: (this.hasEventList() ? 'Update Event List' : 'Create Event List'), value: 1 });
        actions.push({ label: 'Start New Order', value: 2 });
        actions.push({ label: 'Add to Order', value: 3 });
        return actions;
    }

    private getCustomerName = (productlistDetail: ProductlistDetail): string | null => {
        let customerName: string | null = null;
        if (productlistDetail.customerAccount) {
            customerName = productlistDetail.customerAccount.nameOnly + " - ";
            customerName += productlistDetail.customerAccount.billto;
            if (productlistDetail.customerAccount.shipto) {
                customerName += "-";
                customerName += productlistDetail.customerAccount.shipto;
            }
        }
        return customerName;
    }

    private hasCustomer = (): boolean => {
        return this.state.productlistDetail.customerAccount ? true : false;
    }

    private hasEventList = (): boolean => {
        let hasList: boolean = false;
        if (this.props.productlistDetail.assignedEventLists.length > 0 && this.props.productlistDetail.customerNumber) {                
            let matches: AssignedEventList[] = this.props.productlistDetail.assignedEventLists.filter((el: AssignedEventList) => {
                return el.customerNumber ===  this.props.productlistDetail.customerNumber;
            });
            hasList = matches.length > 0 ? true : false;            
        }
        return hasList;
    }

    private initializeForm = (): void => {
        let selectedAction: number = 0;
        this.setState({
            productlistDetail: cloneDeep(this.props.productlistDetail),
            changesAccepted: false,
            changesRejected: false,
            submitting: false,
            retrieving: false,
            retrievingOrder: false,
            actions: this.getActions(),
            selectedAction: selectedAction,
            eventListAnimationComplete1: false,
            eventListAnimationComplete2: false
        });
        this.props.change("action", selectedAction);
        this.props.change("account", this.getCustomerName(this.props.productlistDetail));
    }

    private onEventListRowChange1 = (): void => {
        this.props.change("account", this.getCustomerName(this.state.productlistDetail));
        setTimeout(() => {
            this.setState({
                eventListAnimationComplete1: (this.state.selectedAction === 1 && this.hasCustomer()) ? true : false
            });
        }, 400);
    }

    private onEventListRowChange2 = (): void => {
        this.setFocusOnCustomerFinder();
        setTimeout(() => {
            this.setState({
                eventListAnimationComplete2: (this.state.selectedAction === 1 && !this.hasCustomer()) ? true : false
            });
            this.props.change('account', null);
        }, 400);
    }

    private onSelectCustomer = (selectedCustomer: CustomerName | null): void => {
        if (selectedCustomer) {
            this.retrieveAccount(selectedCustomer);
        }
    }

    private onSelectAction = (event: React.ChangeEvent): void => {
        let selectedValue = event.target.getAttribute("value");
        if (selectedValue && parseInt(selectedValue) != this.state.selectedAction) {
            this.props.reset();
            this.setState({
                selectedAction: parseInt(selectedValue)
            });
        }
    }

    private publishToEventList = (): void => {
        this.setState({
            submitting: true
        });
        this.props.asyncActions.publishProductlistToEventAsync(this.state.productlistDetail.client, this.state.productlistDetail)
            .then(result => {
                if (result) {
                    this.setState({
                        submitting: false,
                        changesAccepted: true,
                        formResult: (this.hasEventList() ? 'Event list updated (ID = ' : 'Event list created (ID =') + result.eventListId + ')'
                    });
                    setTimeout(() => {
                        this.props.onDismiss();
                    }, 5000);
                }
                else {
                    this.setState({
                        submitting: false,
                        changesRejected: true,
                        formResult: 'Event list failed to publish'
                    })
                }
            },
            err => {
                this.props.onDismiss();
            }
        )
    }

    private resetForm = (): void => {
        this.setState({
            submitting: false,
            retrieving: false,
            retrievingOrder: false,
            changesRejected: false,
            formResult: null,
            selectedAction: 0
        });
        this.props.reset();
    }

    private retrieveAccount = (selectedCustomer: CustomerName): void => {
        this.setState({
            retrievingAccount: true
        });
        this.props.asyncActions.requestCustomerDetailAsync(this.state.productlistDetail.client.code,
            selectedCustomer.billto, selectedCustomer.shipto, false)
            .then(result => {
                if (result) {
                    let customer: CustomersStore.CustomerAccount = result as CustomersStore.CustomerAccount;
                    let updatedProductListDetail: ProductlistDetail = cloneDeep(this.state.productlistDetail);
                    updatedProductListDetail.billto = customer.billto;
                    updatedProductListDetail.customerAccount = customer;
                    updatedProductListDetail.customerName = customer.name;
                    updatedProductListDetail.customerNameOnly = customer.nameOnly;
                    updatedProductListDetail.customerNumber = customer.number;
                    updatedProductListDetail.shipto = customer.shipto;
                    this.setState({
                        productlistDetail: updatedProductListDetail,
                        retrievingAccount: false
                    });
                }
                else {
                    this.setState({
                        retrievingAccount: false
                    });
                    this.props.actions.broadcastMessage({
                        messageType: MessageStore.MessageType.ERROR,
                        text: 'Account not found',
                        action: 'requestCustomerDetail',
                        reference: null
                    });
                }
            },
            err => {
                this.setState({
                    retrievingAccount: false
                });
            }
        )
    }

    private setFocusOnCustomerFinder = (): void => {
        let customerFinder: JQuery<Element> = $('#customer-finder .rbt-input');
        if (customerFinder.length > 0) {
            setTimeout(() => {
                customerFinder.focus();
            }, 400);
        }
        else {
            setTimeout(() => {
                this.setFocusOnCustomerFinder();
            }, 250);
        }
    }

    private submitPublishProductlistForm = (): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        ordersState: state.orders,
        orderItemsState: state.orderItems,
        productlistsState: state.productlists,
        userState: state.user
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrdersStore.actionCreators,
            OrderItemsStore.actionCreators,
            ProductlistsStore.actionCreators
        ), dispatch),
        asyncActions: {
            addOrderItemAsync: (client: Client, newOrderItem: NewOrderItem) => dispatch(OrderItemsStore.actionCreators.addOrderItem(client, newOrderItem)),
            requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => dispatch(CustomersStore.actionCreators.requestCustomerDetail(clientCode, billto, shipto, updateState)),
            publishProductlistToEventAsync: (client: Client, productlistToPublish: ProductlistDetail) => dispatch(ProductlistsStore.actionCreators.publishProductlistToEvent(client, productlistToPublish))
        }
    };
}

export default connect<{}, {}, PublishProductlistOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'publishProductlistForm',
    validate: validatePublishProductlistForm,
    enableReinitialize: true
})(PublishProductlist as any));