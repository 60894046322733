import * as React from "react";
import { Nav } from "reactstrap";
import { WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form';
import CustomerFinder from '../customer-finder/CustomerFinder';
import ProductlistFinder, { ProductlistName } from '../productlist-finder/ProductlistFinder';
import OrderList from '../order-list/OrderList';
import { Order } from '../../store/Orders';
import { Category } from '../../store/Categories';
import { CustomerDetail } from '../../store/Customers';
import { CustomerName } from '../../common/AccountTypes';
import BuyerAccountPicker from '../buyer-account-picker/BuyerAccountPicker';
import Checkbox from '../checkbox/Checkbox';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../date-picker-input/DatePickerInput';
import ShipToPicker from '../shipto-picker/ShipToPicker';
import { Button, CustomInput, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Dropdown } from 'reactstrap';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import TreeItemContentProps from '@material-ui/lab/TreeItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// ----------------
// EVENT HANDLERS

export const handleInputPaste = (e: React.ClipboardEvent<HTMLInputElement>, allowedCharacters: number[], suppressedCharacters: number[]) => {
    let data: string = e.clipboardData.getData("text");
    let hasAllowedCharacters: boolean = allowedCharacters.length > 0;
    let hasSuppressedCharacters: boolean = suppressedCharacters.length > 0;

    if (data && (hasAllowedCharacters || hasSuppressedCharacters)) {
        let sanitizedData: string = "";
        let chars: string[] = data.split("");
        for (let n: number = 0; n < chars.length; n++) {
            let cc: number = chars[n].charCodeAt(0);
            if (hasAllowedCharacters && allowedCharacters.includes(cc)) {
                sanitizedData += chars[n];
            }
            else if (hasSuppressedCharacters && !suppressedCharacters.includes(cc)) {
                sanitizedData += chars[n];                
            }
        }
        e.currentTarget.value = sanitizedData;
        e.preventDefault();
    }
}

export const handleInputPasteRegex = (e: React.ClipboardEvent<HTMLInputElement>, regex: RegExp) => {
    let data: string = e.clipboardData.getData("text");
    if (!regex.test(data)) {
        e.preventDefault();
    }
}

export const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, allowedCharacters: number[], suppressedCharacters: number[]) => {
    if (!isControlCharacter(e)) {
        if (allowedCharacters.length > 0) {
            if (!allowedCharacters.includes(e.keyCode)) {
                e.preventDefault();
            }
        }
        if (suppressedCharacters.length > 0) {            
            if (suppressedCharacters.includes(e.keyCode)) {
                e.preventDefault();
            }            
        }
    }
}

export const handleInputKeyDownRegex = (e: React.KeyboardEvent<HTMLInputElement>, regex: RegExp) => {
    let inputValue: string = e.currentTarget.value;
    if (!isControlCharacter(e)) {
        inputValue += e.key;
        if (!regex.test(inputValue)) {
            e.preventDefault();
        }
    }
}

export const isControlCharacter = (e: React.KeyboardEvent<HTMLInputElement>): boolean => {
    let cc: boolean = false;
    let navigationKeys: number[] = [46, 8, 9, 27, 13, 110];
    let controlKeys: number[] = [65, 67, 86, 88, 35, 36, 37, 38, 39]
   
    if (e.ctrlKey || e.metaKey) {
        cc = controlKeys.includes(e.keyCode);
    }
    else {
        cc = navigationKeys.includes(e.keyCode);
    }
    return cc;
}

// ------------------
// PASSWORD FIELD

interface CustomWrappedPasswordFieldProps {
    label?: string;
    type?: string;
    maxLength?: number;
    passwordVisible: boolean;
    showPasswordHandler: () => void;
    reference?: any;
    disabled?: boolean;
}

export const renderPasswordField = ({ input, label, type, maxLength, passwordVisible, showPasswordHandler, reference, disabled, meta }: WrappedFieldProps & CustomWrappedPasswordFieldProps): JSX.Element => {
    let autoCompleteValue: string = "no_" + input.name.toLowerCase() + "_autocomplete";
    let maxLengthValue: number = maxLength ? maxLength : 25;
    let disabledValue: boolean = disabled === true ? true : false;

    let allowedCharacters: number[] = [];
    let suppressedCharacters: number[] = [32];

    return (
        <fieldset className={"form-group with-validation" + (meta.touched && meta.submitFailed && meta.error ? " has-error" : "")}>
            <label>{label}</label>
            <div className="password-container">
                <input {...input} type={type} className="form-control"
                    maxLength={maxLengthValue} autoComplete={autoCompleteValue} spellCheck={false}
                    ref={reference ? node => reference.current = node : null}
                    onKeyDown={(e) => handleInputKeyDown(e, allowedCharacters, suppressedCharacters)}
                    onPaste={(e) => handleInputPaste(e, allowedCharacters, suppressedCharacters)}
                    disabled={disabledValue} />
                <a onClick={showPasswordHandler}>
                    {passwordVisible && (
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="  M16,7C9.934,7,4.798,10.776,3,16c1.798,5.224,6.934,9,13,9s11.202-3.776,13-9C27.202,10.776,22.066,7,16,7z" fill="none" stroke="#a9a9a9" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
                            <circle cx="16" cy="16" fill="none" r="5" stroke="#a9a9a9" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
                            <line fill="none" stroke="#a9a9a9" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" x1="3" x2="29" y1="3" y2="29" />
                        </svg>
                    )}
                    {!passwordVisible && (
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="  M16,7C9.934,7,4.798,10.776,3,16c1.798,5.224,6.934,9,13,9s11.202-3.776,13-9C27.202,10.776,22.066,7,16,7z" fill="none" stroke="#a9a9a9" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
                            <circle cx="16" cy="16" fill="none" r="5" stroke="#a9a9a9" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
                            </svg>
                    )}
                </a>
            </div>
            <div className="validation-error-container">
                {meta.touched && meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// TEXT FIELD

interface CustomWrappedTextFieldProps {
    label?: string;
    type?: string;
    maxLength?: number;
    noSpaces?: boolean;
    numericOnly?: boolean;
    autoComplete?: boolean;
    autoFocus?: boolean;
    reference?: any;
    disabled?: boolean;
    readonly?: boolean;
    placeholder?: string;
    tabIndex?: number;
}

export const renderTextField = ({ input, label, type, maxLength, noSpaces, numericOnly, autoComplete, autoFocus, reference, disabled, readonly, placeholder, tabIndex, meta}: WrappedFieldProps & CustomWrappedTextFieldProps): JSX.Element => {
    let autoCompleteValue: string = autoComplete ? "on" : "no_" + input.name.toLowerCase() + "_autocomplete";
    let autoFocusValue: boolean = autoFocus === true ? true : false;
    let maxLengthValue: number = maxLength ? maxLength : 255;
    let disabledValue: boolean = disabled === true ? true : false;

    let allowedCharacters: number[] = [];
    let suppressedCharacters: number[] = [];

    if (numericOnly) {
        allowedCharacters.push(48,49,50,51,52,53,54,55,56,57);
    }
    else if (noSpaces === true) {
        suppressedCharacters.push(32);
    }

    return (
        <fieldset className={"form-group with-validation" + (meta.touched && meta.submitFailed && meta.error ? " has-error": "")}>
            {label && (
                <label>{label}</label>
            )}
            <input {...input} type={type} className={"form-control" + (readonly ? " borderless" : "")} autoFocus={autoFocusValue}
                maxLength={maxLengthValue} autoComplete={autoCompleteValue} spellCheck={false}
                ref={reference ? node => reference.current = node : null} placeholder={placeholder ? placeholder : undefined }
                onKeyDown={(e) => handleInputKeyDown(e, allowedCharacters, suppressedCharacters)}
                onPaste={(e) => handleInputPaste(e, allowedCharacters, suppressedCharacters)}
                disabled={disabledValue} readOnly={readonly} tabIndex={readonly ? -1 : tabIndex ? tabIndex : 0} />
            <div className="validation-error-container">
                {meta.touched && meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
};

// ------------------
// TEXTAREA FIELD

interface CustomWrappedTextareaFieldProps {
    label?: string;
    maxLength?: number;
    rows?: number;
    reference?: any;
    disabled?: boolean;
    readonly?: boolean;
    placeholder?: string;
    defaultValue?: string;
    tabIndex?: number;
}

export const renderTextareaField = ({ input, label, maxLength, rows, reference, disabled, readonly, placeholder, defaultValue, tabIndex, meta }: WrappedFieldProps & CustomWrappedTextareaFieldProps): JSX.Element => {
    let maxLengthValue: number = maxLength ? maxLength : 255;
    let disabledValue: boolean = disabled === true ? true : false;

    return (
        <fieldset className={"form-group with-validation" + (meta.touched && meta.submitFailed && meta.error ? " has-error" : "")}>
            {label && (
                <label>{label}</label>
            )}
            <textarea {...input} className={"form-control" + (readonly ? " borderless" : "")} rows={rows ? rows : 2}
                maxLength={maxLengthValue} ref={reference ? node => reference.current = node : null} defaultValue={defaultValue}
                disabled={disabledValue} readOnly={readonly} placeholder={placeholder} onChange={input.onChange}
                tabIndex={readonly ? -1 : (tabIndex || 0)} />
            <div className="validation-error-container">
                {meta.touched && meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// RADIO BUTTON GROUP

interface CustomWrappedRadioButtonFieldProps {
    label?: string;
    type?: string;
    options: OptionValue[];    
    reference?: any;
    disabled?: boolean;
    disabledOptions?: number[];
    tabIndex?: number;
}

export const renderRadioButtonField = ({ input, label, type, options, reference, disabled, disabledOptions, tabIndex, meta }: WrappedFieldProps & CustomWrappedRadioButtonFieldProps): JSX.Element => {
    let inputValue: number = input.value ? parseInt(input.value) : 0;
    let disabledValue: boolean = disabled === true ? true : false;
    return (
        <fieldset {...input} className={"form-group with-validation inline" + (meta.submitFailed && meta.error ? " has-error" : "")}
            ref={reference ? node => reference.current = node : null}>
            {label && (
                <label className="d-block">{label}</label>
            )}
            <div className="radio-button-container">
                {options.map((option, index) => {
                    let isDisabled: boolean | undefined = disabledValue || (disabledOptions && disabledOptions.length > index && disabledOptions[index] === 1);
                    return (
                        <label className={"radio-button-wrapper" + (isDisabled ? " disabled" : "")} key={"roleOption" + index}>
                            <input type="radio" name={input.name} value={option.value} checked={inputValue === option.value}
                                disabled={isDisabled} onChange={input.onChange} onFocus={input.onFocus} tabIndex={tabIndex || 0} />
                            <span>{option.label}</span>
                        </label>
                    );
                })}
            </div>
            <div className="validation-error-container">
                {meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// CHECKBOX

interface WrappedCheckboxFieldProps {
    id: string;
    label?: string;
    inlineLabel?: string;
    onCheck: (checked: boolean) => void;
    selected: boolean;
    disabled: boolean;    
}

export const renderCheckboxField = ({ input, id, label, inlineLabel, onCheck, selected, disabled, meta }: WrappedFieldProps & WrappedCheckboxFieldProps): JSX.Element => {
    return (
        <fieldset {...input} className={"form-group with-validation" + (meta.submitFailed && meta.error ? " has-error" : "")}>
            {label && (
                <label className="d-block">{label}</label>
            )}
            <Checkbox id={id} label={inlineLabel} selected={selected} disabled={disabled} onChange={onCheck} />
            <div className="validation-error-container">
                {meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// CHECKBOX GROUP

interface WrappedCheckboxGroupFieldProps {
    id: string;
    label?: string;
    type?: string;
    options: OptionValue[];
    onCheck: (index: number, checked: boolean) => void;
    reference?: any;
    disabled?: boolean;
    disabledOptions?: number[];
    tabIndex?: number;
}

export const renderCheckboxGroupField = ({ input, id, label, type, options, onCheck, reference, disabled, disabledOptions, tabIndex, meta }: WrappedFieldProps & WrappedCheckboxGroupFieldProps): JSX.Element => {
    let disabledValue: boolean = disabled === true ? true : false;
    return (
        <fieldset {...input} className={"form-group with-validation inline" + (meta.submitFailed && meta.error ? " has-error" : "")}
            ref={reference ? node => reference.current = node : null}>
            {label && (
                <label className="d-block">{label}</label>
            )}
            <div className="checkbox-group-container">
                {options.map((option, index) => {
                    let isDisabled: boolean = disabledValue || (disabledOptions && disabledOptions.length > index && disabledOptions[index] === 1) ? true : false;
                    return (
                        <Checkbox id={id + '-' + index} key={"cb-" + index} label={option.label} selected={option.value === true ? true : false} disabled={isDisabled} onChange={(checked: boolean) => onCheck(index, checked)} />
                    );
                })}
            </div>
            <div className="validation-error-container">
                {meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    )
}

// ------------------
// OPTION VALUE

export interface OptionValue {
    label: string,
    value: any
}

// ------------------
// DROPDOWN

interface CustomWrappedDropdownFieldProps {
    label?: string;
    type?: string;
    options: OptionValue[];
    reference?: any;
    defaultValue: string;
    disabled?: boolean;
    open?: boolean;    
    onSelectOption: (selectedValue: OptionValue) => void;
    onToggle?: (event: React.KeyboardEvent | React.MouseEvent) => void;
    tabIndex?: number;
}

export const renderDropdownField = ({ input, label, type, options, reference, defaultValue, disabled, open, onSelectOption, onToggle, tabIndex, meta }: WrappedFieldProps & CustomWrappedDropdownFieldProps): JSX.Element => {
    return (
        <fieldset {...input} className={"form-group with-validation" + (meta.submitFailed && meta.error ? " has-error" : "")}>
            {/*<UncontrolledDropdown ref={reference ? node => reference.current = node : null}>*/}
            <Dropdown ref={reference ? node => reference.current = node : null} isOpen={open} toggle={onToggle} tabIndex={tabIndex ? tabIndex : 0}>
                {label && (
                    <label>{label}</label>
                )}
                <DropdownToggle disabled={disabled} tabIndex={-1}>
                    <div className="dropdown-label">
                        <span>{input.value ? input.value : defaultValue}</span>
                    </div>
                    <div className="dropdown-toggler">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-down">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-up">
                            <polyline points="18 15 12 9 6 15"></polyline>
                        </svg>
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    {options.map((option, index) => {
                        return (
                            <DropdownItem key={"option" + index} itemProp={option.value} className={index % 2 === 1 ? "odd" : ""}
                                onClick={() => onSelectOption(option)}>
                                <div className="option-label">{option.label}</div>
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </Dropdown>
            {/*</UncontrolledDropdown>*/}
            <div className="validation-error-container">
                {meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// FILE INPUT

interface WrappedFileInputFieldProps {
    id: string;
    label?: string;
    placeholder: string;
    reference?: any;
    onSelectFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export const renderFileInputField = ({ input, id, label, placeholder, reference, onSelectFile, disabled, meta }: WrappedFieldProps & WrappedFileInputFieldProps): JSX.Element => {
    return (        
        <fieldset {...input} className={"form-group with-validation" + (meta.submitFailed && meta.error ? " has-error" : "")}>
            {label && (
                <label className="d-block">{label}</label>
            )}
            <CustomInput type="file" id={id} onChange={onSelectFile} invalid={meta.submitFailed && meta.error} label={placeholder}
                disabled={disabled} innerRef={reference ? node => reference.current = node : null} />
            <div className="validation-error-container">
                {meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// DATE PICKER

interface WrappedDatePickerFieldProps {
    label?: string;
    autoComplete?: string;
    onSelectDate: (date: Date) => void;
    selectedDate: Date | null | undefined;
    minDate: Date | null | undefined;
    reference?: any;
    disabled?: boolean;
    placeholder?: string;
    wrapperClass?: string;
    hideClearButton?: boolean;
}

export const getDatePickerInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
        <DatePickerInput datePickerProps={props} readOnly={true} tooltipText="Select Date" hideClearButton={false} />
    );
}

export const getDatePickerInputWithoutClearButton = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
        <DatePickerInput datePickerProps={props} readOnly={true} tooltipText="Select Date" hideClearButton={true} />
    );
}

export const renderDatePickerField = ({ input, label, autoComplete, onSelectDate, selectedDate, minDate, reference, disabled, placeholder, wrapperClass, hideClearButton, meta }: WrappedFieldProps & WrappedDatePickerFieldProps): JSX.Element => {
    let datepicker: React.ReactNode = hideClearButton ?
        React.createElement(React.forwardRef(getDatePickerInputWithoutClearButton)) :
        React.createElement(React.forwardRef(getDatePickerInput));

    return (
        <fieldset {...input} className={"form-group with-validation" + (meta.submitFailed && meta.error ? " has-error" : "")}>
            {label && (
                <label className="d-block">{label}</label>
            )}
            <DatePicker selected={selectedDate} onChange={onSelectDate} customInput={datepicker}
                autoComplete={autoComplete} disabled={disabled} minDate={minDate} dateFormat={"M/d/yyyy"} wrapperClassName={wrapperClass}
                isClearable={!hideClearButton} clearButtonClassName={"datepicker-input-clear-button"} ref={reference ? node => reference.current = node : null} placeholderText={placeholder ? placeholder : ''} />
            <div className="validation-error-container">
                {meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// CATEGORY LIST

interface WrappedCategoryListFieldProps {
    label?: string,
    prompt?: string,
    type?: string,
    categories: Category[];
    expandedCategories: string[];
    selectedCategories: string[];
    reference?: any;
    defaultValue: string;
    disabled?: boolean;
    open?: boolean;
    onSelectCategory: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void;
    onToggle?: (event: React.KeyboardEvent | React.MouseEvent) => void;
    onToggleCategory?: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void;
    tabIndex?: number;
}

export const renderCategoryListField = ({ input, label, type, categories, expandedCategories, selectedCategories, reference, defaultValue, disabled, open, onSelectCategory, onToggle, onToggleCategory, tabIndex, meta }: WrappedFieldProps & WrappedCategoryListFieldProps): JSX.Element => {
    if (!categories) {
        categories = [];
    }

    return (
        <fieldset {...input} className={"form-group with-validation" + (meta.submitFailed && meta.error ? "has-error" : "")} >
            {label && (
                <label className="d-block">{label}</label>
            )}
            <Dropdown ref={reference ? node => reference.current = node : null} isOpen={open}
                toggle={onToggle} tabIndex={tabIndex ? tabIndex : 0}>
                <DropdownToggle disabled={disabled} tabIndex={tabIndex}>
                    <div className="dropdown-label">
                        <span>{input.value ? input.value : defaultValue}</span>
                    </div>
                    <div className="dropdown-toggler">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-down">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-up">
                            <polyline points="18 15 12 9 6 15"></polyline>
                        </svg>
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    <div className="categories">
                        <TreeView
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            expanded={expandedCategories}
                            selected={selectedCategories}
                            onNodeToggle={onToggleCategory}
                            onNodeSelect={onSelectCategory}>
                            <TreeItem nodeId={"AllCategories"} key={"category-0"} label="All" className="category" />
                            {categories.map((category: Category, categoryIndex: number) => (
                                <TreeItem nodeId={category.groupId} key={"category-" + categoryIndex} label={category.name} className="category">
                                    {category.items.map((subcategory1: Category, subcategory1Index: number) => (
                                        <TreeItem nodeId={subcategory1.groupId} key={"subcategory1-" + subcategory1Index} label={subcategory1.name} className="subcategory">
                                            {subcategory1.items.map((subcategory2: Category, subcategory2Index: number) => (
                                                <TreeItem nodeId={subcategory2.groupId} key={"subcategory2-" + subcategory2Index} label={subcategory2.name} className="subcategory">
                                                    {subcategory2.items.map((subcategory3: Category, subcategory3Index: number) => (
                                                        <TreeItem nodeId={subcategory3.groupId} key={"subcategory3-" + subcategory3Index} label={subcategory3.name} className="subcategory">
                                                        </TreeItem>
                                                    ))}
                                                </TreeItem>
                                            ))}
                                        </TreeItem>
                                    ))}
                                </TreeItem>
                            ))}
                        </TreeView>
                    </div>
                </DropdownMenu>
            </Dropdown>
            <div className="validation-error-container">
                {meta.touched && meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// CUSTOMER FINDER

interface WrappedCustomerFinderFieldProps {    
    label?: string;
    reference?: any;
    onSelectCustomer: (selected: CustomerName | null) => void;
    resetFinder?: boolean;
    disabled?: boolean;
}

export const renderCustomerFinderField = ({ input, label, reference, onSelectCustomer, resetFinder, disabled, meta }: WrappedFieldProps & WrappedCustomerFinderFieldProps): JSX.Element => {
    return (
        <fieldset {...input} className={"form-group with-validation" + (meta.submitFailed && meta.error ? " has-error" : "")}
            ref={reference ? node => reference.current = node : null}>
            {label && (
                <label className="d-block">{label}</label>
            )}
            <CustomerFinder clearInput={resetFinder} initialValue={input.value} onChange={onSelectCustomer} disabled={disabled} />
            <div className="validation-error-container finder-validation-error">
                {meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// PRODUCTLIST FINDER

interface WrappedProductlistFinderFieldProps {
    label?: string;
    reference?: any;
    editableListsOnly?: boolean;
    onSelectProductlist: (selected: ProductlistName | null) => void;
    resetFinder?: boolean;
    disabled?: boolean;    
}

export const renderProductlistFinderField = ({ input, label, reference, editableListsOnly, onSelectProductlist, resetFinder, disabled, meta }: WrappedFieldProps & WrappedProductlistFinderFieldProps): JSX.Element => {
    return (
        <fieldset {...input} className={"form-group with-validation" + (meta.submitFailed && meta.error ? " has-error" : "")}
            ref={reference ? node => reference.current = node : null}>
            {label && (
                <label className="d-block">{label}</label>
            )}
            <ProductlistFinder clearInput={resetFinder} initialValue={input.value} editableListsOnly={editableListsOnly && editableListsOnly === true ? true : false}
                onChange={onSelectProductlist} disabled={disabled} />
            <div className="validation-error-container finder-validation-error">
                {meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// CUSTOMER LIST

interface WrappedCustomerListFieldProps {
    label?: string;
    prompt?: string;
    type?: string;
    options: CustomerName[];
    reference?: any;
    disabled?: boolean;
    onSelectCustomer: (selectedCustomer: CustomerName) => void;
}

export const renderCustomerListField = ({ input, label, prompt, type, options, reference, disabled, onSelectCustomer, meta }: WrappedFieldProps & WrappedCustomerListFieldProps): JSX.Element => {
    return (
        <fieldset {...input} className={"form-group with-validation" + (meta.submitFailed && meta.error ? " has-error" : "")}>
            {label && (
                <label>{label}</label>
            )}
            <UncontrolledDropdown className="customer-list-field" ref={reference ? node => reference.current = node : null}>
                <DropdownToggle disabled={disabled ? true : false}>
                    <div className="dropdown-label">
                        {input.value && (
                            <div className="customer-number">{input.value.number}</div>
                        )}
                        <div className="customer-name">{input.value ? input.value.nameOnly : prompt ? prompt : 'Select Customer ...'}</div>
                    </div>
                    <div className="dropdown-toggler">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-down">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-up">
                            <polyline points="18 15 12 9 6 15"></polyline>
                        </svg>
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    {options.map((option: CustomerName, optionIndex: number) => (
                        <DropdownItem key={"cust" + optionIndex} itemProp={option.number} className={optionIndex % 2 === 1 ? "odd" : ""}
                            onClick={() => onSelectCustomer(option)}>
                            <div className="customer-number">{option.number}</div>
                            <div className="customer-name">{option.nameOnly}</div>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
            <div className="validation-error-container">
                {meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// ORDER LIST

interface WrappedOrderListFieldProps {
    label?: string;
    type?: string;
    options: Order[];
    autoSelect?: boolean;
    reference?: any;
    disabled?: boolean;
    onSelectOrder: (order: Order | null | undefined) => void;
}

export const renderOrderListField = ({ input, label, type, options, autoSelect, reference, disabled, onSelectOrder, meta }: WrappedFieldProps & WrappedOrderListFieldProps): JSX.Element => {
    return (
        <fieldset {...input} className={"form-group with-validation" + (meta.submitFailed && meta.error ? " has-error" : "")}>
            {label && (
                <label>{label}</label>
            )}
            <OrderList orders={options} onSelectOrder={onSelectOrder} disabled={disabled ? true : false} autoSelect={autoSelect ? true : false} />
            <div className="validation-error-container">
                {meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// SHIPTO LIST

interface WrappedShipToListFieldProps {
    label?: string;
    prompt?: string;
    type?: string;
    options: CustomerDetail[];
    reference?: any;
    disabled?: boolean;
    onSelectShipTo: (selectedShipTo: CustomerDetail) => void;
}

export const renderShipToListField = ({ input, label, prompt, type, options, reference, disabled, onSelectShipTo, meta }: WrappedFieldProps & WrappedShipToListFieldProps): JSX.Element => {
    return (
        <fieldset {...input} className={"form-group with-validation" + (meta.submitFailed && meta.error ? " has-error" : "")}>
            {label && (
                <label>{label}</label>
            )}
            <ShipToPicker shipToLocations={options} placeholder={prompt || ''} showSelectedItem={true} onSelectShipTo={onSelectShipTo} disabled={disabled ? true : false} />
            <div className="validation-error-container">
                {meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// BUYER ACCOUNT LIST

interface WrappedBuyerAccountListFieldProps {
    label?: string;
    prompt?: string;
    type?: string;
    options: CustomerName[];
    preselectedAccount: CustomerName | null | undefined,
    reference?: any;
    disabled?: boolean;
    onSelectBuyerAccount: (selectedBuyerAccount: CustomerName | null) => void;
}

export const renderBuyerAccountListField = ({ input, label, prompt, type, options, preselectedAccount, reference, disabled, onSelectBuyerAccount, meta }: WrappedFieldProps & WrappedBuyerAccountListFieldProps): JSX.Element => {
    return (
        <fieldset {...input} className={"form-group with-validation" + (meta.submitFailed && meta.error ? " has-error" : "")}>
            {label && (
                <label>{label}</label>
            )}
            <BuyerAccountPicker buyerAccounts={options} placeholder={input.value ? input.value : prompt ? prompt : 'Select...'} onSelectBuyerAccount={onSelectBuyerAccount}
                initialValue={preselectedAccount} disabled={disabled ? true : false} />
            <div className="validation-error-container">
                {meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// LINK BUTTON

interface CustomWrappedLinkButtonFieldProps {
    label?: string;
    buttonLabel: string;
    reference?: any;
    disabled?: boolean;
    onClick: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export const renderLinkButtonField = ({ input, label, buttonLabel, reference, disabled, onClick, meta }: WrappedFieldProps & CustomWrappedLinkButtonFieldProps): JSX.Element => {
    let disabledValue: boolean = disabled === true ? true : false;
    return (
        <fieldset className={"form-group with-validation" + (meta.touched && meta.submitFailed && meta.error ? " has-error" : "")}>
            {label && (
                <label>{label}</label>
            )}
            <div className="form-group-field-container">
                <Button color="link" className="form-group-field" ref={reference ? node => reference.current = node : null} disabled={disabledValue}
                    onClick={onClick}>
                    <label>{buttonLabel}</label>
                </Button>
                <div className="validation-error-container">
                    {meta.touched && meta.submitFailed && meta.error && (
                        <span>{meta.error}</span>
                    )}
                </div>
            </div>
        </fieldset>
    );
}

// ------------------
// HIDDEN FIELD

interface CustomWrappedHiddenFieldProps {
    type?: string;
}

export const renderHiddenField = ({ input, type, meta }: WrappedFieldProps & CustomWrappedHiddenFieldProps): JSX.Element => {
    return (
        <fieldset className={"form-group with-validation" + (meta.touched && meta.submitFailed && meta.error ? " has-error" : "")}>
            <input {...input} type={type} />
            <div className="validation-error-container">
                {meta.touched && meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}

// ------------------
// LABEL FIELD

interface CustomWrappedFieldPlaceholderProps {
    label: string;
    type?: string;
}

export const renderFieldPlaceholder = ({ input, type, label, meta }: WrappedFieldProps & CustomWrappedFieldPlaceholderProps): JSX.Element => {
    return (
        <fieldset className={"form-group with-validation" + (meta.touched && meta.submitFailed && meta.error ? " has-error" : "")}>
            <div className="field-placeholder">
                <label>{label}</label>
            </div>
            <div className="validation-error-container">
                {meta.touched && meta.submitFailed && meta.error && (
                    <span>{meta.error}</span>
                )}
            </div>
        </fieldset>
    );
}