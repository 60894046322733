﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as MessageStore from './Message';
import * as ErrorMessage from '../common/ErrorMessage';
import { Client } from './Client';
import { ClientCode } from '../enums/ClientCode';
import { CustomerType } from '../enums/CustomerType';
import { Address, Country, State } from '../common/AddressTypes';
import { CustomerContact, Terms, ShipVia, FreightTerms, SalesChannel } from '../common/AccountTypes';
import { Logo } from '../common/EmbroideryTypes';
import { Query, QueryResult } from 'material-table';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CustomersState {
    customers: Customers | null;
    customerDetail: CustomerAccount | null | undefined;
    clientCode: ClientCode;
    isLoading: boolean;
    isLoadingDetail: boolean;
    isLoadingLogos: boolean;
}

export interface Customer {
    billto: string;
    shipto: string | null | undefined;
    name: string;
    nameOnly: string;
    number: string;
    postalCode: string | null | undefined;
    type: CustomerType;
    country: Country;
    state: State;
    city: string;
    street: string;
    locationDescription: string;
    salesChannel: SalesChannel;
}

export interface CustomerDetail {
    address: Address;
    attention: string | null | undefined;
    billto: string;
    contact: string;
    displayNumber: string;
    name: string;
    nameOnly: string;
    number: string;
    phone: string | null | undefined;
    selected: boolean;
    shipto: string | null | undefined;
    type: CustomerType;
    locationDescription: string;
}

export interface CustomerAccount extends CustomerDetail {
    balance: number | null | undefined;
    class: string | null | undefined;
    client: Client | null | undefined,
    consolidatedBalance: number | null | undefined;
    contacts: CustomerContact[];
    creditAvailable: number | null | undefined;
    creditLimit: number | null | undefined;
    csrNumber: string | null | undefined;
    discount: number;
    freightTerms: FreightTerms;
    openAccountsReceivable: number | null | undefined;
    overdueBalance: number | null | undefined;
    poRequired: boolean;
    salesChannel: SalesChannel;
    salesRepNumber: string | null | undefined;
    salesLastYear: number | null | undefined;
    salesYearToDate: number | null | undefined;
    shipToLocations: CustomerDetail[];
    shipVia: ShipVia;
    terms: Terms;
}

export interface Customers extends QueryResult<Customer> {
    query: Query<any>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestCustomersAction {
    type: 'REQUEST_CUSTOMERS';
    clientCode: ClientCode;
    query: Query<any>;
}

interface ReceiveCustomersAction {
    type: 'RECEIVE_CUSTOMERS';
    clientCode: ClientCode;
    customers: Customers | null;
}

interface RequestCustomerDetailAction {
    type: 'REQUEST_CUSTOMER_DETAIL';
    clientCode: ClientCode;
    id: string;
}

export interface ReceiveCustomerDetailAction {
    type: 'RECEIVE_CUSTOMER_DETAIL';
    clientCode: ClientCode;
    customerDetail: CustomerAccount | null | undefined;
}

interface RequestCustomerLogosAction {
    type: 'REQUEST_CUSTOMER_LOGOS';
    clientCode: ClientCode;
    id: string;
}

interface ReceiveCustomerLogosAction {
    type: 'RECEIVE_CUSTOMER_LOGOS';
    clientCode: ClientCode;
}

interface ClearCustomersQueryAction {
    type: 'CLEAR_CUSTOMERS_QUERY';
}

interface ClearCustomerDetailAction {
    type: 'CLEAR_CUSTOMER_DETAIL';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = RequestCustomersAction | ReceiveCustomersAction |
    RequestCustomerDetailAction | ReceiveCustomerDetailAction |
    RequestCustomerLogosAction | ReceiveCustomerLogosAction |
    ClearCustomersQueryAction | ClearCustomerDetailAction |
    MessageStore.BroadcastMessageAction;   

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestCustomers: (clientCode: ClientCode, query: Query<Customer>): AppThunkAction<KnownAction> => (dispatch, getState): Promise<QueryResult<Customer>> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.customers && !appState.customers.isLoading) {

                let clientAwareQuery: object = Object.assign({}, query, { clientCode: clientCode });
                let fetchError: boolean = false;
                let action: string = 'customers/get';
                let url: string = `${action}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(clientAwareQuery)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_CUSTOMERS', clientCode: clientCode, customers: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                        let customers: Customers = data as Customers;
                        dispatch({ type: 'RECEIVE_CUSTOMERS', clientCode: clientCode, customers: customers });
                    },
                        err => {
                            reject(err.message);
                            if (!fetchError) {
                                dispatch({ type: 'RECEIVE_CUSTOMERS', clientCode: clientCode, customers: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                        });

                dispatch({ type: 'REQUEST_CUSTOMERS', clientCode: clientCode, query: query });
            }
            else {
                resolve(appState.customers ?
                    appState.customers.customers as QueryResult<Customer> :
                    { data: [], query: query, page: query.page, totalCount: 0 } as QueryResult<Customer>);
            }
        })
    },
    requestCustomerDetail: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean): AppThunkAction<KnownAction> => (dispatch, getState): Promise<CustomerAccount | null | undefined> => {
        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.customers && !appState.customers.isLoadingDetail) {

                let fetchError: boolean = false;
                let action: string = 'customers/getdetail';
                let url: string = `${action}/${clientCode}/${encodeURIComponent(billto)}/${encodeURIComponent(shipto)}`;
                let currentCustomer: CustomerAccount | null | undefined = appState.customers.customerDetail;

                fetch(url,
                    {
                        method: 'GET',
                        headers: { 'Accept': 'application/json' }
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_CUSTOMER_DETAIL', clientCode: clientCode, customerDetail: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                        let customer: CustomerAccount = data as CustomerAccount;
                        dispatch({ type: 'RECEIVE_CUSTOMER_DETAIL', clientCode: clientCode, customerDetail: updateState ? customer : currentCustomer });
                    },
                        err => {
                            reject(err.message);
                            if (!fetchError) {
                                dispatch({ type: 'RECEIVE_CUSTOMER_DETAIL', clientCode: clientCode, customerDetail: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                        })

                dispatch({ type: 'REQUEST_CUSTOMER_DETAIL', clientCode: clientCode, id: billto });
            }
            else {
                resolve(appState.customers ? appState.customers.customerDetail : undefined);
            }
        });
    },
    requestCustomerLogos: (clientCode: ClientCode, billto: string, shipto: string, query: Query<Logo>, broadcastError: boolean): AppThunkAction<KnownAction> => (dispatch, getState): Promise<QueryResult<Logo>> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.customers && !appState.customers.isLoadingLogos) {

                let clientAwareQuery: object = Object.assign({}, query, { clientCode: clientCode }, { billto: billto }, { shipto: shipto });
                let fetchError: boolean = false;
                let action: string = 'customers/getlogos';
                let url: string = `${action}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(clientAwareQuery)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_CUSTOMER_LOGOS', clientCode: clientCode });
                                    if (broadcastError) {
                                        dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                    }
                                    else {
                                        reject(errorMessage.text);
                                    }
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                        dispatch({ type: 'RECEIVE_CUSTOMER_LOGOS', clientCode: clientCode });
                    },
                    err => {
                        if (!fetchError) {
                            reject(err.message);
                            dispatch({ type: 'RECEIVE_CUSTOMER_LOGOS', clientCode: clientCode });
                            if (broadcastError) {
                                dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                            }
                        }
                    });

                dispatch({ type: 'REQUEST_CUSTOMER_LOGOS', clientCode: clientCode, id: billto });
            }
            else {
                resolve({ data: [], query: query, page: query.page, totalCount: 0 } as QueryResult<Logo>);
            }
        })
    },
    clearCustomersQuery: () => ({ type: 'CLEAR_CUSTOMERS_QUERY' } as ClearCustomersQueryAction),
    clearCustomerDetail: () => ({ type: 'CLEAR_CUSTOMER_DETAIL' } as ClearCustomerDetailAction)
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: CustomersState = {
    customers: null,
    customerDetail: null,
    clientCode: ClientCode.Undefined,
    isLoading: false,
    isLoadingDetail: false,
    isLoadingLogos: false
};

export const reducer: Reducer<CustomersState> = (state: CustomersState | undefined, incomingAction: Action): CustomersState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CUSTOMERS':
            return {
                clientCode: action.clientCode,
                customers: state.customers,
                customerDetail: state.customerDetail,
                isLoading: true,
                isLoadingDetail: false,
                isLoadingLogos: false
            }
        case 'RECEIVE_CUSTOMERS':
            return {
                clientCode: action.clientCode,
                customers: action.customers,
                customerDetail: state.customerDetail,
                isLoading: false,
                isLoadingDetail: false,
                isLoadingLogos: false
            };
        case 'REQUEST_CUSTOMER_DETAIL':
            return {
                clientCode: action.clientCode,
                customers: state.customers,
                customerDetail: state.customerDetail,
                isLoading: false,
                isLoadingDetail: true,
                isLoadingLogos: state.isLoadingLogos
            };
        case 'RECEIVE_CUSTOMER_DETAIL':
            return {
                clientCode: action.clientCode,
                customers: state.customers,
                customerDetail: action.customerDetail,
                isLoading: false,
                isLoadingDetail: false,
                isLoadingLogos: state.isLoadingLogos
            };
        case 'REQUEST_CUSTOMER_LOGOS':
            return {
                clientCode: action.clientCode,
                customers: state.customers,
                customerDetail: state.customerDetail,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingLogos: true
            };
        case 'RECEIVE_CUSTOMER_LOGOS':
            return {
                clientCode: action.clientCode,
                customers: state.customers,
                customerDetail: state.customerDetail,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingLogos: false
            };
        case 'CLEAR_CUSTOMERS_QUERY':
            return {
                clientCode: state.clientCode,
                customers: null,
                customerDetail: state.customerDetail,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingLogos: state.isLoadingLogos
            };
        case 'CLEAR_CUSTOMER_DETAIL':
            return {
                clientCode: state.clientCode,
                customers: state.customers,
                customerDetail: null,
                isLoading: state.isLoading,
                isLoadingDetail: state.isLoadingDetail,
                isLoadingLogos: state.isLoadingLogos
            }
        default:
            return state;
    }
}