import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as CustomersStore from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import * as ErrorMessage from '../../common/ErrorMessage';
import { ClientCode } from '../../enums/ClientCode';
import { FileDeliveryType } from '../../enums/FileDeliveryType';
import { NewLogo } from '../../common/EmbroideryTypes';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, CustomInput, Row } from 'reactstrap';
import $ from 'jquery';

import './TapeImporter.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
};

interface TapeImporterAsyncActions {
    asyncActions: {
    }
};

interface TapeImporterOwnProps {
    isOpen: boolean;
    clientCode: ClientCode;
    onSubmit: (submitting: boolean) => void;
    onSubmitFail: () => void;
    onSubmitSucceed: (newLogo: NewLogo) => void;
    customer: CustomersStore.CustomerAccount;
    submitRequested?: boolean;
}

type TapeImporterProps =
    TapeImporterOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & TapeImporterAsyncActions;

// ----------------
// LOCAL STATE

interface TapeImporterState {
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    deliveryOptions: FieldWrapper.OptionValue[];
    selectedDeliveryOption: FileDeliveryType;
}

// ----------------
// FORM VALIDATOR

const validateTapeImporterForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    let fileDelivery: FileDeliveryType = formValues.fileDelivery ? parseInt(formValues.fileDelivery) : FileDeliveryType.Undefined;
    switch (fileDelivery) {
        case FileDeliveryType.Undefined:
            errors.fileDelivery = 'Required';
            break;
        case FileDeliveryType.Upload:
            if (!formValues.embroideryFile || formValues.embroideryFile.length === 0) {
                errors.embroideryFile = 'Required';
            }
            else {
                let fileParts: string[] = formValues.embroideryFile[0].name.toLowerCase().split('.');
                if (fileParts[fileParts.length - 1] !== 'dst') {
                    errors.embroideryFile = 'Must be DST file';
                }
                else if (formValues.embroideryFile[0].size > 1000000) {
                    errors.embroideryFile = 'Must be 1 MB or less';
                }
            }            
            break;
        case FileDeliveryType.Email:
            if (!formValues.tapeNumber || $.trim(formValues.tapeNumber).length === 0) {
                errors.tapeNumber = 'Required';
            }
            break;
    }
    if (!formValues.description || $.trim(formValues.description).length === 0) {
        errors.description = 'Required';
    }
    return errors;
}

const onFormValidationFailed = (errors: any, dispatch: any, submitError: Error, props: TapeImporterOwnProps) => {
    if (props.onSubmitFail) {
        props.onSubmitFail();
    }
}

class TapeImporter extends React.PureComponent<TapeImporterProps, TapeImporterState> {

    // ----------------
    // VARIABLES

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', 'overflow': 'visible' }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;
    public fileInput: React.RefObject<HTMLInputElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: TapeImporterProps, state: TapeImporterState) {
        super(props);
        this.state = {
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            deliveryOptions: this.getDeliveryOptions(),
            selectedDeliveryOption: FileDeliveryType.Undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
        this.fileInput = React.createRef<HTMLInputElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.props.change('fileDelivery', FileDeliveryType.Undefined);
        this.props.reset();
    }

    public componentDidUpdate = (prevProps: TapeImporterProps) => {
        if (this.props.submitRequested && !prevProps.submitRequested) {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        }
        if (this.props.isOpen && !prevProps.isOpen) {
            this.initializePopup();
        }
        else if (prevProps.isOpen && !this.props.isOpen) {
            setTimeout(() => {
                this.resetPopup();
            }, 400);
        }
    }

    public componentWillUnmount = () => {
    }

    public handleFormSubmit = (values: any): void => {
        this.props.onSubmit(true);
        this.setState({
            changesRejected: false,
            submitting: true
        });

        let fileDelivery: FileDeliveryType = values.fileDelivery ? parseInt(values.fileDelivery) : FileDeliveryType.Undefined;
        let formData: FormData = new FormData();
        formData.append("clientCode", this.props.clientCode.toString());
        formData.append("customerNumber", this.props.customer.shipToLocations[0].number);
        formData.append("fileDelivery", fileDelivery.toString());
        formData.append("tapeNumber", values.tapeNumber);
        formData.append("description", values.description);
        if (fileDelivery === FileDeliveryType.Upload) {
            formData.append("embroideryFile", values.embroideryFile[0]);
        }

        this.saveForm(formData);
    }

    public render = () => {
        return (
            <Container id="tape-importer">
                <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                    <Row>
                        <Col className="pl-0 pr-0">
                            <div className="tape-import-rules">
                                <div className="tape-import-rules-wrapper">
                                    <div className="rules-label">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-info">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="12" y1="16" x2="12" y2="12"></line>
                                            <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                        </svg>
                                    </div>
                                    <div className="rules-text">
                                        <span>Orders with a new logo will remain on hold until an approved DST file is received</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pl-0 pr-0 collapse-validation-error">
                            <Field name="fileDelivery" type="text" label="Embroidery File"
                                component={FieldWrapper.renderRadioButtonField}
                                options={this.state.deliveryOptions} onChange={this.onSelectDeliveryOption}
                                disabled={this.state.submitting || this.state.changesAccepted} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="collapsible-row pl-0 pr-0">
                            <motion.div animate={this.state.selectedDeliveryOption === FileDeliveryType.Upload ? "visible" : "hidden"}
                                initial={this.state.selectedDeliveryOption === FileDeliveryType.Upload ? "visible" : "hidden"}
                                variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                                <Field name="embroideryFile" type="text" component={FieldWrapper.renderFileInputField}
                                    id={"tapeFileInput"} placeholder={"Select file..."}
                                    disabled={this.state.submitting || this.state.changesAccepted}
                                    onSelectFile={this.onSelectFile} reference={this.fileInput} />
                            </motion.div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="collapsible-row pl-0 pr-0">
                            <motion.div animate={this.state.selectedDeliveryOption === FileDeliveryType.Email ? "visible" : "hidden"}
                                initial={this.state.selectedDeliveryOption === FileDeliveryType.Email ? "visible" : "hidden"}
                                variants={this.collapsibleRowVariants} transition={{ duration: 0.25 }}>
                                <Field name="tapeNumber" type="text" label="Tape Number" component={FieldWrapper.renderTextField}
                                    maxLength={20} autoComplete={false} disabled={this.state.submitting || this.state.changesAccepted} />
                            </motion.div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pl-0 pr-0">
                            <Field name="description" type="text" label="Description" component={FieldWrapper.renderTextField}
                                maxLength={60} autoComplete={false} disabled={this.state.submitting || this.state.changesAccepted} />
                        </Col>
                    </Row>
                    <button type="submit" ref={this.submitButton}>SUBMIT</button>
                </form>
            </Container>
        );
    }

    // ----------------
    // HELPERS

    private getDeliveryOptions = (): FieldWrapper.OptionValue[] => {
        let deliveryOptions: FieldWrapper.OptionValue[] = [];
        deliveryOptions.push({ label: 'Upload Now', value: FileDeliveryType.Upload });
        deliveryOptions.push({ label: 'Email Later', value: FileDeliveryType.Email });
        return deliveryOptions;
    }

    private initializePopup = (): void => {        
    }

    private onSelectFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    }

    private onSelectDeliveryOption = (event: React.ChangeEvent): void => {
        let selectedValue = event.target.getAttribute("value");
        if (selectedValue && parseInt(selectedValue) != this.state.selectedDeliveryOption) {
            this.setState({
                selectedDeliveryOption: parseInt(selectedValue)
            });
        }
    }

    private resetPopup = (): void => {
        this.props.reset();
        if (this.fileInput && this.fileInput.current) {
            this.fileInput.current.value = '';
        }
        this.setState({
            selectedDeliveryOption: FileDeliveryType.Undefined
        });
    }

    private saveForm = (formData: FormData): void => {
        let fetchError: boolean = false;
        let action: string = 'customers/addlogo';
        let url: string = `${action}`;

        fetch(url,
            {
                method: 'POST',
                body: formData
            })
            .then(response => {
                if (response.status !== 200) {
                    ErrorMessage.getFromResponse(response, action).then(
                        (errorMessage => {
                            this.props.actions.broadcastMessage(errorMessage);
                        })
                    );
                    fetchError = true;
                    throw new Error();
                }
                return response.json();
            })
            .then(data => {
                let result: NewLogo = data as NewLogo;
                this.setState({
                    changesAccepted: true,
                    submitting: false
                });
                this.props.onSubmitSucceed(result);
            },
            err => {
                this.setState({
                    changesRejected: true,
                    submitting: false
                });
                if (!fetchError) {
                    this.props.actions.broadcastMessage(ErrorMessage.getFromError(err, action));
                }
            }
        );
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        initialValues: {
            fileDelivery: FileDeliveryType.Undefined
        }
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {}
    };
}

export default connect<{}, {}, TapeImporterOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: "tapeImporterForm",
    validate: validateTapeImporterForm,
    onSubmitFail: onFormValidationFailed,
    enableReinitialize: true
})(TapeImporter as any));
