﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as MessageStore from './Message';
import * as UserStore from './User';
import * as ErrorMessage from '../common/ErrorMessage';
import { ClientCode } from '../enums/ClientCode';
import { Query, QueryResult } from 'material-table';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UsersState {
    users: Users | null;
    userDetail: UserStore.User | null | undefined;
    clientCode: ClientCode;
    isLoading: boolean;
    isLoadingDetail: boolean;
}
;
export interface Users extends QueryResult<UserStore.User> {
    query: Query<any>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestUsersAction {
    type: 'REQUEST_USERS';
    clientCode: ClientCode;
    query: Query<any>;
}

interface ReceiveUsersAction {
    type: 'RECEIVE_USERS';
    clientCode: ClientCode;
    users: Users | null;
}

interface RequestUserDetailAction {
    type: 'REQUEST_USER_DETAIL';
    clientCode: ClientCode;
    id: number;
}

interface ReceiveUserDetailAction {
    type: 'RECEIVE_USER_DETAIL';
    clientCode: ClientCode;
    userDetail: UserStore.User | null | undefined;
}

interface AddUserAction {
    type: 'ADD_USER';
    clientCode: ClientCode;
    userDetail: UserStore.User;
}

interface EditUserAction {
    type: 'EDIT_USER';
    clientCode: ClientCode;
    userDetail: UserStore.User;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = RequestUsersAction | ReceiveUsersAction | RequestUserDetailAction | ReceiveUserDetailAction | AddUserAction | EditUserAction | MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestUsers: (clientCode: ClientCode, query: Query<UserStore.User>): AppThunkAction<KnownAction> => (dispatch, getState): Promise<QueryResult<UserStore.User>> => {

        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.users && !appState.users.isLoading) {

                let clientAwareQuery: object = Object.assign({}, query, { clientCode: clientCode });
                let fetchError: boolean = false;
                let action: string = 'users/get';
                let url: string = `${action}`;

                fetch(url,
                    {
                        method: 'POST',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(clientAwareQuery)
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_USERS', clientCode: clientCode, users: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                                })                                
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                        let users: Users = data as Users;
                        dispatch({ type: 'RECEIVE_USERS', clientCode: clientCode, users: users });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'RECEIVE_USERS', clientCode: clientCode, users: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

                dispatch({ type: 'REQUEST_USERS', clientCode: clientCode, query: query });
            }
            else {
                resolve(appState.users ?
                    appState.users.users as QueryResult<UserStore.User> :
                    { data: [], query: query, page: query.page, totalCount: 0 } as QueryResult<UserStore.User>);
            }
        })    
    },
    requestUserDetail: (clientCode: ClientCode, id: number): AppThunkAction<KnownAction> => (dispatch, getState): Promise<UserStore.User | null | undefined> => {
        return new Promise((resolve, reject) => {
            const appState = getState();
            if (appState && appState.users && !appState.users.isLoadingDetail) {

                let fetchError: boolean = false;
                let action: string = 'users/getdetail';
                let url: string = `${action}/${clientCode}/${id}`;

                fetch(url,
                    {
                        method: 'GET',
                        headers: { 'Accept': 'application/json' }
                    })
                    .then(response => {
                        if (response.status >= 400) {
                            ErrorMessage.getFromResponse(response, action).then(
                                (errorMessage => {
                                    dispatch({ type: 'RECEIVE_USER_DETAIL', clientCode: clientCode, userDetail: null });
                                    dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });                                    
                                })
                            );
                            fetchError = true;
                            throw new Error();
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                        let user: UserStore.User = data as UserStore.User;
                        dispatch({ type: 'RECEIVE_USER_DETAIL', clientCode: clientCode, userDetail: user });
                    },
                    err => {
                        reject(err.message);
                        if (!fetchError) {
                            dispatch({ type: 'RECEIVE_USER_DETAIL', clientCode: clientCode, userDetail: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

                dispatch({ type: 'REQUEST_USER_DETAIL', clientCode: clientCode, id: id });
            }
            else {
                resolve(appState.users ? appState.users.userDetail : undefined);
            }
        })
    },
    addUser: (clientCode: ClientCode, user: UserStore.User): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.users && !appState.users.isLoadingDetail) {

            let fetchError: boolean = false;
            let action: string = 'users/add';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(user)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'RECEIVE_USER_DETAIL', clientCode: clientCode, userDetail: undefined });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    let userDetail: UserStore.User = data as UserStore.User;
                    if (userDetail) {
                        dispatch({ type: 'RECEIVE_USER_DETAIL', clientCode: clientCode, userDetail: userDetail });
                        let actionComplete: MessageStore.Message = {
                            messageType: MessageStore.MessageType.ACTIONCOMPLETE,
                            action: action,
                            reference: null,
                            text: 'Update complete'
                        };
                        dispatch({ type: 'BROADCAST_MESSAGE', message: actionComplete });
                    }
                    else {
                        throw new Error("Invalid response");
                    }
                },
                err => {
                    if (!fetchError) {
                        dispatch({ type: 'RECEIVE_USER_DETAIL', clientCode: clientCode, userDetail: undefined });
                        dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                    }
                }
            )

            dispatch({ type: 'ADD_USER', clientCode: clientCode, userDetail: user });
        }
    },
    editUser: (clientCode: ClientCode, user: UserStore.User): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.users && !appState.users.isLoadingDetail) {

            let fetchError: boolean = false;
            let action: string = 'users/edit';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'PUT',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(user)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'RECEIVE_USER_DETAIL', clientCode: clientCode, userDetail: (appState.users ? appState.users.userDetail : undefined) });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    let userDetail: UserStore.User = data as UserStore.User;
                    if (userDetail) {
                        dispatch({ type: 'RECEIVE_USER_DETAIL', clientCode: clientCode, userDetail: userDetail });                    
                        let actionComplete: MessageStore.Message = {
                            messageType: MessageStore.MessageType.ACTIONCOMPLETE,
                            action: action,
                            reference: null,
                            text: 'Update complete'
                        };
                        dispatch({ type: 'BROADCAST_MESSAGE', message: actionComplete });
                    }
                    else {
                        throw new Error("Invalid response");
                    }
                },
                err => {
                    if (!fetchError) {
                        dispatch({ type: 'RECEIVE_USER_DETAIL', clientCode: clientCode, userDetail: (appState.users ? appState.users.userDetail : undefined) });
                        dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                    }
                });

            dispatch({ type: 'EDIT_USER', clientCode: clientCode, userDetail: user });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: UsersState = {
    users: null,
    userDetail: null,
    clientCode: ClientCode.Undefined,
    isLoading: false,
    isLoadingDetail: false
};

export const reducer: Reducer<UsersState> = (state: UsersState | undefined, incomingAction: Action): UsersState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_USERS':
            return {
                clientCode: action.clientCode,
                users: state.users,
                userDetail: state.userDetail,
                isLoading: true,
                isLoadingDetail: false
            };
        case 'RECEIVE_USERS':
            return {
                clientCode: action.clientCode,
                users: action.users,
                userDetail: state.userDetail,
                isLoading: false,
                isLoadingDetail: false
            };
        case 'REQUEST_USER_DETAIL':
            return {
                clientCode: action.clientCode,
                users: state.users,
                userDetail: state.userDetail,
                isLoading: false,
                isLoadingDetail: true
            };
        case 'RECEIVE_USER_DETAIL':
            return {
                clientCode: action.clientCode,
                users: state.users,
                userDetail: action.userDetail,
                isLoading: false,
                isLoadingDetail: false
            };
        case 'ADD_USER':
            return {
                clientCode: action.clientCode,
                users: state.users,
                userDetail: state.userDetail,
                isLoading: false,
                isLoadingDetail: true
            };
        case 'EDIT_USER':
            return {
                clientCode: action.clientCode,
                users: state.users,
                userDetail: state.userDetail,
                isLoading: false,
                isLoadingDetail: true
            };
        default:
            return state;
    }
}