import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { RouteComponentProps } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import * as CustomersStore from '../../store/Customers';
import { ClientCode } from '../../enums/ClientCode';
import { Order } from '../../store/Orders';
import { Button, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import Loader from '../loader/Loader';
import DetailTitleBlock from '../detail-title-block/DetailTitleBlock';
import { TitleBlock } from '../../common/ComponentTypes';
import CustomerForm from '../customer-form/CustomerForm';
import CustomerOrders from '../customer-orders/CustomerOrders';

import './EditCustomer.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState,
    customersState: {} as CustomersStore.CustomersState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, CustomersStore.actionCreators)
}

interface EditCustomerAsyncActions {
    asyncActions: {
        requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => Promise<CustomersStore.CustomerAccount | null | undefined>;
    }
}

interface MatchParams {
    id: string;
    tab: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> { }

type EditCustomerProps =
    MatchProps
    & typeof applicationState       // ... state we've requested from Redux store
    & typeof actionCreators         // ... plus action creators we've requested
    & EditCustomerAsyncActions;

// ----------------
// LOCAL STATE

interface EditCustomerState {
    isVisible: boolean;
    isLoadError: boolean;
    isLoadWarning: boolean;
    isReloading: boolean;
    customerDetail: CustomersStore.CustomerAccount | null | undefined;
    titleBlock: TitleBlock;
    activeTab: string;
}

class EditCustomer extends React.PureComponent<EditCustomerProps, EditCustomerState> {

    // ----------------
    // VARIABLES

    public editCustomerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: EditCustomerProps, state: EditCustomerState) {
        super(props);
        this.state = {
            isVisible: false,
            isLoadError: false,
            isLoadWarning: false,
            isReloading: false,
            customerDetail: null,
            titleBlock: this.getTitleBlock(undefined),
            activeTab: this.props.match.params.tab
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.ensureDataFetched();
    }

    public componentDidUpdate = () => {
        if (!this.state.isVisible) {
            if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
                this.setState({
                    isReloading: false
                });
                setTimeout(() => {
                    this.setState({
                        isLoadError: true,
                        isLoadWarning: this.receivedWarningMessage()
                    });
                }, 400);
            }
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={(!this.state.isVisible && !this.state.isLoadError) || this.state.isReloading} />
                <motion.div id="editCustomerPage" className="page-content" animate={this.state.isVisible ? "visible" : "hidden"} initial={"hidden"} variants={this.editCustomerVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <Row className="justify-content-start">
                            <DetailTitleBlock titleBlock={this.state.titleBlock} disabled={this.state.isReloading} />
                        </Row>
                        <Row>
                            <Col className="pl-0 pr-0 customer-tabs">
                                <Nav tabs className="right-align">
                                    <NavItem>
                                        <NavLink className={this.state.activeTab === "account" ? "active" : ''}
                                            onClick={() => this.toggleTabs("account")}
                                            disabled={this.state.isReloading}>
                                            Account
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={this.state.activeTab === "orders" ? "active" : ''}
                                            onClick={() => this.toggleTabs("orders")}
                                            disabled={this.state.isReloading}>
                                            Orders
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pr-0 pt-4">
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="account">
                                        <Container>
                                            <Row>
                                                <Col className="pl-0 pr-0">
                                                    {this.state.customerDetail && (
                                                        <CustomerForm customerDetail={this.props.customersState.customerDetail} />
                                                    )}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </TabPane>
                                    <TabPane tabId="orders">
                                        <Container>
                                            <Row>
                                                <Col className="pl-0 pr-0">
                                                    {this.state.customerDetail && (
                                                        <CustomerOrders customerDetail={this.state.customerDetail} onEditOrder={this.onEditOrder} />
                                                    )}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                        {this.state.isLoadError && (
                            <Container>
                                <Row className="justify-content-center">
                                    <Col className={"pt-4 page-error" + (this.state.isLoadWarning ? " warning" : "")} xs={"auto"}>
                                        <Button color="primary" onClick={this.reloadDetail}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-refresh-ccw">
                                                <polyline points="1 4 1 10 7 10"></polyline>
                                                <polyline points="23 20 23 14 17 14"></polyline>
                                                <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                            </svg>
                                            Reload
                                        </Button>
                                        <Button color="link" onClick={this.goBack}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-left">
                                                <polyline points="15 18 9 12 15 6"></polyline>
                                            </svg>
                                            Go Back
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        )}

                    </Container>
                </motion.div>

            </React.Fragment>
        );
    }

    // ----------------
    // HELPERS

    private customerInStore = (id: string): boolean => {
        let inStore: boolean = false;
        if (this.props.customersState.customerDetail) {
            inStore = (this.props.customersState.customerDetail.number === id);
            if (inStore) {
                inStore = this.props.customersState.customerDetail.selected;
            }
            else {
                let shipTo: CustomersStore.CustomerDetail[] = this.props.customersState.customerDetail.shipToLocations.filter((shipToLocation: CustomersStore.CustomerDetail) => {
                    return shipToLocation.number === id && shipToLocation.selected;
                });
                inStore = shipTo.length > 0;
            }
        }
        return inStore;
    }

    private ensureDataFetched = (): void => {
        let customerNumber: string = this.props.match.params.id;
        if (this.customerInStore(customerNumber)) {
            this.setState({
                isVisible: true,
                isLoadError: false,
                isLoadWarning: false,
                isReloading: false,
                customerDetail: this.props.customersState.customerDetail,
                titleBlock: this.getTitleBlock(this.props.customersState.customerDetail)
            });
        }
        else {
            let clientCode: ClientCode = this.props.clientState.client ? this.props.clientState.client.code : ClientCode.Undefined;
            if (clientCode != ClientCode.Undefined) {
                this.getCustomer(customerNumber, clientCode);
            }
            else {
                setTimeout(() => {
                    this.ensureDataFetched();
                }, 200);
            }
        }
    }

    private getCustomer = (customerNumber: string, clientCode: ClientCode): void => {
        this.props.asyncActions.requestCustomerDetailAsync(clientCode, customerNumber, "", true)
            .then(result => {
                this.setState({
                    isVisible: true,
                    isLoadError: false,
                    isLoadWarning: false,
                    isReloading: false,
                    customerDetail: result,
                    titleBlock: this.getTitleBlock(result)
                })
            },
                err => {
                });
    }

    private getTitleBlock = (customerDetail: CustomersStore.CustomerAccount | null | undefined): TitleBlock => {
        let titleBlock: TitleBlock = {
            header: undefined,
            title: undefined,
            subtitle: undefined,
            link: '/customers'
        };
        if (customerDetail) {
            titleBlock.header = customerDetail.billto;
            titleBlock.title = customerDetail.nameOnly;
            titleBlock.subtitle = customerDetail.salesChannel.description
        }
        return titleBlock;
    }

    private goBack = () => {
        this.props.actions.clearMessage();
        setTimeout(() => {
            this.props.history.push('/customers');
        }, 400);
    }

    private onEditOrder = (id: number): void => {
        if (this.props.customersState.customerDetail) {
            let currentPath: string = '/customers/edit/' + this.props.customersState.customerDetail.number + '/orders';
            this.props.history.push('/orders/edit/' + id + '/header', { from: currentPath });
        }
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private reloadDetail = (): void => {
        this.props.actions.clearMessage();
        let customerNumber: string = this.props.match.params.id;
        let clientCode: ClientCode = this.props.clientState.client ? this.props.clientState.client.code : ClientCode.Undefined;
        setTimeout(() => {
            this.setState({
                isReloading: true,
                isLoadError: false,
                isLoadWarning: false
            });
            this.getCustomer(customerNumber, clientCode);
        }, 200);
    }

    private toggleTabs = (selectedTab: string): void => {
        if (this.state.activeTab != selectedTab) {
            this.setState({
                activeTab: selectedTab
            });
            if (this.state.customerDetail) {
                this.props.history.replace('/customers/edit/' + this.props.match.params.id + '/' + selectedTab);
            }
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message,
        customersState: state.customers
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            CustomersStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => dispatch(CustomersStore.actionCreators.requestCustomerDetail(clientCode, billto, shipto, updateState))
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCustomer as any);
