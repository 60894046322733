import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import { AnimatePresence, motion } from 'framer-motion';
import * as ClientStore from '../../store/Client';
import * as CustomersStore from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import * as OrderDatesStore from '../../store/OrderDates';
import * as OrderItemsStore from '../../store/OrderItems';
import * as OrdersStore from '../../store/Orders';
import * as ProductsStore from '../../store/Products';
import * as ProductlistsStore from '../../store/Productlists';
import * as UserStore from '../../store/User';
import { ClientCode } from '../../enums/ClientCode';
import { CustomerAccount, CustomerDetail } from '../../store/Customers';
import { CustomerName } from '../../common/AccountTypes';
import { Role } from '../../enums/Role';
import { ColorOption, Dimension, Size } from '../../common/ProductTypes';
import { ColorDirection, EmbroiderySpecification, Logo, PlacementOption } from '../../common/EmbroideryTypes';
import { Button, Container, Col, Row } from 'reactstrap';
import ImageLoader from '../image-loader/ImageLoader';
import ProductDescription from '../product-description/ProductDescription';
import ProductColorPicker from '../product-color-picker/ProductColorPicker';
import ProductColors from '../product-colors/ProductColors';
import ProductEmbroidery from '../product-embroidery/ProductEmbroidery';
import ProductlistContextSelector from '../productlist-context-selector/ProductlistContextSelector';
import { ProductlistType } from '../../enums/ProductlistType';
import OrderCustomer from '../order-customer/OrderCustomer';
import DimensionPicker from '../dimension-picker/DimensionPicker';
import SizeRun from '../size-run/SizeRun';
import { toTitleCase } from '../../common/StringFormatter';
import cloneDeep from 'lodash/cloneDeep';

import './ProductForm.scss';

// ----------------
// PROPS

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    customersState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState,
    orderDatesState: {} as OrderDatesStore.OrderDatesState,
    ordersState: {} as OrdersStore.OrdersState,
    productsState: {} as ProductsStore.ProductsState,
    productlistsState: {} as ProductlistsStore.ProductlistsState,
    userState: {} as UserStore.UserState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrderDatesStore.actionCreators, ProductsStore.actionCreators, ProductlistsStore.actionCreators)
};

interface ProductFormAsyncActions {
    asyncActions: {
        requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => Promise<CustomersStore.CustomerAccount | null | undefined>
    }
}

interface ProductFormOwnProps {
    productDetail: ProductsStore.ProductDetail | null | undefined;
    onAddToOrder: (orderItem: OrderItemsStore.NewOrderItem) => void;
    onAddToProductList: (orderItem: OrderItemsStore.NewOrderItem) => void;
    onChangeColor: (colorOption: ColorOption) => void;
    onChangeDimension: (dimension: Dimension) => void;
    onClose: () => void;
}

type ProductFormProps =
    ProductFormOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & ProductFormAsyncActions;

// ----------------
// LOCAL STATE

interface ProductFormState {
    initialized: boolean;
    selectingCustomer: boolean;
    customerSelected: boolean;
    loadingCustomerLogos: boolean;
    customerLogosLoaded: boolean;
    heroImageUrl: string | null | undefined;
    selectedDimension: Dimension | null | undefined;
    embroiderySpecifications: EmbroiderySpecification[];
    maxEmbroideries: number | undefined;
    customer: CustomerAccount | null | undefined;
    isProductlistSelectorOpen: boolean;
    showValidationErrors: boolean;
}

class ProductForm extends React.PureComponent<ProductFormProps, ProductFormState> {

    // ----------------
    // VARIABLES

    public productFormVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, }
    };

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductFormProps, state: ProductFormState) {
        super(props);
        this.state = {
            initialized: (this.props.productDetail && this.props.orderDatesState.orderDates && this.props.clientState.client) ? true : false,
            selectingCustomer: false,
            customerSelected: false,
            loadingCustomerLogos: false,
            customerLogosLoaded: false,
            heroImageUrl: this.props.productDetail ? this.props.productDetail.imageUrl : null,
            selectedDimension: this.props.productDetail ? this.props.productDetail.dimensions[0] : null,
            embroiderySpecifications: this.getEmbroiderySpecifications(this.props.productDetail),
            maxEmbroideries: this.props.clientState.client ? this.props.clientState.client.rules.maxLogosPerItem : undefined,
            customer: this.getCustomer(),
            isProductlistSelectorOpen: false,
            showValidationErrors: false
        };
        this.ensureDataFetched();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: ProductFormProps) => {
        if (this.props.productDetail && this.props.orderDatesState.orderDates && this.props.clientState.client && !this.state.initialized) {
            this.setState({
                initialized: true,
                heroImageUrl: this.props.productDetail.imageUrl,
                selectedDimension: this.props.productDetail.dimensions[0],
                embroiderySpecifications: this.getEmbroiderySpecifications(this.props.productDetail),
                maxEmbroideries: this.props.clientState.client.rules.maxLogosPerItem,
                customer: this.getCustomer()
            });
        }
        else if (this.props.productDetail && prevProps.productDetail) {
            if (this.props.productDetail.id != prevProps.productDetail.id) {
                this.setState({
                    heroImageUrl: this.props.productDetail.imageUrl,
                    selectedDimension: this.props.productDetail.dimensions[0],
                    customer: this.getCustomer()
                });
            }
            else if (this.isDifferentOrder(prevProps)) {
                this.setState({
                    embroiderySpecifications: this.getEmbroiderySpecifications(this.props.productDetail),
                    customer: this.getCustomer(),
                    customerLogosLoaded: false
                })
            }
            else if (this.isDifferentList(prevProps)) {
                if (!this.props.ordersState.orderDetail && !this.state.customerSelected) {
                    this.setState({
                        embroiderySpecifications: this.getEmbroiderySpecifications(this.props.productDetail),
                        customer: this.getCustomer(),
                        customerLogosLoaded: false
                    })
                }
            }
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <Container id="product-form">
                <AnimatePresence>
                    {this.props.productDetail && this.state.initialized && (
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.25 }}>
                            <Row className="d-block d-md-none">
                                <Col className="product-header bordered pl-0 pr-0 pt-3 mb-4 text-left">
                                    <ProductDescription productDetail={this.props.productDetail} />
                                    {(this.hasOneColor(this.props.productDetail) || this.props.productDetail.colorDescription) && (
                                        <label className="default-product-color">{this.getColorName(this.props.productDetail)}</label>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="product-images pl-0 pr-3" md={6} sm={12}>
                                    <div className="hero-image">
                                        <div className="hero-image-stretcher">
                                            <div className="hero-image-canvas">
                                                <ImageLoader url={this.state.heroImageUrl} isLoading={false} useMagnifier={true} />
                                            </div>
                                        </div>
                                    </div>
                                    {this.props.productDetail.views.length > 1 && (
                                        <div className={"views" + (this.props.productDetail.views.length < 3 ? " centered" : "")}>
                                            {this.props.productDetail.views.map((view: string, viewIndex: number) => (
                                                <div className="view" key={"view" + (viewIndex + 1)}>
                                                    <a className={"view-image" + (view === this.state.heroImageUrl ? " selected" : "")}
                                                        onClick={(e: React.MouseEvent) => this.onSelectView(e, view)}>
                                                        <div className="view-image-stretcher">
                                                            <div className="view-image-canvas">
                                                                <ImageLoader url={view} isLoading={false} />
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </Col>
                                <Col className="product-specifications pl-3 pr-0" md={6} sm={12}>
                                    <Container>
                                        <Row className="d-none d-md-block">
                                            <Col className="product-header mt-4 mt-md-0 pl-0 pr-0 text-left">
                                                <ProductDescription productDetail={this.props.productDetail} />
                                                {(this.hasOneColor(this.props.productDetail) || this.props.productDetail.colorDescription)  && (
                                                    <label className="default-product-color">{this.getColorName(this.props.productDetail)}</label>
                                                )}
                                            </Col>
                                        </Row>
                                        {this.props.productDetail.color.code != 'PATTERNED' && this.props.productDetail.colorCount > 1 && (
                                            <Row>
                                                <Col className="pl-0 pr-0 mt-3">
                                                    <div className="info-panel">
                                                        <div className="title-bar">
                                                            <div className="heading">
                                                                <label>Colors</label>
                                                            </div>
                                                        </div>
                                                        <div className="content product-color">
                                                            <ProductColors colorOptions={this.props.productDetail.colorOptions} selectedOption={this.props.productDetail.colorOptions[0]}
                                                                onSelect={this.onSelectColorOption} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col className="pl-0 pr-0 mt-3">
                                                <div className="info-panel flush-bottom">
                                                    <div className="title-bar">
                                                        <div className="heading">
                                                            <label>{this.props.productDetail.dimensions.length === 1 && this.state.selectedDimension && this.state.selectedDimension.sizes.length === 1 ? "Size" : "Sizes"}</label>
                                                        </div>
                                                    </div>
                                                    <div className="content product-size">
                                                        <Container>
                                                            {this.props.productDetail.dimensions.length > 1 && (
                                                                <Row>
                                                                    <Col className="pl-0 pr-0">
                                                                        <div className="product-dimensions">
                                                                            <DimensionPicker description={this.props.productDetail.dimensionDescription} dimensions={this.props.productDetail.dimensions}
                                                                                selectedDimension={this.state.selectedDimension} onSelectDimension={this.onSelectDimension} />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                            <Row>
                                                                <Col className="pl-0 pr-0 pb-0">
                                                                    <div className="product-sizes">
                                                                        <SizeRun dimension={this.state.selectedDimension} description={this.props.productDetail.sizeDescription}
                                                                            displayOnly={false} readOnly={false} shipDate={this.getShipDate()} chaseDate={this.props.productDetail.chaseDate}
                                                                            useEmbroideredDate={this.isEmbroidered()} onChangeQuantity={this.onChangeQuantity} />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        {this.props.productDetail && this.props.productDetail.canEmbroider && (
                                            <React.Fragment>
                                                {this.state.embroiderySpecifications.map((specification: EmbroiderySpecification | null, specificationIndex: number) => (
                                                    <Row key={"specification-" + specificationIndex}>
                                                        <Col className="pl-0 pr-0 mt-3">
                                                            <div className="info-panel editable flush-bottom">
                                                                <div className="title-bar">
                                                                    <div className="heading">
                                                                        <label>Embroidery <span>{this.getEmbroiderySequence(specificationIndex)}</span></label>
                                                                    </div>
                                                                    <div className="button-bar">
                                                                        {this.state.embroiderySpecifications[specificationIndex].logo && (
                                                                            <Button color="link" className="close" onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.onRemoveLogo(e, specificationIndex)}>
                                                                                <span>&times;</span>
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="content flush-top product-embroidery">
                                                                    {this.props.productDetail && this.props.clientState.client && this.props.userState.user && (
                                                                        <ProductEmbroidery clientCode={this.props.clientState.client.code}
                                                                            productDetail={this.props.productDetail}
                                                                            customer={this.state.customer}
                                                                            user={this.props.userState.user}
                                                                            embroiderySpecification={this.state.embroiderySpecifications[specificationIndex]}
                                                                            form={"productEmbroideryForm-" + specificationIndex}
                                                                            onSelectCustomer={this.onSelectCustomer}
                                                                            onLoadCustomer={this.onLoadCustomer}
                                                                            onLoadCustomerLogos={this.onLoadCustomerLogos}
                                                                            onLoadingCustomerLogos={this.onLoadingCustomerLogos}
                                                                            onSelectLogo={(logo: Logo | null) => this.onSelectLogo(specificationIndex, logo)}
                                                                            onSelectPlacement={(placementOption: PlacementOption | null) => this.onSelectPlacement(specificationIndex, placementOption)}
                                                                            onSelectColorDirection={(colorDirection: ColorDirection | null) => this.onSelectColorDirection(specificationIndex, colorDirection)}
                                                                            onAddEmbroideryNotes={(text: string | null) => this.onAddEmbroideryNotes(specificationIndex, text)}
                                                                            validationRequested={this.state.showValidationErrors} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))}
                                                {this.showProductEmbroideryBar() && (
                                                    <Row>
                                                        <Col className="pl-0 pr-0 mt-3">
                                                            <div className="info-panel flush-bottom">
                                                                <div className="content product-embroidery-bar">
                                                                    {!this.props.ordersState.orderDetail && !this.userIsSingleAccountBuyer() && (
                                                                        <Button color="link" onClick={this.onSelectCustomer} disabled={this.state.loadingCustomerLogos}>Change Customer</Button>
                                                                    )}
                                                                    {!this.props.ordersState.orderDetail && this.showAddEmbroidery() && !this.userIsSingleAccountBuyer() && (
                                                                        <label className="separator">|</label>
                                                                    )}
                                                                    {this.showAddEmbroidery() && (
                                                                        <Button color="link" onClick={this.onAddEmbroidery} disabled={!this.enableAddEmbroidery()}>Add Embroidery</Button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </React.Fragment>
                                        )}
                                        <Row>
                                            <Col className="button-bar pl-0 pr-0 mt-5">
                                                <Button type="button" color="primary" onClick={this.addToOrder}>
                                                    Add to Order
                                                </Button>
                                                <Button color="link" onClick={this.props.onClose}>
                                                    Close
                                                </Button>
                                            </Col>
                                        </Row>
                                        {this.props.clientState.client && this.isListable() && (
                                            <React.Fragment>
                                                <Row>
                                                    <Col className="productlist-bar pl-0 pr-0 pt-3 mt-3">
                                                        <div className="list-add">
                                                            <Button type="button" color="secondary" onClick={this.addToProductlist}>
                                                                Add to List
                                                            </Button>
                                                        </div>
                                                        <div className="list-context-bar">
                                                            {this.props.productlistsState.productlistDetail && (
                                                                <React.Fragment>
                                                                    <div className="list-context-bar">
                                                                        <div className="list-tag">
                                                                            <label className="name">Current List</label>
                                                                        </div>
                                                                        <div className="list-name">
                                                                            <label className="value">{this.props.productlistsState.productlistDetail.name}</label>
                                                                            <label className="value-detail">{this.getProductlistCustomer(this.props.productlistsState.productlistDetail)}</label>
                                                                        </div>
                                                                        <div className="list-actions">
                                                                            <Button color="secondary" title="Change" onClick={this.showProductlistSelector}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 626 626" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-change">
                                                                                    <g transform="matrix(0.1,0,0,-0.1,7.8105308e-7,626)">
                                                                                        <path d="m 4969,5905 c -46,-25 -49,-54 -49,-421 v -344 h -512 c -454,0 -528,-2 -644,-19 -225,-34 -488,-132 -663,-250 -146,-97 -376,-332 -506,-516 -139,-197 -307,-507 -450,-830 -235,-534 -294,-663 -357,-790 -188,-375 -370,-581 -589,-668 -127,-51 -202,-57 -676,-57 -365,0 -439,-2 -463,-15 -61,-32 -60,-25 -60,-422 0,-211 4,-372 10,-387 5,-14 24,-35 42,-46 32,-20 44,-21 548,-17 582,4 596,5 827,82 271,90 421,182 619,379 340,341 517,647 941,1636 67,156 202,423 266,525 181,292 351,433 580,482 72,16 142,18 582,18 h 500 l 5,-358 5,-359 33,-29 c 26,-23 42,-29 79,-29 h 47 l 574,573 c 315,314 580,584 588,599 18,34 18,72 0,106 -16,29 -1115,1129 -1156,1156 -30,20 -86,20 -121,1 z" />
                                                                                        <path d="M 52,5120 C 34,5109 15,5088 10,5074 4,5059 0,4897 0,4685 v -365 l 29,-32 29,-33 479,-6 c 548,-6 555,-7 720,-90 194,-96 347,-277 513,-603 37,-71 71,-131 77,-133 5,-2 40,67 77,153 37,86 90,201 117,255 27,54 49,100 49,102 0,8 125,238 178,326 64,108 68,86 -43,225 -85,108 -248,266 -348,340 -200,147 -435,249 -677,292 -80,15 -177,18 -605,21 -499,4 -511,3 -543,-17 z" />
                                                                                        <path d="m 3042,2789 c -16,-35 -44,-98 -62,-139 -64,-146 -260,-536 -319,-637 -34,-56 -61,-105 -61,-108 0,-19 131,-177 240,-289 164,-168 245,-228 443,-325 175,-87 263,-113 485,-145 157,-23 491,-34 849,-28 l 302,5 3,-362 3,-363 33,-29 c 26,-23 42,-29 79,-29 h 47 l 574,572 c 315,315 580,585 588,600 18,34 18,72 0,106 -16,29 -1115,1129 -1156,1156 -46,30 -135,12 -156,-31 -5,-10 -11,-178 -14,-373 l -5,-355 -470,-3 c -527,-3 -584,2 -728,62 -96,40 -163,82 -238,151 -103,94 -236,289 -344,505 -32,63 -59,117 -61,119 -2,2 -17,-25 -32,-60 z" />
                                                                                    </g>
                                                                                </svg>
                                                                            </Button>
                                                                            <Button color="secondary" title="Close" onClick={this.closeProductlist}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather-x">
                                                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                                                </svg>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <ProductlistContextSelector isOpen={this.state.isProductlistSelectorOpen}
                                                    onDismiss={this.hideProductlistSelector}
                                                    client={this.props.clientState.client} />
                                            </React.Fragment>
                                        )}
                                        {this.props.productDetail.longDescription && (
                                            <Row>
                                                <Col className="product-text mt-1 mb-0 pl-0 pr-0 text-left" dangerouslySetInnerHTML={this.formatLongDescription(this.props.productDetail)}>
                                                </Col>
                                            </Row>
                                        )}
                                        {this.props.clientState.client && (
                                            <OrderCustomer isOpen={this.state.selectingCustomer} onDismiss={this.hideSelectCustomer} clientCode={this.props.clientState.client.code} />
                                        )}
                                    </Container>
                                </Col>
                            </Row>
                        </motion.div>
                    )}
                </AnimatePresence>
            </Container>
        );
    }

    // ----------------
    // HELPERS 

    private addToOrder = (): void => {
        if (this.props.clientState.client && this.props.productsState.productDetail && this.state.selectedDimension) {
            this.setState({
                showValidationErrors: false
            });
            if (this.isValidOrderItem()) {
                let orderItem: OrderItemsStore.NewOrderItem = this.getNewOrderItem(
                    this.props.clientState.client as ClientStore.Client,
                    this.props.productsState.productDetail as ProductsStore.ProductDetail,
                    this.state.selectedDimension as Dimension
                );
                this.props.onAddToOrder(orderItem);
            }
            else {
                this.setState({
                    showValidationErrors: true
                });
            }
        }
    }

    private addToProductlist = (): void => {
        if (this.props.clientState.client && this.props.productsState.productDetail && this.state.selectedDimension) {
            this.setState({
                showValidationErrors: false
            });
            if (this.isValidOrderItem()) {
                let orderItem: OrderItemsStore.NewOrderItem = this.getNewOrderItem(
                    this.props.clientState.client as ClientStore.Client,
                    this.props.productsState.productDetail as ProductsStore.ProductDetail,
                    this.state.selectedDimension as Dimension
                );
                this.props.onAddToProductList(orderItem);
            }
            else {
                this.setState({
                    showValidationErrors: true
                });
            }
        }
    }

    private closeProductlist = (): void => {
        this.props.actions.closeProductlist();
    }

    private copyQuantities = (source: Dimension, target: Dimension): Dimension => {
        for (let n: number = 0; n < source.sizes.length; n++) {
            let size: Size = source.sizes[n];
            if (size.quantity > 0) {
                let matchingSizes: Size[] = target.sizes.filter((sz: Size) => {
                    return sz.code === size.code;
                });
                if (matchingSizes.length > 0) {
                    matchingSizes[0].quantity = size.quantity;
                }
            }
        }
        return target;
    }

    private enableAddEmbroidery = (): boolean => {
        let maxEmbroideries: number = this.state.maxEmbroideries as number;
        let enableAdd: boolean = this.state.embroiderySpecifications.length > 0 && this.state.embroiderySpecifications.length < maxEmbroideries ? true : false;
        if (enableAdd) {
            let lastSpecification: EmbroiderySpecification = this.state.embroiderySpecifications[this.state.embroiderySpecifications.length - 1];
            enableAdd = lastSpecification.logo ? true : false;
        }
        return enableAdd;
    }

    private ensureDataFetched = (): void => {
        if (this.props.clientState.client && !this.props.orderDatesState.orderDates) {
            this.props.actions.requestOrderDates(this.props.clientState.client);
        }
        else if (!this.props.orderDatesState.orderDates) {
            setTimeout(() => {
                this.ensureDataFetched();
            }, 250);
        }
    }

    private formatLongDescription = (productDetail: ProductsStore.ProductDetail): { __html: string } => {
        return {
            __html: productDetail.longDescription
        };
    }

    private getColorName = (productDetail: ProductsStore.ProductDetail): string => {
        let colorName: string = productDetail.colorDescription || productDetail.color.displayName || '';
        return colorName;
    }

    private getCustomer = (): CustomerAccount | null | undefined => {
        let customer: CustomerAccount | null | undefined = this.props.ordersState.orderDetail ?
            this.props.ordersState.orderDetail.customerAccount : undefined;
        if (!customer) {
            if (this.state && this.state.customerSelected) {
                customer = this.state.customer;
            }
            else if (this.props.productlistsState.productlistDetail && this.props.productlistsState.productlistDetail.customerAccount) {
                customer = this.props.productlistsState.productlistDetail.customerAccount;
            }
            else if (this.props.customersState.customerDetail) {
                customer = this.props.customersState.customerDetail;
            }
        }
        return customer;
    }

    private getEmbroiderySequence = (specificationIndex: number): string => {
        let sequence: string = "";
        if (this.state.embroiderySpecifications.length > 1) {
            sequence = (specificationIndex + 1).toString();
        }
        return sequence;
    }

    private getEmbroiderySpecifications = (productDetail: ProductsStore.ProductDetail | null | undefined): EmbroiderySpecification[] => {
        let specifications: EmbroiderySpecification[] = [];
        if (productDetail && productDetail.canEmbroider) {
            specifications.push({} as EmbroiderySpecification);
        }
        return specifications;
    }

    private getMatchingDimension = (matchDimension: Dimension | null | undefined): Dimension | null | undefined => {
        let matchingDimension: Dimension | null | undefined = undefined;
        if (matchDimension && this.props.productDetail) {
            let matchingDimensions: Dimension[] = this.props.productDetail.dimensions.filter((d: Dimension) => {
                return d.code === matchDimension.code;
            });
            if (matchingDimensions.length > 0) {
                matchingDimension = this.copyQuantities(matchDimension, matchingDimensions[0]);
            }
        }
        return matchingDimension;
    }

    private getNewOrderItem = (client: ClientStore.Client, productDetail: ProductsStore.ProductDetail, selectedDimension: Dimension): OrderItemsStore.NewOrderItem => {
        return {
            client: client,
            product: productDetail,
            dimension: selectedDimension,
            embroiderySpecifications: this.getValidEmbroiderySpecifications(),
            customerNumber: '',
            orderId: 0
        } as OrderItemsStore.NewOrderItem;
    }

    private getValidEmbroiderySpecifications = (): EmbroiderySpecification[] => {
        let specifications: EmbroiderySpecification[] = [];
        if (this.hasEmbroiderySpecification()) {
            for (let n: number = 0; n < this.state.embroiderySpecifications.length; n++) {
                let specification: EmbroiderySpecification = this.state.embroiderySpecifications[n];
                if (specification.logo) {
                    specifications.push(specification);
                }
            }
        }
        return specifications;
    }

    private getProductlistCustomer = (productlist: ProductlistsStore.ProductlistDetail): string => {
        return productlist.customerAccount ? productlist.customerNameOnly : 'No customer assigned';
    }

    private getShipDate = (): Date | null | undefined => {
        let shipDate: Date | null | undefined = undefined;
        if (this.props.orderDatesState.orderDates) {
            shipDate = this.props.ordersState.orderDetail ?
                this.props.ordersState.orderDetail.shipDate :
                this.props.orderDatesState.orderDates.currentDate;
        }
        return shipDate;
    }

    private hasOneColor = (productDetail: ProductsStore.ProductDetail): boolean => {
        let oneColor: boolean = productDetail.colorCount === 1 ? true : false;
        if (oneColor && productDetail.color.name) {
            oneColor = productDetail.color.name.toLowerCase() != 'patterned';
        }
        return oneColor;
    }

    private hasEmbroiderySpecification = (): boolean => {
        let hasSpecification: boolean = this.state.embroiderySpecifications.length > 1 ? true : false;
        if (this.state.embroiderySpecifications.length > 0 && !hasSpecification) {
            hasSpecification = this.state.embroiderySpecifications[0].logo ? true : false;
            hasSpecification = hasSpecification && this.state.embroiderySpecifications[0].placement ? true : false;
            hasSpecification = hasSpecification && this.state.embroiderySpecifications[0].colorDirection ? true : false;
        }
        return hasSpecification;
    }

    private hideProductlistSelector = () => {
        this.setState({
            isProductlistSelectorOpen: false
        });
    }

    private hideSelectCustomer = (selectedAccount: CustomerAccount | null | undefined): void => {
        let embroiderySpecifications: EmbroiderySpecification[] = this.isDifferentCustomer(selectedAccount) ?
            this.getEmbroiderySpecifications(this.props.productDetail) :
            this.state.embroiderySpecifications;
        this.setState({
            selectingCustomer: false,
            customerSelected: selectedAccount ? true : false,
            customer: selectedAccount || this.state.customer,
            embroiderySpecifications: embroiderySpecifications
        });
    }

    private isDifferentCustomer = (selectedAccount: CustomerAccount | null | undefined): boolean => {
        let isDifferent: boolean = false;
        if (selectedAccount && this.state.customer) {
            isDifferent = (selectedAccount.billto !== this.state.customer.billto) ? true : false;
        }
        return isDifferent;
    }

    private isDifferentList = (prevProps: ProductFormProps): boolean => {
        let isDifferent: boolean = false;
        if (this.props.productlistsState.productlistDetail && !prevProps.productlistsState.productlistDetail) {
            isDifferent = true;
        }
        else if (prevProps.productlistsState.productlistDetail && !this.props.productlistsState.productlistDetail) {
            isDifferent = true;
        }
        else if (this.props.productlistsState.productlistDetail && prevProps.productlistsState.productlistDetail) {
            isDifferent = (this.props.productlistsState.productlistDetail.id === prevProps.productlistsState.productlistDetail.id) ? false : true;
        }
        return isDifferent;
    }

    private isDifferentOrder = (prevProps: ProductFormProps): boolean => {
        let isDifferent: boolean = false;
        if (this.props.ordersState.orderDetail && !prevProps.ordersState.orderDetail) {
            isDifferent = true;
        }
        else if (prevProps.ordersState.orderDetail && !this.props.ordersState.orderDetail) {
            isDifferent = true;
        }
        else if (this.props.ordersState.orderDetail && prevProps.ordersState.orderDetail) {
            isDifferent = (this.props.ordersState.orderDetail.id === prevProps.ordersState.orderDetail.id) ? false : true;
        }
        return isDifferent;
    }

    private isEmbroidered = (): boolean => {
        let hasSpecification: boolean = this.state.embroiderySpecifications.length > 1 ? true : false;
        if (this.state.embroiderySpecifications.length > 0 && !hasSpecification) {
            hasSpecification = this.state.embroiderySpecifications[0].logo ? true : false;
        }
        return hasSpecification;
    }

    private isListable = (): boolean => {
        //return false;
        return (this.props.userState.user && this.props.userState.user.role != Role.Buyer ? true : false);
    }

    private isValidEmbroiderySpecification = (embroiderySpecification: EmbroiderySpecification): boolean => {
        let isValid: boolean = embroiderySpecification.logo ? true : false;
        if (isValid) {
            isValid = embroiderySpecification.placement ? true : false;
            if (isValid) {
                isValid = embroiderySpecification.colorDirection ? true : false;
                if (isValid && embroiderySpecification.placement && embroiderySpecification.colorDirection) {
                    let requiresNotes: boolean = embroiderySpecification.placement.description.toLowerCase().indexOf('notes') > 0 ||
                        embroiderySpecification.colorDirection.description.toLowerCase().indexOf('instructions') > 0;
                    if (requiresNotes) {
                        isValid = (embroiderySpecification.notes && embroiderySpecification.notes.length > 0) ? true : false;
                    }
                }
            }
        }
        return isValid;
    }

    private isValidOrderItem = (): boolean => {
        let isValid: boolean = true;
        let selectedEmbroideries: EmbroiderySpecification[] = this.state.embroiderySpecifications.filter((function (spec: EmbroiderySpecification) {
            return spec.logo ? true : false;
        }))
        if (selectedEmbroideries.length > 0) {
            for (let n: number = 0; n < selectedEmbroideries.length; n++) {
                if (!this.isValidEmbroiderySpecification(selectedEmbroideries[n])) {
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    }

    private onAddEmbroidery = (): void => {
        let maxEmbroideries: number = this.state.maxEmbroideries as number;
        if (this.state.embroiderySpecifications.length < maxEmbroideries) {
            let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
            specifications.push({} as EmbroiderySpecification);
            this.setState({
                embroiderySpecifications: specifications
            });
        }
    }

    private onAddEmbroideryNotes = (specificationIndex: number, text: string | null): void => {
        let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        specifications[specificationIndex].notes = text;
        this.setState({
            embroiderySpecifications: specifications
        });
    }

    private onChangeQuantity = (size: Size): void => {
        // alert('changeQuantity');
    }

    private onLoadCustomer = (customer: CustomerName): void => {
        if (this.props.clientState.client) {
            this.props.asyncActions.requestCustomerDetailAsync(this.props.clientState.client.code, customer.billto, customer.shipto, true)
                .then(result => {
                    if (result) {
                        this.setState({
                            customer: result
                        });
                    }
                },
                err => { });
        }
    }

    private onLoadCustomerLogos = (success: boolean): void => {
        if (!this.state.customerLogosLoaded) {
            if (success || !this.props.ordersState.orderDetail) {
                this.setState({
                    customerLogosLoaded: true,
                    loadingCustomerLogos: false
                });
            }
            else {
                this.setState({
                    loadingCustomerLogos: false
                })
            }
        }
        else {
            this.setState({
                loadingCustomerLogos: false
            })
        }
    }

    private onLoadingCustomerLogos = (): void => {
        this.setState({
            loadingCustomerLogos: true
        });
    }

    private onRemoveLogo = (event: React.KeyboardEvent | React.MouseEvent, specificationIndex: number): void => {
        event.preventDefault();
        let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        if (specifications.length > 1) {
            specifications.splice(specificationIndex, 1);
        }
        else {
            specifications[0].logo = null;
            specifications[0].placement = null;
            specifications[0].colorDirection = null;
            specifications[0].notes = null;
        }
        this.setState({
            embroiderySpecifications: specifications
        });
    }

    private onSelectColorDirection = (specificationIndex: number, colorDirection: ColorDirection | null): void => {
        let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        specifications[specificationIndex].colorDirection = colorDirection;
        this.setState({
            embroiderySpecifications: specifications
        });
    }

    private onSelectColorOption = (colorOption: ColorOption): void => {
        if (this.props.productDetail) {
            if (colorOption.productId != this.props.productDetail.id) {
                this.props.onChangeColor(colorOption);
            }
        }
    }

    private onSelectCustomer = (): void => {
        this.setState({
            selectingCustomer: true
        });
    }

    private onSelectLogo = (specificationIndex: number, logo: Logo | null): void => {
        let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        specifications[specificationIndex].logo = logo;
        this.setState({
            embroiderySpecifications: specifications
        });
    }

    private onSelectPlacement = (specificationIndex: number, placementOption: PlacementOption | null): void => {
        let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        specifications[specificationIndex].placement = placementOption;
        this.setState({
            embroiderySpecifications: specifications
        });
    }

    private onSelectDimension = (dimension: Dimension): void => {
        if (this.props.productDetail) {
            let matchingDimension = this.getMatchingDimension(dimension);
            if (matchingDimension && (matchingDimension.productId != this.props.productDetail.id)) {
                this.props.onChangeDimension(matchingDimension);
            }
            else {
                let selectedDimension = matchingDimension ? matchingDimension : this.props.productDetail.dimensions[0];
                this.setState({
                    selectedDimension: selectedDimension
                });
            }
        }
    }

    private onSelectView = (event: React.MouseEvent, view: string) => {
        setTimeout(() => {
            this.setState({
                heroImageUrl: view
            });
        });
    }

    private showAddEmbroidery = (): boolean => {
        let showAdd: boolean = this.hasEmbroiderySpecification() ? true : false;
        if (showAdd) {
            let maxEmbroideries: number = this.state.maxEmbroideries as number;
            if (maxEmbroideries <= 1) {
                showAdd = false;
            }
        }
        return showAdd;
    }

    private showProductEmbroideryBar = (): boolean => {
        let showBar: boolean = this.state.customerLogosLoaded ? true : false;
        if (showBar) {
            showBar = this.state.embroiderySpecifications.length > 0 ? true : false;
            if (showBar && this.props.ordersState.orderDetail) {
                showBar = this.showAddEmbroidery() ? true : false;
            }
            else if (showBar && this.userIsSingleAccountBuyer()) {
                showBar = this.showAddEmbroidery() && this.isValidEmbroiderySpecification(this.state.embroiderySpecifications[0]) ? true : false;
            }
        }
        return showBar;
    }

    private showProductlistSelector = (): void => {
        this.setState({
            isProductlistSelectorOpen: true
        });
    }

    private titleCaseColorName = (name: string | null | undefined): string => {
        let colorName: string = '';
        if (name) {
            let tokens: string[] = name.split('/');
            for (let n: number = 0; n < tokens.length; n++) {
                tokens[n] = toTitleCase(tokens[n]);
            }
            colorName = tokens.join('/');
        }
        return colorName;
    }

    private userIsBuyer = (): boolean => {
        let isBuyer: boolean = this.props.userState.user && this.props.userState.user.role === Role.Buyer ? true : false;
        return isBuyer;
    }

    private userIsMultipleAccountBuyer = (): boolean => {
        let isMultipleAccountBuyer: boolean = this.userIsBuyer();
        if (isMultipleAccountBuyer && this.props.userState.user) {
            isMultipleAccountBuyer = this.props.userState.user.buyerAccounts.length > 1;
        }
        return isMultipleAccountBuyer;
    }

    private userIsSingleAccountBuyer = (): boolean => {
        let isSingleAccountBuyer: boolean = this.userIsBuyer();
        if (isSingleAccountBuyer && this.props.userState.user) {
            isSingleAccountBuyer = this.props.userState.user.buyerAccounts.length === 1;
        }
        return isSingleAccountBuyer;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        customersState: state.customers,
        messageState: state.message,
        orderDatesState: state.orderDates,
        ordersState: state.orders,
        productsState: state.products,
        productlistsState: state.productlists,
        userState: state.user
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrderDatesStore.actionCreators,
            ProductsStore.actionCreators,
            ProductlistsStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => dispatch(CustomersStore.actionCreators.requestCustomerDetail(clientCode, billto, shipto, updateState))
        }
    };
}

export default connect<{}, {}, ProductFormOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ProductForm as any);
