import * as React from 'react';
import { Container } from 'reactstrap';
import MessageBar from '../message-bar/MessageBar';
import NavMenu from '../nav-menu/NavMenu';

export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public render = () => {
        return (
            <React.Fragment>
                <MessageBar />
                <NavMenu />
                <Container>
                    <div className="device-sm d-sm-none"></div>
                    {this.props.children}
                </Container>
            </React.Fragment>
        );
    }
}