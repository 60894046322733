import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as ProductlistsStore from '../../store/Productlists';
import { Productlist } from '../../store/Productlists';
import { Client } from '../../store/Client';
import * as ErrorMessage from '../../common/ErrorMessage';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';

import './DeleteProductlist.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    productlistsState: {} as ProductlistsStore.ProductlistsState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, ProductlistsStore.actionCreators)
};

interface DeleteProductlistOwnProps {
    client: Client | null | undefined;
    productlistToDelete: Productlist | null;
    onDelete: (() => void);
    onDismiss: (() => void);
    onConcurrencyError: (() => void);
}

type DeleteProductlistProps =
    DeleteProductlistOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface DeleteProductlistState {
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
}

class DeleteProductlist extends React.PureComponent<DeleteProductlistProps, DeleteProductlistState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: DeleteProductlistProps, state: DeleteProductlistState) {
        super(props);
        this.state = {
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: DeleteProductlistProps) => {
        if (this.state.submitting) {
            if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
                this.props.onDismiss();
            }
            else if (this.receivedValidationError()) {
                let formResult: string | null = this.getFormResult();
                setTimeout(() => {
                    this.props.actions.clearMessage();
                    this.props.onConcurrencyError();
                    this.setState({
                        submitting: false,
                        changesRejected: true,
                        formResult: formResult
                    });
                })
            }
            else if (this.receivedActionCompleteMessage()) {
                let formResult: string | null = this.getFormResult();
                setTimeout(() => {
                    this.props.actions.clearMessage();
                    this.setState({
                        submitting: false,
                        changesRejected: false,
                        changesAccepted: true,
                        formResult: formResult
                    });
                    setTimeout(() => {
                        this.props.onDelete();
                    }, 1500);
                })
            }
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <Modal id="delete-productlist" size="sm" isOpen={this.props.productlistToDelete != null}
                onOpened={this.initializeDialog.bind(this)} onClosed={this.resetDialog}>
                <ModalHeader toggle={this.props.onDismiss} className={this.state.submitting ? "disabled" : ""}>
                    Delete List
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className="action-info pl-0 pr-0">
                                <span className="action-description">List will be permanently deleted:</span>
                                <span className="action-target">{this.props.productlistToDelete ? this.props.productlistToDelete.name : ""}</span>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" disabled={this.state.submitting || this.state.changesAccepted}
                                    onClick={this.deleteProductlist}>
                                    {this.state.submitting ? "Working" : "Continue"}
                                </Button>
                                <Button color="link" onClick={this.props.onDismiss} disabled={this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private deleteProductlist = (): void => {
        if (this.props.client && this.props.productlistToDelete) {
            this.setState({
                submitting: true,
                changesRejected: false,
                changesAccepted: false
            });
            this.props.actions.deleteProductlist(this.props.client, this.props.productlistToDelete);
        }
    }

    private getFormResult = (): string | null => {
        let formResult: string | null = null;
        if (this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) {
                formResult = this.props.messageState.message.text || 'Form contains invalid entries';
            }
            else if (this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) {
                formResult = 'List deleted';
            }
        }
        return formResult;
    }

    private initializeDialog = (): void => {
        this.setState({
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        })
    }

    private receivedActionCompleteMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) ?
            true : false;
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private resetDialog = (): void => {
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        productlistsState: state.productlists
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            ProductlistsStore.actionCreators
        ), dispatch)
    };
}

export default connect<{}, {}, DeleteProductlistOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(DeleteProductlist as any);
