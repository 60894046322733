import * as React from 'react';
import { Client } from '../../store/Client';
import { Productlist } from '../../store/Productlists';
import { ProductlistType } from '../../enums/ProductlistType';
import { ClientCode } from '../../enums/ClientCode';
import { convertSQLDateToString } from '../../common/DateConverter';

import './ProductlistCard.scss';

// ----------------
// PROPS

interface ProductlistCardProps {
    client: Client | null;
    data: Productlist;
}

class ProductlistCard extends React.PureComponent<ProductlistCardProps> {

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProductlistCardProps) {
        super(props);
    }

    // ----------------
    // METHODS

    public render = () => {
        return (
            <div className="productlist-card">
                <div className="id-column">
                    <label>{this.props.data.id}</label>
                    <label>{this.getProductlistType()}</label>
                </div>
                <div>{this.props.data.name}</div>
                <div className="detail-column first">
                    <label>Created By</label>
                    <label>{this.props.data.createdBy}</label>
                </div>
                {this.props.data.customerName && (
                    <div className="detail-column">
                        <label>Customer</label>
                        <label>{this.props.data.customerNameOnly}</label>
                    </div>
                )}
            </div>
        );
    }

    // ----------------
    // HELPERS

    private getProductlistType = (): string => {
        let listType: string = '';
        if (this.props.data.listType === ProductlistType.Private) {
            listType = 'Private';
        }
        else if (this.props.data.listType === ProductlistType.Public) {
            listType = 'Public';
        }
        return listType;
    }
}

// ----------------
// EXPORT

export default ProductlistCard;
