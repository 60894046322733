import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as OrderItemsStore from '../../store/OrderItems';
import { OrderItem } from '../../store/OrderItems';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import * as ErrorMessage from '../../common/ErrorMessage';
import { Color } from '../../common/ProductTypes';
import { Field, getFormValues, InjectedFormProps, reduxForm, SubmissionError, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import ImageLoader from '../image-loader/ImageLoader';
import Loader from '../loader/Loader';
import Checkbox from '../checkbox/Checkbox';
import cloneDeep from 'lodash/cloneDeep';
import { toTitleCase } from '../../common/StringFormatter';
import $ from 'jquery';

import './CopyOrderItem.scss';
import { OrderDetail } from '../../store/Orders';

// ----------------
// PROPS

const applicationState = {
    orderItemsState: {} as OrderItemsStore.OrderItemsState,
    messageState: {} as MessageStore.MessageState
}

const actionCreators = {
    actions: Object.assign({}, OrderItemsStore.actionCreators, MessageStore.actionCreators)
}

interface CopyOrderItemAsyncActions {
    asyncActions: {
        copyOrderItemAsync: (clientCode: ClientCode, copyToOrderItems: OrderItemsStore.CopyToOrderItems, broadcastError: boolean) => Promise<OrderDetail | null | undefined>;
        requestCopyToOrderItemsAsync: (clientCode: ClientCode, request: OrderItemsStore.CopyOrderItemRequest, broadcastError: boolean) => Promise<OrderItemsStore.CopyToOrderItems | null | undefined>;
    }
}

interface CopyOrderItemOwnProps {
    client: Client | null | undefined;
    orderItemToCopy: OrderItem | null;
    onDismiss: ((hasUpdates: boolean) => void);
}

type CopyOrderItemProps =
    CopyOrderItemOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & CopyOrderItemAsyncActions;

// ----------------
// LOCAL STATE

interface CopyOrderItemState {
    activeQuery: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    copyToItems: OrderItemsStore.CopyToOrderItems | null | undefined;
    copyOptions: FieldWrapper.OptionValue[];
    copyEmbroidery: boolean;
    copyQuantities: boolean;
    disabledOptions: number[];
    formResult: string | null;
    hasCopyToItems: boolean;
    isRetrievalError: boolean;
    includeEmbroidery: boolean;
    retrievingCopyToItems: boolean;
    selecting: boolean;
    submitting: boolean;
}

// ----------------
// FORM VALIDATOR

const validateCopyOrderItemForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (formValues.copyOptions === "false") {
        errors.copyOptions = 'Select option';
    }
    return errors;
}

class CopyOrderItem extends React.PureComponent<CopyOrderItemProps, CopyOrderItemState> {

    // ----------------
    // VARIABLES

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: CopyOrderItemProps, state: CopyOrderItemState) {
        super(props);
        this.state = {
            activeQuery: false,
            changesAccepted: false,
            changesRejected: false,
            copyToItems: undefined,
            copyOptions: this.getCopyOptions(),
            copyEmbroidery: false,
            copyQuantities: false,
            disabledOptions: this.getDisabledCopyOptions(),
            formResult: null,
            hasCopyToItems: false,
            isRetrievalError: false,
            includeEmbroidery: true,
            retrievingCopyToItems: false,
            selecting: false,
            submitting: false
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: CopyOrderItemProps) => {
        if (this.props.orderItemToCopy && !prevProps.orderItemToCopy) {
            this.setState({
                disabledOptions: this.getDisabledCopyOptions()
            });
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        if (this.state.copyToItems) {
            if (this.state.selecting) {
                this.setState({
                    changesRejected: true,
                    formResult: 'No order item selected'
                })
            }
            else {
                this.state.copyToItems.orderItem = this.props.orderItemToCopy
                this.submit();
            }
        }
        else {
            if (!(this.state.copyEmbroidery || this.state.copyQuantities)) {
                throw new SubmissionError({
                    copyOptions: 'Select option',
                    _error: 'Submit failed'
                });
            }
            this.getCopyToOrderItems(this.props.orderItemToCopy as OrderItem);
        }
    }

    public render = () => {
        return (
            <Modal id="copy-order-item" isOpen={this.props.orderItemToCopy != null}
                onOpened={this.initializeDialog.bind(this)} onClosed={this.resetDialog}>
                <ModalHeader toggle={() => this.props.onDismiss(this.state.changesAccepted)} className={this.state.retrievingCopyToItems || this.state.submitting ? "disabled" : ""}>
                    Copy Item
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                            <Row>
                                <Col className="order-item-description">
                                    {this.props.orderItemToCopy && (
                                        <React.Fragment>
                                            <div className="image">
                                                <div className="order-item-image">
                                                    <div className="image-stretcher">
                                                        <div className="image-canvas">
                                                            <ImageLoader url={this.props.orderItemToCopy.imageUrl} isLoading={false} noFadeIn={false} thumbnail={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <label className="style-code">
                                                    {this.props.orderItemToCopy.style.sku ? "" : "Style "}
                                                    {this.props.orderItemToCopy.style.sku ? this.props.orderItemToCopy.style.sku : this.props.orderItemToCopy.style.code}
                                                </label>
                                                <h4 className="style-name">{this.props.orderItemToCopy.style.name}</h4>
                                                <label className="color">{this.getColorDescription()}</label>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="copy-options">
                                    <label className="section-label">Copy</label>
                                    <Field name="copyOptions" type="text" label="" id="copyOptions" component={FieldWrapper.renderCheckboxGroupField}
                                        options={this.state.copyOptions} onCheck={this.onSelectCopyOption} disabledOptions={this.state.disabledOptions}
                                        disabled={this.state.retrievingCopyToItems || this.state.submitting || this.state.changesAccepted} />
                                </Col>
                            </Row>
                            <Row className="copyto-items-container">
                                <Loader isLoading={this.state.retrievingCopyToItems || this.state.submitting} isChild={true} />
                                <Col className="copyto-items">
                                    <label className="section-label">To</label>
                                    <div className={"query-results" + (this.state.activeQuery && !this.state.hasCopyToItems ? " hidden" : "")}>
                                        {this.state.activeQuery && !this.state.hasCopyToItems && (
                                            <AnimatePresence>
                                                <motion.div className="no-items" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5, delay: 0.5 }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-alert-circle">
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                            <line x1="12" y1="8" x2="12" y2="12"></line>
                                                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                                    </svg>
                                                    <div className="message">
                                                        <label>Order has no other items of this type with same</label>
                                                        <ul>
                                                            {this.state.copyQuantities && (
                                                            <li>size scale{this.state.copyEmbroidery ? " and" : ""}</li>
                                                            )}
                                                            {this.state.copyEmbroidery && (
                                                               <li>logo options</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </motion.div>
                                            </AnimatePresence>
                                        )}
                                        {this.state.activeQuery && this.state.hasCopyToItems && (
                                            <AnimatePresence>
                                                <motion.div className="items" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5, delay: 0.5 }}>
                                                    {this.state.copyToItems && this.state.copyToItems.items.map((copyToItem: OrderItemsStore.CopyToOrderItem, copyToItemIndex: number) => (
                                                        <div className="item" key={"copyToItem=" + copyToItemIndex}>
                                                            <div className="selector">
                                                                <Checkbox id={"copyToItem-" + copyToItemIndex} label="" selected={false} disabled={this.state.submitting || this.state.changesAccepted}
                                                                    onChange={(checked: boolean) => this.onSelectCopyToItem(copyToItemIndex, checked)} />
                                                            </div>
                                                            <div className="descriptor">
                                                                <div className="image">
                                                                    <div className="copyto-item-image">
                                                                        <div className="image-stretcher">
                                                                            <div className="image-canvas">
                                                                                <ImageLoader url={copyToItem.imageUrl} isLoading={false} noFadeIn={false} thumbnail={true} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="text">
                                                                    <label className="style-code">
                                                                        {copyToItem.style.sku ? "" : "Style "}
                                                                        {copyToItem.style.sku ? copyToItem.style.sku : copyToItem.style.code }
                                                                    </label>
                                                                    <h4 className="style-name">{copyToItem.style.name}</h4>
                                                                    <label className="color">{this.getColorDescriptionFromColor(copyToItem.color)}</label>
                                                                    {copyToItem.notes && (
                                                                        <label className="notes">{copyToItem.notes}</label>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </motion.div>
                                            </AnimatePresence>
                                        )}
                                    </div>
                                </Col>
                            </Row>            
                            <button type="submit" ref={this.submitButton}>SUBMIT</button>
                        </form>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" disabled={this.state.retrievingCopyToItems || this.state.selecting || this.state.submitting || this.state.changesAccepted}
                                    onClick={this.copyOrderItem}>
                                    {this.state.submitting ? "Working" : "Paste"}
                                </Button>
                                <Button color="link" onClick={() => this.props.onDismiss(false)} disabled={this.state.retrievingCopyToItems || this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private copyOrderItem = (): void => {
        if (this.props.client && this.props.orderItemToCopy) {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        }
    }

    private getColorDescription = (): string => {
        let color: string | null | undefined;
        if (this.props.orderItemToCopy) {
            color = this.getColorDescriptionFromColor(this.props.orderItemToCopy.color);
        }
        return color || "";
    }

    private getColorDescriptionFromColor = (color: Color): string | null | undefined => {
        let colorName: string | null | undefined = color.name;
        if (colorName) {
            if (colorName != 'PATTERNED') {
                colorName = toTitleCase(colorName);
            }
            else {
                colorName = "";
            }
        }
        else {
            colorName = color.code;
        }
        return colorName;
    }

    private getCopyOptions = (): FieldWrapper.OptionValue[] => {
        let copyOptions: FieldWrapper.OptionValue[] = [];
        copyOptions.push({ label: 'Size Run Quantities', value: false });
        copyOptions.push({ label: 'Embroidery', value: false });
        return copyOptions;
    }

    private getCopyToOrderItems = (copyOrderItem: OrderItem): void => {
        if (this.props.client && this.props.orderItemToCopy) {
            this.setState({
                activeQuery: false,
                changesAccepted: false,
                changesRejected: false,
                copyToItems: null,
                formResult: null,
                isRetrievalError: false,
                retrievingCopyToItems: true,
                selecting: false
            })
            let copyRequest: OrderItemsStore.CopyOrderItemRequest = {
                client: this.props.client,
                copyEmbroidery: this.state.copyEmbroidery,
                copyQuantities: this.state.copyQuantities,
                orderItem: copyOrderItem
            };
            this.props.asyncActions.requestCopyToOrderItemsAsync(this.props.client.code, copyRequest, false)
                .then(result => {
                    this.setState({
                        activeQuery: true,
                        hasCopyToItems: result ? result.items.length > 0 : false,
                        copyToItems: result,
                        retrievingCopyToItems: false,
                        selecting: result ? result.items.length > 0 : false
                    });
                    
                },
                err => {
                    this.setState({
                        changesAccepted: false,
                        changesRejected: true,
                        formResult: err,
                        hasCopyToItems: false,
                        isRetrievalError: true,
                        retrievingCopyToItems: false,
                    })
                }
            );                
        }
    }

    private getCopyToSummaryText = (): string => {
        let text: string = "";
        if (this.props.orderItemToCopy && this.state.copyToItems) {
            text += "Quantity will be copied to " + this.state.copyToItems.items.length;
            text += (this.state.copyToItems.items.length == 1 ? " item" : " items");
        }
        return text;
    }

    private getDisabledCopyOptions = (): number[] => {
        let disabledOptions: number[] = [];
        if (this.props.orderItemToCopy && !this.hasEmbroidery()) {
            disabledOptions = [0, 1];
        }
        return disabledOptions;
    }

    private hasEmbroidery = (): boolean => {
        let embroidery: boolean = false;
        if (this.props.orderItemToCopy && this.props.orderItemToCopy.embroiderySpecifications.length > 0) {
            embroidery = true;
        }
        return embroidery;
    }

    private initializeDialog = (): void => {
        let presetOption: boolean = this.props.orderItemToCopy && !this.hasEmbroidery() ? true : false;
        let copyOptions: FieldWrapper.OptionValue[] = this.getCopyOptions();
        if (presetOption) {
            copyOptions[0].value = true
        };
        this.setState({
            activeQuery: false,
            changesAccepted: false,
            changesRejected: false,
            copyToItems: undefined,
            copyEmbroidery: false,
            copyQuantities: presetOption ? true : false,
            copyOptions: copyOptions,
            formResult: null,
            hasCopyToItems: false,
            isRetrievalError: false,
            includeEmbroidery: true,
            retrievingCopyToItems: false,
            selecting: false,
            submitting: false
        });
        this.props.change("copyOptions", presetOption ? "true" : "false");
        if (presetOption) {
            setTimeout(() => {
                if (this.submitButton.current) {
                    this.submitButton.current.click();
                }
            }, 400);
        }
    }

    private onSelectCopyOption = (index: number, checked: boolean): void => {
        let copyEmbroidery: boolean = this.state.copyEmbroidery;
        let copyQuantities: boolean = this.state.copyQuantities;
        switch (index) {
            case 0:
                copyQuantities = checked ? true : false;
                break;
            case 1:
                copyEmbroidery = checked ? true : false;
                break;
        }
        let retrieveCopyToItems: boolean = (copyEmbroidery || copyQuantities) ? true : false;
        this.setState({
            activeQuery: false,
            copyOptions: this.getCopyOptions(),
            copyEmbroidery: copyEmbroidery,
            copyQuantities: copyQuantities,
            copyToItems: undefined,
            hasCopyToItems: false,
            retrievingCopyToItems: retrieveCopyToItems,
            selecting: false
        });
        this.props.change("copyOptions", retrieveCopyToItems? "true" : "false");
        //if (retrieveCopyToItems) {
            setTimeout(() => {
                this.copyOrderItem();
            }, 400);
        //}
    }

    private onSelectCopyToItem = (index: number, checked: boolean): void => {
        if (this.state.copyToItems) {
            let copyToItems: OrderItemsStore.CopyToOrderItems = cloneDeep(this.state.copyToItems);
            if (index < copyToItems.items.length) {
                copyToItems.items[index].selected = checked ? true : false;
                let selectedItems: OrderItemsStore.CopyToOrderItem[] = copyToItems.items.filter((copyToItem: OrderItemsStore.CopyToOrderItem) => {
                    return copyToItem.selected ? true : false;
                });
                this.setState({
                    changesRejected: false,
                    copyToItems: copyToItems,

                    selecting: selectedItems.length === 0
                });
            }
        }
    }

    private resetDialog = (): void => {        
        this.setState({
            activeQuery: false,
            changesAccepted: false,
            changesRejected: false,
            copyToItems: undefined,
            formResult: null,
            hasCopyToItems: false,
            isRetrievalError: false,
            includeEmbroidery: true,
            retrievingCopyToItems: false,
            submitting: false,
        });
        this.props.reset();
    }

    private submit = (): void => {
        if (this.props.client && this.state.copyToItems) {
            this.setState({
                submitting: true,
                changesAccepted: false,
                changesRejected: false,
                formResult: null
            });
            this.props.asyncActions.copyOrderItemAsync(this.props.client.code, this.state.copyToItems, false)
                .then(result => {
                    this.setState({
                        submitting: false,
                        changesAccepted: true,
                        changesRejected: false,
                        formResult: 'Changes saved',
                        isRetrievalError: false
                    });
                    setTimeout(() => {
                        this.props.onDismiss(true);
                    }, 1500);
                },
                err => {
                    this.setState({
                        submitting: false,
                        changesAccepted: false,
                        changesRejected: true,
                        formResult: err,
                        isRetrievalError: false
                    });
                });
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        orderItemsState: state.orderItems,
        messageState: state.message
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            OrderItemsStore.actionCreators,
            MessageStore.actionCreators
        ), dispatch),
        asyncActions: {
            copyOrderItemAsync: (clientCode: ClientCode, copyToOrderItems: OrderItemsStore.CopyToOrderItems, broadcastError: boolean) => dispatch(
                OrderItemsStore.actionCreators.copyOrderItem(clientCode, copyToOrderItems, broadcastError)),
            requestCopyToOrderItemsAsync: (clientCode: ClientCode, request: OrderItemsStore.CopyOrderItemRequest, broadcastError: boolean) => dispatch(
                OrderItemsStore.actionCreators.requestCopyToOrderItems(clientCode, request, broadcastError))
        }
    };
}

export default connect<{}, {}, CopyOrderItemOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'copyOrderItemForm',
    validate: validateCopyOrderItemForm,
    enableReinitialize: true    
})(CopyOrderItem as any));
