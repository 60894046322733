import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as MessageStore from '../../store/Message';
import { CustomerDetail } from '../../store/Customers';
import { Address, Country } from '../../common/AddressTypes';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Dropdown } from 'reactstrap';

import './ShipToPicker.scss';

// ----------------
// PROPS
// At runtime, Redux will merge together...

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    messageState: {} as MessageStore.MessageState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
}

interface ShipToPickerOwnProps {
    placeholder: string;
    shipToLocations: CustomerDetail[];
    onSelectShipTo: (shipTo: CustomerDetail) => void;
    showSelectedItem: boolean;
    disabled?: boolean;
}

type ShipToPickerProps =
    ShipToPickerOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface ShipToPickerState {
    displayShipToNumber: boolean;
    selectedShipTo: CustomerDetail | undefined;
}

class ShipToPicker extends React.PureComponent<ShipToPickerProps, ShipToPickerState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: ShipToPickerProps, state: ShipToPickerState) {
        super(props);
        this.state = {
            displayShipToNumber: this.props.clientState.client ? this.props.clientState.client.rules.displayShipToNumber : true,
            selectedShipTo: undefined
        }
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: ShipToPickerProps) => {
        if (this.props.clientState.client && !prevProps.clientState.client) {
            if (!this.props.clientState.client.rules.displayShipToNumber) {
                this.setState({
                    displayShipToNumber: false
                })
            }
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <UncontrolledDropdown id="shipto-picker">
                <DropdownToggle disabled={this.props.disabled ? true : false}>
                    <div className="dropdown-label">{ this.state.selectedShipTo ? this.formatName(this.state.selectedShipTo) : this.props.placeholder }</div>
                    <div className="dropdown-toggler">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-down">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-chevron-up">
                            <polyline points="18 15 12 9 6 15"></polyline>
                        </svg>
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    {this.props.shipToLocations.map((shipto: CustomerDetail, shiptoIndex: number) => (
                        <DropdownItem key={"shipto" + shiptoIndex} itemProp={shipto.number} className={shiptoIndex % 2 === 1 ? "odd" : ""}
                            onClick={this.onSelectShipTo}>
                            <div className="two-column">
                                <div className="billto">
                                    {this.state.displayShipToNumber && (
                                        <span>{shipto.displayNumber}</span>
                                    )}
                                    {!this.state.displayShipToNumber && (
                                        <span>{shipto.billto}</span>
                                    )}
                                </div>
                                <div className="description">
                                    {shipto.locationDescription && !this.state.displayShipToNumber && (
                                        <span>{shipto.locationDescription}</span>
                                    )}
                                </div>
                            </div>
                            <div className="customer-name">
                                <span>{shipto.nameOnly}</span>
                            </div>
                            {shipto.address && (
                                <div className="customer-location">
                                    {shipto.address.street1 && (
                                        <label className="street">{shipto.address.street1}</label>
                                    )}
                                    {shipto.address.street2 && (
                                        <label className="street">{shipto.address.street2}</label>
                                    )}
                                    {shipto.address.street3 && (
                                        <label className="street">{shipto.address.street3}</label>
                                    )}
                                    {(shipto.address.city || shipto.address.state.code) && (
                                        <label className="city-state" dangerouslySetInnerHTML={this.formatCityStateZip(shipto)}></label>
                                    )}
                                    {shipto.address.country.code && this.isInternational(shipto.address.country) && (
                                        <label className="country">{shipto.address.country.name}</label>
                                    )}
                                </div>
                            )}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    // ----------------
    // HELPERS

    private formatCityStateZip = (shipToLocation: CustomerDetail): { __html: string } | undefined => {
        let output: string | undefined = undefined;
        if (shipToLocation.address) {
            if (shipToLocation.address.city) {
                output = shipToLocation.address.city;
                if (shipToLocation.address.state) {
                    output = output + ', ' + shipToLocation.address.state.code;
                    if (shipToLocation.address.postalCode) {
                        output = output + " &nbsp;" + shipToLocation.address.postalCode;
                    }
                }
            }
            else if (shipToLocation.address.state) {
                output = shipToLocation.address.state.code;
                if (shipToLocation.address.postalCode) {
                    output = output + " &nbsp;" + shipToLocation.address.postalCode;
                }
            }
        }
        return output ? { __html: output } : undefined;
    }

    private formatName = (shipToLocation: CustomerDetail | undefined): string => {
        let output: string = '';
        if (shipToLocation) {
            output = shipToLocation.nameOnly;
            if (shipToLocation.locationDescription) {
                output = output + '  -  ' + shipToLocation.locationDescription;
            }
        }
        return output;
    }

    private isInternational = (country: Country): boolean => {
        return country.code !== 'US' ? true : false;
    }

    private onSelectShipTo = (event: React.MouseEvent): void => {
        let customerNumber: string | null = event.currentTarget.getAttribute("itemprop");
        if (customerNumber) {
            let selectedShipTo: CustomerDetail[] = this.props.shipToLocations.filter((shipToLocation: CustomerDetail) => {
                return shipToLocation.number === customerNumber;
            });
            if (selectedShipTo.length > 0) {
                if (this.props.showSelectedItem) {
                    this.setState({
                        selectedShipTo: selectedShipTo[0]
                    });
                }
                this.props.onSelectShipTo(selectedShipTo[0]);
            }
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        messageState: state.message
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators
        ), dispatch)
    };
}

export default connect<{}, {}, ShipToPickerOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ShipToPicker as any);