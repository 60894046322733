import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as CustomersStore from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import * as OrderDatesStore from '../../store/OrderDates';
import * as OrdersStore from '../../store/Orders';
import * as UserStore from '../../store/User';
import { ClientCode } from '../../enums/ClientCode';
import { CustomerAccount } from '../../store/Customers';
import { CustomerName } from '../../common/AccountTypes';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import { Button, Container, Col, Row } from 'reactstrap';
import CustomerFinder from '../customer-finder/CustomerFinder';
import ShipDateRules from '../ship-date-rules/ShipDateRules';
import ShipToPicker from '../shipto-picker/ShipToPicker';
import { Role } from '../../enums/Role';
import { User } from '../../store/User';
import * as ErrorMessage from '../../common/ErrorMessage';
import FormResult from '../form-result/FormResult';
import * as Validators from '../../common/Validators';
import Loader from '../loader/Loader';
import cloneDeep from 'lodash/cloneDeep';
import $ from 'jquery';

import './AddOrderForm.scss';

// ----------------
// PROPS

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    customersState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState,
    orderDatesState: {} as OrderDatesStore.OrderDatesState,
    ordersState: {} as OrdersStore.OrdersState,
    userState: {} as UserStore.UserState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrderDatesStore.actionCreators, OrdersStore.actionCreators, UserStore.actionCreators)
};

interface AddOrderFormAsyncActions {
    asyncActions: {
        requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => Promise<CustomersStore.CustomerAccount | null | undefined>
    }
}

interface AddOrderFormOwnProps {
    onChange?: () => void;
    onRetrievingAccount?: (retrieving: boolean) => void;
    onSubmit: (submitting: boolean) => void;
    onSubmitFail: () => void;
    onSubmitSucceed?: (newOrder: OrdersStore.BaseOrder) => void;
    submitRequested: boolean;
    disabled?: boolean;
    customerAccount?: CustomerAccount | null | undefined;
    customerNumber?: string | null;
    resetForm?: boolean;
    stateless?: boolean;
}

type AddOrderFormProps =
    AddOrderFormOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & AddOrderFormAsyncActions;

// ----------------
// LOCAL STATE

interface AddOrderFormState {
    initialized: boolean;
    submitting: boolean;
    changesAccepted: boolean;
    loadingAccount: boolean;
    retrievingAccount: boolean;
    customerAccount: CustomerAccount | null | undefined;
    customerAccountTemp: CustomerAccount | null | undefined;
    buyerAccounts: CustomerName[];
    shipDate: Date | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateAddOrderForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (formValues.accountRequired && !formValues.account) {
        errors.account = 'Required';
    }
    else if (formValues.shiptoRequired && !formValues.shipto) {
        errors.shipto = 'Required';
    }
    if (!formValues.shipDate) {
        errors.shipDate = 'Required';
    }
    if (formValues.poRequired && !formValues.poNumber) {
        errors.poNumber = 'Required';
    }
    return errors;
}

const onFormValidationFailed = (errors: any, dispatch: any, submitError: Error, props: AddOrderFormOwnProps) => {
    if (props.onSubmitFail) {
        props.onSubmitFail();
    }
}

class AddOrderForm extends React.PureComponent<AddOrderFormProps, AddOrderFormState> {

    // ----------------
    // VARIABLES

    public orderFormVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    public orderFormSliderVariants = {
        hidden: { overflow: 'hidden', height: 0 },
        visible: { overflow: 'hidden', height: 'auto' }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: AddOrderFormProps, state: AddOrderFormState) {
        super(props);
        this.state = {
            initialized: (this.props.clientState.client && this.props.orderDatesState.orderDates && this.userHasAccounts()) ? true : false,
            submitting: false,
            changesAccepted: false,
            loadingAccount: false,
            retrievingAccount: false,
            customerAccount: this.props.customerAccount,
            customerAccountTemp: undefined,
            buyerAccounts: this.props.userState.user ? this.props.userState.user.buyerAccounts : [],
            shipDate: this.getMinShipDate()
        };
        if (this.state.initialized) {
            this.initializeForm();
        }
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.ensureDataFetched();
        if (this.props.userState.user && this.props.userState.user.role !== Role.Buyer) {
            this.setFocusOnCustomerFinder();
        }
    }

    public componentDidUpdate = (prevProps: AddOrderFormProps) => {
        if (this.props.clientState.client && this.props.orderDatesState.orderDates && !this.state.initialized) {
            this.setState({
                initialized: true,
                shipDate: this.state.shipDate || this.getMinShipDate()
            });
            setTimeout(() => {
                this.initializeForm();
            }, 400);
        }
        if (this.props.submitRequested && !prevProps.submitRequested) {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        }
        else if (this.state.submitting) {
            if (this.receivedValidationError()) {
                this.setState({
                    submitting: false
                })
            }
            else if (this.receivedActionCompleteMessage()) {
                this.setState({
                    changesAccepted: true,
                    submitting: false
                });
                if (this.props.onSubmitSucceed && this.props.ordersState.newOrder) {
                    this.props.onSubmitSucceed(this.props.ordersState.newOrder);
                }
            }
        }
        else if (this.props.resetForm && !prevProps.resetForm) {
            this.initializeForm();
        }
        this.onOrderFormSliderChange();
    }

    public componentWillUnmount = () => {
        this.setState({
            initialized: false,
            submitting: false,
            changesAccepted: false,
            retrievingAccount: false,
            customerAccount: null,
            shipDate: null
        })
    }

    public handleFormSubmit = (values: any): void => {
        if (this.props.clientState.client && this.state.customerAccount) {
            this.props.onSubmit(true);
            this.setState({
                submitting: true
            });
            if (this.props.stateless) {
                let newOrder: OrdersStore.BaseOrder = values as OrdersStore.BaseOrder;
                newOrder.client = this.props.clientState.client;
                newOrder.customerAccount = this.state.customerAccount;
                this.addOrderAsync(newOrder);
            }
            else {
                this.props.actions.addOrder(this.props.clientState.client, this.state.customerAccount, values);
            }
        }
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={!this.state.initialized} isChild={true} />
                <motion.div id="add-order-form" animate={this.state.initialized ? "visible" : "hidden"} initial={"hidden"}
                    variants={this.orderFormVariants} transition={{ duration: 0.75 }}>
                    <Container>
                        <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                            <Row>
                                <Col className="pl-0 pr-0">
                                    <Field name="accountRequired" component="input" type="hidden" />
                                    <Field name="shiptoRequired" component="input" type="hidden" />
                                    {this.state.customerAccountTemp && (
                                        <div className="shipto-selector">
                                            <Loader isLoading={this.state.loadingAccount} isChild={true} />
                                            {this.state.customerAccountTemp && (
                                                <React.Fragment>
                                                    <div className="step">
                                                        <div className="step-number">1</div>
                                                        <div className="step-label">Select Shipping Location</div>
                                                    </div>
                                                    <div className="field-container">
                                                        <Field name="shipto" label="" component={FieldWrapper.renderShipToListField}
                                                            prompt={"Select..."} options={this.state.customerAccountTemp.shipToLocations}
                                                            disabled={this.state.submitting || this.state.changesAccepted || this.props.disabled}
                                                            required={true} onSelectShipTo={this.onSelectShipTo} />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    )}
                                    {this.userIsMultipleAccountBuyer() && (
                                        <React.Fragment>
                                            <div className="step">
                                                <div className="step-number">1</div>
                                                <div className="step-label">Select Account</div>
                                            </div>
                                            <div className="field-container">
                                                <Field name="account" label="" component={FieldWrapper.renderBuyerAccountListField}
                                                    disabled={this.state.submitting || this.state.changesAccepted || this.props.disabled || (this.props.customerAccount ? true : false)} required={true}
                                                    options={this.state.buyerAccounts} onSelectBuyerAccount={this.onSelectAccount}
                                                    preselectedAccount={this.props.customerAccount ? this.convertCustomerAccountToName(this.props.customerAccount) : undefined} />
                                            </div>
                                        </React.Fragment>
                                    )}
                                    {!this.isBuyer() && (
                                        <React.Fragment>
                                            <div className="step">
                                                <div className="step-number">1</div>
                                                <div className="step-label">Select Customer</div>
                                            </div>
                                            <div className="field-container">
                                                <Field name="account" label="" component={FieldWrapper.renderCustomerFinderField}
                                                    disabled={this.state.submitting || this.state.changesAccepted || this.props.disabled || (this.props.customerAccount ? true : false)} required={true}
                                                    onSelectCustomer={this.onSelectAccount} />
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <Field name="customerNumber" component="input" type="hidden" />
                                </Col>
                            </Row>
                            <motion.div id="orderFormSlider" animate={this.state.customerAccount ? "visible" : "hidden"} initial={(this.props.userState.user && this.isBuyer()) ? "visible" : this.state.customerAccount ? "visible" : "hidden"}
                                variants={this.orderFormSliderVariants} transition={{ duration: 0.25 }} onAnimationComplete={this.onOrderFormSliderChange}>
                                <React.Fragment>
                                    {this.requiresSeason() && (
                                        <Row>
                                            <Col className="pl-0 pr-0">
                                                <label>Select season here...</label>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col className={"pl-0 pr-0" + (this.getStepNumber('SHIP_DATE') === '1' ? '' : ' mt-1')}>
                                            <div className="step-with-instructions">
                                                <div className="col1">
                                                    <div className="step">
                                                        <div className="step-number">{this.getStepNumber("SHIP_DATE")}</div>
                                                        <div className="step-label">Pick Ship Date</div>
                                                    </div>
                                                    <div className="field-container">
                                                        <Field name="shipDate" label="" component={FieldWrapper.renderDatePickerField} hideClearButton={true}
                                                            disabled={this.state.submitting || this.state.changesAccepted || this.props.disabled}
                                                            required={true} onSelectDate={this.onSelectShipDate} selectedDate={this.state.shipDate}
                                                            minDate={this.getMinShipDate()} wrapperClass="shipdate-picker" />
                                                    </div>
                                                </div>
                                                <div className="col2">
                                                    {this.props.orderDatesState.orderDates && this.requiresLeadDays() && (
                                                        <ShipDateRules orderDates={this.props.orderDatesState.orderDates} />
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pl-0 pr-0 mt-3">
                                            <div className="step">
                                                <div className="step-number">{this.getStepNumber("PO_NUMBER")}</div>
                                                <div className="step-label">PO Number{this.requiresPO() ? "" : " (optional)"}</div>
                                            </div>
                                            <div className="field-container">
                                                <Field name="poNumber" type="text" label="" component={FieldWrapper.renderTextField}
                                                    maxLength={80} autoComplete={false} autoFocus={true} required={this.requiresPO()}
                                                    disabled={this.state.submitting || this.state.changesAccepted || this.props.disabled}
                                                    onChange={this.onEnterPONumber} />
                                                <Field name="poRequired" component="input" type="hidden" />
                                            </div>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            </motion.div>
                            <button type="submit" ref={this.submitButton}>Submit</button>
                        </form>
                        {this.state.retrievingAccount && (
                            <div className="retrieval-message">Retrieving account...</div>
                        )}
                    </Container>
                </motion.div>
            </React.Fragment>
        )
    }

    // ----------------
    // HELPERS

    private addOrderAsync = (newOrder: OrdersStore.BaseOrder): void => {
        let fetchError: boolean = false;
        let action: string = 'orders/add';
        let url: string = `${action}`;

        fetch(url,
            {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify(newOrder)
            })
            .then(response => {
                if (response.status >= 400) {
                    ErrorMessage.getFromResponse(response, action).then(
                        (errorMessage => {
                            this.props.actions.broadcastMessage(errorMessage);
                        })
                    );
                    fetchError = true;
                    throw new Error();
                }
                return response.json();
            })
            .then(data => {
                let order: OrdersStore.BaseOrder = data as OrdersStore.BaseOrder;
                if (this.props.onSubmitSucceed) {
                    this.props.onSubmitSucceed(order);
                }
            },
            err => {
                this.props.onSubmitFail();
                if (!fetchError) {
                    this.props.actions.broadcastMessage(ErrorMessage.getFromError(err, action));
                }                
            });
    }

    private convertCustomerAccountToName = (customerAccount: CustomerAccount): CustomerName => {
        return {
            address: customerAccount.shipToLocations[0].address,
            billto: customerAccount.billto,
            billto_shipto: this.getAccountNumber(customerAccount),
            name: customerAccount.name,
            nameOnly: customerAccount.nameOnly,
            number: customerAccount.number,
            shipto: customerAccount.shipToLocations[0].shipto || '',
            locationDescription: customerAccount.shipToLocations[0].locationDescription
        };
    }

    private customerInStore = (customerNumber: string): boolean => {
        let inStore: boolean = false;
        if (this.props.customersState.customerDetail) {
            inStore = (this.props.customersState.customerDetail.number === customerNumber);
        }
        return inStore;
    }

    private ensureDataFetched = (): void => {
        if (this.props.clientState.client && this.props.userState.user) {
            if (!this.props.orderDatesState.orderDates) {
                this.props.actions.requestOrderDates(this.props.clientState.client);
                setTimeout(() => {
                    this.ensureDataFetched();
                }, 400);
            }
            else if (!this.userHasAccounts()) {
                this.props.actions.requestBuyerAccounts(this.props.userState.user);
                setTimeout(() => {
                    this.ensureDataFetched();
                }, 400);
            }
            else if (!this.state.initialized) {
                this.setState({
                    initialized: true,
                    buyerAccounts: this.props.userState.user ? this.props.userState.user.buyerAccounts : []
                });
            }
        }
        else {
            setTimeout(() => {
                this.ensureDataFetched();
            }, 250);
        }
    }

    private getAccountNumber = (account: CustomersStore.CustomerAccount): string => {
        let accountNumber: string = account.billto;
        if (account.shipToLocations[0].shipto) {
            accountNumber = accountNumber + "-" + account.shipToLocations[0].shipto;
        }
        return accountNumber;
    }

    private getMinShipDate = (): Date | null | undefined => {
        let minShipDate: Date | null | undefined = undefined;
        if (this.props.orderDatesState.orderDates) {
            if (this.props.orderDatesState.orderDates.earliestShipDateNonEmbroidered) {
                minShipDate = this.props.orderDatesState.orderDates.earliestShipDateNonEmbroidered;
            }
        }
        return minShipDate;
    }

    private getStepNumber = (tag: string): string => {
        let stepNumber: string = '';        
        switch (tag) {
            case 'SHIP_DATE':
                if (this.userIsSingleAccountBuyer()) {
                    stepNumber = this.requiresSeason() ? "2" : "1";
                }
                else {
                    stepNumber = this.requiresSeason() ? "3" : "2";
                }
                break;
            case 'PO_NUMBER':
                let prevStep: string = this.getStepNumber('SHIP_DATE');
                switch (prevStep) {
                    case "1":
                        stepNumber = "2";
                        break;
                    case "2":
                        stepNumber = "3";
                        break;
                    case "3":
                        stepNumber = "4";
                        break;
                }
                break;
        }
        return stepNumber;
    }

    private initializeForm = (): void => {
        if (this.props.userState.user) {
            this.props.change('accountRequired', true);
            this.props.change('shiptoRequired', false);
            if (this.userIsSingleAccountBuyer()) {
                let buyerAccount: CustomerName = this.props.userState.user.buyerAccounts[0];
                this.onSelectAccount(buyerAccount);
            }
            else if (this.props.customerAccount) {
                let preselectedAccount: CustomerName = this.convertCustomerAccountToName(this.props.customerAccount);
                this.onSelectAccount(preselectedAccount);
            }
            else {
                this.props.change('poRequired', this.requiresPO());
            }
        }
        this.props.change('shipDate', this.state.shipDate);        
    }

    private isBuyer = (): boolean => {
        return (this.props.userState.user && this.props.userState.user.role === Role.Buyer) ? true : false;
    }

    private loadAccount = (customerNumber: string): void => {
        if (this.props.customersState.customerDetail && this.customerInStore(customerNumber)) {
            this.setState({
                customerAccount: this.props.customersState.customerDetail.shipToLocations.length <= 1 ? this.props.customersState.customerDetail : undefined,
                customerAccountTemp: this.props.customersState.customerDetail.shipToLocations.length > 1 ? this.props.customersState.customerDetail : undefined
            });
            this.props.change("poRequired", this.requiresPO(this.props.customersState.customerDetail));
        }
        else if (this.props.clientState.client) {
            let customerNumbers: string[] = customerNumber.split('-');
            let billTo: string = customerNumbers[0];
            let shipTo: string = customerNumbers.length > 1 ? customerNumbers[1] : '';
            this.setState({
                submitting: true,
                loadingAccount: true,
                customerAccount: undefined,
                customerAccountTemp: undefined
            });
            if (this.props.onRetrievingAccount) {
                this.props.onRetrievingAccount(true);
            }
            this.props.asyncActions.requestCustomerDetailAsync(this.props.clientState.client.code, billTo, shipTo, true)
                .then(result => {
                    if (result) {
//                        this.props.change('customerNumber', customerNumber);
                        this.setState({
                            submitting: false,
                            loadingAccount: false,
                            customerAccount: result.shipToLocations.length <= 1 ? result : undefined,
                            customerAccountTemp: result.shipToLocations.length > 1 ? result : undefined
                        });
                        if (this.props.onRetrievingAccount) {
                            this.props.onRetrievingAccount(false);
                        }
                        this.props.change("shiptoRequired", result.shipToLocations.length <= 1 ? true : false);
                        this.props.change("poRequired", this.requiresPO(result));                        
                    }
                    else {
                        this.setState({
                            submitting: false,
                            loadingAccount: false
                        });
                        this.props.actions.broadcastMessage({
                            messageType: MessageStore.MessageType.ERROR,
                            text: 'Account not found',
                            action: 'requestCustomerDetail',
                            reference: null
                        });
                    }
                },
                err => {
                    this.setState({
                        submitting: false,
                        retrievingAccount: false
                    })
                });
        }
    }

    private onEnterPONumber = (): void => {
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    private onOrderFormSliderChange = (): void => {
        setTimeout(() => {
            $('#orderFormSlider').css({ 'overflow': this.state.customerAccount ? 'visible' : 'hidden' });
            if (this.state.customerAccount) {
                this.props.change('poRequired', this.requiresPO());
            }
        }, 400);
    }

    private onSelectAccount = (selectedCustomer: CustomerName | null): void => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        if (selectedCustomer) {
            this.retrieveAccount(selectedCustomer);
            setTimeout(() => {
                this.props.change('account', selectedCustomer);
//                this.props.change('customerNumber', selectedCustomer.number);
            });
        }
        else {
            this.setState({                
                customerAccount: undefined
            });
            this.props.change('account', undefined);
//            this.props.change('customerNumber', undefined);
        }
    }

    private onSelectShipDate = (date: Date) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        this.setState({
            shipDate: date
        });
        this.props.change('shipDate', date);
    }

    private onSelectShipTo = (shipTo: CustomersStore.CustomerDetail): void => {
        if (this.state.customerAccountTemp) {
            let customerAccount: CustomerAccount = cloneDeep(this.state.customerAccountTemp);
            let index: number = customerAccount.shipToLocations.findIndex((c: CustomersStore.CustomerDetail) => {
                return c.number === shipTo.number;
            });
            customerAccount.shipToLocations.splice(index, 1);
            for (let n = 0; n < customerAccount.shipToLocations.length; n++) {
                customerAccount.shipToLocations[n].selected = false;
            }
            customerAccount.shipToLocations.unshift(shipTo);
            this.props.change("shipto", shipTo);
            this.props.change("poRequired", customerAccount.poRequired);
            this.setState({
                customerAccount: customerAccount
            });
        }
    }

    private receivedActionCompleteMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) ?
            true : false;
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private requiresLeadDays = (): boolean => {
        return (this.props.orderDatesState.orderDates &&
            (this.props.orderDatesState.orderDates.earliestShipDateEmbroidered ||
             this.props.orderDatesState.orderDates.earliestShipDateNonEmbroidered)) ? true : false;
    }

    private requiresPO = (customerAccount? : CustomerAccount): boolean => {
        let required: boolean = (this.props.clientState.client && this.props.clientState.client.rules.poRequired) ? true : false;
        if (!required) {
            let account: CustomerAccount | null | undefined = customerAccount ? customerAccount : this.state.customerAccount;
            required = (customerAccount && customerAccount.poRequired) ? true : false;
        }
        return required;
    }

    private requiresSeason = (): boolean => {
        return (this.props.clientState.client && this.props.clientState.client.rules.seasonRequired) ? true : false;
    }

    private retrieveAccount = (selectedCustomer: CustomerName): void => {
        if (this.props.clientState.client) {
            this.setState({
                submitting: true,
                retrievingAccount: true
            });
            if (this.props.onRetrievingAccount) {
                this.props.onRetrievingAccount(true);
            }
            this.props.asyncActions.requestCustomerDetailAsync(this.props.clientState.client.code, selectedCustomer.billto, selectedCustomer.shipto, false)
                .then(result => {
                    if (result) {
//                        this.props.change('customerNumber', selectedCustomer.billto_shipto);
                        this.setState({
                            submitting: false,
                            retrievingAccount: false,
                            customerAccount: result
                        });
                        if (this.props.onRetrievingAccount) {
                            this.props.onRetrievingAccount(false);
                        }
                        this.props.change("poRequired", result.poRequired);
                    }
                    else {
                        this.setState({
                            submitting: false,
                            retrievingAccount: false
                        });
                        this.props.actions.broadcastMessage({
                            messageType: MessageStore.MessageType.ERROR,
                            text: 'Account not found',
                            action: 'requestCustomerDetail',
                            reference: null
                        });
                    }
                },
                err => {
                    this.setState({
                        submitting: false,
                        retrievingAccount: false
                    });
                });
        }
    }

    private setFocusOnCustomerFinder = (): void => {
        let customerFinder: JQuery<Element> =  $('#customer-finder .rbt-input');
        if (customerFinder.length > 0) {
            setTimeout(() => {
                customerFinder.focus();
            }, 400);
        }
        else {
            setTimeout(() => {
                this.setFocusOnCustomerFinder();
            }, 250);
        }
    }

    private userHasAccounts = (): boolean => {
        let hasAccounts: boolean = false;
        if (this.props.userState.user) {
            if (this.props.userState.user.role == Role.Buyer) {
                hasAccounts = this.props.userState.user.buyerAccounts.length > 0;
            }
            else {
                hasAccounts = true;
            }
        }
        return hasAccounts;
    }

    private userIsBuyer = (): boolean => {
        let isBuyer: boolean = false;
        if (this.props.userState.user) {
            isBuyer = this.props.userState.user.role === Role.Buyer;
        }
        return isBuyer;
    }

    private userIsMultipleAccountBuyer = (): boolean => {
        let isMultipleAccountBuyer: boolean = this.userIsBuyer();
        if (isMultipleAccountBuyer && this.props.userState.user) {
            isMultipleAccountBuyer = this.props.userState.user.buyerAccounts.length > 1;
        }
        return isMultipleAccountBuyer;
    }

    private userIsSingleAccountBuyer = (): boolean => {
        let isSingleAccountBuyer: boolean = this.userIsBuyer();
        if (isSingleAccountBuyer && this.props.userState.user) {
            isSingleAccountBuyer = this.props.userState.user.buyerAccounts.length === 1;
        }
        return isSingleAccountBuyer;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        customersState: state.customers,
        messageState: state.message,
        orderDatesState: state.orderDates,
        ordersState: state.orders,
        userState: state.user,
        initialValues: {
            customerNumber: (state.user.user && state.user.user.role === Role.Buyer) ? state.user.user.customerNumber : null
        }
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrderDatesStore.actionCreators,
            OrdersStore.actionCreators,
            UserStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => dispatch(CustomersStore.actionCreators.requestCustomerDetail(clientCode, billto, shipto, updateState))
        }
    };
}

export default connect<{}, {}, AddOrderFormOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'addOrderForm',
    validate: validateAddOrderForm,
    onSubmitFail: onFormValidationFailed,
    enableReinitialize: true
})(AddOrderForm as any));
