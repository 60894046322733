import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import * as UserStore from '../../store/User';
import { Client } from '../../store/Client';
import { BaseOrder } from '../../store/Orders';
import { CustomerAccount, CustomerDetail } from '../../store/Customers';
import { CustomerName } from '../../common/AccountTypes';
import { Role } from '../../enums/Role';
import { OrderCopy } from '../../store/Orders';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import Checkbox from '../checkbox/Checkbox';
import FormResult from '../form-result/FormResult';
import $ from 'jquery';

import './CopyOrder.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState,
    userState: {} as UserStore.UserState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrdersStore.actionCreators)
};

interface CopyOrderAsyncActions {
    asyncActions: {
        copyOrderAsync: (client: Client, orderToCopy: OrderCopy) => Promise<OrderCopy | null | undefined>
    }
}

interface CopyOrderOwnProps {
    client: Client;
    orderToCopy: BaseOrder | null;
    onDismiss: (id: number | null | undefined) => void;
}

type CopyOrderProps =
    CopyOrderOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & CopyOrderAsyncActions;

// ----------------
// LOCAL STATE

interface CopyOrderState {
    submitting: boolean;
    retrieving: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    partialCopy: boolean;
    formResult: string | null;
    actions: FieldWrapper.OptionValue[];
    selectedAction: number;
    copyQuantities: boolean;
    copyEmbroideries: boolean;
    buyerAccounts: CustomerName[];
    selectedCustomer: CustomerName | null | undefined;
    copyOrderId: number | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateCopyOrderForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.action || parseInt(formValues.action) === 0) {
        errors.action = 'Select action';
    }
    else if (formValues.action && parseInt(formValues.action) === 2) {
        if (!formValues.customerNumber) {
            errors.account = 'Required';
        }
    }
    return errors;
}

class CopyOrder extends React.PureComponent<CopyOrderProps, CopyOrderState> {

    // ----------------
    // VARIABLES

    public collapsibleActionBarVariants = {
        hidden: { height: 0, overflow: 'hidden', paddingBottom: '0', paddingTop: '0' },
        visible: { height: 'auto', overflow: 'visible', paddingBottom: '10px', paddingTop: '20px' }
    }

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', overflow: 'visible' }
    }

    public overlayVariants = {
        hidden: { zIndex: '-1' },
        visible:  {zIndex: 103 }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: CopyOrderProps, state: CopyOrderState) {
        super(props);
        this.state = {
            submitting: false,
            retrieving: false,
            changesAccepted: false,
            changesRejected: false,
            partialCopy: false,
            formResult: null,
            actions: this.getActions(),
            selectedAction: 0,
            copyQuantities: true,
            copyEmbroideries: true,
            buyerAccounts: this.props.userState.user ? this.props.userState.user.buyerAccounts : [],
            selectedCustomer: this.props.userState.user && this.userIsSingleAccountBuyer() ? this.props.userState.user.buyerAccounts[0] : undefined,
            copyOrderId: undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: CopyOrderProps) => {
        if (this.state.submitting) {
            if (this.receivedErrorMessage()) {
                this.props.onDismiss(null);
            }
            else if (this.receivedValidationError()) {
                let formResult: string | null = this.getFormResult();
                setTimeout(() => {
                    this.props.actions.clearMessage();
                    this.setState({
                        submitting: false,
                        changesRejected: true,
                        formResult: formResult
                    });
                })
            }
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        if (this.props.orderToCopy && this.state.selectedCustomer) {
            let orderCopy: OrderCopy = {
                copyEmbroideries: parseInt(values.action) === 1 ? this.state.copyEmbroideries : false,
                copyQuantities: this.state.copyQuantities,
                customer: this.state.selectedCustomer,
                order: this.props.orderToCopy,
                itemsUnavailable: false
            };
            this.saveCopy(orderCopy);
        }
    }

    public render = () => {
        return (
            <Modal id="copy-order" isOpen={this.props.orderToCopy != null}
                onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(null)} className={(this.state.retrieving || this.state.submitting) ? "disabled" : ""}>
                    Copy Order
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                        <Container>                        
                            <Row>
                                <Col className={"collapsible-row pl-3 action-bar pr-4 text-center"}>
                                    <motion.div animate={this.state.selectedAction === 3 ? "hidden" : "visible"} initial={this.state.selectedAction === 3 ? "hidden" : "visible"}
                                        variants={this.collapsibleActionBarVariants} transition={{ duration: ((this.state.selectedAction === 0 || this.state.selectedAction === 3) ? 0 : 0.25) }}>
                                            <Field name="action" type="text" label="" component={FieldWrapper.renderRadioButtonField}
                                                options={this.state.actions} onChange={this.onSelectAction}
                                                disabled={this.state.retrieving || this.state.submitting || this.state.changesAccepted} />
                                    </motion.div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="collapsible-row pl-0 pr-0">
                                    <motion.div animate={(this.state.selectedAction === 1 || this.state.selectedAction === 3) ? "visible" : "hidden"} initial={(this.state.selectedAction === 1 || this.state.selectedAction === 3) ? "visible" : "hidden"}
                                        variants={this.collapsibleRowVariants} transition={{ duration: ((this.state.selectedAction === 0 || this.state.selectedAction === 3) ? 0 : 0.25) }}>
                                        <Container className={"component-wrapper same-customer" + (this.state.selectedAction === 3 ? " borderless" : "")}>
                                            <Row>
                                                <Col className="pl-0 pr-0 text-center">
                                                    <div className="copy-options">
                                                        <div className="copy-option">
                                                            <Checkbox id="sameCustomerCopyOption1" label="Copy order item quantities" selected={this.state.copyQuantities} disabled={this.state.submitting || this.state.changesAccepted}
                                                                onChange={(checked: boolean) => this.onSelectCopyOption(1, checked)} />
                                                        </div>
                                                        <div className="copy-option">
                                                            <Checkbox id="sameCustomerCopyOption2" label="Copy embroidery specifications" selected={this.state.copyEmbroideries} disabled={this.state.submitting || this.state.changesAccepted}
                                                                onChange={(checked: boolean) => this.onSelectCopyOption(2, checked)} /> 
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </motion.div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="collapsible-row pl-0 pr-0">
                                    <motion.div animate={(this.state.selectedAction === 2 && this.userIsMultipleAccountBuyer()) ? "visible" : "hidden"} initial={(this.state.selectedAction === 2 && this.userIsMultipleAccountBuyer()) ? "visible" : "hidden"}
                                        variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }} onAnimationComplete={this.setFocusOnBuyerAccountList}>
                                        <Container className="component-wrapper customer-list">
                                            <Row>
                                                <Col className="pl-3 pr-3">
                                                    {this.userIsMultipleAccountBuyer() && (
                                                        <Field name="account" label="" component={FieldWrapper.renderBuyerAccountListField}
                                                            disabled={this.state.submitting || this.state.changesAccepted} prompt="Select account..."
                                                            options={this.state.buyerAccounts} onSelectBuyerAccount={this.onSelectAccount} />
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pl-3 pr-3 pt-4">
                                                    <div className="copy-options">
                                                        <div className="copy-option">
                                                            <Checkbox id="singleAccountCopyOption1" label="Copy order item quantities" selected={this.state.copyQuantities} disabled={this.state.submitting || this.state.changesAccepted}
                                                                onChange={(checked: boolean) => this.onSelectCopyOption(1, checked)} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </motion.div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="collapsible-row pl-0 pr-0">
                                    <motion.div animate={(this.state.selectedAction === 2 && !this.userIsBuyer()) ? "visible" : "hidden"} initial={(this.state.selectedAction === 2 && !this.userIsBuyer()) ? "visible" : "hidden"}
                                        variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }} onAnimationComplete={this.setFocusOnCustomerFinder}>
                                        <Container className="component-wrapper customer-finder">
                                            <Row>
                                                <Col className="pl-3 pr-3">
                                                    {!this.userIsBuyer() && (
                                                        <Field name="account" label="" component={FieldWrapper.renderCustomerFinderField} 
                                                            disabled={this.state.submitting || this.state.changesAccepted}
                                                            onSelectCustomer={this.onSelectAccount} />
                                                    )}
                                                </Col>                                                
                                            </Row>
                                            <Row>
                                                <Col className="pl-3 pr-3 pt-3">
                                                    <div className="copy-options">
                                                        <div className="copy-option">
                                                            <Checkbox id="multiAccountCopyOption1" label="Copy order item quantities" selected={this.state.copyQuantities} disabled={this.state.submitting || this.state.changesAccepted}
                                                                onChange={(checked: boolean) => this.onSelectCopyOption(1, checked)} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </motion.div>
                                </Col>
                            </Row>
                        </Container>
                        <Field name="customerNumber" component="input" type="hidden" />
                        <button type="submit" ref={this.submitButton}>Submit</button>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={this.submitCopyOrderForm} disabled={this.state.retrieving || this.state.submitting }>
                                    { this.state.submitting ? "Working ..." : this.state.changesAccepted ? "View Copy" : "Continue"}
                                </Button>
                                <Button color="link" onClick={() => this.props.onDismiss(null)} disabled={this.state.retrieving || this.state.submitting }>
                                    { this.state.changesAccepted ? "Close" : "Cancel" }
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
                <motion.div className={"partial-copy-message" + (this.state.partialCopy ? " show" : "")} animate={this.state.partialCopy ? "visible" : "hidden"}
                    initial={"hidden"} variants={this.overlayVariants} transition={{ duration: 0.25 }}>
                    <label>Note: one or more items on original order are no longer available.</label>
                    <div className="button-bar">
                        <Button type="button" color="secondary" onClick={this.hidePartialCopyMessage}>OK</Button>
                    </div>
                </motion.div>
                <div className="partial-copy-overlay"></div>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private convertCustomerAccountToName = (order: BaseOrder): CustomerName => {
        if (order.customerAccount) {
            return {
                address: order.customerAccount.shipToLocations[0].address,
                billto: order.customerAccount.billto,
                billto_shipto: this.getAccountNumber(order.customerAccount),
                name: order.customerAccount.name,
                nameOnly: order.customerAccount.nameOnly,
                number: order.customerAccount.number,
                shipto: order.customerAccount.shipToLocations[0].shipto || '',
                locationDescription: order.customerAccount.shipToLocations[0].locationDescription
            };
        }
        else {
            let orderDetail: OrdersStore.OrderDetail = order as OrdersStore.OrderDetail;
            if (orderDetail && orderDetail.shipToCustomer) {
                return {
                    address: orderDetail.shipToCustomer.address,
                    billto: orderDetail.billto,
                    billto_shipto: this.getAccountNumberFromCustomerDetail(orderDetail.shipToCustomer),
                    name: orderDetail.shipToCustomer.name,
                    nameOnly: orderDetail.shipToCustomer.nameOnly,
                    number: orderDetail.shipToCustomer.number,
                    shipto: orderDetail.shipToCustomer.shipto || "",
                    locationDescription: orderDetail.shipToCustomer.locationDescription
                };
            }
            else {
                return {
                    address: null,
                    billto: order.billto,
                    billto_shipto: this.getAccountNumberFromBaseOrder(order),
                    name: "",
                    nameOnly: "",
                    number: order.customerNumber,
                    shipto: order.shipto || "",
                    locationDescription: ""
                };
            }
        }
    }

    private getAccountNumber = (account: CustomerAccount): string => {
        let accountNumber: string = account.billto;
        if (account.shipToLocations[0].shipto) {
            accountNumber = accountNumber + "-" + account.shipToLocations[0].shipto;
        }
        return accountNumber;
    }

    private getAccountNumberFromBaseOrder = (order: BaseOrder): string => {
        let accountNumber: string = order.billto;
        if (order.shipto) {
            accountNumber = accountNumber + "-" + order.shipto;
        }
        return accountNumber;
    }

    private getAccountNumberFromCustomerDetail = (customerDetail: CustomerDetail): string => {
        let accountNumber: string = customerDetail.billto;
        if (customerDetail.shipto) {
            accountNumber = accountNumber + "-" + customerDetail.shipto;
        }
        return accountNumber;
    }

    private getActions = (): FieldWrapper.OptionValue[] => {
        let actions: FieldWrapper.OptionValue[] = [];
        let sameCustomerLabel: string = this.userIsBuyer() ? "Same Account" : "Same Customer";
        let diffCustomerLabel: string = this.userIsBuyer() ? "Different Account" : "Different Customer";
        actions.push({ label: sameCustomerLabel, value: 1 });
        actions.push({ label: diffCustomerLabel, value: 2 });
        return actions;
    }

    private getFormResult = (): string | null => {
        let formResult: string | null = null;
        if (this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) {
                formResult = this.props.messageState.message.text || 'Form contains invalid entries';
            }
        }
        return formResult;
    }

    private hidePartialCopyMessage = (): void => {
        this.setState({
            partialCopy: false
        });
    }

    private initializeForm = (): void => {
        let selectedAction: number = this.userIsSingleAccountBuyer() ? 3 : 0;
        let selectedCustomer: CustomerName | null | undefined = (this.props.userState.user && this.userIsSingleAccountBuyer() ? this.props.userState.user.buyerAccounts[0] : undefined);
        this.setState({
            submitting: false,
            retrieving: false,
            changesAccepted: false,
            changesRejected: false,
            partialCopy: false,
            formResult: null,
            actions: this.getActions(),
            selectedAction: selectedAction,
            copyQuantities: true,
            copyEmbroideries: true,
            buyerAccounts: this.props.userState.user ? this.props.userState.user.buyerAccounts : [],
            selectedCustomer: selectedCustomer,
            copyOrderId: undefined
        });
        this.props.change("action", selectedAction);
        this.props.change("customerNumber", selectedCustomer ? selectedCustomer.number : undefined);
    }

    private onSelectAccount = (selectedCustomer: CustomerName | null): void => {
        this.props.change('customerNumber', selectedCustomer ? selectedCustomer.number : undefined);
        setTimeout(() => {
            this.props.change('account', selectedCustomer); 
            this.setState({
                selectedCustomer: selectedCustomer
            });
        });
    }

    private onSelectAction = (event: React.ChangeEvent): void => {
        let selectedValue = event.target.getAttribute("value");
        if (selectedValue && parseInt(selectedValue) != this.state.selectedAction) {
            let selectedCustomer: CustomerName | null | undefined = (parseInt(selectedValue) === 1 && this.props.orderToCopy) ?
                this.convertCustomerAccountToName(this.props.orderToCopy) : undefined;
            this.setState({
                selectedAction: parseInt(selectedValue),
                selectedCustomer: selectedCustomer
            });
        }
    }

    private onSelectCopyOption = (index: number, checked: boolean): void => {
        let copyQuantities: boolean = index === 1 ? checked : this.state.copyQuantities;
        let copyEmbroideries: boolean = index === 2 ? checked : this.state.copyEmbroideries;
        this.setState({
            copyQuantities: copyQuantities,
            copyEmbroideries: copyEmbroideries
        });
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private resetForm = (): void => {
        this.setState({
            submitting: false,
            retrieving: false,
            changesAccepted: false,
            changesRejected: false,
            partialCopy: false,
            formResult: null,
            selectedAction: 0
        });
        this.props.reset();
    }

    private saveCopy = (orderCopy: OrderCopy): void => {
        this.setState({
            submitting: true,
            changesAccepted: false,
            changesRejected: false
        });
        this.props.asyncActions.copyOrderAsync(this.props.client, orderCopy)
            .then(result => {
                let updatedOrderToCopy: OrderCopy = result as OrderCopy;
                this.setState({
                    submitting: false,
                    changesAccepted: updatedOrderToCopy.id ? true : false,
                    changesRejected: updatedOrderToCopy.id ? false : true,
                    formResult: updatedOrderToCopy.id ? "Changes saved" : null,
                    copyOrderId: updatedOrderToCopy.id,
                    partialCopy: result && result.itemsUnavailable ? true : false
                });
            },
            err => {
                //this.setState({
                //    submitting: false
                //});
            });
    }

    private setFocusOnBuyerAccountList = (): void => {
        if (this.state.selectedAction === 2) {
            let buyerAccountList: JQuery<Element> = $('#buyer-account-picker .btn');
            if (buyerAccountList.length > 0) {
                setTimeout(() => {
                    buyerAccountList.focus();
                }, 100);
            }
            else {
                setTimeout(() => {
                    this.setFocusOnBuyerAccountList();
                }, 250);
            }
        }
    }

    private setFocusOnCustomerFinder = (): void => {
        if (this.state.selectedAction === 2) {
            let customerFinder: JQuery<Element> = $('#customer-finder .rbt-input');
            if (customerFinder.length > 0) {
                setTimeout(() => {
                    customerFinder.focus();
                }, 100);
            }
            else {
                setTimeout(() => {
                    this.setFocusOnCustomerFinder();
                }, 250);
            }
        }
    }

    private submitCopyOrderForm = (): void => {
        if (this.state.changesAccepted) {
            this.props.onDismiss(this.state.copyOrderId);
        }
        else if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }

    private userIsBuyer = (): boolean => {
        let isBuyer: boolean = false;
        if (this.props.userState.user) {
            isBuyer = this.props.userState.user.role === Role.Buyer;
        }
        return isBuyer;
    }

    private userIsMultipleAccountBuyer = (): boolean => {
        let isMultipleAccountBuyer: boolean = this.userIsBuyer();
        if (isMultipleAccountBuyer && this.props.userState.user) {
            isMultipleAccountBuyer = this.props.userState.user.buyerAccounts.length > 1;
        }
        return isMultipleAccountBuyer;
    }

    private userIsSingleAccountBuyer = (): boolean => {
        let isSingleAccountBuyer: boolean = this.userIsBuyer();
        if (isSingleAccountBuyer && this.props.userState.user) {
            isSingleAccountBuyer = this.props.userState.user.buyerAccounts.length === 1;
        }
        return isSingleAccountBuyer;
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        ordersState: state.orders,
        userState: state.user
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrdersStore.actionCreators
        ), dispatch),
        asyncActions: {
            copyOrderAsync: (client: Client, orderToCopy: OrderCopy) => dispatch(OrdersStore.actionCreators.copyOrder(client, orderToCopy))
        }
    };
}

export default connect<{}, {}, CopyOrderOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'copyOrderForm',
    validate: validateCopyOrderForm,
    enableReinitialize: true
})(CopyOrder as any));