import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as CustomersStore from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import * as OrderDatesStore from '../../store/OrderDates';
import * as ProductlistsStore from '../../store/Productlists';
import * as UserStore from '../../store/User';
import { ClientCode } from '../../enums/ClientCode';
import { CustomerAccount } from '../../store/Customers';
import { CustomerName } from '../../common/AccountTypes';
import { ProductlistType } from '../../enums/ProductlistType';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import { Button, Container, Col, Row } from 'reactstrap';
import CustomerFinder from '../customer-finder/CustomerFinder';
import { Role } from '../../enums/Role';
import { User } from '../../store/User';
import FormResult from '../form-result/FormResult';
import * as Validators from '../../common/Validators';
import Loader from '../loader/Loader';
import cloneDeep from 'lodash/cloneDeep';
import $ from 'jquery';

import './AddProductlistForm.scss';
import { Select } from '@material-ui/core';

// ----------------
// PROPS

const applicationState = {
    clientState: {} as ClientStore.ClientState,
    customersState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState,
    orderDatesState: {} as OrderDatesStore.OrderDatesState,
    productlistsState: {} as ProductlistsStore.ProductlistsState,
    userState: {} as UserStore.UserState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrderDatesStore.actionCreators, ProductlistsStore.actionCreators)
};

interface AddProductlistFormAsyncActions {
    asyncActions: {
        requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string) => Promise<CustomersStore.CustomerAccount | null | undefined>,
        saveProductlistDetailAsync: (client: ClientStore.Client, productlistDetail: ProductlistsStore.ProductlistDetail) => Promise<ProductlistsStore.ProductlistDetail | null | undefined>
    }
}

interface AddProductlistFormOwnProps {
    onChange?: () => void;
    onRetrievingAccount?: (retrieving: boolean) => void;
    onSubmit: (submitting: boolean) => void;
    onSubmitFail: () => void;
    onSubmitSucceed?: (newList: ProductlistsStore.BaseProductlist) => void;
    onUpdate?: (updatedList: ProductlistsStore.ProductlistDetail) => void;
    submitRequested: boolean;
    disabled?: boolean;
    customerAccount?: CustomerAccount | null | undefined;
    editProductlist?: ProductlistsStore.ProductlistDetail | null | undefined;
    resetForm?: boolean;
}

type AddProductlistFormProps =
    AddProductlistFormOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & AddProductlistFormAsyncActions;

// ----------------
// LOCAL STATE

interface AddProductlistFormState {
    initialized: boolean;
    submitting: boolean;
    changesAccepted: boolean;
    retrievingAccount: boolean;
    customerAccount: CustomerAccount | null | undefined;
    expirationDate: Date | null | undefined;
    listTypes: FieldWrapper.OptionValue[];
    selectedListType: ProductlistType;
}

// ----------------
// FORM VALIDATOR

const validateAddProductlistForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.name) {
        errors.name = 'Required';
    }
    else if (formValues.name.length < 3) {
        errors.name = 'Min 3 characters';
    }
    if (!formValues.listType) {
        errors.listType = 'Required';
    }
    if (formValues.accountRequired && !formValues.account) {
        errors.account = 'Required';
    }
    return errors;
}

const onFormValidationFailed = (errors: any, dispatch: any, submitError: Error, props: AddProductlistFormOwnProps) => {
    if (props.onSubmitFail) {
        props.onSubmitFail();
    }
}

class AddProductlistForm extends React.PureComponent<AddProductlistFormProps, AddProductlistFormState> {

    // ----------------
    // VARIABLES

    public productlistFormVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    public productlistFormSliderVariants = {
        hidden: { overflow: 'hidden', height: 0 },
        visible: { overflow: 'hidden', height: 'auto' }
    }

    public defaultInput: React.RefObject<HTMLInputElement>;
    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: AddProductlistFormProps, state: AddProductlistFormState) {
        super(props);
        this.state = {
            initialized: (this.props.clientState.client && this.props.orderDatesState.orderDates) ? true : false,
            submitting: false,
            changesAccepted: false,
            retrievingAccount: false,
            customerAccount: this.props.customerAccount,
            expirationDate: this.getMinExpirationDate(),
            listTypes: this.getListTypes(),
            selectedListType: this.getListType()
        };
        if (this.state.initialized) {
            this.initializeForm();
        }
        this.submitButton = React.createRef<HTMLButtonElement>();
        this.defaultInput = React.createRef<HTMLInputElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.ensureDataFetched();
        if (this.props.userState.user && this.props.userState.user.role != Role.Buyer) {
        }
    }

    public componentDidUpdate = (prevProps: AddProductlistFormProps) => {
        if (this.props.clientState.client && this.props.orderDatesState.orderDates && !this.state.initialized) {
            this.setState({
                initialized: true,
                expirationDate: this.state.expirationDate || this.getMinExpirationDate()
            });
            setTimeout(() => {
                this.initializeForm();
            }, 400);
        }
        if (this.props.submitRequested && !prevProps.submitRequested) {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        }
        else if (this.state.submitting) {
            if (this.receivedValidationError()) {
                this.setState({
                    submitting: false
                });
            }
            else if (this.receivedActionCompleteMessage()) {
                this.setState({
                    changesAccepted: true,
                    submitting: false
                });
                if (this.props.onSubmitSucceed && this.props.productlistsState.newProductlist) {
                    this.props.onSubmitSucceed(this.props.productlistsState.newProductlist);
                }
            }
        }
        else if (this.props.resetForm !== prevProps.resetForm) {
            this.props.reset();
            this.setState({
                customerAccount: undefined,
                selectedListType: ProductlistType.Undefined
            });
            setTimeout(() => {
                this.initializeForm();

            });
        }
        this.onProductlistFormSliderChange();
    }

    public componentWillUnmount = () => {
        this.setState({
            initialized: false,
            submitting: false,
            changesAccepted: false,
            retrievingAccount: false,
            customerAccount: null,
            expirationDate: null
        });
    }

    public handleFormSubmit = (values: any): void => {
        if (this.props.clientState.client) {
            this.props.onSubmit(true);
            this.setState({
                submitting: true
            });
            if (this.props.editProductlist) {
                this.updateProductlistDetail(values);
            }
            else {
                this.props.actions.addProductlist(this.props.clientState.client, this.state.customerAccount, values);
            }
        }
    }

    public render = () => {
        return (
            <React.Fragment>
                <Loader isLoading={!this.state.initialized} isChild={true} />
                <motion.div id="add-productlist-form" animate={this.state.initialized ? "visible" : "hidden"} initial={"hidden"}
                    variants={this.productlistFormVariants} transition={{ duration: 0.75 }}
                    className={this.props.editProductlist ? "edit-form" : ""}>
                    <Container>
                        <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                            <Row>
                                <Col className="pl-0 pr-0 mb-2">
                                    <div className="step">
                                        <div className="step-number">1</div>
                                        <div className="step-label">Enter List Name</div>
                                    </div>
                                    <div className="field-container">
                                        <Field name="name" label={this.props.editProductlist ? "Name" : ""} type="text" component={FieldWrapper.renderTextField}
                                            reference={this.defaultInput} disabled={this.state.submitting || this.state.changesAccepted || this.props.disabled}
                                            required={true} maxLength={100} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onEnterListName(e)} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pr-0">
                                    <div className="step-with-instructions">
                                        <div className="col1">
                                            <div className="step">
                                                <div className="step-number">2</div>
                                                <div className="step-label">Select Type</div>
                                            </div>
                                            <div className="field-container radio">
                                                <Field name="listType" label={this.props.editProductlist ? "Type" : ""} component={FieldWrapper.renderRadioButtonField}
                                                    options={this.state.listTypes} required={true} onChange={this.onSelectListType}
                                                    disabled={this.state.submitting || this.state.changesAccepted || this.props.disabled} />
                                            </div>
                                        </div>
                                        <div className="col2">
                                            <div className="list-type-rules">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>View</th>
                                                            <th>Edit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className={(this.state.selectedListType === ProductlistType.Public ? "selected" : "")}>
                                                            <td>Public</td>
                                                            <td>Everyone</td>
                                                            <td>Owner</td>
                                                        </tr>
                                                        <tr className={(this.state.selectedListType === ProductlistType.Private ? "selected" : "")}>
                                                            <td>Private</td>
                                                            <td>Owner</td>
                                                            <td>Owner</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {(this.props.userState.user && this.props.userState.user.role != Role.Buyer) && 
                                <motion.div id="productlistFormSlider" animate={this.showCustomerFinder() ? "visible" : "hidden"}
                                    initial={"hidden"} variants={this.productlistFormSliderVariants} transition={{ duration: (this.showCustomerFinder() ? 0.25 : 0) }}
                                    onAnimationComplete={this.onProductlistFormSliderChange}>
                                    <React.Fragment>
                                        <Row>
                                            <Col className={"pl-0 pr-0"}>
                                                <div className="step">
                                                    <div className="step-number">3</div>
                                                    <div className="step-label">Customer (optional)</div>
                                                </div>
                                            <div className="field-container">
                                                <Field name="account" label={this.props.editProductlist ? "Customer" : ""} component={FieldWrapper.renderCustomerFinderField}
                                                    disabled={this.state.submitting || this.state.changesAccepted || this.props.disabled}
                                                    onSelectCustomer={this.onSelectAccount} resetFinder={this.props.resetForm} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                </motion.div>
                            }
                            <Field name="accountRequired" component="input" type="hidden" />
                            <Field name="customerNumber" component="input" type="hidden" />
                            <button type="submit" ref={this.submitButton}>Submit</button>
                        </form>
                        {this.state.retrievingAccount && (
                            <div className="retrieval-message">Retrieving account...</div>
                        )}
                    </Container>
                </motion.div>
            </React.Fragment>
        )
    }

    // ----------------
    // HELPERS

    private cloneEditProductlist = (values: any): ProductlistsStore.ProductlistDetail => {
        let cloneProductlist: ProductlistsStore.ProductlistDetail = cloneDeep(this.props.editProductlist) as ProductlistsStore.ProductlistDetail;
        cloneProductlist.name = values.name;
        cloneProductlist.listType = values.listType;
        cloneProductlist.customerAccount = this.state.customerAccount;
        cloneProductlist.billto = this.state.customerAccount ? this.state.customerAccount.billto : null;
        cloneProductlist.shipto = this.state.customerAccount ? this.state.customerAccount.shipto : null;
        cloneProductlist.customerName = this.state.customerAccount ? this.state.customerAccount.name : "";
        cloneProductlist.customerNameOnly = this.state.customerAccount ? this.state.customerAccount.nameOnly : "";
        cloneProductlist.customerNumber = this.state.customerAccount ? this.state.customerAccount.number : "";
        cloneProductlist.eventListId = undefined;
        return cloneProductlist;
    }

    private convertCustomerAccountToName = (customerAccount: CustomerAccount): CustomerName => {
        return {
            address: customerAccount.shipToLocations[0].address,
            billto: customerAccount.billto,
            billto_shipto: this.getAccountNumber(customerAccount),
            name: customerAccount.name,
            nameOnly: customerAccount.nameOnly,
            number: customerAccount.number,
            shipto: customerAccount.shipToLocations[0].shipto || '',
            locationDescription: ''
        };
    }

    private ensureDataFetched = (): void => {
        if (this.props.clientState.client) {
            this.props.actions.requestOrderDates(this.props.clientState.client);
        }
        else {
            setTimeout(() => {
                this.ensureDataFetched();
            }, 250);
        }
    }

    private getAccountNumber = (account: CustomersStore.CustomerAccount): string => {
        let accountNumber: string = account.billto;
        if (account.shipToLocations[0].shipto) {
            accountNumber = accountNumber + "-" + account.shipToLocations[0].shipto;
        }
        return accountNumber;
    }

    private getListType = (): ProductlistType => {
        let listType: ProductlistType = ProductlistType.Undefined;
        if (this.props.editProductlist) {
            listType = this.props.editProductlist.listType;
        }
        return listType;
    }

    private getListTypes = (): FieldWrapper.OptionValue[] => {
        let listTypes: FieldWrapper.OptionValue[] = [];
        listTypes.push({ label: 'Public', value: ProductlistType.Public });
        listTypes.push({ label: 'Private', value: ProductlistType.Private });
        return listTypes;
    }

    private getMinExpirationDate = (): Date | null | undefined => {
        let minExpirationDate: Date | null | undefined = undefined;
        if (this.props.orderDatesState.orderDates) {
            if (this.props.orderDatesState.orderDates.currentDate) {
                minExpirationDate = this.props.orderDatesState.orderDates.currentDate;
            }
        }
        return minExpirationDate;
    }

    private initializeForm = (): void => {
        if (this.props.userState.user && this.props.userState.user.role === Role.Buyer) {
            this.props.change('customerNumber', this.props.userState.user.customerNumber);
            this.props.change('accountRequired', false);
        }
        else if (this.props.userState.user) {
            this.props.change('accountRequired', false);
            if (this.props.customerAccount) {
                let selectedCustomer: CustomerName = this.convertCustomerAccountToName(this.props.customerAccount);
                this.props.change('account', selectedCustomer);
                this.props.change('customerNumber', selectedCustomer.billto_shipto);
            }
            else {
                this.props.change('account', null);
                this.props.change('customerNumber', null);
            }
        }
        if (this.props.editProductlist) {
            this.props.change('name', this.props.editProductlist.name);
            this.props.change('listType', this.props.editProductlist.listType);
            if (this.props.editProductlist.customerAccount) {
                let selectedCustomer: CustomerName = this.convertCustomerAccountToName(this.props.editProductlist.customerAccount);
                this.props.change('account', selectedCustomer);
                this.props.change('customerNumber', this.props.editProductlist.customerNumber);
                this.setState({
                    customerAccount: this.props.editProductlist.customerAccount
                });
            }
        }
        this.props.change('expirationDate', this.state.expirationDate);
        this.setFocusOnDefaultInput();
    }

    private onEnterListName = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    private onProductlistFormSliderChange = (): void => {
        setTimeout(() => {
            $('#productlistFormSlider').css({ 'overflow': this.state.selectedListType != ProductlistType.Undefined ? 'visible' : 'hidden' });
        }, 400);
    }

    private onSelectAccount = (selectedCustomer: CustomerName | null): void => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        if (selectedCustomer) {
            this.retrieveAccount(selectedCustomer);
        }
        else {
            this.setState({
                customerAccount: undefined
            });
            this.props.change('customerNumber', undefined);
        }
    }

    private onSelectListType = (event: React.ChangeEvent): void => {
        let selectedValue = event.target.getAttribute("value");
        if (selectedValue && parseInt(selectedValue) != this.state.selectedListType) {
            this.setState({
                selectedListType: parseInt(selectedValue)
            });
        }
    }

    private receivedActionCompleteMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) ?
            true : false;
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private retrieveAccount = (selectedCustomer: CustomerName): void => {
        if (this.props.clientState.client) {
            this.setState({
                submitting: true,
                retrievingAccount: true
            });
            if (this.props.onRetrievingAccount) {
                this.props.onRetrievingAccount(true);
            }
            this.props.asyncActions.requestCustomerDetailAsync(this.props.clientState.client.code, selectedCustomer.billto, selectedCustomer.shipto)
                .then(result => {
                    if (result) {
                        this.props.change('customerNumber', selectedCustomer.billto_shipto);
                        this.setState({
                            submitting: false,
                            retrievingAccount: false,
                            customerAccount: result
                        });
                        if (this.props.onRetrievingAccount) {
                            this.props.onRetrievingAccount(false);
                        }
                    }
                    else {
                        this.setState({
                            submitting: false,
                            retrievingAccount: false
                        });
                        this.props.actions.broadcastMessage({
                            messageType: MessageStore.MessageType.ERROR,
                            text: 'Account not found',
                            action: 'requestCustomerDetail',
                            reference: null
                        });
                    }
                },
                err => {
                    this.setState({
                        submitting: false,
                        retrievingAccount: false
                    });
                });
        }
    }

    private setFocusOnDefaultInput = (): void => {
        if (this.defaultInput && this.defaultInput.current) {
            this.defaultInput.current.focus();
        }
        else {
            setTimeout(() => {
                this.setFocusOnDefaultInput();
            }, 400);
        }
    }

    private showCustomerFinder = (): boolean => {
        let showFinder: boolean = false;
        if (this.props.userState.user && this.props.userState.user.role != Role.Buyer) {
            if (this.state.selectedListType != ProductlistType.Undefined) {
                showFinder = true;
            }
            else if (this.state.customerAccount) {
                showFinder = true;
            }
        }
        return showFinder;
    }

    private updateProductlistDetail = (values: any): void => {
        if (this.props.clientState.client) {
            let updatedProductlist: ProductlistsStore.ProductlistDetail = this.cloneEditProductlist(values);
            this.props.asyncActions.saveProductlistDetailAsync(this.props.clientState.client, updatedProductlist)
                .then(result => {
                    if (this.props.onUpdate && result) {
                        this.props.onUpdate(result);
                    }
                },
                err => {                    
                })
        }        
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        clientState: state.client,
        customersState: state.customers,
        messageState: state.message,
        orderDatesState: state.orderDates,
        productlistsState: state.productlists,
        userState: state.user,
        initialValues: {
            customerNumber: (state.user.user && state.user.user.role === Role.Buyer) ? state.user.user.customerNumber : null
        }
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrderDatesStore.actionCreators,
            ProductlistsStore.actionCreators
        ), dispatch),
        asyncActions: {
            requestCustomerDetailAsync: (clientCode: ClientCode, billto: string, shipto: string, updateState: boolean) => dispatch(CustomersStore.actionCreators.requestCustomerDetail(clientCode, billto, shipto, updateState)),
            saveProductlistDetailAsync: (client: ClientStore.Client, productlistDetail: ProductlistsStore.ProductlistDetail) => dispatch(ProductlistsStore.actionCreators.saveProductlistDetail(client, productlistDetail))
        }
    };
}

export default connect<{}, {}, AddProductlistFormOwnProps>(
    mapStateToProps,
    mapDispatchToProps,
)(reduxForm({
    form: 'addProductlistForm',
    validate: validateAddProductlistForm,
    onSubmitFail: onFormValidationFailed,
    enableReinitialize: true

})(AddProductlistForm as any));
