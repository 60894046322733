﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TapeSelectionState {
    selected: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface TapeSelectedAction {
    type: 'TAPE_SELECTED';
    selected: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = TapeSelectedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    tapeSelected: (selected: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TAPE_SELECTED', selected: selected });
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: TapeSelectionState = { selected: false };

export const reducer: Reducer<TapeSelectionState> = (state: TapeSelectionState | undefined, incomingAction: Action): TapeSelectionState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'TAPE_SELECTED':
            return {
                selected: action.selected
            };
        default:
            return state;
    }
}