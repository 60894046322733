import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import * as OrderItemsStore from '../../store/OrderItems';
import * as UserStore from '../../store/User';
import { Client } from '../../store/Client';
import { CustomerAccount } from '../../store/Customers';
import { Product, ProductDetail } from '../../store/Products';
import { NewOrderItem } from '../../store/OrderItems';
import { Role } from '../../enums/Role';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import OrderDescription from '../order-description/OrderDescription';
import OrderPicker from '../order-picker/OrderPicker';
import AddOrderForm from '../add-order-form/AddOrderForm';
import ImageLoader from '../image-loader/ImageLoader';
import Loader from '../loader/Loader';
import cloneDeep from 'lodash/cloneDeep';
import { toTitleCase } from '../../common/StringFormatter';

import './AddOrderItem.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState,
    orderItemsState: {} as OrderItemsStore.OrderItemsState,
    userState: {} as UserStore.UserState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrdersStore.actionCreators, OrderItemsStore.actionCreators)
};

interface AddOrderItemAsyncActions {
    asyncActions: {
        addOrderItemAsync: (client: Client, newOrderItem: NewOrderItem) => Promise<OrdersStore.OrderDetail | null | undefined>;
    }
}

interface AddOrderItemOwnProps {
    isOpen: boolean;
    client: Client;
    customer: CustomerAccount | null | undefined;
    newOrderItem: NewOrderItem;
    productDetail: ProductDetail;
    onDismiss: (id: number | null | undefined) => void;
    onGoBack: () => void;
    onReviewOrder: (id: number) => void;
}

type AddOrderItemProps =
    RouteComponentProps
    & AddOrderItemOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & AddOrderItemAsyncActions;

// ----------------
// LOCAL STATE

interface AddOrderItemState {
    submitting: boolean;
    retrieving: boolean;
    retrievingOrder: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    submitExistingOrderRequested: boolean;
    submitNewOrderRequested: boolean;
    formResult: string | null;
    actions: FieldWrapper.OptionValue[];
    selectedAction: number;
    selectedOrder: OrdersStore.Order | null | undefined;
    newOrder: OrdersStore.BaseOrder | null | undefined;
}

// ----------------
// FORM VALIDATOR

const validateAddOrderItemForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (formValues.action === 0) {
        errors.action = 'Select action';
    }
    return errors;
}

class AddOrderItem extends React.PureComponent<AddOrderItemProps, AddOrderItemState> {

    // ----------------
    // VARIABLES

    public collapsibleActionBarVariants = {
        hidden: { height: 0, overflow: 'hidden', paddingBottom: '0', paddingTop: '0' },
        visible: { height: 'auto', overflow: 'visible', paddingBottom: '10px', paddingTop: '20px' }
    }

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', overflow: 'visible' }
    }

    public collapsibleFooterVariants = {
        hidden: { height: 0, overflow: 'hidden', width: '100%' },
        visible: { height: 'auto', overflow: 'visible', width: '100%' }
    }

    public confirmationMessageVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: AddOrderItemProps, state: AddOrderItemState) {
        super(props);
        this.state = {
            submitting: false,
            retrieving: false,
            retrievingOrder: false,
            changesAccepted: false,
            changesRejected: false,
            submitExistingOrderRequested: false,
            submitNewOrderRequested: false,
            formResult: null,
            actions: this.getActions(),
            selectedAction: this.isOpenOrder() ? 3 : 0, 
            selectedOrder: undefined,
            newOrder: undefined
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {        
    }

    public componentDidUpdate = (prevProps: AddOrderItemProps) => {
        if (this.receivedErrorMessage() || this.receivedWarningMessage()) {
            this.props.onDismiss(null);
        }
        else if (this.state.retrievingOrder) {
            if (this.props.ordersState.orderDetail && !prevProps.ordersState.orderDetail) {
                this.setState({
                    retrievingOrder: false,
                    submitting: true,
                    selectedAction: this.isOpenOrder() ? 3 : 0
                });
                setTimeout(() => {
                    this.addOrderItem();
                }, 1000);
            }
        }
        else if (this.props.ordersState.orderDetail && !prevProps.ordersState.orderDetail) {
        }
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        let newOrderItem: NewOrderItem | null | undefined = undefined;
        switch (this.state.selectedAction) {
            case 1:
                if (this.state.selectedOrder && this.state.selectedOrder.id) {
                    this.retrieveOrder(this.state.selectedOrder.id);
                }
                break;
            case 2:
                if (this.state.newOrder && this.state.newOrder.id) {
                    this.retrieveOrder(this.state.newOrder.id);
                }
                else {
                    this.setState({
                        submitNewOrderRequested: true
                    });
                }
                break;
            case 0:
            default:
                this.setState({
                    changesRejected: true,
                    formResult: 'Please select an action'
                });
                break;
        }
    }

    public render = () => {
        return (
            <Modal id="add-order-item" isOpen={this.props.isOpen}
                onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(null)} className={(this.state.retrieving || this.state.retrievingOrder || this.state.submitting) ? "disabled" : ""}>
                    Add to Order
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className={"collapsible-row pl-3 action-bar" + (this.props.ordersState.orderDetail ? " pr-3 text-left" : " pr-4 text-center")}>
                                <motion.div animate={this.state.selectedAction === 3 ? "hidden" : "visible"} initial={this.state.selectedAction === 3 ? "hidden" : "visible"}
                                    variants={this.collapsibleActionBarVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>
                                    <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                        <Field name="action" type="text" label=""
                                         component={FieldWrapper.renderRadioButtonField}
                                            options={this.state.actions} onChange={this.onSelectAction}
                                            disabled={this.state.retrieving || this.state.retrievingOrder || this.state.submitting || this.state.changesAccepted} />
                                        <button type="submit" ref={this.submitButton}>Submit</button>
                                    </form>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0">
                                <motion.div animate={this.state.selectedAction === 1 ? "visible" : "hidden"} initial={this.state.selectedAction === 1 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>
                                    <Container className="component-wrapper flush-bottom">
                                        <OrderPicker activated={this.state.selectedAction === 1}
                                            customerAccount={this.props.customer} disabled={this.state.retrievingOrder || this.state.submitting || this.state.changesAccepted}
                                            submitRequested={this.state.submitExistingOrderRequested} onSubmit={this.onSelectOrderFormSubmit}
                                            onSubmitFail={this.onSelectOrderFormSubmitFail} onRetrievingOrder={this.onRetrievingOrder}
                                            onSelectOrder={this.onSelectOrder} />
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0">
                                <motion.div animate={this.state.selectedAction === 2 ? "visible" : "hidden"} initial={this.state.selectedAction === 2 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>
                                    <Container className="component-wrapper">
                                        <AddOrderForm
                                            customerAccount={this.props.customer} disabled={this.state.retrievingOrder || this.state.submitting || this.state.changesAccepted}
                                            submitRequested={this.state.submitNewOrderRequested} onSubmit={this.onAddOrderFormSubmit}
                                            onSubmitFail={this.onAddOrderFormSubmitFail} onSubmitSucceed={this.onAddOrderFormSubmitSucceed}
                                            onRetrievingAccount={this.onRetrievingAccount} onChange={this.onAddOrderFormChange}
                                            customerNumber={this.getCustomerNumber()} resetForm={this.state.selectedAction === 2} />
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="collapsible-row pl-0 pr-0">
                                <motion.div animate={this.state.selectedAction === 3 ? "visible" : "hidden"} initial={this.state.selectedAction === 3 ? "visible" : "hidden"}
                                    variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>
                                    <Container className="component-wrapper borderless">
                                        <Row>
                                            <Col className="order-item-description">
                                                <div className="image">
                                                    <div className="order-item-image">
                                                        <div className="image-stretcher">
                                                            <div className="image-canvas">
                                                                <ImageLoader url={this.props.productDetail.imageUrl} isLoading={false} noFadeIn={false} thumbnail={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text">
                                                    <label className="style-code">
                                                        {this.props.productDetail.style.sku ? "" : "Style "}
                                                        {this.props.productDetail.style.sku ? this.props.productDetail.style.sku : this.props.productDetail.style.code}
                                                    </label>
                                                    <label className="category">{this.props.productDetail.shortDescription}</label>
                                                    <h4 className="style-name">{this.props.productDetail.style.name}</h4>
                                                    <div className="two-column">
                                                        <label className="price">${this.props.productDetail.wholesale.toFixed(2)}</label>
                                                        <label className="color">{this.getColorDescription()}</label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="order-item-confirmation">
                                                <Loader isLoading={this.state.submitting} isChild={true} />
                                                <motion.div animate={this.state.changesAccepted ? "visible" : "hidden"} initial={"hidden"}
                                                    variants={this.confirmationMessageVariants} transition={{ duration: 0.25 }}>
                                                    <React.Fragment>
                                                        <div className="confirmation-message">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                <polyline points="20 6 9 17 4 12"></polyline>
                                                            </svg>
                                                            <label>Added to order {this.props.ordersState.orderDetail ? this.props.ordersState.orderDetail.orderNumber : ""}</label>
                                                        </div>
                                                        <div className="button-bar">
                                                            <Button color="primary" onClick={this.goBack}>
                                                                Continue
                                                            </Button>
                                                            <Button color="link" onClick={this.goToOrder}>
                                                                Go to Cart
                                                            </Button>
                                                        </div>
                                                    </React.Fragment>
                                                </motion.div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </motion.div>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter className={this.state.selectedAction === 3 ? "collapsed" : ""}>
                    <motion.div animate={this.state.selectedAction === 3 ? "hidden" : "visible"} initial={this.state.selectedAction === 3 ? "hidden" : "visible"}
                        variants={this.collapsibleFooterVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0.25) }}>
                        <Container>
                            <Row>
                                <Col className="button-bar pl-0 pr-0">
                                    <Button type="button" color="primary" onClick={this.submitAddOrderItemForm} disabled={this.state.retrieving || this.state.retrievingOrder || this.state.submitting || this.state.changesAccepted}>
                                        {this.state.retrievingOrder || this.state.submitting ? "Working ..." : "Continue"}
                                    </Button>
                                    <Button color="link" onClick={() => this.props.onDismiss(null)} disabled={this.state.retrieving || this.state.retrievingOrder || this.state.submitting || this.state.changesAccepted}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pt-4 pr-0">
                                    <FormResult
                                        failureResult={this.state.changesRejected}
                                        successResult={this.state.changesAccepted}
                                        description={this.state.formResult} />
                                </Col>
                            </Row>
                        </Container>
                    </motion.div>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private addOrderItem = (): void => {
        let orderItem: NewOrderItem = this.getOrderItem();
        this.props.asyncActions.addOrderItemAsync(this.props.client, orderItem)
            .then(result => {
                this.setState({                    
                    submitting: false,
                    changesAccepted: true
                });
            },
            err => {
                this.props.onDismiss(null);
            }
        )
    }

    private getActions = (): FieldWrapper.OptionValue[] => {
        let actions: FieldWrapper.OptionValue[] = [];
        actions.push({ label: 'Select Existing Order', value: 1 });
        actions.push({ label: 'Start New Order', value: 2 });        
        return actions;
    }

    private getColorDescription = (): string => {
        let color: string | null | undefined = this.props.productDetail.color.name;
        if (color) {
            if (color != 'PATTERNED') {
                color = toTitleCase(color);
            }
            else {
                color = "";
            }
        }
        else {
            color = this.props.productDetail.color.code;
        }
        return color || "";
    }

    private getCustomerNumber = (): string | null => {
        let customerNumber: string | null = null;
        if (this.props.userState.user && this.props.userState.user.role === Role.Buyer) {
            customerNumber = this.props.userState.user.customerNumber;
        }
        return customerNumber;
    }

    private getOrderItem = (): NewOrderItem => {
        let orderItem: NewOrderItem = cloneDeep(this.props.newOrderItem);
        if (this.props.ordersState.orderDetail) {
            orderItem.customerNumber = this.props.ordersState.orderDetail.shipToCustomer.number;
            orderItem.orderId = this.props.ordersState.orderDetail.id as number;
        }
        return orderItem;
    }

    private goBack = (): void => {
        this.props.onGoBack();
    }

    private goToOrder = (): void => {        
        this.props.onDismiss(this.props.ordersState.orderDetail ? this.props.ordersState.orderDetail.id : null);
    }

    private initializeForm = (): void => {
        let selectedAction: number = this.isOpenOrder() ? 3 : 0;
        this.setState({            
            actions: this.getActions(),
            selectedAction: selectedAction,
            changesAccepted: false,
            submitting: false
        });
        this.props.change("action", selectedAction);

        // if order is specified, add the item 
        // do it here since it's called only once
        if (this.isOpenOrder()) {
            if (!(this.state.submitting || this.state.changesAccepted)) {
                this.setState({
                    submitting: true,
                    selectedAction: 3
                });
                this.addOrderItem();                
            }
        }
    }

    private isOpenOrder = (): boolean => {
        return (this.props.ordersState.orderDetail && this.props.ordersState.orderDetail.orderStatus === 'Open') ? true : false;
    }

    private onAddOrderFormChange = (): void => {
        this.setState({
            formResult: null
        });
    }

    private onAddOrderFormSubmit = (submitting: boolean): void => {
        this.setState({
            formResult: null,
            submitNewOrderRequested: false,
            submitting: submitting
        });
    }

    private onAddOrderFormSubmitFail = (): void => {
        this.setState({
            formResult: null,
            submitNewOrderRequested: false
        })
    }

    private onAddOrderFormSubmitSucceed = (newOrder: OrdersStore.BaseOrder): void => {
        this.setState({
            newOrder: newOrder
        });
        setTimeout(() => {
            if (this.submitButton.current) {
                this.submitButton.current.click();
            }
        });
    }

    private onRetrievingAccount = (retrieving: boolean): void => {
        this.setState({
            retrieving: retrieving
        });
    }

    private onRetrievingOrder = (retrieving: boolean): void => {
        this.setState({
            retrieving: false,
            submitting: false
        });
    }

    private onSelectAction = (event: React.ChangeEvent): void => {
        let selectedValue = event.target.getAttribute("value");
        if (selectedValue && parseInt(selectedValue) != this.state.selectedAction) {            
            this.setState({
                selectedAction: parseInt(selectedValue)
            });
        }
    }

    private onSelectOrder = (order: OrdersStore.Order | null | undefined): void => {
        this.setState({
            selectedOrder: order
        });
    }

    private onSelectOrderFormSubmit = (submitting: boolean): void => {
        this.setState({
            formResult: null,
            submitExistingOrderRequested: false,
            submitting: submitting
        });
    }

    private onSelectOrderFormSubmitFail = (): void => {
        this.setState({
            formResult: null,
            submitExistingOrderRequested: false
        });
    }

    private receivedErrorMessage = (): boolean => {
        let isError: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) {
                isError = true;
            }
        }
        return isError;
    }

    private receivedWarningMessage = (): boolean => {
        let isWarning: boolean = false;
        if (this.props.messageState && this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.WARNING) {
                isWarning = true;
            }
        }
        return isWarning;
    }

    private resetForm = (): void => {
        this.setState({
            changesAccepted: false,
            changesRejected: false,
            submitting: false,
            submitExistingOrderRequested: false,
            submitNewOrderRequested: false,
            formResult: null,
            selectedAction: 0,
        });
        this.props.reset();
    }

    private retrieveOrder = (orderId: number): void => {
        this.setState({
            retrievingOrder: true
        });
        setTimeout(() => {
            this.props.actions.requestOrderDetail(this.props.client, orderId);
        })
    }

    private reviewOrder = (): void => {
        if (this.props.ordersState.orderDetail) {
            this.props.onReviewOrder(this.props.ordersState.orderDetail.id as number);
        }
    }

    private submitAddOrderItemForm = (): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        ordersState: state.orders,
        orderItemsState: state.orderItems,
        userState: state.user
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrdersStore.actionCreators,
            OrderItemsStore.actionCreators), dispatch),
        asyncActions: {
            addOrderItemAsync: (client: Client, newOrderItem: NewOrderItem) => dispatch(OrderItemsStore.actionCreators.addOrderItem(client, newOrderItem))
        }
    };
}

export default connect<{}, {}, AddOrderItemOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'addOrderItemForm',
    validate: validateAddOrderItemForm,
    enableReinitialize: true
})(AddOrderItem as any));
