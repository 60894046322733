﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as MessageStore from './Message';
import * as ErrorMessage from '../common/ErrorMessage';
import { ClientCode } from '../enums/ClientCode';
import { ColorDirection } from '../common/EmbroideryTypes';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ColorDirectionsState {
    isLoading: boolean;
    clientCode: ClientCode;
    colorDirections: ColorDirection[] | null | undefined;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestColorDirectionsAction {
    type: 'REQUEST_COLOR_DIRECTIONS';
    clientCode: ClientCode;
}

interface ReceiveColorDirectionsAction {
    type: 'RECEIVE_COLOR_DIRECTIONS';
    clientCode: ClientCode;
    colorDirections: ColorDirection[] | null | undefined;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = RequestColorDirectionsAction | ReceiveColorDirectionsAction | MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const isInStore = (state: ColorDirectionsState, clientCode: ClientCode): boolean => {
    return (state && state.colorDirections && state.clientCode === clientCode) ? true : false;
}

export const actionCreators = {
    requestColorDirections: (clientCode: ClientCode): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.colorDirections && !isInStore(appState.colorDirections, clientCode) && !appState.colorDirections.isLoading) {

            let fetchError: boolean = false;
            let action: string = 'colordirections/get'
            let url: string = `${action}/${ClientCode[clientCode]}`;

            fetch(url,
                {
                    method: 'GET',
                    headers: { 'Accept': 'application/json' }
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'RECEIVE_COLOR_DIRECTIONS', clientCode: clientCode, colorDirections: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error;
                    }
                    return response.json();
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_COLOR_DIRECTIONS', clientCode: clientCode, colorDirections: data });
                },
                    err => {
                        if (!fetchError) {
                            dispatch({ type: 'RECEIVE_COLOR_DIRECTIONS', clientCode: clientCode, colorDirections: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

            dispatch({ type: 'REQUEST_COLOR_DIRECTIONS', clientCode: clientCode });
        }
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: ColorDirectionsState = { isLoading: false, clientCode: ClientCode.Undefined, colorDirections: null };

export const reducer: Reducer<ColorDirectionsState> = (state: ColorDirectionsState | undefined, incomingAction: Action): ColorDirectionsState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_COLOR_DIRECTIONS':
            return {
                clientCode: state.clientCode,
                colorDirections: state.colorDirections,
                isLoading: true
            };
        case 'RECEIVE_COLOR_DIRECTIONS':
            return {
                clientCode: action.clientCode,
                colorDirections: action.colorDirections,
                isLoading: false
            };
        default:
            return state;
    }
}