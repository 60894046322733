import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as ClientStore from '../../store/Client';
import * as ColorDirectionsStore from '../../store/ColorDirections';
import * as CustomersStore from '../../store/Customers';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import * as OrderItemsStore from '../../store/OrderItems';
import { ClientCode } from '../../enums/ClientCode';
import { EmbroideryTapeType } from '../../enums/EmbroideryTapeType';
import { ColorDirection, EmbroiderySpecification, Logo, NewLogo, PlacementOption } from '../../common/EmbroideryTypes';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import ImageLoader from '../image-loader/ImageLoader';
import TapeImporter from '../tape-importer/TapeImporter';
import TapeSelector from '../tape-selector/TapeSelector';
import ConfirmEmbroidery from '../confirm-embroidery/ConfirmEmbroidery';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash.debounce';

import './AddEmbroidery.scss';

// ----------------
// PROPS

const applicationState = {
    colorDirectionsState: {} as ColorDirectionsStore.ColorDirectionsState,
    customerState: {} as CustomersStore.CustomersState,
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState
};

const actionCreators = {
    actions: Object.assign({}, ColorDirectionsStore.actionCreators, MessageStore.actionCreators)
};

interface AddEmbroideryAsyncActions {
    asyncActions: {
        updateOrderItemEmbroideriesAsync: (clientCode: ClientCode, orderId: number, orderItem: OrderItemsStore.OrderItem, broadcastError: boolean) =>
            Promise<OrderItemsStore.OrderItem | null | undefined>;
    }
}

interface AddEmbroideryOwnProps {
    isOpen: boolean;
    client: ClientStore.Client;
    customerAccount: CustomersStore.CustomerAccount;
    embroideryIndex: number;
    onChangeEmbroideryIndex: (index: number) => void;
    onDismiss: (orderItem: OrderItemsStore.OrderItem | null | undefined) => void;
    orderItem: OrderItemsStore.OrderItem;
}

type AddEmbroideryProps =
    AddEmbroideryOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & AddEmbroideryAsyncActions;

// ----------------
// LOCAL STATE

interface AddEmbroideryState {
    isInitialized: boolean;
    selectingTape: boolean;
    importingTape: boolean;
    confirming: boolean;
    confirmed: boolean;
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
    selectedIndex: number;
    embroiderySpecifications: EmbroiderySpecification[];
    maxEmbroideries: number;
    tapeTypes: FieldWrapper.OptionValue[];
    placementOptions: FieldWrapper.OptionValue[];
    placementDropdownFlags: boolean[];
    colorDirectionOptions: FieldWrapper.OptionValue[];
    colorDirectionDropdownFlags: boolean[];
    importSubmitRequested: boolean;
    importSubmitting: boolean;
    importAccepted: boolean;
    importRejected: boolean;
    importFormResult: string | null;
}

// ----------------
// FORM VALIDATOR

const validateAddEmbroideryForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    let embroideryCount: number = parseInt(formValues["embroideryCount"]);
    for (let n: number = 0; n < embroideryCount; n++) {
        let tapeTypeField: string = 'tapeType' + n;
        if (!formValues[tapeTypeField]) {
            errors[tapeTypeField] = 'Required';
        }
        let placementField: string = 'placement' + n;
        if (!formValues[placementField]) {
            errors[placementField] = 'Required';
        }
        let colorDirectionField: string = 'colorDirection' + n;
        if (!formValues[colorDirectionField]) {
            errors[colorDirectionField] = 'Required';
        }
        let notesField: string = 'notes' + n;
        if (!formValues[notesField]) {
            if (formValues[placementField] && formValues[placementField].toString().startsWith('Custom ')) {
                errors[notesField] = 'Required';
            }
            else if (formValues[colorDirectionField] && formValues[colorDirectionField].toString().indexOf(' w/ Instructions') >= 0) {
                errors[notesField] = 'Required';
            }
        }
    }
    return errors;
}

const onFormValidationFailed = (errors: any, dispatch: any, submitError: Error, props: AddEmbroideryOwnProps) => {
    let firstErrorKey: string = Object.keys(errors)[0];
    let firstErrorIndex: number = parseInt(firstErrorKey.substring(firstErrorKey.length - 1));
    props.onChangeEmbroideryIndex(firstErrorIndex);
}

class AddEmbroidery extends React.PureComponent<AddEmbroideryProps, AddEmbroideryState> {

    // ----------------
    // VARIABLES

    public embroideryVariants = {
        current: { left: '-1px' },
        next: { left: 'calc(100% + 20px)' },
        prev: { left: 'calc(-100% - 20px)' }
    }

    public popupVariants = {
        hidden: { left: '50%', top: '50%', height: 0, width: 0, zIndex: -1 },
        visible: { left: '10px', top: '10px', height: 'calc(100% - 20px)', width: 'calc(100% - 20px)', zIndex: 4 }
    }

    public popupButtonBarVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: AddEmbroideryProps, state: AddEmbroideryState) {
        super(props);
        this.state = {
            isInitialized: false,
            selectingTape: false,
            importingTape: false,
            confirming: false,
            confirmed: false,
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            selectedIndex: this.props.embroideryIndex,
            embroiderySpecifications: this.getEmbroiderySpecifications(),
            maxEmbroideries: this.props.client.rules.maxLogosPerItem,
            tapeTypes: [
                { label: "Existing", value: EmbroideryTapeType.Existing },
                { label: "New", value: EmbroideryTapeType.New }
            ],
            placementOptions: this.getPlacementOptions(),
            placementDropdownFlags: [false, false, false],
            colorDirectionOptions: this.getColorDirectionOptions(),
            colorDirectionDropdownFlags: [false, false, false],
            importSubmitRequested: false,
            importSubmitting: false,
            importAccepted: false,
            importRejected: false,
            importFormResult: null
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
        this.ensureDataFetched();
    }

    public componentDidUpdate = (prevProps: AddEmbroideryProps) => {
        if (this.props.colorDirectionsState.colorDirections && !this.state.isInitialized) {
            this.setState({                
                isInitialized: true,               
                colorDirectionOptions: this.getColorDirectionOptions(),
                selectedIndex: this.props.embroideryIndex
            });
        }
        else if (this.props.embroideryIndex != this.state.selectedIndex) {
            this.setState({
                selectedIndex: this.props.embroideryIndex
            });
        }
    }

    public componentWillUnmount = () => {
    }

    public handleFormSubmit = (values: any): void => {
        let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        for (let n: number = 0; n < specifications.length; n++) {
            specifications[n].notes = values["notes" + n];
        }
        if (!this.state.confirmed && this.requiresConfirmation(specifications)) {
            this.setState({
                confirming: true,
                embroiderySpecifications: specifications
            });
        }
        else {
            let orderItem: OrderItemsStore.OrderItem = cloneDeep(this.props.orderItem);
            orderItem.embroiderySpecifications = specifications;
            this.saveEmbroidery(orderItem);
        }
    }

    public render = () => {
        return (
            <Modal id="add-embroidery" isOpen={this.props.isOpen} className={(this.state.selectingTape || this.state.importingTape) ? "shaded" : ""}
                onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(null)} className={(this.state.selectingTape || this.state.importingTape || this.state.submitting) ? "disabled" : ""}>
                    Add Embroidery
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                        <div className="layout">
                            <div className="add-embroidery-heading">
                                <h3 className="style-name">{this.props.orderItem.style.name}</h3>
                                <div className="action-bar">
                                    <div className="counter">
                                        <label>Embroidery
                                            {this.state.maxEmbroideries > 1 && (
                                                <span> {this.state.selectedIndex + 1}</span>
                                            )}
                                        </label>
                                    </div>
                                    <div className="actions">
                                        {this.props.orderItem.embroiderySpecifications.length > 0 && (
                                            <Button type="button" color="link" onClick={this.remove} disabled={this.state.submitting || this.state.changesAccepted}>
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-trash">
                                                        <polyline points="3 6 5 6 21 6"></polyline>
                                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                    </svg>
                                                </span>
                                                <label>Remove</label>
                                            </Button>
                                        )}
                                        {this.state.maxEmbroideries > 1 && (
                                            <React.Fragment>
                                                <span className="divider">|</span>
                                                <Button type="button" color="link" onClick={this.addLogo} disabled={this.state.embroiderySpecifications.length >= 3 || this.state.submitting || this.state.changesAccepted}>
                                                    <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-plus">
                                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                                        </svg>
                                                    </span>
                                                    <label>Add</label>
                                                </Button>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="embroideries">
                                {[...Array(this.state.maxEmbroideries)].map((arg: any, index: number) => (
                                    <motion.div key={"embroidery-" + index} className="embroidery"
                                        animate={this.isCurrent(index) ? "current" : this.isNext(index) ? "next" : "prev"}
                                        initial={this.isCurrent(index) ? "current" : this.isNext(index) ? "next" : "prev"}
                                        variants={this.embroideryVariants} transition={{ duration: 0.5 }}>
                                        <Container>
                                            <Row>
                                                <Col className="pb-0 pt-3 pl-3 pr-3 logo">                                    
                                                    <div className="tape">
                                                        <div className="step">
                                                            <div className="step-number">1</div>
                                                            <div className="step-label">Select Tape</div>
                                                        </div>
                                                        <div className="field-container">
                                                            <Field name={"tapeType" + index} type="text" label="" component={FieldWrapper.renderRadioButtonField}
                                                                options={this.state.tapeTypes} onChange={(e: React.ChangeEvent) => this.onSelectTapeType(index, e)}
                                                                onFocus={this.onFocusPlacement} tabIndex={index === this.state.selectedIndex ? 0 : -1}
                                                                disabled={this.state.submitting || this.state.changesAccepted} />
                                                        </div>
                                                        {this.hasSelectedTape(index) && (
                                                            <div className="tape-number">
                                                                <div className="name">Tape #</div>
                                                                <div className="value">{this.getSelectedTape(index).tapeNumber}</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="image">
                                                        <div className="logo-image">
                                                            <div className={"image-stretcher" + (this.hasSelectedTape(index) && this.getSelectedTape(index).tapeType === EmbroideryTapeType.New ? " new" : "") }>
                                                                <div className={"image-canvas" + (this.hasSelectedTape(index) ? "" : " blank")}>
                                                                    {this.hasSelectedTape(index) && (
                                                                        <ImageLoader url={this.getSelectedTape(index).logoUrl} isLoading={false} autoSize={true} noFadeIn={false} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tape-clear">
                                                        {this.hasSelectedTape(index) && (
                                                            <Button color="link" onClick={this.clearLogo} tabIndex={-1} disabled={this.state.submitting || this.state.changesAccepted}>
                                                                Clear
                                                            </Button>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pb-2 pt-0 pl-3 pr-3 instructions">
                                                    <div className="step">
                                                        <div className="step-number">2</div>
                                                        <div className="step-label">Instructions</div>
                                                    </div>                        
                                                    <div className="field-container first">
                                                        <Field name={"placement" + index} label="" component={FieldWrapper.renderDropdownField} defaultValue={this.getDefaultPlacement(index)}
                                                            open={this.state.placementDropdownFlags[index]} disabled={this.state.submitting || this.state.changesAccepted} required={true}
                                                            options={this.state.placementOptions} onSelectOption={this.onSelectPlacement}
                                                            onToggle={(e: React.KeyboardEvent | React.MouseEvent) => this.onTogglePlacement(e, index)}
                                                            tabIndex={index === this.state.selectedIndex ? 0 : -1} />
                                                    </div>
                                                    <div className="field-container">
                                                        <Field name={"colorDirection" + index} label="" component={FieldWrapper.renderDropdownField} defaultValue={this.getDefaultColorDirection(index)}
                                                            open={this.state.colorDirectionDropdownFlags[index]} disabled={this.state.submitting || this.state.changesAccepted} required={true}
                                                            options={this.state.colorDirectionOptions} onSelectOption={this.onSelectColorDirection}
                                                            onToggle={(e: React.KeyboardEvent | React.MouseEvent) => this.onToggleColorDirection(e, index)}
                                                            tabIndex={index === this.state.selectedIndex ? 0 : -1} />
                                                    </div>

                                                    <div className="field-container">
                                                        <Field name={"notes" + index} label="" component={FieldWrapper.renderTextareaField} placeholder={"Notes..."}
                                                            disabled={this.state.submitting} required={false} rows={this.state.maxEmbroideries > 1 ? 2 : 4} maxLength={254}
                                                            onChange={this.onAddNotes} tabIndex={index === this.state.selectedIndex ? 0 : -1}  />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </motion.div>
                                ))}
                            </div>
                            {this.state.maxEmbroideries > 1 && (
                                <div className="nav-bar">
                                    <div className="pager">
                                        <Button color="link" disabled={this.state.selectedIndex === 0} onClick={this.showFirst}>
                                            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                            </svg>
                                        </Button>
                                        <Button color="link" disabled={this.state.selectedIndex === 0} onClick={this.showPrev}>
                                            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                            </svg>
                                        </Button>
                                        <label className="page-number">{this.state.selectedIndex + 1} of {this.state.embroiderySpecifications.length}</label>
                                        <Button color="link" disabled={this.isLast() ? true : false} onClick={this.showNext}>
                                            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                            </svg>
                                        </Button>
                                        <Button color="link" disabled={this.isLast() ? true : false} onClick={this.showLast}>
                                            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                            </svg>
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <Field name="embroideryCount" component="input" type="hidden" />
                        <button type="submit" ref={this.submitButton}>SUBMIT</button>
                    </form>
                    <motion.div className="tape-selector" animate={this.state.selectingTape ? "visible" : "hidden"} initial={"hidden"}
                        variants={this.popupVariants} transition={{ duration: 0.5 }}>
                        <div className="popup">
                            <div className="popup-content">
                                <TapeSelector isOpen={this.state.selectingTape} client={this.props.client} customerAccount={this.props.customerAccount}
                                    onSelect={this.hideTapeSelector} />
                            </div>
                            <motion.div className="popup-button-bar" animate={this.state.selectingTape ? "visible" : "hidden"} initial={"hidden"}
                                variants={this.popupButtonBarVariants} transition={{ duration: 0.5, delay: 0.8 }}>
                                <Button color="secondary" onClick={() => this.hideTapeSelector(undefined)} disabled={!this.state.selectingTape}>Close</Button>
                            </motion.div>
                        </div>
                    </motion.div>
                    <motion.div className="tape-importer" animate={this.state.importingTape ? "visible" : "hidden"} initial={"hidden"}
                        variants={this.popupVariants} transition={{ duration: 0.5 }}>
                        <div className="popup">
                            <div className="popup-content logo-importer">
                                <TapeImporter isOpen={this.state.importingTape} clientCode={this.props.client.code} customer={this.props.customerAccount}
                                    submitRequested={this.state.importSubmitRequested} onSubmit={this.onImportFormSubmit}
                                    onSubmitFail={this.onImportFormSubmitFail} onSubmitSucceed={this.onImportFormSubmitSucceed} />
                            </div>
                            <div className="popup-button-bar logo-importer">
                                <Container>
                                    <Row>
                                        <Col className="button-bar pl-0 pr-0">
                                            <Button type="button" color="primary" disabled={this.state.importSubmitting || this.state.importAccepted}
                                                onClick={this.requestImportSubmit}>
                                                {this.state.importSubmitting ? "Working" : "Save"}
                                            </Button>
                                            <Button color="link" onClick={() => this.hideTapeImporter(null)} disabled={this.state.importSubmitting || this.state.importAccepted}>
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pl-0 pr-0 pt-3">
                                            <FormResult
                                                failureResult={this.state.importRejected}
                                                successResult={this.state.importAccepted}
                                                description={this.state.importFormResult} />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div className="confirmation" animate={this.state.confirming ? "visible" : "hidden"} initial={"hidden"}
                        variants={this.popupVariants} transition={{ duration: 0.5 }}>
                        <div className="popup">
                            <div className="popup-content">
                                <ConfirmEmbroidery embroiderySpecifications={this.state.embroiderySpecifications} onConfirm={this.onConfirmationComplete} />
                            </div>
                        </div>                        
                    </motion.div>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={this.submitForm}
                                    disabled={this.state.selectingTape || this.state.importingTape || this.state.confirming || this.state.submitting || this.state.changesAccepted || !this.state.isInitialized}>
                                    {this.state.submitting ? "Working ..." : "Save"}
                                </Button>
                                <Button color="link" onClick={() => this.props.onDismiss(null)} disabled={this.state.selectingTape || this.state.importingTape || this.state.confirming || this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-3 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private addLogo = (event: React.MouseEvent): void => {
        event.stopPropagation();
        let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        if (specifications.length < 3) {
            specifications.push(this.createNewSpecification());
            let selectedIndex: number = specifications.length - 1;
            this.setState({
                embroiderySpecifications: specifications
            });
            this.props.change('embroideryCount', specifications.length);
            this.props.onChangeEmbroideryIndex(selectedIndex);
        }
    }

    private clearLogo = (): void => {
        let specifications: EmbroiderySpecification[] =  cloneDeep(this.state.embroiderySpecifications);
        specifications[this.state.selectedIndex].logo = null;
        setTimeout(() => {
            this.setState({
                embroiderySpecifications: specifications
            });
        });
        this.props.change("tapeType" + this.state.selectedIndex, null);
    }

    private colorDirectionsInStore = (clientCode: ClientCode): boolean => {
        let inStore: boolean = false;
        if (this.props.colorDirectionsState.colorDirections) {
            inStore = (this.props.colorDirectionsState.clientCode === clientCode);
        }
        return inStore;
    }

    private createNewSpecification = (): EmbroiderySpecification => {
        return {
            logo: null,
            colorDirection: null,
            placement: null,
            notes: null
        };
    }

    private ensureDataFetched = (): void => {
        if (this.colorDirectionsInStore(this.props.client.code)) {
            this.setState({
                isInitialized: true
            });
        }
        else {
            this.props.actions.requestColorDirections(this.props.client.code);
        }
    }

    private getColorDirectionOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        if (this.props.colorDirectionsState.colorDirections) {
            for (let n: number = 0; n < this.props.colorDirectionsState.colorDirections.length; n++) {
                let colorDirection: ColorDirection = this.props.colorDirectionsState.colorDirections[n];
                options.push({ label: colorDirection.titleCaseDescription, value: colorDirection.code });
            }
        }
        return options;
    }

    private getDefaultColorDirection = (index: number): string => {
        let defaultValue = "Select Color Direction...";
        if (this.state.embroiderySpecifications.length > index) {
            let embroiderySpecification: EmbroiderySpecification = this.state.embroiderySpecifications[index];
            if (embroiderySpecification.colorDirection) {
                defaultValue = embroiderySpecification.colorDirection.titleCaseDescription;
            }
        }
        return defaultValue;
    }

    private getDefaultPlacement = (index: number): string => {
        let defaultValue = "Select Placement...";
        if (this.state.embroiderySpecifications.length > index) {
            let embroiderySpecification: EmbroiderySpecification = this.state.embroiderySpecifications[index];
            if (embroiderySpecification.placement) {
                defaultValue = embroiderySpecification.placement.description;
            }
        }
        return defaultValue;
    }

    private getEmbroiderySpecifications = (): EmbroiderySpecification[] => {
        let specifications: EmbroiderySpecification[] = [];
        for (let n: number = 0; n < this.props.orderItem.embroiderySpecifications.length; n++) {
            specifications.push(cloneDeep(this.props.orderItem.embroiderySpecifications[n]));
        };
        if (specifications.length === 0 || this.props.embroideryIndex > 0) {
            specifications.push(this.createNewSpecification());
        }
        return specifications;
    }

    private getPlacementOptions = (): FieldWrapper.OptionValue[] => {
        let options: FieldWrapper.OptionValue[] = [];
        for (let n: number = 0; n < this.props.orderItem.placementOptions.length; n++) {
            let placementOption: PlacementOption = this.props.orderItem.placementOptions[n];
            options.push({ label: placementOption.description, value: placementOption.code });
        }
        return options;
    }

    private getSelectedTape = (index: number): Logo => {
        return this.state.embroiderySpecifications[index].logo as Logo;
    }

    private hasSelectedTape = (index: number): boolean => {
        let hasTape: boolean = this.state.embroiderySpecifications.length > index ? true : false;
        if (hasTape) {
            hasTape = this.state.embroiderySpecifications[index].logo ? true : false;
        }
        return hasTape;
    }

    private hideTapeImporter = (logo: Logo | null | undefined): void => {
        let specifications: EmbroiderySpecification[] = this.state.embroiderySpecifications;
        if (logo) {
            specifications[this.state.selectedIndex].logo = logo;
        }
        else if (specifications[this.state.selectedIndex].logo) {
            let currentLogo: Logo = specifications[this.state.selectedIndex].logo as Logo;
            this.props.change("tapeType" + this.state.selectedIndex, currentLogo.tapeType);
        }
        else {
            this.props.change("tapeType" + this.state.selectedIndex, null);
        }
        this.setState({
            embroiderySpecifications: specifications,
            importingTape: false
        })
    };    

    private hideTapeSelector = (logo: Logo | undefined): void => {
        let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        if (logo) {
            specifications[this.state.selectedIndex].logo = logo;
            this.props.change("tapeNumber" + this.state.selectedIndex, logo.tapeNumber);
        }
        else if (specifications[this.state.selectedIndex].logo) {
            let currentLogo: Logo = specifications[this.state.selectedIndex].logo as Logo;
            this.props.change("tapeType" + this.state.selectedIndex, currentLogo.tapeType);
        }
        else {
            this.props.change("tapeType" + this.state.selectedIndex, null);            
        }
        this.setState({
            embroiderySpecifications: specifications,
            selectingTape: false
        });
    }

    private initializeForm = (): void => {
        let specifications: EmbroiderySpecification[] = this.getEmbroiderySpecifications();
        this.setState({
            submitting: false,
            confirming: false,
            selectingTape: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            selectedIndex: 0,
            embroiderySpecifications: specifications
        });        
        for (let n: number = 0; n < 3; n++) {
            let specification: EmbroiderySpecification | null | undefined = (n < specifications.length) ? specifications[n].logo ? specifications[n] : null : undefined;
            this.updateFields(n, specification);
        }
        this.props.change('embroideryCount', specifications.length);
    }

    private isEmptySpecification = (specification: EmbroiderySpecification): boolean => {
        return (specification.logo === null &&
            specification.placement === null &&
            specification.colorDirection === null &&
            specification.notes === null);
    }

    private isLast = (): boolean => {
        return this.state.selectedIndex >= (this.state.embroiderySpecifications.length - 1);
    }

    private isNext = (index: number): boolean => {
        return index > this.state.selectedIndex ? true : false;
    }

    private isCurrent = (index: number): boolean => {
        return index === this.state.selectedIndex ? true : false;
    }

    private onAddNotes = debounce((event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        this.onAddNotesDebounced(event);
    }, 400, { leading: false, trailing: true});

    private onAddNotesDebounced = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        let specification: EmbroiderySpecification | undefined =
            this.state.selectedIndex < specifications.length ?
                specifications[this.state.selectedIndex] : undefined;
        if (specification) {
            specification.notes = event.target.value;
            this.setState({
                embroiderySpecifications: specifications
            });
        }        
    }

    private onConfirmationComplete = (confirmed: boolean): void => {
        this.setState({
            confirming: false,
            confirmed: confirmed
        });
        if (confirmed) {
            setTimeout(() => {
                this.submitForm();
            }, 400);
        }
    }

    private onFocusPlacement = (event: React.FocusEvent): void => {
        let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        let embroiderySpecification: EmbroiderySpecification | null = this.state.selectedIndex < specifications.length ?
            specifications[this.state.selectedIndex] : null;
        if (embroiderySpecification && !embroiderySpecification.placement) {
            if (event.nativeEvent.target) {
                let element: HTMLInputElement | null = event.target.closest('input');
                if (element) {
                    element.click();
                }
            }
        }
    }

    private onImportFormSubmit = (submitting: boolean): void => {
        this.setState({
            importFormResult: null,
            importSubmitRequested: false,
            importSubmitting: true
        });
    }

    private onImportFormSubmitFail = (): void => {
        this.setState({
            importFormResult: null,
            importSubmitRequested: false
        });
    }

    private onImportFormSubmitSucceed = (newLogo: NewLogo): void => {
        this.setState({
            importAccepted: true,
            importRejected: false,
            importSubmitting: false,
            importFormResult: 'New logo request submitted' 
        });
        let logo: Logo = {
            customerNumber: newLogo.customerNumber,
            description: newLogo.description,
            dimensions: newLogo.dimensions,            
            height: newLogo.height,
            logoFileName: newLogo.previewFileName,
            logoUrl: newLogo.previewUrl,
            price: newLogo.price,
            selected: true,
            stitchCount: newLogo.stitchCount,
            tapeNumber: newLogo.tapeNumber,
            tapeType: EmbroideryTapeType.New,
            width: newLogo.width           
        };
        setTimeout(() => {
            this.hideTapeImporter(logo);
        }, 1500);
    }

    private onSelectColorDirection = (selectedOption: FieldWrapper.OptionValue): void => {
        if (selectedOption) {
            this.props.change('colorDirection' + this.state.selectedIndex, selectedOption.label);
        }

        if (this.props.colorDirectionsState.colorDirections) {
            let colorDirection: ColorDirection | null = selectedOption ?
                this.props.colorDirectionsState.colorDirections.filter((c: ColorDirection) => {
                    return c.code === selectedOption.value;
                })[0] : null;

            let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
            let specification: EmbroiderySpecification = specifications[this.state.selectedIndex];
            specification.colorDirection = colorDirection;

            this.setState({
                embroiderySpecifications: specifications
            })
        }
    }

    private onSelectPlacement = (selectedOption: FieldWrapper.OptionValue): void => {
        if (selectedOption) {
            this.props.change('placement' + this.state.selectedIndex, selectedOption.label);
        }

        let placementOption: PlacementOption | null = selectedOption ?
            this.props.orderItem.placementOptions.filter((p: PlacementOption) => {
                return p.code === selectedOption.value;
            })[0] : null;

        let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        let specification: EmbroiderySpecification = specifications[this.state.selectedIndex];
        specification.placement = placementOption;

        this.setState({
            embroiderySpecifications: specifications
        })
    }

    private onSelectTapeType = (index: number, event: React.ChangeEvent): void => {
        let selectedValue = event.target.getAttribute("value");
        if (selectedValue) {
            let selectedAttribute: EmbroideryTapeType = parseInt(selectedValue);
            if (selectedAttribute === EmbroideryTapeType.Existing) {
                setTimeout(() => {
                    this.showTapeSelector();
                })
            }
            else if (selectedAttribute === EmbroideryTapeType.New) {
                setTimeout(() => {
                    this.showTapeImporter();
                })
            }
        }
    }

    private onToggleColorDirection = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {
        let flags: boolean[] = cloneDeep(this.state.colorDirectionDropdownFlags);
        flags[index] = flags[index] ? false : true;
        if (flags[index]) {
            this.props.change('colorDirection' + index, 'Select Color Direction...');
        }
        this.setState({
            colorDirectionDropdownFlags: flags
        });
    }

    private onTogglePlacement = (event: React.KeyboardEvent | React.MouseEvent, index: number): void => {        
        let flags: boolean[] = cloneDeep(this.state.placementDropdownFlags);
        flags[index] = flags[index] ? false : true;
        if (flags[index]) {
            this.props.change('placement' + index, 'Select Placement...');
        }
        this.setState({
            placementDropdownFlags: flags
        });                          
    }

    private remove = (event: React.MouseEvent): void => {
        event.stopPropagation();
        let specifications: EmbroiderySpecification[] = cloneDeep(this.state.embroiderySpecifications);
        let removeAt: number = this.state.selectedIndex;

        if (removeAt < specifications.length) {
            this.updateFields(removeAt, undefined);
            specifications.splice(removeAt, 1);

            let selectedIndex: number = (removeAt < specifications.length || removeAt === 0) ? removeAt : (removeAt - 1);
            if (specifications.length == 0) {
                specifications.push(this.createNewSpecification());
            }
            else {
                let specification: EmbroiderySpecification = specifications[selectedIndex];
                this.updateFields(selectedIndex, specification);
            }
            this.setState({
                embroiderySpecifications: specifications
            })
            this.props.change('embroideryCount', specifications.length);
            this.props.onChangeEmbroideryIndex(selectedIndex);
        }
    }

    private requiresConfirmation = (specifications: EmbroiderySpecification[]): boolean => {
        let specialCases: boolean = false;
        if (specifications.length > 1) {
            for (let n: number = 0; n < specifications.length; n++) {
                let currentLogo: Logo = specifications[n].logo as Logo;
                let currentPlacement: PlacementOption = specifications[n].placement as PlacementOption;
                let duplicates: EmbroiderySpecification[] = specifications.filter((s: EmbroiderySpecification) => {
                    return ((s.logo && s.logo.tapeNumber === currentLogo.tapeNumber) ||
                        (s.placement && s.placement.code === currentPlacement.code));
                });
                if (duplicates.length > 1) {
                    specialCases = true;
                    break;
                }
            }
        }
        return specialCases;
    }

    private requestImportSubmit = (): void => {
        this.setState({
            importSubmitRequested: true
        });
    }

    private resetForm = (): void => {
        this.setState({
            submitting: false,
            confirming: false,
            selectingTape: false,
            importingTape: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            selectedIndex: 0,
            embroiderySpecifications: [],
            placementDropdownFlags: [false, false, false],
            colorDirectionDropdownFlags: [false, false, false]
        });
        this.props.reset();
    }

    private saveEmbroidery = (orderItem: OrderItemsStore.OrderItem): void => {
        this.props.actions.clearMessage();
        this.setState({
            submitting: true,
            changesRejected: false,
            formResult: null
        });
        this.props.asyncActions.updateOrderItemEmbroideriesAsync(orderItem.clientCode, orderItem.orderId, orderItem, false)
            .then(result => {
                this.setState({
                    submitting: false,
                    changesAccepted: true,
                    formResult: 'Changes saved'
                });
                setTimeout(() => {
                    this.props.onDismiss(orderItem);
                }, 800);
            },
            err => {
                this.setState({
                    submitting: false,
                    changesRejected: true,
                    formResult: err
                });
            }
        )
    }

    private showFirst = (event: React.MouseEvent): void => {
        event.stopPropagation();
        this.props.onChangeEmbroideryIndex(0);
    }

    private showLast = (event: React.MouseEvent): void => {
        event.stopPropagation();
        let selectedIndex: number = this.state.embroiderySpecifications.length - 1;
        this.props.onChangeEmbroideryIndex(selectedIndex);
    }

    private showNext = (event: React.MouseEvent): void => {
        event.stopPropagation();
        let selectedIndex: number = this.state.selectedIndex + 1;
        this.props.onChangeEmbroideryIndex(selectedIndex);
    }

    private showPrev = (event: React.MouseEvent): void => {
        event.stopPropagation();
        let selectedIndex: number = this.state.selectedIndex - 1;
        this.props.onChangeEmbroideryIndex(selectedIndex);
    }

    private showTapeImporter = (): void => {
        if (!this.state.importingTape) {
            this.setState({
                importingTape: true
            });
        }
    }

    private showTapeSelector = (): void => {
        if (!this.state.selectingTape) {
            this.setState({
                selectingTape: true
            });
        }
    }

    private submitForm = (): void => {
        if (this.props.orderItem.embroiderySpecifications.length > 0 &&
            this.state.embroiderySpecifications.length === 1 &&
            this.isEmptySpecification(this.state.embroiderySpecifications[0])) {

            let orderItem: OrderItemsStore.OrderItem = cloneDeep(this.props.orderItem);
            orderItem.embroiderySpecifications = [];
            setTimeout(() => {
                this.saveEmbroidery(orderItem);
            });
        }
        else if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }

    private updateFields = (index: number, specification: EmbroiderySpecification | null | undefined): void => {
        this.props.change('tapeType' + index, (specification && specification.logo) ? specification.logo.tapeType : EmbroideryTapeType.Undefined);
        this.props.change('tapeNumber' + index, (specification && specification.logo) ? specification.logo.tapeNumber : null);
        this.props.change('placement' + index, (specification && specification.placement) ? specification.placement.description : null);
        this.props.change('colorDirection' + index, (specification && specification.colorDirection) ? specification.colorDirection.titleCaseDescription : null);
        this.props.change('notes' + index, (specification && specification.notes) ? specification.notes: null);
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        colorDirectionsState: state.colorDirections,
        customersState: state.customers,
        messageState: state.message,
        ordersState: state.orders
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            ColorDirectionsStore.actionCreators,
            MessageStore.actionCreators), dispatch),
        asyncActions: {
            updateOrderItemEmbroideriesAsync: (clientCode: ClientCode, orderId: number, orderItem: OrderItemsStore.OrderItem, broadcastError: boolean) =>
                dispatch(OrderItemsStore.actionCreators.updateOrderItemEmbroideries(clientCode, orderId, orderItem, broadcastError))
        }
    };
}

export default connect<{}, {}, AddEmbroideryOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: "addEmbroideryForm",
    validate: validateAddEmbroideryForm,
    onSubmitFail: onFormValidationFailed,
    enableReinitialize: true
})(AddEmbroidery as any));
