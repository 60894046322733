import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as UsersStore from '../../store/Users';
import * as ErrorMessage from '../../common/ErrorMessage';
import { error } from 'console';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';

import './PasswordReissue.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    usersState: {} as UsersStore.UsersState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, UsersStore.actionCreators)
};

interface PasswordReissueOwnProps {
    isOpen: boolean;
    onDismiss: (() => void);
}

type PasswordReissueProps =
    PasswordReissueOwnProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface PasswordReissueState {
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
    errorMessage: MessageStore.Message | null | undefined;
}

class PasswordReissue extends React.PureComponent<PasswordReissueProps, PasswordReissueState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: PasswordReissueProps, state: PasswordReissueState) {
        super(props);
        this.state = {
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            errorMessage: null
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
    }

    public render = () => {
        return (
            <Modal id="password-reissue" size="sm" isOpen={this.props.isOpen}
                onOpened={this.initializeDialog.bind(this)} onClosed={this.resetDialog}>
                <ModalHeader toggle={this.props.onDismiss} className={this.state.submitting ? "disabled" : ""}>
                    Reset Password
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className="action-info pl-0 pr-0">
                                <span className="action-description">A new password will be emailed to</span>
                                <span className="action-target">{this.props.usersState.userDetail ? this.props.usersState.userDetail.fullName : ""}</span>
                            </Col>
                        </Row>           
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" diabled={this.state.submitting || this.state.changesAccepted}
                                    onClick={this.reissuePassword}>
                                    {this.state.submitting ? "Working" : "Continue"}
                                </Button>
                                <Button color="link" onClick={this.props.onDismiss} disabled={this.state.submitting || this.state.changesAccepted}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        )
    }

    // ----------------
    // HELPERS

    private initializeDialog = (): void => {
        this.setState({
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            errorMessage: null
        });
    }

    private reissuePassword = (): void => {
        if (this.props.usersState.userDetail) {
            this.setState({
                submitting: true
            });

            let fetchError: boolean = false;
            let action: string = 'users/reissuepassword';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'PUT',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(this.props.usersState.userDetail)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                this.setState({
                                    errorMessage: errorMessage
                                });
                                this.props.onDismiss();
                            })
                        );
                        fetchError = true;
                        throw new Error();
                    }
                    return response.json();
                })
                .then(data => {
                    this.setState({
                        submitting: false,
                        changesAccepted: true,
                        formResult: 'Password reset'
                    });
                    setTimeout(() => {
                        this.props.onDismiss();
                    }, 1500);
                },
                err => {
                    if (!fetchError) {
                        this.setState({
                            errorMessage: ErrorMessage.getFromError(err, action)
                        });
                        this.props.onDismiss();
                    }
                }
            )
        }
    }

    private resetDialog = (): void => {
        setTimeout(() => {
            if (this.state.errorMessage) {
                this.props.actions.broadcastMessage(this.state.errorMessage);
            }
        })
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        usersState: state.users
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            UsersStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, PasswordReissueOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(PasswordReissue as any);
