﻿import { Action, Reducer } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export enum MessageType {
    NONE = 'NO_MESSAGE',
    ERROR = 'ERROR_MESSAGE',
    SUCCESS = 'SUCCESS_MESSAGE',
    WARNING = 'WARNING_MESSAGE',
    UNAUTHORIZED = 'UNAUTHORIZED_MESSAGE',
    VALIDATIONERROR = "VALIDATIONERROR_MESSAGE",
    ACTIONCOMPLETE = "ACTION_COMPLETE"
}

export interface Message {
    messageType: MessageType;
    text: string | null;
    action: string | null;
    reference: string | null;
}

export interface MessageState {
    message: Message | null
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface ClearMessageAction {
    type: 'CLEAR_MESSAGE';
}

export interface BroadcastMessageAction {
    type: 'BROADCAST_MESSAGE',
    message: Message
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = ClearMessageAction | BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    clearMessage: () => ({ type: 'CLEAR_MESSAGE' } as ClearMessageAction),
    broadcastMessage: (message: Message) => ({ type: 'BROADCAST_MESSAGE', message: message } as BroadcastMessageAction)
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MessageState = { message: null };

export const reducer: Reducer<MessageState> = (state: MessageState | undefined, incomingAction: Action): MessageState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CLEAR_MESSAGE':
            return unloadedState;
        case 'BROADCAST_MESSAGE':
            return { message: action.message };
        default:
            return state;
    }
}