import * as React from 'react';
import { Dimension } from '../../common/ProductTypes';

import './DimensionPicker.scss';

// ----------------
// PROPS

interface DimensionPickerProps {
    description: string;
    dimensions: Dimension[];
    onSelectDimension: (dimension: Dimension) => void;
    selectedDimension?: Dimension | null | undefined;
}

// ----------------
// LOCAL STATE

interface DimensionPickerState {
    selectedDimension: Dimension | null | undefined;
    sortedDimensions: Dimension[];
}

class DimensionPicker extends React.PureComponent<DimensionPickerProps, DimensionPickerState>{

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: DimensionPickerProps, state: DimensionPickerState) {
        super(props);
        this.state = {
            selectedDimension: this.props.selectedDimension,
            sortedDimensions: this.props.dimensions.sort(this.sortDimensions)
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: DimensionPickerProps) => {
        if (this.isNewDefaultSelection()) {
            this.setState({
                selectedDimension: this.props.selectedDimension
            });
        }
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <div className="dimension-picker">
                <div className="dimension-label">
                    <label>{this.props.description}</label>
                </div>
                <div className="dimension-values">
                    {this.state.sortedDimensions.map((dimension: Dimension, dimensionIndex: number) => (
                        <a className={"dimension-value" + (this.state.selectedDimension && this.state.selectedDimension.code === dimension.code ? " selected" : "")}
                            key={"dimensionValue-" + dimensionIndex} onClick={(e: React.MouseEvent) => this.onSelectDimension(e, dimension)}>
                            <label className="dimension-value-label">
                                {dimension.name.toLowerCase()}
                            </label>
                        </a>
                    ))}
                </div>
            </div>
        );
    }

    // ----------------
    // HELPERS   

    private isNewDefaultSelection = (): boolean => {
        let isNew: boolean = true;
        if (this.props.selectedDimension && this.state.selectedDimension) {
            isNew = this.props.selectedDimension.code != this.state.selectedDimension.code;
        }
        return isNew;
    }

    private onSelectDimension = (event: React.MouseEvent, dimension: Dimension) => {
        if (!this.state.selectedDimension || (this.state.selectedDimension && this.state.selectedDimension.code != dimension.code)) {
            this.setState({
                selectedDimension: dimension
            });
            this.props.onSelectDimension(dimension);
        }
    }

    private sortDimensions(d1: Dimension, d2: Dimension) {
        return d1.sortIndex < d2.sortIndex ? -1 : (d1.sortIndex > d2.sortIndex ? 1 : 0);
    }
}

// ----------------
// EXPORT

export default DimensionPicker;
