import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as OrdersStore from '../../store/Orders';
import { BouncedOrder } from '../../store/Orders';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import * as ErrorMessage from '../../common/ErrorMessage';

import './ResubmitOrder.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    ordersState: {} as OrdersStore.OrdersState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, OrdersStore.actionCreators)
};

interface ResubmitOrderAsyncActions {
    asyncActions: {

    }
}

interface ResubmitOrderOwnProps {
    orderToResubmit: BouncedOrder | null | undefined;
    client: Client;
    onDismiss: (refresh: boolean) => void;
}

type ResubmitOrderProps =
    ResubmitOrderOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & ResubmitOrderAsyncActions;

// ----------------
// LOCAL STATE

interface ResubmitOrderState {
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
    actions: FieldWrapper.OptionValue[];
    selectedAction: number;
}

// ----------------
// FORM VALIDATOR

const validateResubmitOrderForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (formValues.action === 0) {
        errors.action = 'Select action';
    }
    return errors;
}

class ResubmitOrder extends React.PureComponent<ResubmitOrderProps, ResubmitOrderState> {

    // ----------------
    // VARIABLES

    public collapsibleRowVariants = {
        hidden: { height: 0, overflow: 'hidden' },
        visible: { height: 'auto', overflow: 'visible' }
    }

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: ResubmitOrderProps, state: ResubmitOrderState) {
        super(props);
        this.state = {
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            actions: this.getActions(),
            selectedAction: 0
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: ResubmitOrderProps) => {
    }

    public componentWillUnmount = () => { }

    public handleFormSubmit = (values: any): void => {
        switch (this.state.selectedAction) {
            case 1: {
                if (this.props.orderToResubmit) {
                    this.setState({
                        submitting: true,
                        changesAccepted: false,
                        changesRejected: false,
                        formResult: null
                    });
                    setTimeout(() => {
                        this.resubmitOrder(this.props.orderToResubmit as BouncedOrder);
                    }, 400);
                }
                break;
            }
            case 0:
            default: {
                this.setState({
                    changesRejected: true,
                    formResult: 'Please select an action'
                });
                break;
            }
        }
    }

    public render = () => {
        return (
            <Modal id="resubmit-order" isOpen={this.props.orderToResubmit ? true : false}
                onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(false)} className={(this.state.submitting || this.state.changesAccepted) ? "disabled" : ""}>
                    Resubmit Order
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                            <Row>
                                <Col className="pl-3 pt-2 action-bar text-center">
                                    <Field name="action" type="text" label=""
                                        component={FieldWrapper.renderRadioButtonField}
                                        options={this.state.actions} disabledOptions={[0,1]}
                                        disabled={this.state.submitting || this.state.changesAccepted} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="collapsible-row pl-0 pr-0">
                                    <motion.div animate={this.state.selectedAction === 1 ? "visible" : "hidden"} initial={this.state.selectedAction === 1 ? "visible" : "hidden"}
                                        variants={this.collapsibleRowVariants} transition={{ duration: (this.state.selectedAction === 0 ? 0 : 0) }}>
                                        <Container className="component-wrapper small">
                                            <Row>
                                                <Col className="pb-2 text-center">
                                                    <div className="order-description-container">
                                                        {this.props.orderToResubmit && (
                                                            <div className="order-description">
                                                                <label className="order-number">{this.props.orderToResubmit.orderNumber}</label>
                                                                <label className="order-customer">{this.props.orderToResubmit.customerNameOnly}</label>
                                                                <div className="two-column">
                                                                    <label className="name">Sales Rep</label>
                                                                    <label className="value">{this.props.orderToResubmit.repName}</label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </motion.div>
                                </Col>
                            </Row>
                            <button type="submit" ref={this.submitButton}>Submit</button>
                        </form>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row className="button-bar pl-0 pr-0">
                            <Button type="button" color="primary" onClick={this.submitOrderForm} disabled={this.state.submitting || this.state.changesAccepted}>
                                {this.state.submitting ? "Working ..." : "Continue"}
                            </Button>
                            <Button color="link" onClick={() => this.props.onDismiss(false)} disabled={this.state.submitting || this.state.changesAccepted}>
                                Cancel
                            </Button>
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private getActions = (): FieldWrapper.OptionValue[] => {
        let actions: FieldWrapper.OptionValue[] = [];
        let erpName: string = 'ERP';
        switch (this.props.client.code) {
            case ClientCode.GTN:
                erpName = 'NetSuite';
                break;
        }
        actions.push({ label: 'Resend to ' + erpName, value: 1 });
        actions.push({ label: 'Return to Sales Rep', value: 2 });
        return actions;
    }

    private initializeForm = (): void => {
        this.setState({
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
            selectedAction: 1
        });
        this.props.change('action', 1);
    }

    private resetForm = (): void => {
        this.setState({
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        });
    }

    private resubmitOrder = (order: BouncedOrder): void => {
        let fetchError: boolean = false;
        let action: string = 'orders/resubmit/' + order.orderId;
        let url: string = `${action}`;

        order.clientCode = this.props.client.code;

        fetch(url,
            {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify(order)
            })
            .then(response => {
                if (response.status >= 400) {
                    setTimeout(() => {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                this.setState({
                                    submitting: false,
                                    changesRejected: true,
                                    formResult: errorMessage.text
                                });
                            })
                        );
                    });
                    fetchError = true;
                    throw new Error();
                }
                return response.json();
            })
            .then(data => {
                this.setState({
                    submitting: false,
                    changesAccepted: true,
                    formResult: 'Order resubmitted',
                });
                setTimeout(() => {
                    this.props.onDismiss(true);
                }, 2000);
            },
            err => {
                if (!fetchError) {
                    this.setState({
                        submitting: false,
                        changesRejected: true,
                        formResult: err
                    });
                }
            });
    }

    private submitOrderForm = (): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        ordersState: state.orders
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            OrdersStore.actionCreators), dispatch),
        asyncActions: {

        }
    };
}

export default connect<{}, {}, ResubmitOrderOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'resubmitOrderForm',
    validate: validateResubmitOrderForm,
    enableReinitialize: true
})(ResubmitOrder as any));