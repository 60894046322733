import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { motion } from 'framer-motion';
import { ThunkDispatch } from 'redux-thunk/es/types';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import { OrderItem } from '../../store/OrderItems';
import * as OrderItemsStore from '../../store/OrderItems';
import * as ErrorMessage from '../../common/ErrorMessage';
import { Client } from '../../store/Client';
import { ClientCode } from '../../enums/ClientCode';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import cloneDeep from 'lodash/cloneDeep';

import './AddOrderItemNotes.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    orderItemsState: {} as OrderItemsStore.OrderItemsState
}

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators)
}

interface AddOrderItemNotesAsyncActions {
    asyncActions: {
        updateOrderItemNotesAsync: (clientCode: ClientCode, orderId: number, orderItem: OrderItem, broadcastError: boolean) => Promise<OrderItem | null | undefined>;
    }
}

interface AddOrderItemNotesOwnProps {
    isOpen: boolean;
    isOpenOrder: boolean;
    client: Client;
    orderItem: OrderItem;
    onDismiss: (orderItem: OrderItem | null) => void;
}

type AddOrderItemNotesProps =
    AddOrderItemNotesOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators     // ... plus action creators we've requested
    & AddOrderItemNotesAsyncActions;

// ----------------
// LOCAL STATE

interface AddOrderItemNotesState {
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
}

// ----------------
// FORM VALIDATOR

const validateAddOrderItemsNotesForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    return errors;
}

class AddOrderItemNotes extends React.PureComponent<AddOrderItemNotesProps, AddOrderItemNotesState> {

    // ----------------
    // VARIABLES

    public submitButton: React.RefObject<HTMLButtonElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: AddOrderItemNotesProps, state: AddOrderItemNotesProps) {
        super(props);
        this.state = {
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null,
        };
        this.submitButton = React.createRef<HTMLButtonElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: AddOrderItemNotesProps) => {
    }

    public componentWillUnmount = () => {
    }

    public handleFormSubmit = (values: any): void => {
        let updatedOrderItem: OrderItem = cloneDeep(this.props.orderItem);
        updatedOrderItem.notes = values.notes ? values.notes : null;
        this.saveNotes(updatedOrderItem);
    }

    public render = () => {
        return (
            <Modal className="add-order-item-notes" isOpen={this.props.isOpen}
                onOpened={this.initializeForm} onClosed={this.resetForm}>
                <ModalHeader toggle={() => this.props.onDismiss(null)} className={this.state.submitting ? "disabled" : ""}>
                    Line Item Notes
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className="pl-0 pr-0 order-item-notes-heading">
                                <h3 className="style-name">{this.props.orderItem.style.name}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 pr-0">
                                <form id="orderItemNotesForm" onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                                    {this.props.isOpenOrder && (
                                        <React.Fragment>
                                            <Field name="notes" type="text" label="" placeholder="" component={FieldWrapper.renderTextareaField}
                                                disabled={this.state.submitting || this.state.changesAccepted} required={false} rows={6} maxLength={254} />
                                            <button type="submit" ref={this.submitButton}>Submit</button>
                                        </React.Fragment>
                                    )}
                                    {!this.props.isOpenOrder && (
                                        <div className="readonly-value">{this.props.orderItem.notes}</div>
                                    )}
                                </form>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>                            
                            <Col className="button-bar pl-0 pr-0">
                                {this.props.isOpenOrder && (
                                    <React.Fragment>
                                        <Button type="button" color="primary" onClick={this.submitOrderItemNotesForm} disabled={this.state.submitting || this.state.changesAccepted}>
                                            {this.state.submitting ? "Working ..." : "Continue"}
                                        </Button>
                                        <Button color="link" onClick={() => this.props.onDismiss(null)} disabled={this.state.submitting || this.state.changesAccepted}>
                                            Cancel
                                        </Button>
                                    </React.Fragment>
                                )}
                                {!this.props.isOpenOrder && (
                                    <Button type="button" color="primary" onClick={() => this.props.onDismiss(null)}>
                                        OK
                                    </Button>
                                )}
                            </Col>                            
                        </Row>
                        <Row>
                            <Col className="pl-0 pt-4 pr-0">
                                <FormResult
                                    failureResult={this.state.changesRejected}
                                    successResult={this.state.changesAccepted}
                                    description={this.state.formResult} />
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS 

    private initializeForm = (): void => {
        this.setState({
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        });
        this.props.change("notes", this.props.orderItem.notes);
    }

    private resetForm = (): void => {
        this.props.reset();
    }

    private saveNotes = (orderItem: OrderItem): void => {
        this.setState({
            submitting: true
        });
        this.props.asyncActions.updateOrderItemNotesAsync(this.props.client.code, orderItem.orderId, orderItem, false)
            .then(result => {
                this.setState({
                    submitting: false,
                    changesAccepted: true,
                    formResult: 'Changes saved'
                });
                setTimeout(() => {
                    this.props.onDismiss(orderItem);
                }, 1500);
            },
            err => {
                this.setState({
                    submitting: false,
                    changesRejected: true,
                    formResult: err
                });
            }
        )
    }

    private submitOrderItemNotesForm = (): void => {
        if (this.submitButton.current) {
            this.submitButton.current.click();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        orderItemsState: state.orderItems
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<ApplicationState, void, Action>) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators), dispatch),
        asyncActions: {
            updateOrderItemNotesAsync: (clientCode: ClientCode, orderId: number, orderItem: OrderItem, broadcastError: boolean) =>
                dispatch(OrderItemsStore.actionCreators.updateOrderItemNotes(clientCode, orderId, orderItem, broadcastError))
        }
    };
}

export default connect<{}, {}, AddOrderItemNotesOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'orderItemNotesForm',
    validate: validateAddOrderItemsNotesForm,
    enableReinitialize: true
})(AddOrderItemNotes as any));
