import * as React from 'react';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';

import './NoSelectionsPrompt.scss';

// ----------------
// PROPS

interface NoSelectionsPromptProps {
    isOpen: boolean;
    onDismiss: () => void;
}

// ----------------
// LOCAL STATE

interface NoSelectionsPromptState {
}


class NoSelectionsPrompt extends React.PureComponent<NoSelectionsPromptProps, NoSelectionsPromptState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: NoSelectionsPromptProps, state: NoSelectionsPromptState) {
        super(props);
        this.state = {};
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: NoSelectionsPromptProps) => { }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <Modal id="no-selections-prompt" size="sm" isOpen={this.props.isOpen} 
                onOpened={this.initializeDialog} onClosed={this.resetDialog}>
                <ModalBody>
                    <div className="message-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-alert-circle">
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="12"></line>
                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                        <label>No items selected</label>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col className="button-bar pl-0 pr-0">
                                <Button type="button" color="primary" onClick={this.props.onDismiss}>
                                    OK
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        );
    }

    // ----------------
    // HELPERS

    private initializeDialog = (): void => { }

    private resetDialog = (): void => { }
}

// ----------------
// EXPORT

export default NoSelectionsPrompt;
