import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import * as MessageStore from '../../store/Message';
import * as UserStore from '../../store/User';
import * as FieldWrapper from '../field-wrapper/FieldWrapper';
import { Field, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';
import { Button, Container, Col, Modal, ModalFooter, ModalHeader, ModalBody, Row } from 'reactstrap';
import FormResult from '../form-result/FormResult';
import * as Validators from '../../common/Validators';

import './ProfileEdit.scss';

// ----------------
// PROPS

const applicationState = {
    messageState: {} as MessageStore.MessageState,
    userState: {} as UserStore.UserState
};

const actionCreators = {
    actions: Object.assign({}, MessageStore.actionCreators, UserStore.actionCreators)
};

interface ProfileEditOwnProps {
    isOpen: boolean;
    onDismiss: (() => void);
}

type ProfileEditProps =
    ProfileEditOwnProps
    & InjectedFormProps
    & typeof applicationState   // ... state we've requested from Redux store
    & typeof actionCreators;    // ... plus action creators we've requested

// ----------------
// LOCAL STATE

interface ProfileEditState {
    submitting: boolean;
    changesAccepted: boolean;
    changesRejected: boolean;
    formResult: string | null;
}

// ----------------
// FORM VALIDATOR

const validateProfileEditForm = (formValues: any): { title?: string, description?: string } => {
    let errors: any = {};
    if (!formValues.firstName) {
        errors.firstName = 'Required';
    }
    if (!formValues.lastName) {
        errors.lastName = 'Required';
    }
    if (!formValues.email) {
        errors.email = 'Required';
    }
    else if (!Validators.validateEmail(formValues.email)  ) {
        errors.email = 'Invalid email';
    }
    return errors;
}

class ProfileEdit extends React.PureComponent<ProfileEditProps, ProfileEditState> {

    // ----------------
    // VARIABLES

    public firstNameElement: React.RefObject<HTMLInputElement>;

    // ----------------
    // CONSTRUCTOR

    constructor(props: ProfileEditProps, state: ProfileEditState) {
        super(props);
        this.state = {
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        };
        this.firstNameElement = React.createRef<HTMLInputElement>();
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = () => {
        if (this.state.submitting) {
            if (this.receivedErrorMessage()) {
                setTimeout(() => {
                    this.setState({
                        submitting: false
                    });
                    this.props.onDismiss();
                })
            }
            else if (this.receivedValidationError()) {
                let formResult: string | null = this.getFormResult();
                this.props.actions.clearMessage();
                setTimeout(() => {
                    this.setState({
                        submitting: false,
                        changesRejected: true,
                        formResult: formResult
                    });                    
                })
            }
            else if (this.receivedActionCompleteMessage()) {
                let formResult: string | null = this.getFormResult();
                this.props.actions.clearMessage();
                setTimeout(() => {
                    this.setState({
                        submitting: false,
                        changesAccepted: true,
                        formResult: formResult
                    });
                    setTimeout(() => {
                        this.props.onDismiss();
                    }, 1500);
                })
            }
        }
    }

    public handleFormSubmit = (values: any): void => {
        this.setState({
            submitting: true,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        });
        this.props.actions.clearMessage();
        this.props.actions.updateUser(values);        
    }

    public render = () => {
        return (
            <Modal id="profile-edit" isOpen={this.props.isOpen}
                onOpened={this.initializeForm.bind(this)} onClosed={this.resetForm}>
                <ModalHeader toggle={this.props.onDismiss} className={this.state.submitting ? "disabled" : ""}>
                    Edit Profile
                </ModalHeader>
                <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col className="pl-0 pr-0 pr-sm-3" sm={6} xs={12}>
                                    <Field name="firstName" type="text" label="First Name"
                                        component={FieldWrapper.renderTextField}
                                        maxLength={25} autoComplete={false} autoFocus={true}
                                        reference={this.firstNameElement}
                                        disabled={this.state.submitting || this.state.changesAccepted} />
                                </Col>
                                <Col className="pl-0 pr-0 pl-sm-3" sm={6} xs={12}>
                                    <Field name="lastName" type="text" label="Last Name"
                                        component={FieldWrapper.renderTextField}
                                        maxLength={25} autoComplete={false}
                                        disabled={this.state.submitting || this.state.changesAccepted} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pr-0" xs={12}>
                                    <Field name="email" type="text" label="Email"
                                        component={FieldWrapper.renderTextField}
                                        maxLength={255} noSpaces={true} autoComplete={false}
                                        disabled={this.state.submitting || this.state.changesAccepted} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Container>
                            <Row>
                                <Col className="button-bar pl-0 pr-0 pr-sm-3">
                                    <Button type="submit" color="primary" disabled={this.state.submitting || this.state.changesAccepted}>
                                        {this.state.submitting ? "Working" : "Save"}
                                    </Button>
                                    <Button color="link" onClick={this.props.onDismiss} disabled={this.state.submitting || this.state.changesAccepted}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-0 pt-4 pr-0 pr-sm-3">
                                    <FormResult
                                        failureResult={this.state.changesRejected}
                                        successResult={this.state.changesAccepted}
                                        description={this.state.formResult} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalFooter>
                </form>
            </Modal>
        )
    }

    // ----------------
    // HELPERS

    private getFormResult = (): string | null => {
        let formResult: string | null = null;
        if (this.props.messageState.message) {
            if (this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) {
                formResult = this.props.messageState.message.text || 'Form contains invalid entries';
            }
            else if (this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) {
                formResult = 'Changes saved';
            }
        }
        return formResult;
    }

    private initializeForm = (): void => {
        this.resetForm();
        this.setState({
            submitting: false,
            changesAccepted: false,
            changesRejected: false,
            formResult: null
        });
        setTimeout(() => {
            if (this.firstNameElement.current) {
                this.firstNameElement.current.focus();
                this.firstNameElement.current.setSelectionRange(0, 0);
            }
        });
    }

    private receivedActionCompleteMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ACTIONCOMPLETE) ?
            true : false;
    }

    private receivedErrorMessage = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.ERROR) ?
            true : false;
    }

    private receivedValidationError = (): boolean => {
        return (this.props.messageState.message &&
            this.props.messageState.message.messageType === MessageStore.MessageType.VALIDATIONERROR) ?
            true : false;
    }

    private resetForm = (): void => {
        this.props.reset();
        if (this.receivedValidationError()) {
            this.props.actions.clearMessage();
        }
    }
}

// ----------------
// EXPORT

function mapStateToProps(state: any) {
    return {
        messageState: state.message,
        userState: state.user,
        initialValues: {
            firstName: state.user.user ? state.user.user.firstName : null,
            lastName: state.user.user ? state.user.user.lastName : null,
            email: state.user.user ? state.user.user.email : null
        }
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            MessageStore.actionCreators,
            UserStore.actionCreators), dispatch)
    };
}

export default connect<{}, {}, ProfileEditOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'profileEditForm',
    validate: validateProfileEditForm,
    enableReinitialize: true
})(ProfileEdit as any));
