import * as React from 'react';
import { CustomerDetail } from '../../store/Customers';
import { Address, DropShipAddress } from '../../common/AddressTypes';
import Checkbox from '../checkbox/Checkbox';
import AddressForm from '../address-form/AddressForm';
import { CustomerType } from '../../enums/CustomerType';
import $ from 'jquery';

import './DropShipAddressDescription.scss';

// ----------------
// PROPS

interface DropShipAddressDescriptionProps {
    isLoading: boolean;
    dropShipAddress: DropShipAddress;
    selectedAddressId: number | null | undefined;
    onSelect: (dropShipAddress: DropShipAddress, selected: boolean) => void;
}

// ----------------
// LOCAL STATE

interface DropShipAddressDescriptionState {
    checkboxId: string;
    selected: boolean;
    dropShipCustomerDetail: CustomerDetail;
}

class DropShipAddressDescription extends React.PureComponent<DropShipAddressDescriptionProps, DropShipAddressDescriptionState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: DropShipAddressDescriptionProps, state: DropShipAddressDescriptionState) {
        super(props);
        this.state = {
            checkboxId: "cb" + (this.props.dropShipAddress.id as number).toString(),
            selected: (this.props.selectedAddressId === this.props.dropShipAddress.id),
            dropShipCustomerDetail: this.convertToCustomerDetail(this.props.dropShipAddress)
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: DropShipAddressDescriptionProps) => {
        if (this.props.selectedAddressId != prevProps.selectedAddressId) {
            let selected: boolean = this.props.dropShipAddress.id === this.props.selectedAddressId;
            this.setState({
                selected: selected
            });
            if (!selected) {
                $('#' + this.state.checkboxId).prop("checked", false);
            }
        }
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <div className="dropship-address-description">
                <div className="action">
                    <Checkbox id={this.state.checkboxId}
                        selected={this.state.selected}                        
                        disabled={this.props.isLoading}                        
                        onChange={this.onSelect} />
                </div>
                <div className="text">
                    <AddressForm customerDetail={this.state.dropShipCustomerDetail} readonly={true} showLabels={false}
                        showPlaceholder={false} noMargin={true} hideExtendedFields={true} />
                </div>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private convertToCustomerDetail = (dropShipAddress: DropShipAddress): CustomerDetail => {
        let customerDetail: CustomerDetail = {
            address: dropShipAddress as Address,
            attention: '',
            billto: dropShipAddress.billto,
            contact: '',
            displayNumber: '',
            name: dropShipAddress.name,
            nameOnly: dropShipAddress.name,            
            number: '',
            phone: dropShipAddress.phone,
            selected: true,
            shipto: null,
            type: CustomerType.Shipping,
            locationDescription: ''
        }
        return customerDetail;
    }

    private onSelect = (checked: boolean): void => {
        this.setState({
            selected: checked
        });
        this.props.onSelect(this.props.dropShipAddress, checked);
    }
}

// ----------------
// EXPORT

export default DropShipAddressDescription;
