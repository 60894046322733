﻿/* CSS variable names */
export const BRAND_PRIMARY: string = "--brand-primary";
export const BRAND_SECONDARY: string = "--brand-secondary";
export const BRAND_SECONDARY_LIGHT: string = "--brand-secondary-light";
export const BRAND_TERTIARY: string = "--brand-tertiary";
export const BRAND_INVERSE: string = "--brand-inverse";
export const BRAND_LOGO_HEIGHT: string = "--brand-logo-height";
export const BRAND_LOGO_MARGIN_TOP: string = "--brand-logo-margin-top";
export const BRAND_TITLE_HEIGHT: string = "--brand-title-height";
export const BRAND_TITLE_MARGIN_TOP: string = "--brand-title-margin-top";
export const BRAND_LOGO_HEIGHT_SMALL: string = "--brand-logo-height-small";
export const BRAND_LOGO_MARGIN_TOP_SMALL: string = "--brand-logo-margin-top-small";
export const BRAND_TITLE_HEIGHT_SMALL: string = "--brand-title-height-small";
export const BRAND_TITLE_MARGIN_TOP_SMALL: string = "--brand-title-margin-top-small";
