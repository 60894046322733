﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as MessageStore from './Message';
import { Client } from './Client';
import * as ErrorMessage from '../common/ErrorMessage';
import { convertSQLDateToJSDate } from '../common/DateConverter';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface OrderDatesState {
    isLoading: boolean;
    client: Client | null | undefined;
    lastUpdated: Date | null | undefined;
    orderDates: OrderDates | null | undefined;
}

export interface OrderDates {
    currentDate: Date | null | undefined;
    earliestShipDateEmbroidered: Date | null | undefined;
    earliestShipDateNonEmbroidered: Date | null | undefined;
    expiration: number | null | undefined;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestOrderDatesAction {
    client: Client,
    type: 'REQUEST_ORDERDATES';
}

interface ReceiveOrderDatesAction {
    type: 'RECEIVE_ORDERDATES';
    client: Client,
    orderDates: OrderDates | null | undefined
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = RequestOrderDatesAction | ReceiveOrderDatesAction | MessageStore.BroadcastMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const isExpired = (state: OrderDatesState): boolean => {
    let expired: boolean = true;
    if (state.lastUpdated && state.orderDates && state.orderDates.expiration) {
        let elapsed = Math.abs(new Date().getTime() - state.lastUpdated.getTime()) / 1000;
        elapsed = Math.round(elapsed / 60);
        expired = (elapsed >= state.orderDates.expiration);
    }
    return expired;
}

export const actionCreators = {
    requestOrderDates: (client: Client): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.orderDates && !appState.orderDates.isLoading && isExpired(appState.orderDates)) {

            let fetchError: boolean = false;
            let action: string = 'orderdates/get';
            let url: string = `${action}`;

            fetch(url,
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(client)
                })
                .then(response => {
                    if (response.status >= 400) {
                        ErrorMessage.getFromResponse(response, action).then(
                            (errorMessage => {
                                dispatch({ type: 'RECEIVE_ORDERDATES', client: client, orderDates: null });
                                dispatch({ type: 'BROADCAST_MESSAGE', message: errorMessage });
                            })
                        );
                        fetchError = true;
                        throw new Error;
                    }
                    return response.json();
                })
                .then(data => {
                    let orderDates: OrderDates = data as OrderDates;
                    if (orderDates.currentDate) {
                        orderDates.currentDate = convertSQLDateToJSDate(orderDates.currentDate);
                    }
                    if (orderDates.earliestShipDateEmbroidered) {
                        orderDates.earliestShipDateEmbroidered = convertSQLDateToJSDate(orderDates.earliestShipDateEmbroidered);
                    }
                    if (orderDates.earliestShipDateNonEmbroidered) {
                        orderDates.earliestShipDateNonEmbroidered = convertSQLDateToJSDate(orderDates.earliestShipDateNonEmbroidered);
                    }                    
                    dispatch({ type: 'RECEIVE_ORDERDATES', client: client, orderDates: orderDates });
                },
                    err => {
                        if (!fetchError) {
                            dispatch({ type: 'RECEIVE_ORDERDATES', client: client, orderDates: null });
                            dispatch({ type: 'BROADCAST_MESSAGE', message: ErrorMessage.getFromError(err, action) });
                        }
                    });

            dispatch({ type: 'REQUEST_ORDERDATES', client: client });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: OrderDatesState = { isLoading: false, client: null, orderDates: null, lastUpdated: null };

export const reducer: Reducer<OrderDatesState> = (state: OrderDatesState | undefined, incomingAction: Action): OrderDatesState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ORDERDATES':
            return {
                client: state.client,
                orderDates: state.orderDates,
                lastUpdated: state.lastUpdated,
                isLoading: true
            };
        case 'RECEIVE_ORDERDATES':
            return {
                client: action.client,
                orderDates: action.orderDates,
                lastUpdated: new Date(),
                isLoading: false
            }
        default:
            return state;
    }
}



