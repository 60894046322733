import * as React from 'react';
import { ColorOption } from '../../common/ProductTypes';
import { motion } from 'framer-motion';
import { Button } from 'reactstrap';
import ImageLoader from '../image-loader/ImageLoader';

import './ProductColors.scss';


// ----------------
// PROPS

interface ProductColorsProps {
    colorOptions: ColorOption[];
    selectedOption: ColorOption | null | undefined;
    onSelect: (colorOption: ColorOption) => void;
}

// ----------------
// LOCAL STATE

interface ProductColorsState {
    maxVisible: number;
}

class ProductColors extends React.PureComponent<ProductColorsProps, ProductColorsState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR
    constructor(props: ProductColorsProps, state: ProductColorsState) {
        super(props);
        this.state = {
            maxVisible: 4
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => {
    }

    public componentDidUpdate = (prevProps: ProductColorsProps) => {
    }

    public componentWillUnmount = () => {
    }

    public render = () => {
        return (
            <div className="product-colors">
                {this.props.colorOptions.map((colorOption: ColorOption, colorOptionIndex: number) => (
                    <React.Fragment key={"fragment-" + colorOptionIndex}>
                        {colorOptionIndex < this.state.maxVisible && (
                            <div className="product-color" key={"colorOption-" + colorOptionIndex}>
                                <a className={"product-color-image" + (this.isSelected(colorOption) ? " selected" : "")}
                                    onClick={(e: React.KeyboardEvent | React.MouseEvent) => this.onSelectColorOption(e, colorOption)}>
                                    <div className="product-color-image-stretcher">
                                        <div className="product-color-image-canvas">
                                            <ImageLoader url={colorOption.imageUrl} thumbnail={true} isLoading={false} onLoad={() => this.onColorOptionImageLoad(colorOptionIndex)} />
                                        </div>
                                    </div>
                                </a>
                                <div className="product-color-tag">
                                    <label>{this.titleCaseColorName(colorOption.name)}</label>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                ))}
                {this.state.maxVisible < this.props.colorOptions.length && (
                    <Button color="link" onClick={this.showAllColors}>Show All <span>({this.props.colorOptions.length})</span></Button>
                )}
                {this.state.maxVisible === this.props.colorOptions.length && this.props.colorOptions.length > 4 && (
                    <Button color="link" onClick={this.showLessColors}>Show Less</Button>
                )}
            </div>
        );
    }

    // ----------------
    // HELPERS

    private isSelected = (colorOption: ColorOption): boolean => {
        let selected: boolean = false;
        if (this.props.selectedOption) {
            selected = (colorOption.code === this.props.selectedOption.code) ? true : false;
        }
        return selected;
    }

    private onColorOptionImageLoad = (columnIndex: number): void => {
    }

    private onSelectColorOption = (event: React.KeyboardEvent | React.MouseEvent, colorOption: ColorOption): void => {
        event.preventDefault();
        this.props.onSelect(colorOption);
    }

    private showAllColors = (): void=> {
        this.setState({
            maxVisible: this.props.colorOptions.length
        });
    }

    private showLessColors = (): void=> {
        this.setState({
            maxVisible: 4
        })
    }

    private titleCaseColorName = (name: string | null | undefined): string => {
        let colorName: string = '';
        if (name) {
            let tokens: string[] = name.split('/');
            for (let n: number = 0; n < tokens.length; n++) {
                tokens[n] = this.toTitleCase(tokens[n]);
            }
            colorName = tokens.join('/');
        }
        return colorName;
    }

    private toTitleCase(value: string) {
        return value.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
}

// ----------------
// EXPORT

export default ProductColors;
