import * as React from 'react';
import { Client } from '../../store/Client';
import { OrderItem } from '../../store/OrderItems';
import { EmbroiderySpecification } from '../../common/EmbroideryTypes';
import { Button, Container, Row, Col } from 'reactstrap';
import ImageLoader from '../image-loader/ImageLoader';
import OrderItemTotal from '../order-item-total/OrderItemTotal';
import { toTitleCase } from '../../common/StringFormatter';

import './OrderItemDescription.scss';

// ----------------
// PROPS

interface OrderItemDescriptionProps {
    client: Client;
    orderItem: OrderItem;
    embroiderySpecifications: EmbroiderySpecification[];
    isLoading: boolean;
    isOpenOrder: boolean;
    onViewProduct: (orderItem: OrderItem) => void;
    onAddEmbroidery: (event: any, orderItem: OrderItem, embroideyIndex: number) => void;
    onAddNotes: (event: any, orderItem: OrderItem) => void;
    onCopyOrderItem: (event: any, orderItem: OrderItem) => void;
    onRemoveOrderItem: (event: any, orderItem: OrderItem) => void;
    onImageLoad: () => void;
}

// ----------------
// LOCAL STATE

interface OrderItemDescriptionState {
    maxEmbroideries: number;
}

class OrderItemDescription extends React.PureComponent<OrderItemDescriptionProps, OrderItemDescriptionState> {

    // ----------------
    // VARIABLES

    // ----------------
    // CONSTRUCTOR

    constructor(props: OrderItemDescriptionProps, state: OrderItemDescriptionState) {
        super(props);
        this.state = {
            maxEmbroideries: this.props.client.rules.maxLogosPerItem
        };
    }

    // ----------------
    // METHODS

    public componentDidMount = () => { }

    public componentDidUpdate = (prevProps: OrderItemDescriptionProps) => {
    }

    public componentWillUnmount = () => { }

    public render = () => {
        return (
            <div className="order-item-description">
                {this.props.isOpenOrder && (
                    <div className="actions">
                        <button type="button" className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" tabIndex={0} title="Copy Item"
                            onClick={(e: React.MouseEvent) => this.props.onCopyOrderItem(e, this.props.orderItem)}>
                            <span className="MuiIconButton-label">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-copy">
                                    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                </svg>
                            </span>
                        </button>
                        <button type="button" className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" tabIndex={0} title="Remove Item"
                            onClick={(e: React.MouseEvent) => this.props.onRemoveOrderItem(e, this.props.orderItem)}>
                            <span className="MuiIconButton-label">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-trash">
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                </svg>
                            </span>
                        </button>
                    </div>
                )}
                <a href="#" className="image" onClick={(e: React.MouseEvent) => this.onViewProduct(e)}>
                    <div className="order-item-image">
                        <div className="image-stretcher">
                            <div className="image-canvas">
                                <ImageLoader url={this.props.orderItem.imageUrl} isLoading={this.props.isLoading} onLoad={this.onOrderItemImageLoad} noFadeIn={false} />
                            </div>
                        </div>
                    </div>
                </a>
                <div className="text">
                    <label className="style-code">
                        {this.props.orderItem.style.sku ? "" : "Style "}
                        {this.props.orderItem.style.sku ? this.props.orderItem.style.sku : this.props.orderItem.style.code}
                    </label>
                    <label className="category">{this.props.orderItem.shortDescription}</label>
                    <h4 className="style-name">{this.props.orderItem.style.name}</h4>
                    <div className="two-column">
                        <label className="price">${this.props.orderItem.wholesale.toFixed(2)}</label>
                        <label className="color">{this.getColorDescription()}</label>
                    </div>
                    {this.props.orderItem.isEditable && this.props.orderItem.canEmbroider && !this.hasMaxEmbroideries() && (
                        <React.Fragment>
                            <Button color="link" className="add-embroidery" onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.props.onAddEmbroidery(e, this.props.orderItem, this.getNewEmbroideryIndex())}>
                                <label>Add Embroidery</label>
                            </Button>
                            <span className="divider">|</span>
                        </React.Fragment>
                    )}
                    <Button color="link" className="add-notes" onClick={(e: React.MouseEvent | React.KeyboardEvent) => this.props.onAddNotes(e, this.props.orderItem)}>
                        <label>{this.getNotesLinkLabel()}</label>
                    </Button>
                </div>
                <div className="subtotal">
                    <OrderItemTotal orderItem={this.props.orderItem} />
                </div>
            </div>
        );
    }

    // ----------------
    // HELPERS

    private getColorDescription = (): string => {
        let color: string | null | undefined = this.props.orderItem.color.name;
        if (color) {
            if (color != 'PATTERNED') {
                color = this.titleCaseColorName(color);
            }
            else {
                color = "";
            }
        }
        else {
            color = this.props.orderItem.color.code;
        }
        return color || "";
    }

    private getNewEmbroideryIndex = (): number  => {
        let newIndex: number = 0;
        if (this.props.embroiderySpecifications.length > 0) {
            newIndex = this.props.embroiderySpecifications.length;
        }
        return newIndex;
    }

    private getNotesLinkLabel = (): string => {
        let linkLabel: string = 'Notes';
        if (this.props.orderItem.isEditable && (!this.props.orderItem.canEmbroider || this.hasMaxEmbroideries())) {
            linkLabel = 'Add ' + linkLabel;
        }
        return linkLabel;
    }

    private hasMaxEmbroideries = (): boolean => {
        return this.props.embroiderySpecifications.length === this.state.maxEmbroideries;
    }

    private isEmbroidered = (): boolean => {
        return this.props.embroiderySpecifications.length > 0;
    }

    private onOrderItemImageLoad = (): void => {
        this.props.onImageLoad();
    }

    private onViewProduct = (event: React.MouseEvent): void => {
        event.preventDefault();
        this.props.onViewProduct(this.props.orderItem);
    }

    private titleCaseColorName = (name: string | null | undefined): string => {
        let colorName: string = '';
        if (name) {
            let tokens: string[] = name.split('/');
            for (let n: number = 0; n < tokens.length; n++) {
                tokens[n] = toTitleCase(tokens[n]);
            }
            colorName = tokens.join('/');
        }
        return colorName;
    }
}

// ----------------
// EXPORT

export default OrderItemDescription;
